import React from 'react';
import {AvFeedback, AvGroup} from 'availity-reactstrap-validation';
import ServiceCategoryService from "../../shared/service/ServiceCategoryService";
import ServiceSubcategoryService from "../../shared/service/ServiceSubcategoryService";
import {Button, Col, Label, Row} from 'reactstrap';
import IncidentService from "../../shared/service/IncidentService"
import {Fieldset} from "primereact/fieldset";
import HsmOrganizationService from "../../shared/service/HsmOrganizationService";
import LocationService from "../../shared/service/LocationService";
import ContactService from "../../shared/service/ContactService";
import HsmUserGroup from "../../shared/component/hsmGroup/HsmUserGroup";
import DateInput from "../../shared/component/form/DateInput";
import LocationGroup from "../../shared/component/hsmGroup/LocationGroup";
import {
    ASSET_CATEGORY,
    CHANNEL_TYPE,
    GUEST_CONTACT_ID,
    IMPACT_TYPE,
    INCIDENT_CONFIRMATION_STATUS_ALL,
    INCIDENT_STATUS,
    INCIDENT_STATUS_REASON,
    PAGE_MODE,
    PRIORITY_TYPE,
    REQUEST_TYPE,
    SERVICE_CATEGORY,
    TENANT_ID,
    URGENCY_TYPE
} from "../../shared/utils/Constants";
import GeneralUtils from "../../shared/utils/GeneralUtils";
import OrganizationGroup from "../../shared/component/hsmGroup/OrganizationGroup";
import HsmOrganizationColumnForDialog from '../../shared/component/dataTable/columns/HsmOrganizationColumnForDialog';
import ContactGroup from "../../shared/component/hsmGroup/ContactGroup";
import BbProductService from "../../shared/service/BbProductService";
import HsmResolutionCodeService from "../../shared/service/HsmResolutionCodeService";
import ContactColumnForDialog from "../../shared/component/dataTable/columns/ContactColumnForDialog";
import IncidentColumn from '../../shared/component/dataTable/columns/IncidentColumn';
import 'survey-react/survey.css';
import HermesSweetAlert from "../../shared/component/sweetAlert/HermesSweetAlert";
import AvField from "../../shared/component/form/AvField";
import RequestSltService from "../../shared/service/RequestSltService";
import BbProductGroup from "../../shared/component/hsmGroup/BbProductGroup";
import BbProductColumnForGroup from "../../shared/component/dataTable/columns/BbProductColumnForGroup";
import BusinessRuleService from "../../shared/service/BusinessRuleService";
import RequestAssetService from "../../shared/service/RequestAssetService";
import SurveyDialog from "../request/hsmDialog/SurveyDialog";
import TenantService from "../../shared/service/TenantService";
import PropTypes from "prop-types";
import {PhoneField} from "../../shared/component/form/PhoneField";
import ExternalTicketService from "../../shared/service/ExternalTicketService";
import Dialog from "../../shared/component/dialog/Dialog";
import DataTable from "../../shared/component/dataTable/DataTable";
import ExternalTicketColumn from "../../shared/component/dataTable/columns/ExternalTicketColumn";
import ComplaintTypeService from "../../shared/service/ComplaintTypeService";
import Network360WebClientService from "../../shared/service/Network360WebClientService";

let defaultIncident = {
    id: null,
    title: '',
    description: '',
    serviceCategoryId: 1,
    serviceSubcategoryId: 1,
    organizationId: null,
    locationId: null,
    channel: '',
    urgency: '4',
    priority: '4',
    impact: '4',
    emailNotification: '1',
    status: 'NEW',
    contactGivenNames: '',
    contactFamilyNames: '',
    contactEmail: '',
    contactPhone: '',
    contactGivenNamesTemp: '',
    contactFamilyNamesTemp: '',
    contactEmailTemp: '',
    contactPhoneTemp: '',
    requestedBy: null,
    reportedBy: null,
    resolvedBy: null,
    reopenBy: null,
    assignedTo: null,
    closureBy: null,
    assignmentDate: null,
    lastModifiedDate: null,
    closureDate: null,
    resolutionDate: null,
    createdDate: null,
    createdBy: null,
    lastModifiedBy: null,
    reopenDate: null,
    teamId: null,
    complaintTypeId:null
};

export default class NewIncident extends React.Component {
    constructor(props) {
        super(props);
        defaultIncident.requestedBy = GUEST_CONTACT_ID;
        defaultIncident.reportedBy = GeneralUtils.getContactId();
        defaultIncident.createdBy = GeneralUtils.getUserId();

        this.state = {
            displayContent: false,
            incident: defaultIncident,
            pageMode: PAGE_MODE.CREATE,
            hsmResolutionCodeList: [],
            serviceCategoryList: [{id: 1, name: 'TTVPN'}],
            serviceSubcategoryList: [],
            serviceSubcategoryAllList: [],
            locationList: [{id: 0, name: ''}],
            locationHidden: true,
            emailNotificationHidden: false,
            emailNotificationDisabled: false,
            closureCodeList: [{id: 0, name: ''}],
            organization: null,
            requestedBy: null,
            reportedBy: GeneralUtils.getUserNameAndFamilyNames(),
            displaySurvey: false,
            surveyResult: null,
            externalTicket: {},
            buttonColor:'#1E90FF',
            loadingButton:false,
            buttonName:'Kontrol',
            complaintTypeList: []
        }
        this.service = new IncidentService();
        this.serviceCategoryService = new ServiceCategoryService();
        this.serviceSubategoryService = new ServiceSubcategoryService();
        this.hsmOrganizationService = new HsmOrganizationService();
        this.locationService = new LocationService();
        this.bbProductService = new BbProductService();
        this.hsmResolutionCodeService = new HsmResolutionCodeService();
        this.contactService = new ContactService();
        this.requestSltService = new RequestSltService();
        this.businessRuleService = new BusinessRuleService();
        this.requestAssetService = new RequestAssetService();
        this.tenantService = new TenantService();
        this.externalTicketService = new ExternalTicketService();
        this.complaintTypeService = new ComplaintTypeService();

        this.network360WebClientService = new Network360WebClientService();
    }

    componentDidMount = () => {

        this.clearData();

        this.tenantService.getTenantWithoutLogin().then(result => {
            if (!GeneralUtils.isNullOrEmpty(result)) {
                if (result == TENANT_ID.MEB) {
                    this.setState({  displayControlButton: true, displayComplaintType: true});
                }
            }
        });

        this.serviceCategoryService.findAll().then(response => this.setState({serviceCategoryList: response}));

        this.complaintTypeService.findAll().then(response => this.setState({complaintTypeList: response}));

        this.serviceSubategoryService.findIncidentSubCategories(this.state.incident.serviceCategoryId).then(response => {
            let serviceSubategoryList = this.checkSubcategoryAccess(response);

            let incident = this.state.incident;
            if (!GeneralUtils.isNullOrEmpty(serviceSubategoryList)) {
                incident.serviceSubcategoryId = serviceSubategoryList[0].id;
                incident.title = serviceSubategoryList[0].name;
            }
            this.setState({serviceSubcategoryList: serviceSubategoryList, serviceSubcategoryAllList:response, incident: incident});
        });

        this.hsmResolutionCodeService.findByRequestType(REQUEST_TYPE.INCIDENT).then(response => {
            this.setState({hsmResolutionCodeList: response});
        });

        this.contactService.findById(defaultIncident.requestedBy).then(data=>{
            let requestedBy = data.givenNames + ' ' + data.familyName;
            let requestedByData = data;
            let incident = this.state.incident;
           /* incident.contactGivenNames = data.givenNames;
            incident.contactFamilyNames = data.familyName;*/
            incident.contactGivenNames = "";
            incident.contactFamilyNames = "";
            incident.contactEmail = data.email;
            incident.contactPhone = data.mobilePhone;
            this.setState({
                incident, requestedBy,requestedByData
            });
        });
    }

    clearData = () => {

        let obj = Object.assign({}, defaultIncident);

        this.state = {
            incident: obj,
            pageMode: PAGE_MODE.CREATE,
            hsmResolutionCodeList: [],
            serviceCategoryList: [{id: 1, name: 'TTVPN'}],
            serviceSubcategoryList: [],
            serviceSubcategoryAllList: [],
            locationList: [],
            locationHidden: true,
            emailNotificationHidden: false,
            emailNotificationDisabled: false,
            closureCodeList: [{id: 0, name: ''}],
            organization: null,
            organizationObject: null,
            requestedBy: null,
            reportedBy: GeneralUtils.getUserNameAndFamilyNames(),
            bbProduct: null,
            bbProductObject: null,
            displaySurvey: false,
            surveyResult: null,
            requestedByData: null,
            reportedByData: GeneralUtils.getContact(),
            externalTicket: {}
        }

        this.setState({buttonColor: '#1E90FF', loadingButton:false, buttonName:'Kontrol'})
    }

    render() {

        return (<div>{this.state.displayContent ? this.getContent() :
            ( this.getCategoryAndSubcategory())}</div>);
        /* return (<div>{this.state.displayContent ? this.getContent() :
             (this.state.displayCategoryAndSubcategory ? this.getCategoryAndSubcategory() : this.getSurvey())}</div>);*/
    }


    /*
        getSurvey = () => {
            return <Container>
                <Survey.Survey model={this.state.survey}/>
            </Container>
        }
    */

    getCategoryAndSubcategory = () => {
        return <div>
            {GeneralUtils.checkPrivilege(IncidentColumn.name, 'Create') &&
            <Row className="justify-content-left">
                <Col md="7">
                    <Row>
                        <Col md="7">
                            <Fieldset legend="Arıza Bilgileri">
                                <AvGroup>
                                    <Label for="serviceCategoryId">
                                        Kategori
                                    </Label>
                                    <AvField type="select" className="form-control" name="serviceCategoryId"
                                             value={this.state.incident.serviceCategoryId}
                                             disabled={this.state.displayOrganizationAndLocation}
                                             onChange={this.__handleChangeServiceCategory}>
                                        {this.state.serviceCategoryList.map(element => (
                                            <option value={element.id} key={element.id}>
                                                {element.name}
                                            </option>
                                        ))}
                                    </AvField>
                                </AvGroup>


                                <AvGroup>
                                    <Label for="serviceSubcategoryId">
                                        Alt Kategori
                                    </Label>
                                    <AvField type="select" className="form-control" name="serviceSubcategoryId"
                                             value={this.state.incident.serviceSubcategoryId}
                                             disabled={this.state.displayOrganizationAndLocation}
                                             onChange={this.__handleChangeServiceSubcategory}>
                                        {this.state.serviceSubcategoryList.map(element => (
                                            <option value={element.id} key={element.id}>
                                                {element.name}
                                            </option>
                                        ))}
                                    </AvField>
                                </AvGroup>
                                {this.state.displayOrganizationAndLocation && this.getOrganizationAndLocation()}
                                {!this.state.displayOrganizationAndLocation &&
                                <Button color="primary"
                                        onClick={this.continue}>
                                    Devam
                                </Button>}
                            </Fieldset>
                        </Col>
                        <Col/>
                    </Row>
                </Col>
                <Col md="5">
                </Col>
            </Row>
            }
            <SurveyDialog ref={elem => this.refSurveyDialog = elem}
                          displayOrganizationAndLocation={(displayOrganizationAndLocation) =>
                              this.setState({displayOrganizationAndLocation: displayOrganizationAndLocation})}
                          displaySurvey={(displaySurvey) =>
                              this.setState({displaySurvey: displaySurvey})}
                          surveyResult={(surveyResult) =>
                              this.setState({surveyResult: surveyResult})}
            />
        </div>
    }

    continue = () => {
        if (GeneralUtils.checkPrivilege('Incident', 'FillSurvey')) {
            if (this.state.displaySurvey == true) {
                this.setState({displayOrganizationAndLocation: true})
            } else {
                this.refSurveyDialog.setData(this.state.incident.serviceCategoryId, this.state.incident.serviceSubcategoryId);
            }
        } else {
            this.setState({displayOrganizationAndLocation: true})
        }
    }

    getOrganizationAndLocation = () => {
        return <div>
            <Fieldset legend="Hizmet Numarası, Organizasyon, Lokasyon">
                <BbProductGroup
                    value={this.state.bbProduct}
                    data={this.state.bbProductObject}
                    onDataSelect={this.onBbProductSelect}
                    disabled={GeneralUtils.incidentButtonDisabled(this.state.pageMode, 'bbProduct')}
                    dataTable={{
                        dataURL: "bbProduct/findByContactIdAndStatus",
                        parameter: GeneralUtils.getContactId() + '/' + ['ACTIVE'],
                        fields: BbProductColumnForGroup.fields
                    }}
                    controlButton={!GeneralUtils.isNullOrEmpty(this.state.bbProductObject) && this.state.displayControlButton ? true : false}
                    getDeviceStatus={() => this.getDeviceStatus(this.state.bbProductObject)}
                    buttonStyle={{
                        backgroundColor: this.state.buttonColor,
                        loading: this.state.loadingButton,
                        buttonName: this.state.buttonName
                    }}
                />

                <OrganizationGroup
                    value={this.state.organization}
                    onDataSelect={this.onOrganizationSelect}
                    data={this.state.organizationObject}
                    dataTable={{
                        dataURL: "hsmOrganization/findByContactId",
                        parameter: GeneralUtils.getContactId(),
                        fields: HsmOrganizationColumnForDialog.fields
                    }}
                    disabled={true}
                />

                <LocationGroup value={this.state.incident.locationId}
                               optionList={this.state.locationList}
                               disabled={true}
                               onChange={this.__handleChangeLocation}/>
            </Fieldset>
            <Button color="primary" onClick={() => this.setState({displayOrganizationAndLocation: false})}>
                Geri
            </Button>
            <Button color="primary" onClick={() => {
                if (GeneralUtils.isNullOrEmpty(this.state.bbProduct) || GeneralUtils.isNullOrEmpty(this.state.incident.organizationId) || GeneralUtils.isNullOrEmpty(this.state.incident.locationId)) {
                    return HermesSweetAlert.warn("Zorunlu Alan : Hizmet Numarası, Organizasyon, Lokasyon");
                }
                this.setState({displayContent: true});
                this.props.disabledTabs(this.setDisabledFlagIncidentFollow);
            }}>
                Devam
            </Button>
        </div>
    }

    setDisabledFlagIncidentFollow = (pageMode,requestId) =>{
        this.requestSltService.haveAnyRecord(requestId).then(res => {
            /*Yeni arızda arıza takip tabı arızanın SLA olup olmamasından bağımsız disabled olacak.*/
            if(pageMode === PAGE_MODE.CREATE){
                this.props.disabledTabs(true);
            }else if(pageMode === PAGE_MODE.VIEW){/*arıza güncellerken arıza tipi SLA li ise arıza takip tabı aktif SLA siz ise pasif olacaktır. SLA li türler:1,100,103 */
                if(res == false ){
                    this.props.disabledTabs(true);
                }else if(res == true ){
                    this.props.disabledTabs(false);
                }
            }

        });

    }

    getContent = () => {
        return (
            <div>
                <Row className="justify-content-left">
                    <Col md="4">
                        {this.getIncidentInfo()}
                    </Col>

                    <Col md="5">
                        {this.getDescription()}
                    </Col>

                    <Col md="3">
                        {this.getContactInfo()}
                    </Col>
                </Row>

                <Row className="justify-content-left">
                    {this.state.pageMode !== PAGE_MODE.CREATE &&
                    <Col>
                        {this.getAssignedInfo()}
                    </Col>}

                    {this.state.pageMode !== PAGE_MODE.CREATE &&
                    <Col>
                        {this.closureInfo()}
                    </Col>}

                    {this.state.pageMode !== PAGE_MODE.CREATE && GeneralUtils.checkPrivilege(ExternalTicketColumn.name, 'View') &&
                    <Col>
                        {this.getExternalTicketInfo()}
                    </Col>}
                </Row>


                <Dialog visible={this.state.displayExternalTicketInfoDialog}
                        header='Arıza Talebi ile İlişkili Dış Sistem Arıza Kayıtları'
                        onHide={() => this.setState({displayExternalTicketInfoDialog: false})}
                        style={{width: '90vw'}}>
                    <DataTable  ref={(elem) => this.refExternalTicketDataTable = elem} fields={ExternalTicketColumn.fields} dataURL="externalTicket/findAllByRequestTypeAndRequestId"
                                objectName={ExternalTicketColumn.name} loading={this.state.loading} export={true}  autoLoad={false}/>
                </Dialog>

            </div>
        );
    }

    closureInfo = () => {
        return <Fieldset legend="Çözüm Bilgileri">
            <Row>
                <Col md="4">
                    <AvGroup>
                        <Label for="resolutionCode">
                            Çözüm Kodu
                        </Label>
                        <AvField type="select" className="form-control" name="resolutionCode"
                                 disabled={true}
                                 value={this.state.incident.resolutionCode}
                        >
                            <option value='' key=''/>
                            {this.state.hsmResolutionCodeList.map(element => (
                                <option value={element.code} key={element.code}>
                                    {element.shortName}
                                </option>
                            ))}
                        </AvField>
                    </AvGroup>

                    <AvGroup>
                        <Label for="confirmationStatus">
                            Teyit Durumu
                        </Label>
                        <AvField type="select" className="form-control" name="confirmationStatus"
                                 disabled={true}
                                 value={this.state.incident.confirmationStatus}>
                            <option value='' key=''/>
                            {INCIDENT_CONFIRMATION_STATUS_ALL.map(element => (
                                <option value={element.value} key={element.value}>
                                    {element.name}
                                </option>
                            ))}
                        </AvField>
                    </AvGroup>


                    <AvGroup>
                        <Label for="reopenCount">
                            Yeniden Açılma Sayısı
                        </Label>
                        <AvField type="text" className="form-control" name="reopenCount"
                                 disabled={true}
                                 value={this.state.incident.reopenCount}
                        />
                    </AvGroup>
                </Col>
                <Col md="4">
                    <HsmUserGroup
                        value={this.state.resolvedBy}
                        disabled={true}
                        validate={{
                            required: {
                                value: false,
                                errorMessage: 'Bu alanın doldurulması zorunludur.'
                            }
                        }}
                        label="Çözen"
                    />


                    <HsmUserGroup
                        value={this.state.closureBy}
                        disabled={true}
                        validate={{
                            required: {
                                value: false,
                                errorMessage: 'Bu alanın doldurulması zorunludur.'
                            }
                        }}
                        label="Teyit Veren"
                    />

                    <HsmUserGroup
                        value={this.state.reopenBy}
                        disabled={true}
                        validate={{
                            required: {
                                value: false,
                                errorMessage: 'Bu alanın doldurulması zorunludur.'
                            }
                        }}
                        label="Yeniden Açan"/>
                </Col>
                <Col md="4">
                    <div className="position-relative form-group">
                        <Label for="resolutionDate">
                            Çözüm Tarihi
                        </Label>
                        <DateInput
                            name="resolutionDate"
                            onChange={this.__handleChange}
                            disabled={true}
                            value={this.state.incident.resolutionDate}/>
                    </div>

                    <div className="position-relative form-group">
                        <Label for="closureDate">
                            Teyit Tarihi
                        </Label>
                        <DateInput
                            name="closureDate"
                            onChange={this.__handleChange}
                            disabled={true}
                            value={this.state.incident.closureDate}/>
                    </div>

                    <div className="position-relative form-group">
                        <Label for="reopenDate">
                            Yeniden Açılma Tarihi
                        </Label>
                        <DateInput
                            name="reopenDate"
                            onChange={this.__handleChange}
                            disabled={true}
                            value={this.state.incident.reopenDate}/>
                    </div>
                </Col>
            </Row>
        </Fieldset>;
    }

    getExternalTicketInfo = () => {
        if(this.state.externalTicket.extReference) {
            return <Fieldset legend={this.state.externalTicket.serviceName === 'SERDOO_TICKET' ? 'Serdoo Arıza Kayıtları' : this.state.externalTicket.serviceName === 'HITIT_TICKET' ? 'Hitit Arıza Kayıtları' : this.state.externalTicket.serviceName === 'PYS_TICKET' ? 'Pys Arıza Kayıtları' : 'SM Arıza Kayıtları'}>
            <Row>
                <Col md="4">
                    <Label for="extReference">
                        Referans No
                    </Label>
                    <AvGroup>
                        <div style={{position: "relative"}}>
                            <AvField type="text" className="form-control" name="extReference"
                                     disabled={true}
                                     value={this.state.externalTicket.extReference}
                            />
                            <Button color="primary"
                                    onClick={() => this.getExternalTicketInfoByReference(this.state.incident.id)}
                                    className="pi pi-info selectTableButton"
                                    disabled={!GeneralUtils.checkPrivilege(ExternalTicketColumn.name, 'ViewList')}
                                    size="sm"></Button>
                        </div>
                    </AvGroup>
                    <AvGroup>
                        <Label for="extSolutionCode">
                            Çözüm Kodu
                        </Label>
                        <AvField type="text" className="form-control" name="extSolutionCode"
                                 disabled={true}
                                 value={this.state.externalTicket.extSolutionCode}
                        />
                    </AvGroup>
                    <AvGroup>
                        <Label for="extClosureCode">
                            Kapanma Kodu
                        </Label>
                        <AvField type="text" className="form-control" name="extClosureCode"
                                 disabled={true}
                                 value={this.state.externalTicket.extClosureCode}
                        />
                    </AvGroup>
                </Col>
                <Col md="4">
                    <AvGroup>
                        <Label for="extStatus">
                            Durum
                        </Label>
                        <AvField type="text" className="form-control" name="extStatus"
                                 disabled={true}
                                 value={this.state.externalTicket.extStatus}
                        />
                    </AvGroup>
                    <div className="position-relative form-group">
                        <Label for="extResolvedDate">
                            Çözüm Tarihi
                        </Label>
                        <DateInput
                            name="extResolvedDate"
                            onChange={this.__handleChange}
                            disabled={true}
                            value={this.state.externalTicket.extResolvedDate}/>
                    </div>
                    <div className="position-relative form-group">
                        <Label for="extClosedDate">
                            Kapanma Tarihi
                        </Label>
                        <DateInput
                            name="extClosedDate"
                            onChange={this.__handleChange}
                            disabled={true}
                            value={this.state.externalTicket.extClosedDate}/>
                    </div>
                </Col>
                <Col md="4">
                    <div className="position-relative form-group">
                        <Label for="extCreatedDate">
                            Açılma Tarihi
                        </Label>
                        <DateInput
                            name="extCreatedDate"
                            onChange={this.__handleChange}
                            disabled={true}
                            value={this.state.externalTicket.extCreatedDate}/>
                    </div>
                    <div className="position-relative form-group">
                        <Label for="extUpdatedDate">
                            Güncellenme Tarihi
                        </Label>
                        <DateInput
                            name="extUpdatedDate"
                            onChange={this.__handleChange}
                            disabled={true}
                            value={this.state.externalTicket.extUpdatedDate}/>
                    </div>
                </Col>
            </Row>
        </Fieldset>;
            }

    }

    getAssignedInfo = () => {
        return <Fieldset legend="Atama Bilgileri">
            <Row>
                <Col md="4">
                    <AvGroup>
                        <Label for="assignedTo">
                            Atanan Kişi
                        </Label>
                        <AvField type="text" className="form-control" name="assignedTo" disabled={true}
                                 value={this.state.incident.assignedTo != null ? this.state.incident.assignedTo.givenNames + ' ' + this.state.incident.assignedTo.familyName : ''}/>{' '}
                    </AvGroup>


                    <div className="position-relative form-group">
                        <Label for="createdDate">
                            Talep Tarihi
                        </Label>

                        <DateInput
                            name="createdDate"
                            onChange={this.__handleChange}
                            disabled={true}
                            value={this.state.incident.createdDate}/>


                    </div>

                    <AvGroup>
                        <Label for="status">
                            Durum
                        </Label>
                        <AvField type="select" className="form-control" name="status" disabled={true}
                                 validate={{
                                     required: {
                                         value: true,
                                         errorMessage: 'Bu alanın doldurulması zorunludur.'
                                     }
                                 }}
                                 value={this.state.incident.status}
                                 style={(this.state.incident.status) === 'RESOLVED' ? {backgroundColor: "#87db72"} : null}
                        >

                            {INCIDENT_STATUS.map(element => (
                                <option value={element.value} key={element.value}>
                                    {element.name}
                                </option>
                            ))}
                        </AvField>
                    </AvGroup>

                </Col>

                <Col md="4">
                    <AvGroup>
                        <Label for="teamId">
                            Atanan Ekip
                        </Label>
                        <AvField type="text" className="form-control" name="teamId" disabled={true}
                                 value={this.state.incident.teamId != null ? this.state.incident.teamId.name : ''}/>{' '}
                    </AvGroup>

                    <div className="position-relative form-group">
                        <Label for="lastModifiedDate">
                            Güncelleme Tarihi
                        </Label>

                        <DateInput
                            name="lastModifiedDate"
                            onChange={this.__handleChange}
                            disabled={true}
                            value={this.state.incident.lastModifiedDate}/>
                    </div>

                    <AvGroup>
                        <Label for="statusReason">
                            Durum Nedeni
                        </Label>
                        <AvField type="select" className="form-control" name="statusReason" disabled={true}
                                 value={this.state.incident.statusReason}
                        >
                            <option value='' key=''/>
                            {INCIDENT_STATUS_REASON.map(element => (
                                <option value={element.value} key={element.value}>
                                    {element.name}
                                </option>
                            ))}
                        </AvField>
                    </AvGroup>
                </Col>

                <Col md="4">
                    <div className="position-relative form-group">
                        <Label for="assignmentDate">
                            Atama Tarihi
                        </Label>

                        <DateInput
                            name="assignmentDate"
                            onChange={this.__handleChange}
                            disabled={true}
                            value={this.state.incident.assignmentDate}/>
                    </div>

                    {this.state.incident.closureDate &&
                    <div className="position-relative form-group">
                        <Label for="closureDate">
                            Kapanma Tarihi
                        </Label>

                        <DateInput
                            name="closureDate"
                            onChange={this.__handleChange}
                            disabled={true}
                            value={this.state.incident.closureDate}/>
                    </div>}
                </Col>
            </Row>
        </Fieldset>;
    }

    getUrgencyInfo = () => {
        return <Fieldset legend="Öncelik Bilgileri">


            <AvGroup>
                <Label for="urgency">
                    Aciliyet
                </Label>
                <AvField type="select" className="form-control" name="urgency"
                         validate={{
                             required: {
                                 value: true,
                                 errorMessage: 'Bu alanın doldurulması zorunludur.'
                             }
                         }}
                         value={this.state.incident.urgency}
                         disabled={GeneralUtils.incidentButtonDisabled(this.state.pageMode, 'urgency')}
                         onChange={this.__handleChangeUrgencyAndImpact}>
                    {URGENCY_TYPE.map(element => (
                        <option value={element.value} key={element.value}>
                            {element.name}
                        </option>
                    ))}
                </AvField>
            </AvGroup>


            <AvGroup>
                <Label for="impact">
                    Etki
                </Label>
                <AvField type="select" className="form-control" name="impact"
                         validate={{
                             required: {
                                 value: true,
                                 errorMessage: 'Bu alanın doldurulması zorunludur.'
                             }
                         }}
                         disabled={GeneralUtils.incidentButtonDisabled(this.state.pageMode, 'impact')}
                         value={this.state.incident.impact}
                         onChange={this.__handleChangeUrgencyAndImpact}>
                    {IMPACT_TYPE.map(element => (
                        <option value={element.value} key={element.value}>
                            {element.name}
                        </option>
                    ))}
                </AvField>
            </AvGroup>


            <AvGroup>
                <Label for="priority">
                    Öncelik
                </Label>
                <AvField type="select" className="form-control" name="priority"
                         disabled={true}
                         validate={{
                             required: {
                                 value: true,
                                 errorMessage: 'Bu alanın doldurulması zorunludur.'
                             }
                         }}

                         value={this.state.incident.priority} onChange={this.__handleChange}>
                    {PRIORITY_TYPE.map(element => (
                        <option value={element.value} key={element.value}>
                            {element.name}
                        </option>
                    ))}
                </AvField>
            </AvGroup>


        </Fieldset>;
    }

    getDescription = () => {
        return <Fieldset legend="Arıza Detay Bilgileri">
            {/*<AvGroup>*/}
            {/*    <Label for="channel">*/}
            {/*        Kanal*/}
            {/*    </Label>*/}
            {/*    <AvField type="select" className="form-control" name="channel"*/}
            {/*             disabled={GeneralUtils.incidentButtonDisabled(this.state.pageMode, 'channel')}*/}
            {/*             value={this.state.incident.channel} onChange={this.__handleChange}>*/}
            {/*        <option value='' key=''/>*/}
            {/*        {CHANNEL_TYPE.map(element => (*/}
            {/*            <option value={element.value} key={element.value}>*/}
            {/*                {element.name}*/}
            {/*            </option>*/}
            {/*        ))}*/}
            {/*    </AvField>*/}
            {/*</AvGroup>*/}
            <AvGroup>
                <Label for="title">
                    Başlık
                    <span style={{color: 'red'}}> (*)</span>
                </Label>
                <AvField type="text" className="form-control" name="title" required
                         disabled={GeneralUtils.incidentButtonDisabled(this.state.pageMode, 'title')}
                         validate={{
                             required: {
                                 value: true,
                                 errorMessage: "Bu alanın doldurulması zorunludur."
                             },
                             maxLength: {
                                 value: 255,
                                 errorMessage: 'Bu alan en fazla 255 karakterden oluşabilir.'
                             }
                         }}
                         value={this.state.incident.title} onChange={this.__handleChange}/>
            </AvGroup>

            {this.state.displayComplaintType &&
            <AvGroup>
                <Label for="complaintTypeId">
                    Şikayet Tipi
                    <span style={{color: 'red'}}> (*)</span>
                </Label>
                <AvField type="select" className="form-control" name="complaintTypeId" required
                         disabled={GeneralUtils.incidentButtonDisabled(this.state.pageMode, 'complaintTypeId')}
                         value={!GeneralUtils.__isValueEmpty(this.state.incident.complaintTypeId) ? this.state.incident.complaintTypeId.id: null}
                         validate={{
                             required: {
                                 value: true,
                                 errorMessage: "Bu alanın doldurulması zorunludur."
                             }
                         }}
                         onChange={this.__handleChangeComplaintType}>
                    <option value='' key=''/>
                    {this.state.complaintTypeList.map(element => (
                        <option value={element.id} key={element.id}>
                            {element.name}
                        </option>
                    ))}
                </AvField>
            </AvGroup>
            }


            <AvGroup>
                <Label for="description">
                    Açıklama
                    <span style={{color: 'red'}}> (*)</span>
                </Label>
                <AvField type="textarea"
                         className="form-control textAreaHermesBig" name="description" required
                         disabled={GeneralUtils.incidentButtonDisabled(this.state.pageMode, 'description')}
                         validate={{
                             required: {
                                 value: true,
                                 errorMessage: "Bu alanın doldurulması zorunludur."
                             },
                             maxLength: {
                                 value: 2000,
                                 errorMessage: 'Bu alan en fazla 2000 karakterden oluşabilir.'
                             }
                         }}
                         value={this.state.incident.description}
                         onChange={this.__handleChange}
                         placeholder="Lütfen İletişime Geçme Sebebinizi Detaylı Bir Şekilde Açıklayınız."/>
            </AvGroup>


        </Fieldset>;
    }

    getContactInfo = () => {
        return <Fieldset legend="İletişim Bilgileri">
            <ContactGroup
                data={this.state.reportedByData}
                value={this.state.reportedBy}
                disabled={true}
                label="Raporlayan"/>

            <ContactGroup
                data={this.state.requestedByData}
                value={this.state.requestedBy}
                onDataSelect={this.onRequestedBySelect}
                dataTable={{
                    dataURL : "contact/findContactsInOrganizationPlusGuestAndReportedBy",
                    parameter: this.state.incident.reportedBy +'/' +this.state.incident.organizationId,
                    fields: ContactColumnForDialog.fields
                }}
                autoRefrestData={true}
                disabled={GeneralUtils.incidentButtonDisabled(this.state.pageMode, 'requestedBy')}
                label="Talep Sahibi"/>

            {/*{!GeneralUtils.incidentButtonDisabled(this.state.pageMode, 'addButton') &&*/}
            {/*<div className="position-relative form-group">*/}
            {/*<Label>Kontak Ekle</Label>*/}
            {/*<Button color="primary" className="pi pi-plus selectTableButton" size="sm" onClick={this.addNewContact}/>*/}
            {/*</div>}*/}

{/*            <AvGroup>
                <Label for="emailNotification">
                    Geri Bildirim İsteniyor mu?
                </Label>
                <AvField type="select" className="form-control" name="emailNotification"
                         validate={{
                             required: {
                                 value: true,
                                 errorMessage: 'Bu alanın doldurulması zorunludur.'
                             }
                         }}
                         disabled={true}
                         value={1}
                         onChange={this.__handleChangeEmailNotification}>
                    {YESNO.map(element => (
                        <option value={element.value} key={element.value}>
                            {element.name}
                        </option>
                    ))}
                </AvField>
            </AvGroup>*/}


            <AvGroup>
                <Label for="contactGivenNames">
                    Ad
                    <span style={{color: 'red'}}> (*)</span>
                </Label>
                <AvField
                    type="text"
                    className="form-control"
                    name="contactGivenNames"
                    disabled={this.state.emailNotificationHidden || GeneralUtils.incidentButtonDisabled(this.state.pageMode, 'contactGivenNames')}
                    validate={{
                        maxLength: {
                            value: 255,
                            errorMessage: "Bu alana en fazla 255 karakter girebilirsiniz."
                        },
                        required: {
                            value: true,
                            errorMessage: 'Bu alan zorunlu.'
                        }
                    }}
                    value={this.state.incident.contactGivenNames}
                    onChange={this.__handleChange}/>
                <AvFeedback>This field cannot be longer than 50 characters.</AvFeedback>
            </AvGroup>
            <AvGroup>
                <Label for="contactFamilyNames">
                    Soyad
                    <span style={{color: 'red'}}> (*)</span>
                </Label>
                <AvField
                    type="text"
                    className="form-control"
                    disabled={this.state.emailNotificationHidden || GeneralUtils.incidentButtonDisabled(this.state.pageMode, 'contactFamilyNames')}
                    name="contactFamilyNames"
                    validate={{
                        maxLength: {
                            value: 255,
                            errorMessage: "Bu alana en fazla 255 karakter girebilirsiniz."
                        },
                        required: {
                            value: true,
                            errorMessage: 'Bu alan zorunlu.'
                        }
                    }}
                    value={this.state.incident.contactFamilyNames}
                    onChange={this.__handleChange}/>
            </AvGroup>


            <AvGroup>
                <Label for="contactEmail">
                    E-posta
                    <span style={{color: 'red'}}> (*)</span>
                </Label>
                <AvField
                    name="contactEmail"
                    type="email"
                    disabled={this.state.emailNotificationHidden || GeneralUtils.incidentButtonDisabled(this.state.pageMode, 'contactEmail')}
                    validate={{
                        minLength: {
                            "value": 5,
                            "errorMessage": "Geçersiz E-posta Adresi."
                        },
                        maxLength: {
                            value: 50,
                            errorMessage: "Bu alana en fazla 50 karakter girebilirsiniz."
                        },
                        required: {
                            value: true,
                            errorMessage: 'Bu alan zorunlu.'
                        }
                    }}
                    value={this.state.incident.contactEmail} onChange={this.__handleChange}/>
            </AvGroup>


            <AvGroup>
                <Label for="contactPhone">
                    Telefon
                    <span style={{color: 'red'}}> (*)</span>
                </Label>
                <PhoneField kind={'any'}
                            disabled={this.state.pageMode === PAGE_MODE.VIEW || this.state.pageMode === PAGE_MODE.UPDATE}
                            name="contactPhone" errorMessage="Geçersiz Numara Girişi"
                            value={this.state.incident.contactPhone}
                            onChange={this.__handleChange}
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                }
                            }}
                />
            </AvGroup>

        </Fieldset>;
    }

    getIncidentInfo = () => {
        return <Fieldset legend="Arıza Bilgileri">
            <AvGroup>
                <Label for="serviceCategoryId">
                    Kategori
                </Label>
                <AvField type="select" className="form-control" name="serviceCategoryId"
                         value={this.state.incident.serviceCategoryId}
                         disabled={true}
                         onChange={this.__handleChangeServiceCategory}>
                    {this.state.serviceCategoryList.map(element => (
                        <option value={element.id} key={element.id}>
                            {element.name}
                        </option>
                    ))}
                </AvField>
            </AvGroup>


            <AvGroup>
                <Label for="serviceSubcategoryId">
                    Alt Kategori
                </Label>
                <AvField type="select" className="form-control" name="serviceSubcategoryId"
                         value={this.state.incident.serviceSubcategoryId}
                         disabled={true}
                         onChange={this.__handleChangeServiceSubcategory}>
                    {this.state.serviceSubcategoryAllList.map(element => (
                        <option value={element.id} key={element.id}>
                            {element.name}
                        </option>
                    ))}
                </AvField>
            </AvGroup>

            <Fieldset legend="Hizmet Numarası, Organizasyon, Lokasyon">
                <BbProductGroup
                    value={this.state.incident.serviceCategoryId == SERVICE_CATEGORY.TTVPN ? this.state.bbProduct : ""}
                    data={this.state.bbProductObject}
                    onDataSelect={this.onBbProductSelect}
                    disabled={true}
                    dataTable={{
                        dataURL: "bbProduct/findByContactIdAndStatus",
                        parameter: GeneralUtils.getContactId() + '/' + ['ACTIVE'],
                        fields: BbProductColumnForGroup.fields
                    }}
                    controlButton={!GeneralUtils.isNullOrEmpty(this.state.bbProductObject) && this.state.displayControlButton ? true : false}
                    getDeviceStatus={() => this.getDeviceStatus(this.state.bbProductObject)}
                    buttonStyle={{
                        backgroundColor: this.state.buttonColor,
                        loading: this.state.loadingButton,
                        buttonName: this.state.buttonName
                    }}
                />

                <OrganizationGroup
                    value={this.state.organization}
                    data={this.state.organizationObject}
                    onDataSelect={this.onOrganizationSelect}
                    dataTable={{
                        dataURL: "hsmOrganization/findByContactId",
                        parameter: GeneralUtils.getContactId(),
                        fields: HsmOrganizationColumnForDialog.fields
                    }}
                    disabled={true}
                />

                <LocationGroup value={this.state.incident.locationId}
                               optionList={this.state.locationList}
                               disabled={true}
                               onChange={this.__handleChangeLocation}/>
            </Fieldset>



        </Fieldset>;
    }

    addNewContact = () => {
        this.props.openContactDialog();
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let incident = this.state.incident;
        incident[e.target.name] = value;
        this.setState({incident: incident});
    }

    __handleChangeServiceCategory = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let incident = this.state.incident;
        incident[e.target.name] = value;
        incident.serviceSubcategoryId = null;
        incident.organizationId = null;
        incident.locationId = null;
        this.getSubCategoriesByCategoryId(value);
        this.setState({incident: incident, organization: null, organizationObject: null, locationList: [], bbProduct: null, bbProductObject: null});
        if (this.props.clearAsset) {
            this.props.clearAsset();
        }
    }

    getSubCategoriesByCategoryId = (categoryId) => {
        this.serviceSubategoryService.findIncidentSubCategories(categoryId).then(response => {
            let serviceSubategoryList = this.checkSubcategoryAccess(response);
            this.setState({serviceSubcategoryList: serviceSubategoryList, serviceSubcategoryAllList:response});
        });
    }

    __handleChangeServiceSubcategory = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let incident = this.state.incident;
        incident[e.target.name] = value;
        incident.organizationId = null;
        incident.locationId = null;
        for (var i = 0; i < this.state.serviceSubcategoryList.length; i++) {
            if (this.state.serviceSubcategoryList[i].id == value) {
                incident.title = this.state.serviceSubcategoryList[i].name;
            }
        }
        this.setState({incident: incident, organization: null, organizationObject: null, locationList: [], bbProduct: null, bbProductObject: null , displaySurvey: false});
        if (this.props.clearAsset) {
            this.props.clearAsset();
        }
    }

    __handleChangeComplaintType  = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let incident = this.state.incident;
        incident[e.target.name] = value;

        for (var i = 0; i < this.state.complaintTypeList.length; i++) {
            if (this.state.complaintTypeList[i].id == value) {
                incident.description = this.state.complaintTypeList[i].name;
                incident.complaintTypeId = this.state.complaintTypeList[i];
            }
        }
        this.setState({incident: incident});
    }

    __handleChangeLocation = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let incident = this.state.incident;
        incident[e.target.name] = value;
        this.setState({incident: incident});
        if (this.props.organizationId) {
            this.props.organizationId(this.state.incident.organizationId);
        }
        if (this.props.locationId) {
            this.props.locationId(value);
        }
        this.setBbProduct(this.state.incident.organizationId, value);
    }

    __handleChangeUrgencyAndImpact = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let incident = this.state.incident;
        incident[e.target.name] = value;
        incident.priority = GeneralUtils.findPriority(incident.urgency, incident.impact);
        this.setState({incident: incident});
    }

    __handleChangeEmailNotification = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let incident = this.state.incident;
        incident[e.target.name] = value;

        let reporterLocal = GeneralUtils.getContact();
        let reporterLocalGivenNameFamilyName = reporterLocal.givenNames +' '+ reporterLocal.familyName;
        let reporterChanged = this.state.requestedBy;

        if (reporterLocalGivenNameFamilyName == reporterChanged && value == 1){
            incident.contactEmail = reporterLocal.email;
            incident.contactFamilyNames = reporterLocal.familyName;
            incident.contactGivenNames = reporterLocal.givenNames;
            incident.contactPhone = reporterLocal.mobilePhone;
            this.setState({incident: incident, emailNotificationHidden: false});
        }
        else if (value == 1) {

            incident.contactEmail = incident.contactEmailTemp;
            incident.contactFamilyNames = incident.contactFamilyNamesTemp;
            incident.contactGivenNames = incident.contactGivenNamesTemp;
            incident.contactPhone = incident.contactPhoneTemp;
            this.setState({incident: incident, emailNotificationHidden: false});
        } else {
            incident.contactEmail = '';
            incident.contactFamilyNames = '';
            incident.contactGivenNames = '';
            incident.contactPhone = '';
            this.setState({incident: incident, emailNotificationHidden: true});
        }
        this.setState({incident: incident});
    }

    onBbProductSelect = (data) => {
        this.clearBbProduct();
        this.businessRuleService.checkIncident(ASSET_CATEGORY.BROADBAND_PRODUCT, data.id).then(response => {
            if (response === true) {
                let dataNew;
                this.bbProductService.findById(data.id).then(response => {
                    dataNew=response;

                    let incident = this.state.incident;
                    incident.organizationId = dataNew.organizationId.id;
                    incident.locationId = dataNew.locationId.id;
                    let organization = dataNew.organizationId.code + ' - ' + dataNew.organizationId.name;
                    this.setOrgAndLoc(dataNew.organizationId.id);
                    this.setState({
                        incident: incident,
                        organization: organization,
                        locationHidden: true,
                        organizationObject: dataNew.organizationId,
                        bbProduct:  dataNew.productType + ' - ' + dataNew.name,
                        bbProductObject: dataNew
                    });
                    this.service.findByOrgIdAndLocId(incident.organizationId,incident.locationId).then(response=>{
                        let incident = this.state.incident;
                        if(!GeneralUtils.isNullOrEmpty(response) && response.length > 0){
                            incident.contactEmail = response[0].contactEmail;
                            incident.contactPhone = response[0].contactPhone;
                            incident.contactGivenNames = response[0].contactGivenNames;
                            incident.contactFamilyNames = response[0].contactFamilyNames;
                        }else{
                            incident.contactEmail = "";
                            incident.contactPhone = "";
                            incident.contactGivenNames = "";
                            incident.contactFamilyNames = "";
                        }
                        this.setState({
                            incident
                        });
                    })
                    if (this.props.addAsset) {
                        this.props.addAsset(dataNew);
                    }
                    if (this.props.organizationId) {
                        this.props.organizationId(incident.organizationId);
                    }
                    if (this.props.locationId) {
                        this.props.locationId(incident.locationId);
                    }

                    this.getDefaultContact(dataNew);
                });
            }
        });
    }

    getDeviceStatus = (data)=>{
        let buttonColor = '#1E90FF';
        let buttonName = "Kontrol";
        this.setState({buttonColor: buttonColor, loadingButton:true, buttonName:buttonName});
        this.network360WebClientService.getDeviceStatus(data.serviceNumber).then((response) => {
            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                if (response.currentStatus === 0) {
                    buttonColor = '#ff0000';
                    buttonName = "Sorun Var";
                } else if(response.currentStatus === 1){
                    buttonColor = '#9CCC65';
                    buttonName = 'Sorun Yok'
                } else if(response.currentStatus === 3){
                    buttonColor = '#FFA500';
                    buttonName = 'Pasif'
                }
            }
            this.setState({buttonColor: buttonColor, loadingButton:false, buttonName:buttonName});
        });
    }


    getDefaultContact = (data) => {
        let contact;
        this.contactService.findByOrgIdAndCityIdAndRolId(data.organizationId.id, data.organizationId.cityId.id).then(response => {
            if (!GeneralUtils.isNullOrEmpty(response)) {
                contact=response;
                if (this.props.addContact) {
                    this.props.addContact(contact);
                }
            }
        });
    }

    clearBbProduct = () =>{
        let incident = this.state.incident;
        incident.organizationId = null;
        incident.locationId = null;
        this.setState({
            incident: incident,
            organization: null,
            organizationObject: null,
            locationList: [],
            bbProduct: null,
            bbProductObject: null,
            buttonColor: '#1E90FF',
            loadingButton: false,
            buttonName: 'Kontrol'
        });
        if (this.props.clearAsset) {
            this.props.clearAsset();
        }
        if (this.props.clearContact) {
            this.props.clearContact();
        }
    }

    onOrganizationSelect = (data) => {
        let incident = this.state.incident;
        incident.organizationId = data.id;
        incident.locationId = null;
        let organization = data.code + ' - ' + data.name;
        this.setOrgAndLoc(data.id);
        this.setState({
            incident: incident,
            organization: organization,
            locationHidden: false,
            organizationObject: data
        });
        if (this.props.clearAsset) {
            this.props.clearAsset();
        }
    }

    onRequestedBySelect = (data) => {
        let incident = this.state.incident;
        incident.requestedBy = data.id;
        let requestedBy = data.givenNames + ' ' + data.familyName;
        incident.contactGivenNamesTemp = data.givenNames;
        incident.contactFamilyNamesTemp = data.familyName;
        incident.contactEmailTemp = data.email;
        incident.contactPhoneTemp = data.mobilePhone;

        let emailNotificationHidden = true;
        let emailNotificationDisabled=true;

        if (data.id == GUEST_CONTACT_ID) {//misafir de geri bildirim zorunlu
            incident.emailNotification = 1;
            emailNotificationHidden = false;
            emailNotificationDisabled = true;

            incident.contactGivenNames = "";
            incident.contactFamilyNames = "";
            incident.contactEmail = "";
            incident.contactPhone = "";
        }else{
            incident.contactGivenNames = data.givenNames;
            incident.contactFamilyNames = data.familyName;
            incident.contactEmail = data.email;
            incident.contactPhone = data.mobilePhone;

        }

        this.setState({
            incident: incident,
            requestedByData: data,
            requestedBy: requestedBy,
            locationHidden: false,
            emailNotificationHidden: emailNotificationHidden,
            emailNotificationDisabled: emailNotificationDisabled
        });
    }

    setOrgAndLoc = (orgId) => {
        this.locationService.findByOrganizationId(orgId).then(response => {
                if (!GeneralUtils.isNullOrEmpty(response)) {
                    this.setState({locationList: response});
                }
            }
        )
    }

    setBbProduct = (organizationId, locationId) => {
        if (GeneralUtils.isNullOrEmpty(organizationId) || GeneralUtils.isNullOrEmpty(locationId)) {
            return;
        }
        this.bbProductService.findByOrganizationIdAndLocationIdAndStatus(organizationId, locationId, ['ACTIVE']).then(response => {
            if (GeneralUtils.isNullOrEmpty(response)) {
                // if(this.props.clearAsset){
                //     this.props.clearAsset();
                // }
                let incident = this.state.incident;
                incident.locationId = null;
                this.setState({incident: incident, locationList: []});
                return HermesSweetAlert.warn("Seçmiş olduğunuz lokasyonda kurulu/hizmet veren herhangi bir TTVPN ürünü bulunamamıştır!");
            }
            // if (this.props.addAsset) {
            //     this.props.addAsset(response);
            // }
        });
    }

    assigneeToMe = (response) => {

        let incident = this.state.incident;
        incident.assignedTo = response.assignedTo;
        incident.lastModifiedDate = response.lastModifiedDate;

        this.setState({
            incident: incident
        });

    }

    setData = (data, pageMode) => {
        this.clearData();
        let requestedBy = data.requestedBy ? data.requestedBy.givenNames + ' ' + (data.requestedBy.familyName ? data.requestedBy.familyName :"" ) : null;
        let reportedBy = data.reportedBy ? data.reportedBy.givenNames + ' ' + (data.reportedBy.familyName ? data.reportedBy.familyName : "" )  : null;
        let resolvedBy = data.resolvedBy ? data.resolvedBy.givenNames + ' ' + (data.resolvedBy.familyName ? data.resolvedBy.familyName : "" ) : null;
        let reopenBy = data.reopenBy ? data.reopenBy.givenNames + ' ' + (data.reopenBy.familyName ? data.reopenBy.familyName : "" )  : null;
        let closureBy = data.closureBy ? data.closureBy.givenNames + ' ' + ( data.closureBy.familyName ? data.closureBy.familyName : "" ) : null;

        let organization = data.organizationId ? (data.organizationId.code + ' - ' + data.organizationId.name) : null;
        let location = new Array(data.locationId);
        data.locationId = data.locationId ? data.locationId.id : null;
        data.serviceCategoryId = data.serviceCategoryId ? data.serviceCategoryId.id : null;
        data.serviceSubcategoryId = data.serviceSubcategoryId ? data.serviceSubcategoryId.id : null;
        data.complaintTypeId = data.complaintTypeId ? data.complaintTypeId : null;


        this.setState({
            incident: data,
            pageMode: pageMode,
            requestedByData: data.requestedBy,
            requestedBy: requestedBy,
            reportedByData: data.reportedBy,
            reportedBy: reportedBy,
            organization: organization,
            resolvedBy: resolvedBy,
            reopenBy: reopenBy,
            organizationObject: data.organizationId,
            closureBy: closureBy,
            locationList: location,
            displayContent: true
        });
        this.bbProductService.findByRequestTypeAndRequestId(REQUEST_TYPE.INCIDENT, data.id).then(response => {
            if(!GeneralUtils.isNullOrEmpty(response)) {
                response.assetCategory = ASSET_CATEGORY.BROADBAND_PRODUCT;
                this.setState({bbProduct: response.productType  + ' - ' + response.name, bbProductObject: response});
            } else {
                this.setState({bbProduct: null, bbProductObject: null});
            }
        });

        this.serviceSubategoryService.findIncidentSubCategories(data.serviceCategoryId).then(response => {
            let serviceSubategoryList = this.checkSubcategoryAccess(response);
            this.setState({serviceSubcategoryList: serviceSubategoryList, serviceSubcategoryAllList:response});
        });


        this.externalTicketService.findNewRecordByRequestTypeAndRequestId(REQUEST_TYPE.INCIDENT, data.id).then(response => {
            this.setState({externalTicket: !GeneralUtils.isNullOrEmpty(response) ? response : {}});
        });

    }

    getData = () => {
        return this.state.incident;
    }

    getSurveyResult = () => {
        return this.state.surveyResult;
    }

    setPageMode = (pageMode) => {
        this.setState({pageMode: pageMode});
    }

    setVersion = (version) => {
        let incident = this.state.incident;
        incident.version= version;
        this.setState({incident: incident})
    }

    getExternalTicketInfoByReference = (requestId) => {
        this.refExternalTicketDataTable.setParameter(REQUEST_TYPE.INCIDENT, requestId);
        this.setState({displayExternalTicketInfoDialog: true});
    }
    checkSubcategoryAccess = (serviceSubcategoryList) => {
        let subcategoryList = [];
        serviceSubcategoryList.forEach((element) => {
            if ((element.status=='A') ) {
                subcategoryList.push(element);
            }
        });
        return subcategoryList;
    }
}

NewIncident.propTypes = {
    displayOrganizationAndLocation: PropTypes.func,
    displaySurvey: PropTypes.func,
    surveyResult: PropTypes.func
};
