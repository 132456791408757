import React from 'react';
import DataTableForPivot from "../../../shared/component/dataTable/DataTableForPivot";
import DiscoveryReportService from "../../../shared/service/DiscoveryReportService";

const fieldList = {
    request_id: "Talep No",
    service_number: "Hizmet Numarası",
    city_name: "İl",
    tt_city_name: "TT İl",
    tt_region_name: 'Bölge',
    address: 'Adres',
    organization_name: 'Organizasyon Adı',
    location_code: 'Tesis Kodu',
    organization_code: 'Organizasyon Kodu',
    location_name: 'Lokasyon Adı',
    download_speed: 'İndirme Hızı',
    upload_speed: 'Yükleme Hızı',
    number_of_person: 'Kişi Sayısı'
}

class DiscoveryReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            selected: {},
            pageMode: null
        };
        this.service = new DiscoveryReportService();
    }

    componentDidMount = () => {
        this.findCrossTab();
    }

    render() {
        return (
            <DataTableForPivot ref={(el) => {
                this.refDataTableForPivot = el;
            }} data={this.state.data} loading={this.state.loading}
                               export={true} fieldList={fieldList}/>
        );
    }

    findCrossTab = () => {
        this.setState({loading: true});
        this.service.findCrossTab().then(response => {
            console.log(response.item)
            this.setState({data: response.item, displayDialog: false, loading: false})
            this.refDataTableForPivot.setData(response.item);
        });
    }
}

export default DiscoveryReport;
