import React from 'react';
import RequestWorknoteService from "../../shared/service/RequestWorknoteService";
import {AvForm, AvGroup} from "availity-reactstrap-validation";
import GeneralUtils from "../../shared/utils/GeneralUtils";
import AvField from "../../shared/component/form/AvField";
import {Button} from "reactstrap";
import {APP_TIMESTAMP_FORMAT, convertDateTimeWithFormat, PAGE_MODE} from "../../shared/utils/Constants";
import Comment from "../../shared/component/others/comment/Comment";

export default class RequestWorknote extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageMode: PAGE_MODE.CREATE,
            status: '',
            text: null,
            requestId: null,
            requestType: null
        };
        this.service = new RequestWorknoteService();
    }


    render() {
        let requestWorknote = (this.state.data.map((requestWorknote, i) => {
            return <Comment header={requestWorknote.createdBy.contactId.givenNames + ' ' + requestWorknote.createdBy.contactId.familyName}
                            date={convertDateTimeWithFormat(requestWorknote.createdDate, APP_TIMESTAMP_FORMAT)}
                            content={requestWorknote.text}/>;
        }));
        return (
            <div>
                {GeneralUtils.checkPrivilege('RequestWorknote', 'Create') &&
                <AvForm>
                    <AvGroup>
                        <AvField type="textarea" className="form-control textAreaHermes" name="text" required
                                 disabled={false}
                                 validate={{
                                     required: {
                                         value: false,
                                         errorMessage: "Bu alanın doldurulması zorunludur."
                                     },
                                     maxLength: {
                                         value: 4000,
                                         errorMessage: 'Bu alan en fazla 4000 karakterden oluşabilir.'
                                     }
                                 }}
                                 value={this.state.text} onChange={this.__handleChange}
                        />
                        <Button color="primary" type="submit" onClick={this.save}>
                            İş Notu Ekle
                        </Button>
                    </AvGroup>
                </AvForm>
                }
                {GeneralUtils.checkPrivilege('RequestWorknote', 'View') &&
                    requestWorknote
                }
            </div>
        );
    }

    __handleChange = (e) => {
        let state = this.state;
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        state[e.target.name] = value;
        this.setState(state);
    }


    save = () => {
        if (!GeneralUtils.isNullOrEmpty(this.state.requestId) && !GeneralUtils.isNullOrEmpty(this.state.text)) {
            let comment = {
                requestId: this.state.requestId,
                text: this.state.text,
                requestType: this.state.requestType,
                createdBy: {id: GeneralUtils.getUserId()}
            }
            this.service.create(comment).then((response) => {
                this.service.findByRequestTypeAndRequestId(this.state.requestType, this.state.requestId).then(response => {
                    this.clearData();
                    this.setState({data: response});
                });
            });
        }
    }

    setData = (data, requestType, pageMode, status) => {
        this.service.findByRequestTypeAndRequestId(requestType, data.id).then(response => this.setState({
            data: response,
            requestId: data.id,
            requestType: requestType,
            pageMode: pageMode,
            status: status
        }));
    }

    clearData = () => {
        this.setState({
            data: [],
            pageMode: null,
            text: null
        });
    }

    setPageModeAndStatus = (pageMode, status) => {
        this.setState({pageMode: pageMode, status: status});
    }

}
