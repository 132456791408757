import Service from './Service';
import GeneralUtils from "../utils/GeneralUtils";
import {SERVER_URL} from "../utils/Constants";

export default class BusinessRuleService extends Service {
    constructor() {
        super();
        this.baseURL = 'businessRule';
    }

    doesHaveActiveHsmUser(contactId) {
        return fetch(SERVER_URL + this.baseURL + '/doesHaveActiveHsmUser/' + contactId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        })
            .then(response => response.json())
            .catch(error => {
                GeneralUtils.notification(error);
            });
    }

    doesHaveBillingAccount(organizationId) {
        return fetch(SERVER_URL + this.baseURL + '/doesHaveBillingAccount/' + organizationId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        })
            .then(response => response.json())
            .catch(error => {
                GeneralUtils.notification(error);
            });
    }


    doesHaveOpenSRByOrgId(organizationId) {
        return fetch(SERVER_URL + this.baseURL + '/doesHaveOpenSRByOrgId/' + organizationId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        })
            .then(response => response.json())
            .catch(error => {
                GeneralUtils.notification(error);
            });
    }


    checkSR(serviceSubcategoryId, assetCategory, assetId) {
        return fetch(SERVER_URL + this.baseURL + '/checkSR/' + serviceSubcategoryId + '/' + assetCategory + '/' + assetId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        })
            .then(response => {
                let result = response.clone();
                GeneralUtils.notification(response, true);
                return result.json();
            })
            .catch(error => {
                GeneralUtils.notification(error);
            });
    }

    checkIncident( assetCategory, assetId) {
        return fetch(SERVER_URL + this.baseURL + '/checkIncident/' + assetCategory + '/' + assetId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        })
            .then(response => {
            let result = response.clone();
        GeneralUtils.notification(response, true);
        return result.json();
    })
    .catch(error => {
            GeneralUtils.notification(error);
    });
    }

    doesHaveServiceNumber(serviceNumber) {
        return fetch(SERVER_URL + this.baseURL + '/doesHaveServiceNumber/' + serviceNumber, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        })
            .then(response => response.json())
            .catch(error => {
                GeneralUtils.notification(error);
            });
    }

    doesHaveLocation(organizationId) {
        return fetch(SERVER_URL + this.baseURL + '/doesHaveLocation/' + organizationId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        })
            .then(response => response.json())
            .catch(error => {
                GeneralUtils.notification(error);
            });
    }


    doesHaveOpenSRByServiceSubcategory(serviceSubcategoryId, organizationId, locationId) {
        return fetch(SERVER_URL + this.baseURL + '/doesHaveOpenSRByServiceSubcategory/' + serviceSubcategoryId + '/' + organizationId + '/' + locationId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        })
            .then(response => response.json())
            .catch(error => {
                GeneralUtils.notification(error);
            });
    }

    doesHaveNoticeByType() {
        return fetch(SERVER_URL + this.baseURL + '/doesHaveNoticeByType', {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        })
            .then(response => response.json())
            .catch(error => {
                GeneralUtils.notification(error);
            });
    }
}