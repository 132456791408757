import React from 'react';
import {Panel} from 'primereact/panel';
import DataTable from "../../../shared/component/dataTable/DataTable";
import IncidentGeneralReportWithCityColumn from "../../../shared/component/dataTable/columns/IncidentGeneralReportWithCityColumn";
import IncidentReportService from "../../../shared/service/IncidentReportService";
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import {Button, Col, Label, Row} from "reactstrap";
import Translate from "../../../shared/language/Translate";
import {Calendar} from "primereact/calendar";
import {AvForm, AvGroup} from "availity-reactstrap-validation";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import {toast} from "react-toastify";
import AvField from "../../../shared/component/form/AvField";

const REPORT_TYPE = [
    {name: 'Bölge ve İl Bazlı Rapor', value: true},
    {name: 'Bölge Bazlı Rapor', value: false}
];
export default class IncidentGeneralReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            selected: {},
            pageMode: null,
            loading: false,
            dateStart: null,
            dateEnd: null,
            dateStartDate: null,
            dateEndDate: null,
            isExistCity: true,
            totalIncidentCount: '',
            avgTimePassedTextGeneral: '',
            slaAvgTimePassedTextGeneral: ''
        };
        this.service = new IncidentReportService();
    }


    render() {

        const tr = {
            firstDayOfWeek: 1,
            monthNames: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
            monthNamesShort: ['Oca', 'Şub', 'Mar', 'Nis', 'May', 'Haz', 'Tem', 'Ağu', 'Eyl', 'Eki', 'Kas', 'Ara'],
            dayNames: ['Pazartesi,', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi', 'Pazar'],
            dayNamesShort: ['Pzt', 'Sal', 'Çar', 'Prş', 'Cm', 'Cmr', 'Paz'],
            dayNamesMin: ['P', 'S', 'Ç', 'P', 'C', 'Ct', 'Pz'],
            today: ['Bugün'],
            clear: ['Temizle']
        };

        return (
            <Panel header="Genel Arıza Raporu">
                <AvForm onValidSubmit={this.submit}>
                    <Toolbar display={true}>
                        <div className="p-toolbar-group-right ">
                            <Button color="primary" type="submit" className="float-right">
                                <Translate>Göster</Translate>
                            </Button>
                        </div>
                    </Toolbar>
                    <div className="container-fluid">
                        <h3>Filtre</h3>
                        <div className="row">
                            <div className="col-4" style={{padding: 0}}>
                                <div style={{width: 300}}>
                                    <div className='p-grid p-fluid '>
                                        <Calendar
                                            tooltip="Başlangıç Tarihi"
                                            required={true}
                                            style={{margin: 20}}
                                            placeholder="Başlangıç Tarihi Seç"
                                            value={this.state.dateStart}
                                            onChange={e => this._onChangeDateStart(e)}
                                            dateFormat='dd/mm/yy'
                                            showButtonBar={true}
                                            readOnlyInput={true}
                                            showIcon={true}
                                            locale={tr}
                                            onClearButtonClick={e => this.setState({
                                                dateStart: "",
                                            })
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-4" style={{padding: 0}}>
                                <div style={{width: 300}}>
                                    <div className='p-grid p-fluid '>
                                        <Calendar
                                            tooltip="Bitiş Tarihi"
                                            required={true}
                                            style={{margin: 20}}
                                            placeholder="Bitiş Tarihi Seç"
                                            value={this.state.dateEnd}
                                            onChange={e => this._onChangeDateEnd(e)}
                                            dateFormat='dd/mm/yy'
                                            showButtonBar={true}
                                            readOnlyInput={true}
                                            showIcon={true}
                                            locale={tr}
                                            onClearButtonClick={e => this.setState({
                                                dateEnd: "",
                                            })
                                            }
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-4" className="position-relative form-group">
                                <div style={{width: 300}}>
                                    <div className='p-grid p-fluid '>
                                        <AvGroup>
                                            <Label for="isExistCity">
                                                Rapor Tipi
                                                <span style={{color: 'red'}}> (*)</span>
                                            </Label>
                                            <AvField
                                                type="select"
                                                // type="text"
                                                className="form-control"
                                                name="isExistCity"
                                                value={this.state.isExistCity}
                                                validate={{
                                                    required: {
                                                        value: "true",
                                                        errorMessage: "Bu alanın doldurulması zorunludur."
                                                    }
                                                }}
                                                onChange={this.__handleChange}>
                                                {REPORT_TYPE.map(element => (
                                                    <option value={element.value} key={element.value}>
                                                        {element.name}
                                                    </option>
                                                ))}
                                            </AvField>
                                        </AvGroup>


                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <Label>
                                Başlangıç ve Bitiş Tarihleri Arasında Kapatılan, Durumu "KAPALI" Olan ve SLA
                                Uygulanan Arızaların Raporudur.
                            </Label>
                        </div>
                    </div>

                </AvForm>

                    <DataTable ref={(elem) => this.refDataTable = elem}
                               fields={IncidentGeneralReportWithCityColumn.fields}
                               dataURL="incidentReport/findGeneralReportByStartDateAndEndDate"
                               objectName={IncidentGeneralReportWithCityColumn.name} export={true} autoLoad={false}
                               refresh={false}/>

                <Toolbar display={true}>
                        <Row>
                            <Col md="6"></Col>
                            <Col md="2">
                            <Label
                                for="totalIncidentCount">{!GeneralUtils.__isValueEmpty(this.state.totalIncidentCount) ? 'Toplam Arıza Sayısı:'+this.state.totalIncidentCount : ''} </Label>
                            </Col>
                            <Col md="2">
                            <Label
                                for="avgTimePassedTextGeneral">{!GeneralUtils.__isValueEmpty(this.state.avgTimePassedTextGeneral) ? 'Ortalama Çözüm Süresi:'+this.state.avgTimePassedTextGeneral : ''} </Label>
                            </Col>
                            <Col md="2">
                            <Label
                                for="slaAvgTimePassedTextGeneral">{!GeneralUtils.__isValueEmpty(this.state.slaAvgTimePassedTextGeneral) ? 'Ortalama SLA Zaman Aşımı:'+this.state.slaAvgTimePassedTextGeneral : ''}  </Label>
                            </Col>
                        </Row>
                </Toolbar>
            </Panel>
        );
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        this.setState({isExistCity: value});
    }

    submit = () => {
        let dateStart = this.state.dateStart;
        let dateEnd = this.state.dateEnd;
        let dateStartDate = this.state.dateStartDate;
        let dateEndDate = this.state.dateEndDate;
        let now = Date.now();

        if ((!GeneralUtils.isNullOrEmpty(dateStartDate) && !GeneralUtils.isNullOrEmpty(dateEndDate))) {
            if ((dateStartDate > now || dateEndDate > now)) {
                toast.warn("Başlangıç ve/veya Bitiş Tarihi Bugünün Tarihinde İleri Olamaz.");
                return;
            }
            if (dateStartDate > dateEndDate) {
                toast.warn("Başlangıç Tarihi Bitiş Tarihinden Sonra Olamaz.");
                return;
            }
        }

        if (GeneralUtils.isNullOrEmpty(dateStartDate) || GeneralUtils.isNullOrEmpty(dateEndDate)) {
            toast.warn("Lütfen Başlangıç ve Bitiş Tarihi Alanlarını Doldurunuz.");
            return;
        }
        this.refDataTable.setParameter(dateStart, dateEnd, this.state.isExistCity);
        this.getTotalValues(dateStart, dateEnd);
    }

    getTotalValues = (dateStart, dateEnd) => {
        this.service.findSumGeneralReportByStartDateAndEndDate(dateStart, dateEnd).then((response) => {
                this.setState({
                    totalIncidentCount: response.incidentCount,
                    avgTimePassedTextGeneral: response.avgTimePassedText,
                    slaAvgTimePassedTextGeneral: response.slaAvgTimePassedText
                });
        });
    }

    _onChangeDateStart = (value) => {
        let fullDate = this.getDate(value.value);

        this.setState({
            dateStart: fullDate, dateStartDate: value.value
        });
    }

    _onChangeDateEnd = (value) => {
        let fullDate = this.getDate(value.value);

        this.setState({
            dateEnd: fullDate, dateEndDate: value.value
        });
    }

    getDate(dateValue) {
        let date = new Date(dateValue);
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        return day + "-" + month + "-" + year;
    }

}
