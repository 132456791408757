import {SERVER_URL} from '../utils/Constants';
import GeneralUtils from "../utils/GeneralUtils";

export default class Service {
    constructor(props) {
        this.baseURL = props;
    }

    checkPage() {
        return fetch(SERVER_URL + this.baseURL + "/checkPage", {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")},
        }).then(response => {
            GeneralUtils.checkUnauthorized(response);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    getCount() {
        return fetch(SERVER_URL + this.baseURL + "/getCount", {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")},
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    findAll() {
        return fetch(SERVER_URL + this.baseURL, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")},
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    findById(id) {
        return fetch(SERVER_URL + this.baseURL + '/' + id, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")},
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    create(data) {
        return fetch(SERVER_URL + this.baseURL, {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")},
            body: JSON.stringify(GeneralUtils.addCreateInfo(data))
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response);
            return result.json();
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    update(data) {
        return fetch(SERVER_URL + this.baseURL, {
            crossDomain: true,
            method: 'put',
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")},
            body: JSON.stringify(GeneralUtils.addUpdateInfo(data))
        }).then(response => {
                let result = response.clone();
                GeneralUtils.notification(response);
                return result.json();
            }
        ).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    delete(id) {
        return fetch(SERVER_URL + this.baseURL + '/' + id, {
            crossDomain: true,
            method: 'delete',
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response);
            return result.json();
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

}