import React from 'react';
import {Button, Row} from 'reactstrap';
import {Panel} from "primereact/panel";
import DataTable from "../../../shared/component/dataTable/DataTable";
import HsmServiceColumn from "../../../shared/component/dataTable/columns/HsmServiceColumn.json"
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import Translate from "../../../shared/language/Translate";
import Dialog from "../../../shared/component/dialog/Dialog";
import Service from "../../../shared/service/Service";
import {PAGE_MODE} from "../../../shared/utils/Constants";
import HsmServiceSave from "./HsmServiceSave";
import {AvForm} from "availity-reactstrap-validation";
import {TabPanel, TabView} from "primereact/tabview";
import AuditEvent from "../../administration/AuditEvent";
import HsmAssetColumn from "../../../shared/component/dataTable/columns/HsmAssetColumn.json";


export default class HsmService extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageMode: null
        };
        this.service = new Service('hsmService');
    }

    componentDidMount = () => {
        this.service.checkPage();
    }

    render() {
        return (
            <Row className="justify-content-center">
                <Panel header="Hizmet Yönetimi">
                    <Toolbar display={true}>
                        <div className="p-toolbar-group-right">
                            <Button color="primary" onClick={this.addNew}>
                                <Translate contentKey="entity.action.add">Yeni Kayıt</Translate>
                            </Button>
                        </div>
                    </Toolbar>

                    <DataTable ref={(elem) => this.refDataTable = elem} fields={HsmServiceColumn.fields} dataURL="hsmService/findAll"
                               objectName={HsmServiceColumn.name}
                               selectionMode="single" onDataSelect={this.onDataSelect} export={true}/>

                    <Dialog visible={this.state.displayDialog}
                            header={GeneralUtils.getDialogHeader('Hizmet', this.state.pageMode)}
                            onHide={() => this.setState({displayDialog: false})} style={{width: '40%'}}>
                        <AvForm onValidSubmit={this.save}>
                            <Toolbar
                                display={true}>
                                <div className="p-toolbar-group-right">
                                    <Button color="primary" type="submit" className="float-right">
                                        <Translate contentKey="entity.action.save">Kaydet</Translate>
                                    </Button>
                                    <Button color="primary" className="float-right"
                                            onClick={() => this.setState({displayDialog: false})}>
                                        <Translate contentKey="entity.action.save">Vazgeç</Translate>
                                    </Button></div>

                            </Toolbar>

                            <TabView activeIndex={this.state.activeIndex}
                                     onTabChange={(e) => this.setState({activeIndex: e.index})}
                                     renderActiveOnly={false}>
                                <TabPanel header="Hizmet Bilgileri"
                                          rightIcon="pi pi-user">
                                    <HsmServiceSave ref={elem => this.refHsmServiceSave = elem}/>
                                </TabPanel>

                                <TabPanel header="Tarihçe" rightIcon="pi pi-user"
                                          disabled={this.state.pageMode === PAGE_MODE.CREATE}
                                          headerClassName={this.state.pageMode === PAGE_MODE.CREATE && 'displayNone'}>
                                    <AuditEvent ref={elem => this.refAuditEvent = elem}
                                                objectName={HsmAssetColumn.name}/>
                                </TabPanel>
                            </TabView>

                        </AvForm>
                    </Dialog>
                </Panel>
            </Row>
        );
    }

    refresh = () => {
        this.refDataTable.refresh();
    }

    addNew = () => {
        this.setState({displayDialog: true, pageMode: PAGE_MODE.CREATE});
        this.refHsmServiceSave.clearData();
    }

    onDataSelect = (data) => {
        let pageMode = GeneralUtils.getPageModeFromPrivilege(HsmServiceColumn.name);
        this.setState({displayDialog: true, pageMode: pageMode});
        this.refHsmServiceSave.clearData();
        this.refHsmServiceSave.setData(Object.assign({}, data), pageMode);
        this.refAuditEvent.setData(HsmServiceColumn.name, data.id);
    }

    save = () => {
        let hsmService = this.refHsmServiceSave.getData();
        if (!GeneralUtils.isNullOrEmpty(hsmService.id)) {
            this.service.update(hsmService).then((response) => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    this.setState({displayDialog: false});
                    this.refresh();
                }
            });
        } else {
            this.service.create(hsmService).then((response) => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    this.setState({displayDialog: false});
                    this.refresh();
                }
            });
        }
    }
}
