import React from 'react';
import {Button, Col, Label, Row} from 'reactstrap';
import {AvFeedback, AvForm, AvGroup} from 'availity-reactstrap-validation';
import PropTypes from 'prop-types';
import AvField from "../../../shared/component/form/AvField";
import Translate from "../../../shared/language/Translate";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import {toast} from "react-toastify";
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import HsmDocumentService from "../../../shared/service/HsmDocumentService";
import HsmDocumentPartColumn from '../../../shared/component/dataTable/columns/HsmDocumentPartColumn';
import {ATTACHED_DOCUMENT_STATUS, DOCUMENT_TYPE, PAGE_MODE} from "../../../shared/utils/Constants";
import {Fieldset} from "primereact/fieldset";
import DataTableAll from "../../../shared/component/dataTable/DataTableAll";
import DataModelForm from "../../../shared/component/form/DataModelForm";
import Loading from "../../../shared/component/others/Loading";
import Dialog from "../../../shared/component/dialog/Dialog";


const defaultAttachedDocument = {
    id: '',
    objectType: 'INCIDENT',
    objectId: '',
    documentId: '',
    partId: '',
    documentTitle: '',
    documentType: 'OTHER_FORM',
    documentDescription: '',
    fileName: '',
    fileType: '',
    fileSize: '',
    fileContent: ''
};

export default class AttachmentSave extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            attachedDocument: defaultAttachedDocument,
            pageMode: this.props.pageMode,
            buttonCounter: 1,
            fileMode: "single",
            isOnStart: true,
            hsmDocumentPart: [],
            parentObjectId: null,
            parentObjectType: null,
            isFileListOnUpdateMode: null,
            checkDocRequired: true,
            allDataAndFileArray : this.props.allDataAndFileArray
        }
        this.service = new HsmDocumentService();

    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({pageMode: nextProps.pageMode, allDataAndFileArray: nextProps.allDataAndFileArray});
    }

    render() {
        return (
            <Row className="justify-content-center">
                <Col md="12">
                    {(this.state.pageMode === PAGE_MODE.UPDATE || this.state.pageMode === PAGE_MODE.CREATE) &&
                    <Toolbar display={true}>
                        <div className="p-toolbar-group-right">
                            <Button color="primary" className="float-right" onClick={this.add}>
                                <Translate contentKey="entity.action.save">Yükle</Translate>
                            </Button>
                        </div>
                    </Toolbar>
                    }
                    {(this.state.attachedDocument.documentType === "LOCAL_MIGRATION_FORM" && this.state.attachedDocument.status === ATTACHED_DOCUMENT_STATUS.SUSPENDED) &&
                        <Toolbar display={true}>
                            <div className="p-toolbar-group-right">
                                <Button color="primary" className="float-right" onClick={() => this.updateStatus(ATTACHED_DOCUMENT_STATUS.AWAITING_APPROVAL)}>
                                    Onaya Gönder
                                </Button>
                            </div>
                        </Toolbar>
                    }
                    {(this.state.attachedDocument.documentType === "LOCAL_MIGRATION_FORM" && this.state.attachedDocument.status === ATTACHED_DOCUMENT_STATUS.AWAITING_APPROVAL) &&
                        <Toolbar display={true}>
                            <div className="p-toolbar-group-right">
                                <Button color="primary" className="float-right" onClick={() => this.updateStatus(ATTACHED_DOCUMENT_STATUS.APPROVED)}>
                                    Onayla
                                </Button>
                                <Button color="primary" className="float-right" onClick={() => this.setState({displayDialog: true})}>
                                {/*<Button color="primary" className="float-right" onClick={() => this.updateStatus("SUSPENDED")}>*/}
                                    Reddet
                                </Button>
                            </div>
                        </Toolbar>
                    }
                    <div>
                        <div>
                            <Row className="justify-content-center">
                                <Col md="10">
                                    <AvForm ref={(e) => this.refForm = e}>
                                        <AvGroup>
                                            <Label for="documentTitle">
                                                <Translate contentKey="attachedDocument.documentTitle">Başlık</Translate>
                                                <span style={{color: 'red'}}> (*)</span>
                                            </Label>
                                            <AvField
                                                disabled={this.state.pageMode === PAGE_MODE.VIEW ? true : false}
                                                type="text"
                                                className="form-control"
                                                name="documentTitle"
                                                validate={{
                                                    maxLength: {
                                                        value: 255,
                                                        errorMessage: "Bu alana en fazla 255 karakter girebilirsiniz."
                                                    }
                                                }}
                                                value={this.state.attachedDocument.documentTitle}
                                                onChange={this.__handleChange}
                                            />
                                        </AvGroup>
                                        <AvGroup>
                                            <Label for="documentType">
                                                <Translate contentKey="attachedDocument.documentType">Doküman Tipi</Translate>
                                            </Label>
                                            <AvField
                                                disabled={this.state.pageMode === PAGE_MODE.VIEW ? true : false}
                                                type="select" className="form-control" name="documentType"
                                                value={this.state.attachedDocument.documentType} onChange={this.__handleChange}>
                                                {DOCUMENT_TYPE.map(element => (
                                                    <option value={element.value} key={element.value}>
                                                        {element.name}
                                                    </option>
                                                ))}
                                            </AvField>
                                        </AvGroup>
                                        <AvGroup>
                                            <Label for="documentDescription">
                                                <Translate contentKey="attachedDocument.documentDescription">Açıklama</Translate>
                                            </Label>
                                            <AvField
                                                disabled={this.state.pageMode === PAGE_MODE.VIEW ? true : false}
                                                type="textarea"
                                                className="form-control textAreaHermes"
                                                name="documentDescription"
                                                validate={{
                                                    maxLength: {
                                                        value: 255,
                                                        errorMessage: "Bu alana en fazla 255 karakter girebilirsiniz."
                                                    }
                                                }}
                                                value={this.state.attachedDocument.documentDescription}
                                                onChange={this.__handleChange}
                                            />
                                        </AvGroup>

                                        {this.state.pageMode === PAGE_MODE.CREATE ? <div>
                                            <div>
                                                {this.state.isOnStart ? <Fieldset legend="Doküman">
                                                    <Row>
                                                        <Col>
                                                            <input ref={elem => this.refUploadInput = elem}
                                                                   type="file" name="uploadingFile" id="uploadingFile"
                                                                   onClick={()=>this.refUploadInput.value=null}
                                                                   multiple/>
                                                        </Col>
                                                    </Row>
                                                </Fieldset> : null}
                                            </div>
                                        </div> : <DataTableAll fields={HsmDocumentPartColumn.fields}
                                                               data={this.state.hsmDocumentPart} selectionMode="single"
                                                               privilege='allow'
                                                               onDataSelect={this.onDataSelectDownloadFile}
                                                               action={true}/>}
                                    </AvForm>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
                {this.state.loading && <Loading/>}

                <Row className="justify-content-center">
                    <Col md="8">
                        <Dialog
                            header={'Ret Açıklaması'} modal={true} resizable={true} visible={this.state.displayDialog}
                            maximizable={true} responsive={true} onHide={() => {
                            this.setState({displayDialog: false});
                        }}
                            style={{width: '50vw'}}>

                            <AvForm onValidSubmit={()=> {
                                this.updateStatus("SUSPENDED");
                                this.setState({displayDialog: false});
                            }}>
                                <Toolbar display={true}>
                                    <div className="p-toolbar-group-right">
                                        <Button color="primary" className="float-right" type="submit">
                                            Kaydet
                                        </Button>
                                        <Button color="primary" className="float-right"
                                                onClick={() => {
                                                    this.setState({displayDialog: false});
                                                }}>
                                            Vazgeç
                                        </Button>
                                    </div>
                                </Toolbar>


                                <AvGroup>
                                    <Label for="documentDescription">
                                        <Translate contentKey="description">Açıklama</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>
                                    <AvField
                                        type="textarea"
                                        className="form-control textAreaHermes"
                                        name="documentDescription"
                                        validate={{
                                            maxLength: {
                                                value: 1000,
                                                errorMessage: "Bu alana en fazla 1000 karakter girebilirsiniz."
                                            },
                                            required: {
                                                value: "true",
                                                errorMessage: "Bu alanın doldurulması zorunludur."
                                            }
                                        }}
                                        value={this.state.attachedDocument.documentDescription}
                                        onChange={this.__handleChange}
                                    />
                                </AvGroup>

                            </AvForm>
                        </Dialog>
                    </Col>
                </Row>
            </Row>
        );
    }

    handleChangeFile = (e) => {
        let value = e.target.files[0].name;
        let attachedDocument = this.state.attachedDocument;
        attachedDocument.name = value;
        this.setState({attachedDocument: attachedDocument});
    }

    downloadFileList = (documentId) => {
        this.service.downloadFileList(documentId).then(response => {
            this.setState({hsmDocumentPart: response});
        });
    }

    onDataSelectDownloadFile = (data) => {
        if(data.id === undefined){
            this.convertToDownloadableFile(data, data.fileType, data.fileName);
        }else {
            this.service.download(data.id).then(response => {
                this.convertToDownloadableFile(response, data.fileType, data.fileName);
            });
        }
        
    }

    convertToDownloadableFile = (response, fileType, fileName) => {
        var sampleArr = this.base64ToArrayBuffer(response.file);
        this.saveByteArray(fileName, sampleArr, fileType);
    }

    base64ToArrayBuffer = (base64) => {
        var binaryString = window.atob(base64);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    saveByteArray = (reportName, byte, mimeType) => {
        var blob = new Blob([byte], {type: mimeType});
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var fileName = reportName;
        link.download = fileName;
        link.click();
    };

    add = () => {
        let allDataAndFileArray = this.state.allDataAndFileArray;

        if (GeneralUtils.isNullOrEmpty(this.state.attachedDocument.documentTitle)) {
            return toast.warn("Zorunlu Alan : Lütfen Başlık Giriniz!");
        }

        let files = this.refUploadInput.files;

        if (files === undefined || files.length == 0) {
            if (this.state.checkDocRequired) {
                return toast.warn("Zorunlu Alan : Doküman ekleyiniz!");
            }
        }

        for (let i = 0 ; i < files.length; i++){
            if (files[i].size == 0) {
                return toast.warn("Dosyaların içeriği boş olmamalıdır!");
            }
            if (files[i].size > 4 * 1024 * 1024) {
                return toast.warn("Dosyaların boyutu, her dosya için 4 MB'den küçük olmalıdır!");
            }
        }


        if (files.length > 10) {
            return toast.warn("Döküman sayısı maximum 10 olmalıdır.");
        }
        let totalOfDocSizes =  this.getTotalDocSize(files);
        if (totalOfDocSizes > 20 * 1024 * 1024) {
            return toast.warn("Dosyaların toplam boyutu 20 MB'yi geçmemelidir!");
        }


        if(!GeneralUtils.__isValueEmpty(allDataAndFileArray) && allDataAndFileArray.length > 0){
            let hsmDocumentPartDTOList = allDataAndFileArray[0].data.hsmDocumentPartDTOList;
            if ((hsmDocumentPartDTOList.length + files.length) > 10) {
                return toast.warn("Dosyaların sayısı maximum 10 olmalıdır. Önceki yüklenen dosyaların sayısı : " + hsmDocumentPartDTOList.length);
            }
            let totalAllFileSizes = this.getTotalDocFileSize(hsmDocumentPartDTOList);
            let totalOfDocSizes = this.getTotalDocSize(files);
            if ((totalAllFileSizes + totalOfDocSizes) > 20 * 1024 * 1024) {
                return toast.warn("Dosyaların toplam boyutu 20 MB'yi geçmemelidir!. Önceki yüklenen dosyaların toplam boyutu : "+ GeneralUtils.convertByte(totalAllFileSizes) );
            }
        }

        let data = this.setHsmDocumentDatas();
        data.id = this.generateUUID();

        this.setState({
            loading:true
        });

        this.service.uploadFile(data,files).then(res=>{
            let attachedDocument = res;
            attachedDocument.isDeleted = true;
            attachedDocument.isView = true;
            this.props.add(files, attachedDocument);

            if (this.props.closeDisplayDialog) {
                this.props.closeDisplayDialog();
            }

            if(this.props.setDocumentId){
                this.props.setDocumentId(data.id);
            }
            this.refUploadInput.value=null;
            this.setState({
                loading:false
            });
        })
    }

    generateUUID = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    setHsmDocumentDatas = () => {
        let data = {
            id: this.state.attachedDocument.id,
            objectType: this.state.attachedDocument.objectType,
            objectId: this.state.attachedDocument.objectId,
            documentId: this.state.attachedDocument.documentId,
            partId: this.state.attachedDocument.partId,
            documentTitle: this.state.attachedDocument.documentTitle,
            documentType: this.state.attachedDocument.documentType,
            documentDescription: this.state.attachedDocument.documentDescription,
            fileName: this.state.attachedDocument.fileName,
            fileType: this.state.attachedDocument.fileType,
            fileSize: this.state.attachedDocument.fileSize,
            fileContent: this.state.attachedDocument.fileContent
        };
        if(this.state.attachedDocument.documentType === "LOCAL_MIGRATION_FORM"){
            data.status = "AWAITING_APPROVAL";
        }
        return data;
    }

    setData = (data, pageMode, documentParts) => {
        if (documentParts) {
            this.setState({
                attachedDocument: data,
                pageMode: pageMode,
                hsmDocumentPart: documentParts,
                isFileListOnUpdateMode: data.objectId,
                checkDocRequired: true
            });
        } else {
            this.downloadFileList(data.documentId);
            this.setState({attachedDocument: data, pageMode: pageMode, checkDocRequired: true});
        }
    }

    getFileName = (fileName) => {
        let pureText = fileName.split(".");
        return pureText[0];
    }

    getFileMimeType = (fileName) => {
        let pureText = fileName.split(".");
        return pureText[1];
    }

    clearData = () => {
        //radio buttonların sıfırlanması için kullanılıyor.
        this.refForm.reset();
        let obj = Object.assign({}, defaultAttachedDocument);
        //isOnStart popup açıldığında dosya seç ve dosya ekleme butonu gösterin visibilitysini ayarlıyor. ButtonCounter = 0 ilk açılışta choosefile gözükmemesi için yapıldı.
        this.setState({
            attachedDocument: obj,
            pageMode: null,
            isOnStart: true,
            buttonCounter: 1,
            hsmDocumentPart: null,
            checkDocRequired: true
        });
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let attachedDocument = this.state.attachedDocument;
        attachedDocument[e.target.name] = value;
        this.setState({attachedDocument: attachedDocument});
    }

    delete = (data) => {
        this.props.delete(data);
        this.setState({selected: {}});
        this.refForm.setData({});
        this.refForm.close();
    }

    getDataModelForm = () => {
        let result;
        if (this.props.save) {
            result = (<DataModelForm ref={(elem) => this.refForm = elem} save={this.save} delete={this.props.delete}
                                     inDialog={this.props.inDialog}/>);
        }
        return result;
    }

    getTotalDocSize = (files) => {
        let size=0;
        for (let i = 0 ; i < files.length; i++){
            size += files[i].size;
        }
        return size;
    }

    getTotalDocFileSize = (files) => {
        let size=0;
        files.forEach(file=>{
            size += file.fileSize;
        });
        return size;
    }

    updateStatus = (status) => {
        let data = {
            documentId: this.state.attachedDocument.documentId,
            status: status,
            documentDescription: (status === "SUSPENDED" ? this.state.attachedDocument.documentDescription : null)
        }
        this.service.updateStatus(data).then(()=>{
            if (this.props.closeDisplayDialog) {
                this.props.closeDisplayDialog();
            }
        });
    }
}

AttachmentSave.propTypes = {
    add: PropTypes.func,
    closeDisplayDialog: PropTypes.func,
    delete: PropTypes.func,
};
