import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'reactstrap';
import {
    ASSET_CATEGORY,
    GENERIC_BRAND_ID,
    GENERIC_MODEL_ID,
    ORDER_FORM_NAME,
    PAGE_MODE,
    REQUEST_TYPE,
    SERVICE_SUBCATEGORY
} from "../../shared/utils/Constants";
import {TabPanel, TabView} from "primereact/tabview";
import ServiceRequestService from "../../shared/service/ServiceRequestService";
import ServiceRequestNew from "./ServiceRequestNew";
import {AvForm} from "availity-reactstrap-validation";
import RequestAsset from "../request/RequestAsset";
import RequestContact from "../request/RequestContact";
import GeneralUtils from "../../shared/utils/GeneralUtils";
import RequestComment from "../request/RequestComment";
import RequestWorknote from "../request/RequestWorknote";
import Toolbar from "../../shared/component/toolbar/Toolbar";
import Attachment from "../request/attacheddocument/Attachment";
import {SplitButton} from "primereact/splitbutton";
import StatusChangeDialog from "../request/hsmDialog/StatusChangeDialog";
import AssignedDialog from "../request/hsmDialog/AssignedDialog";
import WorkFlowModelService from "../../shared/service/WorkFlowModelService";
import CloseDialogServiceRequest from "../request/hsmDialog/CloseDialogServiceRequest";
import Dialog from "../../shared/component/dialog/Dialog";
import TeamContactService from "../../shared/service/TeamContactService";
import HsmDocumentService from "../../shared/service/HsmDocumentService";
import DocumentCancellationPDFService from "../../shared/service/DocumentCancellationPDFService";
import DocumentTransferPDFService from "../../shared/service/DocumentTransferPDFService";
import DocumentServicePDFService from "../../shared/service/DocumentServicePDFService";
import HsmAsset from "../asset-management/asset/HsmAsset";
import HsmAssetService from "../../shared/service/HsmAssetService";
import AuditEvent from "../administration/AuditEvent";
import BillingApprovalDialog from "../request/hsmDialog/BillingApprovalDialog";
import Loading from "../../shared/component/others/Loading";
import ServiceRequestColumn from '../../shared/component/dataTable/columns/ServiceRequestColumn';
import Translate from "../../shared/language/Translate";
import ContactSave from "../account/contact/ContactSave";
import DocumentOrderXLSXService from "../../shared/service/DocumentOrderXLSXService";
import FlowGroup from "../../shared/component/hsmGroup/FlowGroup";
import BusinessRuleService from "../../shared/service/BusinessRuleService";
import HermesSweetAlert from "../../shared/component/sweetAlert/HermesSweetAlert";
import Task from "../request/task/Task";
import Event from "../administration/Event";
import PendingDialog from "../request/hsmDialog/PendingDialog";
import RequestSlt from "../request/RequestSlt";
import ModalConfirm from "../request/hsmDialog/ModalConfirm";
import Contact from "../account/contact/Contact";
import HsmOrganization from "../account/organization/HsmOrganization";
import LocationSave from "../asset-management/location/LocationSave";
import BbProductService from "../../shared/service/BbProductService";
import CloseDialogSRConnect from "../request/hsmDialog/CloseDialogSRConnect";

export default class ServiceRequest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            action: '',
            pageMode: PAGE_MODE.CREATE,
            status: '',
            isSameContact: true,
            isSameTeam: true,
            renderPage: true,
            items: [],
            disabledTabs: true,
            requestIdForForm: null,
            loading: false,
            newRecord: true,
            serviceSubcategoryId: 2,
            flowList: [],
            disabledEditButton: true,
            isAllowedTeam: false,
            disabledServiceRequestFollowTab: true,
            disabledResumeButton: true,
            taskLength:null,
            version: '',
            documentIdList:[]
        };
        this.service = new ServiceRequestService();
        this.workFlowModelService = new WorkFlowModelService();
        this.teamContactService = new TeamContactService();
        this.hsmDocumentService = new HsmDocumentService();
        this.documentOrderXLSXService = new DocumentOrderXLSXService();
        this.documentServicePDFService = new DocumentServicePDFService();
        this.documentCancellationPDFService = new DocumentCancellationPDFService();
        this.documentTransferPDFService = new DocumentTransferPDFService();
        this.hsmAssetService = new HsmAssetService();
        this.businessRuleService = new BusinessRuleService();
        this.bbProductService = new BbProductService();
    }

    componentDidMount = () => {
        this.service.checkPage();
    }

    render() {
        if (this.state.renderPage) {
            return (
                <div>
                    <div>
                        <AvForm onValidSubmit={this.checkAndSave}
                                beforeSubmitValidation={() => this.setState({activeIndex: 0})}>
                            <Toolbar display={true}>
                                {this.state.pageMode !== PAGE_MODE.CREATE &&
                                <div className="p-toolbar-group-left">
                                    <FlowGroup flowList={this.state.flowList} flowId={this.state.flowId}/>
                                </div>}
                                {/*<div className="p-toolbar-group-left">{this.state.serviceNumber}</div>*/}
                                <div className="p-toolbar-group-right" style={{paddingTop: 5}} >

                                    {this.buttonRender('cancelButton') &&
                                    <Button color="primary" onClick={this.props.closeDialog}>
                                        Vazgeç
                                    </Button>}

                                    {(this.state.status !== 'DRAFT' && this.buttonRender('saveButton')) &&
                                    <Button color="primary" type="submit" disabled={this.state.disabledTabs}>
                                        Kaydet
                                    </Button>}

                                    {(this.state.status !== 'DRAFT' && this.buttonRender('modifyButton')) &&
                                    <Button color="primary" onClick={this.modify}
                                            disabled={this.state.disabledEditButton}>
                                        Düzenle
                                    </Button>}

                                    {this.state.status === 'DRAFT' &&
                                    <Button color="primary" onClick={this.deleteDraft}>
                                        Taslağı Sil
                                    </Button>}

                                    {/*   {(!(this.state.status === 'NEW' || this.state.status === 'DRAFT' || this.state.status === 'CLOSED' || this.state.status === 'CANCELLED') && this.state.serviceCategoryIdForButton == SERVICE_SUBCATEGORY.TTVPN_CONNECT) &&
                                    <Button color="primary" onClick={this.showOrderForm}>
                                        Sipariş Formu Bastır
                                    </Button>}

                                    {(this.state.status === 'DRAFT' && this.state.serviceCategoryIdForButton == SERVICE_SUBCATEGORY.TTVPN_CONNECT) &&
                                    <Button color="primary" onClick={this.showServiceForm}>
                                        Hizmet Formu Bastır
                                    </Button>}

                                    {(this.state.status === 'DRAFT' && this.state.serviceCategoryIdForButton == SERVICE_SUBCATEGORY.TTVPN_CANCEL) &&
                                    <Button color="primary" onClick={this.showCancellationForm}>
                                        İptal Formu Bastır
                                    </Button>}

                                    {(this.state.status === 'DRAFT' && this.state.serviceCategoryIdForButton == SERVICE_SUBCATEGORY.TTVPN_TRANSFER) &&
                                    <Button color="primary" onClick={this.showTransferForm}>
                                        Devir Formu Bastır
                                    </Button>}*/}

                                    {this.state.status === 'DRAFT' &&
                                    <Button color="primary" type="submit">
                                        Talebi Yolla
                                    </Button>}

                                    {(this.state.status !== 'DRAFT' && this.buttonRender('assigneeButton')) &&
                                    <Button color="primary" onClick={this.showAssigneeDialog}>
                                        Ata
                                    </Button>}

                                    {(this.state.status !== 'DRAFT' && this.buttonRender('assigneeToMeButton')) &&
                                    <Button color="primary" onClick={this.assigneeToMe}>
                                        Kendime Ata
                                    </Button>}

                                    {this.buttonRender('resumeButton') &&
                                    <Button color="primary" onClick={this.resumeCheck}
                                            disabled={this.state.disabledResumeButton}>
                                        Askıdan İndir
                                    </Button>}

                                    {(this.state.status !== 'DRAFT' && this.buttonRender('splitButton')) &&
                                    <SplitButton ref={elem => this.refSplitButton = elem} label="İşlemler"
                                                 icon="pi pi-plus"
                                                 onClick={this.showSplitButtonMenu}
                                                 model={this.state.items} select={this.select}></SplitButton>}
                                </div>
                            </Toolbar>

                            <TabView activeIndex={this.state.activeIndex} renderActiveOnly={false}
                                     onTabChange={(e) => this.setState({activeIndex: e.index})}>

                                <TabPanel header="Talep Bilgisi">
                                    <ServiceRequestNew ref={elem => this.refServiceRequestNew = elem}
                                                       addAssetList={(dataList) => {
                                                           this.refRequestAsset.onDataListSelect(dataList);
                                                           // this.setState({assetList: dataList});
                                                       }}
                                                       addAsset={(data) => {
                                                           this.refRequestAsset.onDataSelect(data, false);
                                                       }}
                                                       clearAsset={this.clearAsset}
                                                       addContact={(data) => {this.refRequestContact.addContact(data, false);}}
                                                       clearContact={this.clearContact}
                                                       disabledTabs={() => this.setState({disabledTabs: false})}
                                                       organizationId={(organizationId) => this.setState({organizationId: organizationId})}
                                                       locationId={(locationId) => this.setState({locationId: locationId})}
                                                       serviceSubcategoryId={(serviceSubcategoryId) => this.setState({serviceSubcategoryId: serviceSubcategoryId})}
                                                       setTransfereeOrganizationId={(transfereeOrganizationId) => {
                                                           this.setState({transfereeOrganizationId: transfereeOrganizationId})
                                                       }}
                                                       productOfferingId={this.state.productOfferingId}
                                                       openContactDialog={() => {
                                                           this.refContactSave.clearData();
                                                           this.setState({displayContactDialog: true});
                                                       }}
                                                       createNewOrganization ={this.createNewOrganization}
                                                       createNewLocation ={this.createNewLocation}
                                    />
                                </TabPanel>
                                <TabPanel
                                    header={GeneralUtils.isNullOrEmpty(this.state.assetList) ? "Varlıklar" : "Varlıklar (" + this.state.assetList.length + ")"}
                                    rightIcon="pi pi-user"
                                    disabled={this.state.disabledTabs}
                                    headerClassName={this.state.disabledTabs && 'displayNone'}>
                                    {this.getRequestAsset()}
                                </TabPanel>
                                <TabPanel
                                    header={GeneralUtils.isNullOrEmpty(this.state.contactList) ? "Kişiler" : "Kişiler (" + this.state.contactList.length + ")"}
                                    rightIcon="pi pi-user"
                                    disabled={this.state.disabledTabs}
                                    headerClassName={this.state.disabledTabs && 'displayNone'}>
                                    {this.getRequestContact()}
                                </TabPanel>
                                <TabPanel
                                    header={GeneralUtils.isNullOrEmpty(this.state.attachmentLength) ? "Dokümanlar" : "Dokümanlar (" + this.state.attachmentLength + ")"}
                                    rightIcon="pi pi-user"
                                    disabled={this.state.disabledTabs}
                                    headerClassName={this.state.disabledTabs && 'displayNone'}>
                                    <Attachment ref={elem => this.refAttachedDocument = elem}
                                                status={this.state.status}
                                                setAttachmentLength={(length) => this.setState({attachmentLength: length})}
                                                setDocumentId={(documentId)=>{
                                                    let documentIdList = this.state.documentIdList;
                                                    documentIdList.push(documentId);
                                                    this.setState({documentIdList});
                                                }
                                                }
                                                deleteDocumentId={(documentId)=>{
                                                    let documentIdList = this.deleteDocumentId(documentId);
                                                    this.setState({
                                                        documentIdList
                                                    });
                                                }}
                                    />
                                </TabPanel>

                            </TabView>
                        </AvForm>
                        <br/><br/>
                        <TabView activeIndex={this.state.activeIndexCW} renderActiveOnly={false}
                                 style={{display: this.state.pageMode === PAGE_MODE.CREATE ? 'none' : ''}}
                                 onTabChange={(e) => this.setState({activeIndexCW: e.index})}>
                            <TabPanel header="Yorumlar" rightIcon="pi pi-user">
                                <RequestComment ref={elem => this.refRequestComment = elem}/>
                            </TabPanel>
                            <TabPanel header="İş Notları" rightIcon="pi pi-user">
                                <RequestWorknote ref={elem => this.refRequestWorknote = elem}/>
                            </TabPanel>
                            <TabPanel
                                header={GeneralUtils.isNullOrEmpty(this.state.taskLength) ? "Görevler" : "Görevler (" + this.state.taskLength + ")"}
                                rightIcon="pi pi-user"
                                disabled={this.state.pageMode === PAGE_MODE.CREATE || (this.state.serviceCategoryIdForButton != SERVICE_SUBCATEGORY.TTVPN_CONNECT &&
                                    this.state.serviceCategoryIdForButton != SERVICE_SUBCATEGORY.TTVPN_CONNECT_BLOCK && this.state.serviceCategoryIdForButton != SERVICE_SUBCATEGORY.TTVPN_WIFI_EQUIP_INSTALL &&
                                    this.state.serviceCategoryIdForButton != SERVICE_SUBCATEGORY.TTVPN_WIFI_EQUIP_UNINSTALL)}
                                headerClassName={(this.state.pageMode === PAGE_MODE.CREATE || (this.state.serviceCategoryIdForButton != SERVICE_SUBCATEGORY.TTVPN_CONNECT &&
                                    this.state.serviceCategoryIdForButton != SERVICE_SUBCATEGORY.TTVPN_CONNECT_BLOCK && this.state.serviceCategoryIdForButton != SERVICE_SUBCATEGORY.TTVPN_WIFI_EQUIP_INSTALL &&
                                    this.state.serviceCategoryIdForButton != SERVICE_SUBCATEGORY.TTVPN_WIFI_EQUIP_UNINSTALL)) && 'displayNone'}>
                                <Task ref={elem => this.refTask = elem}
                                      setLength={(length)=> this.setState({
                                          taskLength:length
                                      })
                                      }
                                      requestType={REQUEST_TYPE.SERVICE_REQUEST}
                                      serviceSubcategoryId={this.state.serviceSubcategoryId}
                                      createNewHsmAssetForInventory={this.createNewHsmAssetForInventory}
                                      refreshAssetList={this.refreshAssetList}
                                      version={(version)=>this.setState({version:version})}
                                />
                            </TabPanel>

                            <TabPanel
                                header={this.state.pageMode === PAGE_MODE.CREATE || GeneralUtils.isNullOrEmpty(this.state.requestSltList) ? "Hizmet Talebi Takip" : "Hizmet Talebi Takip (" + this.state.requestSltList.length + ")"}
                                disabled={this.state.disabledServiceRequestFollowTab}
                                headerClassName={this.state.disabledServiceRequestFollowTab && 'displayNone'}>
                                <RequestSlt ref={elem => this.refRequestSlt = elem}
                                            disabledServiceRequestFollowTab={(e) => this.setState({disabledServiceRequestFollowTab: e})}
                                            setRequestSltList={(requestSltList) => this.setState({requestSltList: requestSltList})}
                                />
                            </TabPanel>
                            <TabPanel header="Tarihçe" rightIcon="pi pi-user"
                                      disabled={this.state.pageMode === PAGE_MODE.CREATE}
                                      headerClassName={this.state.pageMode === PAGE_MODE.CREATE && 'displayNone'}>
                                <AuditEvent ref={elem => this.refAuditEvent = elem}
                                            objectName={ServiceRequestColumn.name}/>
                            </TabPanel>
                            <TabPanel header="Olay Kayıtları" rightIcon="pi pi-user"
                                      disabled={this.state.pageMode === PAGE_MODE.CREATE}
                                      headerClassName={this.state.pageMode === PAGE_MODE.CREATE && 'displayNone'}>
                                <Event ref={elem => this.refEvent = elem}
                                       objectName={ServiceRequestColumn.name}/>
                            </TabPanel>
                        </TabView>


                        <StatusChangeDialog ref={elem => this.refStatusChangeDialog = elem}
                                            statusChange={this.statusChange}/>

                        <AssignedDialog ref={elem => this.refAssignedDialog = elem} assigned={this.assigned} />

                        {/*<ResolveDialog ref={elem => this.refResolveDialog = elem} resolve={this.resolve} />*/}

                        <CloseDialogServiceRequest ref={elem => this.refCloseDialog = elem} close={this.close}
                                                   serviceSubcategoryId={this.state.serviceSubcategoryId} />

                        <CloseDialogSRConnect ref={elem => this.refCloseDialogSRConnect = elem} closeSRConnect={this.closeSRConnect} />

                        <BillingApprovalDialog ref={elem => this.refBillingApprovalDialog = elem} billingApproved={this.billingApproved}
                                               // serviceSubcategoryId={this.state.serviceSubcategoryId}
                        />

                        <PendingDialog ref={elem => this.refPendingDialog = elem} pending={this.pending} />


                        {/*<Dialog*/}
                        {/*header={GeneralUtils.isNullOrEmpty(this.state.assetList) ? "Varlıklar" : "Varlıklar (" + this.state.assetList.length + ")"}*/}
                        {/*modal={true} resizable={true} visible={this.state.displayAssetDialog}*/}
                        {/*maximizable={true} responsive={true} onHide={() => this.setState({displayAssetDialog: false})}*/}
                        {/*style={{width: '50vw'}}>*/}
                        {/*{this.getRequestAsset()}*/}
                        {/*</Dialog>*/}

                        <Dialog visible={this.state.displayHsmAssetDialog}
                                header={this.state.pageMode === PAGE_MODE.UPDATE ? 'Varlık Güncelleme' : 'Yeni Varlık Oluşturma'}
                                onHide={() => this.setState({displayHsmAssetDialog: false})}
                                style={{width: '50vw'}}>
                            <HsmAsset ref={elem => this.refHsmAsset = elem}
                                      closeDisplayDialog={() => this.setState({displayHsmAssetDialog: false})}
                                      setHsmAsset={this.setHsmAsset}/>
                        </Dialog>

                        <Dialog visible={this.state.displayHsmAssetDialogForInventory}
                                header={this.state.pageMode === PAGE_MODE.UPDATE ? 'Varlık Güncelleme' : 'Yeni Varlık Oluşturma'}
                                onHide={() => this.setState({displayHsmAssetDialogForInventory: false})}
                                style={{width: '50vw'}}>
                            <HsmAsset ref={elem => this.refHsmAssetForInventory = elem}
                                      closeDisplayDialog={() => this.setState({displayHsmAssetDialogForInventory: false})}
                                      setHsmAsset={this.setHsmAssetForInventory}/>
                        </Dialog>

                        <Dialog visible={this.state.displayNewContactDialog}
                                header={GeneralUtils.getDialogHeader('Kontak', this.state.pageMode)}
                                onHide={() => this.setState({displayNewContactDialog: false})}
                                style={{width: '50vw'}}>
                            <Contact ref={elem => this.refContact = elem}
                                     closeDisplayDialog={() => this.setState({displayNewContactDialog: false})}
                                     setNewContact={this.setNewContact} style={{width: '50vw'}}/>
                        </Dialog>


                        <Dialog visible={this.state.displayContactDialog}
                                header='Kontak Oluşturma'
                                modal={true} resizable={true}
                                maximizable={true} responsive={true}
                                onHide={() => this.setState({displayContactDialog: false})}
                                style={{width: '70vw'}}>
                            <AvForm onValidSubmit={this.submitContact}>
                                <Toolbar
                                    display={true}>
                                    <div className="p-toolbar-group-right">
                                        <div>
                                            <Button color="primary" type="submit" className="float-right">
                                                &nbsp;
                                                <Translate contentKey="entity.action.save">Kaydet</Translate>
                                            </Button>
                                            <Button color="primary" className="float-right"
                                                    onClick={() => this.setState({displayContactDialog: false})}>
                                                &nbsp;
                                                <Translate contentKey="entity.action.save">Vazgeç</Translate>
                                            </Button>
                                        </div>
                                    </div>
                                </Toolbar>

                                <ContactSave save={() => this.setState({displayContactDialog: false})}
                                             ref={(elem) => this.refContactSave = elem}
                                             closeDisplayDialog={() => this.setState({displayContactDialog: false})}/>
                            </AvForm>
                        </Dialog>
                        <Dialog visible={this.state.displayOrganizationSave}
                                header={this.state.pageMode === PAGE_MODE.UPDATE ? 'Organizasyon Güncelleme' : 'Yeni Organizasyon Oluşturma'}
                                onHide={() => this.setState({displayOrganizationSave: false})}
                                style={{width: '50vw'}}>
                            <HsmOrganization    ref={(elem) => this.refHsmNewOrganization = elem}
                                closeDisplayDialog={()=>
                                    this.setState({
                                    displayOrganizationSave:false
                                     })
                            }/>
                        </Dialog>

                        <Dialog visible={this.state.displayLocationSave}
                                header='Lokasyon Oluşturma'
                                modal={true} resizable={true}
                                maximizable={true} responsive={true}
                                onHide={() => this.setState({displayLocationSave: false})}
                                style={{width: '70vw'}}>
                            <AvForm onValidSubmit={this.submitLocation}>
                                <Toolbar
                                    display={true}>
                                    <div className="p-toolbar-group-right">
                                        <div>
                                            <Button color="primary" type="submit" className="float-right">
                                                &nbsp;
                                                <Translate contentKey="entity.action.save">Kaydet</Translate>
                                            </Button>
                                            <Button color="primary" className="float-right"
                                                    onClick={() => this.setState({displayLocationSave: false})}>
                                                &nbsp;
                                                <Translate contentKey="entity.action.save">Vazgeç</Translate>
                                            </Button>
                                        </div>
                                    </div>
                                </Toolbar>

                                <LocationSave  ref={(elem) => this.refNewLocation = elem}
                                    refresh={(location)=>{
                                        this.refServiceRequestNew.refresh(location);
                                    }}
                                    closeDisplayDialog={()=>
                                       this.setState({
                                           displayLocationSave:false
                                       })}
                                />
                            </AvForm>
                        </Dialog>

                    </div>

                    <ModalConfirm header='Uyarı' ok='Devam Et' onOkClick={this.resume}
                                  onCancelClick={() => this.setState({displayConfirmDialogResume: false})}
                                  show={this.state.displayConfirmDialogResume}
                                  message={this.state.noticeResponse}/>

                    <ModalConfirm header='Uyarı' ok='Devam Et' onOkClick={this.refreshScreen}
                                  show={this.state.displayConfirmDialogVersion}
                                  message={this.state.noticeResponse}/>
                    {this.state.loading && <Loading/>}
                </div>
            );
        } else {
            return '';
        }
    }

    submitContact = () => {
        this.refContactSave.checkAndSave();
    }

    submitLocation = () => {
        this.refNewLocation.save();
    }

    clearAsset = () => {
        this.setState({assetList: []});
        this.refRequestAsset.clearData();
    }

    getRequestAsset = () => {
        return <RequestAsset ref={elem => this.refRequestAsset = elem} requestType={REQUEST_TYPE.SERVICE_REQUEST}
                             organizationId={this.state.organizationId} locationId={this.state.locationId}
                             serviceSubcategoryId={this.state.serviceSubcategoryId}
                             setAssetList={(assetList) => {
                                 // let serviceNumber;
                                 // for (var i = 0; i < assetList.length; i++) {
                                 //     if (assetList[i].assetType == ASSET_TYPE.BROADBAND_PRODUCT) {
                                 //         serviceNumber = assetList[i].name;
                                 //     }
                                 // }
                                 // this.setState({assetList: assetList, serviceNumber: serviceNumber});
                                 this.setState({assetList: assetList});
                             }}
                             getAssetList={this.state.assetList} status={this.state.status}
                             createNewHsmAsset={this.createNewHsmAsset}
                             setProductOfferingId={(productOfferingId) => this.setState({productOfferingId: productOfferingId})}/>
    }

    getRequestContact = () => {
        return <RequestContact ref={elem => this.refRequestContact = elem} requestType={REQUEST_TYPE.SERVICE_REQUEST}
                               organizationId={this.state.organizationId} locationId={this.state.locationId}
                               transfereeOrganizationId={this.state.transfereeOrganizationId}
                               createNewContact={this.createNewContact}
                               setContactList={(contactList) => this.setState({contactList: contactList})}
                               getContactList={this.state.contactList}/>

    }

    showSplitButtonMenu = () => {
        this.refSplitButton.onDropdownButtonClick();
    }

    checkAndSave = () => {
        let serviceRequest = this.refServiceRequestNew.getData();
        let serviceRequestExt = this.refServiceRequestNew.getDataExt();
        let requestContactList = this.refRequestContact.getData();
        let requestAssetList = this.refRequestAsset.getData();
        let servedLocationList = null;
        let hsmSubnetList = null;
        let hsmSubnetRemovedList = null;

        if (serviceRequest.emailNotification == 1) {
            if (GeneralUtils.isNullOrEmpty(serviceRequest.contactGivenNames)) {
                return HermesSweetAlert.warn("Zorunlu Alan : Ad Giriniz!");
            }
            if (GeneralUtils.isNullOrEmpty(serviceRequest.contactFamilyNames)) {
                return HermesSweetAlert.warn("Zorunlu Alan : Soyad Giriniz!");
            }
            if (GeneralUtils.isNullOrEmpty(serviceRequest.contactEmail)) {
                return serviceRequest.warn("Zorunlu Alan : E-posta Seçiniz!");
            }
        }

        if (GeneralUtils.isNullOrEmpty(serviceRequest.description)) {
            return HermesSweetAlert.warn("Zorunlu Alan : Açıklama Giriniz!");
        }

        if (serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CONNECT |
            (GeneralUtils.isNullOrEmpty(serviceRequest.serviceSubcategoryId) && serviceRequest.serviceSubcategoryId.id == SERVICE_SUBCATEGORY.TTVPN_CONNECT)) {
            if (GeneralUtils.isNullOrEmpty(serviceRequest.id)) {
                serviceRequest.status = 'DRAFT';
            }
            else {
                if (!this.isExistsDataAndFile("SERVICE_FORM")) {
                    return HermesSweetAlert.warn("Talebiniz ile ilgili olarak ihtiyaç duyulan hizmet formunu imzalamanız ve imzaladığınız formu “Dokümanlar” sekmesi altında eklemeniz gerekmektedir.");
                }
            }
            servedLocationList = this.refServiceRequestNew.getDataServedLocationList();
            hsmSubnetList = this.refServiceRequestNew.getDataHsmSubnetList();
            hsmSubnetRemovedList = this.refServiceRequestNew.getDataHsmSubnetRemovedList();
        } else if (serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CANCEL |
            (GeneralUtils.isNullOrEmpty(serviceRequest.serviceSubcategoryId) && serviceRequest.serviceSubcategoryId.id == SERVICE_SUBCATEGORY.TTVPN_CANCEL)) {
            if (GeneralUtils.isNullOrEmpty(serviceRequest.id)) {
                serviceRequest.status = 'DRAFT';
            } else {
                if (!this.isExistsDataAndFile("CANCELLATION_FORM")) {
                    return HermesSweetAlert.warn("Talebiniz ile ilgili olarak ihtiyaç duyulan iptal formunu imzalamanız ve imzaladığınız formu “Dokümanlar” sekmesi altında eklemeniz gerekmektedir.");
                }
            }
            servedLocationList = this.refServiceRequestNew.getDataServedLocationList();
        } else if (serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_TRANSFER |
            (GeneralUtils.isNullOrEmpty(serviceRequest.serviceSubcategoryId) && serviceRequest.serviceSubcategoryId.id == SERVICE_SUBCATEGORY.TTVPN_TRANSFER)) {
            if (GeneralUtils.isNullOrEmpty(serviceRequest.id)) {
                if (serviceRequest.organizationId === serviceRequestExt.transfereeOrganizationId) {
                    return HermesSweetAlert.warn("Devreden ve devralan aynı organizasyon olamaz. Lütfen farklı bir organizasyon seçiniz.");
                }
                serviceRequest.status = 'DRAFT';
            } else {
                if (!this.isExistsDataAndFile("TRANSFER_FORM")) {
                    return HermesSweetAlert.warn("Talebiniz ile ilgili olarak ihtiyaç duyulan devir formunu imzalamanız ve imzaladığınız formu “Dokümanlar” sekmesi altında eklemeniz gerekmektedir.");
                }
            }
        } else if (serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CONNECT_BLOCK |
            (GeneralUtils.isNullOrEmpty(serviceRequest.serviceSubcategoryId) && serviceRequest.serviceSubcategoryId.id == SERVICE_SUBCATEGORY.TTVPN_CONNECT_BLOCK)) {
            servedLocationList = this.refServiceRequestNew.getDataServedLocationList();
            if (GeneralUtils.isNullOrEmpty(servedLocationList)) {
                return HermesSweetAlert.warn("Zorunlu Alan : Eklenecek Lokasyonları Seçiniz!");
            }
        } else if (serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_DISCONNECT_BLOCK |
            (GeneralUtils.isNullOrEmpty(serviceRequest.serviceSubcategoryId) && serviceRequest.serviceSubcategoryId.id == SERVICE_SUBCATEGORY.TTVPN_DISCONNECT_BLOCK)) {
            servedLocationList = this.refServiceRequestNew.getDataServedLocationList();
            if (GeneralUtils.isNullOrEmpty(servedLocationList)) {
                return HermesSweetAlert.warn("Zorunlu Alan : Kaldırılacak Lokasyonları Seçiniz!");
            }
        } else {
            if (!GeneralUtils.isExistsBbProduct(requestAssetList)) {
                return HermesSweetAlert.warn("Zorunlu Alan : Talebiniz ile ilgili bir ürün/hizmet seçilmedi! Lütfen ilgili ürünü/hizmeti “Varlıklar” sekmesi altında seçiniz.");
            }
        }


        let data = {
            serviceRequest: serviceRequest,
            serviceRequestExt: serviceRequestExt,
            requestContactList: requestContactList.map((element) => GeneralUtils.addCreateInfo(element)),
            requestAssetList: requestAssetList.map((element) => GeneralUtils.addCreateInfo(element)),
            servedLocationList: servedLocationList,
            hsmSubnetList: hsmSubnetList,
            hsmSubnetRemovedList: hsmSubnetRemovedList,
            documentIdList : this.state.documentIdList
        };

        //SR taslak ve id si varsa yani talebi yolla butonuna basılmışsa çalışır
        if(!GeneralUtils.isNullOrEmpty(serviceRequest.id) && serviceRequest.status == 'DRAFT'){
                if (serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CONNECT ||
                    (GeneralUtils.isNullOrEmpty(serviceRequest.serviceSubcategoryId) && serviceRequest.serviceSubcategoryId.id == SERVICE_SUBCATEGORY.TTVPN_CONNECT)) {
                    this.businessRuleService.doesHaveOpenSRByServiceSubcategory(serviceRequest.serviceSubcategoryId, serviceRequest.organizationId, serviceRequest.locationId,).then(response => {
                        if (response === true) {
                            return HermesSweetAlert.warn("Seçmiş olduğunuz genişbant ürünü üzerinde henüz kapanmamış bir hizmet talebi mevcuttur! Yeni bir hizmet talebi iletemezsiniz.");
                        } else {
                            this.bbProductService.findByOrganizationIdAndLocationIdAndStatus(serviceRequest.organizationId, serviceRequest.locationId, ['ACTIVE', 'INSTALLED']).then(response => {
                                if (!GeneralUtils.isNullOrEmpty(response)) {
                                    return HermesSweetAlert.warn("Seçmiş olduğunuz organizasyon ve lokasyon üzerinde bir ürün/hizmet vardır!");
                                } else {
                                    this.save(data);
                                }
                            });
                        }

                    });
                } else {
                    let bbProdcutId = GeneralUtils.getBbProductFromRequestAssetList(requestAssetList);
                    if (!GeneralUtils.isNullOrEmpty(bbProdcutId)) {
                        this.businessRuleService.checkSR(serviceRequest.serviceSubcategoryId, ASSET_CATEGORY.BROADBAND_PRODUCT, bbProdcutId).then(response => {
                            if (response === true) {
                                this.bbProductService.findByOrganizationIdAndLocationIdAndStatus(serviceRequest.organizationId, serviceRequest.locationId, GeneralUtils.getAssetStatus(serviceRequest.serviceSubcategoryId)).then(response => {
                                    if (GeneralUtils.isNullOrEmpty(response)) {
                                        return HermesSweetAlert.warn("Seçmiş olduğunuz organizasyon ve lokasyon üzerinde talebinize uygun bir ürün/hizmet yoktur!");
                                    } else {
                                        this.save(data);
                                    }
                                });
                            }
                        });
                    } else{
                        return HermesSweetAlert.warn("Seçmiş olduğunuz organizasyon ve lokasyon üzerinde talebinize uygun bir ürün/hizmet (Genişbant) yoktur!");
                    }
                }
        }else{
            this.save(data);
        }
    }

    isExistsDataAndFile = (documentType) => {
        let isExists = false;
            let allData = this.refAttachedDocument.getData();
            for (let i = 0; i < allData.length; i++) {
                if (allData[i].documentType === documentType) {
                    isExists = true;
                }
            }
        return isExists;
    }

    save(data) {
        this.setState({loading: true});

        if (GeneralUtils.isNullOrEmpty(data.serviceRequest.id)) {//save
            this.service.createServiceRequest(data).then((response) => {
                if (!GeneralUtils.isNullOrEmpty(response.serviceRequest) && !GeneralUtils.isNullOrEmpty(response.serviceRequest.id)) {
                    if (data.serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CONNECT || data.serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CANCEL ||
                        data.serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_TRANSFER) {
                        this.refServiceRequestNew.setId(response.serviceRequest.id);
                        this.refServiceRequestNew.setPageMode(PAGE_MODE.VIEW);
                        this.setState({
                            status: 'DRAFT',
                            serviceCategoryIdForButton: data.serviceRequest.serviceSubcategoryId,
                            requestIdForForm: response.serviceRequest.id
                        });
                        if(!GeneralUtils.isNullOrEmpty(response.hsmSubnetList)){
                            this.refServiceRequestNew.setDataHsmSubnetList(response.hsmSubnetList);
                        }
                    } else {
                        this.setState({renderPage: false});
                        //TODO : change link
                        window.location = '#/home';
                    }
                    this.callProps();
                    this.setState({loading: false});
                    return HermesSweetAlert.success(response.serviceRequest.id + " numarasıyla kaydınız oluşturulmuştur.");
                } else {
                    this.setState({loading: false});
                    GeneralUtils.showNotification(response);
                }
            });
        } else {//update

            data.serviceRequest.version = this.state.version;
            this.service.update(data).then((response) => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    if (!GeneralUtils.isNullOrEmpty(response.serviceRequest) && !GeneralUtils.isNullOrEmpty(response.serviceRequest.id)) {
                        this.callProps();
                        if (data.serviceRequest.status === 'DRAFT' && this.state.newRecord) {
                            this.setState({renderPage: false});
                            //TODO : change link
                            window.location = '#/home';
                        }
                    }
                    this.setState({loading: false});
                } else {
                    this.setState({
                        displayConfirmDialogVersion: true,
                        noticeResponse: response.message
                    });
                    this.setState({loading: false});
                }
            });
        }
    }

    callProps = () => {
        if (this.props.closeDialog) {
            this.props.closeDialog();
        }
        if (this.props.refresh) {
            this.props.refresh();
        }
    }

    setData = (data, pageMode) => {
        this.clearData();

        this.setState({
            teamId: data.teamId,
            pageMode: pageMode,
            status: data.status,
            organizationId: data.organizationId.id,
            locationId: data.locationId ? data.locationId.id : null,
            activeIndex: 0,
            activeIndexCW:0,
            disabledTabs: false,
            serviceCategoryId: data.serviceCategoryId ? data.serviceCategoryId.id : null,
            serviceSubcategoryId: data.serviceSubcategoryId ? data.serviceSubcategoryId.id : null,
            serviceCategoryIdForButton: data.serviceSubcategoryId ? data.serviceSubcategoryId.id : null,
            requestIdForForm: data.id,
            newRecord: false,
            flowId: data.flowId
        });

        let teamId = data.teamId != null ? data.teamId.id : null;
        let assignedTo = data.assignedTo != null ? data.assignedTo.id : null;
        this.getButtonList(data.serviceCategoryId.id, data.serviceSubcategoryId.id, data.flowId, teamId, assignedTo, data.status);

        this.workFlowModelService.findByCategoryIdAndSubcategoryId(data.serviceCategoryId.id, data.serviceSubcategoryId.id).then(response => {
            this.setState({flowList: response});
        });

        this.hsmDocumentService.findDocumentIdListByObjectTypeAndObjectId(REQUEST_TYPE.SERVICE_REQUEST,data.id).then(res=>{
           let documentIdList = res;
            this.setState({
                documentIdList
            });
        })


        this.refServiceRequestNew.setData(Object.assign({}, data), pageMode);

        this.refRequestContact.setData(data, REQUEST_TYPE.SERVICE_REQUEST, pageMode, this.state.status);

        this.refRequestAsset.setData(data, REQUEST_TYPE.SERVICE_REQUEST, pageMode, this.state.status);

        this.refAttachedDocument.setData(data, REQUEST_TYPE.SERVICE_REQUEST, pageMode, this.state.status);

        this.refRequestComment.setData(data, REQUEST_TYPE.SERVICE_REQUEST, pageMode, this.state.status);

        this.refRequestWorknote.setData(data, REQUEST_TYPE.SERVICE_REQUEST, pageMode, this.state.status);

        this.refRequestSlt.setData(data.id, REQUEST_TYPE.SERVICE_REQUEST);

        this.refAuditEvent.setData('ServiceRequest', data.id);
        this.refEvent.setData('ServiceRequest', data.id);

        if (data.serviceSubcategoryId.id == SERVICE_SUBCATEGORY.TTVPN_CONNECT || data.serviceSubcategoryId.id == SERVICE_SUBCATEGORY.TTVPN_CONNECT_BLOCK) {
            this.refTask.setData(data, REQUEST_TYPE.SERVICE_REQUEST, pageMode, null);
        }
    }

    getButtonList=(serviceCategoryId, serviceSubcategoryId, flowId, teamId, assignedTo, status)=> {
        let userContactId = GeneralUtils.getContactId();

        var isSameContact = false;
        var isSameTeam = false;

        if (assignedTo == userContactId) {
            isSameContact = true;
        }

        this.teamContactService.findTeamsByContactId(userContactId).then(response => {

            let teamIdList = [];
            if (!GeneralUtils.isNullOrEmpty(response)) {
                for (var i = 0; i < response.length; i++) {
                    if (response[i].teamId.id == teamId) {
                        isSameTeam = true;
                    }
                    teamIdList.push(response[i].teamId.id);
                }
            }

            this.setState({
                isSameContact: isSameContact,
                isSameTeam: isSameTeam,
                disabledEditButton: true,
                disabledResumeButton: true
            });

            if (!GeneralUtils.isNullOrEmpty(teamIdList)) {
                this.workFlowModelService.findByCategoryAndSubcategoryAndFlowIdAndTeamIdList(serviceCategoryId, serviceSubcategoryId, flowId, teamIdList).then(response => {
                    if (!GeneralUtils.isNullOrEmpty(response)) {
                        this.setState({isAllowedTeam: true})
                    }
                });
            }

            if (!GeneralUtils.isNullOrEmpty(teamIdList) && !GeneralUtils.isNullOrEmpty(teamId)) {
                this.workFlowModelService.getSplitButtonItems(serviceCategoryId, serviceSubcategoryId, flowId, teamIdList).then(response => {
                    this.addCommandToSplitButtons(response);
                });
            }

            if (GeneralUtils.isNullOrEmpty(teamId) && status !== 'DRAFT') {
                this.workFlowModelService.getSplitButtonItemsTeamIdIsNull(serviceCategoryId, serviceSubcategoryId, flowId).then(response => {
                    this.addCommandToSplitButtons(response);
                });
            }

        });
    }

    clearData = () => {
        this.setState({
            // serviceNumber: null,
            assetList: [],
            contactList: null,
            taskList: [],
            flowList: [],
            disabledEditButton: true,
            disabledResumeButton: true,
            isAllowedTeam: false,
            loading:false
        });
    }


    modify = () => {

        this.setState({pageMode: PAGE_MODE.UPDATE});
        this.refServiceRequestNew.setPageMode(PAGE_MODE.UPDATE);

        this.refRequestContact.setPageModeAndStatus(PAGE_MODE.UPDATE, this.state.status);

        this.refRequestAsset.setPageModeAndStatus(PAGE_MODE.UPDATE, this.state.status);

        this.refAttachedDocument.setPageModeAndStatus(PAGE_MODE.UPDATE, this.state.status);

        this.refRequestComment.setPageModeAndStatus(PAGE_MODE.UPDATE, this.state.status);

        this.refRequestWorknote.setPageModeAndStatus(PAGE_MODE.UPDATE, this.state.status);

        this.refTask.setPageModeAndStatus(PAGE_MODE.UPDATE, this.state.status);

    }

    resumeCheck = () => {
        this.setState({
            displayConfirmDialogResume: true,
            noticeResponse: " Talebi askıdan indirmek üzeresiniz. Devam etmek istiyor musunuz? "
        });
    }

    resume = () => {
        let serviceRequest = this.refServiceRequestNew.getData();
        let data = {
            requestId: serviceRequest.id,
            version: serviceRequest.version,
            status: 'RESUME',
            description: null,
            lastModifiedBy: GeneralUtils.getUserId()
        }
        this.trigger(data);
    }

    showAssigneeDialog = () => {
        this.refAssignedDialog.setDialogAndTeamId(true, this.state.teamId, this.state.serviceCategoryId, this.state.serviceSubcategoryId, this.state.flowId,true);
    }

    assigned = () => {

        let serviceRequest = this.refServiceRequestNew.getData();
        let assignedServiceRequest = this.refAssignedDialog.getData();

        let data = {
            requestId: serviceRequest.id,
            version: serviceRequest.version,
            status: 'ASSIGNED',
            description: null,
            lastModifiedBy: GeneralUtils.getUserId(),
            assignedToContactId: assignedServiceRequest.assignedToContactId,
            teamId: assignedServiceRequest.teamId
        }
        this.trigger(data);
    }

    assigneeToMe = () => {
        let serviceRequest = this.refServiceRequestNew.getData();
        let data = {
            requestId: serviceRequest.id,
            version: serviceRequest.version,
            status: 'ASSIGNEDTOME',
            description: null,
            lastModifiedBy: GeneralUtils.getUserId()
        }

        this.setState({loading: true});

        this.service.trigger(data).then((response) => {
            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                this.setState({isSameContact: true});
                this.refServiceRequestNew.setVersion(response.version);
                this.refServiceRequestNew.assigneeToMe(response);
                if (this.props.refresh) {
                    this.props.refresh();
                }
            } else {
                this.setState({
                    displayConfirmDialogVersion: true,
                    noticeResponse: response.message
                });
            }
            this.setState({loading: false});
        });
    }

    billingApproved = () => {
        let serviceRequest = this.refServiceRequestNew.getData();
        let billingApprovalDialog = this.refBillingApprovalDialog.getData();

        let data = {
            requestId: serviceRequest.id,
            version: serviceRequest.version,
            status: this.state.action,
            description: billingApprovalDialog.description,
            billingApproved: billingApprovalDialog.billingApprovedTF,
            smartBoardExist : billingApprovalDialog.smartBoardExist,
            lastModifiedBy: GeneralUtils.getUserId()
        }
        this.trigger(data);
    }

    closeSRConnect = () => {
        let serviceRequest = this.refServiceRequestNew.getData();
        let closeDialogSRConnect = this.refCloseDialogSRConnect.getData();

        let data = {
            requestId: serviceRequest.id,
            version: serviceRequest.version,
            status: this.state.action,
            description: closeDialogSRConnect.description,
            ratingDate : closeDialogSRConnect.ratingDate,
            lastModifiedBy: GeneralUtils.getUserId()
        }
        this.trigger(data);
    }

    statusChange = () => {
        let serviceRequest = this.refServiceRequestNew.getData();
        let statusChangeServiceRequest = this.refStatusChangeDialog.getData();

        let data = {
            requestId: serviceRequest.id,
            version: serviceRequest.version,
            status: this.state.action,
            description: statusChangeServiceRequest.description,
            lastModifiedBy: GeneralUtils.getUserId()
        }
        this.trigger(data);
    }

    pending = () => {
        let serviceRequest = this.refServiceRequestNew.getData();
        let pendingServiceRequest = this.refPendingDialog.getData();

        let data = {
            requestId: serviceRequest.id,
            version: serviceRequest.version,
            status: this.state.action,
            description: pendingServiceRequest.description,
            lastModifiedBy: GeneralUtils.getUserId(),
            statusReason: pendingServiceRequest.statusReason
        }
        this.trigger(data);
    }

    // resolve = () => {
    //     let serviceRequest = this.refServiceRequestNew.getData();
    //     let resolveServiceRequest = this.refResolveDialog.getData();
    //
    //     let data = {
    //         requestId: serviceRequest.id,
    //         version: serviceRequest.version,
    //         status: this.state.action,
    //         description: resolveServiceRequest.description,
    //         lastModifiedBy: GeneralUtils.getUserId(),
    //         resolutionCode: resolveServiceRequest.resolutionCode
    //     }
    //     this.trigger(data);
    // }

    close = () => {
        let serviceRequest = this.refServiceRequestNew.getData();
        let closeServiceRequest = this.refCloseDialog.getData();
        // if (serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CANCEL || serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_TRANSFER
        // || serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CHANGE_TITLE || serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_BILLING_APPROVAL){
        //     if(closeServiceRequest.resolutionCode !== 'RC-1001'){
        //         return HermesSweetAlert.warn("Bu hizmet talebini sadece 'Tamamlandı' olarak kapatabilirsiniz.");
        //     }
        // }
        if (serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CONNECT && closeServiceRequest.resolutionCode === 'RC-1001') {
            let serviceRequestExt = this.refServiceRequestNew.getDataExt();
            if (GeneralUtils.isNullOrEmpty(serviceRequestExt.serviceNumber)) {
                return HermesSweetAlert.warn("Zorunlu Alan : Hizmet Numarası Giriniz!");
            }
            // let requestAssetList = this.refRequestAsset.getData();
            // if (!GeneralUtils.isExistsNetworkDevice(requestAssetList)) {
            //     return HermesSweetAlert.warn("Zorunlu Alan : Talebiniz ile ilgili bir ağ cihazı seçilmedi! Lütfen ilgili ağ cihazını “Varlıklar” sekmesi altında seçiniz.");
            // }
            // if (this.checkBrandAndModelInNetworkDevice(requestAssetList)) {
            //     return HermesSweetAlert.warn("Lütfen “Varlıklar” sekmesi altında ilgili ağ cihazının marka ve modelini seçiniz.");
            // }
            if (!this.isExistsDataAndFile("SERVICE_FORM")) {
                return HermesSweetAlert.warn("Talebiniz ile ilgili olarak ihtiyaç duyulan hizmet formunu imzalamanız ve imzaladığınız formu “Dokümanlar” sekmesi altında eklemeniz gerekmektedir.");
            }
            let taskList = this.refTask.getData();
            if (GeneralUtils.isExistsOpenTask(taskList)) {
                return HermesSweetAlert.warn("Lütfen açık durumda olan Görevleri kapatınız.");
            }
        }

        let data = {
            requestId: serviceRequest.id,
            version: serviceRequest.version,
            status: this.state.action,
            description: closeServiceRequest.description,
            lastModifiedBy: GeneralUtils.getUserId(),
            resolutionCode: closeServiceRequest.resolutionCode,
            ratingDate: closeServiceRequest.ratingDate
        }
        this.trigger(data);
    }

    trigger=(data)=> {
        this.setState({loading: true});

        this.service.trigger(data).then((response) => {
            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                this.props.refresh();
                this.props.closeDialog();
            } else {
                this.setState({
                    displayConfirmDialogVersion: true,
                    noticeResponse: response.message
                });
            }
            this.setState({loading: false});
        });
    }

    checkBrandAndModelInNetworkDevice = (requestAssetList) => {
        let isExistsGeneric = false;
        for (let asset of requestAssetList) {
            if (asset.assetCategory === ASSET_CATEGORY.NETWORK_DEVICE) {
                this.hsmAssetService.findById(asset.id).then(response => {
                    if (response.brandId === GENERIC_BRAND_ID || response.modelId === GENERIC_MODEL_ID) {
                        isExistsGeneric = true;
                    }
                });
            }
        }
        return isExistsGeneric;
    }

    addCommandToSplitButtons(response) {
        let me = this;
        let items = Object.assign([]);
        response.map((el) => {
            var o = Object.assign({}, el);
            if (GeneralUtils.checkPrivilege('ServiceRequest', o.value)) {
                o.command = (e) => {
                    me.buttonActions(e);
                }
            }
            o.disabled = !GeneralUtils.checkPrivilege('ServiceRequest', o.value);
            if (o.visible != false) {
                items.push(o);
            }
            // Edit için yapıldı
            if (o.value == 'Edit') {
                this.setState({disabledEditButton: false});
            }

            // Askıdan indir işlemi için yapıldı
            if (o.value == 'Resume') {
                this.setState({disabledResumeButton: false});
            }

            return o;
        });
        this.setState({items: items})
    }

    buttonActions = (e) => {
        let label = e.item.label;
        switch (label) {
            case "Talebi Onayla":
                let serviceRequest = this.refServiceRequestNew.getData();
                if ((serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CONNECT && serviceRequest.status == 'AWAITING_APPROVAL' && serviceRequest.statusReason == 'POST_INSTALL_APPROVAL') ||
                    (serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_BILLING_APPROVAL && serviceRequest.status == 'AWAITING_APPROVAL' && serviceRequest.statusReason == 'BILLING_APPROVAL')) {
                    this.refBillingApprovalDialog.setLocationId(serviceRequest.locationId);
                    this.refBillingApprovalDialog.setDialog(true);
                    this.setState({action: 'APPROVED'});
                } else {
                    this.refStatusChangeDialog.setDialog(true);
                    this.setState({action: 'APPROVED'});
                }
                break;
            case "Talebi İptal Et":
                this.refStatusChangeDialog.setDialog(true);
                this.setState({action: 'CANCELLED'});
                break;
            case "Çözümle":
                this.refCloseDialog.setDialog(true);
                this.setState({action: 'RESOLVED'});
                break;
            case "Reddet":
                this.refStatusChangeDialog.setDialog(true);
                this.setState({action: 'REJECTED'});
                break;
            case "Kapat":
                let sr = this.refServiceRequestNew.getData();
                if ((sr.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CONNECT && sr.status == 'RESOLVED' && sr.statusReason == 'AWAITING_CLOSURE')) {
                    let serviceRequestExt = this.refServiceRequestNew.getDataExt();
                    let data = {
                        billingApproved: serviceRequestExt.billingApproved == "1" ? true : false,
                        ratingDate: serviceRequestExt.ratingDate
                    }
                    this.refCloseDialogSRConnect.setDialog(true);
                    this.refCloseDialogSRConnect.setData(data);
                    this.setState({action: 'CLOSED'});
                } else {
                    this.refCloseDialog.setDialog(true);
                    this.setState({action: 'CLOSED'});
                }
                break;
            case "Askıya Al":
                this.refPendingDialog.setDialog(true);
                this.setState({action: 'PENDING'});
                break;
            default:

        }
    }

    buttonRender(button) {
        return GeneralUtils.incidentButtonRender(this.state.pageMode, button, this.state.status, this.state.isSameContact, this.state.isSameTeam, 'ServiceRequest', this.state.isAllowedTeam);
    }

    deleteDraft = () => {
        let serviceRequest = this.refServiceRequestNew.getData();
        this.service.delete(serviceRequest.id).then(() => {
            if (this.props.closeDialog) {
                this.props.closeDialog();
            }
            if (this.props.refresh) {
                this.props.refresh();
            }
        });
    }

    showOrderForm = () => {
        let data = {
            requestId: this.state.requestIdForForm,
            requestType: REQUEST_TYPE.SERVICE_REQUEST
        }
        this.documentOrderXLSXService.generateOrderXSLX(data).then(response => {
            var blob = new Blob([response]);
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = ORDER_FORM_NAME;
            link.click();
        });
    }

    showServiceForm = () => {
        let data = {
            requestId: this.state.requestIdForForm,
            requestType: REQUEST_TYPE.SERVICE_REQUEST
        }
        this.documentServicePDFService.generateServicePDF(data).then(response => {
            var blob = new Blob([response], {type: 'application/pdf'});
            var fileURL = window.URL.createObjectURL(blob);
            window.open(fileURL, "MsgWindow", "width=1000,height=800");
        });
    }

    showCancellationForm = () => {
        let data = {
            requestId: this.state.requestIdForForm,
            requestType: REQUEST_TYPE.SERVICE_REQUEST
        }
        this.documentCancellationPDFService.generateCancellationPDF(data).then(response => {
            var blob = new Blob([response], {type: 'application/pdf'});
            var fileURL = window.URL.createObjectURL(blob);
            window.open(fileURL, "MsgWindow", "width=1000,height=800");
        });
    }

    showTransferForm = () => {
        let data = {
            requestId: this.state.requestIdForForm,
            requestType: REQUEST_TYPE.SERVICE_REQUEST
        }
        this.documentTransferPDFService.generateTransferPDF(data).then(response => {
            var blob = new Blob([response], {type: 'application/pdf'});
            var fileURL = window.URL.createObjectURL(blob);
            window.open(fileURL, "MsgWindow", "width=1000,height=800");
        });
    }

    createNewHsmAsset = () => {
        this.refHsmAsset.addNew();
        this.refHsmAsset.setOrgAndLocAndGeneric(this.state.organizationId, this.state.locationId);
        this.setState({displayHsmAssetDialog: true});
    }


    createNewHsmAssetForInventory = () => {
        this.refHsmAssetForInventory.addNew();
        this.refHsmAssetForInventory.setOrgAndLocAndGeneric(this.state.organizationId, this.state.locationId);
        this.setState({displayHsmAssetDialogForInventory: true});
    }

    createNewContact = () => {
        this.refContact.addNew();
        this.refContact.setOrgAndLocAndGeneric(this.state.organizationId, this.state.locationId);
        this.setState({displayNewContactDialog: true});
    }

    createNewOrganization = () => {
        this.refHsmNewOrganization.addNew();
        this.setState({displayOrganizationSave: true});
    }

    createNewLocation = () => {
        this.refNewLocation.clearData();
        this.refNewLocation.setPageMode(this.state.pageMode);
        this.setState({displayLocationSave: true});
    }

    setHsmAsset = (data) => {
        this.refRequestAsset.onHsmAssetSelect(data)
    }

    setHsmAssetForInventory = (data) => {
        this.refTask.onHsmAssetSelect(data)
    }

    setNewContact = (data) => {
        this.refRequestContact.onContactDataSelect(data)
    }

    setDisabledFlagServiceRequestFollow = (pageMode, requestId) => {
        this.refRequestSlt.setDisabledFlagServiceRequestFollow(pageMode, requestId);
    }

    clearContact = () => {
        this.setState({contactList: null});
        this.refRequestContact.clearData();
    }

    refreshScreen = () => {
        this.setState({displayConfirmDialogVersion: false})
        if (this.props.closeDialog) {
            this.props.closeDialog();
        }
        if (this.props.refresh) {
            this.props.refresh();
        }
    }

    refreshAssetList=()=>{
        //envanterden envanterleri ekledikten sonra hizmet talebi üzerindeki varlıklar tabını refresh etmek için
        let data = this.refServiceRequestNew.getData();
        this.refRequestAsset.setData(data, REQUEST_TYPE.SERVICE_REQUEST, this.state.pageMode, this.state.status);
    }

    deleteDocumentId(documentId) {
        let documentIdList = this.state.documentIdList;
            return documentIdList.filter(function (el) {
                return el != documentId;
            });
    }
}
ServiceRequest.propTypes = {
    closeDialog: PropTypes.func,
    refresh: PropTypes.func,
};
