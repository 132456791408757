import React from "react";
import PropTypes from 'prop-types';
import SelectDataTable from '../form/SelectDataTable';
import TeamColumn from '../../../shared/component/dataTable/columns/TeamColumn';
import TeamService from "../../../shared/service/TeamService";

export default class TeamGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value,
            validate: this.props.validate,
            dataTable: this.props.dataTable,
            disabled: this.props.disabled,
            label : this.props.label
        };
        this.service = new TeamService();
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({
            validate: nextProps.validate,
            value: nextProps.value,
            dataTable: nextProps.dataTable,
            label: nextProps.label,
            disabled: nextProps.disabled,
        });
    }

    render() {
        let validate = this.state.validate;
        if (!validate) {
            validate = {
                required: {
                    value: true,
                    errorMessage: "Bu alanın doldurulması zorunludur."
                }
            };
        }

        let label = this.state.label;
        if (!label) {
            label = "Ekip"
        }


        let dataTable = this.state.dataTable;
        if (!dataTable) {
            dataTable = {
                dataURL : "team/findAll",
                fields: TeamColumn.fields
            };
        }
        return (
            <SelectDataTable
                value={this.state.value}
                validate={validate}
                dataTable={dataTable}
                disabled={this.state.disabled}
                name="team"
                translateKey="teamManagement.contact"
                label={label}
                dialogHeader="Ekip Listesi"
                onDataSelect={this.props.onDataSelect}
                autoRefrestData={this.props.autoRefrestData ? this.props.autoRefrestData : false}
            />
        );
    }
}
TeamGroup.propTypes = {
    value: PropTypes.string,
    validate: PropTypes.object,
    dataTable: PropTypes.object
}