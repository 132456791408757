import React from 'react';
import {Button, Label} from 'reactstrap';
import PropTypes from 'prop-types';
import {AvGroup} from 'availity-reactstrap-validation';
import AvField from "../../../../shared/component/form/AvField";
import Translate from "../../../../shared/language/Translate";
import GeneralUtils from '../../../../shared/utils/GeneralUtils';
import {NETWORK_INTERFACE_NAME, PAGE_MODE} from "../../../../shared/utils/Constants";
import Toolbar from "../../../../shared/component/toolbar/Toolbar";
import {toast} from "react-toastify";
import HsmNetworkInterfaceColumn from '../../../../shared/component/dataTable/columns/HsmNetworkInterfaceColumn';
import AvFieldForMac from "../../../../shared/component/form/AvFieldForMac";

const defaultNsmNetworkInterface = {
    id: '',
    name: '',
    ipAddress: '',
    macAddress: '',
    vlanId: '',
    assetId: ''
};

export default class HsmNetworkInterfaceSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hsmNetworkInterface: defaultNsmNetworkInterface,
            pageMode: null
        }
    }

    render() {
        return (
            <div>
                {this.state.displayButton !== false &&
                <Toolbar
                    display={GeneralUtils.getDialogToolbarDisplay(HsmNetworkInterfaceColumn.name, this.state.pageMode)}>
                    <div className="p-toolbar-group-right">
                        <Button color="primary" className="float-right"
                                onClick={this.add}>
                            &nbsp;
                            <Translate contentKey="entity.action.add">Ekle</Translate>
                        </Button>
                        <Button color="primary" className="float-right"
                                onClick={this.props.closeDisplayDialog}>
                            <Translate contentKey="entity.action.add">Vazgeç</Translate>
                        </Button>
                        {this.state.pageMode === PAGE_MODE.UPDATE &&
                        <Button color="primary" className="float-right" onClick={this.delete}>
                            <Translate contentKey="entity.action.delete">Sil</Translate>
                        </Button>
                        }
                    </div>
                </Toolbar>}
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <AvGroup>
                                <Label for="nameNI">
                                    <Translate contentKey="hsmNetworkInterface.name">Ağ Adaptörü Adı</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvField type="select"
                                         className="form-control"
                                         name="nameNI"
                                         value={this.state.hsmNetworkInterface.name}
                                         onChange={this.__handleChangeName}>
                                    <option value='' key=''/>
                                    {NETWORK_INTERFACE_NAME.map(element => (
                                        <option value={element.value} key={element.value}>
                                            {element.name }
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>
                            <AvGroup>
                                <Label for="vlanIdNI">
                                    <Translate contentKey="hsmNetworkInterface.vlanId">VLAN ID</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="vlanIdNI"
                                    disabled={true}
                                    validate={{
                                        maxLength: {
                                            value: 30,
                                            errorMessage: 'Bu alan en fazla 30 karakterden oluşabilir.'
                                        }
                                    }}
                                    value={this.state.hsmNetworkInterface.vlanId}
                                    onChange={this.__handleChange}
                                />
                            </AvGroup>
                        </div>
                        <div className="col">
                            <AvGroup>
                                <Label for="ipAddressNI">
                                    <Translate contentKey="hsmNetworkInterface.ipAddress">IP Adresi</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="ipAddressNI"
                                    validate={{
                                        maxLength: {
                                            value: 15,
                                            errorMessage: 'Bu alan en fazla 15 karakterden oluşabilir.'
                                        }
                                    }}
                                    placeholder="000.000.000.000"
                                    value={this.state.hsmNetworkInterface.ipAddress}
                                    onChange={this.__handleChange}
                                />
                            </AvGroup>
                            <AvGroup>
                                <Label for="macAddressNI">
                                    <Translate contentKey="hsmNetworkInterface.macAddressNI">MAC Adresi</Translate>
                                </Label>
                                <AvFieldForMac
                                    ref={(elem) => this.refMacAddress = elem}
                                    type="text"
                                    className="form-control"
                                    name="macAddressNI"
                                    value={this.state.hsmNetworkInterface.macAddress}
                                    setValue={(value)=> {
                                        let hsmNetworkInterface = this.state.hsmNetworkInterface;
                                        hsmNetworkInterface.macAddress =value;
                                        this.setState({
                                            hsmNetworkInterface
                                        });
                                    }}
                                    onChange={this.__handleChange}
                                />
                            </AvGroup>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    setData = (data, pageMode, displayButton) => {
        this.clearData();
        data.assetId = data.assetId ? data.assetId.id : null;
        this.refMacAddress.setPageMode(pageMode);
        this.setState({hsmNetworkInterface: data, pageMode: pageMode, displayButton: displayButton});
    }

    clearData = () => {
        this.setState({hsmNetworkInterface: Object.assign({}, defaultNsmNetworkInterface), pageMode: null});
    }

    __handleChangeName = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let hsmNetworkInterface = this.state.hsmNetworkInterface;
        hsmNetworkInterface[e.target.name.replace("NI", "")] = value;
        // hsmNetworkInterface.vlanId = this.getVlanId(value);
        this.setState({hsmNetworkInterface: hsmNetworkInterface});
    }

    // getVlanId = (value) => {
    //     let hsmNetworkInterfaceNameList = NETWORK_INTERFACE_NAME;
    //     for (let i = 0; i < hsmNetworkInterfaceNameList.length; i++) {
    //         if (hsmNetworkInterfaceNameList[i].value === value) {
    //             return hsmNetworkInterfaceNameList[i].vlanId;
    //         }
    //     }
    //     return '';
    // }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let hsmNetworkInterface = this.state.hsmNetworkInterface;
        hsmNetworkInterface[e.target.name.replace("NI", "")] = value;
        this.setState({hsmNetworkInterface: hsmNetworkInterface});
    }

    setAssetIdAndPageMode = (assetId, pageMode) => {
        let hsmNetworkInterface = Object.assign({}, defaultNsmNetworkInterface);
        hsmNetworkInterface.assetId = assetId;
        this.refMacAddress.setPageMode(pageMode);
        this.setState({hsmNetworkInterface: hsmNetworkInterface, pageMode: pageMode});
    }

    add = () => {
        if (GeneralUtils.isNullOrEmpty(this.state.hsmNetworkInterface.name)) {
            return toast.warn("Zorunlu Alan : Lütfen Ağ Adaptörü adı giriniz!");
        }
        let ipAddress = this.state.hsmNetworkInterface.ipAddress;
        if(!GeneralUtils.isNullOrEmpty(ipAddress) &&!GeneralUtils.isIP4(ipAddress)){
            return toast.warn("Lütfen ip adresi alanını kontrol ediniz!");
        }
        let macAdress = this.state.hsmNetworkInterface.macAddress;
        if(!GeneralUtils.isNullOrEmpty(macAdress) &&!this.refMacAddress.checkMacAddress(macAdress)){
            return toast.warn("Lütfen mac adresi alanını kontrol ediniz!");
        }
        if (this.props.checkName(this.state.hsmNetworkInterface.name)) {
            return toast.warn("Seçilen ağ adaptörü adı tanımlı!");
        }
        let normalizaMacAdress = this.refMacAddress.getValue();
        let hsmNetworkInterface = this.state.hsmNetworkInterface;
        hsmNetworkInterface.macAddress=normalizaMacAdress;
        if (this.props.add) {
            this.props.add(hsmNetworkInterface);
        }
        if (this.props.closeDisplayDialog) {
            this.props.closeDisplayDialog();
        }
    }

    delete = () => {
        if (this.props.delete) {
            this.props.delete(this.state.hsmNetworkInterface);
        }
        if (this.props.closeDisplayDialog) {
            this.props.closeDisplayDialog();
        }
    }

    // addNetworkInterface = () => {
    //     if (GeneralUtils.isNullOrEmpty(this.state.hsmNetworkInterface.name)) {
    //         return toast.warn("Zorunlu Alan : Lütfen Cihaz Arayüz adı giriniz!");
    //     }
    //     if (GeneralUtils.isNullOrEmpty(this.state.hsmNetworkInterface.purpose)) {
    //         return toast.warn("Zorunlu Alan : Lütfen Kullanım Amacı Seçiniz!");
    //     }
    //     let data = {};
    //     if (GeneralUtils.isNullOrEmpty(this.state.hsmNetworkInterface.networkDeviceId)) {
    //         data = Object.assign({}, this.state.hsmNetworkInterface);
    //         data.networkDeviceId = {id: this.state.hsmNetworkInterface.networkDeviceId};
    //     } else {
    //         data = Object.assign({}, this.state.hsmNetworkInterface);
    //     }
    //     // if (this.props.checkName(data.name)) {
    //     //     return toast.warn("Girilen arayüz adı tanımlı!");
    //     // }
    //     if (!this.props.checkRecordExists(data.id)) {
    //         if (this.props.checkPurpose(data.purpose)) {
    //             return toast.warn("Seçilen arayüzde ağ cihazı arayüzü tanımlı!");
    //         }
    //     }
    //     if (this.props.add) {
    //         this.props.add(data);
    //     }
    //     if (this.props.closeDisplayDialog) {
    //         this.props.closeDisplayDialog();
    //     }
    // }
}

HsmNetworkInterfaceSave.propTypes = {
    add: PropTypes.func,
    hsmNetworkInterface: PropTypes.object
};




