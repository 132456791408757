import React from 'react';
import {AvForm, AvGroup} from "availity-reactstrap-validation";
import {Button, Label} from "reactstrap";
import Translate from "../../../shared/language/Translate";
import AvField from "../../../shared/component/form/AvField";
import LogMailService from "../../../shared/service/LogMailService";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import Loading from "../../../shared/component/others/Loading";
import ReactQuill from "react-quill";
import {Panel} from "primereact/panel";
import {toast} from "react-toastify";
import Toolbar from "../../../shared/component/toolbar/Toolbar";


const defaultMail = {
    id: '',
    mailFrom: '',
    mailTo: '',
    cc: '',
    bcc: '',
    subject: '',
    content: ''
};
export default class MailTest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: defaultMail
        };
        this.service = new LogMailService();
    }

    componentDidMount = () => {
       this.clearData();
    }

    render() {
        return (
            <Panel header="Eposta Test Sayfası" >
                <AvForm onValidSubmit={this.sendMailTest}>
                    <Toolbar display={true}>
                        <div className="p-toolbar-group-right">
                            <Button color="primary" type="submit" className="float-right">
                                <Translate contentKey="entity.action.save">Gönder</Translate>
                            </Button>
                        </div>
                    </Toolbar>
                    <AvGroup>
                        <Label for="mailFrom">
                            <Translate contentKey="logMailContent.mailFrom">Gönderen</Translate>
                            <span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="mailFrom"
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                },
                                minLength: {
                                    value: 1,
                                    errorMessage: 'Bu alan en az 1 karakterden oluşabilir.'
                                },
                                maxLength: {
                                    value: 256,
                                    errorMessage: 'Bu alan en fazla 256 karakterden oluşabilir.'
                                }
                            }}
                            value={this.state.data.mailFrom}
                            onChange={this.__handleChange}
                        />
                    </AvGroup>
                    <AvGroup>
                        <Label for="mailTo">
                            <Translate contentKey="logMailContent.mailTo">Alıcı</Translate>
                            <span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="mailTo"
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                },
                                minLength: {
                                    value: 1,
                                    errorMessage: 'Bu alan en az 1 karakterden oluşabilir.'
                                },
                                maxLength: {
                                    value: 256,
                                    errorMessage: 'Bu alan en fazla 256 karakterden oluşabilir.'
                                }
                            }}
                            value={this.state.data.mailTo}
                            onChange={this.__handleChange}
                        />
                    </AvGroup>
                    <AvGroup>
                        <Label for="cc">
                            <Translate contentKey="logMailContent.cc">CC</Translate>
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="cc"
                            value={this.state.data.cc}
                            onChange={this.__handleChange}
                        />
                    </AvGroup>
                    <AvGroup>
                        <Label for="bcc">
                            <Translate contentKey="logMailContent.bcc">BCC</Translate>
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="bcc"
                            value={this.state.data.bcc}
                            onChange={this.__handleChange}
                        />
                    </AvGroup>
                    <AvGroup>
                        <Label for="subject">
                            <Translate contentKey="logMailContent.subject">Konu</Translate>
                            <span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="subject"
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                },
                                minLength: {
                                    value: 1,
                                    errorMessage: 'Bu alan en az 1 karakterden oluşabilir.'
                                },
                                maxLength: {
                                    value: 256,
                                    errorMessage: 'Bu alan en fazla 256 karakterden oluşabilir.'
                                }
                            }}
                            value={this.state.data.subject}
                            onChange={this.__handleChange}
                        />
                    </AvGroup>
                    <AvGroup>
                        <Label for="content">
                            <Translate contentKey="logMailContent.content">İçerik</Translate>
                            <span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <ReactQuill
                            name="content"
                            value={this.state.data.content}
                            onChange={this.__handleChangeEditor}
                            style={{height: '320px', marginBottom: '50px'}}
                        />
                    </AvGroup>
                </AvForm>
                {this.state.loading && <Loading/>}
            </Panel>
        );
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let data = this.state.data;
        data[e.target.name] = value;
        this.setState({data: data});
    }

    __handleChangeEditor = (e) => {
        let data = this.state.data;
        data.content = e;
        this.setState({data: data});
    }

    sendMailTest = () => {
        if (GeneralUtils.isNullOrEmpty(this.state.data.content)) {
            return toast.warn("Zorunlu Alan : Lütfen İçerik Bilgisi Giriniz!");
        }
        let mail = this.state.data;
        this.setState({loading: true});
        this.service.sendMailTest(mail).then((result) => {
            if (!GeneralUtils.isNullOrEmpty(result) && GeneralUtils.isNullOrEmpty(result.type)) {
                this.clearData();
            }
            this.setState({loading: false});
        });
    }

    clearData = () => {
        this.setState({data: Object.assign({}, defaultMail)})
    }
}
