import React from 'react';
import {TreeTable} from "../../../shared/component/treeTable/TreeTable";
import {Col, Row} from "reactstrap";
import Service from "../../../shared/service/Service";
import HsmRoleMenuColumn from "../../../shared/component/treeTable/columns/HsmRoleMenuColumn";

export default class HsmRoleMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedNodeKeys: []
        };
        this.service = new Service('roleMenu');
    }

    componentDidMount = () => {
        this.service.findAll().then(response => this.setState({data: response}));
    }

    render() {
        return (
            <div>
                <Row className="justify-content-center">
                    <Col md="8">
                        <TreeTable fields={HsmRoleMenuColumn.fields} data={this.state.data} onSelectionChange={this.onSelectionChange} scrollable scrollHeight="250px" style={{height: '300px'}}
                                   selectionKeys={this.state.selectedNodeKeys} ref={el=>this.refTreeTable=el}/>
                    </Col>
                </Row>
            </div>

        );
    }

    setData = (data, pageMode) => {
        let selectedNodeKeys = {};
        data.menuList.forEach((element) => {
            selectedNodeKeys[element.id] = {checked: true, partialChecked: false};
        });

        this.editSelectedNodes(selectedNodeKeys);

        this.setState({selectedNodeKeys: selectedNodeKeys});
    }

    getData = () => {
        let menuList = [];
        Object.keys(this.state.selectedNodeKeys).map(function (key) {
            menuList.push({id: key});
        });
        return menuList;
    }

    clearData = () => {
        this.setState({selectedNodeKeys: []});
    }

    onSelectionChange = (e) => {
        this.setState({selectedNodeKeys: e.value});
    }

    collapseAll=()=>{
        this.refTreeTable.collapseAll();
    }

    editSelectedNodes= (selectedNodeKeys) =>{
        let control=0;
        for (let i=0;i<this.state.data.length;i++){
            if(this.state.data[i].children!= undefined) {
                for (let j = 0; j < this.state.data[i].children.length; j++) {
                    if (selectedNodeKeys[this.state.data[i].children[j].key] != undefined) {
                        control++;
                    }
                }
                if (this.state.data[i].children.length == control) {
                    selectedNodeKeys[this.state.data[i].key] = {checked: true, partialChecked: false};
                } else if (control < this.state.data[i].children.length && control > 0) {
                    selectedNodeKeys[this.state.data[i].key] = {checked: false, partialChecked: true};
                }
            }

            if(control==0 && selectedNodeKeys[this.state.data[i].key]){
                selectedNodeKeys[this.state.data[i].key] = {checked: false, partialChecked: true};
            }

            control=0;
        }
    }
}
