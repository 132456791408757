import React from 'react';
import {Panel} from 'primereact/panel';
import {Button, Col, Container, Label, Row} from 'reactstrap';
import Doughnut from "../../../shared/component/chart/Doughnut";
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import LocationReportService from "../../../shared/service/LocationReportService";
import HermesSweetAlert from "../../../shared/component/sweetAlert/HermesSweetAlert.js"

class LocationChartReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            totalCount: 0,
            data: {
                labels: ['KURULU OLAN LOKASYON', 'KURULU OLMAYAN LOKASYON', 'KURULACAK LOKASYON'],
                datasets: [{
                    data: [],
                    backgroundColor: ['#9CCC65', '#F52A2D', '#42A5F5'],
                    hoverBackgroundColor: ['#9CCC65', '#F52A2D', '#42A5F5']
                }]
            }
        };
        this.service = new LocationReportService();
    }

    componentDidMount = () => {
        this.service.checkPage();
        this.refresh();
    }

    render() {
        return (
            <Panel header="Lokasyon Raporu">
                <Toolbar display={true}>
                    <div className="p-toolbar-group-right">
                        <Button color="primary" onClick={this.refresh}>
                            Yenile
                        </Button>
                    </div>
                </Toolbar>
                <Container>
                    <Row>
                        <Col>
                            {this.state.item}
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Label >{'Toplam : '+this.state.totalCount}</Label>
                    </Row>
                </Container>
            </Panel>
        );
    }

    getDoughnut = () => {
        return <Doughnut data={this.state.data} onElementsClick={this.redirectPage} options={
            {legend: {display: true}, responsive: true, cutoutPercentage: 0}
        }/>;
    }

    redirectPage = (e) => {
        if (e.length !== 0) {
            if (e[0]._model.label.includes('KURULU OLAN LOKASYON')) {
                window.location = '#/locationDetailReport?type=findInstalledLocation';
            }
            else if (e[0]._model.label.includes('KURULU OLMAYAN LOKASYON')) {
                window.location = '#/locationDetailReport?type=findUninstalledLocation';
            }
            else if (e[0]._model.label.includes('KURULACAK LOKASYON')) {
                window.location = '#/locationDetailReport?type=findWillInstallLocation';
            }
        }
    }

    refresh = () => {
        // HermesSweetAlert.error("Hata var!!!");
        // HermesSweetAlert.info("Bilgi var!!!");
        // HermesSweetAlert.warn("Uyarı var!!!");
        // HermesSweetAlert.success("Başarı var!!!");
        let installedLocation = 0;
        let uninstalledLocation = 0;
        let willInstallLocation = 0;
        let data = this.state.data;
        this.setState({item: null});
        this.service.findInstalledLocation().then((response) => {
            installedLocation = response;
            this.service.findUninstalledLocation().then((response) => {
                uninstalledLocation = response;
                this.service.findWillInstallLocation().then((response) => {
                    willInstallLocation = response;
                    data.datasets[0].data = [];
                    data.datasets[0].data.push(installedLocation, uninstalledLocation, willInstallLocation);
                    data.labels=[data.labels[0] + " (" +installedLocation + ")", data.labels[1] + " (" +uninstalledLocation + ")",
                        data.labels[2] + " (" +willInstallLocation + ")"];
                    let totalCount = new Number(installedLocation) + new Number(uninstalledLocation) + new Number(willInstallLocation);
                    this.setState({data: data, totalCount:totalCount});
                    this.setState({item: this.getDoughnut()});
                })
            })
        });
    }
}

export default LocationChartReport;
