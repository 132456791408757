import React from 'react';
import {Panel} from 'primereact/panel';
import IncidentService from '../../shared/service/IncidentService';
import IncidentColumn from '../../shared/component/dataTable/columns/IncidentColumn';
import {PAGE_MODE} from "../../shared/utils/Constants";
import Incident from "./Incident";
import Dialog from "../../shared/component/dialog/Dialog";
import GeneralUtils from "../../shared/utils/GeneralUtils";
import DataTableRequest from "../../shared/component/dataTable/DataTableRequest";

export default class IncidentList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageMode: null,
            dialogHeader: 'Arıza Bilgileri'
        };
        this.service = new IncidentService();
    }

    componentDidMount = () => {
        this.init();
    }

    render() {
        return (
            <Panel header="Arıza Yönetimi">
                <DataTableRequest ref={(elem) => this.refDataTable = elem} fields={IncidentColumn.fields} dataURL="incident/findAllFiltered" objectName={IncidentColumn.name}
                           selectionMode="single" onDataSelect={this.onDataSelect} loading={this.state.loading} export={true} autoLoad={false}/>


                <Dialog visible={this.state.displayDialog} header={this.state.dialogHeader}
                        onHide={() => this.setState({displayDialog: false})} style={{width: '100%'}}>
                    <Incident  ref={elem => this.refIncident = elem} closeDialog={() => this.setState({displayDialog: false})} refresh={this.refresh}/>

                </Dialog>
            </Panel>
        );
    }

    refresh = () => {
        this.init();
        // this.refDataTable.setParameter("ALL", GeneralUtils.getContactId());
    }

    init = () => {
        let parameter = window.location.href.split('?')[1];
        if (parameter) {
            if (parameter.indexOf("&") > -1) {
                let values = parameter.split('&');
                let name = values[0].split('=')[1];
                let subcategoryId = values[1].split('=')[1];
                let status = values[2].split('=')[1];
                if (!GeneralUtils.isNullOrEmpty(name) && !GeneralUtils.isNullOrEmpty(subcategoryId) && !GeneralUtils.isNullOrEmpty(status)) {
                    this.refDataTable.setParameter(name, GeneralUtils.getContactId(), subcategoryId, status);
                }
            } else {
                let name = parameter.split('=')[1];
                if (!GeneralUtils.isNullOrEmpty(name)) {
                    this.refDataTable.setParameter(name, GeneralUtils.getContactId());
                }
            }
        } else {
            this.refDataTable.setParameter("ALL", GeneralUtils.getContactId());
        }
    }

    onDataSelect = (data) => {
        let dialogHeader = data.id +' Numaralı Arıza Kaydı';
        this.setState({displayDialog: true, pageMode: PAGE_MODE.VIEW, activeIndex: 0, dialogHeader: dialogHeader});
        this.refIncident.refAttachedDocument.clearAllDataAndFileArray();
        this.refIncident.setData(Object.assign({}, data), PAGE_MODE.VIEW);
        this.refIncident.setDisabledFlagIncidentFollow(PAGE_MODE.VIEW,data.id);
    }
}
