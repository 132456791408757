import React from 'react';
import {AvGroup} from "availity-reactstrap-validation";
import {Col, Label, Row} from "reactstrap";
import AvField from "../../shared/component/form/AvField";
import {STATUS} from "../../shared/utils/Constants"
import OrganizationGroup from "../../shared/component/hsmGroup/OrganizationGroup";
const defaultServiceCategory = {
    id: '',
    name:'',
    description: '',
    providerOrgId:null,
    status: 'A',
    
};
export default class ServiceCategorySave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            serviceCategory: defaultServiceCategory,
            providerOrg:null,
            pageMode: null
        };
    }


    render() {
        return (
            <Row className="justify-content-center">
                <Col>
                    <AvGroup>
                        <Label for="name">Kategori<span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="name"
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                },
                                minLength: {
                                    value: 1,
                                    errorMessage: 'Bu alan en az 1 karakterden oluşabilir.'
                                },
                                maxLength: {
                                    value: 255,
                                    errorMessage: 'Bu alan en fazla 255 karakterden oluşabilir.'
                                }
                            }}
                            value={this.state.serviceCategory.name}
                            onChange={this.__handleChange}
                        />
                    </AvGroup>
                    <AvGroup>
                        <Label for="description">Açıklama</Label>
                        <AvField
                            type="textarea"
                            className="form-control textAreaHermes"
                            name="description"
                            validate={{
                                maxLength: {
                                    value: 500,
                                    errorMessage: 'Bu alan en fazla 500 karakterden oluşabilir.'
                                }
                            }}
                            value={this.state.serviceCategory.description}
                            onChange={this.__handleChange}
                        />
                    </AvGroup>
                    <OrganizationGroup
                            value={this.state.providerOrg}
                            onDataSelect={this.onOrganizationSelect}
                            label="Servis Sağlayıcı"
                            
                        />
                    
                    <AvGroup>
                        <Label for="status">Durum<span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="select"
                            className="form-control"
                            name="status"
                            value={this.state.serviceCategory.status}
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                }
                            }}
                            onChange={this.__handleChange}>
                        {STATUS.map(element => (
                            <option value={element.value} key={element.value}>
                                {element.name}
                            </option>
                        ))}
                        </AvField>
                    </AvGroup>
                </Col>
            </Row>
        );
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let serviceCategory = this.state.serviceCategory;
        serviceCategory[e.target.name] = value;
        this.setState({serviceCategory: serviceCategory});
    }

    setData = (data, pageMode) => {
        let providerOrg = data.providerOrgId ? data.providerOrgId.name : null;
        this.setState({serviceCategory: data,providerOrg:providerOrg, pageMode: pageMode});
    }

    getData = () => {
        return this.state.serviceCategory;
    }

    clearData = () => {
        this.setState({serviceCategory: Object.assign({}, defaultServiceCategory),providerOrg:null, pageMode: null});
    }
    onOrganizationSelect = (data) => {
        let serviceCategory = this.state.serviceCategory;
        serviceCategory.providerOrgId = data;
        this.setState({serviceCategory: serviceCategory, providerOrg: data.name, displayDialog: false});
    }

}
