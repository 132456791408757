import React, {Component} from 'react';
import {Button, Label, Tooltip,Spinner} from 'reactstrap';
import {AvGroup} from 'availity-reactstrap-validation';
import AvField from '../../../shared/component/form/AvField';
import Translate from "../../../shared/language/Translate";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import Dialog from "../../../shared/component/dialog/Dialog";
import DataTable from "../../../shared/component/dataTable/DataTable";
import PropTypes from 'prop-types';
import {ProgressSpinner} from "primereact/progressspinner";

/**
 * Samet USLU ._.
 */
export default class SelectDataTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: this.props.name,
            translateKey: this.props.translateKey,
            label: this.props.label,
            disabled: this.props.disabled,
            addNewButton: this.props.addNewButton,
            validate: this.props.validate,
            value: this.props.value,
            dialogHeader: this.props.dialogHeader,
            dataTable: this.props.dataTable,
            autoRefrestData: this.props.autoRefrestData,
            loading:true,
            displayDialog: false,
            dataTableRows: [],
            controlButton: this.props.controlButton,
            buttonStyle:this.props.buttonStyle
        };
    }

    // componentDidMount = () => {
    //     if(!this.state.disabled) {
    //         this.refDataTable.setParameter(this.state.dataTable.parameter);
    //     }
    // }

    componentWillReceiveProps = (nextProps) => {
        this.setState({
            translateKey: nextProps.translateKey,
            label: nextProps.label,
            disabled: nextProps.disabled,
            addNewButton: nextProps.addNewButton,
            validate: nextProps.validate,
            value: nextProps.value,
            dialogHeader: nextProps.dialogHeader,
            dataTable: nextProps.dataTable,
            controlButton: nextProps.controlButton,
            buttonStyle:nextProps.buttonStyle
        });
    }

    render() {
        let value = this.props.value;
        if (GeneralUtils.__isValueEmpty(value)) {
            value = '';
        }

        let requiredIcon = this.getRequiredIcon();
        let dialog = this.getDialog();
        return (
            <div>
                <AvGroup>
                    <Label for={this.state.name}>
                        <Translate contentKey={this.state.translateKey}>{this.state.label}</Translate>
                        {requiredIcon}
                    </Label>
                    <div style={{position: "relative"}}>
                        <AvField
                            type="text"
                            className="form-control"
                            name={this.state.name}
                            disabled={true}
                            validate={this.state.validate}
                            value={this.state.value}
                        />
                        {!this.state.disabled &&
                        < Button color="primary" onClick={this.openDialog}
                            disabled={this.state.disabled}
                            className="pi pi-search selectTableButton" size="sm"/>
                        }
                        {(this.props.openDetailDialog && this.state.value) &&
                        <Button color="primary" onClick={this.props.openDetailDialog}
                                disabled={this.props.openDetailDialog ? false : true}
                                className={this.state.disabled === true ? "pi pi-info selectTableButton" : "pi pi-info selectTableButton2"}
                                size="sm"/>
                        }
                        {this.state.addNewButton &&
                        <Button color="primary"
                                onClick={this.props.createNewOrganization}
                                className={this.props.openDetailDialog ? "pi pi-plus selectTablePlusButton60" :"pi pi-plus selectTablePlusButton25"}
                                size="sm"/>
                        }
                        {this.state.controlButton &&
                        <Button color="primary"
                                style={{backgroundColor: this.props.buttonStyle.backgroundColor}}
                                onClick={this.props.getDeviceStatus}
                                className="selectControlButton"
                                size="sm">
                            {this.props.buttonStyle.loading &&
                            <span> <ProgressSpinner style={{width: '16px', height: '16px'}} strokeWidth="8"/> Sorgulanıyor </span>
                            }
                            {!this.props.buttonStyle.loading &&
                            <span>{this.props.buttonStyle.buttonName}</span>
                            }
                        </Button>
                        }

                    </div>
                </AvGroup>
                {dialog}
            </div>
        );
    }


    getRequiredIcon = () => {
        let result = null;
        if (this.state.validate && this.state.validate.required && this.state.validate.required.value === true) {
            result = <span style={{color: 'red'}}> (*)</span>;
        }
        return result;
    }

    getDialog = () => {
        let result = (
            <Dialog style={{width: '90%'}}
                    visible={this.state.displayDialog}
                    header={this.props.dialogHeader}
                    onHide={() => this.setState({displayDialog: false})}>
                <DataTable
                    ref={(elem) => this.refDataTable = elem}
                    loading={this.state.loading}
                    fields={this.state.dataTable.fields}
                    privilege="allow"
                    dataURL={this.state.dataTable.dataURL}
                    selectionMode="single"
                    onDataSelect={this.onDataSelect}
                    autoLoad={false}
                />
            </Dialog>
        );
        return result;
    }

    openDialog = () => {
        this.refDataTable.setParameter(this.state.dataTable.parameter);
        this.setState({displayDialog: true});
    }

    onDataSelect = (data) => {
        this.setState({displayDialog: false});
        this.props.onDataSelect(data);
    }
}

SelectDataTable.propTypes = {
    name: PropTypes.string,
    translateKey: PropTypes.string,
    label: PropTypes.string,
    dialogHeader: PropTypes.string,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    addNewButton: PropTypes.bool,
    onDataSelect: PropTypes.func,
    autoRefrestData: PropTypes.bool,
    validate: PropTypes.object,
    dataTable: PropTypes.object,
    controlButton: PropTypes.object,
    buttonStyle: PropTypes.object
};
