import React from 'react';
import LogMailDetailColumn from '../../../shared/component/dataTable/columns/LogMailDetailColumn';
import LogMailDetailService from "../../../shared/service/LogMailDetailService";
import DataTable from "../../../shared/component/dataTable/DataTable";


export default class LogMailDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
        this.service = new LogMailDetailService();
    }

    render() {
        return (
            <DataTable ref={(elem) => this.refDataTable = elem} fields={LogMailDetailColumn.fields} dataURL="logMailDetail/findByLogMailId" objectName={LogMailDetailColumn.name} autoLoad={false}/>
        );
    }

    setData = (data) => {
        this.refDataTable.setParameter(data.id);
        // this.service.findByLogMailId(data.id).then(response => {
        //     this.setState({data: response})
        // });
    }

    clearData = () => {
        this.setState({data: []})
    }
}
