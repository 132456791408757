import {toast} from 'react-toastify';
import {ASSET_CATEGORY, PAGE_MODE, SERVICE_SUBCATEGORY} from "./Constants";
import LoginService from "../service/LoginService";

const loginService = new LoginService();
export default class GeneralUtils {
    static isNullOrEmpty(value) {
        return (!value || value === undefined || value === "" || value.length === 0 || JSON.stringify(value) === JSON.stringify({}));
    }

    static __isValueEmpty(value) {
        switch (typeof value) {
            case "undefined":
                return true;
            case "object":
                if (value === null) return true;
                return Object.keys.length === 0;
            case "string":
                return (value === undefined || value === null || value.trim() === "");
            default:
                break;
        }
    }

    // Returns if a value is an object
    static isObject(value) {
        return value && typeof value === 'object' && value.constructor === Object;
    }


    static checkPasswordFormat(str) {
        var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;
        return re.test(str);
    }

    static checkUnauthorized(response) {
        switch (response.status) {
            case 200:
                break;
            case 401 :
                let data = {eventType: "TIMEOUT"};
                loginService.logout(data);
                localStorage.clear();
                window.location = "";
                break;
            default:
                toast.error("Beklenmeyen bir hata oluştu. Hata kodu: " + response.status);
                break;
        }
    }

    static notification(response) {
        switch (response.status) {
            case 200:
                response.json().then(function (notification) {
                    if (notification.type) {
                        GeneralUtils.showNotification(notification);
                    } else {
                        toast.success("İşlem başarıyla gerçekleştirilmiştir.");
                    }
                });
                break;
            case 401 :
                response.json().then(function (notification) {
                    if (notification.type) {
                        GeneralUtils.showNotification(notification);
                    } else {
                        toast.warn("Erişim yetkiniz yoktur.");
                    }
                });
                break;
            default:
                toast.error("Beklenmeyen bir hata oluştu. Hata kodu: " + response.status);
                break;
        }
    }

    static notification(response, hiddenSucces) {
        switch (response.status) {
            case 200:
                response.json().then(function (notification) {
                    if (notification.type) {
                        GeneralUtils.showNotification(notification);
                    } else {
                        if(!hiddenSucces) {
                            toast.success("İşlem başarıyla gerçekleştirilmiştir.");
                        }
                    }
                });
                break;
            case 401 :
                response.json().then(function (notification) {
                    if (notification.type) {
                        GeneralUtils.showNotification(notification);
                    } else {
                        toast.warn("Erişim yetkiniz yoktur.");
                    }
                });
                break;
            default:
                toast.error("Beklenmeyen bir hata oluştu. Hata kodu: " + response.status);
                break;
        }
    }

    static showNotification(notification) {
        switch (notification.type) {
            case "SUCCESS":
                toast.success(notification.message);
                break;
            case "INFO" :
                toast.info(notification.message);
                break;
            case "WARNING" :
                toast.warn(notification.message);
                break;
            case "ERROR":
                toast.error(notification.message);
                break;
            default:
                toast.error("Beklenmeyen bir hata oluştu. " + notification.message);
                break;
        }
    }

    static findPriority(urgency, impact) {
        let priority = 0;
        if (urgency === 1) {
            if (impact === 1)
                priority = 1;
            else if (impact === 2)
                priority = 2;
            else if (impact === 3)
                priority = 2;
            else if (impact === 4)
                priority = 3;
        } else if (urgency === 2) {
            if (impact === 1)
                priority = 2;
            else if (impact === 2)
                priority = 2;
            else if (impact === 3)
                priority = 3;
            else if (impact === 4)
                priority = 4;
        } else if (urgency === 3) {
            if (impact === 1)
                priority = 2;
            else if (impact === 2)
                priority = 3;
            else if (impact === 3)
                priority = 4;
            else if (impact === 4)
                priority = 4;
        } else if (urgency === 4) {
            if (impact === 1)
                priority = 3;
            else if (impact === 2)
                priority = 4;
            else if (impact === 3)
                priority = 4;
            else if (impact === 4)
                priority = 4;
        }
        return priority;
    }

    static incidentButtonDisabled(viewMode, buttonId) {
        let disabled = false;

        if (viewMode === PAGE_MODE.VIEW) {
            disabled = true;
        }

        if (viewMode === PAGE_MODE.CREATE) {
            disabled = false;
        }

        if (viewMode === PAGE_MODE.UPDATE) {
            if (buttonId === 'title' || buttonId === 'description' || buttonId === 'requestCommentText' || buttonId === 'requestWorknoteText' || buttonId === 'connectInfo' || buttonId === 'moveInfo' || buttonId === 'complaintTypeId') {
                disabled = false;
            } else {
                disabled = true;
            }
        }


        return disabled;

    }


    static incidentButtonRender(viewMode, buttonId, status, isSameContact, isSameTeam, objectName , isAllowedTeam) {
        let render = false;

        if (buttonId === 'assigneeToMeButton') {
            if ((isSameTeam === true) && isSameContact === false && (viewMode === PAGE_MODE.VIEW || viewMode === PAGE_MODE.UPDATE) && status != 'CANCELLED' && status != 'CLOSED') {
                if (this.checkPrivilege(objectName, 'Assign')) {
                    render = true;
                }
            }
        } else if (buttonId === 'assigneeButton') {
            if (isSameTeam === false && isSameContact === true && viewMode === PAGE_MODE.VIEW && status != 'CANCELLED' && status != 'CLOSED') {
                if (this.checkPrivilege(objectName, 'Assign')) {
                    render = true;
                }
            }
            if ((isSameTeam === true || isAllowedTeam === true ) && viewMode === PAGE_MODE.VIEW && status != 'CANCELLED' && status != 'CLOSED') {
                if (this.checkPrivilege(objectName, 'Assign')) {
                    render = true;
                }
            }
        } else if (buttonId === 'modifyButton') {
            if ((isSameContact === true ) && viewMode === PAGE_MODE.VIEW && status != 'CANCELLED' && status != 'CLOSED') {
                if (this.checkPrivilege(objectName, 'Modify')) {
                    render = true;
                }
            }
        } else if (buttonId === 'cancelButton' && (viewMode === PAGE_MODE.VIEW || viewMode === PAGE_MODE.UPDATE)) {
            render = true;
        } else if (buttonId === 'splitButton') {
            if ((isSameContact === true ) && viewMode === PAGE_MODE.VIEW && status != 'CANCELLED' && status != 'CLOSED' && status != 'PENDING') {
                render = true;
            }
        } else if (buttonId === 'saveButton') {
            if (viewMode === PAGE_MODE.CREATE || viewMode === PAGE_MODE.UPDATE) {
                if ((this.checkPrivilege(objectName, 'Create') || this.checkPrivilege(objectName, 'Modify'))) {
                    render = true;
                }
            }
        }else if (buttonId === 'resumeButton') {
            if ((isSameContact === true || isAllowedTeam === true ) && viewMode === PAGE_MODE.VIEW && status == 'PENDING') {
                if (this.checkPrivilege(objectName, 'Resume')) {
                    render = true;
                }
            }
        }

        return render;
    }

    static getUpperCasedVariable = (data) => {
        return data.toUpperCase();
    }

    static getContact() {
        var hsmUserLocalStorage = JSON.parse(localStorage.getItem("hsmUser"));
        return (!this.__isValueEmpty(hsmUserLocalStorage)) ? hsmUserLocalStorage.contactId : null;
    }

    static getContactId() {
        var hsmUserLocalStorage = JSON.parse(localStorage.getItem("hsmUser"));
        return (!this.__isValueEmpty(hsmUserLocalStorage)) ? hsmUserLocalStorage.contactId.id : null;
    }

    static getUserId() {
        var hsmUserLocalStorage = JSON.parse(localStorage.getItem("hsmUser"));
        return (!this.__isValueEmpty(hsmUserLocalStorage)) ? hsmUserLocalStorage.id : null;
    }

    static getUserName() {
        var hsmUserLocalStorage = JSON.parse(localStorage.getItem("hsmUser"));
        return (!this.__isValueEmpty(hsmUserLocalStorage)) ? hsmUserLocalStorage.userName : null;
    }


    static getUserNameAndFamilyNames() {
        var hsmUserLocalStorage = JSON.parse(localStorage.getItem("hsmUser"));
        return (!this.__isValueEmpty(hsmUserLocalStorage)) ? hsmUserLocalStorage.contactId.givenNames + ' ' + (GeneralUtils.isNullOrEmpty(hsmUserLocalStorage.contactId.familyName) ? "":hsmUserLocalStorage.contactId.familyName)  : '';
    }

    static getUserRoleList() {
        var hsmUserLocalStorage = JSON.parse(localStorage.getItem("hsmUser"));
        return (!this.__isValueEmpty(hsmUserLocalStorage)) ? hsmUserLocalStorage.hsmRoleList : null;
    }

    static getPrivilegeListFromRoleList() {
        var hsmRoleList = this.getUserRoleList();
        let hsmPrivilegeList = []
        hsmRoleList.forEach((element) => {
            element.hsmPrivilegeList.forEach((hsmPrivilege) => {
                hsmPrivilegeList.push(hsmPrivilege);
            });
        });
        return (!this.__isValueEmpty(hsmPrivilegeList)) ? hsmPrivilegeList : null;
    }

    static getPrivilegeList() {
        var hsmPrivilegeList = JSON.parse(localStorage.getItem("hsmPrivilegeList"));
        return (!this.__isValueEmpty(hsmPrivilegeList)) ? hsmPrivilegeList : null;
    }

    //TODO: check these
    static getAssetStatus(serviceSubcategoryId) {
        if (serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CONNECT) {
            return [];
        } else if (serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_TRANSFER) {
            return ['ACTIVE', 'INSTALLED', 'SUSPENDED'];
        } else if (serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CANCEL) {
            return ['ACTIVE', 'INSTALLED', 'SUSPENDED'];
        } else if (serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_MOVE) {
            return ['ACTIVE'];
        } else if (serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_SUSPEND) {
            return ['ACTIVE'];
        } else if (serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CHANGE_TITLE) {
            return ['ACTIVE', 'INSTALLED', 'SUSPENDED'];
        } else if (serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_BILL_DISPUTE) {
            return ['ACTIVE', 'UNINSTALLED', 'SUSPENDED'];
        } else if (serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_BILLING_APPROVAL) {
            return ['INSTALLED', 'SUSPENDED'];
        } else if (serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_INCREASE_BW) {
            return ['ACTIVE'];
        } else if (serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_REDUCE_BW) {
            return ['ACTIVE'];
        } else if (serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CONNECT_BLOCK) {
            return ['ACTIVE'];
        } else if (serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_DISCONNECT_BLOCK) {
            return ['ACTIVE'];
        } else if (serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_MOVE_BLOCK) {
            return ['ACTIVE'];
        } else if (serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_WIFI_EQUIP_INSTALL) {
            return ['ACTIVE'];
        } else if (serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_WIFI_EQUIP_UNINSTALL) {
            return ['ACTIVE'];
        } else if (serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CHANGE_ADDRESS) {
            return ['ACTIVE'];
        }
    }


    static isExistsBbProduct = (requestAssetList) => {
        let isExists = false;
        for (let requestAsset of requestAssetList) {
            if (requestAsset.assetCategory === ASSET_CATEGORY.BROADBAND_PRODUCT) {
                isExists = true;
            }
        }
        return isExists;
    }

    static getBbProductFromRequestAssetList = (requestAssetList) => {
        for (let requestAsset of requestAssetList) {
            if (requestAsset.assetCategory === ASSET_CATEGORY.BROADBAND_PRODUCT) {
                return requestAsset.id;
            }
        }
    }

    static isExistsNetworkDevice = (requestAssetList) => {
        let isExists = false;
        for (let requestAsset of requestAssetList) {
            if (requestAsset.assetCategory === ASSET_CATEGORY.NETWORK_DEVICE) {
                isExists = true;
            }
        }
        return isExists;
    }

    static isExistsOpenTask = (taskList) => {
        let isExists = false;
        for (let task of taskList) {
            if (task.status == 'OPEN') {
                isExists = true;
            }
        }
        return isExists;
    }

    static addCreateInfo(data) {
        data.createdBy = this.getUserId();
        data.createdDate = new Date();
        return data;
    }

    static addUpdateInfo(data) {
        data.lastModifiedBy = this.getUserId();
        data.lastModifiedDate = new Date();
        return data;
    }

    static checkPrivilege(objectName, action) {
        var hsmPrivilegeList = this.getPrivilegeList();
        for (var element of hsmPrivilegeList) {
            if (element.objectName == objectName && element.action == action && element.status == 'A') {
                return true;
            }
        }
        return false;
    }


    static getPageModeFromPrivilege(objectName) {
        return GeneralUtils.checkPrivilege(objectName, 'Modify') ? PAGE_MODE.UPDATE : PAGE_MODE.VIEW;
    }

    static getDialogToolbarDisplay(objectName, pageMode) {
        if (pageMode === PAGE_MODE.UPDATE) {
            return GeneralUtils.checkPrivilege(objectName, 'Modify');
        } else if (pageMode === PAGE_MODE.CREATE) {
            return GeneralUtils.checkPrivilege(objectName, 'Create');
        }
    }

    static getDialogHeader(objectName, pageMode) {
        if (pageMode === PAGE_MODE.VIEW) {
            return objectName + ' Görüntüleme';
        } else if (pageMode === PAGE_MODE.UPDATE) {
            return objectName + '  Güncelleme';
        } else if (pageMode === PAGE_MODE.CREATE) {
            return 'Yeni ' + objectName + ' Oluşturma';
        }
    }

    static randomID() {
        return Math.random().toString(36).substr(2, 16);
    }

    static convertBps(data) {
        if (this.isNullOrEmpty(data)) {
            return 0;
        }
        var bps = 0;
        if (data.includes("Kbps")) {
            bps = new Number(data.replace(" Kbps", "")) * 1000;
        } else if (data.includes("Mbps")) {
            bps = new Number(data.replace(" Mbps", "")) * 1000000;
        } else if (data.includes("Gbps")) {
            bps = new Number(data.replace(" Gbps", "")) * 1000000000;
        } else {
            bps = new Number(data);
        }
        return bps;
    }

    static convertByte(bytes) {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    }

    static getReplaceValue(value) {
        return value.toString().replace("(", "").replace(")", "")
            .replace(" ", "").replace("-", "");
    }

    static isIP4 = (ipAddress) => {
        let regex = "\\b((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\\.|$)){4}\\b";
        if (ipAddress.match(regex)) {
            return true;
        }
        return false;
    }

    static excelDateToJSDate(serial) {
        let utc_days = Math.floor(serial - 25569);
        let utc_value = utc_days * 86400;
        let date_info = new Date(utc_value * 1000);
        let fractional_day = serial - Math.floor(serial) + 0.0000001;
        let total_seconds = Math.floor(86400 * fractional_day);
        let seconds = total_seconds % 60;
        total_seconds -= seconds;
        let hours = Math.floor(total_seconds / (60 * 60));
        let minutes = Math.floor(total_seconds / 60) % 60;

        return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
    }

    static isNumeric = (value) => {
        if (isNaN(value)) {
            return false;
        } else {
            return true;
        }
    };

    static isJSON = (str)=> {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }


    static getRandomString = (length) => {
        let randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
        }
        return result;
    }

    static getAddress = (location) => {
        let result = location.addressLine + "\n" + location.buildingName;
        if (!GeneralUtils.isNullOrEmpty(location.buildingNumber)) {
            result += " No: " + location.buildingNumber;
            if (!GeneralUtils.isNullOrEmpty(location.doorNumber)) {
                result += "/" + location.doorNumber;
            }
        }
        if (!GeneralUtils.isNullOrEmpty(location.levelNumber)) {
            result += " Kat: " + location.levelNumber;
        }
        result += "\n" + location.postalCode + " " + location.districtId.name + "/" + location.cityId.name;
        return result.replace("null", "");
    }

}
