import React from 'react';
import HsmSubnetColumn from '../../../shared/component/dataTable/columns/HsmSubnetColumn';
import DataTableAll from "../../../shared/component/dataTable/DataTableAll";
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import {Button} from "reactstrap";
import Translate from "../../../shared/language/Translate";
import BbProductColumn from "../../../shared/component/dataTable/columns/BbProductColumn";
import {PAGE_MODE, TENANT_ID} from "../../../shared/utils/Constants";
import Dialog from "../../../shared/component/dialog/Dialog";
import HsmSubnetSave from "./HsmSubnetSave";
import HsmSubnetService from "../../../shared/service/HsmSubnetService";
import HsmSubnetPoolService from "../../../shared/service/HsmSubnetPoolService";
import {toast} from "react-toastify";
import TenantService from "../../../shared/service/TenantService";


export default class HsmSubnet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hsmSubnetList: [],
            hsmSubnetRemovedList: [],
            pageMode: PAGE_MODE.UPDATE,
            locationId: null,
            organizationId: null,
        };
        this.service = new HsmSubnetService();
        this.hsmSubnetPoolService = new HsmSubnetPoolService();
        this.tenantService = new TenantService();
    }

    componentDidMount = () => {
        this.tenantService.getTenant().then(result => {
            if(!GeneralUtils.isNullOrEmpty(result)){
                this.setState({tenantId: result});
            }
        });
    }

    componentWillReceiveProps = (nextProps) => {
        if (this.props.data) {
            this.setState({hsmSubnetList: nextProps.data, pageMode: nextProps.pageMode, status: nextProps.status});
        }
        if( !GeneralUtils.isNullOrEmpty(nextProps.locationId) && !GeneralUtils.isNullOrEmpty(nextProps.organizationId)) {
            this.setState({ locationId: nextProps.locationId, organizationId:nextProps.organizationId});
        }
    }

    render() {
        return (
            <div>
                {(this.state.pageMode !== PAGE_MODE.VIEW || this.state.status === 'DRAFT') &&
                <Toolbar display={true}>
                    <div className="p-toolbar-group-right">
                        <Button color="primary" onClick={this.addNew}>
                            <Translate contentKey="entity.action.add">Subnet Ekle</Translate>
                        </Button>
                    </div>
                    {this.state.tenantId == TENANT_ID.MEB &&
                    <div className="p-toolbar-group-right">
                        <Button color="primary" onClick={this.autoLoadForSubnetPool}>
                            <Translate contentKey="entity.action.add">Otomatik Getir</Translate>
                        </Button>
                    </div>
                    }
                </Toolbar>}
                <DataTableAll ref={(elem) => this.refDataTable = elem} fields={HsmSubnetColumn.fields}
                              data={this.state.hsmSubnetList} objectName={HsmSubnetColumn.name} autoLoad={false}
                              selectionMode={(this.state.pageMode !== PAGE_MODE.VIEW || this.state.status === 'DRAFT') ? "single" : null}
                                  onDataSelect={this.onDataSelect} privilege="allow"/>

                <Dialog visible={this.state.displayDialog}
                        header={GeneralUtils.getDialogHeader('Subnet ', this.state.pageMode)} modal={true}
                        onHide={() => this.setState({displayDialog: false})} style={{width: '50vw'}}>
                    <HsmSubnetSave
                        ref={(elem) => this.refHsmSubnetSave = elem}
                        closeDisplayDialog={this.closeDisplayDialog}
                        refresh={this.refresh}
                        add={this.getListItem} delete={this.deleteListItem}
                        checkName={this.checkName}
                    />
                </Dialog>
            </div>
        );
    }

    refresh = () => {
        this.refDataTable.setParameter(this.state.productId);
        this.setState({displayDialog: false});
    }

    addNew = () => {
        this.setState({displayDialog: true, pageMode: PAGE_MODE.UPDATE, activeIndex: 0});
        this.refHsmSubnetSave.clearData();
        this.refHsmSubnetSave.setAssetIdAndPageMode(this.state.productId, PAGE_MODE.UPDATE);
    }

    setData = (data, pageMode) => {
        this.clearData();
        this.setState({locationId: data.locationId.id, organizationId: data.organizationId.id})
        this.service.findByProductId(data.id).then(response => {
            this.setState({hsmSubnetList: response ? response : [], pageMode: pageMode, productId: data.id})
        });
    }

    getData = () => {
        return this.state.hsmSubnetList;
    }

    getDataRemoved = () => {
        return this.state.hsmSubnetRemovedList;
    }

    clearData = () => {
        this.setState({hsmSubnetList: [], hsmSubnetRemovedList: [], productId: null, displayAutoLoadSubnet: false})
    }

    onDataSelect = (data) => {
        let pageMode = GeneralUtils.getPageModeFromPrivilege(BbProductColumn.name);
        this.setState({displayDialog: true, pageMode: pageMode});
        this.refHsmSubnetSave.clearData();
        this.refHsmSubnetSave.setData(Object.assign({}, data), pageMode);
    }

    closeDisplayDialog = () => {
        this.setState({displayDialog: false});
    }

    setPageMode = (pageMode) => {
        this.setState({pageMode: pageMode});
    }

    getListItem = (data) => {
        let hsmSubnetList = this.arrayRemove(this.state.hsmSubnetList, data);
        hsmSubnetList.push(data);
        this.setState({hsmSubnetList: hsmSubnetList});
        if(this.props.setHsmSubnetList){
            this.props.setHsmSubnetList(hsmSubnetList);
        }
    }

    deleteListItem = (data) => {
        let hsmSubnetRemovedList = this.state.hsmSubnetRemovedList;
        hsmSubnetRemovedList.push(data);
        let hsmSubnetList = this.arrayRemove(this.state.hsmSubnetList, data);
        this.setState({hsmSubnetList: hsmSubnetList, hsmSubnetRemovedList: hsmSubnetRemovedList})
        if(this.props.setHsmSubnetList){
            this.props.setHsmSubnetList(hsmSubnetList);
        }
    }

    arrayRemove = (arr, element) => {
        return arr.filter(function (el) {
            return el.name !== element.name;
        });
    }

    checkName = (selectedName) => {
        let hsmSubnetList = this.state.hsmSubnetList;
        for (let i = 0; i < hsmSubnetList.length; i++) {
            if (hsmSubnetList[i].name === selectedName) {
                return true;
            }
        }
        return false;
    }

    setPageModeAndStatus = (pageMode, status) => {
        this.setState({pageMode: pageMode, status: status});
    }

    autoLoadForSubnetPool = () => {
        this.hsmSubnetPoolService.findByOrganizationId(this.state.organizationId).then(response => {
            if (!GeneralUtils.isNullOrEmpty(response) && response.length>0) {
                response.forEach((element) => {
                    element.id = null;
                });
                toast.info('Organizasyon üzerinde tanımlı eski ip paketi otomatik getirilmiştir.');
                this.setState({hsmSubnetList: response});
            } else {
                this.hsmSubnetPoolService.findByGroupIdSubnetPool(this.state.locationId).then(response => {
                    if (!GeneralUtils.isNullOrEmpty(response) && response.length>0 ) {
                        response.forEach((element) => {
                            element.id = null;
                        });
                        toast.info('İP havuzundan ilgili il için sıradaki ip paketi otomatik getirilmiştir.');
                        this.setState({hsmSubnetList: response});
                    } else {
                        toast.info('İP havuzunda ilgili il için kayıtlı ip bulunamamıştır.');
                        this.setState({hsmSubnetList: []});
                    }
                });

            }
        });




        // this.hsmSubnetPoolService.findByGroupIdSubnetPool(this.state.organizationId, this.state.locationId).then(response => {
        //     if (!GeneralUtils.isNullOrEmpty(response) && response.length>0) {
        //         response.forEach((element) => {
        //             element.id = null;
        //         });
        //         this.setState({hsmSubnetList: response});
        //     } else {
        //         toast.info('İP havuzunda ilgili il için kayıtlı ip bulunamamıştır.');
        //         this.setState({hsmSubnetList: []});
        //     }
        // });
    }
}
