import React from 'react';
import {Panel} from 'primereact/panel';
import DataTable from '../../shared/component/dataTable/DataTable';
import AuditEventLogColumn from '../../shared/component/dataTable/columns/AuditEventLogColumn';
import AuditEventLogService from "../../shared/service/AuditEventLogService";
import GeneralUtils from "../../shared/utils/GeneralUtils";
import DataTableLog from "../../shared/component/dataTable/DataTableLog";

export default class AuditEventLog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            auditEventId: this.props.auditEventId
        };
        this.service = new AuditEventLogService();
    }

    render() {
        return (
            <Panel header="Tarihçe İzleme">
                <DataTable ref={(elem) => this.refDataTable = elem} fields={AuditEventLogColumn.fields} dataURL="auditEventLog/findByAuditEventId" autoLoad={false}/>
            </Panel>
        );
    }

    setData = (auditEventId) => {
        if (!GeneralUtils.isNullOrEmpty(auditEventId)) {
            this.refDataTable.setParameter(auditEventId);
            // this.service.findByAuditEventId(auditEventId).then(response => this.setState({data: response}));
        }
    }
}

