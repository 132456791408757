import moment from "moment";

const services = {
    auth: (window.location.protocol !== "https:" ? "http://" : "https://") + (window.location.hostname === "localhost" ? "localhost:8081" : window.location.hostname + (window.location.port !== 80 && window.location.port !== 443 ? ":" + window.location.port : "")) + "/authapi/",
    hermes: (window.location.protocol !== "https:" ? "http://" : "https://") + (window.location.hostname === "localhost" ? "localhost:8080" : window.location.hostname + (window.location.port !== 80 && window.location.port !== 443 ? "" + "" : "")) + "/hermes/"
}

export const convertDateTimeFromServer = date => (date ? moment(date).format(APP_LOCAL_DATETIME_FORMAT) : null);

export const convertDateTimeWithFormat = (date, format) => (date ? moment(date).format(format) : null);

export const SERVER_URL = services['hermes'];

export const APP_DATE_FORMAT = 'DD/MM/YYYY HH:mm';
export const APP_TIMESTAMP_FORMAT = 'DD/MM/YYYY HH:mm:ss';
export const APP_LOCAL_DATE_FORMAT = 'DD/MM/YYYY';
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDThh:mm';

export const LANGUAGES = {
    tr: {name: 'Türkçe'}
};

export const STATUS = [
    {name: 'Aktif', value: 'A'},
    {name: 'Pasif', value: 'P'}
];

export const IMPORTANCE = [
    {name: 'Yüksek', value: 'HIGH'},
    {name: 'Normal', value: 'NORMAL'},
    {name: 'Düşük', value: 'LOW'}
];

export const YESNO_STRING = [
    {name: 'Hayır', value: 'N'},
    {name: 'Evet', value: 'Y'}

];

export const NULLYESNO_STRING = [
    {name: 'Seçiniz', value: ''},
    {name: 'Hayır', value: 'N'},
    {name: 'Evet', value: 'Y'}

];

export const PAGE_MODE = {
    CREATE: 'create',
    UPDATE: 'update',
    VIEW: 'view'
};

export const REQUEST_TYPE = {
    INCIDENT: 'INCIDENT',
    SERVICE_REQUEST: 'SERVICE_REQUEST',
    BB_PRODUCT: 'BB_PRODUCT',
    HSM_ASSET: 'HSM_ASSET'
};

export const ATTACHED_DOCUMENT_STATUS = {
    AWAITING_APPROVAL: 'AWAITING_APPROVAL',
    APPROVED: 'APPROVED',
    SUSPENDED: 'SUSPENDED'
};

export const ASSET_CATEGORY = {
    NETWORK_DEVICE: 'NETWORK_DEVICE',
    BROADBAND_PRODUCT: 'BROADBAND_PRODUCT',
    ENCLOSURE: 'ENCLOSURE',
    USER_DEVICE: 'USER_DEVICE'
};


export const DEVICE_TYPES = [
    {name: 'Router', value: 'Router'},
    {name: 'Switch', value: 'Switch'},
    {name: 'Firewall', value: 'Firewall'},
    {name: 'Load Balancer', value: 'LoadBalancer'},
    {name: 'Network Device', value: 'NetworkDevice'}
];

export const CONTACT_TYPE = [
    {name: 'Uygulama Kullanıcısı', value: 'ApplicationUser'},
    {name: 'Yardım Masası', value: 'HelpdeskPerson'},
    {name: 'Teknisyen', value: 'SupportPerson'},
    {name: 'Teknik Kişi', value: 'TechnicalPerson'},
    {name: 'Yetkili Kişi', value: 'AuthorizedPerson'}
];

export const TEAM_CONTACT_TYPE = [
    {name: 'Ekip Üyesi', value: 'TeamMember'},
    {name: 'Ekip Yöneticisi', value: 'TeamManager'}
];

export const LOCATION_TYPES = [
    {name: 'Bina', value: 'Building'}
];

export const EXISTS = [
    {name: 'Var', value: 'Var'},
    {name: 'Yok', value: 'Yok'}
];

export const BILL_PREFERENCE = [
    {name: 'Elektronik Fatura', value: 'ElectronicInvoice'},
    {name: 'Basılı Fatura', value: 'PrintedInvoice'}
];


export const ORGANIZATION_ROLE = [
    {name: 'Müşteri', value: 'CustomerOrganization'},
    {name: 'Servis Sağlayıcı', value: 'ProviderOrganization'},
    {name: 'Çözüm Ortağı', value: 'PartnerOrganization'}
];

export const ORGANIZATION_SECTOR = [
    {name: 'Kamu', value: 'PublicSector'},
    {name: 'Özel', value: 'PrivateSector'}
];

export const URGENCY_TYPE = [
    {name: 'Çok Acil', value: '1'},
    {name: 'Acil', value: '2'},
    {name: 'Orta', value: '3'},
    {name: 'Düşük', value: '4'}

];

export const IMPACT_TYPE = [
    {name: 'Kurum', value: '1'},
    {name: 'Bölüm / Grup', value: '2'},
    {name: 'Birkaç Kişi', value: '3'},
    {name: 'Tek Kişi', value: '4'}

];

export const PRIORITY_TYPE = [
    {name: 'Kritik', value: '1'},
    {name: 'Yüksek', value: '2'},
    {name: 'Orta', value: '3'},
    {name: 'Düşük', value: '4'}

];

export const CHANNEL_TYPE = [
    {name: 'Portal', value: '1'},
    {name: 'Web Form', value: '2'},
    {name: 'E-Posta', value: '3'},
    {name: 'İzleme', value: '4'}

];

export const FATIH_PHASES = [
    {name: 'Faz-1', value: 'Faz-1'},
    {name: 'Faz-2', value: 'Faz-2'}

];


export const YESNO = [
    {name: 'Hayır', value: '0'},
    {name: 'Evet', value: '1'}

];
export const NETWORK_INTERFACE_NAME = [
    {name: 'MANAGEMENT IP', value: 'MNGMT_IP', vlanId: ''}
];



export const SUBNET_NAME_MEB_CONST = {
    WAN_IP_BLOCK: 'WAN IP BLOCK',
    MEB_NETWORK: 'MEB NETWORK',
    REEL_NETWORK: 'REEL NETWORK',
    MANAGEMENT_NETWORK: 'MANAGEMENT NETWORK'
};

export const INCIDENT_STATUS = [
    {name: 'Yeni', value: 'NEW'},
    {name: 'Onay Bekliyor', value: 'AWAITING_APPROVAL'},
    {name: 'Açık', value: 'OPEN'},
    {name: 'Çözüldü', value: 'RESOLVED'},
    {name: 'Kapalı', value: 'CLOSED'},
    {name: 'İptal', value: 'CANCELLED'},
    {name: 'Askıda', value: 'PENDING'}
];

export const SERVICE_REQUEST_STATUS = [
    {name: 'Taslak', value: 'DRAFT'},
    {name: 'Yeni', value: 'NEW'},
    {name: 'Onay Bekliyor', value: 'AWAITING_APPROVAL'},
    {name: 'Açık', value: 'OPEN'},
    {name: 'Çözüldü', value: 'RESOLVED'},
    {name: 'Kapalı', value: 'CLOSED'},
    {name: 'İptal', value: 'CANCELLED'},
    {name: 'Askıda', value: 'PENDING'}
];

export const OBJECT_STATUS = [
    {name: 'Arıza', value: 'INCIDENT'},
    {name: 'Hizmet Talebi', value: 'SERVICE_REQUEST'}
];

export const TASK_STATUS = [
    {name: 'Açık', value: 'OPEN'},
    {name: 'Kapalı', value: 'CLOSED'}
];

export const TASK_NAME = [
    {name: 'Cihaz Sevk', value: 'ShipmentTask'},
    {name: 'Fiber Kurulum', value: 'InstallationTask'},
    {name: 'Keşif', value: 'DiscoveryTask'},
    {name: 'Envanter Girişi', value: 'InventoryEntry'},
    {name: 'Wifi Kurulum', value: 'WifiEquipInstall'},
];

export const DOCUMENT_TYPE = [
    {name: 'Diğer', value: 'OTHER_FORM'},
    {name: 'İptal Formu', value: 'CANCELLATION_FORM'},
    {name: 'Devir Formu', value: 'TRANSFER_FORM'},
    {name: 'Hizmet Formu', value: 'SERVICE_FORM'},
    {name: 'Erişim Faaliyet Tutanağı', value: 'ARRIVAL_OPERATION_FORM'},
    {name: 'Sipariş Formu', value: 'ORDER_FORM'},
    {name: 'Fatura', value: 'BILLING_FORM'},
    {name: 'Mücbir Askıya Alma Formu', value: 'COMPELLING_FORM'},
    {name: 'Kurulum Formları', value: 'INSTALLATION_FORMS'},
    {name: 'Keşif Formu', value: 'DISCOVERY_FORM'},
    {name: 'Servis Formu', value: 'SERVICE_FORM_2'},
    {name: 'Demontaj Formu', value: 'UNINSTALL_FORM'},
    {name: 'Lokal Aktarım Formu', value: 'LOCAL_MIGRATION_FORM'},
    {name: 'Garanti Belgesi', value: 'WARRANTY_CERTIFICATE'}
];

export const INCIDENT_CONFIRMATION_STATUS = [
    {name: 'Memnun', value: '1'},
    {name: 'Memnun değil', value: '2'}
];

export const INCIDENT_CONFIRMATION_STATUS_ALL = [
    {name: 'Memnun', value: '1'},
    {name: 'Memnun değil', value: '2'},
    {name: 'Olumlu (Otomatik Teyit)', value: '3'}
];

export const BILLING_APPROVAL_STATUS_OK = [
    {name: 'Faturalamaya onay verilmiştir.', value: true},
];

export const BILLING_APPROVAL_STATUS_ALL = [
    {name: 'Faturalamaya onay verilmemiştir.', value: false},
    {name: 'Faturalamaya onay verilmiştir.', value: true},
];


export const PENDING_STATUS = [
    {name: 'Müşteriye Ulaşılamıyor', value: 'CUSTOMER_UNREACHABLE'},
    {name: 'Müşteriden Randevu Bekleniyor', value: 'WAITING_CUSTOMER_APPOINTMENT'},
    {name: 'Beklenmedik Durum ya da Mücbir Sebep', value: 'UNEXPECTED_CONDITION'},
    {name: 'Nakil Sebebiyle', value: 'DUE_TO_MOVE'}
];

export const INCIDENT_STATUS_REASON = [
    {name: 'Müşteriye Ulaşılamıyor', value: 'CUSTOMER_UNREACHABLE'},
    {name: 'Müşteriden Randevu Bekleniyor', value: 'WAITING_CUSTOMER_APPOINTMENT'},
    {name: 'Beklenmedik Durum ya da Mücbir Sebep', value: 'UNEXPECTED_CONDITION'},
    {name: 'Seviye 1 Onayı', value: 'FIRST_LINE_APPROVAL'},
    {name: 'Nakil Sebebiyle', value: 'DUE_TO_MOVE'},
    {name: 'Teyit Bekliyor', value: 'AWAITING_CONFIRMATION'}
];

export const SERVICE_REQUEST_STATUS_REASON = [
    {name: 'Seviye 1 Onayı', value: 'FIRST_LINE_APPROVAL'},
    {name: 'Kurulum Onayı', value: 'POST_INSTALL_APPROVAL'},
    {name: 'Faturalama Onayı', value: 'BILLING_APPROVAL'},
    {name: 'Müşteriye Ulaşılamıyor', value: 'CUSTOMER_UNREACHABLE'},
    {name: 'Müşteriden Randevu Bekleniyor', value: 'WAITING_CUSTOMER_APPOINTMENT'},
    {name: 'Beklenmedik Durum ya da Mücbir Sebep', value: 'UNEXPECTED_CONDITION'},
    {name: 'Tamamlandı', value: 'COMPLETED'},
    {name: 'Gerçekleşmedi', value: 'INCOMPLETED'},
    {name: 'Teyit Bekliyor', value: 'AWAITING_CONFIRMATION'},
    {name: 'Kapatılmayı Bekliyor', value: 'AWAITING_CLOSURE'}
];

export const SERVICE_CATEGORY = {
    TTVPN: '1'
};


export const SERVICE_SUBCATEGORY = {
    TTVPN_CONNECT: '2',
    TTVPN_TRANSFER: '3',
    TTVPN_CANCEL: '4',
    TTVPN_MOVE: '5',
    TTVPN_SUSPEND: '6',
    TTVPN_CHANGE_TITLE: '7',
    TTVPN_BILL_DISPUTE: '8',
    TTVPN_BILLING_APPROVAL: '9',
    TTVPN_INCREASE_BW: '10',
    TTVPN_REDUCE_BW: '11',
    TTVPN_CONNECT_BLOCK: '12',
    TTVPN_DISCONNECT_BLOCK: '13',
    TTVPN_MOVE_BLOCK: '14',
    TTVPN_WIFI_EQUIP_INSTALL: '15',
    TTVPN_WIFI_EQUIP_UNINSTALL: '16',
    TTVPN_CHANGE_ADDRESS: '17',
    TTVPN_AUTOMATIC_SWITCH_TROUBLE: '201',
    TTVPN_AUTOMATIC_ROUTER_TROUBLE: '202',
    TTVPN_AUTOMATIC_ACCESS_POINT_TROUBLE: '203',
    TTVPN_AUTOMATIC_FIREWALL_TROUBLE: '204'

};

export const CANCELLATION_TYPE = [
    {name: 'Müşteri İsteği İle İptal', value: 'CANCELLATION_FOR_CUSTOMER'},
    {name: 'Hizmet Türü ve Alt Hizmet Türü Değişikliği Nedeniyle İptal', value: 'CANCELLATION_FOR_ALTERATION'}
];

export const BB_PRODUCT_STATUS = [
    {name: 'Tümü', value: null},
    {name: 'Pasif', value: 'PASSIVE'},
    {name: 'Kurulu', value: 'INSTALLED'},
    {name: 'Askıda', value: 'SUSPENDED'},
    {name: 'Aktif', value: 'ACTIVE'},
    {name: 'İptal', value: 'UNINSTALLED'}
];

export const REGION_TYPE = [
    {name: 'Metropol', value: 'Metropolitan'},
    {name: 'Kırsal', value: 'Countryside'}
];

export const YEARS = [
    {name: '2018', value: '2018'},
    {name: '2019', value: '2019'},
    {name: '2020', value: '2020'},
    {name: '2021', value: '2021'},
    {name: '2022', value: '2022'},
    {name: '2023', value: '2023'},
    {name: '2024', value: '2024'},
    {name: '2025', value: '2025'},
    {name: '2026', value: '2026'},
    {name: '2027', value: '2027'}
];

export const MONTHS = [
    {name: '1', value: '01'},
    {name: '2', value: '02'},
    {name: '3', value: '03'},
    {name: '4', value: '04'},
    {name: '5', value: '05'},
    {name: '6', value: '06'},
    {name: '7', value: '07'},
    {name: '8', value: '08'},
    {name: '9', value: '09'},
    {name: '10', value: '10'},
    {name: '11', value: '11'},
    {name: '12', value: '12'}
];

export const ASSET_OWNER = [
    {name: 'Müşteri', value: 'Customer'},
    {name: 'Türk Telekom', value: 'Provider'}
];

export const PREFERRED_ACCESS_TECH = [
    {name: 'Bakır', value: 'Copper'},
    {name: 'Fiber', value: 'Fiber'},
    {name: 'R/L', value: 'Radiolink'},
    {name: 'Mevcut Devre/Hizmet', value: 'PresentService'},
];

export const NOTICE_TYPE = [
    {name: 'Seçiniz', value: ''},
    {name: 'Genel Arıza', value: 'General Network Trouble'},
    {name: 'Planlı Bakım', value: 'Planned Maintenance'}
];

export const TENANT_ID = {
    MEB: 'MEB',
    GSB: 'GSB',
    KB: 'KB'
};

export const GUEST_CONTACT_ID = 4;

export const GENERIC_BRAND_ID = 1;
export const GENERIC_MODEL_ID = 1;

export const SYS_ADMIN = 1;
export const ORG_ADMIN = 10;

export const AUTHORIZATION_HEADER = 'Authorization';

export const ORDER_FORM_NAME = 'Fatih_VPN_Sipariş_Formu_' + convertDateTimeWithFormat(new Date(), APP_LOCAL_DATE_FORMAT)+ '.xlsx';

export const NETWORK_DEVICE_ASSET_TYPE=1;
export const ENCLOSURE_ASSET_TYPE=4;

export const MRTG_LINK = 'https://monitor.turktelekom.com.tr/ttmonitor/login.jsp';

export const  MILLISECONDS_ONE_HOUR =  3600000;

export const BB_PRODUCT_LOCATION_TYPE = [
    {name: 'Tüm Lokasyonlar', value: 'SERVED'},
    {name: 'Sadece Kurulu Lokasyonlar', value: 'INSTALLED'},
];

export const TRUEFALSE = [
    {name: 'Evet', value: true},
    {name: 'Hayır', value: false}
];

export const SLT_TYPE=[ 
    {name: 'ResponseSLT', value: 'ResponseSLT'},
    {name: 'ResolutionSLT', value: 'ResolutionSLT'},
    {name: 'DeliverySLT', value: 'DeliverySLT'}
];

export const SLT_UNITS=[
    {name: 'Gün', value: 'DAYS'},
    {name: 'Saat', value: 'HOURS'},
    {name: 'Dakika', value: 'MINUTES'}
];

export const PENALTY_ROUNDING=[
    {name: 'Yukarı', value: 'UP'},
    {name: 'Aşağı', value: 'DOWN'},
    {name: 'Tam', value: 'EXACT'}
];

export const ACCESS_TECHNOLOGY={
    xDSL: 'xDSL',
    ME: 'ME'
};

export const FLOW_ID=[
    {name: 'support', value: 'support'},
    {name: 'theStart', value: 'theStart'},
    {name: 'theEnd', value: 'theEnd'},
    {name: 'firstLineApproval', value: 'firstLineApproval'},
    {name: 'providerClosure', value: 'providerClosure'},
    {name: 'partnerSupport', value: 'partnerSupport'},
    {name: 'postInstallApproval', value: 'postInstallApproval'},
    {name: 'providerSupport', value: 'providerSupport'},
    {name: 'firstLineConfirmation', value: 'firstLineConfirmation'},
];

export const SERVICE_NAME=[
    {name: 'INNOVA SM', value: 'INNOVA_SM_INCIDENT'},
    {name: 'SERDOO', value: 'SERDOO_TICKET'},
    {name: 'AGOLA', value: 'AGOLA'},
    {name: 'HITIT', value: 'HITIT_TICKET'},
    {name: 'PYS', value: 'PYS_TICKET'}
];

export const REQUEST_TYPES_CONS=[
    {name: 'Hizmet Talebi', value: 'SERVICE_REQUEST'},
    {name: 'Arıza Talebi', value: 'INCIDENT'}
];