import Service from './Service';
import GeneralUtils from "../utils/GeneralUtils";
import {SERVER_URL} from "../utils/Constants";

export default class ServiceRequestReportService extends Service {
    constructor() {
        super();
        this.baseURL = 'serviceRequestReport';
    }

    getConnectServiceRequestCount() {
        return fetch(SERVER_URL + this.baseURL + "/getConnectServiceRequestCount", {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    getTransferServiceRequestCount() {
        return fetch(SERVER_URL + this.baseURL + "/getTransferServiceRequestCount", {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    getCancelServiceRequestCount() {
        return fetch(SERVER_URL + this.baseURL + "/getCancelServiceRequestCount", {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    getMoveServiceRequestCount() {
        return fetch(SERVER_URL + this.baseURL + "/getMoveServiceRequestCount", {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    getPendingServiceRequestCount() {
        return fetch(SERVER_URL + this.baseURL + "/getPendingServiceRequestCount", {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    getChangeTitleServiceRequestCount() {
        return fetch(SERVER_URL + this.baseURL + "/getChangeTitleServiceRequestCount", {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    getBillDisputeServiceRequestCount() {
        return fetch(SERVER_URL + this.baseURL + "/getBillDisputeServiceRequestCount", {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    getBillingApprovalServiceRequestCount() {
        return fetch(SERVER_URL + this.baseURL + "/getBillingApprovalServiceRequestCount", {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    getIncreaseBWServiceRequestCount() {
        return fetch(SERVER_URL + this.baseURL + "/getIncreaseBWServiceRequestCount", {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    getReduceBWServiceRequestCount() {
        return fetch(SERVER_URL + this.baseURL + "/getReduceBWServiceRequestCount", {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

}