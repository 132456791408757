import React from 'react';
import {Panel} from 'primereact/panel';
import DataTable from '../../../shared/component/dataTable/DataTable';
import HsmDocumentService from "../../../shared/service/HsmDocumentService";
import HsmDocumentColumn from '../../../shared/component/dataTable/columns/HsmDocumentColumn';
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import {Button} from 'reactstrap';
import HsmDocumentSave from "./HsmDocumentSave";
import {TabPanel, TabView} from "primereact/tabview";
import {PAGE_MODE} from "../../../shared/utils/Constants";
import Dialog from "../../../shared/component/dialog/Dialog";

export default class HsmDocument extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            selected: {},
            pageMode: null
        };
        this.service = new HsmDocumentService();
    }

    componentDidMount = () => {
        this.findAll();
    }

    render() {
        return (
            <Panel header="Yeni Doküman Yönetimi">
                <Button color="primary" onClick={() => {
                    this.setState({displayDialog: true, pageMode: PAGE_MODE.CREATE, activeIndex: 0});
                    this.refHsmDocumentSave.clearData()
                }}>
                    Yeni Kayıt
                </Button>

                <DataTable fields={HsmDocumentColumn.fields} data={this.state.data} selectionMode="single"
                           onDataSelect={this.onDataSelect} action={true}/>

                <Dialog visible={this.state.displayDialog}
                        header={this.state.pageMode === PAGE_MODE.UPDATE ? 'Doküman Güncelleme' : 'Yeni Doküman Oluşturma'}
                        onHide={() => this.setState({displayDialog: false})} style={{width: '50vw'}}>
                    <TabView activeIndex={this.state.activeIndex}
                             onTabChange={(e) => this.setState({activeIndex: e.index})} renderActiveOnly={false}>
                        <TabPanel header="Doküman Bilgileri" modal={true}>
                            <HsmDocumentSave refresh={this.findAll} ref={(elem) => this.refHsmDocumentSave = elem}
                                             closeDisplayDialog={this.closeDisplayDialog}
                                             changePageMode={this.changePageMode} pageMode={this.state.pageMode}/>
                        </TabPanel>

                        <TabPanel header="Tarihçe" rightIcon="pi pi-user"
                                  disabled={this.state.pageMode === PAGE_MODE.CREATE}
                                  headerClassName={this.state.pageMode === PAGE_MODE.CREATE && 'displayNone'}>

                            Tarihçe
                        </TabPanel>
                    </TabView>
                </Dialog>
            </Panel>
        );
    }

    findAll = () => {
        this.service.findAll().then(response => this.setState({data: response}));
        this.setState({displayDialog: false})
    }

    save = (newData) => {
        if (!GeneralUtils.isNullOrEmpty(newData.id)) {
            this.service.update(newData).then(() => {
                this.refHsmDocumentSave.setData(PAGE_MODE.UPDATE);
                this.findAll();
            });
        } else {
            this.service.create(newData).then(() => {
                this.refHsmDocumentSave.setData(PAGE_MODE.CREATE);
                this.findAll();
            });
        }
    }
    onDataSelect = (data) => {
        this.setState({
            displayDialog: true,
            pageMode: PAGE_MODE.UPDATE,
            activeIndex: 0
        });
        this.refHsmDocumentSave.setData(Object.assign({}, data), PAGE_MODE.UPDATE);
    }

    closeDisplayDialog = () => {
        this.setState({displayDialog: false});
    }

    changePageMode = () => {
        this.setState({pageMode: PAGE_MODE.UPDATE});
    }
}
