import React from 'react';
import {Panel} from 'primereact/panel';
import DataTable from "../../../shared/component/dataTable/DataTable";
import {PAGE_MODE} from "../../../shared/utils/Constants";
import RatingReportColumn from "../../../shared/component/dataTable/columns/RatingReportColumn";
import RatingReportTtColumn from "../../../shared/component/dataTable/columns/RatingReportTtColumn";
import RatingReportService from "../../../shared/service/RatingReportService";
import {Calendar} from "primereact/calendar";
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import {Button, Label, Row} from "reactstrap";
import Translate from "../../../shared/language/Translate";
import {AvForm} from "availity-reactstrap-validation";
import {toast} from "react-toastify";


export default class RatingReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            year: null,
            month: null,
            selected: {},
            pageMode: null,
            date: null,
            loading: false,
            fields: RatingReportColumn.fields,
            param: '',
            totalPayAmount: '0.00TL',
            totalPenaltyAmount: '0.00TL',
            totalDuration: '00:00:00',
            totalDurationHour: '0,00'
        };
        this.service = new RatingReportService();
    }

    componentDidMount = () => {
        this.service.checkPage();
        let parameter = window.location.href.split('?')[1];
        if (!GeneralUtils.isNullOrEmpty(parameter)) {
            let value = parameter.split('=')[1];
            this.setState({fields: RatingReportTtColumn.fields, param: value});
        }
    }

    render() {
        const tr = {
            monthNames: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
            monthNamesShort: ['Oca', 'Şub', 'Mar', 'Nis', 'May', 'Haz', 'Tem', 'Ağu', 'Eyl', 'Eki', 'Kas', 'Ara'],
            today: ['Bugün'],
            clear: ['Temizle']
        };

        return (
            <Panel header="Aylık Hakediş Raporu">

                <AvForm onValidSubmit={this.findByMonthAndYear}>
                    <Toolbar display={true}>
                        <div className="p-toolbar-group-right ">
                            <Button color="primary" type="submit" className="float-right">
                                <Translate>Göster</Translate>
                            </Button>
                        </div>
                    </Toolbar>
                    <div style={{width: 300}}>
                        <h3>Filtre</h3>
                        <div className='p-grid p-fluid '>
                            <Calendar
                                tooltip="Ay/Yıl Seç"
                                required={true}
                                style={{margin: 20}}
                                placeholder="Ay/Yıl Seç"
                                value={this.state.date}
                                onChange={e => this.setState({date: e.value})}
                                view='month'
                                dateFormat='mm/yy'
                                yearNavigator={true}
                                yearRange='2010:2030'
                                showButtonBar={true}
                                readOnlyInput={true}
                                showIcon={true}
                                locale={tr}
                            />
                        </div>
                    </div>
                </AvForm>


                <DataTable ref={(elem) => this.refDataTable = elem} fields={this.state.fields}
                           dataURL="ratingReport/findByMonthAndYear" objectName={RatingReportColumn.name}
                           loading={this.state.loading} export={true}
                    // selectionMode="single"
                    // onDataSelect={this.onDataSelect}
                           autoLoad={false} refresh={false}/>

                <Toolbar display={true}>
                    <div className="p-toolbar-group-right ">
                        <Row className="justify-content-center footerToolBar">
                            <Label for="total">Toplam: </Label>
                            <Label
                                for="totalDuration">{!GeneralUtils.__isValueEmpty(this.state.totalDuration) ? this.state.totalDuration : '00:00:00'} </Label>
                            <Label
                                for="totalDurationHour">{!GeneralUtils.__isValueEmpty(this.state.totalDurationHour) ? this.state.totalDurationHour : '0,00'} </Label>
                            <Label
                                for="totalPenaltyAmount">{!GeneralUtils.__isValueEmpty(this.state.totalPenaltyAmount) ? this.state.totalPenaltyAmount : '0.00TL'}  </Label>
                            <Label
                                for="totalPayAmount">{!GeneralUtils.__isValueEmpty(this.state.totalPayAmount) ? this.state.totalPayAmount : '0.00TL'} </Label>
                        </Row>
                    </div>
                </Toolbar>

            </Panel>
        );
    }

    findByMonthAndYear = () => {
        this.setState({loading: true});
        let date = this.state.date;
        if (GeneralUtils.isNullOrEmpty(date)) {
            toast.warn("Lütfen Ay/Yıl Seç Alanını Doldurunuz.");
            this.setState({loading: false});
            return;
        }
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        this.refDataTable.setParameter(month, year);
        this.setState({month: month, year: year})
        // this.service.findByMonthAndYear(month,year).then(data => {
        //     this.setState({data:data,loading:false})
        // })

        this.getTotalValues();
    }

    onDataSelect = (data) => {
        this.setState({displayDialog: true, pageMode: PAGE_MODE.UPDATE, activeIndex: 0});
        this.refBbProductSave.setData(Object.assign({}, data), PAGE_MODE.UPDATE);
    }

    closeDisplayDialog = () => {
        this.setState({displayDialog: false});
    }

    getTotalValues = () => {
        let totalPayAmount = 0;
        let totalPenaltyAmount = 0;
        let totalDuration = 0;
        let totalDurationHour = 0;

        this.service.findTotalAmountAndDurationByMonthAndYear(this.state.month, this.state.year).then((response) => {
            if (this.state.param == 'Tt') {
                totalPayAmount = response.totalPayAmountTT;
                totalPenaltyAmount = response.totalPenaltyAmountTT;
                totalDuration = response.totalDurationTT;
                totalDurationHour = response.totalDurationHourTT;
                this.setState({
                    totalPayAmount: totalPayAmount,
                    totalPenaltyAmount: totalPenaltyAmount,
                    totalDuration: totalDuration,
                    totalDurationHour: totalDurationHour
                });

            } else {
                totalPayAmount = response.totalPayAmount;
                totalPenaltyAmount = response.totalPenaltyAmount;
                totalDuration = response.totalDuration;
                totalDurationHour = response.totalDurationHour;

                this.setState({
                    totalPayAmount: totalPayAmount,
                    totalPenaltyAmount: totalPenaltyAmount,
                    totalDuration: totalDuration,
                    totalDurationHour: totalDurationHour
                });
            }

        });
    }
}
