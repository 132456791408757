import React from 'react';
import {Col, Label, Row,} from 'reactstrap';
import {AvGroup} from 'availity-reactstrap-validation';
import Translate from "../../../shared/language/Translate";
import HsmRoleService from "../../../shared/service/HsmRoleService";
import HsmUserService from "../../../shared/service/HsmUserService";
import ContactColumn from '../../../shared/component/dataTable/columns/ContactColumn';
import ContactService from "../../../shared/service/ContactService";
import AvField from "../../../shared/component/form/AvField";
import {
    APP_DATE_FORMAT,
    convertDateTimeWithFormat,
    ORG_ADMIN,
    PAGE_MODE,
    STATUS, SYS_ADMIN,
    YESNO_STRING
} from "../../../shared/utils/Constants";
import {PickList} from 'primereact/picklist';
import {Fieldset} from "primereact/fieldset";
import ContactGroup from "../../../shared/component/hsmGroup/ContactGroup";
import OrganizationGroup from "../../../shared/component/hsmGroup/OrganizationGroup";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import {PhoneField} from "../../../shared/component/form/PhoneField";

const defaultUser = {
    id: '',
    userName: '',
    status: 'P',
    language: 'tr',
    hsmRoleList: [],
    createdBy: '',
    contactId: {},
    createdDate: null,
    lastModifiedBy: '',
    lastModifiedDate: null,
    locked: 'N',
    loginRetry: 0
};

// const languages = {
//     tr: {name: 'Türkçe'},
//     en: {name: 'İngilizce'}
// };
// const locales = Object.keys(languages).sort();

export default class HsmUserSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: defaultUser,
            roleList: [],
            avaibleRoleList: [],
            selectedRoleList: [],

            contactData: null,
            contact: null,
            email: null,
            mobilePhone: null,
            organization: null,
            pageMode: null,
            displayAllowedOrganization: false
        }
        this.service = new HsmUserService();
        this.hsmRoleService = new HsmRoleService();
        this.contactService = new ContactService();
    }

    componentDidMount = () => {
        this.hsmRoleService.findAll().then(response => this.setState({roleList: response}));
    }

    render() {
        return (
            <div>
                <Row className="justify-content-center">
                    <Col md="8">
                        <AvGroup>
                            <Label for="userName">
                                <Translate contentKey="userManagement.userName">Kullanıcı</Translate>
                                <span style={{color: 'red'}}> (*)</span>
                            </Label>
                            <AvField disabled={this.state.pageMode === PAGE_MODE.UPDATE}
                                     type="text"
                                     className="form-control"
                                     name="userName"
                                     validate={{
                                         required: {
                                             value: true,
                                             errorMessage: 'Bu alan zorunlu.'
                                         },
                                         pattern: {
                                             value: '^[_.@A-Za-z0-9-]*$',
                                             errorMessage: 'Bu alan ^[_.@A-Za-z0-9-]*$ şablonuna uymuyor.'
                                         },
                                         minLength: {
                                             value: 1,
                                             errorMessage: 'Bu alan en az 1 karakterden oluşabilir.'
                                         },
                                         maxLength: {
                                             value: 50,
                                             errorMessage: 'Bu alan en fazla 50 karakterden oluşabilir.'
                                         }
                                     }}
                                     value={this.state.user.userName}
                                     onChange={this.__handleChange}
                            />
                        </AvGroup>
                        <Fieldset legend="Kontak Bilgileri">
                            <ContactGroup
                                value={this.state.contact}
                                onDataSelect={this.onContactSelect}
                                dataTable={{
                                    dataURL: "contact/findNotUsedInHsmUser",
                                    fields: ContactColumn.fields
                                }}
                                disabled={this.state.pageMode === PAGE_MODE.VIEW || this.state.pageMode === PAGE_MODE.UPDATE}/>

                            <AvGroup>
                                <Label for="organization">
                                    <Translate contentKey="userManagement.organization">Organizasyon</Translate>
                                </Label>
                                <AvField type="text" className="form-control" name="organization" disabled
                                         value={this.state.organization}/>{' '}
                            </AvGroup>

                            <AvGroup>
                                <Label for="mobilePhone">
                                    <Translate contentKey="userManagement.mobilePhone">GSM No</Translate>
                                </Label>
                                <PhoneField kind={'mobile'} disabled
                                            name="mobilePhone"
                                            value={this.state.mobilePhone}
                                />
                            </AvGroup>
                            <AvGroup>
                                <Label for="email">
                                    <Translate contentKey="userManagement.email">E-Posta</Translate>
                                </Label>
                                <AvField type="text" className="form-control" name="email" disabled
                                         value={this.state.email}/>{' '}
                            </AvGroup>
                        </Fieldset>
                        <AvGroup>
                            <PickList source={this.state.avaibleRoleList} target={this.state.selectedRoleList}
                                      itemTemplate={this.pickListTemplate}
                                      onChange={(e) => {this.setState({
                                          avaibleRoleList: e.source,
                                          selectedRoleList: e.target
                                      });
                                      // this.checkAllowedOrganization(e.target);
                                      }
                                      }
                                      sourceHeader="Roller" targetHeader="Seçili Roller" responsive={true}
                                      showSourceControls={false} showTargetControls={false}
                                      sourceStyle={{height: '150px'}} targetStyle={{height: '150px'}}/>
                        </AvGroup>

                        {/*{(this.state.displayAllowedOrganization && GeneralUtils.getUserId() == SYS_ADMIN) &&*/}
                        {/*<OrganizationGroup*/}
                        {/*    label="İzin Verilen Organizasyon"*/}
                        {/*    value={this.state.allowedOrganization}*/}
                        {/*    onDataSelect={this.onAllowedOrganizationSelect}*/}
                        {/*    disabled={this.state.pageMode === PAGE_MODE.VIEW}*/}
                        {/*/>*/}
                        {/*}*/}

                        <AvGroup>
                            <Label for="status">
                                <Translate contentKey="userManagement.status">Durum</Translate>
                            </Label>
                            <AvField type="select" className="form-control" name="status"
                                     value={this.state.user.status} onChange={this.__handleChange}
                                     disabled={this.state.contactStatus === 'P'}>
                                {STATUS.map(element => (
                                    <option value={element.value} key={element.value}>
                                        {element.name}
                                    </option>
                                ))}
                            </AvField>
                        </AvGroup>
                        {this.state.pageMode === PAGE_MODE.UPDATE &&
                        <Row>
                            <Col>
                                <AvGroup>
                                    <Label for="loginRetry">
                                        <Translate contentKey="userManagement.loginRetry">Hatalı Giriş</Translate>
                                    </Label>
                                    <AvField type="text" className="form-control" name="loginRetry" disabled
                                             value={!this.state.user.loginRetry ? "0" : this.state.user.loginRetry}/>{' '}
                                </AvGroup>
                                <AvGroup>
                                    <Label for="locked">
                                        <Translate contentKey="userManagement.locked">Kilitli</Translate>
                                    </Label>
                                    <AvField type="select" className="form-control" name="locked" disabled
                                             value={this.state.user.locked} onChange={this.__handleChange}>
                                        {YESNO_STRING.map(element => (
                                            <option value={element.value} key={element.value}>
                                                {element.name}
                                            </option>
                                        ))}
                                    </AvField>
                                </AvGroup>
                            </Col>
                            <Col>
                                <AvGroup>
                                    <AvGroup>
                                        <Label for="activationDate">
                                            Aktivasyon Tarihi
                                        </Label>
                                        <AvField type="text" className="form-control" name="activationDate" disabled
                                                 value={convertDateTimeWithFormat(this.state.user.activationDate, APP_DATE_FORMAT)}/>{' '}
                                    </AvGroup>
                                    <AvGroup>
                                        <Label for="passwordChangeDate">
                                            Şifre Değiştirme Tarihi
                                        </Label>
                                        <AvField type="text" className="form-control" name="passwordChangeDate"
                                                 disabled
                                                 value={convertDateTimeWithFormat(this.state.user.passwordChangeDate, APP_DATE_FORMAT)}/>{' '}
                                    </AvGroup>
                                </AvGroup>
                            </Col>
                        </Row>}
                        {/*<AvGroup>*/}
                        {/*<Label >*/}
                        {/*<Translate contentKey="userManagement.teamList">Ekipler</Translate>*/}
                        {/*</Label>*/}
                        {/*{this.getTeamList()}*/}
                        {/*</AvGroup>*/}
                        {/*<AvGroup>*/}
                        {/*<Label for="language">*/}
                        {/*<Translate contentKey="userManagement.langKey">Language Key</Translate>*/}
                        {/*</Label>*/}
                        {/*<AvField type="select" className="form-control" name="language"*/}
                        {/*value={this.state.user.language} onChange={this.__handleChange}>*/}
                        {/*{locales.map(element => (*/}
                        {/*<option value={element} key={element}>*/}
                        {/*{languages[element].name}*/}
                        {/*</option>*/}
                        {/*))}*/}
                        {/*</AvField>*/}
                        {/*</AvGroup>*/}

                    </Col>
                </Row>

            </div>
        );
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let user = this.state.user;
        user[e.target.name] = value;
        this.setState({user: user});
    }

    setData = (data, pageMode) => {
        this.setMultiSelectValue(data);
        let contact = data.contactId.givenNames + ' ' + data.contactId.familyName;
        let allowedOrganization = data.allowedOrganizationId ? data.allowedOrganizationId.name : null;
        this.setState({
            user: data,
            pageMode: pageMode,
            contact: contact,
            email: data.contactId.email,
            mobilePhone: data.contactId.mobilePhone,
            organization: data.contactId.organizationId.name,
            contactStatus: data.contactId.status,
            allowedOrganization: allowedOrganization
        });
    }

    getData = () => {
        let user = this.state.user;
        user.hsmRoleList = this.state.selectedRoleList;
        return user;
    }

    clearData = () => {
        this.setState({
            user: Object.assign({}, defaultUser),
            selectedRoleList: [],
            avaibleRoleList: this.state.roleList,
            contact: null,
            email: null,
            mobilePhone: null,
            organization: null,
            pageMode: null,
            // displayAllowedOrganization: false,
            // allowedOrganization: null
        });
    }

    setMultiSelectValue = (user) => {
        let avaibleRoleList = Object.assign([], this.state.roleList);
        let selectedRoleList = []
        user.hsmRoleList.forEach((element) => {
            selectedRoleList.push(element);
            avaibleRoleList.forEach((role) => {
                if (role.id === element.id) {
                    avaibleRoleList.splice(avaibleRoleList.indexOf(role), 1);
                }
            });
        });
        this.setState({
            avaibleRoleList: avaibleRoleList,
            selectedRoleList: selectedRoleList
        });
        // this.checkAllowedOrganization(selectedRoleList);
    }

    onContactSelect = (data) => {
        let user = this.state.user;
        user.contactId = data.id;
        let contact = data.givenNames + ' ' + data.familyName;
        this.setState({
            user: user,
            contact: contact,
            email: data.email,
            mobilePhone: data.mobilePhone,
            organization: data.organizationId.name
        });
    }

    pickListTemplate(data) {
        return (
            <div className="p-clearfix">
                <div>{data.name}</div>
            </div>
        );
    }

    // __handleChangeMultiSelect = (e) => {
    //     let state = {};
    //     var options = e.target.options;
    //     var value = [];
    //     for (var i = 0, l = options.length; i < l; i++) {
    //         if (options[i].selected) {
    //             value.push(Number(options[i].value));
    //         }
    //     }
    //     state[e.target.name] = value;
    //     this.setState(state);
    // }
    //
    // getTeamList() {
    //     let content = [];
    //     this.state.user.contactId.teamList.map(element => (
    //         content.push(<Badge style={{marginRight: '5px'}} color="info">{element.name}</Badge>)
    //     ));
    //     return content;
    // }

    // checkAllowedOrganization = (selectedRoleList) =>{
    //     let isExistOrgAdmin = false;
    //     selectedRoleList.forEach((element) => {
    //         if (element.id === ORG_ADMIN) {
    //             isExistOrgAdmin = true;
    //             this.setState({displayAllowedOrganization: true});
    //         }
    //     });
    //     if(!isExistOrgAdmin){
    //         this.setState({displayAllowedOrganization: false});
    //     }
    // }

    // onAllowedOrganizationSelect = (data) => {
    //     let user = this.state.user;
    //     user.allowedOrganizationId = data;
    //     this.setState({user: user, allowedOrganization: data.name});
    // }
}




