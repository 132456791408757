import React, {Component} from 'react';
import "./Comment.css";

export default class Comment extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="comment">
                <div className="comment-header">
                    <strong><i className="pi pi-user"></i>{this.props.header + '       '}</strong> <strong><i className="pi pi-clock"></i>{this.props.date}</strong>
                </div>
                <div className="comment-content">
                    {this.props.content ? this.props.content.split('\n').map(element => {
                        return <div>{element}<span><br/></span></div>;
                    }) : ''}
                </div>
            </div>
        );
    }
}