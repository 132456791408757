import React, {Component} from 'react';
import {Button, Col, Label, Row} from 'reactstrap';
import {AvFeedback, AvForm, AvGroup} from 'availity-reactstrap-validation';
import AvField from '../../../shared/component/form/AvField';
import Translate from "../../../shared/language/Translate";
import {Fieldset} from "primereact/fieldset";
import BbProductService from "../../../shared/service/BbProductService";
import HsmSystemParameterService from "../../../shared/service/HsmSystemParameterService";
import {toast} from "react-toastify";
import ReCAPTCHA from 'react-google-recaptcha';
import IncidentService from "../../../shared/service/IncidentService";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import ServiceSubcategoryService from "../../../shared/service/ServiceSubcategoryService";
import SurveyDialog from "../../request/hsmDialog/SurveyDialog";
import 'survey-react/survey.css';
import PropTypes from "prop-types";
import HsmSurveyResultService from "../../../shared/service/HsmSurveyResultService";
import {PhoneField} from "../../../shared/component/form/PhoneField";

const defaultIncidentRecord = {
    locationCode: '',
    ttVpnCode: '',
    contactGivenNames: '',
    contactFamilyNames: '',
    contactPhone: '',
    contactEmail: '',
    title: '',
    description: '',
    serviceCategoryId: 1,
    serviceSubcategoryId: 1
};

export default class GuestIncident extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            incidentRecord: Object.assign({}, defaultIncidentRecord),
            checkFacility: false,
            recaptchaToken: null,
            serviceSubcategoryList: [],
            displaySurvey: false
        };
        this.bbProductService = new BbProductService();
        this.incidentService = new IncidentService();
        this.hsmSystemParameterService = new HsmSystemParameterService();
        this.serviceSubategoryService = new ServiceSubcategoryService();
        this.hsmSurveyResultService = new HsmSurveyResultService();

    }

    componentDidMount =()=> {
        this.findAllSubcategoryAndCheckParameter();
        if (this.reCAPTCHA) {
            this.reCAPTCHA.reset();
        }
    }

    onChange =(recaptchaToken)=> {
        this.setState({recaptchaToken: recaptchaToken});
    }

    render() {
        return (
            <div>
                <Row>
                    <Col>
                        <div className="container">
                            {this.searchFacility()}
                        </div>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col>
                        <div className="container">
                            {this.searchSubcategory()}
                        </div>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col>
                        <div className="container">
                            {this.otherInfo()}
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }

    searchFacility = () => {
        return <AvForm onValidSubmit={this.findFacility} ref={(e)=>this.refForm=e}>
            <Fieldset legend="Tesis & TTVPN Bilgileri">
                <AvGroup>
                    <Label for="locationCode">
                        <Translate contentKey="incidentRecord.locationCode">Tesis Kodu</Translate>
                        <span style={{color: 'red'}}> (*)</span>
                    </Label>
                    <AvField
                        type="text"
                        className="form-control"
                        name="locationCode"
                        disabled={this.state.checkFacility}
                        validate={{
                            maxLength: {
                                value: 50,
                                errorMessage: "Bu alana en fazla 50 karakter girebilirsiniz."
                            },
                            required: {
                                value: "true",
                                errorMessage: " "
                            }
                        }}
                        value={this.state.incidentRecord.locationCode} onChange={this.__handleChange}/>
                    <AvFeedback>Bu alana en fazla 50 karakter girebilirsiniz.</AvFeedback>
                </AvGroup>
                <AvGroup>
                    <Label for="ttVpnCode">
                        <Translate contentKey="incidentRecord.ttVpnCode">TTVPN Hizmet No</Translate>
                        <span style={{color: 'red'}}> (*)</span>
                    </Label>
                    <AvField
                        type="text"
                        className="form-control"
                        name="ttVpnCode"
                        disabled={this.state.checkFacility}
                        validate={{
                            maxLength: {
                                value: 50,
                                errorMessage: "Bu alana en fazla 50 karakter girebilirsiniz."
                            },
                            required: {
                                value: "true",
                                errorMessage: " "
                            }
                        }}
                        value={this.state.incidentRecord.ttVpnCode} onChange={this.__handleChange}/>
                    <AvFeedback>Bu alana en fazla 50 karakter girebilirsiniz.</AvFeedback>
                </AvGroup>
                <div>
                    <Row>
                        { this.state.doesSystemWorkInLiveMode &&
                            <Col className="text-center">
                                <ReCAPTCHA
                                    ref={(el) => {this.reCAPTCHA = el;}}
                                    size="normal"
                                    sitekey="6Lep9qIUAAAAAHk6E9GINxi8JKbHK7m9yIQTloQ_"
                                    onChange={this.onChange}/>
                            </Col>
                        }
                        <Col>
                            <Button color="primary" type="submit" className="captchaButton"  disabled={!this.state.recaptchaToken}
                                    style={this.state.checkFacility  ? {display:'none'}:{display:'block'}}>
                                <Translate contentKey="entity.action.add">Gönder</Translate>
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Fieldset>
        </AvForm>;
    }

    searchSubcategory = () => {
        if (this.state.checkFacility === true) {
            return <div>
             <AvForm onValidSubmit={this.displaySurveyDialog}>
                <Fieldset legend="Şikayet Bilgileri">
                    <div>
                        <Row>
                            <Col>
                                <AvGroup>
                                    <Label for="serviceSubcategoryId">
                                        Şikayet Tipi
                                    </Label>
                                    <AvField type="select" className="form-control" name="serviceSubcategoryId"
                                             value={this.state.incidentRecord.serviceSubcategoryId}
                                             disabled={this.state.displaySurvey}
                                             onChange={this.__handleChangeServiceSubcategory}>
                                        {this.state.serviceSubcategoryList.map(element => (
                                            <option value={element.id} key={element.id}>
                                                {element.name}
                                            </option>
                                        ))}
                                    </AvField>
                                </AvGroup>
                            </Col>
                        </Row>
                    </div>
                    <div className="text-center">
                        <Button color="primary" type="submit" className="captchaButton" style={this.state.displaySurvey  ? {display:'none'}:{display:'block'}}>
                            <Translate contentKey="entity.action.add">Gönder</Translate>
                        </Button>
                    </div>
                </Fieldset>
            </AvForm>

            <SurveyDialog ref={elem => this.refSurveyDialog = elem}
                          displaySurvey={(displaySurvey) =>
                              this.setState({displaySurvey: displaySurvey})}
            />

          </div>
        }
        else {
            return;
        }
    }

    otherInfo = () => {
        if (this.state.displaySurvey === true) {
            return <AvForm onValidSubmit={this.sendIncident}>
                <Fieldset legend="Ek Bilgiler">

                    <Fieldset legend="İrtibat Bilgileri">
                        <div>
                            <Row>
                                <Col>
                                    <AvGroup>
                                        <Label for="contactGivenNames">
                                            <Translate contentKey="incidentRecord.contactGivenNames">Adı</Translate>
                                            <span style={{color: 'red'}}> (*)</span>
                                        </Label>
                                        <AvField
                                            type="text"
                                            className="form-control"
                                            name="contactGivenNames"
                                            validate={{
                                                maxLength: {
                                                    value: 50,
                                                    errorMessage: "Bu alana en fazla 50 karakter girebilirsiniz."
                                                },
                                                required: {
                                                    value: "true",
                                                    errorMessage: "Bu alanın doldurulması zorunludur."
                                                }
                                            }}
                                            value={this.state.incidentRecord.contactGivenNames}
                                            onChange={this.__handleChange}/>
                                        <AvFeedback>Bu alana en fazla 50 karakter girebilirsiniz.</AvFeedback>
                                    </AvGroup>
                                </Col>
                                <Col>
                                    <AvGroup>
                                        <Label for="contactFamilyNames">
                                            <Translate contentKey="incidentRecord.contactFamilyNames">Soyadı</Translate>
                                            <span style={{color: 'red'}}> (*)</span>
                                        </Label>
                                        <AvField
                                            type="text"
                                            className="form-control"
                                            name="contactFamilyNames"
                                            validate={{
                                                maxLength: {
                                                    value: 50,
                                                    errorMessage: "Bu alana en fazla 50 karakter girebilirsiniz."
                                                },
                                                required: {
                                                    value: "true",
                                                    errorMessage: "Bu alanın doldurulması zorunludur."
                                                }
                                            }}
                                            value={this.state.incidentRecord.contactFamilyNames}
                                            onChange={this.__handleChange}/>
                                        <AvFeedback>Bu alana en fazla 50 karakter girebilirsiniz.</AvFeedback>
                                    </AvGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <AvGroup>
                                        <Label for="contactPhone">
                                            <Translate contentKey="incidentRecord.contactPhone">Tel No</Translate>
                                            <span style={{color: 'red'}}> (*)</span>
                                        </Label>
                                        <PhoneField
                                                    validate={{
                                                        required: {
                                                            value: "true",
                                                            errorMessage: "Bu alanın doldurulması zorunludur."
                                                        }
                                                    }}
                                                    kind={'any'}
                                                    name="contactPhone" errorMessage="Geçersiz Numara Girişi"
                                                    value={this.state.incidentRecord.contactPhone}
                                                    onChange={this.__handleChange}
                                        />
                                    </AvGroup>
                                </Col>
                                <Col>
                                    <AvGroup>
                                        <Label for="contactEmail">
                                            <Translate contentKey="incidentRecord.contactEmail">E-posta</Translate>
                                            <span style={{color: 'red'}}> (*)</span>
                                        </Label>
                                        <AvField
                                            className="form-control"
                                            name="contactEmail"
                                            type="email"
                                            validate={{
                                                required: {value: true, errorMessage: 'Bu alan zorunlu.'},
                                                minLength: {
                                                    value: 5,
                                                    errorMessage: 'Bu alan en az 5 karakterden oluşabilir.'
                                                },
                                                maxLength: {
                                                    value: 254,
                                                    errorMessage: 'E-Posta adresi 254 karakterden fazla olamaz.'
                                                },
                                                email: {errorMessage: 'E-Posta alanı uygun değil.'}
                                            }}
                                            value={this.state.incidentRecord.contactEmail}
                                            onChange={this.__handleChange}/>
                                        <AvFeedback>Bu alana en fazla 50 karakter girebilirsiniz.</AvFeedback>
                                    </AvGroup>
                                </Col>
                            </Row>
                        </div>
                    </Fieldset>
                    <br/>
                    <Fieldset legend="Arıza Bilgileri">
                        <div>
                            <Row>
                                <Col>
                                    <AvGroup>
                                        <Label for="serviceSubcategoryId">
                                            Şikayet Tipi
                                        </Label>
                                        <AvField type="select" className="form-control" name="serviceSubcategoryId"
                                                 value={this.state.incidentRecord.serviceSubcategoryId}
                                                 disabled={this.state.displaySurvey}
                                                 onChange={this.__handleChangeServiceSubcategory}>
                                            {this.state.serviceSubcategoryList.map(element => (
                                                <option value={element.id} key={element.id}>
                                                    {element.name}
                                                </option>
                                            ))}
                                        </AvField>
                                    </AvGroup>
                                    <AvGroup>
                                        <Label for="title">
                                            <Translate contentKey="incidentRecord.title">Arıza
                                                Özet</Translate>
                                            <span style={{color: 'red'}}> (*)</span>
                                        </Label>
                                        <AvField
                                            type="text"
                                            className="form-control"
                                            name="title"
                                            validate={{
                                                maxLength: {
                                                    value: 255,
                                                    errorMessage: "Bu alana en fazla 255 karakter girebilirsiniz."
                                                },
                                                required: {
                                                    value: "true",
                                                    errorMessage: "Bu alanın doldurulması zorunludur."
                                                }
                                            }}
                                            value={this.state.incidentRecord.title}
                                            onChange={this.__handleChange}/>
                                        <AvFeedback>Bu alana en fazla 255 karakter girebilirsiniz.</AvFeedback>
                                    </AvGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <AvGroup>
                                        <Label for="description">
                                            <Translate contentKey="incidentRecord.description">Arıza
                                                Açıklama</Translate>
                                            <span style={{color: 'red'}}> (*)</span>
                                        </Label>
                                        <AvField
                                            type="textarea"
                                            className="form-control textAreaHermes"
                                            name="description"
                                            validate={{
                                                maxLength: {
                                                    value: 4000,
                                                    errorMessage: "Bu alana en fazla 4000 karakter girebilirsiniz."
                                                },
                                                required: {
                                                    value: "true",
                                                    errorMessage: "Bu alanın doldurulması zorunludur."
                                                }
                                            }}
                                            value={this.state.incidentRecord.description}
                                            onChange={this.__handleChange}/>
                                        <AvFeedback>Bu alana en fazla 4000 karakter girebilirsiniz.</AvFeedback>
                                    </AvGroup>
                                </Col>
                            </Row>
                        </div>
                    </Fieldset>
                    <br/>
                    <div className="text-center">
                        <Button color="primary" type="submit">
                            <Translate contentKey="entity.action.add">Arıza Kaydı Aç</Translate>
                        </Button>
                        <Button color="primary" onClick={this.clearData}>
                            <Translate contentKey="entity.action.cancel">Vazgeç</Translate>
                        </Button>
                    </div>
                </Fieldset>
            </AvForm>;
        }
        else {
            return;
        }
    }

    findFacility = () => {
        if (GeneralUtils.isNullOrEmpty(this.state.recaptchaToken)) {
            return;
        }
        let locationCode = this.state.incidentRecord.locationCode;
        let ttVpnCode = this.state.incidentRecord.ttVpnCode;

        this.bbProductService.doesLocationAndTTVpnExist(GeneralUtils.getUpperCasedVariable(locationCode), ttVpnCode).then(response => {
                if (response) {
                    this.setState({checkFacility: true});
                }
                else {
                    if(this.reCAPTCHA){
                        this.reCAPTCHA.reset();
                    }

                    this.setState({checkFacility: false, recaptchaToken: null});
                    return toast.warn("Girmiş olduğunuz Tesis Kodu ve/veya TTVPN Hizmet Numarası hatalıdır veya sistemdeki kayıtlar ile eşleşmemektedir!");
                }
            }
        )
    }

    displaySurveyDialog = () => {
        if (this.state.displaySurvey == false) {
            this.refSurveyDialog.setData(this.state.incidentRecord.serviceCategoryId, this.state.incidentRecord.serviceSubcategoryId);
        }
    }

    clearData = () => {

        if(this.reCAPTCHA){
            this.reCAPTCHA.reset();
        }

        this.findAllSubcategoryAndCheckParameter();
        this.setState({
            incidentRecord: Object.assign({}, defaultIncidentRecord),
            checkFacility: false,
            recaptchaToken: null,
            serviceSubcategoryList: [],
            displaySurvey: false
        });
    }

    setData = () => {
        let data = {
            locationCode: GeneralUtils.getUpperCasedVariable(this.state.incidentRecord.locationCode),
            ttVpnCode: this.state.incidentRecord.ttVpnCode,
            contactGivenNames: this.state.incidentRecord.contactGivenNames,
            contactFamilyNames: this.state.incidentRecord.contactFamilyNames,
            contactPhone: this.state.incidentRecord.contactPhone,
            contactEmail: this.state.incidentRecord.contactEmail,
            title: this.state.incidentRecord.title,
            description: this.state.incidentRecord.description,
            serviceCategoryId: this.state.incidentRecord.serviceCategoryId,
            serviceSubcategoryId: this.state.incidentRecord.serviceSubcategoryId
        };
        return data;
    }

    sendIncident = () => {
        let data = this.setData();
        this.incidentService.guestSaveIncident(data).then(response => {
            if(response && !GeneralUtils.isNullOrEmpty(response.id)) {
                let surveyResult = this.refSurveyDialog.getSurveyResult();
                surveyResult.requestId = response.id;
                this.hsmSurveyResultService.update(surveyResult);

                this.refForm.reset();
                this.clearData();
                return toast.success(response.id + " arıza numarasıyla kaydınız oluşturulmuştur.");
            }
            else {
/*
                this.refForm.reset();
                this.clearData();
*/
                GeneralUtils.showNotification(response);
            }
        });
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let incidentRecord = this.state.incidentRecord;
        incidentRecord[e.target.name] = value;
        this.setState({incidentRecord: incidentRecord});
    }

    __handleChangeServiceSubcategory = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let incidentRecord = this.state.incidentRecord;
        incidentRecord[e.target.name] = value;
        for (var i = 0; i < this.state.serviceSubcategoryList.length; i++) {
            if (this.state.serviceSubcategoryList[i].id == value) {
                incidentRecord.title = this.state.serviceSubcategoryList[i].name;
            }
        }
        this.setState({incidentRecord: incidentRecord});
        if (this.props.clearAsset) {
            this.props.clearAsset();
        }
    }

    findAllSubcategoryAndCheckParameter = ()  => {
        this.hsmSystemParameterService.doesSystemWorkInLiveMode().then(response => {
            if(response){
                this.setState({doesSystemWorkInLiveMode: response, recaptchaToken : false})
            }
            else{
                this.setState({doesSystemWorkInLiveMode: response, recaptchaToken : true})
            }
        });
        this.serviceSubategoryService.findIncidentSubCategories(this.state.incidentRecord.serviceCategoryId).then(response => {
            let incidentRecord = this.state.incidentRecord;
            incidentRecord.serviceSubcategoryId = response[0].id;
            incidentRecord.title = response[0].name;
            this.setState({serviceSubcategoryList: response, incidentRecord: incidentRecord});
        });
    }

}

GuestIncident.propTypes = {
    displaySurvey: PropTypes.func
};
