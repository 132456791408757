import React from 'react';
import PriceColumn from '../../../shared/component/dataTable/columns/PriceColumn';
import PriceService from "../../../shared/service/PriceService";
import DataTable from "../../../shared/component/dataTable/DataTable";
import {Fieldset} from "primereact/fieldset";


export default class PriceList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
        this.service = new PriceService();
    }

    render() {
        return (
            <Fieldset legend="Ücret Bilgileri">
                <DataTable ref={(elem) => this.refDataTable = elem} fields={PriceColumn.fields}
                           dataURL="price/findByProductOfferingId" objectName={PriceColumn.name} autoLoad={false}/>
            </Fieldset>
        );
    }

    setData = (data) => {
        this.clearData();
        this.refDataTable.setParameter(data.id);
    }

    clearData = () => {
        this.setState({data: []})
    }
}
