import React from 'react';
import GeneralUtils from "../../utils/GeneralUtils";
import DataTableAll from "./DataTableAll";

export default class DataTableForExcel extends DataTableAll {

    constructor(props) {
        super(props);
    }

    rowClassName(rowData) {
        let hasError = rowData.hasError;
        let hasWarning = rowData.hasWarning;

        if(hasError){
            return {'highlightTableRow' : !GeneralUtils.__isValueEmpty(hasError)};
        }else{
            if(hasWarning){
                return {'warningTableRow' : !GeneralUtils.__isValueEmpty(hasWarning)};
            }
        }
    }
}
