import React from 'react';
import {Button, Col, Label, Row} from 'reactstrap';
import {Panel} from 'primereact/panel';
import {Fieldset} from "primereact/fieldset";
import {AvForm, AvGroup} from 'availity-reactstrap-validation';
import AvField from '../../../shared/component/form/AvField';
import PropTypes from 'prop-types';
import Translate from "../../../shared/language/Translate";
import BbProductService from "../../../shared/service/BbProductService";
import DateInput from "../../../shared/component/form/DateInput";
import {
    BB_PRODUCT_STATUS,
    PAGE_MODE,
    REGION_TYPE,
    REQUEST_TYPE,
    SERVICE_SUBCATEGORY
} from "../../../shared/utils/Constants";
import OrganizationGroup from "../../../shared/component/hsmGroup/OrganizationGroup";
import HsmOrganizationColumnForDialog
    from '../../../shared/component/dataTable/columns/HsmOrganizationColumnForDialog.json';
import LocationGroup from "../../../shared/component/hsmGroup/LocationGroup";
import ProductOfferingGroup from "../../../shared/component/hsmGroup/ProductOfferingGroup";
import BillingAccountGroup from "../../../shared/component/hsmGroup/BillingAccountGroup";
import BillingAccountColumn from '../../../shared/component/dataTable/columns/BillingAccountColumn';
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import HsmOrganizationService from "../../../shared/service/HsmOrganizationService";
import BusinessRuleService from "../../../shared/service/BusinessRuleService";
import LocationService from "../../../shared/service/LocationService";
import HsmProjectService from "../../../shared/service/HsmProjectService"
import HermesSweetAlert from "../../../shared/component/sweetAlert/HermesSweetAlert";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import {toast} from "react-toastify";
import HsmSystemParameterService from "../../../shared/service/HsmSystemParameterService";
import {MultiSelect} from "primereact/multiselect";


const defaultBbProduct = {
    id: '',
    productType: '',
    name: '',
    status: 'ACTIVE',
    regionType: 'Metropolitan',
    serviceNumber: '',
    serviceNumberOld: '',
    externalBillingAccountNumber: '',
    vpnCircuitType: 'Şehirlerarası',
    cloudVpnId: null,
    cloudId: null,
    installedDate: null,
    activatedDate: null,
    uninstalledDate: null,
    suspendedDate: null,
    bandwidthId: '',
    organizationId: null,
    providerOrganizationId: 100,
    locationId: null,
    billingAccountId: '',
    productOfferingId: '',
    hsmProjectId: null,

};

export default class BbProductSaveForMigration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bbProduct: Object.assign({}, defaultBbProduct),
            organization: null,
            providerOrganization: null,
            billingAccountName: null,
            locationList: [],
            locationListForMultiple: [],
            hsmProjectList: [],
            pageMode: null,
            cloudVpnId: null,
            cloudId: null,
            servedLocationList: []
        }
        this.service = new BbProductService();
        this.hsmOrganizationService = new HsmOrganizationService();
        this.locationService = new LocationService();
        this.hsmProjectService = new HsmProjectService();
        this.businessRuleService = new BusinessRuleService();
        this.hsmSystemParameterService = new HsmSystemParameterService();
    }

    componentDidMount = () => {
        this.hsmProjectService.findAllActive().then(response => this.setState({hsmProjectList: response}));
        this.hsmSystemParameterService.findByKey('CLOUD_VPN_ID').then(response => this.setState({cloudVpnId: response.value}));
        this.hsmSystemParameterService.findByKey('CLOUD_ID').then(response => this.setState({cloudId : response.value}));
    }

    render() {
        return (
            <Panel header="Genişbant Ürün Tanımları">
                <AvForm onValidSubmit={this.save}>
                    <Toolbar display={true}>
                        <div className="p-toolbar-group-right">
                            <Button color="primary" type="submit" className="float-right">
                                <Translate contentKey="entity.action.save">Kaydet</Translate>
                            </Button>
                            <Button color="primary" className="float-right"
                                    onClick={this.clearData}>
                                <Translate contentKey="entity.action.save">Temizle</Translate>
                            </Button></div>
                    </Toolbar>
                    <div>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <OrganizationGroup
                                        name="organizationId"
                                        value={this.state.organization}
                                        data={this.state.organizationObject}
                                        onDataSelect={this.onOrganizationSelect}
                                        dataTable={{
                                            dataURL: "hsmOrganization/findFilteredOrgs",
                                            fields: HsmOrganizationColumnForDialog.fields
                                        }}
                                    />
                                    <LocationGroup value={this.state.bbProduct.locationId}
                                                   optionList={this.state.locationList}
                                                   onChange={this.__handleChangeLocation}
                                                   disabled={!this.state.organization}
                                    />
                                    <div className="position-relative form-group">
                                        <Row>
                                            <Col>
                                                <Label for="servedLocationList">
                                                    Hizmet Verilecek Ek Lokasyonlar
                                                </Label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <MultiSelect optionLabel="name" dataKey="id" value={this.state.servedLocationList}
                                                             options={this.state.locationListForMultiple}
                                                             onChange={(e) => {
                                                                 this.setState({servedLocationList: e.value})
                                                             }} filter={true}
                                                             disabled={!this.state.organization}
                                                             selectedItemTemplate={this.servedLocationListTemplate} selectedItemsLabel="{0} Kayıt Seçildi"/>
                                            </Col>
                                        </Row>
                                    </div>
                                    <BillingAccountGroup
                                        value={this.state.billingAccountName}
                                        dataTable={{
                                            dataURL: "billingAccount/findAllFilteredByOrganizationId",
                                            parameter: this.state.bbProduct.organizationId ? this.state.bbProduct.organizationId : 0,
                                            fields: BillingAccountColumn.fields,
                                        }}
                                        disabled={!this.state.organization}
                                        autoRefrestData={true}
                                        onDataSelect={this.onBillingAccountSelect}
                                    />
                                    <AvGroup>
                                        <Label for="serviceNumber">
                                            <Translate contentKey="bbProduct.serviceNumber">Hizmet Numarası<span
                                                style={{color: 'red'}}> (*)</span></Translate>
                                        </Label>
                                        <AvField
                                            type="text"
                                            className="form-control"
                                            validate={{
                                                required: {
                                                    value: true,
                                                    errorMessage: 'Bu alanın doldurulması zorunludur.'
                                                },
                                                minLength: {
                                                    value: 1,
                                                    errorMessage: 'Bu alan en az 1 karakterden oluşabilir.'
                                                },
                                                maxLength: {
                                                    value: 50,
                                                    errorMessage: "Bu alana en fazla 50 karakter girebilirsiniz."
                                                }
                                            }}
                                            name="serviceNumber"
                                            value={this.state.bbProduct.serviceNumber}
                                            onChange={this.__handleChangeServiceNumber}/>
                                    </AvGroup>

                                    {/*<AvGroup>*/}
                                    {/*    <Label for="name">*/}
                                    {/*        <Translate contentKey="bbProduct.name">Genişbant Ürün Adı<span*/}
                                    {/*            style={{color: 'red'}}> (*)</span></Translate>*/}
                                    {/*    </Label>*/}
                                    {/*    <AvField*/}
                                    {/*        type="text"*/}
                                    {/*        className="form-control"*/}
                                    {/*        name="name"*/}
                                    {/*        validate={{*/}
                                    {/*            required: {*/}
                                    {/*                value: true,*/}
                                    {/*                errorMessage: 'Bu alan zorunlu.'*/}
                                    {/*            },*/}
                                    {/*            minLength: {*/}
                                    {/*                value: 1,*/}
                                    {/*                errorMessage: 'Bu alan en az 1 karakterden oluşabilir.'*/}
                                    {/*            },*/}
                                    {/*            maxLength: {*/}
                                    {/*                value: 50,*/}
                                    {/*                errorMessage: "Bu alana en fazla 50 karakter girebilirsiniz."*/}
                                    {/*            }*/}
                                    {/*        }}*/}
                                    {/*        value={this.state.bbProduct.name}*/}
                                    {/*        disabled={true}*/}
                                    {/*        onChange={this.__handleChange}/>*/}
                                    {/*</AvGroup>*/}

                                    {/*<AvGroup>*/}
                                    {/*    <Label for="serviceNumberOld">*/}
                                    {/*        <Translate contentKey="bbProduct.serviceNumberOld">Eski Hizmet*/}
                                    {/*            Numarası</Translate>*/}
                                    {/*    </Label>*/}
                                    {/*    <AvField*/}
                                    {/*        type="text"*/}
                                    {/*        className="form-control"*/}
                                    {/*        name="serviceNumberOld"*/}
                                    {/*        validate={{*/}
                                    {/*            maxLength: {*/}
                                    {/*                value: 50,*/}
                                    {/*                errorMessage: "Bu alana en fazla 50 karakter girebilirsiniz."*/}
                                    {/*            }*/}
                                    {/*        }}*/}
                                    {/*        value={this.state.bbProduct.serviceNumberOld}*/}
                                    {/*        onChange={this.__handleChange}/>*/}
                                    {/*</AvGroup>*/}
                                    <AvGroup>
                                        <Label for="externalBillingAccountNumber">
                                            <Translate contentKey="bbProduct.externalBillingAccountNumber">Harici Fatura
                                                Hesap Numarası</Translate>
                                        </Label>
                                        <AvField
                                            type="text"
                                            className="form-control"
                                            name="externalBillingAccountNumber"
                                            disabled={this.state.pageMode === PAGE_MODE.VIEW}
                                            value={this.state.bbProduct.externalBillingAccountNumber}
                                            validate={{
                                                maxLength: {
                                                    value: 50,
                                                    errorMessage: "Bu alana en fazla 50 karakter girebilirsiniz."
                                                }
                                            }}
                                            onChange={this.__handleChange}/>
                                    </AvGroup>
                                    <Fieldset legend="Teklif Bilgileri" toggleable={true}
                                              collapsed={this.state.panelCollapsedSR}
                                              onToggle={(e) => this.setState({panelCollapsedSR: e.value})}>

                                        <div className="row">
                                            <div className="col">
                                                <ProductOfferingGroup
                                                    value={this.state.tariffName}
                                                    onDataSelect={this.onProductOfferingSelect}
                                                />
                                                <AvGroup>
                                                    <Label for="packageName">
                                                        <Translate contentKey="packageName">Paket</Translate>
                                                    </Label>
                                                    <AvField
                                                        type="text"
                                                        className="form-control"
                                                        name="packageName"
                                                        disabled={true}
                                                        value={this.state.packageName}/>
                                                </AvGroup>
                                                <AvGroup>
                                                    <Label for="productSpecName">
                                                        <Translate contentKey="productSpecName">Ürün Adı</Translate>
                                                    </Label>
                                                    <AvField
                                                        type="text"
                                                        className="form-control"
                                                        name="productSpecName"
                                                        disabled={true}
                                                        value={this.state.productSpecName}/>
                                                </AvGroup>
                                                <AvGroup>
                                                    <Label for="productType">
                                                        <Translate contentKey="productType">Ürün Tipi</Translate>
                                                    </Label>
                                                    <AvField
                                                        type="text"
                                                        className="form-control"
                                                        name="productType"
                                                        disabled={true}
                                                        value={this.state.productType}/>
                                                </AvGroup>
                                            </div>
                                            <div className="col">
                                                <AvGroup>
                                                    <Label for="accessTechnologyPO">
                                                        <Translate contentKey="bbProduct.bandwidthDownloadSpeed">Erişim
                                                            Teknolojisi</Translate>
                                                    </Label>
                                                    <AvField
                                                        type="text"
                                                        className="form-control"
                                                        name="accessTechnologyPO"
                                                        disabled={true}
                                                        value={this.state.accessTechnologyPO}/>
                                                </AvGroup>
                                                <AvGroup>
                                                    <Label for="downloadSpeedPO">
                                                        <Translate contentKey="bbProduct.bandwidthDownloadSpeed">İndirme
                                                            Hızı</Translate>
                                                    </Label>
                                                    <AvField
                                                        type="text"
                                                        className="form-control"
                                                        name="downloadSpeedPO"
                                                        disabled={true}
                                                        value={this.state.downloadSpeedPO}/>
                                                </AvGroup>
                                                <AvGroup>
                                                    <Label for="uploadSpeedPO">
                                                        <Translate contentKey="bbProduct.bandwidthUploadSpeed">Yükleme
                                                            Hızı</Translate>
                                                    </Label>
                                                    <AvField
                                                        type="text"
                                                        className="form-control"
                                                        name="uploadSpeedPO"
                                                        disabled={true}
                                                        value={this.state.uploadSpeedPO}/>
                                                </AvGroup>
                                            </div>
                                        </div>


                                    </Fieldset>
                                </div>
                                <div className="col">
                                    <AvGroup>
                                        <Label for="status">
                                            <Translate contentKey="bbProduct.status">Durum</Translate>
                                        </Label>
                                        <AvField type="select" className="form-control" name="status"
                                                 value={this.state.bbProduct.status} onChange={this.__handleChange}
                                                 disabled={true}>
                                            {BB_PRODUCT_STATUS.map(element => (
                                                <option value={element.value} key={element.value}>
                                                    {element.name}
                                                </option>
                                            ))}
                                        </AvField>
                                    </AvGroup>

                                    <AvGroup>
                                        <Label for="regionType">
                                            <Translate contentKey="bbProduct.regionType">Bölge</Translate>
                                        </Label>
                                        <AvField type="select" className="form-control" name="regionType"
                                                 value={this.state.bbProduct.regionType} onChange={this.__handleChange}
                                        >
                                            {REGION_TYPE.map(element => (
                                                <option value={element.value} key={element.value}>
                                                    {element.name}
                                                </option>
                                            ))}
                                        </AvField>
                                    </AvGroup>

                                    <AvGroup>
                                        <Label for="vpnCircuitType">
                                            <Translate contentKey="bbProduct.vpnCircuitType">VPN Devre Tipi</Translate>
                                        </Label>
                                        <AvField
                                            type="text"
                                            className="form-control"
                                            validate={{
                                                maxLength: {
                                                    value: 20,
                                                    errorMessage: "Bu alana en fazla 20 karakter girebilirsiniz."
                                                }
                                            }}
                                            name="vpnCircuitType"
                                            value={this.state.bbProduct.vpnCircuitType}
                                            disabled={true}
                                            onChange={this.__handleChange}/>
                                    </AvGroup>
                                    <AvGroup>
                                        <Label for="cloudVpnId">
                                            <Translate contentKey="bbProduct.cloudVpnId">Bulut VPN Abonelik
                                                Id</Translate>
                                        </Label>
                                        <AvField
                                            type="text"
                                            className="form-control"
                                            name="cloudVpnId"
                                            validate={{
                                                maxLength: {
                                                    value: 20,
                                                    errorMessage: "Bu alana en fazla 20 karakter girebilirsiniz."
                                                }
                                            }}
                                            value={this.state.cloudVpnId}
                                            disabled={true}
                                            onChange={this.__handleChange}/>
                                    </AvGroup>
                                    <AvGroup>
                                        <Label for="cloudId">
                                            <Translate contentKey="bbProduct.cloudId">Bulut VPN Id</Translate>
                                        </Label>
                                        <AvField
                                            type="text"
                                            className="form-control"
                                            name="cloudId"
                                            validate={{
                                                maxLength: {
                                                    value: 20,
                                                    errorMessage: "Bu alana en fazla 20 karakter girebilirsiniz."
                                                }
                                            }}
                                            value={this.state.cloudId}
                                            disabled={true}
                                            onChange={this.__handleChange}/>
                                    </AvGroup>
                                    {/*<OrganizationGroup*/}
                                    {/*    name="providerOrganizationId"*/}
                                    {/*    label="Servis Sağlayıcı"*/}
                                    {/*    value={this.state.providerOrganization}*/}
                                    {/*    data={this.state.providerOrganizationObject}*/}
                                    {/*    onDataSelect={this.onProviderOrganizationSelect}*/}
                                    {/*/>*/}
                                    <AvGroup>
                                        <Label for="hsmProjectId">Proje<span style={{color: 'red'}}> (*)</span>
                                        </Label>
                                        <AvField
                                            type="select"
                                            className="form-control"
                                            name="hsmProjectId"
                                            value={this.state.bbProduct.hsmProjectId}
                                            validate={{
                                                required: {
                                                    value: true,
                                                    errorMessage: 'Bu alanın doldurulması zorunludur.'
                                                }
                                            }}
                                            onChange={this.__handleChange}>
                                            <option value='' key=''/>
                                            {this.state.hsmProjectList.map(element => (
                                                <option value={element.id} key={element.id}>
                                                    {element.name}
                                                </option>
                                            ))}
                                        </AvField>
                                    </AvGroup>
                                    <div className="position-relative form-group">
                                        <Label for="installedDate">
                                            <Translate contentKey="bbProduct.installedDate">Kurulum Tarihi</Translate>
                                        </Label>
                                        <DateInput
                                            name="installedDate"
                                            onChange={this.__handleChange}
                                            value={this.state.bbProduct.installedDate}
                                            placeholder=""
                                        />
                                    </div>
                                    <div className="position-relative form-group">
                                        <Label for="activatedDate" style={{color: "green"}}>
                                            <Translate contentKey="bbProduct.activatedDate">Aktivasyon / Fatura Başlatma
                                                Tarihi</Translate>
                                        </Label>
                                        <DateInput
                                            name="activatedDate"
                                            onChange={this.__handleChange}
                                            value={this.state.bbProduct.activatedDate}
                                            placeholder=""
                                        />
                                    </div>
                                    <div className="position-relative form-group">
                                        <Label for="uninstalledDate" style={{color: "red"}}>
                                            <Translate contentKey="bbProduct.uninstalledDate">İptal / Fatura Sonlandırma
                                                Tarihi</Translate>
                                        </Label>
                                        <DateInput
                                            name="uninstalledDate"
                                            onChange={this.__handleChange}
                                            value={this.state.bbProduct.uninstalledDate}
                                            placeholder=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </AvForm>
            </Panel>
        );
    }


    setData = (data, pageMode) => {

        data.providerOrganizationName = data.providerOrganizationId.code + ' - ' + data.providerOrganizationId.name;
        data.billingAccountCode = data.billingAccountId ? data.billingAccountId.code : null;
        data.billingAccountName = data.billingAccountId ? data.billingAccountId.name : null;
        data.bandwidthAccessTechnology = data.bandwidthId.accessTechnology;
        data.bandwidthUploadSpeed = data.bandwidthId.uploadSpeed;
        data.bandwidthDownloadSpeed = data.bandwidthId.downloadSpeed;
        data.tariffName = data.productOfferingId ? data.productOfferingId.tariffName : null;
        data.campaignName = data.productOfferingId ? data.productOfferingId.campaignName : null;
        data.packageName = data.productOfferingId ? data.productOfferingId.packageName : null;
        data.organizationName = data.organizationId.code + ' - ' + data.organizationId.name;
        data.hsmProjectId = data.hsmProjectId ? data.hsmProjectId.id : null;
        data.locationName = data.locationId.code + ' - ' + data.locationId.name;

        this.setState({bbProduct: data, pageMode: pageMode});
    }

    clearData = () => {
        this.setState({
            bbProduct: Object.assign({}, defaultBbProduct),
            providerOrganization: null,
            organization: null,
            locationName: null,
            billingAccountName: null,
            tariffName: null,
            campaignName: null,
            packageName: null,
            productSpecName: null,
            accessTechnologyPO: null,
            downloadSpeedPO: null,
            uploadSpeedPO: null,
            productType: null,
            pageMode: null,
            locationList: [],
            servedLocationList: [],
        });
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let bbProduct = this.state.bbProduct;
        bbProduct[e.target.name] = value;
        this.setState({bbProduct: bbProduct});
    }

    __handleChangeServiceNumber = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let bbProduct = this.state.bbProduct;
        bbProduct[e.target.name] = value;
        this.setState({bbProduct: bbProduct});
        if (value.length == 15) {
            this.doesHaveServiceNumber(value);
        }
    }

    doesHaveServiceNumber(serviceNumber) {
        this.businessRuleService.doesHaveServiceNumber(serviceNumber).then(response => {
            if (response) {
                let bbProduct = this.state.bbProduct;
                bbProduct.serviceNumber = null;
                this.setState({bbProduct: bbProduct});
                return HermesSweetAlert.warn(serviceNumber + " hizmet numarasına sahip bir ürün sistemde mevcuttur! Bu hizmet numarası yeniden kullanılamaz.");
            }
        });
    }

    onOrganizationSelect = (data) => {
        let bbProduct = this.state.bbProduct;
        bbProduct.organizationId = data.id;
        bbProduct.locationId = null;
        let organization = data.code + ' - ' + data.name;
        this.setOrgAndLoc(data.id);
        this.setState({
            bbProduct: bbProduct,
            organizationObject: data,
            organization: organization,
            organizationCode: data.code,
            locationListForMultiple:[],
            servedLocationList: []
        });
        this.doesHaveBillingAccount(data.id);

    }

    doesHaveBillingAccount = (organizationId) => {
        this.businessRuleService.doesHaveBillingAccount(organizationId).then(response => {
            if (!response) {
                let bbProduct = this.state.bbProduct;
                bbProduct.organizationId = null;
                this.setState({
                    bbProduct: bbProduct,
                    organizationObject: null,
                    organization: null,
                    locationList: [],
                    organizationCode: null
                });
                return HermesSweetAlert.warn("Seçmiş olduğunuz organizasyona bağlı fatura hesabı yoktur!");
            }
        });
    }


    __handleChangeLocation = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let bbProduct = this.state.bbProduct;
        bbProduct[e.target.name] = value;
        this.setState({bbProduct: bbProduct, locationListForMultiple:[], servedLocationList: []});
        if (!GeneralUtils.isNullOrEmpty(value)) {
            this.doesHaveBbProductForConnect(this.state.bbProduct.organizationId, value);
            this.findNotInBbProductByOrganizationId(this.state.bbProduct.organizationId, value);
        }
    }

    doesHaveBbProductForConnect = (organizationId, locationId) => {
        if (GeneralUtils.isNullOrEmpty(organizationId) || GeneralUtils.isNullOrEmpty(locationId)) {
            return;
        }
        this.service.findByOrganizationIdAndLocationIdAndStatus(organizationId, locationId, ['ACTIVE', 'INSTALLED']).then(response => {
            if (!GeneralUtils.isNullOrEmpty(response)) {
                // if(this.props.clearAsset){
                //     this.props.clearAsset();
                // }
                let bbProduct = this.state.bbProduct;
                bbProduct.locationId = null;
                this.setState({bbProduct: bbProduct});
                return HermesSweetAlert.warn("Seçmiş olduğunuz organizasyon ve lokasyon üzerinde bir ürün/hizmet vardır!");
            }
        });
    }

    findNotInBbProductByOrganizationId = (orgId, locId) => {
        this.locationService.findNotInBbProductByOrganizationId(orgId, locId).then(response => {
                if (!GeneralUtils.isNullOrEmpty(response)) {
                    this.setState({locationListForMultiple: response});
                }
            }
        )
    }

    onProviderOrganizationSelect = (data) => {
        let bbProduct = this.state.bbProduct;
        bbProduct.providerOrganizationId = data.id;
        let providerOrganization = data.code + ' - ' + data.name;
        this.setState({
            bbProduct: bbProduct,
            providerOrganizationObject: data,
            providerOrganization: providerOrganization,
        });

    }

    setOrgAndLoc = (orgId) => {
        this.locationService.findNotUsedInBbProductByOrganizationId(orgId).then(response => {
                if (!GeneralUtils.isNullOrEmpty(response)) {
                    this.setState({locationList: response});
                }
            }
        )
    }

    onProductOfferingSelect = (data) => {
        let bbProduct = this.state.bbProduct;
        bbProduct.productOfferingId = data.id;
        bbProduct.productType = data.productType;
        bbProduct.bandwidthId = data.productSpecId.bandwidthId.id;
        this.setState({
            bbProduct: bbProduct,
            tariffName: data.tariffName,
            packageName: data.packageName,
            productType: data.productType,
            productSpecName: data.productSpecId.name,
            accessTechnologyPO: data.productSpecId.bandwidthId.accessTechnology,
            downloadSpeedPO: data.productSpecId.bandwidthId.downloadSpeed,
            uploadSpeedPO: data.productSpecId.bandwidthId.uploadSpeed
        });
    }

    onBillingAccountSelect = (data) => {
        let bbProduct = this.state.bbProduct;
        bbProduct.billingAccountId = data.id;
        this.setState({bbProduct: bbProduct, billingAccountName: data.name});
    }

    save = () => {
        let bbProduct = this.state.bbProduct;

        // if (bbProduct.status === 'INSTALLED') {
        //     if (GeneralUtils.isNullOrEmpty(this.state.bbProduct.installedDate)) {
        //         return toast.warn("Zorunlu Alan : Lütfen Kurulum Tarihi Alanını Giriniz!");
        //     }
        // } else if (bbProduct.status === 'ACTIVE') {
        //     if (GeneralUtils.isNullOrEmpty(this.state.bbProduct.activatedDate)) {
        //         return toast.warn("Zorunlu Alan : Lütfen Aktivasyon / Fatura Başlatma Tarihi Alanını Giriniz!");
        //     }
        // } else if (bbProduct.status === 'UNINSTALLED') {
        //     if (GeneralUtils.isNullOrEmpty(this.state.bbProduct.uninstalledDate)) {
        //         return toast.warn("Zorunlu Alan : Lütfen İptal / Fatura Sonlandırma Tarihi Alanını Giriniz!");
        //     }
        // }

        if (GeneralUtils.isNullOrEmpty(bbProduct.installedDate)) {
            return toast.warn("Zorunlu Alan : Lütfen Kurulum Tarihi Alanını Giriniz!");
        }

        let currentDate = new Date().getTime();
        if (new Date(bbProduct.installedDate).getTime() > currentDate) {
            return toast.warn("Kurulum tarihi, bugünün tarihinden sonra olamaz!");
        }

        if (GeneralUtils.isNullOrEmpty(bbProduct.installedDate) && !GeneralUtils.isNullOrEmpty(bbProduct.activatedDate)) {
            return toast.warn("Aktivasyon / fatura başlatma tarihinden önce kurulum tarihi giriniz!");
        }

        if (!GeneralUtils.isNullOrEmpty(bbProduct.installedDate) && !GeneralUtils.isNullOrEmpty(bbProduct.activatedDate)
            && new Date(bbProduct.installedDate).getTime() > new Date(bbProduct.activatedDate).getTime()) {
            return toast.warn("Kurulum tarihi, aktivasyon / fatura başlatma tarihinden sonra olamaz!");
        }
        if (GeneralUtils.isNullOrEmpty(bbProduct.installedDate) && GeneralUtils.isNullOrEmpty(bbProduct.activatedDate)
            && GeneralUtils.isNullOrEmpty(bbProduct.uninstalledDate)){
            bbProduct.status = 'PASSIVE';
        }
        if (!GeneralUtils.isNullOrEmpty(bbProduct.installedDate)){
            bbProduct.status = 'INSTALLED';
        }
        if (!GeneralUtils.isNullOrEmpty(bbProduct.activatedDate)){
            bbProduct.status = 'ACTIVE';
        }
        if (!GeneralUtils.isNullOrEmpty(bbProduct.uninstalledDate)){
            bbProduct.status = 'UNINSTALLED';
        }


        bbProduct.name = bbProduct.serviceNumber;
        bbProduct.cloudVpnId = this.state.cloudVpnId;
        bbProduct.cloudId = this.state.cloudId;
        bbProduct.servedLocationList = this.state.servedLocationList;

        this.service.create(bbProduct).then((response) => {
            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                this.clearData();
            }
        });

    }

}


BbProductSaveForMigration.propTypes = {
    bbProduct: PropTypes.object
};




