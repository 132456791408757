import React from 'react';
import {Panel} from 'primereact/panel';
import DataTable from '../../../shared/component/dataTable/DataTable';
import LogJobColumn from '../../../shared/component/dataTable/columns/LogJobColumn';
import LogJobService from "../../../shared/service/LogJobService";
import GeneralUtils from "../../../shared/utils/GeneralUtils";


export default class LogJob extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
        this.service = new LogJobService();
    }

    render() {
        return (
            <Panel header="Tarihçe İzleme">
                <DataTable ref={(elem) => this.refDataTable = elem} fields={LogJobColumn.fields} dataURL="logJob/findByHsmJobId" objectName={LogJobColumn.name} autoLoad={false}/>
            </Panel>
        );
    }


    setDataById = (hsmJobId, pageMode) => {
        if (!GeneralUtils.isNullOrEmpty(hsmJobId)) {
            this.setState({pageMode: pageMode});
            this.refDataTable.setParameter(hsmJobId);
            // this.service.findByHsmJobId(hsmJobId).then((data) => {
            //     this.setData(data, pageMode);
            // });
        }
    }

    // setData = (data, pageMode) => {
    //     if (!GeneralUtils.__isValueEmpty(data)) {
    //         this.setState({data: data, pageMode: pageMode})
    //     }
    // }


}
