import React from 'react';
import {AvField, AvForm} from 'availity-reactstrap-validation';
import {Alert, Button, Col, Row} from 'reactstrap';
import Translate from "../../shared/language/Translate";
import LoginService from "../../shared/service/LoginService";
import ReCAPTCHA from 'react-google-recaptcha';
import HsmSystemParameterService from "../../shared/service/HsmSystemParameterService";
import GeneralUtils from "../../shared/utils/GeneralUtils";

export default class PasswordResetInit extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            recaptchaToken: null,
            email: null,
        };
        this.service = new LoginService();
        this.hsmSystemParameterService = new HsmSystemParameterService();

    }


    componentDidMount() {
        if (this.reCAPTCHA) {
            this.reCAPTCHA.reset();
        }
        this.hsmSystemParameterService.doesSystemWorkInLiveMode().then(response => {
            if(response){
                this.setState({doesSystemWorkInLiveMode: response, recaptchaToken : false})
            }
            else{
                this.setState({doesSystemWorkInLiveMode: response, recaptchaToken : true})
            }
        });
    }

    onChange =(recaptchaToken)=> {
        this.setState({recaptchaToken: recaptchaToken});
    }

    render() {
        return (
            <div>
                <Alert color="warning">
                    <p>
                        <Translate contentKey="reset.request.messages.info">Kayıt olurken kullanılan e-posta adresini
                            giriniz. Şifrenizi sıfırlamak için e-postanıza gönderilen talimatları takip
                            ediniz.</Translate>
                    </p>
                </Alert>
                <AvForm >
                    <AvField
                        name="email"
                        label='E-Posta'
                        placeholder='E-Posta adresiniz'
                        type="email"
                        value={this.state.email} onChange={this.__handleChange}
                        validate={{
                            required: {value: true, errorMessage: 'Bu alan zorunlu.'},
                            minLength: {value: 5, errorMessage: 'Bu alan en az 5 karakterden oluşabilir.'},
                            maxLength: {value: 254, errorMessage: 'E-Posta adresi 254 karakterden fazla olamaz.'},
                            email: {errorMessage: 'E-Posta alanı uygun değil.'}
                        }}
                    />

                    <div>
                        <Row>
                            { this.state.doesSystemWorkInLiveMode &&
                            <Col className="text-center">
                                <ReCAPTCHA
                                    ref={(el) => {this.reCAPTCHA = el;}}
                                    size="normal"
                                    render="explicit"
                                    sitekey="6Lep9qIUAAAAAHk6E9GINxi8JKbHK7m9yIQTloQ_"
                                    onChange={this.onChange}/>
                            </Col>
                            }
                            <Col>
                                <br/>
                                <Button color="primary" onClick={this.handleValidSubmit} className="captchaButton" disabled={!this.state.recaptchaToken}>
                                    <Translate contentKey="entity.action.add">Gönder</Translate>
                                </Button>

                            </Col>
                        </Row>
                    </div>

                </AvForm>
            </div>
        );
    }

    __handleChange = (e) => {
        let state = this.state;
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        state[e.target.name] = value;
        this.setState(state);
    }

    handleValidSubmit =  () => {
        if(!GeneralUtils.__isValueEmpty(this.state.email)){
            this.service.resetPasswordInit(this.state.email);

        }

    };
}
