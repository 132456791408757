import React from 'react';
import {Button, Label} from 'reactstrap';
import {AvFeedback, AvGroup} from 'availity-reactstrap-validation';
import AvField from '../../../shared/component/form/AvField';
import PropTypes from 'prop-types';
import Translate from "../../../shared/language/Translate";
import GeneralUtils from '../../../shared/utils/GeneralUtils';
import LocationService from "../../../shared/service/LocationService";
import CityService from "../../../shared/service/CityService";
import DistrictService from "../../../shared/service/DistrictService";
import TtRegionService from "../../../shared/service/TtRegionService";
import {EXISTS, LOCATION_TYPES, PAGE_MODE, STATUS, TENANT_ID} from '../../../shared/utils/Constants';
import {Fieldset} from "primereact/fieldset";
import {PhoneField} from "../../../shared/component/form/PhoneField";
import DataTable from "../../../shared/component/dataTable/DataTable";
import Dialog from "../../../shared/component/dialog/Dialog";
import HsmOrganizationColumnForDialog from "../../../shared/component/dataTable/columns/HsmOrganizationColumnForDialog";
import HsmOrganizationService from "../../../shared/service/HsmOrganizationService";
import BbProductService from "../../../shared/service/BbProductService";
import TenantService from "../../../shared/service/TenantService";
import AvFieldUpper from "../../../shared/component/form/AvFieldUpper";

const defaultLocation = {
    id: '',
    name: '',
    status: 'A',
    code: '',
    locationType: '',
    addressLine: '',
    cityId: 1,
    districtId: '',
    buildingName: '',
    buildingNumber: '',
    doorNumber: '',
    levelNumber: '',
    statusIsDisabled: false,
    postalCode: '',
    latitude: '',
    longitude: '',
    numberOfRooms: '',
    numberOfPerson: '',
    ttRegionId: '',
    fiberExist: '',
    smartBoardExist: '',
    pstnNumber: '',
    organizationId: ''
};

export default class LocationSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            location: defaultLocation,
            cityList: [{id: 0, name: 'Adana'}],
            districtList: [],
            pageMode: null,
            organizationData: null,
            organization: null
        }
        this.service = new LocationService();
        this.cityService = new CityService();
        this.districtService = new DistrictService();
        this.ttRegionService = new TtRegionService();
        this.hsmOrganizationService = new HsmOrganizationService();
        this.bbProductservice = new BbProductService();
        this.tenantService = new TenantService();
    }

    componentDidMount = () => {
        this.cityService.findAll().then(response => this.setState({cityList: response}));
        this.districtService.findByCityId(this.state.location.cityId).then(response => {
            let location = this.state.location;
            location.districtId = response[0].id;
            this.setState({districtList: response, location: location});
        });
        this.tenantService.getTenant().then(result => {
            if(!GeneralUtils.isNullOrEmpty(result)){
                this.setState({tenantId: result});
            }
        });
    }

    render() {
        return (
            <div>

                <div className="container">
                    <div className="row">
                        <div className="col">
                            <Fieldset legend="Organizasyon Bilgileri">
                                <AvGroup>
                                    <Label for="organizationId">
                                        <Translate contentKey="location.organizationId">Organizasyon Adı</Translate>
                                        <span style={{color: 'red'}}>(*)</span>
                                    </Label>
                                    <div style={{position: "relative"}}>
                                        <AvField type="text" className="form-control" name="organization" disabled
                                                 validate={this.state.pageMode === PAGE_MODE.VIEW ? {} : {
                                                     required: {
                                                         value: true,
                                                         errorMessage: "Bu alanın doldurulması zorunludur."
                                                     }
                                                 }}
                                                 value={this.state.organization}/>{' '}
                                        {this.state.pageMode !== PAGE_MODE.VIEW &&
                                        <Button color="primary" onClick={this.findAllOrganization}
                                                disabled={this.state.statusIsDisabled}
                                                className="pi pi-search selectTableButton" size="sm"/>
                                        }
                                    </div>
                                </AvGroup>
                            </Fieldset>
                            {/*<Fieldset legend="Organizasyon Bilgileri">*/}
                            {/*    <OrganizationGroup*/}
                            {/*        value={this.state.organization}*/}
                            {/*        onDataSelect={this.onOrganizationSelect}*/}
                            {/*        disabled={this.state.pageMode === PAGE_MODE.VIEW}*/}
                            {/*        validate={this.state.pageMode === PAGE_MODE.VIEW ? {} : {*/}
                            {/*            required: {*/}
                            {/*                value: true,*/}
                            {/*                errorMessage: "Bu alanın doldurulması zorunludur."*/}
                            {/*            }*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*</Fieldset>*/}
                            &nbsp;
                            <AvGroup>
                                <Label for="code">
                                    <Translate contentKey="location.code">Tesis Kodu</Translate>
                                    <span style={{color: 'red'}}>(*)</span>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="code"
                                    disabled={this.state.pageMode === PAGE_MODE.VIEW || this.state.statusIsDisabled}
                                    validate={this.state.pageMode === PAGE_MODE.VIEW ? {} : {
                                        maxLength: {
                                            value: 20,
                                            errorMessage: 'Bu alan en fazla 20 karakterden oluşabilir.'
                                        },
                                        required: {
                                            value: "true",
                                            errorMessage: "Bu alanın doldurulması zorunludur."
                                        }
                                    }}
                                    value={this.state.location.code}
                                    onChange={this.__handleChange}/>
                            </AvGroup>
                            <AvGroup>
                                <Label for="name">
                                    <Translate contentKey="location.name">Lokasyon Adı</Translate>
                                    <span style={{color: 'red'}}>(*)</span>
                                </Label>
                                <AvFieldUpper
                                    type="text"
                                    className="form-control"
                                    name="name"
                                    validate={this.state.pageMode === PAGE_MODE.VIEW ? {} : {
                                        maxLength: {
                                            value: 255,
                                            errorMessage: 'Bu alan en fazla 255 karakterden oluşabilir.'
                                        },
                                        required: {
                                            value: "true",
                                            errorMessage: "Bu alanın doldurulması zorunludur."
                                        }
                                    }}
                                    value={this.state.location.name}
                                    onChange={this.__handleChange}
                                    disabled={this.state.pageMode === PAGE_MODE.VIEW}/>
                            </AvGroup>
                            <AvGroup>
                                <Label for="locationType">
                                    <Translate contentKey="location.locationType">Lokasyon Tipi</Translate>
                                    <span style={{color: 'red'}}>(*)</span>
                                </Label>
                                <AvField type="select" className="form-control" name="locationType"
                                         disabled={this.state.pageMode === PAGE_MODE.VIEW || this.state.pageMode === PAGE_MODE.UPDATE}
                                         validate={this.state.pageMode === PAGE_MODE.VIEW ? {} : {
                                             required: {
                                                 value: "true",
                                                 errorMessage: "Bu alanın doldurulması zorunludur."
                                             }
                                         }}
                                         value={this.state.location.locationType} onChange={this.__handleChange}>
                                    <option value='' key=''/>
                                    {LOCATION_TYPES.map(element => (
                                        <option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                                <AvFeedback>Bu alan en fazla 50 karakterden oluşabilir.</AvFeedback>
                            </AvGroup>
                            <AvGroup>
                                <Label for="status">
                                    <Translate contentKey="location.status">Durum</Translate>
                                    <span style={{color: 'red'}}>(*)</span>
                                </Label>
                                <AvField type="select" className="form-control" name="status"
                                         disabled={this.state.pageMode === PAGE_MODE.VIEW || this.state.statusIsDisabled}
                                         value={this.state.location.status} onChange={this.__handleChange}>
                                    {STATUS.map(element => (
                                        <option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>
                            <AvGroup>
                                <Label for="cityId">
                                    <Translate
                                        contentKey="location.cityId">İl Adı</Translate>
                                    <span style={{color: 'red'}}>(*)</span>
                                </Label>
                                <AvField type="select" className="form-control" name="cityId"
                                         disabled={this.state.pageMode === PAGE_MODE.VIEW || this.state.pageMode === PAGE_MODE.UPDATE}
                                         value={this.state.location.cityId}
                                         onChange={this.__handleChangeCity}>
                                    {this.state.cityList.map(element => (
                                        <option value={element.id} key={element.id}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>
                            <AvGroup>
                                <Label for="districtId">
                                    <Translate contentKey="location.districtId">İlçe Adı</Translate>
                                    <span style={{color: 'red'}}>(*)</span>
                                </Label>
                                <AvField type="select" className="form-control" name="districtId"
                                         value={!GeneralUtils.isNullOrEmpty(this.state.location.districtId) ? this.state.location.districtId : null }
                                         onChange={this.__handleChange}
                                         disabled={this.state.pageMode === PAGE_MODE.VIEW}
                                         validate={this.state.pageMode === PAGE_MODE.VIEW ? {} : {
                                             required: {
                                                 value: "true",
                                                 errorMessage: "Bu alanın doldurulması zorunludur."
                                             }
                                         }}>
                                    <option value='' key=''/>
                                    {this.state.districtList.map(element => (
                                        <option value={element.id} key={element.id}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>
                            <AvGroup>
                                <Label for="ttRegionId">
                                    <Translate contentKey="location.ttRegionId">TT Bölge Adı</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="ttRegionId"
                                    value={this.state.location.ttRegionId}
                                    onChange={this.__handleChange}
                                    disabled={true}
                                />
                            </AvGroup>
                        </div>
                        <div className="col">
                            <AvGroup>
                                <Label for="addressLine">
                                    <Translate contentKey="location.addressLine">Cadde/Sokak/Bulvar/Mahalle</Translate>
                                    <span style={{color: 'red'}}>(*)</span>
                                </Label>
                                <AvField
                                    type="textarea"
                                    className="form-control textAreaHermes"
                                    name="addressLine"
                                    validate={this.state.pageMode === PAGE_MODE.VIEW ? {} : {
                                        maxLength: {
                                            value: 200,
                                            errorMessage: 'Bu alan en fazla 200 karakterden oluşabilir.'
                                        },
                                        required: {
                                            value: "true",
                                            errorMessage: "Bu alanın doldurulması zorunludur."
                                        }
                                    }}
                                    value={this.state.location.addressLine}
                                    onChange={this.__handleChange}
                                    disabled={this.state.pageMode === PAGE_MODE.VIEW}
                                />
                                <AvFeedback>Bu alan en fazla 200 karakterden oluşabilir.</AvFeedback>
                            </AvGroup>
                            <AvGroup>
                                <Label for="buildingName">
                                    <Translate contentKey="location.buildingName">Bina Adı</Translate>
                                    <span style={{color: 'red'}}>(*)</span>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="buildingName"
                                    validate={this.state.pageMode === PAGE_MODE.VIEW ? {} : {
                                        maxLength: {
                                            value: 50,
                                            errorMessage: 'Bu alan en fazla 50 karakterden oluşabilir.'
                                        },
                                        required: {
                                            value: "true",
                                            errorMessage: "Bu alanın doldurulması zorunludur."
                                        }
                                    }}
                                    value={this.state.location.buildingName}
                                    onChange={this.__handleChange}
                                    disabled={this.state.pageMode === PAGE_MODE.VIEW}
                                />
                                <AvFeedback>Bu alan en fazla 50 karakterden oluşabilir.</AvFeedback>
                            </AvGroup>
                            <AvGroup>
                                <Label for="buildingNumber">
                                    <Translate contentKey="location.buildingNumber">Bina Numarası</Translate>
                                    <span style={{color: 'red'}}>(*)</span>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="buildingNumber"
                                    validate={this.state.pageMode === PAGE_MODE.VIEW ? {} : {
                                        maxLength: {
                                            value: 20,
                                            errorMessage: 'Bu alan en fazla 20 karakterden oluşabilir.'
                                        },
                                        required: {
                                            value: "true",
                                            errorMessage: "Bu alanın doldurulması zorunludur."
                                        }
                                    }}
                                    value={this.state.location.buildingNumber}
                                    onChange={this.__handleChange}
                                    disabled={this.state.pageMode === PAGE_MODE.VIEW}
                                />
                            </AvGroup>
                            <AvGroup>
                                <Label for="doorNumber">
                                    <Translate contentKey="location.doorNumber">Kapı Numarası</Translate>
                                </Label>
                                <AvField
                                    type="number"
                                    className="form-control"
                                    name="doorNumber"
                                    max="99999"
                                    value={this.state.location.doorNumber}
                                    onChange={this.__handleChange}
                                    disabled={this.state.pageMode === PAGE_MODE.VIEW}
                                />
                            </AvGroup>
                            <AvGroup>
                                <Label for="levelNumber">
                                    <Translate contentKey="location.levelNumber">Kat Numarası</Translate>
                                </Label>
                                <AvField
                                    type="number"
                                    className="form-control"
                                    name="levelNumber"
                                    max="999"
                                    value={this.state.location.levelNumber}
                                    onChange={this.__handleChange}
                                    disabled={this.state.pageMode === PAGE_MODE.VIEW}
                                />
                            </AvGroup>
                            <AvGroup>
                                <Label for="postalCode">
                                    <Translate contentKey="location.postalCode">Posta Kodu</Translate>
                                </Label>
                                <AvField
                                    type="number"
                                    className="form-control"
                                    name="postalCode"
                                    min="1000"
                                    max="99999"
                                    value={this.state.location.postalCode}
                                    onChange={this.__handleChange}
                                    disabled={this.state.pageMode === PAGE_MODE.VIEW}
                                />
                                <AvFeedback>Bu alan en fazla 5 karakterden oluşabilir.</AvFeedback>
                            </AvGroup>
                            <AvGroup>
                                <Label for="latitude">
                                    <Translate contentKey="location.latitude">Enlem</Translate>
                                </Label>
                                <AvField
                                    type="number"
                                    className="form-control"
                                    name="latitude"
                                    max="999999"
                                    value={this.state.location.latitude}
                                    onChange={this.__handleChange}
                                    disabled={this.state.pageMode === PAGE_MODE.VIEW}
                                />
                                <AvFeedback>Bu alanın formatı yanlış.</AvFeedback>
                            </AvGroup>
                            <AvGroup>
                                <Label for="longitude">
                                    <Translate contentKey="location.longitude">Boylam</Translate>
                                </Label>
                                <AvField
                                    type="number"
                                    className="form-control"
                                    name="longitude"
                                    max="999999"
                                    value={this.state.location.longitude}
                                    onChange={this.__handleChange}
                                    disabled={this.state.pageMode === PAGE_MODE.VIEW}
                                />
                                <AvFeedback>Bu alanın formatı yanlış.</AvFeedback>
                            </AvGroup>
                        </div>
                        <div className="col">
                            <AvGroup>
                                <Label for="numberOfRooms">
                                    <Translate contentKey="location.numberOfRooms">Oda Sayısı</Translate>
                                </Label>
                                <AvField
                                    type="number"
                                    className="form-control"
                                    name="numberOfRooms"
                                    max="999"
                                    value={this.state.location.numberOfRooms}
                                    onChange={this.__handleChange}
                                    disabled={this.state.pageMode === PAGE_MODE.VIEW}
                                />
                            </AvGroup>
                            <AvGroup>
                                <Label for="numberOfPerson">
                                    <Translate contentKey="location.numberOfPerson">Kişi Sayısı</Translate>
                                </Label>
                                <AvField
                                    type="number"
                                    className="form-control"
                                    name="numberOfPerson"
                                    max="99999"
                                    value={this.state.location.numberOfPerson}
                                    onChange={this.__handleChange}
                                    disabled={this.state.pageMode === PAGE_MODE.VIEW}
                                />
                            </AvGroup>
                            <AvGroup>
                                <Label for="fiberExist">
                                    <Translate contentKey="location.fiberExist">Fiber Altyapı</Translate>
                                </Label>
                                <AvField type="select" className="form-control" name="fiberExist"
                                         value={this.state.location.fiberExist} onChange={this.__handleChange}
                                         disabled={this.state.pageMode === PAGE_MODE.VIEW}>
                                    <option value='' key=''/>
                                    {EXISTS.map(element => (
                                        <option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>
                            {this.state.tenantId == TENANT_ID.MEB &&
                            <AvGroup>
                                <Label for="smartBoardExist">
                                    <Translate contentKey="location.smartBoardExist">Akıllı Tahta</Translate>
                                </Label>
                                <AvField type="select" className="form-control" name="smartBoardExist"
                                         value={this.state.location.smartBoardExist} onChange={this.__handleChange}
                                         disabled={this.state.pageMode === PAGE_MODE.VIEW}>
                                    <option value='' key=''/>
                                    {EXISTS.map(element => (
                                        <option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>}

                            <AvGroup>
                                <Label for="pstnNumber">
                                    <Translate contentKey="location.pstnNumber">İş Telefonu</Translate>
                                    <span style={{color: 'red'}}>(*)</span>
                                </Label>
                                <PhoneField kind={'business'}
                                            name="pstnNumber" errorMessage="Geçersiz Numara Girişi"
                                            validate={this.state.pageMode === PAGE_MODE.VIEW ? {} : {
                                                required: {
                                                    value: "true",
                                                    errorMessage: "Bu alanın doldurulması zorunludur."
                                                }
                                            }}
                                            value={this.state.location.pstnNumber}
                                            onChange={this.__handleChange}
                                            disabled={this.state.pageMode === PAGE_MODE.VIEW}
                                />
                            </AvGroup>

                        </div>
                    </div>
                </div>
                <Dialog id="OrganizationDialog" key="OrganizationDialogKey" visible={this.state.displayDialog}
                        header="Organizasyon Listesi" onHide={() => this.setState({displayDialog: false})}
                        style={{width: '90%', height: '80%'}}>
                    <DataTable ref={(elem) => this.refDataTable = elem} fields={HsmOrganizationColumnForDialog.fields} privilege="allow"
                               dataURL="hsmOrganization/findAll"
                               selectionMode="single" onDataSelect={this.onOrganizationSelect}/>
                </Dialog>
            </div>
        );
    }

    findAllOrganization = () => {
        this.refDataTable.refresh();
        this.setState({displayDialog: true});
    }

    save = () => {
        let data = Object.assign({}, this.state.location);
        data.code = GeneralUtils.getUpperCasedVariable(this.state.location.code);
        data.cityId = {id: this.state.location.cityId};
        data.districtId = {id: this.state.location.districtId};
        data.ttRegionId = {id: this.state.ttRegion.id};
        data.organizationId = {id: this.state.location.organizationId};

        if (!GeneralUtils.isNullOrEmpty(this.state.location.id)) {
            this.service.update(data)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        this.props.save();
                    }
                    if(this.props.refresh){
                        this.props.refresh(data);
                    }
                });
        } else {
            this.service.create(data)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        let location = this.state.location;
                        location.id = response.id;
                        this.setState({location: location});
                        if(this.props.save){
                            this.props.save();
                        }
                        if(this.props.refresh){
                            this.props.refresh(location);
                        }
                        if(this.props.closeDisplayDialog){
                            this.props.closeDisplayDialog();
                        }
                    }
                });
        }
    }

    setData = (data, pageMode) => {
        this.clearData(true);
        let organization = data.organizationId.name;
        data.organizationId = data.organizationId.id;
        let districtId = GeneralUtils.isNullOrEmpty(data.districtId) ? null : data.districtId.id;
        this.findDistrictByCityId(data.cityId.id, districtId);
        this.findTtRegionByCityId(data.cityId.id);
        this.setState({location: data, pageMode: pageMode, organization: organization});
        this.findStatusIsDisable(data);
    }

    setDataById = (id, pageMode) => {
        this.service.findById(id).then((data) => {
            this.setData(data, pageMode);
        });
    }

    clearData = (isRefresh) => {
        let location = Object.assign({}, defaultLocation);
        if (!isRefresh) {
            this.findDistrictByCityId(this.state.cityList[0].id);
            this.findTtRegionByCityId(this.state.cityList[0].id);
        }
        this.setState({location: location, pageMode: null, organization: null, statusIsDisabled:false, });
    }


    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let location = this.state.location;
        location[e.target.name] = value;
        this.setState({location: location});
    }

    __handleChangeCity = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let location = this.state.location;
        location[e.target.name] = value;
        this.findDistrictByCityId(value);
        this.findTtRegionByCityId(value);
    }


    findDistrictByCityId = (cityId, districtId) => {
        this.districtService.findByCityId(cityId).then(response => {
            let location = this.state.location;
            location.cityId = cityId;
            if (districtId) {
                location.districtId = districtId;
            }
            this.setState({districtList: response, location: location});
        });
    }

    findTtRegionByCityId = (cityId) => {
        this.ttRegionService.findTtRegionByCityId(cityId).then(response => {
            let location = this.state.location;
            location.ttRegionId = response.ttRegionName;
            this.setState({location: location, ttRegion: response});
        });
    }

    onOrganizationSelect = (data) => {
        let location = this.state.location;
        location.organizationId = data.id;
        location.cityId = data.cityId ? data.cityId.id : null;
        this.districtService.findByCityId(location.cityId).then(response => {
            this.setState({districtList: response});
        });
        this.ttRegionService.findTtRegionByCityId(location.cityId).then(response => {
            let location = this.state.location;
            location.ttRegionId = response.ttRegionName;
            this.setState({ttRegion: response});
        });
        location.districtId = data.districtId ? data.districtId.id : null;
        let organization = data.name;
        this.setState({location: location, organization: organization, displayDialog: false});
    }

    setPageMode = (pageMode) => {
        this.setState({pageMode: pageMode});
    }

    findStatusIsDisable = (data) => {
        this.bbProductservice.hasBbProductOnLocation(data.id).then((response => {
            let statusIsDisabled = false;
            if (response) {
                if (data.status == 'A') {
                    statusIsDisabled = true;
                }
            }
            this.setState({ statusIsDisabled: statusIsDisabled})
        }));
    }
}

LocationSave.propTypes = {
    location: PropTypes.object
};
