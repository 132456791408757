import React from 'react';
import {Button, Col, Label, Row} from 'reactstrap';
import {Fieldset} from "primereact/fieldset";
import GeneralUtils from "../../shared/utils/GeneralUtils";
import Loading from "../../shared/component/others/Loading";
import {AvForm, AvGroup} from "availity-reactstrap-validation";
import {SERVER_URL} from "../../shared/utils/Constants";
import AvField from "../../shared/component/form/AvField";
import ReactJson from "react-json-view";


const METHODS = [
    {
        value: 'get',
        name: 'GET',
    },
    {
        value: 'post',
        name: 'POST',
    },
    {
        value: 'put',
        name: 'PUT',
    },
    {
        value: 'patch',
        name: 'PATCH',
    },
    {
        value: 'delete',
        name: 'DELETE',
    },
];

class ServiceInvoke extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }


    render() {
        return (
            <div>
                <Fieldset legend="Servis Çalıştır">
                    <AvForm onValidSubmit={this.invoke}>
                        <Row>
                            <Col sm="2">
                                <AvGroup>
                                    <Label for="method">
                                        Metot
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>
                                    <AvField type="select" className="form-control" name="method"
                                             value={this.state.method} onChange={this.__handleChange}>
                                        {METHODS.map(element => (
                                            <option value={element.value} key={element.value}>
                                                {element.name}
                                            </option>
                                        ))}
                                    </AvField>
                                </AvGroup>
                            </Col>
                            <Col sm="10">
                                <AvGroup>
                                    <Label for="url">
                                        URL
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>

                                    <div style={{position: "relative"}}>
                                        <AvField
                                            type="text"
                                            className="form-control"
                                            name="url"
                                            validate={{
                                                required: {
                                                    value: true,
                                                    errorMessage: 'Bu alan zorunlu.'
                                                }
                                            }}
                                            value={this.state.url}
                                            onChange={this.__handleChange}/>
                                        <Button color="primary" className="selectTableButton" type="submit">
                                            Gönder
                                        </Button>
                                    </div>

                                </AvGroup>
                            </Col>
                        </Row>


                        <AvGroup>
                            <Label for="body">
                                Body
                            </Label>
                            <AvField type="textarea"
                                     className="form-control textAreaHermesBig" name="body"
                                     value={this.state.body}
                                     onChange={this.__handleChange}/>
                        </AvGroup>
                        {this.state.response &&
                            <div>
                                <AvGroup>
                                    <Label for="status">
                                        Status
                                    </Label>
                                    <AvField
                                        type="text"
                                        className={`form-control status-${!GeneralUtils.isNullOrEmpty(this.state.status)
                                        && this.state.status === 200 ? "Success" : "Error"}`}
                                        name="status"
                                        value={this.state.status}
                                        disabled={true}
                                    />
                                </AvGroup>
                                <AvGroup>
                                    <Label for="response">
                                        Response
                                    </Label>

                                    <ReactJson src={this.state.response} />
                                </AvGroup>
                            </div>}
                    </AvForm>
                </Fieldset>
                {this.state.loading && <Loading/>}
            </div>
        );
    }


    clearDataExcel = () => {
        this.setState({data: []});
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        this.setState({[e.target.name]: value});
    }

    invoke = () => {
        this.setState({loading: true});
        return fetch(SERVER_URL + this.state.url, {
            crossDomain: true,
            method: this.state.method,
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")},
            body: this.state.body
        }).then(response => {
                const hasResponse = !(response == null);
                if (hasResponse) {
                    this.setState({status: (hasResponse ? response.status : 0)})
                }

                let result = response.clone();
                GeneralUtils.checkUnauthorized(response);
                return result.json();
            }
        ).then(response => {
            this.setState({response: response, loading: false})
            console.log(response);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }
}

export default ServiceInvoke;
