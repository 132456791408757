import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class HsmAssetService extends Service {
    constructor() {
        super();
        this.baseURL = 'hsmAsset';
    }

    findByOrganizationIdAndLocationId(organizationId, locationId) {
        return fetch(SERVER_URL + this.baseURL + '/' + organizationId + '/' + locationId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        })
            .then(response => response.json())
            .catch(error => {
                GeneralUtils.notification(error);
            });
    }

    findByAssetTag(data) {
        return fetch(SERVER_URL + this.baseURL + '/findByAssetTag', {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            // GeneralUtils.notification(error);
        });
    }

    findAllView() {
        return fetch(SERVER_URL + this.baseURL + '/findAllView', {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        })
            .then(response => response.json())
            .catch(error => {
                GeneralUtils.notification(error);
            });
    }

    sendToStock(data) {
        return fetch(SERVER_URL + this.baseURL + '/sendToStock', {
            crossDomain: true,
            method: 'put',
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")},
            body: JSON.stringify(data)
        }).then(response => GeneralUtils.notification(response)).catch(error => {
            GeneralUtils.notification(error);
        });
    }

}