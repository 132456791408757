import React, {Component} from 'react';
import {DialogPR} from './DialogPR';
import PropTypes from 'prop-types';
import './Dialog.css';

/**
 * Samet USLU ._.
 */
export default class Dialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: this.props.visible,
            header: this.props.header
        };
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({ visible: nextProps.visible, header: nextProps.header });
        // this.maximaze(nextProps.visible);
    }

    render() {
        // if(this.state.visible === false){
        //     return null;
        // }
        return (
            <DialogPR {...this.props}  appendTo={document.body}
                visible={this.state.visible}
                header={this.state.header}
                modal={true}
                resizable={true}
                maximizable={true}
                responsive={true}
                ref={(el) => this.refDialog = el}
            />
        );
    }

    maximaze = (maximazed) => {
        this.refDialog.setState({ maximized: maximazed });
    }
}

Dialog.propTypes = {
    visible: PropTypes.bool,
    header: PropTypes.string,
    onHide: PropTypes.func
};
