import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class LogJobService extends Service{
    constructor() {
        super();
        this.baseURL = 'logJob';
    }

    findByHsmJobId(hsmJobId) {
        return fetch(SERVER_URL + this.baseURL+ '/findByHsmJobId/' + hsmJobId, {
            headers: { Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
    });
    }


}