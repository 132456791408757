import React from 'react';
import {AvGroup} from "availity-reactstrap-validation";
import {Col, Label, Row} from "reactstrap";
import AvField from "../../shared/component/form/AvField";

const defaultComplaintType = {
    id: '',
    name:''
    
};
export default class ComplaintTypeSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            complaintType: defaultComplaintType,
            pageMode: null
        };
    }

    render() {
        return (
            <Row className="justify-content-center">
                <Col>
                    <AvGroup>
                        <Label for="name">Şikayet Tipi<span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="name"
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                },
                                minLength: {
                                    value: 1,
                                    errorMessage: 'Bu alan en az 1 karakterden oluşabilir.'
                                },
                                maxLength: {
                                    value: 50,
                                    errorMessage: 'Bu alan en fazla 50 karakterden oluşabilir.'
                                }
                            }}
                            value={this.state.complaintType.name}
                            onChange={this.__handleChange}
                        />
                    </AvGroup>
                </Col>
            </Row>
        );
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let complaintType = this.state.complaintType;
        complaintType[e.target.name] = value;
        this.setState({complaintType: complaintType});
    }

    setData = (data, pageMode) => {
        this.setState({complaintType: data, pageMode: pageMode});
    }

    getData = () => {
        return this.state.complaintType;
    }

    clearData = () => {
        this.setState({complaintType: Object.assign({}, defaultComplaintType), pageMode: null});
    }

}
