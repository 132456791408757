import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class ProviderSlaRecordService extends Service{
    constructor() {
        super();
        this.baseURL = 'providerSlaRecords';
    }

    createSlaRecordForExcel(data) {
        return fetch(SERVER_URL + this.baseURL + '/createSlaRecordForExcel', {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")},
            body: JSON.stringify(GeneralUtils.addCreateInfo(data))
        }).then(response => {
            let result = response.clone();
            return result.json();
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    checkSlaRecordForExcel(data) {
        return fetch(SERVER_URL + this.baseURL + '/checkSlaRecordForExcel', {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")},
            body: JSON.stringify(GeneralUtils.addCreateInfo(data))
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response);
            return result.json();
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }
}