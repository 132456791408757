import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class SlaExceedReportService extends Service{
    constructor() {
        super();
        this.baseURL = 'slaExceedReport';
    }

    getOpenSlaCounts() {
        return fetch(SERVER_URL + this.baseURL + "/getOpenSlaCounts", {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
    });
    }


}