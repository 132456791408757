import Service from './Service';
import GeneralUtils from "../utils/GeneralUtils";
import {SERVER_URL} from "../utils/Constants";

export default class ServiceRequestService extends Service {
    constructor() {
        super();
        this.baseURL = 'serviceRequest';
    }

    findAllFilteredForDashboard(name) {
        return fetch(SERVER_URL + this.baseURL + "/findAllFilteredForDashboard/" + name + "/"+ GeneralUtils.getContactId(), {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    findAllFiltered() {
        return fetch(SERVER_URL + this.baseURL + '/findAllFiltered/' + GeneralUtils.getUserId(), {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    trigger(data) {
        return fetch(SERVER_URL + this.baseURL + '/trigger', {
            crossDomain: true,
            method: 'put',
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")},
            body: JSON.stringify(data)
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response);
            return result.json();
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    createServiceRequest(data) {
        return fetch(SERVER_URL + this.baseURL, {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")},
            body: JSON.stringify(data)
        }).then(response => {
            let result = response.clone();
            //GeneralUtils.notification(response);
            return result.json();
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

}