import React from 'react';
import {Col, Container, Row} from 'reactstrap';
import SlaExceedBarChartReport from "./SlaExceedBarChartReport";
import GeneralUtils from "../../../shared/utils/GeneralUtils";

export default class SlaExceedReport extends React.Component {

    render() {
        return (
            <Container>
                {GeneralUtils.checkPrivilege('SlaExceedReport', 'View') &&
                <Row>
                    <Col>
                        <SlaExceedBarChartReport/>
                    </Col>
                </Row>
                }
            </Container>
        );
    }
}
