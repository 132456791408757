import React from 'react';
import {Panel} from 'primereact/panel';
import DataTable from "../../../shared/component/dataTable/DataTable";
import {PAGE_MODE} from "../../../shared/utils/Constants";
import DailySlaReportColumn from "../../../shared/component/dataTable/columns/DailySlaReportColumn";
import DailySlaReportService from "../../../shared/service/DailySlaReportService";

export default class DailySlaReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            selected: {},
            pageMode: null
        };
        this.service = new DailySlaReportService();
    }

    componentDidMount = () => {
        this.service.checkPage();
    }

    render() {
        return (
            <Panel header="Günlük SLA Takip Raporu">
                <DataTable fields={DailySlaReportColumn.fields} dataURL="dailySlaReport/findDailySlaReportView" objectName={DailySlaReportColumn.name} loading={this.state.loading} export={true}
                           // selectionMode="single"
                    // onDataSelect={this.onDataSelect}
                />
            </Panel>
        );
    }

    onDataSelect = (data) => {
        this.setState({displayDialog: true, pageMode: PAGE_MODE.UPDATE, activeIndex: 0});
        this.refBbProductSave.setData(Object.assign({}, data), PAGE_MODE.UPDATE);
    }

    closeDisplayDialog = () => {
        this.setState({displayDialog: false});
    }
}
