import React from 'react';
import HsmEdrColumn from '../../../shared/component/dataTable/columns/HsmEdrColumn';
import DataTable from "../../../shared/component/dataTable/DataTable";
import HsmEdrService from "../../../shared/service/HsmEdrService";


export default class HsmEdr extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
        this.service = new HsmEdrService();
    }

    render() {
        return (
            <DataTable ref={(elem) => this.refDataTable = elem} fields={HsmEdrColumn.fields} dataURL="hsmEdr/findByAssetTypeAndAssetId" autoLoad={false} privilege="allow"/>
        );
    }

    setData = (data) => {
        this.refDataTable.setParameter('TTVPN', data.id);
        // this.service.findByAssetTypeAndAssetId('TTVPN', data.id).then(response => {
        //     this.setState({data: response})
        // });
    }

    clearData = () => {
        this.setState({data: []})
    }
}
