import Service from './Service';
import GeneralUtils from "../utils/GeneralUtils";
import {SERVER_URL} from "../utils/Constants";

export default class RatingReportService extends Service {
    constructor() {
        super();
        this.baseURL = 'ratingReport';
    }

    findRatingReportView = () => {
        return fetch(SERVER_URL + this.baseURL + '/findRatingReportView', {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    findByMonthAndYear = (month,year) =>{
        return fetch(SERVER_URL + this.baseURL + '/findByMonthAndYear/'+month+'/'+year, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    findTotalAmountAndDurationByMonthAndYear(month,year) {
        return fetch(SERVER_URL + this.baseURL + '/findTotalAmountAndDurationByMonthAndYear/'+month+'/'+year, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

}