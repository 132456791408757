import React from 'react';
import {HashRouter, Route} from 'react-router-dom';
import HsmOrganizationList from '../../module/account/organization/HsmOrganizationList';
import Team from '../../module/account/team/Team';
import TeamContactList from '../../module/account/team/TeamContactList';
import NoticeBoard from '../../module/dasboard/notice/NoticeBoard';
import Notice from '../../module/dasboard/notice/Notice';
import HsmRole from '../../module/account/role/HsmRole';
import HsmRoleMenu from '../../module/account/role/HsmRoleMenu';
import LogLogin from '../../module/administration/LogLogin';
import LogPage from '../../module/administration/LogPage';
import LogMail from '../../module/administration/logMail/LogMail';
import HsmJob from '../../module/administration/job/HsmJob';
import Cache from '../../module/administration/Cache';
import TestPage from '../../module/test/TestPage';
import CalculateRating from '../../module/rating/CalculateRating';
import Incident from '../../module/incident/Incident';
import IncidentList from '../../module/incident/IncidentList';
import HsmUser from '../../module/account/user/HsmUser';
import HsmUserAccount from '../../module/account/user/HsmUserAccount';
import PasswordChange from '../../module/account/user/PasswordChange';
import HsmAssetList from '../../module/asset-management/asset/HsmAssetList';
import HsmAssetDocumentList from "../../module/asset-management/document/HsmAssetDocumentList";
import HsmService from '../../module/asset-management/hsm-service/HsmService';
import HsmSubnetList from '../../module/asset-management/hsm-subnet/HsmSubnetList';
import BillingAccount from '../../module/asset-management/billing-account/BillingAccount';
import Brand from '../../module/asset-management/brand/Brand';
import Location from '../../module/asset-management/location/Location';
import Model from '../../module/asset-management/model/Model';
import ServiceRequest from '../../module/serviceRequest/ServiceRequest';
import ServiceRequestList from '../../module/serviceRequest/ServiceRequestList';
import TaskList from '../../module/request/task/TaskList';
import BbProductList from "../../module/asset-management/bb-product/BbProductList";
import BbProductDocumentList from "../../module/asset-management/document/BbProductDocumentList";
import HsmDocument from "../../module/test/hsmDocument/HsmDocument";
import LocationReport from "../../module/report/locationReport/LocationReport";
import TtvpnReport from "../../module/report/ttvpnReport/TtvpnReport";
import TtvpnDetailReport from "../../module/report/ttvpnReport/TtvpnDetailReport";
import ServiceRequestStatusReport from "../../module/report/serviceRequestStatusReport/ServiceRequestStatusReport";
import IncidentStatusReport from "../../module/report/incidentStatusReport/IncidentStatusReport";
import SlaExceedReport from "../../module/report/slaExceedReport/SlaExceedReport";
import SlaExceedDetailReport from "../../module/report/slaExceedReport/SlaExceedDetailReport";
import RatingReport from "../../module/report/ratingReport/RatingReport";
import AssetRating from "../../module/report/assetRating/AssetRating";
import IpPlanListReport from "../../module/report/ipPlanListReport/IpPlanListReport";
import ServiceRequestReport from "../../module/report/serviceRequestReport/ServiceRequestReport";
import DailySlaReport from "../../module/report/dailySlaReport/DailySlaReport";
import LocationDetailReport from "../../module/report/locationReport/LocationDetailReport";
import Dashboard from "../../module/dasboard/Dashboard";
import IncidentGeneralReport from "../../module/report/incidentReport/IncidentGeneralReport";
import IncidentReport from "../../module/report/incidentReport/IncidentReport";
import IncidentHistoryReport from "../../module/report/incidentReport/IncidentHistoryReport";
import DiscoveryReport from "../../module/report/discoveryReport/DiscoveryReport";
import ContactList from "../../module/account/contact/ContactList";
import WebServiceLog from "../../module/administration/WebServiceLog";
import MonitoringPage from "../../module/report/monitoringPage/MonitoringPage";
import ProductOffering from "../../module/parameter/product/ProductOffering";
import TTRecordSlaExceedReport from "../../module/report/ttRecordSlaExceedReport/TTRecordSlaExceedReport";
import ProviderSlaRecords from '../../module/rating/ProviderSlaRecords';
import LogEvent from "../../module/administration/LogEvent";
import LogViewer from "../../module/administration/LogViewer";
import MailTemplate from '../../module/administration/mailTemplate/MailTemplate';
import ServiceInvoke from "../../module/administration/ServiceInvoke";
import HsmResolutionCode from '../../module/parameter/HsmResolutionCode';
import HsmSystemParameter from '../../module/parameter/HsmSystemParameter';
import Floor from "../../module/parameter/Floor";
import Room from "../../module/parameter/Room";
import City from "../../module/parameter/City";
import District from "../../module/parameter/District";
import Bandwidth from "../../module/parameter/Bandwidth";
import ComplaintType from "../../module/parameter/ComplaintType";
import TtRegion from "../../module/parameter/TtRegion";
import Sla from "../../module/parameter/Sla";
import ServiceCategory from "../../module/parameter/ServiceCategory";
import ServiceSubcategory from "../../module/parameter/ServiceSubcategory";
import Slt from "../../module/parameter/Slt";
import SltScope from "../../module/parameter/SltScope";
import HsmOrganizationType from "../../module/parameter/HsmOrganizationType";
import AssetCategory from "../../module/parameter/AssetCategory";
import AssetType from "../../module/parameter/AssetType";
import AssetNature from "../../module/parameter/AssetNature";

import BbProductSaveForMigration from "../../module/asset-management/bb-product/BbProductSaveForMigration";
import MailTest from "../../module/test/mail/MailTest";
import HsmSubnetPoolList from "../../module/asset-management/hsm-subnet-pool/HsmSubnetPoolList";
import WorkFlowModel from "../../module/parameter/workFlow/WorkFlowModel";
import WorkFlowList from "../../module/parameter/workFlow/WorkFlowList";
import ExternalResolutionMap from "../../module/parameter/external/ExternalResolutionMap";
import ExternalSubcategoryMap from "../../module/parameter/external/ExternalSubcategoryMap";
import ExternalStatusMap from "../../module/parameter/external/ExternalStatusMap";
import ExternalTicketReport from "../../module/report/externalTicket/ExternalTicketReport";
import CalculateSla from "../../module/sla/CalculateSla";
import IncidentPendingReport from "../../module/report/incidentPendingReport/IncidentPendingReport";

const HashRouterComp =(props)=>(<HashRouter>
    <div>
        <Route path='//' component={Dashboard}/>
        <Route path='/hsmOrganization' component={HsmOrganizationList}/>
        <Route path='/team' component={Team}/>
        <Route path='/teamContactList' component={TeamContactList}/>
        <Route path='/hsmRole' component={HsmRole}/>
        <Route path='/hsmRoleMenu' component={HsmRoleMenu}/>
        <Route path='/hsmUser' component={HsmUser}/>
        <Route path='/hsmUserAccount' component={HsmUserAccount}/>
        <Route path='/contact' component={ContactList}/>
        <Route path='/hsmAsset' component={HsmAssetList}/>
        <Route path='/hsmAssetS' component={HsmAssetList}/>
        <Route path='/hsmAssetND' component={HsmAssetList}/>
        <Route path='/hsmAssetE' component={HsmAssetList}/>
        <Route path='/hsmAssetUD' component={HsmAssetList}/>
        <Route path='/hsmAssetDocumentList' component={HsmAssetDocumentList}/>
        <Route path='/hsmService' component={HsmService}/>
        <Route path='/hsmSubnetList' component={HsmSubnetList}/>
        <Route path='/brand' component={Brand}/>
        <Route path='/location' component={Location}/>
        <Route path='/broadbandProduct' component={BbProductList}/>
        <Route path='/billingAccount' component={BillingAccount}/>
        <Route path='/model' component={Model}/>
        <Route path='/noticeBoard' component={NoticeBoard}/>
        <Route path='/notice' component={Notice}/>
        <Route path='/logLogin' component={LogLogin}/>
        <Route path='/logPage' component={LogPage}/>
        <Route path='/logMail' component={LogMail}/>
        <Route path='/hsmJob' component={HsmJob}/>
        <Route path='/cache' component={Cache}/>
        <Route path='/testPage' component={TestPage}/>
        <Route path='/calculateRating' component={CalculateRating}/>
        <Route path='/incident' component={Incident}/>
        <Route path='/incidentList' component={IncidentList}/>
        <Route path='/incidentListClosed' component={IncidentList}/>
        <Route path='/passwordChange' component={PasswordChange}/>
        <Route path='/bbProductDocumentList' component={BbProductDocumentList}/>
        <Route path='/hsmDocument' component={HsmDocument}/>
        <Route path='/taskList' component={TaskList}/>
        <Route path='/serviceRequest' component={ServiceRequest}/>
        <Route path='/serviceRequestList' component={ServiceRequestList}/>
        <Route path='/serviceRequestListClosed' component={ServiceRequestList}/>
        <Route path='/locationReport' component={LocationReport}/>
        <Route path='/locationDetailReport' component={LocationDetailReport}/>
        <Route path='/ttvpnReport' component={TtvpnReport}/>
        <Route path='/ttvpnDetailReport' component={TtvpnDetailReport}/>
        <Route path='/serviceRequestStatusReport' component={ServiceRequestStatusReport}/>
        <Route path='/incidentStatusReport' component={IncidentStatusReport}/>
        <Route path='/slaExceedReport' component={SlaExceedReport}/>
        <Route path='/slaExceedDetailReport' component={SlaExceedDetailReport}/>
        <Route path='/ratingReport' component={RatingReport}/>
        <Route path='/ratingReportTt' component={RatingReport}/>
        <Route path='/assetRating' component={AssetRating}/>
        <Route path='/assetRatingForCustomer' component={AssetRating}/>
        <Route path='/dailySlaReport' component={DailySlaReport}/>
        <Route path='/incidentReport' component={IncidentReport}/>
        <Route path='/incidentHistoryReport' component={IncidentHistoryReport}/>
        <Route path='/incidentGeneralReport' component={IncidentGeneralReport}/>
        <Route path='/discoveryReport' component={DiscoveryReport}/>
        <Route path='/webServiceLog' component={WebServiceLog}/>
        <Route path='/monitoringPage' component={MonitoringPage}/>
        <Route path='/productOffering' component={ProductOffering}/>
        <Route path='/serviceRequestReport' component={ServiceRequestReport}/>
        <Route path='/ttRecordSlaExceedReport' component={TTRecordSlaExceedReport}/>
        <Route path='/providerSlaRecords' component={ProviderSlaRecords}/>
        <Route path='/ipPlanListReport' component={IpPlanListReport}/>
        <Route path='/logEvent' component={LogEvent}/>
        <Route path='/logViewer' component={LogViewer}/>
        <Route path='/mailTemplate' component={MailTemplate}/>
        <Route path='/serviceInvoke' component={ServiceInvoke}/>
        <Route path='/hsmResolutionCode' component={HsmResolutionCode}/>
        <Route path='/hsmSystemParameter' component={HsmSystemParameter}/>
        <Route path='/floor' component={Floor}/>
        <Route path='/room' component={Room}/>
        <Route path='/city' component={City}/>
        <Route path='/district' component={District}/>
        <Route path='/bandwidth' component={Bandwidth}/>
        <Route path='/complaintType' component={ComplaintType}/>
        <Route path='/ttRegion' component={TtRegion}/>
        <Route path='/sla' component={Sla}/>
        <Route path='/serviceCategory' component={ServiceCategory}/>
        <Route path='/serviceSubcategory' component={ServiceSubcategory}/>
        <Route path='/slt' component={Slt}/>
        <Route path='/sltScope' component={SltScope}/>
        <Route path='/hsmOrganizationType' component={HsmOrganizationType}/>
        <Route path='/workFlowModel' component={WorkFlowModel}/>
        <Route path='/assetCategory' component={AssetCategory}/>
        <Route path='/assetType' component={AssetType}/>
        <Route path='/assetNature' component={AssetNature}/>
        <Route path='/bbProductSaveForMigration' component={BbProductSaveForMigration}/>
        <Route path='/mailTest' component={MailTest}/>
        <Route path='/hsmSubnetPoolList' component={HsmSubnetPoolList}/>
        <Route path='/workFlowList' component={WorkFlowList}/>
        <Route path='/externalResolutionMap' component={ExternalResolutionMap}/>
        <Route path='/externalStatusMap' component={ExternalStatusMap}/>
        <Route path='/externalSubcategoryMap' component={ExternalSubcategoryMap}/>
        <Route path='/externalTicketReport' component={ExternalTicketReport}/>
        <Route path='/calculateSla' component={CalculateSla}/>
        <Route path='/incidentPendingReport' component={IncidentPendingReport}/>

    </div>
</HashRouter>);

export default HashRouterComp;
