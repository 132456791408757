import React, {Component} from 'react';
import TenantService from "../../service/TenantService";
import GeneralUtils from "../../utils/GeneralUtils";
import {CONFIG} from "../../utils/Config";

export class AppFooter extends Component {
    constructor() {
        super();
        this.state = {
            logoMain: CONFIG.LOGO.replace('TENANT_ID', 'DEFAULT'),
            logoOther: CONFIG.LOGO.replace('TENANT_ID', 'DEFAULT'),
        };
        this.tenantService = new TenantService();
    }

    componentDidMount = () => {
        this.tenantService.getTenantWithoutLogin().then(result => {
            if(!GeneralUtils.isNullOrEmpty(result)){
                this.setState({logoMain: CONFIG.LOGO_HERMES.replace('TENANT_ID', result), logoOther: CONFIG.LOGO.replace('TENANT_ID', result)});
            }
        });
    }

    render() {
        return (
            <div className="layout-footer p-grid p-fluid">
                <div className="p-col-3">
                    <img src={this.state.logoOther} alt="" height="50"/>
                </div>
                <div className="p-col-6" style={{textAlign: 'center'}}>
                    <img src={this.state.logoMain} alt="" height="40"/>
                    <span className="footer-text" style={{'marginLeft': '10px', marginTop: '9px'}}>İnnova Bilişim Çözümleri A.Ş © 2022</span>
                </div>
                <div className="p-col-3">
                    <img src={CONFIG.TT.LOGO} alt="" height="45" style={{float: 'right'}}/>
                </div>
            </div>
        );
    }
}
