import React from 'react';
import DataTableAll from "../../../shared/component/dataTable/DataTableAll";
import TaskColumn from "../../../shared/component/dataTable/columns/TaskColumn";
import TaskService from "../../../shared/service/TaskService";
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import {Button} from "reactstrap";
import Translate from "../../../shared/language/Translate";
import Dialog from "../../../shared/component/dialog/Dialog";
import {AvForm} from "availity-reactstrap-validation";
import {TabPanel, TabView} from "primereact/tabview";
import {PAGE_MODE, REQUEST_TYPE, TASK_NAME} from "../../../shared/utils/Constants";
import TaskSave from "./TaskSave";
import {Fieldset} from "primereact/fieldset";
import RequestWorknote from "../RequestWorknote";
import {toast} from 'react-toastify';
import {DiscoveryOperation} from "./DiscoveryOperation";
import RequestDiscoveryItemService from "../../../shared/service/RequestDiscoveryItemService";
import {Panel} from "primereact/panel";
import DataTable from "../../../shared/component/dataTable/DataTable";
import AuditEvent from "../../administration/AuditEvent";
import ServiceRequestService from "../../../shared/service/ServiceRequestService";
import RequestAsset from "../RequestAsset";

class Task extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            discoveryDisabled: true,
            pageMode: null,
            inventoryEnteringDisabled: true
        };
        this.service = new TaskService();
        this.requestDiscoveryItemService = new RequestDiscoveryItemService();
        this.serviceRequestService = new ServiceRequestService();
    }

    componentDidMount=()=> {
        if(this.props.inMenu) {
            this.refresh();
        }
    }

    render() {
        return (
            <div>
                {this.props.inMenu ? this.getContentInMenu() : this.getContent()}
                <Dialog visible={this.state.displayDialog}
                        header={GeneralUtils.getDialogHeader('Görev', this.state.pageMode)}
                        onHide={() => this.setState({displayDialog: false})}
                        style={{width: '70vw'}}>
                    <AvForm onValidSubmit={this.save} beforeSubmitValidation={() => this.setState({activeIndex: 0})}>
                        <Toolbar
                            display={GeneralUtils.checkPrivilege(TaskColumn.name, 'Create')}>
                            <div className="p-toolbar-group-right">
                                <div>
                                    {this.state.taskStatus != 'CLOSED' &&
                                    <Button color="primary" type="submit" className="float-right">
                                        &nbsp;
                                        <Translate contentKey="entity.action.save">Kaydet</Translate>
                                    </Button>}
                                    <Button color="primary" className="float-right"
                                            onClick={() => this.closeDisplayDialog()}>
                                        &nbsp;
                                        <Translate contentKey="entity.action.save">Vazgeç</Translate>
                                    </Button>
                                </div>
                            </div>
                        </Toolbar>
                        <TabView activeIndex={this.state.activeIndex}
                                 onTabChange={(e) => this.setState({activeIndex: e.index})} renderActiveOnly={false}>
                            <TabPanel header="Görev Bilgileri">
                                <TaskSave onChangeDiscoveryDisabled={(e) => {
                                    this.openOrCloseDiscoveryTab(e)
                                }} ref={(elem) => this.refTaskSave = elem} />
                            </TabPanel>

                            <TabPanel disabled={this.state.discoveryDisabled}
                                      headerClassName={this.state.discoveryDisabled && 'displayNone'}
                                      header={GeneralUtils.isNullOrEmpty(this.state.discoveryItemLength) ? "Keşif İşlemleri" : "Keşif İşlemleri (" + this.state.discoveryItemLength + ")"}
                            >
                                <DiscoveryOperation ref={(elem) => this.refDiscoveryOperation = elem}
                                   setLength={(discoveryItemLength) => this.setState({discoveryItemLength})}
                                />
                            </TabPanel>

                            <TabPanel disabled={this.state.inventoryEnteringDisabled}
                                      headerClassName={this.state.inventoryEnteringDisabled && 'displayNone'}
                                      header={GeneralUtils.isNullOrEmpty(this.state.assetLength) ? "Envanter Girişi" : "Envanter Girişi (" + this.state.assetLength + ")"}
                            >
                                <RequestAsset ref={elem => this.refRequestAsset = elem}
                                              requestType={this.props.requestType}
                                              serviceSubcategoryId={this.props.serviceSubcategoryId}
                                              createNewHsmAsset={this.props.createNewHsmAssetForInventory}
                                              setLength={(assetLength) => this.setState({assetLength})}
                                              showMode='INVENTORY'
                                              taskStatus={this.state.taskStatus}
                                />
                            </TabPanel>
                            <TabPanel header="Tarihçe" rightIcon="pi pi-user"
                                      disabled={this.state.pageMode === PAGE_MODE.CREATE}
                                      headerClassName={this.state.pageMode === PAGE_MODE.CREATE && 'displayNone'}>
                                <AuditEvent ref={elem => this.refAuditEvent = elem} objectName={TaskColumn.name}/>
                            </TabPanel>
                        </TabView>
                    </AvForm>
                    {GeneralUtils.checkPrivilege(TaskColumn.name, 'Create') &&
                    <Fieldset legend="İş Notları" toggleable={true} collapsed={this.state.panelCollapsedSR}
                              onToggle={(e) => this.setState({panelCollapsedSR: e.value})}
                              style={{display: this.state.pageMode === PAGE_MODE.CREATE ? 'none' : ''}}>
                        <RequestWorknote ref={elem => this.refRequestWorknote = elem}/>
                    </Fieldset>
                    }
                </Dialog>
            </div>
        );
    }

    getContent = () =>{
        return (<div>
            {this.state.pageMode === PAGE_MODE.UPDATE &&
            <Toolbar display={true}>
                <div className="p-toolbar-group-right">
                    <Button color="primary" onClick={this.addNew}
                            disabled={!GeneralUtils.checkPrivilege(TaskColumn.name, 'Create')}>
                        <Translate contentKey="entity.action.add">Ekle</Translate>
                    </Button>
                </div>
            </Toolbar>
            }

            <DataTableAll fields={TaskColumn.fields} data={this.state.data} objectName={TaskColumn.name}
                          selectionMode={this.state.pageMode === PAGE_MODE.UPDATE ? "single" : null}
                          onDataSelect={this.onDataSelect} loading={this.state.loading}/>
        </div>);
    }

    getContentInMenu = () =>{
        return (
            <Panel header="Görev Yönetimi">
                <DataTable ref={(elem) => this.refDataTable = elem} fields={TaskColumn.fields} dataURL="task/findAllFiltered" objectName={TaskColumn.name}
                           selectionMode="single"
                           onDataSelect={this.onDataSelect} autoLoad={false}/>
            </Panel>);
    }

    refresh = () =>{
        if(this.props.inMenu) {
            this.refDataTable.setParameter(GeneralUtils.getContactId());
        }else{
            this.findByRequestTypeAndRequestId();
        }
    }

    openOrCloseDiscoveryTab = (e) => {
        e === "DiscoveryTask" ? this.setState({discoveryDisabled: false}) : this.setState({discoveryDisabled: true});
        e === "InventoryEntry" ? this.setState({inventoryEnteringDisabled: false}) : this.setState({inventoryEnteringDisabled: true});
    }

    addNew = () => {
        this.setState({displayDialog: true, pageMode: PAGE_MODE.UPDATE, activeIndex: 0, taskStatus: null,discoveryDisabled: true,inventoryEnteringDisabled: true, assetList:[]});
        this.refTaskSave.clearData();
        this.refDiscoveryOperation.clearData();
        this.refTaskSave.setRequestTypeAndRequestId(this.state.requestType,this.state.requestId);
        this.refTaskSave.haveAnyRecord(this.state.requestType,this.state.requestId);
        this.setState({discoveryDisabled: true});
        this.refDiscoveryOperation.setData(this.state.requestType,this.state.requestId);

        this.serviceRequestService.findById(this.state.requestId).then(data=>{
            this.refRequestAsset.setData({id: data.id,organizationId:data.organizationId.id,locationId:data.locationId.id}, REQUEST_TYPE.SERVICE_REQUEST, PAGE_MODE.CREATE, null, 'INVENTORY');
        })
    }

    findByRequestTypeAndRequestId = () => {
        this.setState({loading: true});
        this.service.findByRequestTypeAndRequestId(this.state.requestType, this.state.requestId).then(response => this.setState({
            data: response,
            loading: false
        }));
    }

/*TODO
    this.state.id=id; gibi kullanımları kaldır.
*/

    setData = (data, requestType, pageMode, status) => {
        this.setState({loading: true});
        this.service.findByRequestTypeAndRequestId(requestType, data.id).then(response => {
                this.setState({
                    data: response,
                    requestId: data.id,
                    requestType: requestType,
                    loading: false,
                    pageMode: pageMode,
                    status: status
                });
            if (this.props.setLength) {
                this.props.setLength(response.length);
                }
            }
        );

    }


    onDataSelect = (data) => {
        let taskStatus = data.status;
        let pageMode = GeneralUtils.getPageModeFromPrivilege(TaskColumn.name);
        this.setState({displayDialog: true, pageMode: pageMode, activeIndex: 0, taskStatus: data.status,assetList:[]});
        this.refTaskSave.clearData();
        this.refTaskSave.setData(Object.assign({}, data), pageMode);
        this.refRequestWorknote.setData(data, 'TASK', pageMode, null, null, null);
        this.openOrCloseDiscoveryTab(data.name);
        if(data.name=='InventoryEntry'){
            this.service.findById(data.id).then(data=>{
                this.serviceRequestService.findById(data.requestId).then(data=>{
                    this.refRequestAsset.setData({id: data.id,organizationId:data.organizationId.id,locationId:data.locationId.id}, REQUEST_TYPE.SERVICE_REQUEST, pageMode, null, 'INVENTORY');
                })
            })
        }

        if(this.props.inMenu){
            this.service.findById(data.id).then(data=>{
                this.serviceRequestService.findById(data.requestId).then(data=>{
                    this.refDiscoveryOperation.setData('SERVICE_REQUEST',data.id,taskStatus);
                })
            })
        }else{
            this.refDiscoveryOperation.setData(this.state.requestType,this.state.requestId,taskStatus);
        }
        this.refAuditEvent.setData(TaskColumn.name, data.id);
    }

    save = () => {
        let task = this.refTaskSave.getData();
        let requestAssetList = this.refRequestAsset.getData();

        if (GeneralUtils.isNullOrEmpty(task.requestId) || GeneralUtils.isNullOrEmpty(task.requestType)) {
            return toast.warn('Görev talep ile ilişkili olmalıdır.')
        }
        if(task.status === 'CLOSED') {
            if (GeneralUtils.isNullOrEmpty(task.assignedTo) || (!GeneralUtils.isNullOrEmpty(task.assignedTo) && GeneralUtils.getContactId() != task.assignedTo)) {
                this.refTaskSave.changeStatus('OPEN');
                return toast.warn('Görevi kapatabilmek için kendinize atayınız.')
            }
        }
        if(!GeneralUtils.isNullOrEmpty(task.plannedStartDate) && !GeneralUtils.isNullOrEmpty(task.plannedEndDate)){
            let plannedStartDate = new Date(task.plannedStartDate).getTime();
            let plannedEndDate = new Date(task.plannedEndDate).getTime();
            if (plannedStartDate > plannedEndDate) {
                return toast.warn("Başlangıç tarihi bitiş tarihinden sonra olamaz!");
            }
        }
        if(!GeneralUtils.isNullOrEmpty(task.plannedStartDate) && !GeneralUtils.isNullOrEmpty(task.plannedEndDate)){
            let plannedStartDate = new Date(task.plannedStartDate).getTime();
            let plannedEndDate = new Date(task.plannedEndDate).getTime();
            if (plannedStartDate > plannedEndDate) {
                return toast.warn("Başlangıç tarihi bitiş tarihinden sonra olamaz!");
            }
        }

        // if(task.name === 'InventoryEntry'){
        //     if(requestAssetList.length === 0 ){
        //         this.setState({activeIndex: 2});
        //         return toast.warn("Lütfen envanter ekleyiniz!");
        //     }
        // }

        let requestDiscoveryItems = this.refDiscoveryOperation.getData();

        // if(task.name === 'DiscoveryTask'){
        //     if(requestDiscoveryItems.length === 0 ){
        //         this.setState({activeIndex: 1});
        //         return toast.warn("Lütfen keşif cihazı ekleyiniz!");
        //     }
        // }

        if (!GeneralUtils.isNullOrEmpty(requestDiscoveryItems) && this.state.discoveryDisabled === false) {
            if (this.refDiscoveryOperation.checkQuantityValue()) {
                this.setState({activeIndex: 1});
                return toast.error("Toplam 0' dan büyük olmalıdır.");
            }
        }

        let data ;

        if (!GeneralUtils.isNullOrEmpty(task.id)) {
            task = GeneralUtils.addUpdateInfo(task)
             data = {
                 task: task,
                 requestDiscoveryItems: requestDiscoveryItems,
                 version: this.state.data.version,
                 requestAssetList: requestAssetList.map((element) => GeneralUtils.addCreateInfo(element))
             };
            this.service.update(data).then((response) => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    this.setState({displayDialog: false});
                    this.refresh();
                    if (this.props.refreshAssetList) {
                        this.props.refreshAssetList();
                    }
                    if(this.props.version){
                        this.props.version(response.version);
                    }
                }
            });
        } else {
            task.requestId = this.state.requestId;
            task.requestType = this.state.requestType;
            task = GeneralUtils.addCreateInfo(task)
            data = {
                task: task,
                requestDiscoveryItems: requestDiscoveryItems,
                version: this.state.data.version,
                requestAssetList: requestAssetList.map((element) => GeneralUtils.addCreateInfo(element))
            };

            this.service.create(data).then((response) => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    this.setState({displayDialog: false});
                    this.refresh();
                    if (this.props.refreshAssetList) {
                        this.props.refreshAssetList();
                    }
                    if(this.props.version){
                        this.props.version(response.version);
                    }
                }
            });
        }
    }

    closeDisplayDialog = () => {
        this.setState({displayDialog: false});
    }

    setPageModeAndStatus = (pageMode, status) => {
        this.setState({pageMode: pageMode, status: status});
    }

    getData = () =>{
        return this.state.data;
    }

    onHsmAssetSelect = (data) =>{
        this.refRequestAsset.onHsmAssetSelect(data);
    }

}

export default Task;