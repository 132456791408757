import React from 'react';
import PropTypes from 'prop-types';
import {TabPanel, TabView} from "primereact/tabview";
import {MRTG_LINK, PAGE_MODE, REQUEST_TYPE, TENANT_ID} from "../../shared/utils/Constants";
import NewIncident from "./NewIncident";
import {AvForm, AvGroup} from "availity-reactstrap-validation";
import {Button, Container} from "reactstrap";
import IncidentService from "../../shared/service/IncidentService"
import GeneralUtils from "../../shared/utils/GeneralUtils";
import RequestContact from "../request/RequestContact";
import RequestAsset from "../request/RequestAsset";
import {SplitButton} from 'primereact/splitbutton';
import RequestComment from "../request/RequestComment";
import RequestWorknote from "../request/RequestWorknote";
import Toolbar from "../../shared/component/toolbar/Toolbar";
import WorkFlowModelService from "../../shared/service/WorkFlowModelService";
import TeamContactService from "../../shared/service/TeamContactService";
import HsmUserService from "../../shared/service/HsmUserService";


import StatusChangeDialog from "../request/hsmDialog/StatusChangeDialog";
import AssignedDialog from "../request/hsmDialog/AssignedDialog";
import ResolveDialog from "../request/hsmDialog/ResolveDialog";
import CloseDialog from "../request/hsmDialog/CloseDialog";
import PendingDialog from "../request/hsmDialog/PendingDialog";
import Attachment from "../request/attacheddocument/Attachment";
import HsmDocumentService from "../../shared/service/HsmDocumentService";
import RequestSlt from "../request/RequestSlt";
import AuditEvent from "../administration/AuditEvent";
import Loading from "../../shared/component/others/Loading";
import IncidentColumn from '../../shared/component/dataTable/columns/IncidentColumn';
import HsmSurveyResultService from "../../shared/service/HsmSurveyResultService";
import HsmSurveyService from "../../shared/service/HsmSurveyService";
import * as Survey from "survey-react/survey.react";
import Translate from "../../shared/language/Translate";
import Dialog from "../../shared/component/dialog/Dialog";
import ContactSave from "../account/contact/ContactSave";
import FlowGroup from "../../shared/component/hsmGroup/FlowGroup";
import ModalConfirm from "../request/hsmDialog/ModalConfirm";
import BusinessRuleService from "../../shared/service/BusinessRuleService";
import DataTable from "../../shared/component/dataTable/DataTable";
import HermesSweetAlert from "../../shared/component/sweetAlert/HermesSweetAlert";
import HsmSystemParameterService from "../../shared/service/HsmSystemParameterService";
import Event from "../administration/Event";
import TenantService from "../../shared/service/TenantService";
import Contact from "../account/contact/Contact";
import AvField from "../../shared/component/form/AvField";
import {Fieldset} from "primereact/fieldset";
import IncidentHistoryColumn from "../../shared/component/dataTable/columns/IncidentHistoryColumn";

export default class Incident extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            action: '',
            pageMode: PAGE_MODE.CREATE,
            status: '',
            isSameContact: false,
            isSameTeam: false,
            // disableWorkNoteTab: false,
            renderIncidentPage: true,
            items: [],
            disabledTabs: true,
            disabledIncidentFollowTab: true,
            loading: false,
            survey: new Survey.Model({}),
            flowList: [],
            incidentData:{},
            maxReopenCount:'',
            disabledEditButton : true,
            isAllowedTeam: false,
            disabledResumeButton : true,
            surveyResult: [],
            disabledTabSurveyResult: false,
            documentIdList:[],
            disabledSaveButton: false
        };

        this.service = new IncidentService();
        this.workFlowModelService = new WorkFlowModelService();
        this.teamContactService = new TeamContactService();
        this.hsmUserService = new HsmUserService();
        this.hsmDocumentService = new HsmDocumentService();
        this.hsmSurveyService = new HsmSurveyService();
        this.hsmSurveyResultService = new HsmSurveyResultService();
        this.businessRuleService = new BusinessRuleService();
        this.hsmSystemParameterService = new HsmSystemParameterService();
        this.tenantService = new TenantService();
    }

    componentDidMount = () => {
        this.hsmSystemParameterService.findMaxReopenCountByKey().then(response =>this.setState({maxReopenCount:response}));
        this.service.checkPage();
        this.tenantService.getTenantWithoutLogin().then(result => {
            if(!GeneralUtils.isNullOrEmpty(result) && result == TENANT_ID.MEB){
                this.setState({displayMRTGLink: true});
            }
        });
    }

    render() {
        if (this.state.renderIncidentPage) {
            return (
                <div>
                    <div>
                        <AvForm onValidSubmit={this.checkAndSave}>
                            <Toolbar display={true}>
                                {this.state.pageMode !== PAGE_MODE.CREATE &&
                                <div className="p-toolbar-group-left">
                                    <FlowGroup flowList={this.state.flowList} flowId={this.state.flowId}/>
                                </div>}
                                {/*<div className="p-toolbar-group-left">{this.state.serviceNumber}</div>*/}
                                <div className="p-toolbar-group-right" style={{paddingTop: 5}}>
                                    {this.state.displayMRTGLink &&
                                    <Button color="primary" onClick={() => window.open(MRTG_LINK,'_blank')}>
                                        MRTG Giriş
                                    </Button>
                                    }
                                    {(!GeneralUtils.__isValueEmpty(this.state.organizationId) && !GeneralUtils.__isValueEmpty(this.state.locationId)) &&
                                    <Button color="primary" onClick={this.incidentHistory}>
                                        Arıza Geçmişi
                                    </Button>}
                                    {this.buttonRender('cancelButton') &&
                                    <Button color="primary" onClick={this.props.closeDialog}>
                                        Vazgeç
                                    </Button>
                                    }
                                    {this.buttonRender('saveButton') &&
                                    <Button color="primary" type="submit" disabled={this.state.disabledSaveButton}>
                                        Kaydet
                                    </Button>
                                    }
                                    {this.buttonRender('modifyButton') &&
                                    <Button color="primary" onClick={this.modify} disabled={this.state.disabledEditButton}>
                                        Düzenle
                                    </Button>
                                    }
                                    {this.buttonRender('assigneeButton') &&
                                    <Button color="primary" onClick={this.showAssigneeDialog}>
                                        Ata
                                    </Button>
                                    }
                                    {this.buttonRender('assigneeToMeButton') &&
                                    <Button color="primary" onClick={this.assigneeToMe}>
                                        Kendime Ata
                                    </Button>
                                    }
                                    {this.buttonRender('resumeButton') &&
                                    <Button color="primary" onClick={this.resumeCheck} disabled={this.state.disabledResumeButton}>
                                        Askıdan İndir
                                    </Button>
                                    }
                                    {this.buttonRender('splitButton') &&
                                    <SplitButton ref={elem => this.refSplitButton = elem} label="İşlemler"
                                                 icon="pi pi-plus" onClick={this.showSplitButtonMenu}
                                                 model={this.state.items} select={this.select}></SplitButton>
                                    }
                                </div>
                            </Toolbar>

                            <TabView activeIndex={this.state.activeIndex} renderActiveOnly={false}
                                     onTabChange={(e) => this.setState({activeIndex: e.index})}>
                                <TabPanel header="Arıza Bilgileri">
                                    <NewIncident ref={elem => this.refNewIncident = elem}
                                                 addAsset={(data) => {this.refRequestAsset.onDataSelect(data, false);}}
                                                 clearAsset={this.clearAsset}
                                                 addContact={(data) => {this.refRequestContact.addContact(data);}}
                                                 clearContact={this.clearContact}
                                                 disabledTabs={(e) => this.setState({disabledTabs: false,disabledIncidentFollowTab:e})}
                                                 organizationId={(organizationId) => this.setState({organizationId: organizationId})}
                                                 locationId={(locationId) => this.setState({locationId: locationId})}
                                                 openContactDialog={() => {
                                                     this.refContactSave.clearData();
                                                     this.setState({displayContactDialog: true});
                                                 }}
                                        // setSurvey={(survey)=>{this.setState({survey: survey})}}
                                        // setDisabledTabSurveyResult={()=> this.setState({disabledTabSurveyResult: true})}
                                    />
                                </TabPanel>

                                <TabPanel
                                    header={GeneralUtils.isNullOrEmpty(this.state.assetList) ? "Varlıklar" : "Varlıklar (" + this.state.assetList.length + ")"}
                                    disabled={this.state.disabledTabs}
                                    headerClassName={this.state.disabledTabs && 'displayNone'}>
                                    {this.getRequestAsset()}
                                </TabPanel>

                                <TabPanel
                                    header={GeneralUtils.isNullOrEmpty(this.state.contactList) ? "Kişiler" : "Kişiler (" + this.state.contactList.length + ")"}
                                    disabled={this.state.disabledTabs}
                                    headerClassName={this.state.disabledTabs && 'displayNone'}>
                                    {this.getRequestContact()}
                                    {/*
                                    <RequestContact ref={elem => this.refRequestContact = elem}
                                                    organizationId={this.state.organizationId}
                                                    setRequestContactList={(requestContactList) => this.setState({requestContactList: requestContactList})}/>
*/}
                                </TabPanel>

                                <TabPanel
                                    header={GeneralUtils.isNullOrEmpty(this.state.attachmentLength) ? "Dokümanlar" : "Dokümanlar (" + this.state.attachmentLength + ")"}
                                    disabled={this.state.disabledTabs}
                                    headerClassName={this.state.disabledTabs && 'displayNone'}>

                                    <Attachment ref={elem => this.refAttachedDocument = elem}
                                                setAttachmentLength={(length) => this.setState({attachmentLength: length})}
                                                setDocumentId={(documentId)=>{
                                                    let documentIdList = this.state.documentIdList;
                                                    documentIdList.push(documentId);
                                                    this.setState({documentIdList});
                                                }
                                                }
                                                deleteDocumentId={(documentId)=>{
                                                    let documentIdList = this.deleteDocumentId(documentId);
                                                    this.setState({
                                                        documentIdList
                                                    });
                                                }}
                                    />
                                </TabPanel>

                            </TabView>
                        </AvForm>
                        <br/><br/>
                        <TabView activeIndex={this.state.activeIndexCW} renderActiveOnly={false}
                                 style={{display: this.state.pageMode === PAGE_MODE.CREATE ? 'none' : ''}}
                                 onTabChange={(e) => this.setState({activeIndexCW: e.index})}>

                            <TabPanel header="Yorumlar">
                                <RequestComment ref={elem => this.refRequestComment = elem}/>
                            </TabPanel>

                            <TabPanel header="İş Notları"
                                // disabled={this.state.disableWorkNoteTab}
                            >
                                <RequestWorknote ref={elem => this.refRequestWorknote = elem}/>
                            </TabPanel>

                            <TabPanel
                                header="Kontroller"
                                disabled={this.state.pageMode === PAGE_MODE.CREATE || this.state.disabledTabs || this.state.disabledTabSurveyResult}
                                headerClassName={(this.state.pageMode === PAGE_MODE.CREATE || this.state.disabledTabs || this.state.disabledTabSurveyResult) && 'displayNone'}>

                                {this.getSurveyResultDescription()}
                            </TabPanel>

                            <TabPanel
                                header={this.state.pageMode === PAGE_MODE.CREATE || GeneralUtils.isNullOrEmpty(this.state.requestSltList) ? "Arıza Takip" : "Arıza Takip (" + this.state.requestSltList.length + ")"}
                                disabled={this.state.disabledTabs || this.state.disabledIncidentFollowTab}
                                headerClassName={(this.state.disabledTabs || this.state.disabledIncidentFollowTab) && 'displayNone'}>

                                <RequestSlt ref={elem => this.refRequestSlt = elem}
                                            setRequestSltList={(requestSltList) => this.setState({requestSltList: requestSltList})}/>
                            </TabPanel>

                            <TabPanel header="Tarihçe" rightIcon="pi pi-user"
                                      disabled={this.state.pageMode === PAGE_MODE.CREATE}
                                      headerClassName={this.state.pageMode === PAGE_MODE.CREATE && 'displayNone'}>
                                <AuditEvent ref={elem => this.refAuditEvent = elem} objectName={IncidentColumn.name}/>
                            </TabPanel>

                            <TabPanel header="Olay Kayıtları" rightIcon="pi pi-user"
                                      disabled={this.state.pageMode === PAGE_MODE.CREATE}
                                      headerClassName={this.state.pageMode === PAGE_MODE.CREATE && 'displayNone'}>
                                <Event ref={elem => this.refEvent = elem} objectName={IncidentColumn.name}/>
                            </TabPanel>

                        </TabView>

                        <StatusChangeDialog ref={elem => this.refStatusChangeDialog = elem}
                                            statusChange={this.statusChange} />

                        <AssignedDialog ref={elem => this.refAssignedDialog = elem} assigned={this.assigned} />

                        <ResolveDialog ref={elem => this.refResolveDialog = elem} resolve={this.resolve} />

                        <CloseDialog ref={elem => this.refCloseDialog = elem} close={this.close} />

                        <PendingDialog ref={elem => this.refPendingDialog = elem} pending={this.pending} />

                        {/*<Dialog*/}
                        {/*header={GeneralUtils.isNullOrEmpty(this.state.assetList) ? "Varlıklar" : "Varlıklar ("+ this.state.assetList.length + ")"}*/}
                        {/*modal={true} resizable={true} visible={this.state.displayAssetDialog}*/}
                        {/*maximizable={true} responsive={true} onHide={() => this.setState({displayAssetDialog: false})}*/}
                        {/*style={{width: '50vw'}}>*/}
                        {/*{this.getRequestAsset()}*/}
                        {/*</Dialog>*/}

                        <Dialog visible={this.state.displayContactDialog}
                                header='Kontak Oluşturma'
                                onHide={() => this.setState({displayContactDialog: false})}
                                style={{width: '70vw'}}>
                            <AvForm onValidSubmit={this.submitContact}>
                                <Toolbar
                                    display={true}>
                                    <div className="p-toolbar-group-right">
                                        <div>
                                            <Button color="primary" type="submit" className="float-right">
                                                &nbsp;
                                                <Translate contentKey="entity.action.save">Kaydet</Translate>
                                            </Button>
                                            <Button color="primary" className="float-right"
                                                    onClick={() => this.setState({displayContactDialog: false})}>
                                                &nbsp;
                                                <Translate contentKey="entity.action.save">Vazgeç</Translate>
                                            </Button>
                                        </div>
                                    </div>
                                </Toolbar>

                                <ContactSave save={() => this.setState({displayContactDialog: false})}
                                             ref={(elem) => this.refContactSave = elem}
                                             closeDisplayDialog={() => this.setState({displayContactDialog: false})}/>
                            </AvForm>
                        </Dialog>


                        <Dialog visible={this.state.displayIncidentHistoryDialog}
                                header='Arıza Geçmişi'
                                onHide={() => this.setState({displayIncidentHistoryDialog: false})}
                                style={{width: '70vw'}}>
                            <DataTable  ref={(elem) => this.refDataTable = elem} fields={IncidentHistoryColumn.fields} dataURL="incident/findByOrgIdAndLocIdAndExcludeIncidentIdOfOwn"
                                        objectName={IncidentHistoryColumn.name} loading={this.state.loading} export={true}  autoLoad={false}/>
                        </Dialog>
                    </div>

                    <Dialog visible={this.state.displayNewContactDialog}
                            header={GeneralUtils.getDialogHeader('Kontak', this.state.pageMode)}
                            onHide={() => this.setState({displayNewContactDialog: false})}
                            style={{width: '50vw'}}>
                        <Contact ref={elem => this.refContact = elem}
                                 closeDisplayDialog={() => this.setState({displayNewContactDialog: false})}
                                 setNewContact={this.setNewContact}  style={{width: '50vw'}}/>
                    </Dialog>

                    <ModalConfirm header='Uyarı' ok='Devam Et' onOkClick={this.save}
                                  onCancelClick={() => this.setState({displayConfirmDialog: false})}
                                  show={this.state.displayConfirmDialog}
                                  message={this.state.noticeResponse}/>

                    <ModalConfirm header='Uyarı' ok='Devam Et' onOkClick={this.resume}
                                  onCancelClick={() => this.setState({displayConfirmDialogResume: false})}
                                  show={this.state.displayConfirmDialogResume}
                                  message={this.state.noticeResponse}/>

                    <ModalConfirm header='Uyarı' ok='Devam Et' onOkClick={this.refreshScreen}
                                  show={this.state.displayConfirmDialogVersion}
                                  message={this.state.noticeResponse}/>
                    {this.state.loading && <Loading/>}
                </div>
            );
        } else
            return '';
    }

    setDisabledFlagIncidentFollow=(pageMode,serviceSubcategoryId)=>{
        this.refNewIncident.setDisabledFlagIncidentFollow(pageMode,serviceSubcategoryId);
    }

    incidentHistory = () =>{
        if (GeneralUtils.isNullOrEmpty(this.state.organizationId) || GeneralUtils.isNullOrEmpty(this.state.locationId)) {
            return HermesSweetAlert.warn("Arıza Geçmişini görüntülemek için Organizasyon ve Lokasyon Seçmelisiniz!");
        }
        this.refDataTable.setParameter(this.state.organizationId, this.state.locationId, this.state.incidentData.id);
        this.setState({displayIncidentHistoryDialog: true});
    }

    submitContact = () => {
        this.refContactSave.checkAndSave();
    }

    getSurveyResult = () => {
        return <Container>
            <Survey.Survey model={this.state.survey}/>
        </Container>
    }

    getSurveyResultDescription = () =>  {
        return <div>
            <AvForm>
                <Fieldset legend={<span>Anket Sonuçları</span>}>
                    <AvGroup>
                        <AvField type="textarea" className="form-control textAreaHermes" name="text"  style={{width: '75%'}}
                                 disabled={true}
                                 value={this.state.surveyResult.description}
                        />
                    </AvGroup>
                </Fieldset>
            </AvForm>
        </div>
    }

    clearAsset = () => {
        this.setState({assetList: []});
        this.refRequestAsset.clearData();
    }

    clearContact = () => {
        this.setState({contactList: null});
        this.refRequestContact.clearData();
    }

    getRequestAsset = () => {
        return <RequestAsset ref={elem => this.refRequestAsset = elem} requestType={REQUEST_TYPE.INCIDENT}
                             organizationId={this.state.organizationId} locationId={this.state.locationId}
                             setAssetList={(assetList) => {
                                 // let serviceNumber;
                                 // for (var i = 0; i < assetList.length; i++) {
                                 //     if (assetList[i].assetType == ASSET_TYPE.BROADBAND_PRODUCT) {
                                 //         serviceNumber = assetList[i].name;
                                 //     }
                                 // }
                                 // this.setState({assetList: assetList, serviceNumber: serviceNumber});
                                 this.setState({assetList: assetList});
                             }}
                             getAssetList={this.state.assetList}/>
    }

    getRequestContact = () => {
        return <RequestContact ref={elem => this.refRequestContact = elem} requestType={REQUEST_TYPE.INCIDENT}
                               organizationId={this.state.organizationId} locationId={this.state.locationId}
                               createNewContact={this.createNewContact}
                               setContactList={(contactList) => this.setState({contactList: contactList})}
                               getContactList={this.state.contactList}
        />
    }

    showSplitButtonMenu = () => {
        this.refSplitButton.onDropdownButtonClick();
    }

    checkAndSave = () => {
        this.setState({disabledSaveButton:true});

        let newIncident = this.refNewIncident.getData();

        let requestAssetList = this.refRequestAsset.getData();

        if (newIncident.emailNotification == 1) {
            if (GeneralUtils.isNullOrEmpty(newIncident.contactGivenNames)) {
                return HermesSweetAlert.warn("Zorunlu Alan : Ad Giriniz!");
                this.setState({disabledSaveButton:true});
            }
            if (GeneralUtils.isNullOrEmpty(newIncident.contactFamilyNames)) {
                return HermesSweetAlert.warn("Zorunlu Alan : Soyad Giriniz!");
                this.setState({disabledSaveButton:true});
            }
            if (GeneralUtils.isNullOrEmpty(newIncident.contactEmail)) {
                return HermesSweetAlert.warn("Zorunlu Alan : E-posta Seçiniz!");
                this.setState({disabledSaveButton:true});
            }
        }

        if (GeneralUtils.isNullOrEmpty(newIncident.description)) {
            return HermesSweetAlert.warn("Zorunlu Alan : Açıklama Giriniz!");
            this.setState({disabledSaveButton:true});
        }

        if (requestAssetList.length == 0) {
            return HermesSweetAlert.warn("Açılan arızaya ilişkin genişbant hizmeti/ürünü varlıklar sekmesi altında eklenmelidir!");
            this.setState({disabledSaveButton:true});
        }

        if (requestAssetList.length > 1) {
            return HermesSweetAlert.warn("Açılan arızaya ilişkin en fazla bir tane genişbant hizmeti/ürünü varlığı eklenmelidir!");
            this.setState({disabledSaveButton:true});
        }

        this.businessRuleService.doesHaveNoticeByType().then(response => {
            if (!GeneralUtils.__isValueEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                this.save();
            }else{
                this.setState({displayConfirmDialog: true, noticeResponse: response.message + " Yine de devam etmek istiyor musunuz?"});
            }
        });

    }


    save=()=> {
        let newIncident = this.refNewIncident.getData();

        let requestContactList = this.refRequestContact.getData();

        let requestAssetList = this.refRequestAsset.getData();
        let data = {
            incident: newIncident,
            requestContactList: requestContactList,
            requestAssetList: requestAssetList,
            documentIdList : this.state.documentIdList
        };

        this.setState({loading: true});
        if (GeneralUtils.isNullOrEmpty(data.incident.id)) {//save
            this.service.createIncident(data).then((response) => {
                if (!GeneralUtils.isNullOrEmpty(response.incident) && !GeneralUtils.isNullOrEmpty(response.incident.id)) {

                    let surveyResult = this.refNewIncident.getSurveyResult();
                    if(!GeneralUtils.isNullOrEmpty(surveyResult)){
                        surveyResult.requestId = response.incident.id;
                        this.hsmSurveyResultService.update(surveyResult);
                    }
                    this.setState({renderIncidentPage: false});
                    this.callProps();
                    this.setState({loading: false});
                    return HermesSweetAlert.success(response.incident.id + " arıza numarasıyla kaydınız oluşturulmuştur.", () =>{window.location = '#/incidentList';});

                } else {
                    this.setState({loading: false});
                    GeneralUtils.showNotification(response);
                }
            });

        } else {//update
            this.service.update(data).then((response) => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    if (!GeneralUtils.isNullOrEmpty(response.incident) && !GeneralUtils.isNullOrEmpty(response.incident.id)) {
                        this.callProps();
                    }
                    this.setState({loading: true});
                }else{
                    this.setState({
                        displayConfirmDialogVersion: true,
                        noticeResponse: response.message
                    });
                    this.setState({loading: false});
                }
            });
        }
    }

    callProps = () => {
        if (this.props.closeDialog) {
            this.props.closeDialog();
        }
        if (this.props.refresh) {
            this.props.refresh();
        }
    }

    setData = (data, pageMode) => {
        this.clearData();
        this.setState({incidentData:data});

        this.setState({
            teamId: data.teamId,
            disabledTabs: false,
            disabledSaveButton:false,
            pageMode: pageMode,
            status: data.status,
            organizationId: data.organizationId.id,
            locationId: data.locationId.id,
            serviceCategoryId: data.serviceCategoryId ? data.serviceCategoryId.id : null,
            serviceSubcategoryId: data.serviceSubcategoryId ? data.serviceSubcategoryId.id : null,
            flowId: data.flowId,
            activeIndex: 0,
            activeIndexCW: 0
        });

        let teamId = data.teamId != null ? data.teamId.id : null;
        let assignedTo = data.assignedTo != null ? data.assignedTo.id : null;
        this.getButtonList(data.serviceCategoryId.id, data.serviceSubcategoryId.id, data.flowId, teamId, assignedTo);

        this.workFlowModelService.findByCategoryIdAndSubcategoryId(data.serviceCategoryId.id, data.serviceSubcategoryId.id).then(response => {
            this.setState({flowList: response});
        });

        this.hsmSurveyService.findByCategoryIdSubCategoryId(data.serviceCategoryId.id, data.serviceSubcategoryId.id).then((response) => {
            if(!GeneralUtils.__isValueEmpty(response)){
                let survey = new Survey.Model(response.model);
                survey.mode = 'display';
                this.setState({survey: survey});
            }
            this.hsmSurveyResultService.findByRequestTypeAndRequestId('INCIDENT', data.id).then(response => {
                if (!GeneralUtils.isNullOrEmpty(response) && !GeneralUtils.isNullOrEmpty(response.surveyResult)  ) {
                    this.state.survey.data = JSON.parse(response.surveyResult);
                    this.setState({surveyResult:response});
                } else {
                    this.setState({disabledTabSurveyResult: true});
                }
            });
        });

        this.refNewIncident.setData(Object.assign({}, data), pageMode);

        this.refRequestContact.setData(data, REQUEST_TYPE.INCIDENT, pageMode, this.state.status);

        this.refRequestAsset.setData(data, REQUEST_TYPE.INCIDENT, pageMode, this.state.status);

        this.refAttachedDocument.setData(data, REQUEST_TYPE.INCIDENT, pageMode, this.state.status);

        this.refRequestComment.setData(data, REQUEST_TYPE.INCIDENT, pageMode, this.state.status);

        this.refRequestWorknote.setData(data, REQUEST_TYPE.INCIDENT, pageMode, this.state.status);

        this.refRequestSlt.setData(data.id, REQUEST_TYPE.INCIDENT);

        this.refAuditEvent.setData('Incident', data.id);
        this.refEvent.setData('Incident', data.id);

    }


    getButtonList=(serviceCategoryId, serviceSubcategoryId, flowId, teamId, assignedTo)=> {
        let userContactId = GeneralUtils.getContactId();

        var isSameContact = false;
        var isSameTeam = false;

        if (assignedTo == userContactId) {
            isSameContact = true;
        }

        this.teamContactService.findTeamsByContactId(userContactId).then(response => {

            let teamIdList = [];
            let contactTeamId;
            if (!GeneralUtils.isNullOrEmpty(response)) {
                for (var i = 0; i < response.length; i++) {
                    if (response[i].teamId.id == teamId) {
                        isSameTeam = true;
                    }
                    contactTeamId = response[i].teamId.id;
                    teamIdList.push(contactTeamId);
                }
            }

            this.setState({
                isSameContact: isSameContact,
                isSameTeam: isSameTeam,
                disabledEditButton: true,
                disabledResumeButton: true
            });

            if (!GeneralUtils.isNullOrEmpty(teamIdList)) {
                this.workFlowModelService.findByCategoryAndSubcategoryAndFlowIdAndTeamIdList(serviceCategoryId, serviceSubcategoryId, flowId, teamIdList).then(response => {
                    if (!GeneralUtils.isNullOrEmpty(response)) {
                        this.setState({isAllowedTeam: true})
                    }
                });
            }


            if (!GeneralUtils.isNullOrEmpty(teamIdList) && !GeneralUtils.isNullOrEmpty(teamId)) {
                this.workFlowModelService.getSplitButtonItems(serviceCategoryId, serviceSubcategoryId, flowId, teamIdList).then(response => {
                    this.addCommandToSplitButtons(response);
                });
            }

            if (GeneralUtils.isNullOrEmpty(teamId)) {
                this.workFlowModelService.getSplitButtonItemsTeamIdIsNull(serviceCategoryId, serviceSubcategoryId, flowId).then(response => {
                    this.addCommandToSplitButtons(response);
                });
            }
        });
    }

    clearData = () => {
        this.setState({
            // serviceNumber: null,
            assetList: [],
            contactList: [],
            disabledEditButton : true,
            disabledResumeButton : true ,
            isAllowedTeam: false,
            surveyResult: [],
            loading:false,
            disabledTabSurveyResult:false
        });
    }

    createNewContact = () => {
        this.refContact.addNew();
        this.refContact.setOrgAndLocAndGeneric(this.state.organizationId, this.state.locationId, PAGE_MODE.VIEW);
        this.setState({displayNewContactDialog: true});

    }

    statusChange = () => {
        let newIncident = this.refNewIncident.getData();
        let statusChangeIncident = this.refStatusChangeDialog.getData();


        let data = {
            requestId: newIncident.id,
            version: newIncident.version,
            status: this.state.action,
            description: statusChangeIncident.description,
            lastModifiedBy: GeneralUtils.getUserId()
        }
        this.trigger(data);
    }

    assigned = () => {
        let newIncident = this.refNewIncident.getData();
        let assignedIncident = this.refAssignedDialog.getData();

        let data = {
            requestId: newIncident.id,
            version: newIncident.version,
            status: 'ASSIGNED',
            description: null,
            lastModifiedBy: GeneralUtils.getUserId(),
            assignedToContactId: assignedIncident.assignedToContactId,
            teamId: assignedIncident.teamId
        }
        this.trigger(data);
    }

    resolve = () => {
        let newIncident = this.refNewIncident.getData();
        let resolveIncident = this.refResolveDialog.getData();

        let data = {
            requestId: newIncident.id,
            version: newIncident.version,
            status: this.state.action,
            description: resolveIncident.description,
            lastModifiedBy: GeneralUtils.getUserId(),
            resolutionCode: resolveIncident.resolutionCode
        }
        this.trigger(data);
    }

    pending = () => {
        let newIncident = this.refNewIncident.getData();
        let pendingIncident = this.refPendingDialog.getData();

        /*   let allDataAndFileArray = this.refAttachedDocument.getAllDataAndFileArray();

           if (!this.isExistsDataAndFile(allDataAndFileArray, "COMPELLING_FORM")) {
               return HermesSweetAlert.warn("Talebiniz ile ilgili olarak ihtiyaç duyulan mücbir askıya alma formunu imzalamanız ve imzaladığınız formu “Dokümanlar” sekmesi altında eklemeniz gerekmektedir.");
           }*/

        let data = {
            requestId: newIncident.id,
            version: newIncident.version,
            status: this.state.action,
            description: pendingIncident.description,
            lastModifiedBy: GeneralUtils.getUserId(),
            statusReason: pendingIncident.statusReason
        }
        this.trigger(data);
    }

    close = () => {
        let newIncident = this.refNewIncident.getData();
        let closeIncident = this.refCloseDialog.getData();

        let data = {
            requestId: newIncident.id,
            version: newIncident.version,
            status: this.state.action,
            description: closeIncident.description,
            lastModifiedBy: GeneralUtils.getUserId(),
            confirmationStatus: closeIncident.confirmationStatus
        }
        this.trigger(data);
    }

    modify = () => {
        this.setState({pageMode: PAGE_MODE.UPDATE});
        this.refNewIncident.setPageMode(PAGE_MODE.UPDATE);

        this.refRequestContact.setPageModeAndStatus(PAGE_MODE.UPDATE, this.state.status);

        this.refRequestAsset.setPageModeAndStatus(PAGE_MODE.UPDATE, this.state.status);

        this.refAttachedDocument.setPageModeAndStatus(PAGE_MODE.UPDATE, this.state.status);

        this.refRequestComment.setPageModeAndStatus(PAGE_MODE.UPDATE, this.state.status);

        this.refRequestWorknote.setPageModeAndStatus(PAGE_MODE.UPDATE, this.state.status);

    }

    resumeCheck= () => {
        this.setState({displayConfirmDialogResume: true, noticeResponse: " Talebi askıdan indirmek üzeresiniz. Devam etmek istiyor musunuz? "});
    }

    resume = () => {
        let newIncident = this.refNewIncident.getData();
        let data = {
            requestId: newIncident.id,
            version: newIncident.version,
            status: 'RESUME',
            description: null,
            lastModifiedBy: GeneralUtils.getUserId()
        }
        this.trigger(data);
    }

    trigger = (data) => {
        this.setState({loading: true});

        this.service.trigger(data).then((response) => {
            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                this.setState({isSameContact: true});
                this.props.refresh();
                this.props.closeDialog();
            } else {
                this.setState({
                    displayConfirmDialogVersion: true,
                    noticeResponse: response.message
                });
            }
            this.setState({loading: false});
        });
    }

    showAssigneeDialog = () => {
        this.refAssignedDialog.setDialogAndTeamId(true, this.state.teamId, this.state.serviceCategoryId, this.state.serviceSubcategoryId, this.state.flowId, false);
    }

    assigneeToMe = () => {
        let newIncident = this.refNewIncident.getData();
        let data = {
            requestId: newIncident.id,
            version: newIncident.version,
            status: 'ASSIGNEDTOME',
            description: null,
            lastModifiedBy: GeneralUtils.getUserId()
        }
        this.setState({loading: true});

        this.service.trigger(data).then((response) => {
            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                this.setState({isSameContact: true});
                this.refNewIncident.setVersion(response.version);
                this.refNewIncident.assigneeToMe(response);
                if (this.props.refresh) {
                    this.props.refresh();
                }
            }else{
                this.setState({
                    displayConfirmDialogVersion: true,
                    noticeResponse: response.message
                });
            }
            this.setState({loading: false});
        });
    }

    addCommandToSplitButtons(response) {
        let me = this;
        let items= Object.assign([]);

        response.map((el) => {
            var o = Object.assign({}, el);
            if (GeneralUtils.checkPrivilege('Incident', o.value)) {
                o.command = (e) => {
                    me.buttonActions(e);
                }
            }
            o.disabled = !GeneralUtils.checkPrivilege('Incident', o.value);

            if(o.visible != false){
                items.push(o);
            }
            // Edit için yapıldı
            if(o.value == 'Edit'){
                this.setState({disabledEditButton: false});
            }

            // Askıdan indir işlemi için yapıldı
            if(o.value == 'Resume'){
                this.setState({disabledResumeButton: false});
            }

            return o;
        })
        this.setState({items: items})
    }

    buttonActions = (e) => {
        let label = e.item.label;
        switch (label) {
            case "Talebi Onayla":
                this.refStatusChangeDialog.setDialog(true);
                this.setState({action: 'APPROVED'});
                break;
            case "Talebi İptal Et":
                this.refStatusChangeDialog.setDialog(true);
                this.setState({action: 'CANCELLED'});
                break;
            case "Eskale Et":
                this.refStatusChangeDialog.setDialog(true);
                this.setState({action: 'ESCALATED'});
                break;
            case "Çözümle":
                this.refResolveDialog.setDialog(true);
                this.setState({action: 'RESOLVED'});
                break;
            case "Kapat":
                this.refCloseDialog.setDialog(true);
                this.setState({action: 'CLOSED'});
                this.beforeIncidentClose();
                break;
            case "Tekrar Aç":
                this.tenantService.getTenant().then(result => {
                    if (!GeneralUtils.isNullOrEmpty(result) && result == TENANT_ID.GSB) {
                        if (GeneralUtils.checkPrivilege('Incident', 'ReopenLimited') && this.state.incidentData.reopenCount >= this.state.maxReopenCount) {
                            return HermesSweetAlert.warn("Bu arızayı artık yeniden açamazsınız. Lütfen GSB BT ile iletişime geçiniz.");
                        }
                    }
                    this.refStatusChangeDialog.setDialog(true);
                    this.setState({action: 'REOPENED'});
                });
                break;
            case "Askıya Al":
                this.refPendingDialog.setDialog(true);
                this.setState({action: 'PENDING'});
                break;
            default:

        }
    }

    buttonRender(button) {
        return GeneralUtils.incidentButtonRender(this.state.pageMode, button, this.state.status, this.state.isSameContact, this.state.isSameTeam, 'Incident',this.state.isAllowedTeam);
    }

    isExistsDataAndFile = (allDataAndFileArray, documentType) => {
        let isExists = false;
        if (allDataAndFileArray.length === 0) {
            let allData = this.refAttachedDocument.getData();
            for (let i = 0; i < allData.length; i++) {
                if (allData[i].documentType === documentType) {
                    isExists = true;
                }
            }
        } else {
            for (let i = 0; i < allDataAndFileArray.length; i++) {
                if (allDataAndFileArray[i].data.documentType === documentType) {
                    isExists = true;
                }
            }
        }
        return isExists;
    }

    beforeIncidentClose(){
        this.refCloseDialog.setRequestCommentAndResolutionCode(REQUEST_TYPE.INCIDENT, this.state.incidentData.id, this.state.incidentData.resolutionCode);
    }

    setNewContact = (data) => {
        this.refRequestContact.onContactDataSelect(data)
    }

    refreshScreen = () => {
        this.setState({displayConfirmDialogVersion: false})
        if (this.props.closeDialog) {
            this.props.closeDialog();
        }
        if (this.props.refresh) {
            this.props.refresh();
        }
    }

    deleteDocumentId(documentId) {
        let documentIdList = this.state.documentIdList;
        return documentIdList.filter(function (el) {
            return el != documentId;
        });
    }

}
Incident.propTypes = {
    closeDialog: PropTypes.func,
    refresh: PropTypes.func,
};
