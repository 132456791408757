import React from "react";
import PropTypes from 'prop-types';
import SelectDataTable from '../form/SelectDataTable';
import BbProductColumn from "../dataTable/columns/BbProductColumn";
import BbProductService from "../../service/BbProductService";
import GeneralUtils from "../../utils/GeneralUtils";
import {PAGE_MODE} from "../../utils/Constants";
import Dialog from "../dialog/Dialog";
import BbProduct from "../../../module/asset-management/bb-product/BbProduct";

export default class BbProductGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            value: this.props.value,
            validate: this.props.validate,
            dataTable: this.props.dataTable,
            label: this.props.label,
            disabled: this.props.disabled,
            controlButton: this.props.controlButton,
            buttonStyle: this.props.buttonStyle
        };
        this.service = new BbProductService();
    }

    componentDidMount = () => {
        if (this.state.data && GeneralUtils.checkPrivilege(BbProductColumn.name, 'View')) {
            this.refBbProduct.setPageMode(PAGE_MODE.VIEW);
        }
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({
            data: this.props.data,
            validate: nextProps.validate,
            value: nextProps.value,
            dataTable: nextProps.dataTable,
            label: nextProps.label,
            disabled: nextProps.disabled,
            controlButton: nextProps.controlButton,
            buttonStyle: nextProps.buttonStyle
        });
    }

    render() {
        let validate = this.state.validate;
        if (!validate) {
            validate = {
                required: {
                    value: true,
                    errorMessage: "Bu alanın doldurulması zorunludur."
                }
            };
        }

        let dataTable = this.state.dataTable;
        if (!dataTable) {
            dataTable = {
                dataURL : "bandwidth/findAll",
                fields: BbProductColumn.fields
            };
        }

        let label = this.state.label;
        if (!label) {
            label = "Hizmet Numarası"
        }
        let openDetailDialog = (this.state.data && GeneralUtils.checkPrivilege(BbProductColumn.name, 'View')) ? this.openDetailDialog : false;
        return (
            <div>
                <SelectDataTable
                    value={this.state.value}
                    validate={validate}
                    dataTable={dataTable}
                    disabled={this.state.disabled}
                    name="requestedBy"
                    translateKey="defaultHsmUserGroup"
                    label={label}
                    dialogHeader="Genişbant Ürün Listesi"
                    onDataSelect={this.props.onDataSelect}
                    autoRefrestData={false}
                    openDetailDialog={openDetailDialog}
                    controlButton={this.state.controlButton}
                    getDeviceStatus={this.props.getDeviceStatus}
                    buttonStyle={this.props.buttonStyle}
                />
                {(this.state.data && GeneralUtils.checkPrivilege(BbProductColumn.name, 'View')) && this.getDetailDialog()}
            </div>
        );
    }

    openDetailDialog = () => {
        this.refBbProduct.setData(Object.assign({}, this.state.data), PAGE_MODE.VIEW);
        this.setState({displayDialog: true});
    }

    getDetailDialog = () => {
        let result = (
            <Dialog visible={this.state.displayDialog} header="Genişbant Ürün Görüntüleme" modal={true}
                    resizable={true} maximizable={true} responsive={true}
                    onHide={() => this.setState({displayDialog: false})} style={{width: '50vw'}}>
                <BbProduct ref={(elem) => this.refBbProduct = elem}
                           closeDisplayDialog={() => this.setState({displayDialog: false})}/>
            </Dialog>
        );
        return result;
    }
}
BbProductGroup.propTypes = {
    data: PropTypes.object,
    value: PropTypes.string,
    validate: PropTypes.object,
    dataTable: PropTypes.object,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    name: PropTypes.string,
    controlButton: PropTypes.bool,
    buttonStyle:PropTypes.object
}
