import React from "react";
import {Toolbar as ToolbarPR} from 'primereact/toolbar';

export default class Toolbar extends React.Component {

    render() {
        let style = Object.assign({}, this.props.style, {marginBottom: '10px'});
        let toolbar = <ToolbarPR {...this.props} style={style}>
            {this.props.children}
        </ToolbarPR>;
        return (
            <div>
                {this.props.display && toolbar}
            </div>
        );
    }
}