import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class TenantService{
    constructor() {
        this.baseURL = 'tenant';
    }

    getTenant() {
        return fetch(SERVER_URL + this.baseURL+ '/getTenant', {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    getTenantWithoutLogin() {
        return fetch(SERVER_URL + this.baseURL+ '/getTenant', {
            headers: {'Content-Type': 'application/json'}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }


}