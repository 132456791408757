import React from 'react';
import {AvGroup} from "availity-reactstrap-validation";
import {Col, Label, Row} from "reactstrap";
import AvField from "../../shared/component/form/AvField";
import SlaService from "../../shared/service/SlaService"
import WorkScheduleGroup from "../../shared/component/hsmGroup/WorkScheduleGroup"
import {SLT_TYPE,SLT_UNITS,PENALTY_ROUNDING} from "../../shared/utils/Constants"

const defaultSlt = {
    id: '',
    slaId: 1,
    workScheduleId: null,
    type: '',
    name: '',
    penaltyRate: '',
    penaltyFactor: '',
    penaltyUnits: '',
    penaltyRounding: '',
    value: '',
    units: ''
};
export default class SltSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            slt: defaultSlt,
            slaList:[],
            workSchedule: null,
            pageMode: null
        };
        this.slaService=new SlaService();
    }
    componentDidMount = () => {
        this.clearData();
        this.slaService.findAll().then(response => this.setState({slaList: response}));
    }

    render() {
        return (
            <Row className="justify-content-center">
                <Col>
                    <AvGroup>
                        <Label for="slaId">SLA</Label>
                        <AvField
                            type="select"
                            className="form-control"
                            name="slaId"
                            value={this.state.slt.slaId}
                            onChange={this.__handleChange}>
                        {this.state.slaList.map(element => (
                            <option value={element.id} key={element.id}>
                                {element.name}
                            </option>
                        ))}
                        </AvField>
                    </AvGroup>
                    <AvGroup>
                        <Label for="name">SLT Adı<span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="name"
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                },
                                minLength: {
                                    value: 1,
                                    errorMessage: 'Bu alan en az 1 karakterden oluşabilir.'
                                },
                                maxLength: {
                                    value: 255,
                                    errorMessage: 'Bu alan en fazla 255 karakterden oluşabilir.'
                                }
                            }}
                            value={this.state.slt.name}
                            onChange={this.__handleChange}
                        />
                    </AvGroup>
                    <AvGroup>
                        <Label for="type">SLT Tipi<span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="select"
                            className="form-control"
                            name="type"
                            value={this.state.slt.type}
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                },
                                minLength: {
                                    value: 1,
                                    errorMessage: 'Bu alan en az 1 karakterden oluşabilir.'
                                },
                                maxLength: {
                                    value: 255,
                                    errorMessage: 'Bu alan en fazla 255 karakterden oluşabilir.'
                                }
                            }}
                            onChange={this.__handleChange}>
                        <option value='' key=''/>
                        {SLT_TYPE.map(element => (
                            <option value={element.value} key={element.value}>
                                {element.name}
                            </option>
                        ))}
                        </AvField>
                    </AvGroup>
                    <Row>
                        <Col>
                            <AvGroup>
                                <Label for="value">Değer</Label>
                                <AvField
                                    type="number"
                                    className="form-control"
                                    name="value"
                                    value={this.state.slt.value}
                                    max="999999"
                                    onChange={this.__handleChange}
                                />
                            </AvGroup>
                        </Col>
                        <Col>
                            <AvGroup>
                                <Label for="units">Birim<span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvField
                                    type="select"
                                    className="form-control"
                                    name="units"
                                    value={this.state.slt.units}
                                    validate={{
                                        required: {
                                            value: true,
                                            errorMessage: 'Bu alan zorunlu.'
                                        },
                                        minLength: {
                                            value: 1,
                                            errorMessage: 'Bu alan en az 1 karakterden oluşabilir.'
                                        },
                                        maxLength: {
                                            value: 255,
                                            errorMessage: 'Bu alan en fazla 255 karakterden oluşabilir.'
                                        }
                                    }}
                                    onChange={this.__handleChange}>
                                <option value='' key=''/>
                                {SLT_UNITS.map(element => (
                                    <option value={element.value} key={element.value}>
                                        {element.name}
                                    </option>
                                ))}
                                </AvField>
                            </AvGroup>
                        </Col>
                    </Row>
                    <WorkScheduleGroup
                            value={this.state.workSchedule}
                            onDataSelect={this.onWorkScheduleSelect}
                            name="workSchedule"
                        />
                    <AvGroup>
                        <Label for="penaltyRate">Ceza Faizi</Label>
                        <AvField
                            type="number"
                            className="form-control"
                            name="penaltyRate"
                            max="999999"
                            value={this.state.slt.penaltyRate}
                            onChange={this.__handleChange}
                        />
                    </AvGroup>
                    <AvGroup>
                        <Label for="penaltyFactor">Ceza Katsayısı</Label>
                        <AvField
                            type="number"
                            className="form-control"
                            name="penaltyFactor"
                            max="999999"
                            value={this.state.slt.penaltyFactor}
                            onChange={this.__handleChange}
                        />
                    </AvGroup>
                    <AvGroup>
                        <Label for="penaltyUnits">Ceza Birimi</Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="penaltyUnits"
                            validate={{
                                maxLength: {
                                    value: 255,
                                    errorMessage: 'Bu alan en fazla 255 karakterden oluşabilir.'
                                }
                            }}
                            value={this.state.slt.penaltyUnits}
                            onChange={this.__handleChange}
                        />
                    </AvGroup>
                    <AvGroup>
                        <Label for="penaltyRounding">Ceza Yuvarlama<span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="select"
                            className="form-control"
                            name="penaltyRounding"
                            value={this.state.slt.penaltyRounding}
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                },
                                minLength: {
                                    value: 1,
                                    errorMessage: 'Bu alan en az 1 karakterden oluşabilir.'
                                },
                                maxLength: {
                                    value: 5,
                                    errorMessage: 'Bu alan en fazla 5 karakterden oluşabilir.'
                                }
                            }}
                            onChange={this.__handleChange}>
                        <option value='' key=''/>
                        {PENALTY_ROUNDING.map(element => (
                            <option value={element.value} key={element.value}>
                                {element.name}
                            </option>
                        ))}
                        </AvField>
                    </AvGroup>          
                </Col>
            </Row>
        );
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let slt = this.state.slt;
        slt[e.target.name] = value;
        this.setState({slt: slt});
    }

    setData = (data, pageMode) => {
        data.slaId = data.slaId ? data.slaId.id : null;
        let workSchedule = data.workScheduleId ? data.workScheduleId.name : null;
        this.setState({slt: data, workSchedule:workSchedule, pageMode: pageMode});
    }

    getData = () => {
        return this.state.slt;
    }

    clearData = () => {
        this.setState({slt: Object.assign({}, defaultSlt), workSchedule:null, pageMode: null});
    }
    
    onWorkScheduleSelect = (data) => {
        let slt = this.state.slt;
        slt.workScheduleId = data;
        this.setState({slt: slt, workSchedule: data.name, displayDialog: false});
    }

}
