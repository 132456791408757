import React from 'react';
import {Col, Container, Row} from 'reactstrap';
import TtvpnBarChartReport from "./TtvpnBarChartReport";
import GeneralUtils from "../../../shared/utils/GeneralUtils";

export default class TtvpnReport extends React.Component {

    render() {
        return (
            <Container>
                {GeneralUtils.checkPrivilege('TtvpnReport', 'View') &&
                <Row>
                    <Col>
                        <TtvpnBarChartReport/>
                    </Col>
                </Row>
                }
            </Container>
        );
    }
}
