import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class HsmOrganizationService extends Service{
    constructor() {
        super();
        this.baseURL = 'hsmOrganization';
    }

    findSubOrganizations(id) {
        return fetch(SERVER_URL + this.baseURL + '/findSubOrganizations/' + id, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    findFilteredOrgs() {
        return fetch(SERVER_URL + this.baseURL + "/findFilteredOrgs", {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    findByCode(code) {
        return fetch(SERVER_URL + this.baseURL + '/findByCode/' + code, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    findFilteredOrgsByContactId() {
        return fetch(SERVER_URL + this.baseURL + '/findFilteredOrgsByContactId/'+GeneralUtils.getContactId(), {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
    });
    }

    findAllOrganizations() {
        let gridModel = {"filter":[], "sort":[], "pageNumber": 1, "limit":100};
        return fetch(SERVER_URL + this.baseURL + '/findAllOrganizations', {
            crossDomain: true,
            method: 'put',
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")},
            body: JSON.stringify(gridModel)
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
    });
    }

}