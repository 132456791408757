import React from 'react';
import {Button, Col, Label, Row} from "reactstrap";
import {AvField, AvForm, AvGroup} from "availity-reactstrap-validation";
import {Fieldset} from "primereact/fieldset";
import HsmUserService from "../../../shared/service/HsmUserService";
import {Panel} from "primereact/panel";
import PasswordChange from "./PasswordChange";
import {APP_DATE_FORMAT, CONTACT_TYPE, convertDateTimeWithFormat, STATUS} from "../../../shared/utils/Constants";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import Dialog from "../../../shared/component/dialog/Dialog";
import {PhoneField} from "../../../shared/component/form/PhoneField";
import TeamContactService from "../../../shared/service/TeamContactService";
import LogLoginService from "../../../shared/service/LogLoginService";
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import Translate from "../../../shared/language/Translate";
import {InputSwitch} from "primereact/inputswitch";
import ContactService from "../../../shared/service/ContactService";
import Loading from "../../../shared/component/others/Loading";


const defaultUser = {
    id: '',
    userName: '',
    status: 'P',
    language: 'tr',
    hsmRoleList: [],
    createdBy: '',
    contactId: {organizationId: {}, locationId: {}},
    createdDate: null,
    lastModifiedBy: '',
    lastModifiedDate: null,
    locked: 'N'
};

export default class HsmUserSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: defaultUser,
            emailNotification: 1
        }
        this.service = new HsmUserService();
        this.contactService = new ContactService();
        this.teamContactService = new TeamContactService();
        this.logLoginService = new LogLoginService();
    }

    componentDidMount = () => {
        this.service.findById(GeneralUtils.getUserId()).then(response => this.setState({user: response, emailNotification: response.contactId.emailNotification}));
        this.logLoginService.lastLoginByUserIdAndEventType(GeneralUtils.getUserId(), 'AUTHENTICATION_SUCCESS').then(response => this.setState({lastSuccesLoginDate: response}));
        this.logLoginService.lastLoginByUserIdAndEventType(GeneralUtils.getUserId(), 'AUTHENTICATION_FAILURE').then(response => this.setState({lastFailureLoginDate: response}));
        this.setTeamList();
        this.setRoleList();
    }

    render() {
        return (
            <Panel header="Hesabım">
                <Toolbar display={true}>
                    <div className="p-toolbar-group-left">
                        <div className="p-grid">
                            <div className="p-col" style={{width: '200px', padding: 0, paddingTop: '5px'}}>
                                   Ekip Epostaları
                            </div>
                            <div className="p-col"style={{padding: 0}}>
                                <InputSwitch checked={this.state.emailNotification != 0} onChange={(e) => this.setEmailNotification(e)}/>
                            </div>
                        </div>
                    </div>
                    <div className="p-toolbar-group-right">
                        <Button color="primary" onClick={() => {
                            this.setState({displayDialog: true})
                        }}>
                            Şifre Değiştir
                        </Button>
                    </div>
                </Toolbar>
                <Row className="justify-content-center">
                    <Col md="12">
                        <AvForm>
                            &nbsp;
                            &nbsp;
                            <Fieldset legend="Kullanıcı Bilgileri">
                                <Row>
                                    <Col>
                                        {this.getField('userName', 'Kullanıcı', this.state.user.userName)}
                                        <AvGroup>
                                            <Label for="status">
                                                Durum
                                            </Label>
                                            <AvField type="select" className="form-control" name="status"
                                                     disabled
                                                     value={this.state.user.status}
                                                     onChange={this.__handleChange}>
                                                {STATUS.map(element => (
                                                    <option value={element.value} key={element.value}>
                                                        {element.name}
                                                    </option>
                                                ))}
                                            </AvField>
                                        </AvGroup>
                                    </Col>
                                    <Col>
                                        <AvGroup>
                                            <Label for="activationDate">
                                                Aktivasyon Tarihi
                                            </Label>
                                            <AvField type="text" className="form-control" name="activationDate"
                                                     disabled
                                                     value={convertDateTimeWithFormat(this.state.user.activationDate, APP_DATE_FORMAT)}/>{' '}
                                        </AvGroup>
                                        <AvGroup>
                                            <Label for="passwordChangeDate">
                                                Şifre Değiştirme Tarihi
                                            </Label>
                                            <AvField type="text" className="form-control"
                                                     name="passwordChangeDate"
                                                     disabled
                                                     value={convertDateTimeWithFormat(this.state.user.passwordChangeDate, APP_DATE_FORMAT)}/>{' '}
                                        </AvGroup>
                                    </Col>
                                    <Col>
                                        <AvGroup>
                                            <Label for="lastSuccesLoginDate">
                                                Son Başarılı Giriş Tarihi
                                            </Label>
                                            <AvField type="text" className="form-control" name="lastSuccesLoginDate"
                                                     disabled
                                                     value={convertDateTimeWithFormat(this.state.lastSuccesLoginDate, APP_DATE_FORMAT)}/>{' '}
                                        </AvGroup>
                                        <AvGroup>
                                            <Label for="lastFailureLoginDate">
                                                Son Başarısız Giriş Tarihi
                                            </Label>
                                            <AvField type="text" className="form-control"
                                                     name="lastFailureLoginDate"
                                                     disabled
                                                     value={convertDateTimeWithFormat(this.state.lastFailureLoginDate, APP_DATE_FORMAT)}/>{' '}
                                        </AvGroup>
                                    </Col>
                                    <Col>
                                        <AvGroup>
                                            <Label for="roleList">
                                                Roller
                                            </Label>
                                            <AvField type="textarea" className="form-control textAreaHermes"
                                                     name="roleList" disabled
                                                     value={this.state.roleList}/>
                                        </AvGroup>
                                    </Col>
                                    <Col>
                                        <AvGroup>
                                            <Label for="teamList">
                                                Ekipler
                                            </Label>
                                            <AvField type="textarea" className="form-control textAreaHermes"
                                                     name="teamList" disabled
                                                     value={this.state.teamList}/>
                                        </AvGroup>
                                    </Col>
                                </Row>
                            </Fieldset>
                            <Fieldset legend="Kontak Bilgileri">
                                <Row>
                                    <Col md="4">
                                        {this.getField('contact', 'Ad Soyad', this.state.user.contactId.givenNames + ' ' + (GeneralUtils.isNullOrEmpty(this.state.user.contactId.familyName) ? "" : this.state.user.contactId.familyName))}
                                        {this.getField('tc', 'T.C. Kimlik No', this.state.user.contactId.turkishIdentityNo)}
                                        <AvGroup>
                                            <Label for="contactType">
                                                <Translate contentKey="contactManagement.contactType">Kontak
                                                    Tipi</Translate>
                                                <span style={{color: 'red'}}> (*)</span>
                                            </Label>
                                            <AvField type="select" className="form-control" name="contactType"
                                                     value={this.state.user.contactId.contactType} disabled>
                                                <option value='' key=''/>
                                                {CONTACT_TYPE.map(element => (
                                                    <option value={element.value} key={element.value}>
                                                        {element.name}
                                                    </option>
                                                ))}
                                            </AvField>
                                        </AvGroup>
                                        {this.getField('taxOffice', 'Vergi Dairesi', this.state.user.contactId.taxOffice)}
                                    </Col>
                                    <Col md="4">
                                        <Label for="mobilePhone">
                                            Cep Telefonu
                                        </Label>
                                        <PhoneField disabled name="mobilePhone"
                                                    kind={'mobile'}
                                        />
                                        <Label for="businessPhone">
                                            İş Telefonu
                                        </Label>
                                        <PhoneField disabled name="businessPhone"
                                                    kind={'business'}
                                        />
                                        {this.getField('faxNumber', 'Fax', this.state.user.contactId.faxNumber)}
                                        {this.getField('email', 'E-Posta', this.state.user.contactId.email)}
                                    </Col>
                                    <Col md="4">

                                        {this.getField('workTitle', 'Ünvan', this.state.user.contactId.workTitle)}
                                        {this.getField('employeeNo', 'Sicil No', this.state.user.contactId.employeeNo)}
                                        {this.getField('organization', 'Organization', this.state.user.contactId.organizationId.name)}
                                        {this.getField('location', 'Lokasyon', GeneralUtils.isNullOrEmpty(this.state.user.contactId.locationId) ? '' : this.state.user.contactId.locationId.name)}

                                    </Col>
                                </Row>
                            </Fieldset>
                        </AvForm>
                    </Col>
                </Row>
                <Dialog visible={this.state.displayDialog} header="Şifre Değiştirme Sayfası"
                        onHide={() => this.setState({displayDialog: false})} style={{width: '60%'}}>
                    <PasswordChange closeDialog={() => this.setState({displayDialog: false})}/>
                </Dialog>
                {this.state.loading && <Loading/>}
            </Panel>
        );
    }

    setEmailNotification=(e)=> {
        this.setState({loading: true});
        let data = {
            "contactId" : GeneralUtils.getContactId(),
            "emailNotification": (e.value ? '1' : '0')
        };
        this.contactService.setEmailNotification(data).then(response => {
            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                this.setState({emailNotification: e.value, loading: false});
            }
        });
    }

    getField(key, title, value) {
        return <AvGroup>
            <Label for={key}>
                {title}
            </Label>
            <AvField type="text" className="form-control" name={key} disabled
                     value={value}/>{' '}
        </AvGroup>;
    }

    setTeamList = () => {
        this.teamContactService.findTeamsByContactId(GeneralUtils.getContactId()).then(response => {
            if (!GeneralUtils.isNullOrEmpty(response)) {
                let teamList;
                response.forEach((element) => {
                    teamList = element.teamId.name + "; "
                });
                this.setState({teamList: teamList});
            }
        });
    }


    setRoleList = () => {
        let roleList;
        GeneralUtils.getUserRoleList().forEach((element) => {
            roleList = element.name + "; "
        });
        this.setState({roleList: roleList});
    }

}


