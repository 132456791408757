import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class BbProductService extends Service {
    constructor() {
        super();
        this.baseURL = 'bbProduct';
    }

    findAllByOrganizationId(organizationId) {
        return fetch(SERVER_URL + this.baseURL + '/findAllByOrganizationId/' + organizationId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        })
            .then(response => response.json())
            .catch(error => {
                GeneralUtils.notification(error);
            });
    }

    findAllByOrganizationIdAndLocationId(organizationId, locationId) {
        return fetch(SERVER_URL + this.baseURL + '/findAllByOrganizationIdAndLocationId/' + organizationId + '/' + locationId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        })
            .then(response => response.json())
            .catch(error => {
                GeneralUtils.notification(error);
            });
    }

    findByOrganizationIdAndLocationIdAndStatus(organizationId, locationId, status) {
        return fetch(SERVER_URL + this.baseURL + '/findByOrganizationIdAndLocationIdAndStatus/' + organizationId + '/' + locationId+ '/' +status, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        })
            .then(response => response.json())
            .catch(error => {
                GeneralUtils.notification(error);
            });
    }

    findByContactIdAndStatus(status) {
        return fetch(SERVER_URL + this.baseURL + '/findByContactIdAndStatus/' + GeneralUtils.getContactId()+ '/' +status, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        })
            .then(response => response.json())
            .catch(error => {
                GeneralUtils.notification(error);
            });
    }

    doesLocationAndTTVpnExist(locationCode, ttVpnCode) {
        return fetch(SERVER_URL + this.baseURL + '/doesLocationAndTTVpnExist/' + locationCode + '/' + ttVpnCode, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
    });
    }

    findByRequestTypeAndRequestId(requestType, requestId) {
        return fetch(SERVER_URL + this.baseURL + '/findByRequestTypeAndRequestId/' +requestType+ '/'+requestId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        })
            .then(response => response.json())
            .catch(error => {
                GeneralUtils.notification(error);
            });
    }
    hasBbProductOnOrganization(organizationId) {
        return fetch(SERVER_URL + this.baseURL + '/hasBbProductOnOrganization/' + organizationId , {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        })
            .then(response => response.json())
            .catch(error => {
                GeneralUtils.notification(error);
            });
    }

    hasBbProductOnLocation(locationId) {
        return fetch(SERVER_URL + this.baseURL + '/hasBbProductOnLocation/' + locationId , {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        })
            .then(response => response.json())
            .catch(error => {
                GeneralUtils.notification(error);
            });
    }

    findAllFilteredForDashboard(name) {
        return fetch(SERVER_URL + this.baseURL + "/findAllFilteredForDashboard/" + name , {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    findByServiceNumber(serviceNumber) {
        return fetch(SERVER_URL + this.baseURL + "/findByServiceNumber/" + serviceNumber, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

}