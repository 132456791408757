import React from 'react';
import {PAGE_MODE} from "../../../shared/utils/Constants";
import HsmDocumentService from "../../../shared/service/HsmDocumentService";
import BbProductDocumentColumn from '../../../shared/component/dataTable/columns/BbProductDocumentColumn';
import HsmAssetDocumentColumn from '../../../shared/component/dataTable/columns/HsmAssetDocumentColumn';
import Dialog from "../../../shared/component/dialog/Dialog";
import AttachmentSave from "../../request/attacheddocument/AttachmentSave";
import {Panel} from "primereact/panel";
import DataTable from "../../../shared/component/dataTable/DataTable";
import Loading from "../../../shared/component/others/Loading";
import GeneralUtils from "../../../shared/utils/GeneralUtils";

export default class BbProductDocumentList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: BbProductDocumentColumn.fields,
        };
        this.service = new HsmDocumentService();

    }

    componentDidMount = () => {
        this.service.checkPage();
        // this.init();
    }

    // init = () => {
    //     let parameter = window.location.href.split('?')[1];
    //     if (parameter) {
    //         let value = parameter.split('=')[1];
    //         if (!GeneralUtils.isNullOrEmpty(value)) {
    //             this.refDataTable.setParameter(value);
    //             if(value === 'BB_PRODUCT'){
    //                 this.setState({fields: BbProductDocumentColumn.fields})
    //             }else if(value === 'HSM_ASSET'){
    //                 this.setState({fields: HsmAssetDocumentColumn.fields})
    //             }
    //         }
    //     }
    // }

    render() {
        return (
            <div>

                <Panel header="Genişbant Dokümanları Listesi">
                    <DataTable ref={(elem) => this.refDataTable = elem} fields={this.state.fields}
                               dataURL="hsmDocument/findAllBbProductDocument"
                               privilege="allow"
                               selectionMode="single" onDataSelect={this.onDataSelect} refresh={true}  export={true}/>
                </Panel>

                <Dialog visible={this.state.displayDialog}
                        header='Doküman Görüntüleme'
                        onHide={() => this.setState({displayDialog: false})} style={{width: '50vw'}}>
                    <AttachmentSave ref={(elem) => this.refAttachmentSave = elem}
                                    closeDisplayDialog={this.closeDisplayDialog}/>

                </Dialog>
                {this.state.loading && <Loading/>}
            </div>
        );
    }


    onDataSelect = (data) => {
        this.setState({loading: true});
        let documentId = data.documentId;

        this.service.findDocumentPartsByDocumentId(documentId).then(res => {
            let documentParts = res;
            this.setState({displayDialog: true, loading: false});
            this.refAttachmentSave.setData(Object.assign({}, data), PAGE_MODE.VIEW, documentParts);
        });
    }

    closeDisplayDialog = () => {
        this.refDataTable.refresh();
        this.setState({displayDialog: false});
    }
}
