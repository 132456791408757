import React from 'react';
import {Button, Col, Label, Row} from 'reactstrap';
import {AvForm, AvGroup, AvRadio, AvRadioGroup} from 'availity-reactstrap-validation';
import AvField from '../../../shared/component/form/AvField';
import PropTypes from 'prop-types';
import Translate from "../../../shared/language/Translate";
import GeneralUtils from '../../../shared/utils/GeneralUtils';
import HsmDocumentService from "../../../shared/service/HsmDocumentService";
import {DOCUMENT_TYPE, OBJECT_STATUS, PAGE_MODE} from "../../../shared/utils/Constants";
import {Fieldset} from "primereact/fieldset";
import HsmDocumentPartColumn from '../../../shared/component/dataTable/columns/HsmDocumentPartColumn';
import DataTable from "../../../shared/component/dataTable/DataTable";


const defaultHsmDocument = {
    id: '',
    objectType: 'INCIDENT',
    objectId: '',
    documentId: '',
    partId: '',
    documentTitle: '',
    documentType: 'CANCELLATION_FORM',
    documentDescription: '',
    fileName: '',
    fileType: '',
    fileSize: '',
    fileContent: ''
};

export default class HsmDocumentSave extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hsmDocument: defaultHsmDocument,
            pageMode: this.props.pageMode,
            buttonCounter: 0,
            fileMode: null,
            isOnStart: null,
            hsmDocumentPart: null
        }
        this.service = new HsmDocumentService();
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({pageMode: nextProps.pageMode});
}

    render() {
        return (
            <div>
                <div>
                    <Row className="justify-content-center">
                        <Col md="8">
                            <AvForm onValidSubmit={this.upload} ref={(e) => this.refForm = e}>
                                <AvGroup>
                                    <Label for="documentTitle">
                                        <Translate contentKey="hsmDocument.documentTitle">Başlık</Translate>
                                    </Label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="documentTitle"
                                        value={this.state.hsmDocument.documentTitle}
                                        onChange={this.__handleChange}
                                    />
                                </AvGroup>
                                <AvGroup>
                                    <Label for="documentType">
                                        <Translate contentKey="hsmDocument.documentType">Doküman Tipi</Translate>
                                    </Label>
                                    <AvField type="select" className="form-control" name="documentType"
                                             value={this.state.hsmDocument.documentType} onChange={this.__handleChange}>
                                        {DOCUMENT_TYPE.map(element => (
                                            <option value={element.value} key={element.value}>
                                                {element.name}
                                            </option>
                                        ))}
                                    </AvField>
                                </AvGroup>
                                <AvGroup>
                                    <Label for="objectType">
                                        <Translate contentKey="hsmDocument.objectType">Obje Tipi</Translate>
                                    </Label>
                                    <AvField type="select" className="form-control" name="objectType"
                                             value={this.state.hsmDocument.objectType} onChange={this.__handleChange}>
                                        {OBJECT_STATUS.map(element => (
                                            <option value={element.value} key={element.value}>
                                                {element.name}
                                            </option>
                                        ))}
                                    </AvField>
                                </AvGroup>
                                <AvGroup>
                                    <Label for="documentDescription">
                                        <Translate contentKey="hsmDocument.documentDescription">Açıklama</Translate>
                                    </Label>
                                    <AvField
                                        type="textarea"
                                        className="form-control textAreaHermes"
                                        name="documentDescription"
                                        value={this.state.hsmDocument.documentDescription}
                                        onChange={this.__handleChange}
                                    />
                                </AvGroup>

                                {this.state.pageMode === PAGE_MODE.CREATE ? <div>
                                <Fieldset legend="Doküman ekleme tercihiniz:">
                                    <AvRadioGroup name="fileSelection" required
                                                  errorMessage="Doküman ekleme tercihi yapınız">
                                        <AvRadio customInput label="Doküman tek sayfa/dosya içeriyor." value="OneFile"
                                                 onClick={() => {
                                                     this.addButtonCounterToOne();
                                                     this.setState({fileMode: 'single', isOnStart: true});
                                                 }}/>
                                        <AvRadio customInput label="Doküman birden fazla sayfa/dosya içeriyor."
                                                 value="MultiFile" onClick={() => {
                                            this.addButtonCounterToOne();
                                            this.setState({fileMode: 'multi', isOnStart: true});
                                        }}/>
                                    </AvRadioGroup>
                                </Fieldset>
                                <br/>

                                <div>
                                    {this.state.isOnStart ? <Fieldset legend="Doküman ekleme tercihiniz:">
                                    <Row>
                                        <Col>
                                            {this.fileRow()}
                                        </Col>
                                        <Col>{this.state.fileMode === 'multi' &&
                                        <div>
                                            {this.state.isOnStart ?
                                                <Button color="primary" onClick={() => this.addButtonCounterIncrease()}>
                                                    <Translate contentKey="entity.action.save">+</Translate>
                                                </Button> : null}
                                        </div>}
                                        </Col>
                                    </Row>
                                </Fieldset> : null}
                                </div>
                                </div> : <DataTable fields={HsmDocumentPartColumn.fields} data={this.state.hsmDocumentPart} selectionMode="single"
                                                    onDataSelect={this.onDataSelectDownloadFile} action={true}/>}
                                <div>
                                    <Button color="primary" type="submit" className="float-right">
                                        &nbsp;
                                        <Translate contentKey="entity.action.save">Upload</Translate>
                                    </Button>

                                    <Button color="primary" className="float-right" icon="pi pi-download"
                                            onClick={() => this.download()}
                                            disabled={this.state.pageMode === PAGE_MODE.CREATE}>
                                        &nbsp;
                                        <Translate contentKey="entity.action.save">Download</Translate>
                                    </Button>
                                </div>
                            </AvForm>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }

    upload = () => {
        let data = {
            id: this.state.hsmDocument.id,
            objectType: this.state.hsmDocument.objectType,
            objectId: this.state.hsmDocument.objectId,
            documentId: this.state.hsmDocument.documentId,
            partId: this.state.hsmDocument.partId,
            documentTitle: this.state.hsmDocument.documentTitle,
            documentType: this.state.hsmDocument.documentType,
            documentDescription: this.state.hsmDocument.documentDescription,
            fileName: this.state.hsmDocument.fileName,
            fileType: this.state.hsmDocument.fileType,
            fileSize: this.state.hsmDocument.fileSize,
            fileContent: this.state.hsmDocument.fileContent
        };
        //update
        if (!GeneralUtils.isNullOrEmpty(this.state.hsmDocument.id)) {
            this.service.update(data).then(response => {
                this.setState({hsmDocument: response});
                this.props.refresh();
                if(response) {
                    // toast.success("İşlem başarıyla gerçekleştirilmiştir.");
                }
                // this.props.changePageMode();
            })
        }
        else {
            let fileArr = [];
            let counter = this.state.buttonCounter;
            for (let i=0; i<counter; i++) {
                fileArr.push(document.getElementById('avatar'+i));
            }
            this.service.uploadFile(fileArr, data, GeneralUtils.getUserId(), () => {
                this.props.refresh();
                this.props.changePageMode();
            });
        }
    }

    setData = (data, pageMode) => {
        this.downloadFileList(data.documentId);
        this.setState({hsmDocument: data, pageMode: pageMode});
    }

    clearData = () => {
        //radio buttonların sıfırlanması için kullanılıyor.
        this.refForm.reset();
        let obj = Object.assign({}, defaultHsmDocument);
        //isOnStart popup açıldığında dosya seç ve dosya ekleme butonu gösterin visibilitysini ayarlıyor. ButtonCounter = 0 ilk açılışta choosefile gözükmemesi için yapıldı.
        this.setState({hsmDocument: obj, pageMode: null, isOnStart: false, buttonCounter: 0, hsmDocumentPart:null});
    }

    downloadFileList = (documentId) => {
        this.service.downloadFileList(documentId).then(response => {
            this.setState({hsmDocumentPart: response});
        });
    }

    onDataSelectDownloadFile = (data) => {
        this.service.download(data.id).then(response => {
            this.convertToDownloadableFile(response, data.fileType, data.fileName);
        });
    }

    download = () => {
        this.service.download(this.state.hsmDocument.id).then(response => {
            this.convertToDownloadableFile(response);
        });
    }

    convertToDownloadableFile = (response, fileType, fileName) => {
        // var sampleArr = this.base64ToArrayBuffer(response);
        this.saveByteArray(fileName, response, fileType);
        // this.saveFile(fileURL, response.data.name);
    }

    base64ToArrayBuffer = (base64) => {
        var binaryString = window.atob(base64);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    saveByteArray = (fileName, byte, fileType) => {
        var blob = new Blob([byte], {type: fileType});
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
    };

    fileRow = () => {
        let itemList = [];
        let counter = this.state.buttonCounter;
        for (let i = 0; i < counter; i++) {
            let fileId = "avatar" + i;
            itemList.push(<Col>{this.state.fileMode &&
            <div>
                {this.state.isOnStart ? <input type="file" id={fileId} name={fileId}/> : null}
            </div>}
            </Col>);
        }
        return itemList;
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let hsmDocument = this.state.hsmDocument;
        hsmDocument[e.target.name] = value;
        this.setState({hsmDocument: hsmDocument});
    }

    addButtonCounterIncrease = () => {
        this.setState({buttonCounter: this.state.buttonCounter + 1})
    }

    addButtonCounterToOne = () => {
        this.setState({buttonCounter: 1});
        this.forceUpdate();
    }
}

HsmDocumentSave.propTypes = {
    attachedDocument: PropTypes.object
};
