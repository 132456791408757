import React from 'react';
import {Panel} from 'primereact/panel';
import {AvForm} from 'availity-reactstrap-validation';
import {Button, Col, Row} from 'reactstrap';
import RatingService from "../../shared/service/RatingService";
import GeneralUtils from "../../shared/utils/GeneralUtils";
import {toast} from "react-toastify";
import Loading from "../../shared/component/others/Loading";
import ModalConfirm from "../request/hsmDialog/ModalConfirm";
import {Calendar} from "primereact/calendar";

class CalculateRating extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url: null,
            year:null,
            month:null,
            loading: false,
            date:null,
            locked:'Y'
        }
        this.service = new RatingService();
    }

    componentDidMount = () => {
        this.service.checkPage();
        let parameter = window.location.href.split('?')[1];
        if (!GeneralUtils.isNullOrEmpty(parameter)) {
            if (parameter.indexOf("&") > -1) {
                let values = parameter.split('&');
                let value = values[0].split('=')[1];
                let value1 = values[1].split('=')[1];
                if (!GeneralUtils.isNullOrEmpty(value) && !GeneralUtils.isNullOrEmpty(value1)) {
                    let date= new Date();
                    date.setMonth((value-1));
                    date.setFullYear(value1);
                    this.setState({month: value, year: value1, date:date});
                    this.isPeriodLocked(value, value1);
                }
            }
        }
    }

    render() {
        const tr = {
            monthNames: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
            monthNamesShort: ['Oca', 'Şub', 'Mar', 'Nis', 'May', 'Haz', 'Tem', 'Ağu', 'Eyl', 'Eki', 'Kas', 'Ara'],
            today:['Bugün'],
            clear:['Temizle']
        };


        return (
            <Panel header="Hakediş Hesapla">
                <Row className="justify-content-center">
                    <Col md="4">
                        <AvForm>
                            &nbsp;
                            <Row className="justify-content-center">
                                <div>
                                    <Calendar
                                        panelStyle={{width:300}}
                                        tooltip="Ay/Yıl Seç"
                                        required={true}
                                        style={{margin:20}}
                                        placeholder="Ay/Yıl Seç"
                                        value={this.state.date}
                                        onChange={this.__handleChangeCalender}
                                        view='month'
                                        dateFormat='mm/yy'
                                        yearNavigator={true}
                                        yearRange='2010:2030'
                                        showButtonBar={true}
                                        readOnlyInput={true}
                                        showIcon={true}
                                        locale={tr}
                                    />
                                </div>
                            </Row>
                            <Row style={{marginBottom:100}} className="justify-content-center">
                                <Col md="5">
                            <Button disabled={this.state.disabledRatingButton} color="primary"  onClick={this.showConfirmDialog} className="float-left">
                                Hakediş Hesapla
                            </Button>
                                </Col>
                                <Col md="5">
                            <Button disabled={this.state.disabledLockedButton} color="primary"  onClick={this.showConfirmDialogPeriodLocked} className="float-right">
                                Dönemi Dondur
                            </Button>
                                </Col>
                            </Row>
                        </AvForm>

                    </Col>
                </Row>
                {this.state.loading && <Loading/>}

                <ModalConfirm header='Uyarı' ok='Devam Et' onOkClick={this.calculateRating}
                              onCancelClick={() => this.setState({displayConfirmDialog: false})}
                              show={this.state.displayConfirmDialog}
                              message={this.state.noticeResponse}/>

                  <ModalConfirm header='Uyarı' ok='Devam Et' onOkClick={this.calculateRatingPeriodLocked}
                  onCancelClick={() => this.setState({displayConfirmDialogPeriodLocked: false})}
                  show={this.state.displayConfirmDialogPeriodLocked}
                  message={this.state.noticeResponsePeriodLocked}/>
            </Panel>
        );
    }

    showConfirmDialog= () => {
        if(GeneralUtils.isNullOrEmpty(this.state.month) ||  GeneralUtils.isNullOrEmpty(this.state.year)){
            this.setState({loading: false});
            return toast.warn("Hesaplama için ay ve yıl seçiniz!");
        }

        this.setState({displayConfirmDialog: true, noticeResponse: " Seçtiğiniz döneme ait Hakediş Hesaplaması silinip, tekrar hesaplama yapılacaktır. Devam etmek istiyor musunuz? (Tahmini işlem süresi: 1 dakikadır.)"});
    }

    showConfirmDialogPeriodLocked = () => {
        if(GeneralUtils.isNullOrEmpty(this.state.month) ||  GeneralUtils.isNullOrEmpty(this.state.year)){
            this.setState({loading: false});
            return toast.warn("Hesaplama için ay ve yıl seçiniz!");
        }

        this.setState({displayConfirmDialogPeriodLocked: true, noticeResponsePeriodLocked: " Seçtiğiniz döneme ait dönemi donduruyorsunuz. Bir daha bu döneme ait hakediş hesabı yapamıyacaksınız, devam etmek istiyor musunuz ? "});
    }

    calculateRating = () => {
        this.setState({loading: true});

        this.service.calculateRating(this.state.month,this.state.year).then(response => {
            this.setState({loading: false});
        });

    }

    calculateRatingPeriodLocked = () => {
        this.setState({loading: true});

        this.service.lockPeriod(this.state.month,this.state.year).then(response => {
            this.setState({loading: false});
        });

    }

    __handleChange = (e) => {
        let state = {};
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        state[e.target.name] = value;
        this.setState(state);
    }

    __handleChangeCalender = (e) => {
        let date = e.value;

        let year = date.getFullYear();
        let month = date.getMonth()+1;

        this.setState({ date , year, month});

        this.isPeriodLocked(month, year);

    }

    isPeriodLocked = (month,year) => {
        this.service.isPeriodLocked(month,year).then(response=>{
            let disabledLockedButton = response;
            this.setState({
                disabledLockedButton
            });
        });

        this.isDisabledRatingButton(month,year);

    }

    isDisabledRatingButton = (month,year) => {
        let date = new Date(), newDateYear = date.getFullYear(), newDateMonth = (date.getMonth() + 1);

        if (newDateMonth <= month || newDateYear < year) {
            this.setState({disabledRatingButton: true});
        } else {
            this.setState({disabledRatingButton: false});
        }
    }


}

export default CalculateRating;
