import React from 'react';
import {Panel} from 'primereact/panel';
import DataTable from '../../shared/component/dataTable/DataTable';
import Service from '../../shared/service/Service';
import LogLoginColumn from '../../shared/component/dataTable/columns/LogLoginColumn';


export default class LogLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
        this.service = new Service('logLogin');
    }

    componentDidMount = () => {
        this.service.checkPage();
    }

    render() {
        return (
            <Panel header="Kullanıcı Giriş Günlüğü">
                <DataTable fields = {LogLoginColumn.fields} dataURL="logLogin/findAll" objectName={LogLoginColumn.name} export={true}/>
            </Panel>
        );
    }


}
