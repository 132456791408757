import React from "react";
import {Col, Container, Row} from "reactstrap";
import * as Survey from "survey-react";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import HsmSurveyService from "../../../shared/service/HsmSurveyService";
import HsmSurveyResultService from "../../../shared/service/HsmSurveyResultService";
import Dialog from "../../../shared/component/dialog/Dialog";

let data = {
    serviceCategoryId: 1,
    serviceSubcategoryId: 1
}

export default class SurveyDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            survey: new Survey.Model({}),
            surveyResultString: [],
            displayOrganizationAndLocation:false,
            hsmSurvey: data,
            surveyData: null
        }
        this.hsmSurveyService = new HsmSurveyService();
        this.hsmSurveyResultService = new HsmSurveyResultService();
    }

    render() {
        return (
            <Row className="justify-content-center">
                <Col md="8">
                    <Dialog
                        header={'Anket'} modal={true} resizable={true} visible={this.state.displayDialog}
                        maximizable={true} responsive={true} onHide={() => this.setState({displayDialog: false})}
                        style={{width: '50vw'}}>
                        <Container>
                            <Survey.Survey model={this.state.survey}/>
                        </Container>
                    </Dialog>
                </Col>
            </Row>
        );
    }

    clearData = () => {
       let obj = Object.assign({}, data);
        this.state = {
            survey: new Survey.Model({}),
            surveyResultString: [],
            surveyData:null,
            displayOrganizationAndLocation:false,
            hsmSurvey: data,
            surveyResult: null
        }
    }

    setData = (serviceCategoryId, serviceSubcategoryId) => {
        this.setState({surveyResultString: []})
        this.state.hsmSurvey.serviceCategoryId = serviceCategoryId;
        this.state.hsmSurvey.serviceSubcategoryId = serviceSubcategoryId;
        this.getHsmSurvey();
    }

    getHsmSurvey = () => {
        this.hsmSurveyService.findByCategoryIdSubCategoryId( this.state.hsmSurvey.serviceCategoryId, this.state.hsmSurvey.serviceSubcategoryId)
            .then((response) => {
                if(!GeneralUtils.__isValueEmpty(response)) {
                    let surveyData = JSON.parse(JSON.stringify(response));
                    let survey = new Survey.Model(response.model);
                    survey
                        .onComplete
                        .add(result => {
                            this.onCompleteResult(result);
                        });
                    this.setState({survey: survey, surveyData:surveyData,hsmSurvey: this.state.hsmSurvey ,displayDialog: true});
                } else {
                    this.setState({displayDialog: false, surveyResult: null, displayOrganizationAndLocation: true});
                    if (this.props.displayOrganizationAndLocation) {
                        this.props.displayOrganizationAndLocation(this.state.displayOrganizationAndLocation)
                    }
                    if (this.props.surveyResult) {
                        this.props.surveyResult(this.state.surveyResult)
                    }
                }
            });
    }


    onCompleteResult = (result) => {
        this.surveyResultParseString(result);
        let surveyResultString = this.state.surveyResultString.join(" , ")
        let data = {
            surveyResult: JSON.stringify(result.data),
            surveyId: this.state.surveyData.id,
            author: GeneralUtils.getUserId(),
            requestType: 'INCIDENT',
            description: surveyResultString
        }
        this.hsmSurveyResultService.create(data).then((result) => {
            this.setState({surveyResult: result , displayOrganizationAndLocation: true , displaySurvey:true, displayDialog: false})

            if (this.props.displayOrganizationAndLocation) {
                this.props.displayOrganizationAndLocation(this.state.displayOrganizationAndLocation)
            }
            if(this.props.displaySurvey){
                this.props.displaySurvey(this.state.displaySurvey)
            }
            if(this.props.surveyResult){
                this.props.surveyResult(this.state.surveyResult)
            }
        });
    }

    surveyResultParseString = (result ) => {

        let surveyResult = JSON.parse(JSON.stringify(result.data));
        let surveyModelData = JSON.parse(this.state.surveyData.model);
        for (const [key, value] of Object.entries(surveyResult)) {
            surveyModelData.pages.forEach(element => {
                this.elementsSearch(key, value, element);
            });
        }
    }

    elementsSearch(searchValue, searchText, searchArray) {
        let surveyResultString = this.state.surveyResultString;
        searchArray.elements.forEach(element => {
            if (element.type != "panel") {
                if (element.type == "radiogroup" || element.type == "dropdown") {
                    if (element.name == searchValue) {
                        element.choices.forEach(choicesElement => {
                            if (choicesElement.value == searchText) {
                                surveyResultString.push(" { " + element.title + " : " + choicesElement.text + " } " );
                                this.setState({surveyResultString: surveyResultString});
                            }
                        });
                    }
                }
                if (element.type == "boolean") {
                    if (element.name == searchValue) {
                        surveyResultString.push(
                            " { " + element.title + " : " + (searchText ? "Evet" : "Hayır") + " } "
                        );
                        this.setState({surveyResultString: surveyResultString});
                    }
                }
                if(element.type == "text"){
                    if(element.name == searchValue){
                        surveyResultString.push(
                            " { " + element.title + " : " + searchText + " } "
                        );
                        this.setState({surveyResultString: surveyResultString});
                    }
                }
            } else {
                this.elementsSearch(searchValue, searchText, element);
            }
        });
    }

   getSurveyResult = () => {
        return this.state.surveyResult;
    }

}


