import React from 'react';
import {AvGroup} from "availity-reactstrap-validation";
import {Col, Label, Row} from "reactstrap";
import AvField from "../../../shared/component/form/AvField";
import {REQUEST_TYPES_CONS, SERVICE_NAME} from "../../../shared/utils/Constants";


const defaultExternalStatusMapParameter = {
    id: '',
    serviceName: '',
    requestType: '',
    status: '',
    statusReason: '',
    extStatus: '',
    extSubstatus: ''
};

export default class ExternalStatusMapSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            externalStatusMapParameter: defaultExternalStatusMapParameter,
            pageMode: null
        };
    }

    render() {
        return (
            <Row className="justify-content-center">
                <Col>
                    <AvGroup>
                        <Label for="serviceName">Hizmet Türü<span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="select"
                            className="form-control"
                            name="serviceName"
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                },
                                minLength: {
                                    value: 1,
                                    errorMessage: 'Bu alan en az 1 karakterden oluşabilir.'
                                },
                                maxLength: {
                                    value: 40,
                                    errorMessage: 'Bu alan en fazla 40 karakterden oluşabilir.'
                                }
                            }}
                            value={this.state.externalStatusMapParameter.serviceName}
                            onChange={this.__handleChange}
                        >
                            <option value='' key=''/>
                            {SERVICE_NAME.map(element => (
                                <option value={element.value} key={element.value}>
                                    {element.name}
                                </option>
                            ))}
                        </AvField>
                    </AvGroup>

                    <AvGroup>
                        <Label for="requestType">Talep Tipi<span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="select"
                            className="form-control"
                            name="requestType"
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                },
                                minLength: {
                                    value: 1,
                                    errorMessage: 'Bu alan en az 1 karakterden oluşabilir.'
                                },
                                maxLength: {
                                    value: 40,
                                    errorMessage: 'Bu alan en fazla 40 karakterden oluşabilir.'
                                }
                            }}
                            value={this.state.externalStatusMapParameter.requestType}
                            onChange={this.__handleChange}
                        >
                            <option value='' key=''/>
                            {REQUEST_TYPES_CONS.map(element => (
                                <option value={element.value} key={element.value}>
                                    {element.name}
                                </option>
                            ))}
                        </AvField>
                    </AvGroup>

                    <AvGroup>
                        <Label for="status">Durum<span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="status"
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                },
                                minLength: {
                                    value: 1,
                                    errorMessage: 'Bu alan en az 1 karakterden oluşabilir.'
                                },
                                maxLength: {
                                    value: 40,
                                    errorMessage: 'Bu alan en fazla 40 karakterden oluşabilir.'
                                }
                            }}
                            value={this.state.externalStatusMapParameter.status}
                            onChange={this.__handleChange}
                        />
                    </AvGroup>

                    <AvGroup>
                        <Label for="statusReason">Durum Nedeni
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="statusReason"
                            validate={{
                                maxLength: {
                                    value: 40,
                                    errorMessage: 'Bu alan en fazla 40 karakterden oluşabilir.'
                                }
                            }}
                            value={this.state.externalStatusMapParameter.statusReason}
                            onChange={this.__handleChange}
                        />
                    </AvGroup>

                    <AvGroup>
                        <Label for="extStatus">Dış Sistem Durum<span style={{color: 'red'}}> (*)</span></Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="extStatus"
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                },
                                minLength: {
                                    value: 1,
                                    errorMessage: 'Bu alan en az 1 karakterden oluşabilir.'
                                },
                                maxLength: {
                                    value: 40,
                                    errorMessage: 'Bu alan en fazla 40 karakterden oluşabilir.'
                                }
                            }}
                            value={this.state.externalStatusMapParameter.extStatus}
                            onChange={this.__handleChange}
                        />
                    </AvGroup>

                    <AvGroup>
                        <Label for="extSubstatus">Dış Sistem Durum Nedeni</Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="extSubstatus"
                            validate={{
                                maxLength: {
                                    value: 40,
                                    errorMessage: 'Bu alan en fazla 40 karakterden oluşabilir.'
                                }
                            }}
                            value={this.state.externalStatusMapParameter.extSubstatus}
                            onChange={this.__handleChange}
                        />
                    </AvGroup>
                </Col>
            </Row>
        );
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let externalStatusMapParameter = this.state.externalStatusMapParameter;
        externalStatusMapParameter[e.target.name] = value;
        this.setState({externalStatusMapParameter: externalStatusMapParameter});
    }

    setData = (data, pageMode) => {
        this.setState({externalStatusMapParameter: data, pageMode: pageMode});
    }

    getData = () => {
        return this.state.externalStatusMapParameter;
    }

    clearData = () => {
        this.setState({
            externalStatusMapParameter: Object.assign({}, defaultExternalStatusMapParameter),
            pageMode: null
        });
    }

}
