import React from 'react';
import {Panel} from 'primereact/panel';
import DataTable from "../../../shared/component/dataTable/DataTable";
import {AvForm} from "availity-reactstrap-validation";
import OrganizationGroup from "../../../shared/component/hsmGroup/OrganizationGroup";
import HsmOrganizationService from "../../../shared/service/HsmOrganizationService";
import {Col, Row} from 'reactstrap';
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import LocationService from "../../../shared/service/LocationService";
import HsmOrganizationColumnForDialog from "../../../shared/component/dataTable/columns/HsmOrganizationColumnForDialog";
import {TabPanel, TabView} from "primereact/tabview";
import HsmOrganizationColumn from "../../../shared/component/dataTable/columns/HsmOrganizationColumn";
import LocationColumn from '../../../shared/component/dataTable/columns/LocationColumn';
import BbProductColumn from '../../../shared/component/dataTable/columns/BbProductColumn';
import HsmUserColumn from '../../../shared/component/dataTable/columns/HsmUserColumn';
import HsmAssetColumn from '../../../shared/component/dataTable/columns/HsmAssetColumn';
import ContactColumn from '../../../shared/component/dataTable/columns/ContactColumn';
import ServiceRequestColumn from '../../../shared/component/dataTable/columns/ServiceRequestColumn';
import IncidentColumn from '../../../shared/component/dataTable/columns/IncidentColumn';
import {RadioButton} from 'primereact/radiobutton';
import BbProductColumnForGroup from "../../../shared/component/dataTable/columns/BbProductColumnForGroup";
import BbProductGroup from "../../../shared/component/hsmGroup/BbProductGroup";
import BbProductService from "../../../shared/service/BbProductService";
import LocationGroupWithSearchButton from "../../../shared/component/hsmGroup/LocationGroupWithSearchButton";
import MonitoringPageService from "../../../shared/service/MonitoringPageService";

const defaultLocation = {
    locationName: null,
    locationId: null,
    organizationId: null
}
export default class TestPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            selected: {},
            pageMode: null,
            loading: false,
            organization: null,
            activeIndex: null,
            locationList: [{id: 0, name: ''}],
            disabledLocationGroup: true,
            bbProductName: null,
            organizationId: null,
            locationId: null,
            location: defaultLocation,
            displayBbProduct: true,
            displayOrganization: true,
            displayLocation: true,
            rbSelectedItem: null,
            displayOrganizationTab: true,
            displayUserTab: true,
            displayContactTab: true,
            displayServiceRequestTab: true,
            displayIncidentTab: true,
            displayLocationTab: true,
            displayBbProductTab: true,
            displayHsmAssetTab: true,
            dataUrlOrg: null,
            dataUrlLoc: null,
            dataUrlBB: null,
            dataUrlUsers: null,
            dataUrlContact: null,
            dataUrlServiceRequest: null,
            dataUrlIncident: null,
            displayBBProduct: true

        };
        this.service = new MonitoringPageService();
        this.hsmOrganizationService = new HsmOrganizationService();
        this.locationService = new LocationService();
        this.bbProductService = new BbProductService();
    }


    render() {
        return (
            <Panel header="İzleme Sayfası">
                <AvForm>

                    <div>
                        <div className="content-section implementation">
                            <Row>

                                <div className="p-col-4">

                                    <RadioButton inputId="rbOrganization" name="city" value="organizasyon"
                                                 onChange={this.__handleChangeRadioOrg}
                                                 checked={this.state.rbSelectedItem === 'organizasyon'}
                                                 style={{marginLeft: '10px'}}/>
                                    <label htmlFor="rbOrganization" className="p-radiobutton-label"
                                           style={{width: '70%'}}>
                                        <Col md="30">
                                            <OrganizationGroup
                                                value={this.state.organization}
                                                onDataSelect={this.onOrganizationSelect}
                                                dataTable={{
                                                    dataURL: "monitoringPage/findOrganizationsByContactId",
                                                    parameter: GeneralUtils.getContactId(),
                                                    fields: HsmOrganizationColumnForDialog.fields
                                                }}
                                                disabled={this.state.displayOrganization}
                                                validate={{
                                                    required: {
                                                        value: false,
                                                    }
                                                }
                                                }
                                            />
                                        </Col>
                                    </label>


                                </div>
                                <div className="p-col-4">

                                    <RadioButton inputId="rbLocation" name="city" value="lokasyon"
                                                 onChange={this.__handleChangeRadioLoc}
                                                 checked={this.state.rbSelectedItem === 'lokasyon'}/>
                                    <label htmlFor="rbLocation" className="p-radiobutton-label" style={{width: '70%'}}>
                                        <Col md="30">

                                            <LocationGroupWithSearchButton
                                                value={this.state.location.locationName}
                                                onDataSelect={this.onLocationSelect}
                                                dataTable={{
                                                    dataURL: "monitoringPage/findLocationsByContactId",
                                                    parameter: GeneralUtils.getContactId(),
                                                    fields: LocationColumn.fields
                                                }}
                                                disabled={this.state.displayLocation}
                                                validate={{
                                                    required: {
                                                        value: false,
                                                    }
                                                }
                                                }

                                            />
                                        </Col>
                                    </label>

                                </div>
                                <div className="p-col-4">

                                    <RadioButton inputId="rbBbProduct" name="city" value="genisbant"
                                                 onChange={this.__handleChangeRadioBbProduct}
                                                 checked={this.state.rbSelectedItem === 'genisbant'}/>
                                    <label htmlFor="rbBbProduct" className="p-radiobutton-label" style={{width: '70%'}}>
                                        <Col md="30">
                                            <BbProductGroup
                                                value={this.state.bbProduct}
                                                onDataSelect={this.onBbProductSelect}
                                                dataTable={{
                                                    dataURL: "bbProduct/findByContactIdAndStatus",
                                                    parameter: GeneralUtils.getContactId() + '/' + ['ACTIVE', 'INSTALLED', 'UNINSTALLED', 'SUSPENDED'],
                                                    fields: BbProductColumnForGroup.fields
                                                }}
                                                disabled={this.state.displayBbProduct}
                                                validate={{
                                                    required: {
                                                        value: false,
                                                    }
                                                }
                                                }
                                            />
                                        </Col>
                                    </label>

                                </div>


                            </Row>

                        </div>

                        <Row>
                        </Row>
                    </div>
                </AvForm>

                <TabView activeIndex={this.state.activeIndex} renderActiveOnly={false}
                         onTabChange={(e) => this.setState({activeIndex: e.index})}>
                    <TabPanel header="Organizasyon"
                              disabled={this.state.displayOrganizationTab}
                              headerClassName={this.state.displayOrganizationTab && 'displayNone'}>
                        <DataTable ref={(elem) => this.refDataTableOrg = elem} fields={HsmOrganizationColumn.fields}
                                   dataURL={this.state.dataUrlOrg} objectName={HsmOrganizationColumn.name}
                                   loading={this.state.loading} export={true} autoLoad={false} refresh={false}/>
                    </TabPanel>

                    <TabPanel header="Lokasyon"
                              disabled={this.state.displayLocationTab}
                              headerClassName={this.state.displayLocationTab && 'displayNone'}>
                        <DataTable ref={(elem) => this.refDataTableLoc = elem} fields={LocationColumn.fields}
                                   dataURL={this.state.dataUrlLoc} objectName={LocationColumn.name}
                                   loading={this.state.loading} export={true} autoLoad={false} refresh={false}/>
                    </TabPanel>

                    <TabPanel header="Genişbant Ürünleri"
                              disabled={this.state.displayBbProductTab}
                              headerClassName={this.state.displayBbProductTab && 'displayNone'}>
                        <DataTable ref={(elem) => this.refDataTableBB = elem} fields={BbProductColumn.fields}
                                   dataURL={this.state.dataUrlBB} objectName={BbProductColumn.name}
                                   selectionMode="single" onDataSelect={this.onDataSelect}
                                   loading={this.state.loading} export={true} autoLoad={false} refresh={true}/>

                        <TabView activeIndex={this.state.activeIndexBB} renderActiveOnly={false}
                                 onTabChange={(e) => this.setState({activeIndexBB: e.index})}>
                            <TabPanel header="Varlıklar"
                                      disabled={this.state.displayHsmAssetTab}
                                      headerClassName={this.state.displayHsmAssetTab && 'displayNone'}>
                                <DataTable ref={(elem) => this.refDataTableHsmAsset = elem}
                                           fields={HsmAssetColumn.fields}
                                           dataURL="monitoringPage/findAllHsmAssetByBbProductId"
                                           objectName={HsmAssetColumn.name}
                                           loading={this.state.loading} export={true} autoLoad={false} refresh={true}/>
                            </TabPanel>
                        </TabView>
                    </TabPanel>

                    <TabPanel header="Kullanıcılar"
                              disabled={this.state.displayUserTab}
                              headerClassName={this.state.displayUserTab && 'displayNone'}>
                        <DataTable ref={(elem) => this.refDataTableUsers = elem} fields={HsmUserColumn.fields}
                                   dataURL={this.state.dataUrlUsers} objectName={HsmUserColumn.name}
                                   loading={this.state.loading} export={true} autoLoad={false} refresh={false}
                        />
                    </TabPanel>

                    <TabPanel header="Kişiler"
                              disabled={this.state.displayContactTab}
                              headerClassName={this.state.displayContactTab && 'displayNone'}>
                        <DataTable ref={(elem) => this.refDataTableContact = elem} fields={ContactColumn.fields}
                                   dataURL={this.state.dataUrlContact} objectName={ContactColumn.name}
                                   loading={this.state.loading} export={true} autoLoad={false} refresh={false}
                        />
                    </TabPanel>

                    <TabPanel header="Hizmet Talepleri"
                              disabled={this.state.displayServiceRequestTab}
                              headerClassName={this.state.displayServiceRequestTab && 'displayNone'}>
                        <DataTable ref={(elem) => this.refDataTableServiceRequest = elem}
                                   fields={ServiceRequestColumn.fields}
                                   dataURL={this.state.dataUrlServiceRequest} objectName={ServiceRequestColumn.name}
                                   loading={this.state.loading} export={true} autoLoad={false} refresh={false}/>
                    </TabPanel>

                    <TabPanel header="Arızalar"
                              disabled={this.state.displayIncidentTab}
                              headerClassName={this.state.displayIncidentTab && 'displayNone'}>
                        <DataTable ref={(elem) => this.refDataTableIncident = elem} fields={IncidentColumn.fields}
                                   dataURL={this.state.dataUrlIncident} objectName={IncidentColumn.name}
                                   loading={this.state.loading} export={true} autoLoad={false} refresh={false}/>
                    </TabPanel>

                </TabView>
            </Panel>
        );
    }

    __handleChangeRadioOrg = (e) => {
        this.setState({
            rbSelectedItem: e.value,
            displayBbProduct: true,
            displayOrganization: false,
            displayLocation: true,
            displayOrganizationTab: false,
            displayLocationTab: false,
            displayBbProductTab: false,
            displayHsmAssetTab: false,
            displayUserTab: false,
            displayContactTab: false,
            displayServiceRequestTab: false,
            displayIncidentTab: false,
            activeIndex: 0,
            dataUrlOrg: "monitoringPage/findByOrgId",
            dataUrlLoc: "monitoringPage/findLocByOrgId",
            dataUrlBB: "monitoringPage/findAllBBProductByOrgId",
            dataUrlUsers: "monitoringPage/findAllUsersByOrgId",
            dataUrlContact: "monitoringPage/findAllContactByOrgId",
            dataUrlServiceRequest: "monitoringPage/findAllServiceRequestByOrgId",
            dataUrlIncident: "monitoringPage/findAllIncidentByOrgId"
        });
    }

    __handleChangeRadioLoc = (e) => this.setState(
        {
            rbSelectedItem: e.value,
            displayBbProduct: true,
            displayOrganization: true,
            displayLocation: false,
            displayOrganizationTab: false,
            displayLocationTab: false,
            displayBbProductTab: false,
            displayHsmAssetTab: false,
            displayUserTab: false,
            displayContactTab: false,
            displayServiceRequestTab: false,
            displayIncidentTab: false,
            activeIndex: 0,
            dataUrlOrg: "monitoringPage/findByOrgId",
            dataUrlLoc: "monitoringPage/findByLocId",
            dataUrlBB: "monitoringPage/findAllBBProductByLocId",
            dataUrlUsers: "monitoringPage/findAllUsersByLocId",
            dataUrlContact: "monitoringPage/findAllContactByLocId",
            dataUrlServiceRequest: "monitoringPage/findAllServiceRequestByLocId",
            dataUrlIncident: "monitoringPage/findAllIncidentByLocId"
        })

    __handleChangeRadioBbProduct = (e) => this.setState(
        {
            rbSelectedItem: e.value,
            displayBbProduct: false,
            displayOrganization: true,
            displayLocation: true,
            displayOrganizationTab: false,
            displayLocationTab: false,
            displayBbProductTab: false,
            displayHsmAssetTab: false,
            displayUserTab: true,
            displayContactTab: true,
            displayServiceRequestTab: false,
            displayIncidentTab: false,
            activeIndex: 0,
            dataUrlOrg: "monitoringPage/findByOrgId",
            dataUrlLoc: "monitoringPage/findLocationByBbProductId",
            dataUrlBB: "monitoringPage/findByBbProductId",
            dataUrlServiceRequest: "monitoringPage/findAllServiceRequestByOrgId",
            dataUrlIncident: "monitoringPage/findAllIncidentByOrgId"


        })

    onOrganizationSelect = (data) => {
        let organization = data.code + ' - ' + data.name;
        this.setState({
            organization: organization,
            organizationId: data.id,
            locationId: null,
            location: [{locationName: ''}],
            bbProduct: '',
            activeIndex: 0

        });
        this.refDataTableOrg.setParameter(data.id);
        this.refDataTableLoc.setParameter(data.id);
        this.refDataTableBB.setParameter(data.id);
        this.refDataTableUsers.setParameter(data.id);
        this.refDataTableContact.setParameter(data.id);
        this.refDataTableServiceRequest.setParameter(data.id);
        this.refDataTableIncident.setParameter(data.id);
        this.refDataTableHsmAsset.clear();

    }


    onLocationSelect = (data) => {
        let location = this.state.location;

        location.organizationId = data.organizationId.id;
        location.locationId = data.id;
        location.locationName = data.name;
        this.setState({
            organization: '',
            bbProduct: '',
            location,
            displayDialog: false,
            activeIndex: 0
        });
        this.refDataTableOrg.setParameter(this.state.location.organizationId);
        this.refDataTableLoc.setParameter(this.state.location.locationId);
        this.refDataTableBB.setParameter(this.state.location.locationId);
        this.refDataTableContact.setParameter(this.state.location.locationId);
        this.refDataTableServiceRequest.setParameter(this.state.location.locationId);
        this.refDataTableIncident.setParameter(this.state.location.locationId);
        this.refDataTableHsmAsset.clear();


    }


    onBbProductSelect = (data) => {
        let dataNew, organizationId = null, locationId = null, bbProductId = null;

        this.bbProductService.findById(data.id).then(response => {
            dataNew = response;
            organizationId = dataNew.organizationId.id;
            locationId = dataNew.locationId.id;
            bbProductId = dataNew.id;
            this.setState({
                location: [{locationName: ''}],
                organization: '',
                bbProduct: dataNew.name,
                organizationId: organizationId,
                locationId: locationId,
                bbproductId: bbProductId,
                activeIndex: 0


            });
        });

        this.refDataTableOrg.setParameter(data.organizationId.id);
        this.refDataTableLoc.setParameter(data.id);
        this.refDataTableBB.setParameter(data.id);
        this.refDataTableServiceRequest.setParameter(data.organizationId.id);
        this.refDataTableIncident.setParameter(data.organizationId.id);
        this.refDataTableHsmAsset.clear();

    }

    onDataSelect = (data) => {
        this.refDataTableHsmAsset.setParameter(data.id);
    }

}
