import React from 'react';
import {Panel} from 'primereact/panel';
import DataTable from '../../../shared/component/dataTable/DataTable';
import HsmOrganizationService from '../../../shared/service/HsmOrganizationService';
import HsmOrganizationColumn from '../../../shared/component/dataTable/columns/HsmOrganizationColumn';
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import {Button} from 'reactstrap';
import Translate from "../../../shared/language/Translate";
import {TabPanel, TabView} from "primereact/tabview";
import {PAGE_MODE} from "../../../shared/utils/Constants";
import HsmOrganizationSave from "./HsmOrganizationSave";
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import {AvForm} from 'availity-reactstrap-validation';
import AuditEvent from "../../administration/AuditEvent";
import BillingAccountView from "../../asset-management/billing-account/BillingAccountView";


export default class HsmOrganization extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageMode: null
        };
        this.service = new HsmOrganizationService();
    }


    render() {
        return (
                    <AvForm onValidSubmit={this.save} beforeSubmitValidation={() => this.setState({activeIndex: 0})}>
                        <Toolbar
                            display={GeneralUtils.getDialogToolbarDisplay(HsmOrganizationColumn.name, this.state.pageMode)}>
                            <div className="p-toolbar-group-right">
                                <Button color="primary" type="submit" className="float-right">
                                    &nbsp;
                                    <Translate contentKey="entity.action.save">Kaydet</Translate>
                                </Button>
                                <Button color="primary" className="float-right"
                                        onClick={this.props.closeDisplayDialog}>
                                    &nbsp;
                                    <Translate contentKey="entity.action.save">Vazgeç</Translate>
                                </Button>
                            </div>
                        </Toolbar>
                        <TabView activeIndex={this.state.activeIndex}
                                 onTabChange={(e) => this.setState({activeIndex: e.index})} renderActiveOnly={false}>
                            <TabPanel header="Organizasyon Bilgileri">
                                <HsmOrganizationSave save={this.refresh}
                                                     ref={(elem) => this.refHsmOrganizationSave = elem}/>
                            </TabPanel>

                            <TabPanel header="Fatura Hesabı"
                                      disabled={this.state.pageMode === PAGE_MODE.CREATE}
                                      headerClassName={this.state.pageMode === PAGE_MODE.CREATE && 'displayNone'}>
                                <BillingAccountView ref={(elem) => this.refBillingAccountSave = elem}/>
                            </TabPanel>

                            <TabPanel header="Tarihçe" rightIcon="pi pi-user"
                                      disabled={this.state.pageMode === PAGE_MODE.CREATE}
                                      headerClassName={this.state.pageMode === PAGE_MODE.CREATE && 'displayNone'}>
                                <AuditEvent ref={elem => this.refAuditEvent = elem}
                                            objectName={HsmOrganizationColumn.name}/>
                            </TabPanel>
                        </TabView>
                    </AvForm>
        );
    }

    addNew = () => {
        this.setState({pageMode: PAGE_MODE.CREATE, activeIndex: 0});
        this.refHsmOrganizationSave.clearData();
        this.refBillingAccountSave.setPageMode(PAGE_MODE.VIEW);
    }


    save = () => {
        let hsmOrganization = this.refHsmOrganizationSave.getData();
        if (!GeneralUtils.isNullOrEmpty(hsmOrganization.id)) {
            this.service.update(hsmOrganization).then((response) => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    if(this.props.refresh){
                        this.props.refresh();
                    }
                }
            });
        } else {
            this.service.create(hsmOrganization).then((response) => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    if(this.props.refresh){
                        this.props.refresh();
                    }
                    if(this.props.closeDisplayDialog){
                        this.props.closeDisplayDialog();
                    }
                }
            });
        }
    }

    setData = (data) => {
        let pageMode = GeneralUtils.getPageModeFromPrivilege(HsmOrganizationColumn.name);
        this.setState({
            pageMode: pageMode,
            activeIndex: 0
        });
        this.refHsmOrganizationSave.setData(Object.assign({}, data), pageMode);
        this.refBillingAccountSave.setDataByOrganizationId(data.id, PAGE_MODE.VIEW);
        this.refAuditEvent.setData(HsmOrganizationColumn.name, data.id);
    }
}