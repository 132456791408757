import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class DiscoveryReportService {
    constructor() {
        this.baseURL = 'discoveryReport';
    }

    findCrossTab() {
        return fetch(SERVER_URL + this.baseURL + '/findCrossTab', {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }
}