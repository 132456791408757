import Service from './Service';
import GeneralUtils from "../utils/GeneralUtils";
import {SERVER_URL} from "../utils/Constants";

export default class NoticeService extends Service{
    constructor() {
        super();
        this.baseURL = 'notice';
    }

    findAllActiveAndUnread() {
        return fetch(SERVER_URL + this.baseURL+ '/findAllActiveAndUnread', {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    findAllActive() {
        return fetch(SERVER_URL + this.baseURL+ '/findAllActive', {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    getCountAllActive() {
        return fetch(SERVER_URL + this.baseURL+ '/getCountAllActive', {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }
}