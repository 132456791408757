import React from 'react';
import {LazyLog} from 'react-lazylog';
import {Panel} from "primereact/panel";
import LogViewService from "../../shared/service/LogViewService";
import Loading from "../../shared/component/others/Loading";
import Toolbar from "../../shared/component/toolbar/Toolbar";
import {Button as ButtonPR} from "primereact/button";
import GeneralUtils from "../../shared/utils/GeneralUtils";

export default class LogViewer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            logText: {},
            checkLogIsReady: false
        }
        this.service = new LogViewService();
    }

    componentDidMount = () => {
        this.service.checkPage();
        this.getLogFile();
    }

    render() {
        return (
            <Panel header="Hata Günlüğü">
                <Toolbar
                    display={true}>
                    <div className="p-toolbar-group-right">
                        <ButtonPR icon="pi pi-refresh" style={{'float': 'right'}} onClick={this.getLogFile}/>
                    </div>
                </Toolbar>
                {this.viewLog()}
                {this.state.loading && <Loading/>}
            </Panel>
        );
    }

    viewLog = () => {
        if (this.state.checkLogIsReady === true) {
            return <div style={{height: 700, width: '100%'}}>
                <LazyLog extraLines={1} enableSearch selectableLines={true} text={this.state.logText.value} caseInsensitive/>
            </div>
        }
    }


    getLogFile = () => {
        this.setState({loading: true});
        this.service.getLogFile().then(result => {
            if (!GeneralUtils.isNullOrEmpty(result)) {
                let logText = {};
                logText.value = result.value;
                this.setState({logText: logText, checkLogIsReady: true, loading: false})
            }else this.setState({loading: false});
        });
    }
}
