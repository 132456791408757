import React from 'react';
import {Panel} from 'primereact/panel';
import DataTableAll from '../../../shared/component/dataTable/DataTableAll';
import Service from '../../../shared/service/Service';
import MailTemplateColumn from '../../../shared/component/dataTable/columns/MailTemplateColumn';
import MailTemplateSave from "./MailTemplateSave";
import {PAGE_MODE} from "../../../shared/utils/Constants";
import Dialog from "../../../shared/component/dialog/Dialog";


export default class MailTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
        this.service = new Service('mailTemplate');
    }

    componentDidMount = () => {
        this.service.checkPage();
        this.findAll();
    }

    render() {
        return (
            <Panel header="E-Posta Template">
                <DataTableAll fields={MailTemplateColumn.fields} data={this.state.data} selectionMode="single"
                           privilege="allow"
                           onDataSelect={this.onDataSelect} loading={this.state.loading}/>

                <Dialog visible={this.state.displayDialog} header="Mail Template Detayı"
                        onHide={() => this.setState({displayDialog: false})} style={{width: '60%'}}>
                    <MailTemplateSave ref={elem => this.refMailTemplateSave = elem}
                                      closeDialog={() => this.setState({displayDialog: false})}
                                      refresh={this.findAll}/>
                </Dialog>
            </Panel>
        );
    }

    findAll = () => {
        this.setState({loading: true});
        this.service.findAll().then(response => this.setState({data: response, loading: false}));
    }

    onDataSelect = (data) => {
        this.setState({displayDialog: true, pageMode: PAGE_MODE.UPDATE, activeIndex: 0});
        this.refMailTemplateSave.clearData();
        this.refMailTemplateSave.setData(Object.assign({}, data));
    }
}
