import React from 'react';
import {Panel} from 'primereact/panel';
import {PAGE_MODE} from "../../shared/utils/Constants";
import ServiceRequestService from '../../shared/service/ServiceRequestService';
import ServiceRequestColumn from '../../shared/component/dataTable/columns/ServiceRequestColumn';
import {AvForm} from "availity-reactstrap-validation";
import ServiceRequest from "./ServiceRequest";
import GeneralUtils from "../../shared/utils/GeneralUtils";
import Dialog from "../../shared/component/dialog/Dialog";
import DataTableRequest from "../../shared/component/dataTable/DataTableRequest";

export default class ServiceRequestList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageMode: null,
            dialogHeader: 'Talep'
        };
        this.service = new ServiceRequestService();
    }

    componentDidMount = () => {
        this.init();
    }

    init = () => {
        let parameter = window.location.href.split('?')[1];
        if (parameter) {
            if (parameter.indexOf("&") > -1) {
                let values = parameter.split('&');
                let name = values[0].split('=')[1];
                let subcategoryId = values[1].split('=')[1];
                let status = values[2].split('=')[1];
                let statusReason = '';
                if (!GeneralUtils.__isValueEmpty(values[3])) {
                    statusReason = values[3].split('=')[1];
                }
                if (!GeneralUtils.isNullOrEmpty(name) && !GeneralUtils.isNullOrEmpty(subcategoryId) && !GeneralUtils.isNullOrEmpty(status)) {
                    this.refDataTable.setParameter(name, GeneralUtils.getContactId(), subcategoryId, status, statusReason);
                }
            } else {
                let name = parameter.split('=')[1];
                if (!GeneralUtils.isNullOrEmpty(name)) {
                    this.refDataTable.setParameter(name, GeneralUtils.getContactId());
                }
            }
        } else {
            this.refDataTable.setParameter("ALL", GeneralUtils.getContactId());
        }
    }

    render() {
        return (
            <Panel header="Hizmet Talebi Yönetimi">
                <DataTableRequest ref={(elem) => this.refDataTable = elem} fields={ServiceRequestColumn.fields}
                                  dataURL="serviceRequest/findAllFiltered"
                                  objectName={ServiceRequestColumn.name}
                                  selectionMode="single" onDataSelect={this.onDataSelect} loading={this.state.loading}
                                  export={true} autoLoad={false}/>

                <Dialog visible={this.state.displayDialog} header={this.state.dialogHeader}
                        onHide={() => this.setState({displayDialog: false})} style={{width: '100%'}}>
                    <AvForm>
                        <ServiceRequest ref={elem => this.refServiceRequest = elem}
                                        closeDialog={() => this.setState({displayDialog: false})}
                                        refresh={this.refresh}/>
                    </AvForm>
                </Dialog>
            </Panel>
        );
    }

    refresh = () => {
        this.init();
        // this.refDataTable.setParameter("ALL", GeneralUtils.getContactId());
    }

    onDataSelect = (data) => {
        let dialogHeader = data.id + ' Numaralı Hizmet Talebi';
        this.setState({displayDialog: true, pageMode: PAGE_MODE.VIEW, activeIndex: 0, dialogHeader: dialogHeader});
        this.refServiceRequest.setData(Object.assign({}, data), PAGE_MODE.VIEW);
        this.refServiceRequest.setDisabledFlagServiceRequestFollow(PAGE_MODE.VIEW, data.id);
    }
}