import React from 'react';
import {Col, Label, Row} from 'reactstrap';
import {AvGroup} from 'availity-reactstrap-validation';
import AvField from '../../../shared/component/form/AvField';
import PropTypes from 'prop-types';
import Translate from "../../../shared/language/Translate";
import GeneralUtils from '../../../shared/utils/GeneralUtils';
import ModelService from "../../../shared/service/ModelService";
import BrandService from "../../../shared/service/BrandService";
import AssetCategoryService from "../../../shared/service/AssetCategoryService";
import AvFieldUpper from "../../../shared/component/form/AvFieldUpper";

const defaultModel = {
    id: '',
    name: '',
    assetCategory: 'ENCLOSURE',
    brandId: ''
};

const assetCategory = {
NETWORK_DEVICE : "Ağ Cihazı", BROADBAND_PRODUCT : "TTVPN Genişbant" , ENCLOSURE : "Muhafaza" ,NETWORK_INTERFACE : "Ağ Adaptörü"
}

export default class ModelSave extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            model: defaultModel,
            brandList: [{id: 0, name: "default"}],
            assetCategoryList: [],
            pageMode: null
        }
        this.service = new ModelService();
        this.brandService = new BrandService();
        this.assetCategoryService = new AssetCategoryService();
    }

    componentDidMount = () => {
        this.brandService.findAll().then(response => this.setState({brandList: response}));
        this.assetCategoryService.findAllAssetCategory().then(response => {
            let a = response;
            this.setState({assetCategoryList: response});
        });

    }

    render() {
        return (
            <div>
                <div>
                    <Row className="justify-content-center">
                        <Col md="8">


                            <div className="container">
                                <AvGroup>
                                    <Label for="assetCategory">
                                        <Translate contentKey="model.assetCategory">Varlık Sınıfı</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>
                                    <AvField
                                        type="select"
                                        className="form-control"
                                        name="assetCategory"
                                        value={this.state.model.assetCategory}
                                        onChange={this.__handleChange}
                                        validate={{
                                            required: {
                                                value: "true",
                                                errorMessage: "Bu alanın doldurulması zorunludur."
                                            }
                                        }}>
                                        {this.state.assetCategoryList.map(element => (
                                            <option value={element.name} key={element.name}>
                                                {assetCategory[element.name]}
                                            </option>
                                        ))}
                                    </AvField>
                                </AvGroup>
                                <AvGroup>
                                    <Label for="brandId">
                                        <Translate contentKey="model.brandId">Marka Adı</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>
                                    <AvField type="select" className="form-control" name="brandId"
                                             value={this.state.model.brandId} onChange={this.__handleChange}
                                             validate={{
                                                 required: {
                                                     value: "true",
                                                     errorMessage: "Bu alanın doldurulması zorunludur."
                                                 }
                                             }}>
                                        <option value='' key=''/>
                                        {this.state.brandList.map(element => (
                                            <option value={element.id} key={element.id}>
                                                {element.name}
                                            </option>
                                        ))}
                                    </AvField>
                                </AvGroup>
                                <AvGroup>
                                    <Label for="name">
                                        <Translate contentKey="model.name">Model Adı</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>
                                    <AvFieldUpper
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        validate={{
                                            maxLength: {
                                                value: 100,
                                                errorMessage: "Bu alana en fazla 100 karakter girebilirsiniz."
                                            },
                                            required: {
                                                value: "true",
                                                errorMessage: "Bu alanın doldurulması zorunludur."
                                            }
                                        }}
                                        value={this.state.model.name}
                                        onChange={this.__handleChange}
                                        required/>
                                </AvGroup>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }

    save = () => {
        let data = Object.assign({}, this.state.model);
        data.brandId = {id: this.state.model.brandId};
        if (!GeneralUtils.isNullOrEmpty(this.state.model.id)) {
            this.service.update(data)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        this.props.save();
                    }
                });
        } else {
            this.service.create(data)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        let model = this.state.model;
                        model.id = response.id;
                        this.setState({model: model});
                        this.props.save();
                    }
                });
        }
    }

    setData = (data, pageMode) => {
        data.brandId = data.brandId.id;
        this.setState({model: data, pageMode: pageMode});
    }

    clearData = () => {
        let model = Object.assign({}, defaultModel);
        this.setState({model: model, pageMode: null});
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let model = this.state.model;
        model[e.target.name] = value;
        this.setState({model: model});
    }
}
ModelSave.propTypes = {
    model: PropTypes.object
};
