import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class BillingAccountService extends Service {
    constructor() {
        super();
        this.baseURL = 'billingAccount';
    }

    findByOrganizationIdFromBbProduct(organizationId) {
        return fetch(SERVER_URL + this.baseURL + '/findByOrganizationIdFromBbProduct/' + organizationId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        })
            .then(response => response.json())
            .catch(error => {
                GeneralUtils.notification(error);
            });
    }

    findAllFilteredByOrganizationId(organizationId) {
        return fetch(SERVER_URL + this.baseURL + '/findAllFilteredByOrganizationId/' + organizationId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        })
            .then(response => response.json())
            .catch(error => {
                GeneralUtils.notification(error);
            });
    }

}