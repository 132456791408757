import React from 'react';
import DataTable from "./DataTable";

/**
 * Ümit Yıldırım ._.
 */
const fieldList = {
    //BbProduct
    activatedDate : "Aktivasyon Tarihi",
    installedDate : "Kurulum Tarihi",
    uninstalledDate : "İptal Tarihi",
    suspendedDate : "Askıya Alınma Tarihi",
    serviceNumber: "Hizmet Numarası",
    //Brand
    name: "Ad",
    //Model
    deviceType: "Cihaz Tipi",
    brandId: "Marka",
    //BillingAccount
    code: "Kod",
    billPreference: "Gönderim Tercihi",
    status: "Durum",
    email: "E-Posta",
    addressLine: "Adres Alanı",
    cityId: "İl Adı",
    districtId: "İlçe Adı",
    description: "Açıklama",
    buildingName: "Bina Adı",
    buildingNumber: "Bina Numarası",
    doorNumber: "Kapı Numarası",
    levelNumber: "Kat Numarası",
    postalCode: "Posta Kodu",
    organizationId: "Organizasyon",
    //Contact
    turkishIdentityNo: "TCK No",
    givenNames: "Ad",
    familyName: "Soyad",
    contactType: "Kontak Tipi",
    workTitle: "Ünvanı",
    employeeNo: "Çalışan Numarası",
    locationId: "Lokasyon",
    mobilePhone: "GSM No",
    businessPhone: "İş Telefonu",
    faxNumber: "Fax Numarası",
    taxOffice: "Vergi Dairesi",
    //HsmOrganization
    parentOrganizationId: "Bağlı Olduğu Organizasyon Adı",
    organizationType: "Organizasyon Tipi",
    role: "Rol",
    sector: "Sektör",
    taxNumber: "Vergi Numarası",
    commercialRegistrationNumber: "Ticari Kayıt Numarası",
    hsmOrganizationType: "Organizasyon Tipi",
    //Incident
    title: "Başlık",
    assignmentDate: "Atama Tarihi",
    statusReason: "Çözüm Sebebi",
    HsmAsset: "Varlık",
    BbProduct: "Genişbant",
    RequestAsset: "Varlık",
    RequestContact: "Kişi",
    RequestComment: "Yorum",
    RequestWorknote: "İş Notu",
    createdDate: "Kayıt Tarihi",
    confirmationDate: "Çözüm Tarihi",
    confirmationStatus: "Çözüm Kodu",
    closureDate: "Kapatılma Tarihi",
    closureBy: "Kapatan",
    reopenDate: "Yeniden Açılma Tarihi",
    reopenBy: "Yeniden Açan",
    reopenCount: "Yeniden Açılma Sayısı",
    //Location
    ttRegionId: "TT Bölge Adı",
    locationType: "Lokasyon Tipi",
    latitude: "Enlem",
    longitude: "Boylam",
    numberOfRooms: "Oda Sayısı",
    numberOfPerson: "Kişi Sayısı",
    fiberExist: "Fiber Altyapı",
    smartBoardExist: "Akıllı Tahta",
    pstnNumber: "Tel No",
    //HsmAsset
    assetTag: "Barkod",
    serialNumber: "Seri No",
    acceptance_date: "Kabul Tarihi",
    modelId: "Model",
    HsmNetworkInterface: "Ağ Adaptörü",
    //HsmNetworkInterface
    purpose: "Kullanım Amacı",
    ipAddress: "IP Adresi",
    subnetMask: "Subnet Mask",
    //Notice
    content: "İçerik",
    startDate: "Başlangıç Tarihi",
    endDate: "Bitiş Tarihi",
    TeamContact: "Ekip Üyesi",
    HsmRole: "Rol",
    HsmAttachedDocument: "Eklenti",
    assetCategory: "Varlık Sınıfı",
    assetType: "Varlık Tipi",
    externalBillingAccountNumber : "Harici Fatura Hesap Numarası",
    assignedTo: "Atanan Kişi",
    teamId: "Atanan Ekip",
    plannedStartDate: "Planlanan Başlangıç Tarihi",
    plannedEndDate: "Planlanan Bitiş Tarihi",
    completionDate: "Tamamlanma Tarihi",
    quantity: "Adet"
}

export default class DataTableLog extends DataTable {

    complexCell = (rowData, column, col) => {
        if (column.field.includes('auditEventLogList') && column.field.includes('*')) {
            let content = [];
            let objectName = column.field.split('.')[0];
            if (Array.isArray(rowData[objectName])) {
                rowData[objectName].forEach((obj) => {
                    if(rowData.eventType === 'ADD'){
                        content.push(<span>
                            <span style={{ color: 'red' }}>[{fieldList[obj.fieldName]}]</span>
                            <span style={{ color: 'green' }}> Yeni Değer: </span>"{obj.newValue}"
                        </span>);
                    }else if(rowData.eventType === 'DELETE'){
                        content.push(<span>
                            <span style={{color: 'red'}}>[{fieldList[obj.fieldName]}]</span>
                            <span style={{color: 'blue'}}> Eski Değer: </span>"{obj.oldValue}"
                        </span>);
                    }else {
                        content.push(<span>
                            <span style={{color: 'red'}}>[{fieldList[obj.fieldName]}]</span>
                            <span style={{color: 'blue'}}> Eski Değer: </span>"{obj.oldValue}"
                            <span style={{color: 'green'}}> Yeni Değer: </span>"{obj.newValue}"
                        </span>);
                    }
                    content.push(<br/>)
                });
            }
            return <span>{content}</span>;
        } else {
            return this.complexCellMultiField(rowData, column, col);
        }
    }

}
