import React from 'react';
import TeamContactService from "../../../shared/service/TeamContactService";
import TeamContactColumn from '../../../shared/component/dataTable/columns/TeamContactColumn';
import {PAGE_MODE} from "../../../shared/utils/Constants";
import {Button} from "reactstrap";
import Translate from "../../../shared/language/Translate";
import TeamContactSave from "./TeamContactSave";
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import Dialog from "../../../shared/component/dialog/Dialog";
import DataTableAll from "../../../shared/component/dataTable/DataTableAll";

export default class TeamContact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            team: [],
            teamContact: [],
            teamContactRemoved: [],
            pageMode: null
        };
        this.service = new TeamContactService();
    }

    render() {
        return (
            <div>
                <Toolbar display={true}>
                    <div className="p-toolbar-group-right">
                        <Button color="primary" onClick={this.addNew}
                                disabled={!GeneralUtils.checkPrivilege(TeamContactColumn.name, 'Create')}>
                            <Translate contentKey="entity.action.add">Yeni Üye</Translate>
                        </Button>
                    </div>
                </Toolbar>
                {/*{this.state.team.name &&*/}
                {/*<Row className="justify-content-center"><h5>Ekip : {this.state.team.name}</h5></Row>}*/}
                <DataTableAll fields={TeamContactColumn.fields} data={this.state.teamContact}
                           objectName={TeamContactColumn.name}
                           selectionMode="single" onDataSelect={this.onDataSelect}/>
                <Dialog visible={this.state.displayDialog} header="Üye İşlemleri"
                        onHide={() => this.setState({displayDialog: false})} style={{width: '50%'}}>
                    <TeamContactSave ref={elem => this.refTeamContactSave = elem}
                                     closeDialog={() => this.setState({displayDialog: false})}
                                     add={this.addTeamContact} delete={this.deleteTeamContact}/>
                </Dialog>
            </div>
        );
    }

    onDataSelect = (data) => {
        this.setState({displayDialog: true, pageMode: PAGE_MODE.UPDATE});
        this.refTeamContactSave.clearData();
        this.refTeamContactSave.setData(Object.assign({}, data), PAGE_MODE.UPDATE);
    }


    addNew = () => {
        this.setState({displayDialog: true, pageMode: PAGE_MODE.CREATE});
        this.refTeamContactSave.clearData();
        this.refTeamContactSave.setData(Object.assign({}, {
            teamId: {id: this.state.team.id},
            contactId: {givenNames: '', familyName: ''}
        }), PAGE_MODE.CREATE);
    }

    setData = (data, pageMode) => {
        this.service.findByTeamId(data.id).then(response => {
            this.setState({teamContact: response ? response : [], pageMode: pageMode, team: data})
        });
    }

    getData = () => {
        return this.state.teamContact;
    }

    getDataRemoved = () => {
        return this.state.teamContactRemoved;
    }

    clearData = () => {
        this.setState({team: [], teamContact: [], pageMode: null});
    }

    addTeamContact = (data) => {
        let teamContact = this.arrayRemove(this.state.teamContact, data);
        teamContact.push(data);
        this.setState({teamContact: teamContact})
    }

    deleteTeamContact = (data) => {
        let teamContactRemoved = this.state.teamContactRemoved;
        teamContactRemoved.push(data);
        let teamContact = this.arrayRemove(this.state.teamContact, data);
        this.setState({teamContact: teamContact, teamContactRemoved: teamContactRemoved})
    }

    arrayRemove = (arr, element) => {
        return arr.filter(function (el) {
            return el.contactId.id !== element.contactId.id;
        });
    }
}