import React from "react";
import {REQUEST_TYPE, SERVICE_SUBCATEGORY} from "../../utils/Constants";

export default class CardUtils {

    static getCardContent(requestType,serviceSubcategoryId, countObject) {
        let url = (requestType == REQUEST_TYPE.INCIDENT ? '#/incidentList' : '#/serviceRequestList')+ '?name=dashboard&subcategory=' + serviceSubcategoryId;

        if (serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CONNECT) {
            return {
                labels: ['AÇIK', 'ASKIDA', 'KURULUM ONAYINDA', 'KAPATILMAYI BEKLİYOR', 'KAPALI'],
                backgroundColor: ['#24F52A', '#8BC34A', '#F52A2D', '#01579B', '#42A5F5'],
                hoverBackgroundColor: ['#24F52A', '#8BC34A', '#F52A2D', '#01579B', '#42A5F5'],
                data: [countObject.countOpen, countObject.countPending, countObject.countInstallApproval, countObject.countAwaitingClosure, countObject.countClosed],
                link: [url+'&status=OPEN', url+'&status=PENDING', url+'&status=STATUS&statusReason=POST_INSTALL_APPROVAL', url+'&status=STATUS&statusReason=AWAITING_CLOSURE', url+'&status=CLOSED']
            };
        } else if (requestType == REQUEST_TYPE.INCIDENT || serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_MOVE) {
            return {
                labels: ['AÇIK', 'ASKIDA', 'KAPALI'],
                backgroundColor: ['#24F52A', '#8BC34A', '#42A5F5'],
                hoverBackgroundColor: ['#24F52A', '#8BC34A', '#42A5F5'],
                data: [countObject.countOpen, countObject.countPending, countObject.countClosed],
                link: [url + '&status=OPEN', url + '&status=PENDING', url + '&status=CLOSED']
            }
        } else {
            return {
                labels: ['AÇIK', 'KAPALI'],
                backgroundColor: ['#24F52A', '#42A5F5'],
                hoverBackgroundColor: ['#24F52A', '#42A5F5'],
                data: [countObject.countOpen, countObject.countClosed],
                link: [url+'&status=OPEN', url+'&status=CLOSED']
            };
        }
    }

}
