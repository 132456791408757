import React from 'react';
import {Panel} from 'primereact/panel';
import DataTable from "../../../shared/component/dataTable/DataTable";
import IncidentReportService from "../../../shared/service/IncidentReportService";
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import Translate from "../../../shared/language/Translate";
import {AvForm} from "availity-reactstrap-validation";
import IncidentColumn from "../../../shared/component/dataTable/columns/IncidentColumn";
import OrganizationGroup from "../../../shared/component/hsmGroup/OrganizationGroup";
import HsmOrganizationService from "../../../shared/service/HsmOrganizationService";
import {Button, Col, Row} from 'reactstrap';
import LocationGroup from "../../../shared/component/hsmGroup/LocationGroup";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import LocationService from "../../../shared/service/LocationService";
import IncidentService from "../../../shared/service/IncidentService";
import HsmOrganizationColumnForDialog from "../../../shared/component/dataTable/columns/HsmOrganizationColumnForDialog";
import DataTableLog from "../../../shared/component/dataTable/DataTableLog";

export default class IncidentReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            selected: {},
            pageMode: null,
            loading: false,
            organization: null,
            locationList: [{id: 0, name: ''}],
            disabledLocationGroup: true,
            organizationId: null,
            locationId: null
        };
        this.service = new IncidentReportService();
        this.incidentService = new IncidentService();
        this.hsmOrganizationService = new HsmOrganizationService();
        this.locationService = new LocationService();
    }


    render() {
        return (
            <Panel header="Arıza Geçmişi Raporu">
                <AvForm onValidSubmit={this.submit}>
                    <Toolbar display={true}>
                        <div className="p-toolbar-group-right ">
                            <Button color="primary" type="submit" className="float-right">
                                <Translate>Göster</Translate>
                            </Button>
                        </div>
                    </Toolbar>
                    <div>
                        <h3>Filtre</h3>
                        <Row>
                            <Col md="3">
                                <OrganizationGroup
                                    value={this.state.organization}
                                    onDataSelect={this.onOrganizationSelect}
                                    dataTable={{
                                        dataURL:"hsmOrganization/findByContactId",
                                        parameter: GeneralUtils.getContactId(),
                                        fields: HsmOrganizationColumnForDialog.fields}}
                                />
                            </Col>
                            <Col md="3"/>
                            <Col md="3">
                                <LocationGroup value={this.state.location}
                                               optionList={this.state.locationList}
                                               disabled={this.state.disabledLocationGroup}
                                               validate={true}
                                               onChange={this.__handleChangeLocation}
                                />
                            </Col>
                        </Row>
                    </div>
                </AvForm>

                <DataTable ref={(elem) => this.refDataTable = elem} fields={IncidentColumn.fields} dataURL="incident/findByOrgIdAndForIncidentHistoryReport" objectName={IncidentColumn.name}
                           loading={this.state.loadingIncident} export={true} autoLoad={false} refresh={false}/>
            </Panel>
        );
    }

    submit = () => {
        if(!GeneralUtils.isNullOrEmpty(this.state.locationId)){
            this.refDataTable.setParameter(this.state.organizationId, this.state.locationId);
        }else{
            this.refDataTable.setParameter(this.state.organizationId);
        }
    }

    onOrganizationSelect = (data) => {
        let organization = data.code + ' - ' + data.name;
        this.setOrgAndLoc(data.id);
        this.setState({
            organization: organization,
            organizationId: data.id,
            disabledLocationGroup: false,
            locationId:null
        });
    }

    setOrgAndLoc = (orgId) => {
        this.locationService.findByOrganizationId(orgId).then(response => {
                if (!GeneralUtils.isNullOrEmpty(response)) {
                    this.setState({locationList: response});
                }
            }
        )
    }

    __handleChangeLocation = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        this.setState({locationId: value});
    }

}
