import React from 'react';
import AuditEventColumn from '../../shared/component/dataTable/columns/AuditEventColumn';
import AuditEventService from "../../shared/service/AuditEventService";
import GeneralUtils from "../../shared/utils/GeneralUtils";
import Dialog from "../../shared/component/dialog/Dialog";
import AuditEventLog from "./AuditEventLog";
import DataTableLog from "../../shared/component/dataTable/DataTableLog";
import {Panel} from "primereact/panel";

export default class LogEvent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
        };
        this.service = new AuditEventService();
    }

    render() {
        return (
            <div>
            <Panel header="Olay Günlüğü">
                <DataTableLog ref={(elem) => this.refDataTable = elem} fields={AuditEventColumn.fields} dataURL="auditEvent/findAll" privilege="allow"
                              export={true} />
            </Panel>
                </div>
        );
    }
}

