import React from 'react';
import PropTypes from 'prop-types';
import SelectInput from '../../../shared/component/form/SelectInput';
import Dialog from "../dialog/Dialog";
import {PAGE_MODE} from "../../utils/Constants";
import GeneralUtils from "../../utils/GeneralUtils";
import LocationColumn from '../../../shared/component/dataTable/columns/LocationColumn';
import LocationView from "../../../module/asset-management/location/LocationView";

export default class LocationGroup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value,
            disabled: this.props.disabled,
            addNewButton: this.props.addNewButton,
            validate: this.props.validate,
            optionList: this.props.optionList,
            name: this.props.name,
            label: this.props.label
        };
    }

    componentDidMount = () => {
        if(this.state.value && GeneralUtils.checkPrivilege(LocationColumn.name, 'View')) {
            this.refLocationSave.setPageMode(PAGE_MODE.VIEW);
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            value: nextProps.value,
            disabled: nextProps.disabled,
            addNewButton: nextProps.addNewButton,
            validate: nextProps.validate,
            optionList: nextProps.optionList,
            name: nextProps.name,
            label: nextProps.label
        });
    }

    render() {
        let validate = this.state.validate;
        if (!validate) {
            validate = {
                required: {
                    value: true,
                    errorMessage: "Bu alanın doldurulması zorunludur."
                }
            };
        }
        let name = this.state.name;
        if (!name) {
            name = "locationId"
        }
        let label = this.state.label;
        if (!label) {
            label = "Lokasyon"
        }
        let openDetailDialog = (this.state.value && GeneralUtils.checkPrivilege(LocationColumn.name, 'View')) ? this.openDetailDialog : false;
        let dateComp = (
            <div>
                <SelectInput
                    name={name}
                    value={this.state.value}
                    label={label}
                    translateKey="asset-management.locationId"
                    onChange={this.props.onChange}
                    optionList={this.state.optionList}
                    keyText="id"
                    valueKeyText="code-name"
                    disabled={this.state.disabled}
                    addNewButton={this.state.addNewButton}
                    validate={validate}
                    emptyOption={true}
                    openDetailDialog={openDetailDialog}
                    createNewLocation={this.props.createNewLocation}
                />
                {(this.state.value && GeneralUtils.checkPrivilege(LocationColumn.name, 'View')) && this.getDetailDialog()}
            </div>
        );
        return (dateComp);
    }

    openDetailDialog = () => {
        this.refLocationSave.setDataById(this.state.value, PAGE_MODE.VIEW);
        this.setState({displayDialog: true});
    }

    getDetailDialog = () => {
        let result = (
            <Dialog style={{width: '70%'}}
                    visible={this.state.displayDialog}
                    header="Lokasyon Bilgileri"
                    onHide={() => this.setState({displayDialog: false})}>
                <LocationView ref={(elem) => this.refLocationSave = elem}
                              closeDisplayDialog={() => this.setState({displayDialog: false})}/>
            </Dialog>
        );
        return result;
    }

}

LocationGroup.propTypes = {
    value: PropTypes.string,
    disabled: PropTypes.bool,
    addNewButton: PropTypes.bool,
    validate: PropTypes.object,
    optionList: PropTypes.array,
    name: PropTypes.string
};