import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class LocationService extends Service{
    constructor() {
        super();
        this.baseURL = 'location';
    }

    findByOrganizationId(data) {
        return fetch(SERVER_URL + this.baseURL+'/findByOrganizationId', {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch((error) => {
            // GeneralUtils.notification(error);
        });
    }

    findNotInBbProductByOrganizationId(orgId, locId) {
        return fetch(SERVER_URL + this.baseURL+'/findNotInBbProductByOrganizationId/' + orgId + '/' + locId, {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch((error) => {
            // GeneralUtils.notification(error);
        });
    }

    findNotInBbProductByOnlyOrganizationId(orgId) {
        return fetch(SERVER_URL + this.baseURL+'/findNotInBbProductByOnlyOrganizationId/' + orgId, {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch((error) => {
            // GeneralUtils.notification(error);
        });
    }

    findServedLocationByOrgIdAndLocId(orgId, locId) {
        return fetch(SERVER_URL + this.baseURL + '/findServedLocationByOrgIdAndLocId/' + orgId + '/' + locId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        })
            .then(response => response.json())
            .catch(error => {
                GeneralUtils.notification(error);
            });
    }

    findByOrganizationIdList(data) {
        return fetch(SERVER_URL + this.baseURL+'/findByOrganizationIdList', {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch((error) => {
            GeneralUtils.notification(error);
        });
    }

    findAllLocations() {
        return fetch(SERVER_URL + this.baseURL + '/findAllLocations', {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
    });
    }

    findNotUsedInBbProductByOrganizationId(orgId) {
        return fetch(SERVER_URL + this.baseURL+'/findNotUsedInBbProductByOrganizationId/' + orgId, {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch((error) => {
            // GeneralUtils.notification(error);
        });
    }
}