import React from 'react';
import {Button, Row} from 'reactstrap';
import Toolbar from "../../shared/component/toolbar/Toolbar";
import GeneralUtils from "../../shared/utils/GeneralUtils";
import Loading from "../../shared/component/others/Loading";
import ProviderSlaRecordsColumn from "../../shared/component/dataTable/columns/ProviderSlaRecordsColumn";
import * as XLSX from "xlsx";
import {toast} from "react-toastify";
import ProviderSlaRecordService from "../../shared/service/ProviderSlaRecordService";
import DataTableForExcel from "../../shared/component/dataTable/DataTableForExcel";
import {Panel} from "primereact/panel";
import {Calendar} from "primereact/calendar";
import {AvForm} from "availity-reactstrap-validation";
import HermesSweetAlert from "../../shared/component/sweetAlert/HermesSweetAlert";
import {FileUpload} from 'primereact/fileupload';
import {MILLISECONDS_ONE_HOUR} from "../../shared/utils/Constants";

export default class ProviderSlaRecords extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            date: null,
            year: null,
            month: null,
            referenceIdError: false
        };

        this.providerSlaRecordService = new ProviderSlaRecordService();

        this.refFileInput = React.createRef();

    }

    render() {
        const tr = {
            monthNames: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
            monthNamesShort: ['Oca', 'Şub', 'Mar', 'Nis', 'May', 'Haz', 'Tem', 'Ağu', 'Eyl', 'Eki', 'Kas', 'Ara'],
            today: ['Bugün'],
            clear: ['Temizle']
        };
        return (
            <Panel header="Türk Telekom SLA Kayıtları Girişi">
                <Toolbar display={true}>
                    <div className="p-toolbar-group-right">
                        <Button color="primary" onClick={this.clearDataExcel}>
                            Temizle
                        </Button>
                        <Button color="primary" onClick={this.saveForExcel}>
                            Kaydet
                        </Button>
                    </div>
                </Toolbar>

                <AvForm>
                    <div style={{width: 300}}>
                        <br/>
                        <h5>Dönem Bilgileri</h5>
                        <div className='p-grid p-fluid '>
                            <Calendar
                                tooltip="Ay/Yıl Seç"
                                required={true}
                                style={{margin: 20}}
                                placeholder="Ay/Yıl Seç"
                                value={this.state.date}
                                onChange={e => {
                                    this.setState({
                                        date: e.value,
                                        month: e.value.getMonth() + 1,
                                        year: e.value.getFullYear()
                                    })
                                }}
                                view='month'
                                dateFormat='mm/yy'
                                yearNavigator={true}
                                yearRange='2010:2030'
                                showButtonBar={true}
                                readOnlyInput={true}
                                showIcon={true}
                                locale={tr}
                            />
                        </div>
                    </div>
                </AvForm>

                <Row className="justify-content-center">
                    <div>
                        <Button color="primary" onClick={this.downloadSlaExcel}> Taslak Dosya İndir </Button>
                    </div>
                    <div style={this.state.date ? {opacity: 1} : {opacity: 0.5}}>
                        <FileUpload ref={this.refFileInput}
                                    mode="basic"
                                    accept='.xlsx, .xls'
                                    chooseLabel="Dosya Seç"
                                    disabled={this.state.date ? false : true}
                                    onSelect={this.onImportExcel}></FileUpload>
                    </div>

                </Row>

                <DataTableForExcel ref={(elem) => this.refDataTable = elem}
                                   fields={ProviderSlaRecordsColumn.fields}
                                   data={this.state.data}
                                   loading={this.state.loading}
                                   privilege="allow"/>

                {this.state.loading && <Loading/>}
            </Panel>
        );
    }

    onImportExcel = (file) => {
        // Get the uploaded file object
        let {files} = file;
        if (GeneralUtils.isNullOrEmpty(files)) {
            return;
        }
        // Read the file through the FileReader object
        let fileReader = new FileReader();
        fileReader.onload = event => {
            try {
                let {result} = event.target;
                // Read the entire excel table object in binary stream
                let workbook = XLSX.read(result, {type: 'binary'});
                let data = []; // store the acquired data
                // Traverse each worksheet for reading (here only the first table is read by default)
                for (let sheet in workbook.Sheets) {
                    if (workbook.Sheets.hasOwnProperty(sheet)) {
                        // Convert excel to json data using the sheet_to_json method
                        data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
                        // break; // If you only take the first table, uncomment this line
                    }
                }
                this.getNewDataArray(data);

            } catch (e) {
                this.setState({loading: false});
                this.clearDataExcel();
                return toast.warn('Lütfen seçilen dosyayı kontrol ediniz.');
            }
        };
        // Open the file in binary mode
        fileReader.readAsBinaryString(files[0]);
    }

    getNewDataArray = (data) => {

        let newDataArray = [];
        data.map(element => {
            let newData = {
                importFileName: "",
                importFileRecordNo: "",
                ttRegion: "",
                ttDirectorate: "",
                serviceType: "",
                accessTech: "",
                serviceNumber: "",
                orgName: "",
                region: "",
                referenceId: "",
                dateReported: "",
                dateResolved: "",
                totalDuration: "",
                committedDuration: "",
                solution: "",
                responsible: "",
                compliance: "",
                slaPassed: "",
                totalDays: "",
                amount: "",
                hourlyAmount: "",
                penaltyHours: "",
                penaltyAmount: "",
                controlServiceNumber: "",
                hasError: '',
                errorList: '',
                createdBy: GeneralUtils.getUserId()
            }
            newData.importFileName = this.refFileInput.current.files[0].name;
            newData.importFileRecordNo = element['Sıra No'];
            newData.ttRegion = element['Bolge'];
            newData.ttDirectorate = element['İl'];
            newData.serviceType = element['Cfs Tipi (Hizmet Türü)'];
            newData.accessTech = element['Erişim Tipi'];
            newData.serviceNumber = element['Hizmet No'];
            newData.orgName = element['Resmi Ad'];
            newData.region = element['Kademe'];
            newData.referenceId = element['Ariza Id'];
            newData.dateReported = GeneralUtils.excelDateToJSDate(element['Bildirim Zamanı']);
            newData.dateResolved = GeneralUtils.excelDateToJSDate(element['Islah Zamani']);

            if (!GeneralUtils.__isValueEmpty(element['08:00-00:00 Süre (Saat)'])) {
                if (GeneralUtils.isNumeric(element['08:00-00:00 Süre (Saat)'])) {
                    newData.totalDuration = this.getMiliSecond(element['08:00-00:00 Süre (Saat)']);
                } else {
                    newData.hasError = true;
                    newData.errorList = newData.errorList + "[Hata]: 08:00-00:00 Süre (Saat) bilgisi sayısal değer olmalıdır.\n";
                }
            } else {
                newData.hasError = true;
                newData.errorList = newData.errorList + "[Hata]: 08:00-00:00 Süre (Saat) bilgisi boş olamaz.\n";
            }

            if (!GeneralUtils.__isValueEmpty(element['Taahhüt Edilen Arıza Giderme Süresi (Saat)'])) {
                if (GeneralUtils.isNumeric(element['Taahhüt Edilen Arıza Giderme Süresi (Saat)'])) {
                    newData.committedDuration = this.getMiliSecond(element['Taahhüt Edilen Arıza Giderme Süresi (Saat)']);
                } else {
                    newData.hasError = true;
                    newData.errorList = newData.errorList + "[Hata]: Taahhüt Edilen Arıza Giderme Süresi (Saat) bilgisi sayısal değer olmalıdır.\n";
                }
            } else {
                newData.hasError = true;
                newData.errorList = newData.errorList + "[Hata]: Taahhüt Edilen Arıza Giderme Süresi (Saat) bilgisi boş olamaz.\n";
            }

            newData.solution = element['Islem Sonuc']
            newData.responsible = element['Sorumluluk Durumu'];
            newData.compliance = element['Uyum Durumu'];
            newData.serviceNumber = element['Hizmet No'];
            newData.controlServiceNumber = element['Hizmet Numarası'];

            if (!GeneralUtils.__isValueEmpty(element['SLA Geçen Süre'])) {
                if (GeneralUtils.isNumeric(element['SLA Geçen Süre'])) {
                    newData.slaPassed = this.getMiliSecond(element['SLA Geçen Süre']);
                } else {
                    newData.hasError = true;
                    newData.errorList = newData.errorList + "[Hata]: SLA Geçen Süre bilgisi sayısal değer olmalıdır.\n";
                }
            } else {
                newData.hasError = true;
                newData.errorList = newData.errorList + "[Hata]: SLA Geçen Süre bilgisi boş olamaz.\n";
            }

            if (!GeneralUtils.__isValueEmpty(element['Gün'])) {
                if (GeneralUtils.isNumeric(element['Gün'])) {
                    newData.totalDays = element['Gün'];
                } else {
                    newData.hasError = true;
                    newData.errorList = newData.errorList + "[Hata]: Gün bilgisi sayısal değer olmalıdır.\n";
                }
            } else {
                newData.hasError = true;
                newData.errorList = newData.errorList + "[Hata]: Gün bilgisi boş olamaz.\n";
            }

            if (!GeneralUtils.__isValueEmpty(element['VH Aylık Ücret'])) {
                if (GeneralUtils.isNumeric(element['VH Aylık Ücret'])) {
                    newData.amount = element['VH Aylık Ücret'];
                } else {
                    newData.hasError = true;
                    newData.errorList = newData.errorList + "[Hata]: VH Aylık Ücret bilgisi sayısal değer olmalıdır.\n";
                }
            } else {
                newData.hasError = true;
                newData.errorList = newData.errorList + "[Hata]: VH Aylık Ücret bilgisi boş olamaz.\n";
            }

            if (!GeneralUtils.__isValueEmpty(element['Saatlik Ücret'])) {
                if (GeneralUtils.isNumeric(element['Saatlik Ücret'])) {
                    newData.hourlyAmount = element['Saatlik Ücret'];
                } else {
                    newData.hasError = true;
                    newData.errorList = newData.errorList + "[Hata]: Saatlik Ücret bilgisi sayısal değer olmalıdır.\n";
                }
            } else {
                newData.hasError = true;
                newData.errorList = newData.errorList + "[Hata]: Saatlik Ücret bilgisi boş olamaz.\n";
            }

            if (!GeneralUtils.__isValueEmpty(element['3 saat aşımı'])) {
                if (GeneralUtils.isNumeric(element['3 saat aşımı'])) {
                    newData.penaltyHours = this.getMiliSecond(element['3 saat aşımı']);
                } else {
                    newData.hasError = true;
                    newData.errorList = newData.errorList + "[Hata]: 3 saat aşımı bilgisi sayısal değer olmalıdır.\n";
                }
            } else {
                newData.hasError = true;
                newData.errorList = newData.errorList + "[Hata]: 3 saat aşımı bilgisi boş olamaz.\n";
            }

            if (!GeneralUtils.__isValueEmpty(element['SLA'])) {
                if (GeneralUtils.isNumeric(element['SLA'])) {
                    newData.penaltyAmount = element['SLA'];
                } else {
                    newData.hasError = true;
                    newData.errorList = newData.errorList + "[Hata]: SLA bilgisi sayısal değer olmalıdır.\n";
                }
            } else {
                newData.hasError = true;
                newData.errorList = newData.errorList + "[Hata]: SLA bilgisi boş olamaz.\n";
            }

            this.checkData(newData, newDataArray);
            newDataArray.push(newData);
            return element;
        });

        if (newDataArray.length == 0) {
            this.clearDataExcel();
            return toast.warn('Yüklediğiniz dosyada kayıt bulunamadı.');
        }

        if (this.state.referenceIdError) {
            this.clearDataExcel();
            return HermesSweetAlert.error('Yüklediğiniz dosya içinde aynı Arıza Id ile kayıtlar vardır!');
        }

        this.setState({loading: true});

        this.providerSlaRecordService.checkSlaRecordForExcel(newDataArray).then(response => {
                if (!GeneralUtils.isNullOrEmpty(response)) {

                    this.setState({
                        data: response,
                        loading: false
                    });
                    this.checkDataList(response);
                }
            }
        );
    }

    saveForExcel = () => {

        if (GeneralUtils.isNullOrEmpty(this.state.data)) {
            this.clearDataExcel();
            return toast.warn('Lütfen dönem seçiniz ve dosya yükleyiniz.');
        }
        this.setState({loading: true});
        let data = this.state.data.map((element) => GeneralUtils.addCreateInfo(element));
        this.providerSlaRecordService.createSlaRecordForExcel(data).then(response => {
            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                let errorCheckCount = 0;
                response.forEach(element => {
                    if (element.hasError) {
                        errorCheckCount++;
                    }
                });
                if (errorCheckCount != 0) {
                    this.setState({loading: false, data: response});
                    return toast.warn('Lütfen hatalı kayıtları düzelterek kaydedin.');
                } else {
                    this.setState({loading: false});
                    return HermesSweetAlert.success(response.length + " kayıt başarılı bir şekilde kaydedilmiştir. Lütfen diğer adıma geçiniz.", () => {
                        window.location = '#/calculateRating?parameter=' + this.state.month + '&parameter2=' + this.state.year
                    });
                }
            }
        });
    }

    downloadSlaExcel = () => {
        let url = 'data/slaKayıtlarıTaslakExcel.xlsx'
        let a = document.createElement('a')
        a.href = url
        a.download = 'slaKayıtlarıTaslakExcel.xlsx'
        a.click()
        window.URL.revokeObjectURL(url)
    }

    clearDataExcel = () => {
        this.setState({data: [], date: null, referenceIdError: false});
        this.refFileInput.current.clear();
    }

    getMiliSecond = (duration) => {
        // mılı sanıyeye cevırmek lazım total_duration	committed_duration	sla_passed penalty_hours
        return Math.ceil(duration * MILLISECONDS_ONE_HOUR);
    }

    checkData = (data, dataList) => {
        let date = this.state.date;
        let year = date.getFullYear();
        let month = date.getMonth() + 1;

        if (!GeneralUtils.__isValueEmpty(data.dateReported)) {
            if (data.dateReported.getFullYear() !== year) {
                this.clearDataExcel();
                return HermesSweetAlert.error('Seçilen dönem ve kayıtların tarihi uyuşmamaktadır!');
            } else {
                if ((data.dateReported.getMonth() + 1) !== month) {
                    this.clearDataExcel();
                    return HermesSweetAlert.error('Seçilen dönem ve kayıtların tarihi uyuşmamaktadır!');
                }
            }
        }
        dataList.forEach(slaRecordData => {
            if (slaRecordData.referenceId === data.referenceId) {
                this.setState({referenceIdError: true})
            }
        });

    }

    checkDataList = (dataList) => {
        dataList.forEach(data => {
            if (!GeneralUtils.__isValueEmpty(data.errorList)) {
                return HermesSweetAlert.info('Yüklediğiniz dosya içinde hatalı ve/veya uyarı içeren kayıtlar mevcuttur. Lütfen kontrol ediniz.');
            }
        });
    }

}
