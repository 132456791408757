import React, {Component} from 'react';
import PropTypes from 'prop-types';
import NoticeService from "../../service/NoticeService";

export class AppTopbar extends Component {
    constructor() {
        super();
        this.state = {
            noticeCount: 0
        };
        this.noticeService = new NoticeService();
    }

    componentDidMount = () =>{
        this.noticeService.getCountAllActive().then(response => this.setState({noticeCount: response}));
    }

    render() {
        return (
            <div className="layout-topbar clearfix">
                <a className="layout-menu-button" onClick={this.props.onToggleMenu}>
                    <span className="pi pi-bars"/>
                </a>
                <div className="layout-topbar-icons">
                    {/*<span className="layout-topbar-search">*/}
                        {/*<InputText type="text" placeholder="Ara"/>*/}
                        {/*<span className="layout-topbar-search-icon pi pi-search"/>*/}
                    {/*</span>*/}
                    <a onClick={() => {window.location = '#/hsmUserAccount'}}>
                        <span className="layout-topbar-item-text">Hesap Bilgileri</span>
                        <span className="layout-topbar-icon pi pi-user"/>
                    </a>
                    <a onClick={() => {window.location = '#/noticeBoard'}}>
                        <span className="layout-topbar-item-text">Duyurular</span>
                        <span className="layout-topbar-icon pi pi-inbox"/>
                        {this.state.noticeCount>0 && <span className="layout-topbar-badge">{this.state.noticeCount}</span>}
                    </a>
                    <a onClick={this.props.logout}>
                        <span className="layout-topbar-item-text">Çıkış</span>
                        <span className="layout-topbar-icon pi pi-power-off"/>
                    </a>
                </div>
            </div>
        );
    }
}

AppTopbar.propTypes = {
    onToggleMenu: PropTypes.func.isRequired,
    logout: PropTypes.func
};

AppTopbar.defaultProps = {
    onToggleMenu: null
};
