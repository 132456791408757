import React, { createRef } from 'react';
import { Button } from 'reactstrap';
import { PAGE_MODE, REQUEST_TYPE } from "../../../shared/utils/Constants";
import Translate from "../../../shared/language/Translate";
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import HsmDocumentService from "../../../shared/service/HsmDocumentService";
import HsmDocumentColumn from '../../../shared/component/dataTable/columns/HsmDocumentColumn';
import Dialog from "../../../shared/component/dialog/Dialog";
import DataTableWithButton from "../../../shared/component/dataTable/DataTableWithButton";
import AttachmentSave from "./AttachmentSave";

export default class Attachment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            fileList: [],
            pageMode: PAGE_MODE.CREATE,
            pageModeForSavePage: PAGE_MODE.CREATE,
            status: '',
            allDataAndFileArray: [],
            allDataAndFileObject: { data: {}, file: [] },
            onSelectedDocumentId: null,
            showAttachmentSave: false,

        };
        this.service = new HsmDocumentService();
        this.attachmentSaveRef = createRef();
    }


    handleDownload = (response, fileType, fileName) => {
        if (this.attachmentSaveRef.current) {
            this.attachmentSaveRef.current.convertToDownloadableFile(response, fileType, fileName);
        }
    }

    render() {
        const { showAttachmentSave } = this.state;
        return (
            <div>
                {showAttachmentSave && <AttachmentSave ref={this.attachmentSaveRef} />}
                {(this.state.pageMode !== PAGE_MODE.VIEW || this.props.status === 'DRAFT') &&
                    <Toolbar display={true}>
                        <div className="p-toolbar-group-right">
                            <Button color="primary" onClick={this.addNew}
                                disabled={!GeneralUtils.checkPrivilege(HsmDocumentColumn.name, 'Add')}>
                                <Translate contentKey="entity.action.add">Ekle</Translate>
                            </Button>
                        </div>
                    </Toolbar>
                }

                <DataTableWithButton fields={HsmDocumentColumn.fields} data={this.state.data} objectName={HsmDocumentColumn.name}
                    delete={this.deleteRow}
                    view={this.onDataSelect} />

                <Dialog visible={this.state.displayDialog}
                    header={this.state.pageModeForSavePage === PAGE_MODE.VIEW ? 'Doküman Görüntüleme' : 'Yeni Doküman Oluşturma'}
                    onHide={() => this.setState({ displayDialog: false })} style={{ width: '50vw' }}>
                    <AttachmentSave ref={(elem) => this.refAttachmentSave = elem} pageMode={this.state.pageModeForSavePage}
                        closeDisplayDialog={this.closeDisplayDialog}
                        add={this.addAttachment}
                        delete={this.deleteRow}
                        setDocumentId={(documentId) => {
                            this.props.setDocumentId(documentId);
                            this.setState({
                                documentId
                            });
                        }}
                        allDataAndFileArray={this.state.allDataAndFileArray}
                    />

                </Dialog>

            </div>
        );
    }

    findAll = () => {
        this.service.findByObjectTypeAndObjectId(this.state.parentObjectType, this.state.parentObjectId).then(response => {
            this.setState({ data: response });
        })
    }

    addNew = () => {
        this.setState({ displayDialog: true, pageMode: PAGE_MODE.CREATE, pageModeForSavePage: PAGE_MODE.CREATE, activeIndex: 0 });
        this.refAttachmentSave.clearData();
    }

    setData = (data, parentObjectType, pageMode, status) => {
        this.service.findByObjectTypeAndObjectId(parentObjectType, data.id).then(response => {
            for (let dataInstance of response) {
                dataInstance.isView = true;
                dataInstance.isDeleted = true;
            }
            this.setState({
                data: response,
                parentObjectId: data.id,
                parentObjectType: parentObjectType,
                pageMode: pageMode,
                status: status
            });
            if (this.props.setAttachmentLength) {
                this.props.setAttachmentLength(response.length);
            }
        }
        );
    }

    getData = () => {
        return this.state.data;
    }

    getFileList = () => {
        return this.state.fileList;
    }

    clearAllDataAndFileArray = () => {
        this.setState({ allDataAndFileArray: [] });
    }

    onDataSelect = (data) => {
        let documentId = data.documentId;
        if (data.documentType === "SMAX") {
            let documents = [];
            let document = {
                file: data.file,
                fileType: data.mimeType,
                fileName: data.documentTitle,
                partId: 1
            }
            documents.push(document)
            this.setState({
                displayDialog: true,
                pageModeForSavePage: PAGE_MODE.VIEW,
                activeIndex: 0,
                onSelectedDocumentId: data
            });
            this.refAttachmentSave.setData(Object.assign({}, data), PAGE_MODE.VIEW, documents);
        } else {
            this.service.findDocumentPartsByDocumentId(documentId).then(res => {
                let documentParts = res;
                this.setState({
                    displayDialog: true,
                    pageModeForSavePage: PAGE_MODE.VIEW,
                    activeIndex: 0,
                    onSelectedDocumentId: data.documentId
                });
                this.refAttachmentSave.setData(Object.assign({}, data), PAGE_MODE.VIEW, documentParts);
            });
        }
    }

    closeDisplayDialog = () => {
        this.setState({ displayDialog: false });
    }

    changePageMode = () => {
        this.setState({ pageMode: PAGE_MODE.UPDATE });
    }


    addAttachment = (file, data) => {
        let temp = this.state.data;
        temp.push(data);
        let fileList = file;

        let allDataAndFileObject = { data: {}, file: [] };
        allDataAndFileObject.id = data.id;
        allDataAndFileObject.data = data;
        allDataAndFileObject.file = file;

        let allDataAndFileArray = this.state.allDataAndFileArray;
        allDataAndFileArray.push(allDataAndFileObject);

        this.setState({ data: temp, fileList: fileList, allDataAndFileArray });
        if (this.props.setAttachmentLength) {
            this.props.setAttachmentLength(this.state.allDataAndFileArray.length);
        }
    }


    setPageModeAndStatus = (pageMode, status) => {
        this.setState({ pageMode: pageMode, status: status, allDataAndFileArray: [] });
    }

    deleteRow = (rowData) => {
        let allDataAndFileArray = this.state.allDataAndFileArray;
        let index = 0;
        for (let i = 0; i < allDataAndFileArray.length; i++) {
            if (allDataAndFileArray[i].id == rowData.id) {
                index = i;
                break;
            }
        }
        /*Listeden sil butonuna tıklayınca db den silme action ı
         */
        this.service.deleteDocument(rowData.documentId).then(res => {
            if (!GeneralUtils.__isValueEmpty(res)) {
                this.deleteFromallDataAndFileArray(allDataAndFileArray, index);

                let data = this.state.data;
                let newData = this.arrayRemove(data, rowData);

                this.setState({ data: newData });

                if (this.props.deleteDocumentId) {
                    this.props.deleteDocumentId(rowData.documentId);
                }
                if (this.props.setData) {
                    this.props.setData(newData);
                }
                if (this.props.setAttachmentLength) {
                    this.props.setAttachmentLength(newData.length);
                }
            }
        })
    }

    arrayRemove = (arr, element) => {
        return arr.filter(function (el) {
            return el.id != element.id;
        });
    }

    deleteFromallDataAndFileArray = (arr, index) => {
        if (index > -1) {
            arr.splice(index, 1);
        }
    }

    /*
    Doküman ekledi kaydet dedi. kaydet dedikten sonra doküman listesinde sadece view butonu olması lazım. HT kaydı db ye kaydoldugu
    için artık doküman listeden silinmemeli. Çünkü db den silme durumu söz konusu değil.
     */
    refresh = (requestId) => {
        this.service.findByObjectTypeAndObjectId(REQUEST_TYPE.SERVICE_REQUEST, requestId).then(response => {
            let data = response;
            data.forEach(element => {
                element.isView = true;
            })
            this.setState({
                data
            });

        });
    }
}