import React from 'react';
import {Button, Label} from 'reactstrap';
import PropTypes from 'prop-types';
import {AvGroup} from 'availity-reactstrap-validation';
import AvField from "../../../shared/component/form/AvField";
import Translate from "../../../shared/language/Translate";
import GeneralUtils from '../../../shared/utils/GeneralUtils';
import {
    PAGE_MODE,
    SUBNET_NAME_MEB_CONST,
    TENANT_ID
} from "../../../shared/utils/Constants";
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import {toast} from "react-toastify";
import TenantService from "../../../shared/service/TenantService";
import HsmSubnetNameService from "../../../shared/service/HsmSubnetNameService";

const defaultHsmSubnetPool = {
    id: '',
    name: '',
    ipAddress: '',
    ipMask: '',
    vlanId: '',
    productId: ''
};

export default class HsmSubnetPoolSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hsmSubnetPool: defaultHsmSubnetPool,
            pageMode: null,
            disabledMask: false,
            subnetName: []
        };
        this.tenantService = new TenantService();
        this.hsmSubnetNameService = new HsmSubnetNameService();
    }

    componentDidMount = () => {
        this.hsmSubnetNameService.findAll().then(result => {
            if (!GeneralUtils.isNullOrEmpty(result)) {
                this.setState({subnetName: result});
            }

        });

        this.tenantService.getTenant().then(result => {
            if (!GeneralUtils.isNullOrEmpty(result)) {
                this.setState({tenantId: result});
            }
        });
    }

    render() {
        return (
            <div>
                <Toolbar
                    display={true}>
                    <div className="p-toolbar-group-right">
                        <Button color="primary" className="float-right"
                                onClick={this.add}>
                            &nbsp;
                            <Translate contentKey="entity.action.add">Ekle</Translate>
                        </Button>
                        <Button color="primary" className="float-right"
                                onClick={this.props.closeDisplayDialog}>
                            <Translate contentKey="entity.action.add">Vazgeç</Translate>
                        </Button>
                        {this.state.pageMode === PAGE_MODE.UPDATE &&
                        <Button color="primary" className="float-right" onClick={this.delete}>
                            <Translate contentKey="entity.action.delete">Sil</Translate>
                        </Button>
                        }
                    </div>
                </Toolbar>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <AvGroup>
                                <Label for="nameBB">
                                    <Translate contentKey="hsmSubnetPool.name">Subnet Adı</Translate>
                                    <span style={{color: 'red'}}>(*)</span>
                                </Label>
                                <AvField type="select"
                                         className="form-control"
                                         name="nameBB"
                                         value={this.state.hsmSubnetPool.name}
                                         onChange={this.__handleChangeName}>
                                    <option value='' key=''/>
                                    {this.state.subnetName.map(element => (
                                        <option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>
                            <AvGroup>
                                <Label for="vlanIdBB">
                                    <Translate contentKey="hsmSubnetPool.vlanId">VLAN ID</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="vlanIdBB"
                                    disabled={true}
                                    validate={{
                                        maxLength: {
                                            value: 30,
                                            errorMessage: 'Bu alan en fazla 30 karakterden oluşabilir.'
                                        }
                                    }}
                                    value={this.state.hsmSubnetPool.vlanId}
                                    onChange={this.__handleChange}
                                />
                            </AvGroup>
                        </div>
                        <div className="col">
                            <AvGroup>
                                <Label for="ipAddressBB">
                                    <Translate contentKey="hsmSubnetPool.ipAddress">IP Adresi</Translate>
                                    <span style={{color: 'red'}}>(*)</span>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="ipAddressBB"
                                    validate={{
                                        maxLength: {
                                            value: 15,
                                            errorMessage: 'Bu alan en fazla 15 karakterden oluşabilir.'
                                        }
                                    }}
                                    placeholder="000.000.000.000"
                                    value={this.state.hsmSubnetPool.ipAddress}
                                    onChange={this.__handleChange}
                                />
                            </AvGroup>
                            <AvGroup>
                                <Label for="ipMaskBB">
                                    <Translate contentKey="hsmSubnetPool.ipMaskBB">IP Mask</Translate>
                                </Label>
                                <AvField
                                    type="number"
                                    className="form-control"
                                    validate={{
                                        maxLength: {
                                            value: 2,
                                            errorMessage: 'Bu alan en fazla 2 karakterden oluşabilir.'
                                        }
                                    }}
                                    name="ipMaskBB"
                                    value={this.state.hsmSubnetPool.ipMask}
                                    onChange={this.__handleChange}
                                    disabled={this.state.disabledMask}
                                />
                            </AvGroup>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    setData = (data, pageMode) => {
        this.clearData();
        data.productId = data.productId ? data.productId.id : null;
        this.setState({hsmSubnetPool: data, pageMode: pageMode});
    }

    clearData = () => {
        this.setState({hsmSubnetPool: Object.assign({}, defaultHsmSubnetPool), pageMode: null, disabledMask: false});
    }

    __handleChangeName = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let hsmSubnetPool = this.state.hsmSubnetPool;
        hsmSubnetPool[e.target.name.replace("BB", "")] = value;
        if (this.state.tenantId == TENANT_ID.MEB) {
            this.findIpValueAndMask(hsmSubnetPool);
        }
        this.setState({hsmSubnetPool: hsmSubnetPool});
    }

    findIpValueAndMask = (hsmSubnetPool) => {
        let disabledMask = false;

        // 95 li IP ler reel network olacak.
        // 10. lu IP ler MEB Network(subnet kuralı olmayacak./24 ve /25 değişiyor) olacak.
        // Management Network 172./32
        // WAN IP Block 172./30 olmalıdır
        // MEB Network'e 95 li IP , Reel Network'e 10 lu IP girilmesine sistem müsaade etmemelidir.

        if (hsmSubnetPool.name === SUBNET_NAME_MEB_CONST.WAN_IP_BLOCK) {
            hsmSubnetPool.ipAddress = '172.'
            hsmSubnetPool.ipMask = '30';
            disabledMask = true;
        } else if (hsmSubnetPool.name === SUBNET_NAME_MEB_CONST.MEB_NETWORK) {
            hsmSubnetPool.ipAddress = '10.'
            hsmSubnetPool.ipMask = '';
        } else if (hsmSubnetPool.name === SUBNET_NAME_MEB_CONST.REEL_NETWORK) {
            hsmSubnetPool.ipAddress = '95.'
            hsmSubnetPool.ipMask = '';
        } else if (hsmSubnetPool.name === SUBNET_NAME_MEB_CONST.MANAGEMENT_NETWORK) {
            hsmSubnetPool.ipAddress = '172.'
            hsmSubnetPool.ipMask = '32';
            disabledMask = true;
        }
        this.setState({hsmSubnetPool: hsmSubnetPool, disabledMask});
    }


    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let hsmSubnetPool = this.state.hsmSubnetPool;
        hsmSubnetPool[e.target.name.replace("BB", "")] = value;
        this.setState({hsmSubnetPool: hsmSubnetPool});
    }

    setAssetIdAndPageMode = (productId, pageMode) => {
        let hsmSubnetPool = Object.assign({}, defaultHsmSubnetPool);
        hsmSubnetPool.productId = productId;
        this.setState({hsmSubnetPool: hsmSubnetPool, pageMode: pageMode});
    }

    add = () => {
        if (GeneralUtils.isNullOrEmpty(this.state.hsmSubnetPool.name)) {
            return toast.warn("Zorunlu Alan : Lütfen subnet adı giriniz!");
        }
        if (GeneralUtils.isNullOrEmpty(this.state.hsmSubnetPool.ipAddress)) {
            return toast.warn("Zorunlu Alan : Lütfen IP adresi giriniz!");
        }
        if (this.props.checkName(this.state.hsmSubnetPool.name)) {
            return toast.warn("Seçilen Subnet adı tanımlı!");
        }
        if (this.props.checkIpAddress(this.state.hsmSubnetPool.ipAddress)) {
            return toast.warn("Girilen ip adres tanımlı!");
        }
        let ipAddress = this.state.hsmSubnetPool.ipAddress;
        if (!GeneralUtils.isNullOrEmpty(ipAddress) && !GeneralUtils.isIP4(ipAddress)) {
            return toast.warn("Lütfen ip adresi alanını kontrol ediniz!");
        }
        let hsmSubnetPool = this.state.hsmSubnetPool;
        let ipMask = hsmSubnetPool.ipMask;

        if (this.state.tenantId == TENANT_ID.MEB) {
            if (hsmSubnetPool.name === SUBNET_NAME_MEB_CONST.WAN_IP_BLOCK && hsmSubnetPool.ipAddress.split('.')[0].trim() !== '172') {
                return toast.warn("İp adresi alanı 'WAN IP BLOCK' için 172 ile başlamalıdır!");
            } else if (hsmSubnetPool.name === SUBNET_NAME_MEB_CONST.REEL_NETWORK && hsmSubnetPool.ipAddress.split('.')[0].trim() !== '95') {
                return toast.warn("İp adresi alanı 'REEL NETWORK' için 95 ile başlamalıdır!");
            } else if (hsmSubnetPool.name === SUBNET_NAME_MEB_CONST.MANAGEMENT_NETWORK && hsmSubnetPool.ipAddress.split('.')[0].trim() !== '172') {
                return toast.warn("İp adresi alanı 'MANAGEMENT NETWORK' için 172 ile başlamalıdır!");
            } else if (hsmSubnetPool.name === SUBNET_NAME_MEB_CONST.MEB_NETWORK) {
                if (hsmSubnetPool.ipAddress.split('.')[0].trim() !== '10') {
                    return toast.warn("İp adresi alanı 'MEB NETWORK' için 10 ile başlamalıdır!");
                }
                if (hsmSubnetPool.ipMask !== '23' && hsmSubnetPool.ipMask !== '24' && hsmSubnetPool.ipMask !== '25') {
                    return toast.warn("İp mask alanı 'MEB NETWORK' için 23, 24 ya da 25 olmalıdır!");
                }
            }
        }

        if (!GeneralUtils.isNullOrEmpty(ipMask)) {
            if (ipMask < 1 || ipMask > 32) {
                return toast.warn("İp mask alanı 1-32 arasındaki değerleri alabilir.!");
            }
        }

        if (this.props.add) {
            this.props.add(this.state.hsmSubnetPool);
        }
        if (this.props.closeDisplayDialog) {
            this.props.closeDisplayDialog();
        }
    }

    delete = () => {
        if (this.props.delete) {
            this.props.delete(this.state.hsmSubnetPool);
        }
        if (this.props.closeDisplayDialog) {
            this.props.closeDisplayDialog();
        }
    }

}

HsmSubnetPoolSave.propTypes = {
    add: PropTypes.func,
    hsmSubnetPool: PropTypes.object
};

