import React from 'react';
import {Button, Col, Label, Row} from 'reactstrap';
import {AvFeedback, AvForm, AvGroup} from 'availity-reactstrap-validation';
import AvField from '../../../shared/component/form/AvField';
import PropTypes from 'prop-types';
import Translate from "../../../shared/language/Translate";
import {REQUEST_TYPE, SERVICE_SUBCATEGORY} from "../../../shared/utils/Constants";
import Dialog from "../../../shared/component/dialog/Dialog";
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import {toast} from "react-toastify";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import HsmResolutionCodeService from "../../../shared/service/HsmResolutionCodeService";
import DateInput from "../../../shared/component/form/DateInput";

const data = {
    description: '',
    resolutionCode: 'RC-1001',
    ratingDate: null
};

export default class CloseDialogServiceRequest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: data,
            pageMode: null,
            hsmResolutionCodeList: []
        }
        this.hsmResolutionCodeService = new HsmResolutionCodeService();
    }

    componentDidMount = () => {
        this.hsmResolutionCodeService.findByRequestType(REQUEST_TYPE.SERVICE_REQUEST).then(response => {
            this.setState({hsmResolutionCodeList: response});
        });
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_BILLING_APPROVAL || nextProps.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CANCEL) {
            let resolutionCodeList = [];
            this.state.hsmResolutionCodeList.forEach((element) => {
                if (element.code === 'RC-1001') {
                    resolutionCodeList.push(element);
                }
            });
            this.setState({hsmResolutionCodeList: resolutionCodeList});
        }
        this.setState({serviceSubcategoryId : nextProps.serviceSubcategoryId})
    }

    render() {
        return (

            <Row className="justify-content-center">
                <Col md="8">
                    <Dialog
                        header={'Kapatma Bilgileri'} modal={true} resizable={true} visible={this.state.displayDialog}
                        maximizable={true} responsive={true} onHide={() => {
                        this.setState({displayDialog: false});
                    }}
                        style={{width: '50vw'}}>

                        <AvForm onValidSubmit={this.close}>
                            <Toolbar display={true}>
                                <div className="p-toolbar-group-right">
                                    <Button color="primary" className="float-right" type="submit">
                                        Kaydet
                                    </Button>
                                    <Button color="primary" className="float-right"
                                            onClick={() => {
                                                this.setState({displayDialog: false});
                                            }}>
                                        Vazgeç
                                    </Button>
                                </div>
                            </Toolbar>


                            <AvGroup>
                                <Label for="resolutionCode">
                                    Çözüm Kodu
                                </Label>
                                <AvField type="select" className="form-control" name="resolutionCode"
                                         validate={{
                                             required: {
                                                 value: true,
                                                 errorMessage: "Bu alanın doldurulması zorunludur."
                                             }
                                         }}
                                         value={this.state.data.resolutionCode}
                                         onChange={this.__handleChange}>
                                    {this.state.hsmResolutionCodeList.map(element => (
                                        <option value={element.code} key={element.code}>
                                            {element.shortName}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>

                            <AvGroup>
                                <Label for="description">
                                    <Translate contentKey="description">Açıklama</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvField
                                    type="textarea"
                                    className="form-control textAreaHermes"
                                    name="description"
                                    validate={{
                                        maxLength: {
                                            value: 4000,
                                            errorMessage: "Bu alana en fazla 4000 karakter girebilirsiniz."
                                        },
                                        required: {
                                            value: "true",
                                            errorMessage: "Bu alanın doldurulması zorunludur."
                                        }
                                    }}
                                    value={this.state.data.description}
                                    onChange={this.__handleChange}
                                />
                                <AvFeedback>Bu alana en fazla 4000 karakter girebilirsiniz.</AvFeedback>
                            </AvGroup>

                            {(this.state.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CANCEL || this.state.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_TRANSFER
                                || this.state.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_REDUCE_BW || this.state.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_INCREASE_BW) &&
                            <div className="position-relative form-group">
                                <Label for="ratingDate">{this.state.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CANCEL ? 'Faturalama Bitiş Tarihi' :  'Faturalama Değişiklik Tarihi'}</Label>
                                <DateInput
                                    name="ratingDate"
                                    onChange={this.__handleChange}
                                    value={this.state.data.ratingDate}/>
                            </div>}
                        </AvForm>
                    </Dialog>
                </Col>
            </Row>

        );
    }

    close = () => {
        if (GeneralUtils.isNullOrEmpty(this.state.data.description)) {
            return toast.warn("Zorunlu Alan : Açıklama Giriniz!");
        }
        if((this.state.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CANCEL || this.state.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_TRANSFER
            || this.state.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_REDUCE_BW || this.state.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_INCREASE_BW)){
            if (GeneralUtils.isNullOrEmpty(this.state.data.ratingDate)) {
                return toast.warn("Zorunlu Alan : Faturalama Değişiklik Tarihi Giriniz!");
            }
            let ratingDate = new Date(this.state.data.ratingDate).getTime();
            let currentDate = new Date().getTime();
            if (ratingDate > currentDate) {
                return toast.warn("Girdiğiniz tarih, bugünün tarihinden sonra olamaz!");
            }
        }
        this.props.close();
        this.setState({displayDialog: false});
    }

    setData = (data) => {
        this.setState({data: data});
    }

    clearData = () => {
        this.setState({data: Object.assign({}, data)});
    }

    getData = () => {
        return this.state.data;
    }


    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let data = this.state.data;
        data[e.target.name] = value;
        this.setState({data: data});
    }

    setDialog = (value) => {
        this.clearData();
        this.setState({displayDialog: value});
    }
}

CloseDialogServiceRequest.propTypes = {
    data: PropTypes.object
};
