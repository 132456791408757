import React from 'react';
import {Label} from 'reactstrap';
import {AvGroup} from 'availity-reactstrap-validation';
import AvField from '../../../shared/component/form/AvField';
import PropTypes from 'prop-types';
import Translate from "../../../shared/language/Translate";
import BbProductService from "../../../shared/service/BbProductService";
import DateInput from "../../../shared/component/form/DateInput";
import {BB_PRODUCT_STATUS, PAGE_MODE, REGION_TYPE, TENANT_ID} from "../../../shared/utils/Constants";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import TenantService from "../../../shared/service/TenantService";
import {Checkbox} from "primereact/checkbox";
import {toast} from "react-toastify";
import {Fieldset} from "primereact/fieldset";

const defaultBbProduct = {
    id: '',
    productType: '',
    name: '',
    status: 'A',
    regionType: 'Metropolitan',
    serviceNumber: '',
    vpnCircuitType: '',
    cloudVpnId: '',
    cloudId: '',
    installedDate: '',
    activatedDate: '',
    suspendedDate: '',
    uninstalledDate: '',
    bandwidthId: '',
    organizationId: '',
    providerOrganizationId: '',
    locationId: '',
    organizationName: '',
    providerOrganizationName: '',
    locationName: '',
    bandwidthAccessTechnology: '',
    bandwidthUploadSpeed: '',
    bandwidthDownloadSpeed: '',
    productOfferingId: '',
    description: '',
    isSuspend: ''
};

export default class BbProductSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bbProduct: defaultBbProduct,
            pageMode: null
        }
        this.service = new BbProductService();
        this.tenantService = new TenantService();
    }

    componentDidMount = () => {
        this.tenantService.getTenant().then(result => {
            if(!GeneralUtils.isNullOrEmpty(result)){
                this.setState({tenantId: result});
            }
        });
    }
    
    render() {
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <AvGroup>
                                <Label for="productType">
                                    <Translate contentKey="bbProduct.productType">Genişbant Ürün Tipi</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="productType"
                                    disabled={true}
                                    value={this.state.bbProduct.productType}
                                    onChange={this.__handleChange}/>
                            </AvGroup>
                            <AvGroup>
                                <Label for="name">
                                    <Translate contentKey="bbProduct.name">Genişbant Ürün Adı</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="name"
                                    disabled={true}
                                    value={this.state.bbProduct.name}
                                    onChange={this.__handleChange}/>
                            </AvGroup>
                            <AvGroup>
                                <Label for="status">
                                    <Translate contentKey="bbProduct.status">Durum</Translate>
                                </Label>
                                <AvField type="select" className="form-control" name="status"
                                         value={this.state.bbProduct.status} onChange={this.__handleChange}
                                         disabled={true}>
                                    {BB_PRODUCT_STATUS.map(element => (
                                        <option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>

                            <AvGroup>
                                <Label for="status">
                                    <Translate contentKey="bbProduct.regionType">Bölge</Translate>
                                </Label>
                                <AvField type="select" className="form-control" name="regionType"
                                         value={this.state.bbProduct.regionType} onChange={this.__handleChange}
                                         disabled={this.state.pageMode === PAGE_MODE.VIEW}>
                                    {REGION_TYPE.map(element => (
                                        <option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>

                            <AvGroup>
                                <Label for="tariffName">
                                    <Translate contentKey="bbProduct.tariffName">Tarife Adı</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="tariffName"
                                    disabled={true}
                                    value={this.state.bbProduct.tariffName}
                                    onChange={this.__handleChange}/>
                            </AvGroup>
                            <AvGroup>
                                <Label for="campaignName">
                                    <Translate contentKey="bbProduct.campaignName">Kampanya</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="campaignName"
                                    disabled={true}
                                    value={this.state.bbProduct.campaignName}
                                    onChange={this.__handleChange}/>
                            </AvGroup>
                            <AvGroup>
                                <Label for="packageName">
                                    <Translate contentKey="bbProduct.packageName">Paket Adı</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="packageName"
                                    disabled={true}
                                    value={this.state.bbProduct.packageName}
                                    onChange={this.__handleChange}/>
                            </AvGroup>
                            <AvGroup>
                                <Label for="serviceNumber">
                                    <Translate contentKey="bbProduct.serviceNumber">Hizmet Numarası</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="serviceNumber"
                                    disabled={this.state.tenantId !== TENANT_ID.KB}
                                    value={this.state.bbProduct.serviceNumber}
                                    onChange={this.__handleChangeServiceNumber}/>
                            </AvGroup>
                            <AvGroup>
                                <Label for="externalBillingAccountNumber">
                                    <Translate contentKey="bbProduct.externalBillingAccountNumber">Harici Fatura Hesap Numarası</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="externalBillingAccountNumber"
                                    disabled={this.state.pageMode === PAGE_MODE.VIEW}
                                    value={this.state.bbProduct.externalBillingAccountNumber}
                                    validate={{
                                        maxLength: {
                                            value: 50,
                                            errorMessage: "Bu alana en fazla 50 karakter girebilirsiniz."
                                        }
                                    }}
                                    onChange={this.__handleChange}/>
                            </AvGroup>
                            <AvGroup>
                                <Label for="bandwidthAccessTechnology">
                                    <Translate contentKey="bbProduct.bandwidthAccessTechnology">Erişim
                                        Teknolojisi</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="bandwidthAccessTechnology"
                                    disabled={true}
                                    value={this.state.bbProduct.bandwidthAccessTechnology}
                                    onChange={this.__handleChange}/>
                            </AvGroup>
                            <AvGroup>
                                <Label for="bandwidthDownloadSpeed">
                                    <Translate contentKey="bbProduct.bandwidthDownloadSpeed">İndirme Hızı</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="bandwidthDownloadSpeed"
                                    disabled={true}
                                    value={this.state.bbProduct.bandwidthDownloadSpeed}
                                    onChange={this.__handleChange}/>
                            </AvGroup>
                            <AvGroup>
                                <Label for="bandwidthUploadSpeed">
                                    <Translate contentKey="bbProduct.bandwidthUploadSpeed">Yükleme Hızı</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="bandwidthUploadSpeed"
                                    disabled={true}
                                    value={this.state.bbProduct.bandwidthUploadSpeed}
                                    onChange={this.__handleChange}/>
                            </AvGroup>
                        </div>

                        <div className="col">
                            <AvGroup>
                                <Label for="vpnCircuitType">
                                    <Translate contentKey="bbProduct.vpnCircuitType">VPN Devre Tipi</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="vpnCircuitType"
                                    disabled={true}
                                    value={this.state.bbProduct.vpnCircuitType}
                                    onChange={this.__handleChange}/>
                            </AvGroup>
                            <AvGroup>
                                <Label for="cloudVpnId">
                                    <Translate contentKey="bbProduct.cloudVpnId">Bulut VPN Abonelik Id</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="cloudVpnId"
                                    disabled={true}
                                    value={this.state.bbProduct.cloudVpnId}
                                    onChange={this.__handleChange}/>
                            </AvGroup>
                            <AvGroup>
                                <Label for="cloudId">
                                    <Translate contentKey="bbProduct.cloudId">Bulut VPN Id</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="cloudId"
                                    disabled={true}
                                    value={this.state.bbProduct.cloudId}
                                    onChange={this.__handleChange}/>
                            </AvGroup>

                            <AvGroup>
                                <Label for="organizationName">
                                    <Translate contentKey="bbProduct.organizationName">Organizasyon</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="organizationName"
                                    disabled={true}
                                    value={this.state.bbProduct.organizationName}
                                    onChange={this.__handleChange}/>
                            </AvGroup>
                            <AvGroup>
                                <Label for="locationName">
                                    <Translate contentKey="bbProduct.locationName">Lokasyon</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="locationName"
                                    disabled={true}
                                    value={this.state.bbProduct.locationName}
                                    onChange={this.__handleChange}/>
                            </AvGroup>
                            <AvGroup>
                                <Label for="billingAccountName">
                                    <Translate contentKey="bbProduct.billingAccountName">Fatura Hesabı</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="billingAccountName"
                                    disabled={true}
                                    value={this.state.bbProduct.billingAccountName}
                                    onChange={this.__handleChange}/>
                            </AvGroup>
                            <AvGroup>
                                <Label for="providerOrganizationName">
                                    <Translate contentKey="bbProduct.providerOrganizationName">Servis
                                        Sağlayıcı</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="providerOrganizationName"
                                    disabled={true}
                                    value={this.state.bbProduct.providerOrganizationName}
                                    onChange={this.__handleChange}/>
                            </AvGroup>
                            <div className="position-relative form-group">
                                <Label for="installedDate">
                                    <Translate contentKey="asset-management.installedDate">Kurulum Tarihi</Translate>
                                </Label>
                                <DateInput
                                    name="installedDate"
                                    onChange={this.__handleChange}
                                    value={this.state.bbProduct.installedDate}
                                    disabled={this.state.tenantId !== TENANT_ID.KB}
                                    placeholder=""
                                />
                            </div>
                            <div className="position-relative form-group">
                                <Label for="activatedDate" style={{color: "green"}}>
                                    <Translate contentKey="asset-management.activatedDate">Aktivasyon / Fatura Başlatma Tarihi</Translate>
                                </Label>
                                <DateInput
                                    name="activatedDate"
                                    onChange={this.__handleChange}
                                    value={this.state.bbProduct.activatedDate}
                                    disabled={this.state.tenantId !== TENANT_ID.KB}
                                    placeholder=""
                                />
                            </div>
                            <div className="position-relative form-group">
                                <Label for="uninstalledDate" style={{color: "red"}}>
                                    <Translate contentKey="asset-management.uninstalledDate">İptal / Fatura Sonlandırma Tarihi</Translate>
                                </Label>
                                <DateInput
                                    name="uninstalledDate"
                                    onChange={this.__handleChange}
                                    value={this.state.bbProduct.uninstalledDate}
                                    disabled={true}
                                    placeholder=""
                                />
                            </div>
                            {(this.state.tenantId === TENANT_ID.KB && this.state.bbProduct.status === 'INSTALLED') &&
                                <div className="position-relative form-group">
                                    <Checkbox name="isSuspend" style={{color: '#000000', marginRight: '5px'}}
                                              onChange={this.__handleChangeIsSuspend}
                                              checked={this.state.bbProduct.isSuspend}></Checkbox>
                                    <Label for="isSuspend">
                                        Faturalamaya Onay Verilmemiştir
                                    </Label>
                                </div>
                            }
                            {(this.state.tenantId === TENANT_ID.KB && (this.state.bbProduct.status === 'INSTALLED' || this.state.bbProduct.status === 'SUSPENDED')) &&
                            <AvGroup>
                                <Label for="description">Açıklama</Label>
                                <AvField type="textarea"
                                         className="form-control textAreaHermesBig" name="description"
                                         validate={{
                                             maxLength: {
                                                 value: 4000,
                                                 errorMessage: 'Bu alan en fazla 4000 karakterden oluşabilir.'
                                             }
                                         }}
                                         value={this.state.bbProduct.description}
                                         onChange={this.__handleChange}
                                         placeholder="Lütfen Detaylı Bir Şekilde Açıklayınız."/>
                            </AvGroup>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    getData = () =>{
        return this.state.bbProduct;
    }

    setData = (data, pageMode) => {
        this.clearData();

        data.providerOrganizationName = data.providerOrganizationId.name;
        data.billingAccountCode = data.billingAccountId ? data.billingAccountId.code : null;
        data.billingAccountName = data.billingAccountId ? data.billingAccountId.name : null;
        data.bandwidthAccessTechnology = data.bandwidthId.accessTechnology;
        data.bandwidthUploadSpeed = data.bandwidthId.uploadSpeed;
        data.bandwidthDownloadSpeed = data.bandwidthId.downloadSpeed;
        data.tariffName = data.productOfferingId ? data.productOfferingId.tariffName : null;
        data.campaignName = data.productOfferingId ? data.productOfferingId.campaignName : null;
        data.packageName = data.productOfferingId ? data.productOfferingId.packageName : null;
        data.organizationName = data.organizationId.code + ' - ' + data.organizationId.name;
        data.locationName = data.locationId.code + ' - ' + data.locationId.name;
        data.billingAccountName = data.billingAccountCode ? data.billingAccountId.code + ' - ' + data.billingAccountId.name : data.billingAccountId.name;

        this.setState({bbProduct: data, pageMode: pageMode});
    }

    clearData = () => {
        let obj = Object.assign({}, defaultBbProduct);
        this.setState({bbProduct: obj});
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let bbProduct = this.state.bbProduct;
        bbProduct[e.target.name] = value;
        this.setState({bbProduct: bbProduct});
    }

    __handleChangeServiceNumber = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let bbProduct = this.state.bbProduct;
        bbProduct[e.target.name] = value;
        bbProduct.name = value;
        this.setState({bbProduct: bbProduct});
    }

    __handleChangeIsSuspend = (e) => {
        let bbProduct = this.state.bbProduct;
        bbProduct.isSuspend = e.checked;
        this.setState({bbProduct: bbProduct});
        if(e.checked) {
            toast.info("Faturalamaya Onay Verilmediği için Genişbant Ürünü Askıya Alınacaktır.");
        }
    }

}

BbProductSave.propTypes = {
    bbProduct: PropTypes.object
};




