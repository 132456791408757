import React from 'react';
import {Panel} from 'primereact/panel';
import DataTable from '../../../shared/component/dataTable/DataTable';
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import {PAGE_MODE} from "../../../shared/utils/Constants";
import HsmSubnetPoolColumn from "../../../shared/component/dataTable/columns/HsmSubnetPoolColumn.json";
import BbProductColumn from "../../../shared/component/dataTable/columns/BbProductColumn.json";
import Dialog from "../../../shared/component/dialog/Dialog";
import HsmSubnetPoolService from "../../../shared/service/HsmSubnetPoolService";
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import {Button} from "reactstrap";
import Translate from "../../../shared/language/Translate";
import HsmSubnetPoolForRegion from "./HsmSubnetPoolForRegion";


export default class HsmSubnetPoolList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
        this.service = new HsmSubnetPoolService();
    }

    componentDidMount = () => {
        this.service.checkPage();
    }

    render() {
        return (
            <Panel header="Subnet Havuzu">
                <Toolbar display={true}>
                    <div className="p-toolbar-group-right">
                        <Button color="primary" onClick={this.addNew}>
                            <Translate contentKey="entity.action.add">Yeni Kayıt</Translate>
                        </Button>
                    </div>
                </Toolbar>
                <DataTable ref={(elem) => this.refDataTable = elem} fields={HsmSubnetPoolColumn.fields}
                           dataURL="hsmSubnetPool/findAll" objectName={HsmSubnetPoolColumn.name}
                           selectionMode="single" onDataSelect={this.onDataSelect} privilege="allow" export={true}/>
                <Dialog visible={this.state.displayDialog}
                        header={this.state.pageMode === PAGE_MODE.UPDATE ? 'IP Güncelleme' : 'Yeni IP Oluşturma'}
                        onHide={() => this.setState({displayDialog: false})} style={{width: '70vw'}}>
                    <HsmSubnetPoolForRegion save={this.refresh} ref={(elem) => this.refHsmSubnetPool = elem}
                                            closeDisplayDialog={this.closeDisplayDialog}/>
                </Dialog>
            </Panel>
        );
    }

    refresh = () => {
        this.refDataTable.refresh();
    }

    onDataSelect = (data) => {
        let pageMode = GeneralUtils.getPageModeFromPrivilege(BbProductColumn.name);
        this.service.findByGroupId(data.groupId).then(response => {
            this.setState({displayDialog: true, pageMode: pageMode});
            this.refHsmSubnetPool.setData(response, pageMode);
        });
    }

    closeDisplayDialog = () => {
        this.setState({displayDialog: false});
        this.refresh();
    }

    addNew = () => {
        this.setState({displayDialog: true, pageMode: PAGE_MODE.CREATE});
        this.refHsmSubnetPool.clearData();
        this.refHsmSubnetPool.setPageMode(PAGE_MODE.CREATE);
    }

}
