import React from 'react';
import {Panel} from 'primereact/panel';
import DataTable from '../../../shared/component/dataTable/DataTable';
import BrandService from "../../../shared/service/BrandService";
import BrandColumn from '../../../shared/component/dataTable/columns/BrandColumn';
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import {Button, Row} from 'reactstrap';
import BrandSave from "./BrandSave";
import Translate from "../../../shared/language/Translate";
import {TabPanel, TabView} from "primereact/tabview";
import {PAGE_MODE} from "../../../shared/utils/Constants";
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import {AvForm} from 'availity-reactstrap-validation';
import AuditEvent from "../../administration/AuditEvent";
import Dialog from "../../../shared/component/dialog/Dialog";

export default class Brand extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            selected: {},
            pageMode: null
        };
        this.service = new BrandService();
    }

    componentDidMount = () => {
        this.service.checkPage();
    }

    render() {
        return (
            <Row className="justify-content-center">
                <Panel header="Marka Yönetimi" style={{width: '40%'}}>
                    <Toolbar display={GeneralUtils.checkPrivilege(BrandColumn.name, 'Create')}>
                        <div className="p-toolbar-group-right">
                            <Button color="primary" onClick={() => {
                                this.setState({displayDialog: true, pageMode: PAGE_MODE.CREATE, activeIndex: 0});
                                this.refModelSave.clearData()
                            }}>
                                <Translate contentKey="entity.action.add">Yeni Kayıt</Translate>
                            </Button>
                        </div>
                    </Toolbar>
                    <DataTable ref={(elem) => this.refDataTable = elem} fields={BrandColumn.fields} dataURL="brand/findAll" objectName={BrandColumn.name}
                               delete={this.delete} selectionMode="single" onDataSelect={this.onDataSelect}
                               loading={this.state.loading}/>

                    <Dialog visible={this.state.displayDialog}
                            header={GeneralUtils.getDialogHeader('Marka', this.state.pageMode)}
                            onHide={() => this.setState({displayDialog: false})} style={{width: '50vw'}}>
                        <AvForm onValidSubmit={this.submit}
                                beforeSubmitValidation={() => this.setState({activeIndex: 0})}>
                            <Toolbar
                                display={GeneralUtils.getDialogToolbarDisplay(BrandColumn.name, this.state.pageMode)}>
                                <div className="p-toolbar-group-right">
                                    <Button color="primary" type="submit" className="float-right">
                                        &nbsp;
                                        <Translate contentKey="entity.action.save">Kaydet</Translate>
                                    </Button>
                                    <Button color="primary" className="float-right"
                                            onClick={() => this.closeDisplayDialog()}>
                                        &nbsp;
                                        <Translate contentKey="entity.action.save">Vazgeç</Translate>
                                    </Button>
                                </div>
                            </Toolbar>
                            <TabView activeIndex={this.state.activeIndex}
                                     onTabChange={(e) => this.setState({activeIndex: e.index})}
                                     renderActiveOnly={false}>
                                <TabPanel header="Marka Bilgileri">
                                    <BrandSave save={this.refresh} ref={(elem) => this.refModelSave = elem}
                                               closeDisplayDialog={this.closeDisplayDialog}/>
                                </TabPanel>

                                <TabPanel header="Tarihçe" rightIcon="pi pi-user"
                                          disabled={this.state.pageMode === PAGE_MODE.CREATE}
                                          headerClassName={this.state.pageMode === PAGE_MODE.CREATE && 'displayNone'}>
                                    <AuditEvent ref={elem => this.refAuditEvent = elem} objectName={BrandColumn.name}/>
                                </TabPanel>
                            </TabView>
                        </AvForm>
                    </Dialog>
                </Panel>
            </Row>
        );
    }

    submit = () => {
        this.refModelSave.save();
    }

    refresh = () => {
        this.refDataTable.refresh();
        this.setState({displayDialog: false});
    }

    delete = (selected) => {
        this.service.delete(selected.id).then(() => {
            this.refModelSave.setData(PAGE_MODE.UPDATE);
            this.refresh();
        });
    }

    save = (newData) => {
        if (!GeneralUtils.isNullOrEmpty(newData.id)) {
            this.service.update(newData).then(() => {
                this.refModelSave.setData(PAGE_MODE.CREATE);
                this.refresh();
            });
        } else {
            this.service.create(newData).then(() => {
                this.refresh();
            });
        }
    }
    onDataSelect = (data) => {
        let pageMode = GeneralUtils.getPageModeFromPrivilege(BrandColumn.name);
        this.setState({
            displayDialog: true,
            pageMode: pageMode,
            activeIndex: 0
        });
        this.refModelSave.setData(Object.assign({}, data), pageMode);
        this.refAuditEvent.setData(BrandColumn.name, data.id);
    }

    closeDisplayDialog = () => {
        this.setState({displayDialog: false});
    }
}
