import React from "react";
import {Checkbox as PrCheckbox} from 'primereact/checkbox'
import {Label} from "reactstrap"
import PropTypes from 'prop-types';

export default class CheckboxGroup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            checkboxList: this.props.checkboxList,
        };
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            checkboxList: nextProps.checkboxList,
        });
    }

    render() {
        return (
            <div className="p-formgroup-inline">
                {this.state.checkboxList.map(option => {
                    return(
                        
                        <div key={option.name} className="p-field-checkbox p-m-3">
                        <PrCheckbox inputId={option.name}
                        onChange={(e)=>this.props.onChange(e,option)}
                        checked={option.isChecked}
                        name={option.name}
                        />
                        <Label  className="p-checkbox-label" for={option.name}>{option.label}</Label>
                        </div>

                    )
                })}

            </div>

        );
    }
}

CheckboxGroup.propTypes={
    checkboxList: PropTypes.array,
}