import React from 'react';
import {Panel} from 'primereact/panel';
import DataTable from '../../../shared/component/dataTable/DataTable';
import Dialog from "../../../shared/component/dialog/Dialog";
import {PAGE_MODE} from "../../../shared/utils/Constants";
import WorkFlowProcessService from "../../../shared/service/WorkFlowProcessService";
import WorkFlowProcessColumn from '../../../shared/component/dataTable/columns/WorkFlowProcessColumn';
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import {Button} from "reactstrap";
import Translate from "../../../shared/language/Translate";
import {TabPanel, TabView} from "primereact/tabview";
import WorkFlowModel from "./WorkFlowModel";
import WorkFlowProcess from "./WorkFlowProcess";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import WorkFlowModelColumn from '../../../shared/component/dataTable/columns/WorkFlowModelColumn';
import ServiceSubcategoryColumn from "../../../shared/component/dataTable/columns/ServiceSubcategoryColumn.json";


export default class WorkFlowList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageMode: null
        };
        this.service = new WorkFlowProcessService();
    }


    render() {
        return (
            <Panel header="İş Akışı Süreci Yönetimi">
                {/*<Toolbar display={true}>*/}
                {/*    <div className="p-toolbar-group-right">*/}
                {/*        <Button color="primary" onClick={this.addNew}>*/}
                {/*            <Translate contentKey="entity.action.add">Yeni Kayıt</Translate>*/}
                {/*        </Button>*/}
                {/*    </div>*/}
                {/*</Toolbar>*/}
                <DataTable ref={(elem) => this.refDataTable = elem} fields={ServiceSubcategoryColumn.fields} dataURL="serviceSubcategory/findAllOrderByStatus"
                           privilege="allow"
                           selectionMode="single" onDataSelect={this.onDataSelect} export={true}/>

                <Dialog visible={this.state.displayDialog}
                        header={this.state.pageMode === PAGE_MODE.UPDATE ? 'İş Akışı Süreci Güncelleme' : 'İş Akışı Süreci Ekleme'}
                        onHide={() => this.setState({displayDialog: false})} style={{width: '90vw'}}>
                    <TabView activeIndex={this.state.activeIndex}
                             onTabChange={(e) => this.setState({activeIndex: e.index})} renderActiveOnly={false}>
                        <TabPanel header="İş Akışı" rightIcon="pi pi-user">
                            <WorkFlowModel ref={(elem) => this.refWorkFlowModel = elem}/>
                        </TabPanel>
                        <TabPanel header="İş Akışı Süreci" rightIcon="pi pi-user">
                            <WorkFlowProcess ref={(elem) => this.refWorkFlowProcess = elem}/>
                        </TabPanel>
                    </TabView>
                </Dialog>
            </Panel>
        );
    }

    refresh = () => {
        this.refDataTable.setParameter('ALL');
    }

    onDataSelect = (data) => {
        let pageMode = GeneralUtils.getPageModeFromPrivilege(WorkFlowProcessColumn.name);  // privilate e bakya da kaldır bıseyler yap
        this.setState({displayDialog: true, pageMode: pageMode, activeIndex: 0});
        this.refWorkFlowModel.setData(Object.assign({}, data), pageMode);
        this.refWorkFlowProcess.setData(Object.assign({}, data), pageMode);
    }

    closeDisplayDialog = () => {
        this.setState({displayDialog: false});
    }

    // addNew = () => {
    //     this.setState({displayDialog: true, pageMode: PAGE_MODE.CREATE});
    // }
}