import React from 'react';
import {Col, Label, Row} from 'reactstrap';
import {AvFeedback, AvGroup} from 'availity-reactstrap-validation';
import AvField from '../../../shared/component/form/AvField';
import PropTypes from 'prop-types';
import Translate from "../../../shared/language/Translate";
import GeneralUtils from '../../../shared/utils/GeneralUtils';
import BrandService from "../../../shared/service/BrandService";
import AvFieldUpper from "../../../shared/component/form/AvFieldUpper";

const defaultBrand = {
    id: '',
    name: ''
};

export default class BrandSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            brand: defaultBrand,
            pageMode: null
        }
        this.service = new BrandService();
    }

    render() {
        return (
            <div>
                <div>
                    <Row className="justify-content-center">
                        <Col md="8">
                            <div className="container">
                                <AvGroup>
                                    <Label for="name">
                                        <Translate contentKey="brand.name">Marka Adı</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>
                                    <AvFieldUpper
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        validate={{
                                            maxLength: {
                                                value: 20,
                                                errorMessage: "Bu alana en fazla 20 karakter girebilirsiniz."
                                            },
                                            required: {
                                                value: "true",
                                                errorMessage: "Bu alanın doldurulması zorunludur."
                                            }
                                        }}
                                        value={this.state.brand.name}
                                        onChange={this.__handleChange}/>
                                    <AvFeedback>Bu alana en fazla 20 karakter girebilirsiniz.</AvFeedback>
                                </AvGroup>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }

    save = () => {
        if (!GeneralUtils.isNullOrEmpty(this.state.brand.id)) {
            this.service.update(this.state.brand)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        this.props.save();
                    }
                });
        } else {
            this.service.create(this.state.brand)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        let brand = this.state.brand;
                        brand.id = response.id;
                        this.setState({brand: brand});
                        this.props.save();
                    }
                });
        }
    }

    setData = (data) => {
        this.setState({brand: data});
        // this.refForm.reset();
    }

    clearData = () => {
        let obj = Object.assign({}, defaultBrand);
        this.setState({brand: obj});
        // this.refForm.reset();
    }


    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let brand = this.state.brand;
        brand[e.target.name] = value;
        this.setState({brand: brand});
    }
}

BrandSave.propTypes = {
    brand: PropTypes.object
};
