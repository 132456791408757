import Service from './Service';
import GeneralUtils from "../utils/GeneralUtils";
import {SERVER_URL} from "../utils/Constants";

export default class IncidentService extends Service{
    constructor() {
        super();
        this.baseURL = 'incident';
    }

    findAllFilteredForDashboard(name) {
        return fetch(SERVER_URL + this.baseURL + "/findAllFilteredForDashboard/" + name + "/"+ GeneralUtils.getContactId(), {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    findByOrgIdAndLocId(organizationId, locationId) {
        return fetch(SERVER_URL + this.baseURL + '/findByOrgIdAndLocId/' + organizationId + '/' + locationId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        })
            .then(response => response.json())
            .catch(error => {
                GeneralUtils.notification(error);
            });
    }

    findByOrgIdAndLocIdAndExcludeIncidentIdOfOwn(organizationId, locationId , incidentId) {
        return fetch(SERVER_URL + this.baseURL + '/findByOrgIdAndLocIdAndExcludeIncidentIdOfOwn/' + organizationId + '/' + locationId + '/' + incidentId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        })
            .then(response => response.json())
            .catch(error => {
                GeneralUtils.notification(error);
            });
    }

    findByOrgIdAndForIncidentHistoryReport(organizationId, locationId) {
        let locationIdParam='';
        if(!GeneralUtils.isNullOrEmpty(locationId)){
            locationIdParam = '/'+locationId;
        }
        return fetch(SERVER_URL + this.baseURL + '/findByOrgIdAndForIncidentHistoryReport/' + organizationId + locationIdParam, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        })
            .then(response => response.json())
            .catch(error => {
                GeneralUtils.notification(error);
            });
    }

    trigger(data) {
        return fetch(SERVER_URL + this.baseURL+'/trigger', {
            crossDomain: true,
            method: 'put',
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")},
            body: JSON.stringify(data)
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response);
            return result.json();
    }).catch(error => {
            GeneralUtils.notification(error);
    });
    }

    guestSaveIncident(data) {
        return fetch(SERVER_URL + this.baseURL + '/guestSaveIncident', {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")},
            body: JSON.stringify(data)
        }).then(response => {
            let result = response.clone();
        // GeneralUtils.notification(response);
        return result.json();
    }).catch(error => {
            GeneralUtils.notification(error);
    });
    }

    createIncident(data) {
        return fetch(SERVER_URL + this.baseURL, {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")},
            body: JSON.stringify(data)
        }).then(response => {
            let result = response.clone();
        //GeneralUtils.notification(response);
        return result.json();
    }).catch(error => {
            GeneralUtils.notification(error);
    });
    }

    findAllFiltered(name, contactId) {
        return fetch(SERVER_URL + this.baseURL + '/findAllFiltered/' + name + "/" + contactId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
    });
    }

}