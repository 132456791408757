import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class ContactService extends Service{
    constructor() {
        super();
        this.baseURL = 'contact';
    }

    hsmUserQuickSave(data) {
        return fetch(SERVER_URL + this.baseURL + '/hsmUserQuickSave', {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")},
            body: JSON.stringify(data)
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response);
            return result.json();
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }
    findNotUsedInHsmUser() {
        return fetch(SERVER_URL + this.baseURL+ '/findNotUsedInHsmUser', {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    findNotUsedInTeamContact(teamId) {
        return fetch(SERVER_URL + this.baseURL+ '/findNotUsedInTeamContact/'+teamId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    findContacts(requestId, data) {
        return fetch(SERVER_URL + this.baseURL+'/findContacts/'+requestId, {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    findContactsInSameTeamByTeamIdAndContactId(parameter) {
        return fetch(SERVER_URL + this.baseURL+ '/findContactsInSameTeamByTeamIdAndContactId/'+parameter.teamId+ '/' +parameter.contactId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    findContactsInSameOrganization(organizationId,transferOrganizationId) {
        return fetch(SERVER_URL + this.baseURL+ '/findContactsInSameOrganization/'+organizationId+'/'+transferOrganizationId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
    });
    }

    findAllContacts() {
        return fetch(SERVER_URL + this.baseURL + '/findAllContacts', {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
    });
    }

    findContactsInOrganizationPlusGuestAndReportedBy(parameter) {
        if(GeneralUtils.isObject(parameter.reportedByContactId)){
            parameter.reportedByContactId = parameter.reportedByContactId.id;
        }
        if(GeneralUtils.isObject(parameter.organizationId)){
            parameter.organizationId = parameter.organizationId.id;
        }
        return fetch(SERVER_URL + this.baseURL+ '/findContactsInOrganizationPlusGuestAndReportedBy/'+parameter.reportedByContactId+ '/' +parameter.organizationId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
    });
    }

    findContactsByOrganizationId(organizationId) {
        return fetch(SERVER_URL + this.baseURL+ '/findContactsByOrganizationId/'+organizationId , {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    findByOrgIdAndCityIdAndRolId(organizationId,organizationCityId) {
        return fetch(SERVER_URL + this.baseURL+ '/findByOrgIdAndCityIdAndRolId/'+organizationId+'/'+organizationCityId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    setEmailNotification(data) {
        return fetch(SERVER_URL + this.baseURL + '/setEmailNotification', {
            crossDomain: true,
            method: 'put',
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")},
            body: JSON.stringify(data)
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response);
            return result.json();
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }
}
