import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class LogLoginService extends Service{
    constructor() {
        super();
        this.baseURL = 'logLogin';
    }

    lastLoginByUserIdAndEventType(userId, eventType) {
        return fetch(SERVER_URL + this.baseURL+ '/lastLoginByUserIdAndEventType/' + userId + '/'+eventType,  {
            headers: { Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            // GeneralUtils.notification(error);
    });
    }


}