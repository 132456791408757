import React from 'react';
import DataTableAll from '../../../../shared/component/dataTable/DataTableAll';
import HsmNetworkInterfaceService from "../../../../shared/service/HsmNetworkInterfaceService";
import HsmNetworkInterfaceColumn from '../../../../shared/component/dataTable/columns/HsmNetworkInterfaceColumn';
import HsmNetworkInterfaceSave from "./HsmNetworkInterfaceSave";
import Translate from "../../../../shared/language/Translate";
import {Button} from 'reactstrap';
import {PAGE_MODE} from "../../../../shared/utils/Constants";
import Toolbar from "../../../../shared/component/toolbar/Toolbar";
import GeneralUtils from "../../../../shared/utils/GeneralUtils";
import Dialog from "../../../../shared/component/dialog/Dialog";

export default class HsmNetworkInterface extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            networkInterfaceList: [],
            networkInterfaceRemovedList: [],
            pageMode: null
        };
        this.service = new HsmNetworkInterfaceService();
    }

    render() {
        return (
            <div>
                {this.state.displayButton !== false &&
                <Toolbar display={GeneralUtils.checkPrivilege(HsmNetworkInterfaceColumn.name, 'Create')}>
                    <div className="p-toolbar-group-right">
                        <Button color="primary" onClick={this.addNew}>
                            <Translate contentKey="entity.action.add">Ağ Adaptörü Ekle</Translate>
                        </Button>
                    </div>
                </Toolbar>
                }
                <DataTableAll ref={(elem) => this.refDataTable = elem} fields={HsmNetworkInterfaceColumn.fields} data={this.state.networkInterfaceList}
                           objectName={HsmNetworkInterfaceColumn.name}
                           selectionMode="single" onDataSelect={this.onDataSelect}/>
                <Dialog visible={this.state.displayDialog}
                        header={GeneralUtils.getDialogHeader('Ağ Adaptörü ', this.state.pageMode)} modal={true}
                        onHide={() => this.setState({displayDialog: false})} style={{width: '50vw'}}>
                    <HsmNetworkInterfaceSave
                        ref={(elem) => this.refHsmNetworkInterfaceSave = elem}
                        closeDisplayDialog={this.closeDisplayDialog}
                        refresh={this.refresh}
                        add={this.getListItem} delete={this.deleteListItem}
                        checkName={this.checkName}
                                             // checkPurpose={this.checkPurpose}
                                             // checkRecordExists={this.checkRecordExists}
                    />
                </Dialog>
            </div>
        );
    }

    addNew = () => {
        this.setState({displayDialog: true, pageMode: PAGE_MODE.CREATE, activeIndex: 0});
        this.refHsmNetworkInterfaceSave.setAssetIdAndPageMode(this.state.assetId, PAGE_MODE.CREATE);
    }

    refresh = () =>{
        this.refDataTable.setParameter(this.state.assetId);
        this.setState({displayDialog: false});
    }

    setData = (data, pageMode, displayButton) => {
        this.clearData();
        this.service.findByAssetId(data.id).then(response => {
            this.setState({networkInterfaceList: response ? response : [], pageMode: pageMode, assetId: data.id, displayButton: displayButton})
        });
    }

    getData = () =>{
        return this.state.networkInterfaceList;
    }

    getDataRemoved = () => {
        return this.state.networkInterfaceRemovedList;
    }

    clearData = () => {
        this.setState({
            networkInterfaceList: [],
            networkInterfaceRemovedList:[],
            pageMode: null,
            assetId: null
        });
    }

    onDataSelect = (data) => {
        let pageMode = GeneralUtils.getPageModeFromPrivilege(HsmNetworkInterfaceColumn.name);
        this.setState({displayDialog: true, pageMode: pageMode});
        this.refHsmNetworkInterfaceSave.clearData();
        this.refHsmNetworkInterfaceSave.setData(Object.assign({}, data), pageMode, this.state.displayButton);
    }

    closeDisplayDialog = () => {
        this.setState({displayDialog: false});
    }

    setPageMode = (pageMode) => {
        this.setState({pageMode: pageMode});
    }

    getListItem = (data) => {
        let networkInterfaceList = this.arrayRemove(this.state.networkInterfaceList, data);
        networkInterfaceList.push(data);
        this.setState({networkInterfaceList: networkInterfaceList})
    }

    deleteListItem = (data) => {
        let networkInterfaceRemovedList = this.state.networkInterfaceRemovedList;
        networkInterfaceRemovedList.push(data);
        let networkInterfaceList = this.arrayRemove(this.state.networkInterfaceList, data);
        this.setState({networkInterfaceList: networkInterfaceList, networkInterfaceRemovedList: networkInterfaceRemovedList})
    }

    arrayRemove = (arr, element) => {
        return arr.filter(function (el) {
            return el.name!== element.name;
        });
    }

    checkName = (selectedName) => {
        let networkInterfaceList = this.state.networkInterfaceList;
        for (let i = 0; i < networkInterfaceList.length; i++) {
            if (networkInterfaceList[i].name === selectedName) {
                return true;
            }
        }
        return false;
    }

    // checkPurpose = (selectedPurpose) => {
    //     let networkInterfaces = this.state.networkInterface;
    //     for (let i = 0; i < networkInterfaces.length; i++) {
    //         if (networkInterfaces[i].purpose === selectedPurpose) {
    //             return true;
    //         }
    //     }
    //     return false;
    // }
    //
    //
    // checkRecordExists = (id) => {
    //     let networkInterfaces = this.state.networkInterface;
    //     for (let i = 0; i < networkInterfaces.length; i++) {
    //         if (networkInterfaces[i].id === id) {
    //             return true;
    //         }
    //     }
    //     return false;
    // }
}