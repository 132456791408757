import React, { Component } from 'react'
import {AvForm} from "availity-reactstrap-validation";
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import {Button, Col, Label, Row} from "reactstrap";
import Translate from "../../../shared/language/Translate";
import {Calendar} from "primereact/calendar";
import DataTable from "../../../shared/component/dataTable/DataTable";
import ServiceRequestReportColumn from "../../../shared/component/dataTable/columns/ServiceRequestReportColumn";
import {Panel} from "primereact/panel";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import {toast} from "react-toastify";
import {MultiSelect} from "primereact/multiselect";
import ServiceSubcategoryService from "../../../shared/service/ServiceSubcategoryService";
import ServiceRequestReportService from "../../../shared/service/ServiceRequestReportService";

export default class ServiceRequestReport extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            selected: {},
            pageMode: null,
            loading: false,
            dateStart: null,
            dateEnd: null,
            dateStartDate:null,
            dateEndDate:null,
            serviceSubcategoryListForMultiple:[],
            serviceSubcategoryList:[],
            serviceCategoryId: 1,
        };
        this.service = new ServiceRequestReportService();
        this.serviceSubategoryService = new ServiceSubcategoryService();

    }

    componentDidMount = () => {
        this.serviceSubategoryService.findServiceRequestSubCategories(this.state.serviceCategoryId).then(response => {
            let serviceSubategoryList = this.checkSubcategoryAccess(response);
            this.setState({serviceSubcategoryListForMultiple: serviceSubategoryList});
        });
    }

  render() {

      const tr = {
          firstDayOfWeek: 1,
          monthNames: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
          monthNamesShort: ['Oca', 'Şub', 'Mar', 'Nis', 'May', 'Haz', 'Tem', 'Ağu', 'Eyl', 'Eki', 'Kas', 'Ara'],
          dayNames: ['Pazartesi,', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi', 'Pazar'],
          dayNamesShort: ['Pzt', 'Sal', 'Çar', 'Prş', 'Cm', 'Cmr', 'Paz'],
          dayNamesMin: ['P', 'S', 'Ç', 'P', 'C', 'Ct', 'Pz'],
          today: ['Bugün'],
          clear: ['Temizle']
      };

    return (
        <Panel header="Hizmet Talepleri Raporu">
            <AvForm onValidSubmit={this.submit}>
                <Toolbar display={true}>
                    <div className="p-toolbar-group-right ">
                        <Button color="primary" type="submit" className="float-right">
                            <Translate>Göster</Translate>
                        </Button>
                    </div>
                </Toolbar>
                <div className="container-fluid">
                    <h3>Filtre</h3>
                    <div className="row">
                        <div className="col-4" style={{padding: 0}}>
                            <div style={{width: 300}}>
                                <div className='p-grid p-fluid '>
                                    <Calendar
                                        tooltip="Başlangıç Tarihi"
                                        required={true}
                                        style={{margin: 20}}
                                        placeholder="Başlangıç Tarihi Seç"
                                        value={this.state.dateStart}
                                        onChange={ e => this._onChangeDateStart(e)}
                                        dateFormat='dd/mm/yy'
                                        showButtonBar={true}
                                        readOnlyInput={true}
                                        showIcon={true}
                                        locale={tr}
                                        onClearButtonClick={e => this.setState({
                                            dateStart:"",
                                        })
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-4" style={{padding: 0}}>
                            <div style={{width: 300}}>
                                <div className='p-grid p-fluid '>
                                    <Calendar
                                        tooltip="Bitiş Tarihi"
                                        required={true}
                                        style={{margin: 20}}
                                        placeholder="Bitiş Tarihi Seç"
                                        value={this.state.dateEnd}
                                        onChange={ e=> this._onChangeDateEnd(e)}
                                        dateFormat='dd/mm/yy'
                                        showButtonBar={true}
                                        readOnlyInput={true}
                                        showIcon={true}
                                        locale={tr}
                                        onClearButtonClick={e => this.setState({
                                            dateEnd:"",
                                        })
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-4" className="position-relative form-group">
                            <div style={{width: 300}}>
                                <div className='p-grid p-fluid '>
                                    <Label for="servedLocationList">
                                        Alt Kategori
                                    </Label>
                                    <MultiSelect optionLabel="name" dataKey="id" value={this.state.serviceSubcategoryList}
                                                 options={this.state.serviceSubcategoryListForMultiple}
                                                 onChange={(e) => {
                                                     console.log(e)
                                                     this.setState({serviceSubcategoryList: e.value})
                                                 }}
                                                 filter={true}
                                                 disabled={false}
                                                 selectedItemTemplate={this.serviceSubcategoryListTemplate}
                                                 selectedItemsLabel="{0} Kayıt Seçildi"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </AvForm>


            <DataTable ref={(elem) => this.refDataTable = elem} fields={ServiceRequestReportColumn.fields} dataURL="serviceRequestReport/findServiceRequestReportByStartDateAndEndDateEndSubCategory"
                       objectName={ServiceRequestReportColumn.name} loading={this.state.loading} export={true} autoLoad={false} refresh={false}/>
        </Panel>
    )
  }

    submit = () => {
        this.setState({loading: true});
        let dateStart = this.state.dateStart;
        let dateEnd = this.state.dateEnd;
        let dateStartDate = this.state.dateStartDate;
        let dateEndDate = this.state.dateEndDate;
        let now = Date.now();

        if ((!GeneralUtils.isNullOrEmpty(dateStartDate) && !GeneralUtils.isNullOrEmpty(dateEndDate))) {
            if ((dateStartDate > now || dateEndDate > now)) {
                toast.warn("Başlangıç ve/veya Bitiş Tarihi Bugünün Tarihinde İleri Olamaz.");
                this.setState({loading: false});
                return;
            }
            if (dateStartDate > dateEndDate ) {
                toast.warn("Başlangıç Tarihi Bitiş Tarihinden Sonra Olamaz.");
                this.setState({loading: false});
                return;
            }
        }

        if (GeneralUtils.isNullOrEmpty(dateStartDate) || GeneralUtils.isNullOrEmpty(dateEndDate)) {
            toast.warn("Lütfen Başlangıç ve Bitiş Tarihi Alanlarını Doldurunuz.");
            this.setState({loading: false});
            return;
        }

        let serviceSubCategoryIds = this.getServiceSubCategoryIds();

        this.refDataTable.setParameter(dateStart,dateEnd,serviceSubCategoryIds);
    }

    _onChangeDateStart = (value) => {
        let fullDate = this.getDate(value.value);

        this.setState({
            dateStart:fullDate,dateStartDate:value.value
        });
    }

    _onChangeDateEnd = (value) => {
        let fullDate = this.getDate(value.value);

        this.setState({
            dateEnd:fullDate,dateEndDate:value.value
        });
    }

    getDate(dateValue){
        let date = new Date(dateValue);
        let day = date.getDate();
        let month = date.getMonth()+1;
        let year = date.getFullYear();

        return day + "-" + month + "-" + year;
    }

    SetSubcategoryWithoutAccess = (serviceSubcategoryList) => {
        let subcategoryList = [];
        serviceSubcategoryList.forEach((element) => {
            subcategoryList.push(element);
        });
        return subcategoryList;
    }

    serviceSubcategoryListTemplate = (option) => {
        if (option) {
            return (
                <div className="my-multiselected-item-token">
                    <span>{option.name}</span>
                </div>
            );
        } else {
            return <span>&nbsp;</span>
        }
    }

    getServiceSubCategoryIds = () => {
        let serviceSubCategoryIds=[];
        let serviceSubCategoryList = this.state.serviceSubcategoryList;
        serviceSubCategoryList.forEach((element=>{
            serviceSubCategoryIds.push(element.id);
        }))

        return serviceSubCategoryIds;
    }

    checkSubcategoryAccess = (serviceSubcategoryList) => {
        let subcategoryList = [];
        serviceSubcategoryList.forEach((element) => {
            if (GeneralUtils.checkPrivilege('SubcategoryAccess', new String(element.id)) && (element.status=='A') ) {
                subcategoryList.push(element);
            }
        });
        return subcategoryList;
    }
}
