import React from 'react';
import {Button, Label} from 'reactstrap';
import {AvGroup} from 'availity-reactstrap-validation';
import AvField from '../../../shared/component/form/AvField';
import PropTypes from 'prop-types';
import Translate from "../../../shared/language/Translate";
import GeneralUtils from '../../../shared/utils/GeneralUtils';
import HsmOrganizationService from "../../../shared/service/HsmOrganizationService";
import DistrictService from "../../../shared/service/DistrictService";
import CityService from "../../../shared/service/CityService";
import HsmOrganizationTypeService from "../../../shared/service/HsmOrganizationTypeService";
import {ORGANIZATION_ROLE, ORGANIZATION_SECTOR, STATUS} from '../../../shared/utils/Constants';
import {Fieldset} from "primereact/fieldset";
import {PhoneField} from "../../../shared/component/form/PhoneField";
import Dialog from "../../../shared/component/dialog/Dialog";
import DataTable from "../../../shared/component/dataTable/DataTable";
import HsmOrganizationColumnForDialog from "../../../shared/component/dataTable/columns/HsmOrganizationColumnForDialog";
import AvFieldUpper from "../../../shared/component/form/AvFieldUpper";

const defaultHsmOrganization = {
    id: '',
    name: '',
    parentOrganizationId: '',
    cityId: 1,
    districtId: '',
    status: 'A',
    code: '',
    nameIsDisabled: false,
    statusIsDisabled: false,
    email: '',
    businessPhone: '',
    hsmOrganizationType: null,
    description: '',
    role: '',
    taxNumber: '',
    taxOffice: '',
    commercialRegistrationNumber: '',
    sector: ''
};

export default class HsmOrganizationView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hsmOrganization: defaultHsmOrganization,
            parentOrganizationData: [],
            cityList: [],
            hsmOrganizationTypeList: [],
            districtList: [],
            pageMode: null
        }
        this.service = new HsmOrganizationService();
        this.hsmOrganizationService = new HsmOrganizationService();
        this.cityService = new CityService();
        this.districtService = new DistrictService();
        this.hsmOrganizationTypeService = new HsmOrganizationTypeService();
    }

    componentDidMount = () => {
        this.cityService.findAll().then(response => this.setState({cityList: response}));
        this.hsmOrganizationTypeService.findAll().then(response => this.setState({hsmOrganizationTypeList: response}));
        this.districtService.findByCityId(this.state.hsmOrganization.cityId).then(response => {
            let hsmOrganization = this.state.hsmOrganization;
            hsmOrganization.districtId = response[0].id;
            this.setState({districtList: response, hsmOrganization: hsmOrganization});
        });
    }

    render() {
        return (
            <div>

                <div className="container">
                    <div className="row">
                        <div className="col">
                            <AvGroup>
                                <Label for="code">
                                    <Translate contentKey="hsmOrganizationManagement.code">Organizasyon Kodu</Translate>
                                    <span style={{color: 'red'}}>(*)</span>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="code"
                                    value={this.state.hsmOrganization.code}
                                    disabled={true}
                                />
                            </AvGroup>
                            <AvGroup>
                                <Label for="name">
                                    <Translate contentKey="hsmOrganizationManagement.name">Organizasyon Adı</Translate>
                                    <span style={{color: 'red'}}>(*)</span>
                                </Label>
                                <AvFieldUpper
                                    type="text"
                                    className="form-control"
                                    name="name"
                                    disabled={true}
                                    value={this.state.hsmOrganization.name}/>
                            </AvGroup>

                            <Fieldset legend="Bağlı Olduğu Organizasyon Bilgileri">
                                <AvGroup>
                                    <Label for="parentOrganizationId">
                                        <Translate contentKey="asset-management.parentOrganizationId">Bağlı Olduğu
                                            Organizasyon Adı</Translate>
                                        <span style={{color: 'red'}}>(*)</span>
                                    </Label>
                                    <div style={{position: "relative"}}>
                                        <AvField type="text" className="form-control" name="parentOrganizationId"
                                                 disabled
                                                 value={this.state.hsmOrganization.parentOrganizationName}/>{' '}
                                        {false &&
                                        <Button color="primary" onClick={this.findAllOrganization}
                                                className="pi pi-search selectTableButton" size="sm"/>}
                                    </div>
                                </AvGroup>
                            </Fieldset>

                            {/*<Fieldset legend="Bağlı Olduğu Organizasyon Bilgileri">*/}
                            {/*    <OrganizationGroup*/}
                            {/*        name="parentOrganizationId"*/}
                            {/*        label="Bağlı Olduğu Organizasyon Adı"*/}
                            {/*        value={this.state.hsmOrganization.parentOrganizationName}*/}
                            {/*        onDataSelect={this.onOrganizationSelect}*/}
                            {/*        disabled={this.state.pageMode === PAGE_MODE.VIEW}*/}
                            {/*        validate={this.state.pageMode === PAGE_MODE.VIEW ? {} : {*/}
                            {/*            required: {*/}
                            {/*                value: true,*/}
                            {/*                errorMessage: "Bu alanın doldurulması zorunludur."*/}
                            {/*            }*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*</Fieldset>*/}
                            <br/>
                            <AvGroup>
                                <Label for="cityId">
                                    <Translate contentKey="hsmOrganizationManagement.cityId">İl Adı</Translate>
                                    <span style={{color: 'red'}}>(*)</span>
                                </Label>
                                <AvField type="select" className="form-control" name="cityId"
                                         value={this.state.hsmOrganization.cityId}
                                         disabled={true}>
                                    {this.state.cityList.map(element => (
                                        <option value={element.id} key={element.id}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>
                            <AvGroup>
                                <Label for="districtId">
                                    <Translate contentKey="hsmOrganizationManagement.districtId">İlçe Adı</Translate>
                                    <span style={{color: 'red'}}>(*)</span>
                                </Label>
                                <AvField type="select" className="form-control" name="districtId"
                                         value={GeneralUtils.isNullOrEmpty(this.state.hsmOrganization.districtId) ? "" : this.state.hsmOrganization.districtId}
                                         disabled={true}>
                                    <option value='' key=''/>
                                    {this.state.districtList.map(element => (
                                        <option value={element.id} key={element.id}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>
                            <AvGroup>
                                <Label for="status">
                                    <Translate contentKey="hsmOrganizationManagement.status">Durum</Translate>
                                    <span style={{color: 'red'}}>(*)</span>
                                </Label>
                                <AvField type="select" className="form-control" name="status"
                                         disabled={true}
                                         value={this.state.hsmOrganization.status}>
                                    {STATUS.map(element => (
                                        <option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>
                            <AvGroup>
                                <Label for="email">
                                    <Translate contentKey="hsmOrganizationManagement.email">E-Posta</Translate>
                                    <span style={{color: 'red'}}>(*)</span>
                                </Label>
                                <AvField
                                    name="email"
                                    type="text"
                                    value={this.state.hsmOrganization.email}
                                    disabled={true}/>
                            </AvGroup>
                            <AvGroup>
                                <Label for="businessPhone">
                                    <Translate contentKey="hsmOrganizationManagement.businessPhone">İş
                                        Telefonu</Translate>
                                </Label>
                                <PhoneField kind={'business'}
                                            name="businessPhone" errorMessage="Geçersiz Numara Girişi"
                                            value={this.state.hsmOrganization.businessPhone}
                                            disabled={true}
                                />
                            </AvGroup>
                        </div>
                        <div className="col">
                            <AvGroup>

                                <Label for="hsmOrganizationType">
                                    <Translate contentKey="hsmOrganizationManagement.hsmOrganizationType">Organizasyon
                                        Tipi</Translate>
                                </Label>
                                <AvField type="select" className="form-control" name="hsmOrganizationType"
                                         value={this.state.hsmOrganization.hsmOrganizationType}
                                         disabled={true}>
                                    <option value='' key=''/>
                                    {this.state.hsmOrganizationTypeList.map(element => (
                                        <option value={element.id} key={element.id}>
                                            {element.name}
                                        </option>
                                    ))}

                                </AvField>
                            </AvGroup>


                            <AvGroup>
                                <Label for="description">
                                    <Translate
                                        contentKey="hsmOrganizationManagement.description">Açıklama</Translate>
                                </Label>
                                <AvField
                                    type="textarea"
                                    className="form-control textAreaHermes"
                                    name="description"
                                    value={this.state.hsmOrganization.description}
                                    disabled={true}
                                />
                            </AvGroup>
                            <AvGroup>
                                <Label for="role">
                                    <Translate contentKey="hsmOrganizationManagement.role">Rol</Translate>
                                    <span style={{color: 'red'}}>(*)</span>
                                </Label>
                                <AvField type="select" className="form-control" name="role"
                                         value={this.state.hsmOrganization.role}
                                         disabled={true}>
                                    <option value='' key=''/>
                                    {ORGANIZATION_ROLE.map(element => (
                                        <option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>
                            <AvGroup>
                                <Label for="sector">
                                    <Translate contentKey="hsmOrganizationManagement.sector">Sektör</Translate>
                                    <span style={{color: 'red'}}>(*)</span>
                                </Label>
                                <AvField type="select" className="form-control" name="sector"
                                         value={this.state.hsmOrganization.sector}
                                         disabled={true}>
                                    <option value='' key=''/>
                                    {ORGANIZATION_SECTOR.map(element => (
                                        <option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>
                            <Fieldset legend="Organizasyon Ticari Bilgileri">
                                <AvGroup>
                                    <Label for="taxNumber">
                                        <Translate contentKey="hsmOrganizationManagement.taxNumber">Vergi
                                            Numarası</Translate>
                                    </Label>
                                    <AvField
                                        type="number"
                                        min="1000000000"
                                        max="9999999999"
                                        className="form-control"
                                        name="taxNumber"
                                        disabled={true}
                                        value={this.state.hsmOrganization.taxNumber}
                                    />
                                </AvGroup>
                                <AvGroup>
                                    <Label for="taxOffice">
                                        <Translate contentKey="hsmOrganizationManagement.taxOffice">Vergi Dairesi
                                            Adı</Translate>
                                    </Label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="taxOffice"
                                        disabled={true}
                                        value={this.state.hsmOrganization.taxOffice}
                                    />
                                </AvGroup>
                                <AvGroup>
                                    <Label for="commercialRegistrationNumber">
                                        <Translate contentKey="hsmOrganizationManagement.commercialRegistrationNumber">Ticari
                                            Sicil Numarası</Translate>
                                    </Label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="commercialRegistrationNumber"
                                        value={this.state.hsmOrganization.commercialRegistrationNumber}
                                        disabled={true}
                                    />
                                </AvGroup>
                            </Fieldset>
                        </div>
                    </div>
                </div>
                <Dialog id="OrganizationDialog" key="OrganizationDialogKey" visible={this.state.displayDialog}
                        header="Bağlı Olduğu Organizasyon Listesi" onHide={() => this.setState({displayDialog: false})}
                        style={{width: '90%'}}>
                    <DataTable fields={HsmOrganizationColumnForDialog.fields} privilege="allow"
                               data={this.state.parentOrganizationData}
                               selectionMode="single" onDataSelect={this.onOrganizationSelect}/>
                </Dialog>
            </div>
        );
    }

    findAllOrganization = () => {
        this.hsmOrganizationService.findAll().then(response => this.setState({
            displayDialog: true,
            parentOrganizationData: response
        }));
    }

    setData = (data, pageMode) => {
        data.parentOrganizationName = data.parentOrganizationId ? data.parentOrganizationId.name : null;
        data.hsmOrganizationType = data.hsmOrganizationType ? data.hsmOrganizationType.id : null;
        data.cityId = data.cityId ? data.cityId.id : null;
        data.districtId = data.districtId ? data.districtId.id : null;
        this.setState({
            hsmOrganization: data,
            pageMode: pageMode
        });
        this.findDistrictByCityId(data.cityId, data.districtId);
    }

    getData = () => {
        let hsmOrganization = this.state.hsmOrganization;
        hsmOrganization.cityId = {id: this.state.hsmOrganization.cityId};
        hsmOrganization.districtId = {id: this.state.hsmOrganization.districtId};
        hsmOrganization.hsmOrganizationType = !GeneralUtils.isNullOrEmpty(hsmOrganization.hsmOrganizationType) ? {id: hsmOrganization.hsmOrganizationType} : null;
        return hsmOrganization;
    }

    clearData = () => {
        this.setState({
            hsmOrganization: Object.assign({}, defaultHsmOrganization),
            pageMode: null
        });
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let hsmOrganization = this.state.hsmOrganization;
        hsmOrganization[e.target.name] = value;
        this.setState({hsmOrganization: hsmOrganization});
    }

    __handleChangeCity = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let hsmOrganization = this.state.hsmOrganization;
        hsmOrganization[e.target.name] = value;
        // this.findDistrictByCityId(value);
    }

    findDistrictByCityId = (cityId, districtId) => {
        this.districtService.findByCityId(cityId).then(response => {
            let districtList = response;
            let hsmOrganization = this.state.hsmOrganization;
            hsmOrganization.cityId = cityId;
            if (districtId) {
                hsmOrganization.districtId = districtId;
            } else {
                hsmOrganization.districtId = "";
                districtList = [];
            }
            this.setState({districtList, hsmOrganization: hsmOrganization});
        });
    }

    onOrganizationSelect = (data) => {
        let hsmOrganization = this.state.hsmOrganization;
        hsmOrganization.parentOrganizationId = data.id;
        hsmOrganization.parentOrganizationName = data.name;
        this.setState({hsmOrganization: hsmOrganization, displayDialog: false});
    }

    setPageMode = (pageMode) => {
        this.setState({pageMode: pageMode});
    }
}

HsmOrganizationView.propTypes = {
    hsmOrganization: PropTypes.object
};




