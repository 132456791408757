import React from 'react';
import {AvGroup} from 'availity-reactstrap-validation';
import {PAGE_MODE, TASK_NAME, TASK_STATUS} from "../../../shared/utils/Constants";
import AvField from "../../../shared/component/form/AvField";
import {Col, Label, Row} from "reactstrap";
import Translate from "../../../shared/language/Translate";
import DateInput from "../../../shared/component/form/DateInput";
import ContactGroup from "../../../shared/component/hsmGroup/ContactGroup";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import ContactColumnForDialog from "../../../shared/component/dataTable/columns/ContactColumnForDialog";
import ContactService from "../../../shared/service/ContactService";
import TeamGroup from "../../../shared/component/hsmGroup/TeamGroup";
import TaskService from "../../../shared/service/TaskService";

const defaultTask = {
    name: '',
    description: '',
    status: 'OPEN',
    plannedStartDate: null,
    plannedEndDate: null,
    completionDate: null,
    teamId: null,
    assignedTo: null,
    requestId: null,
    haveAnyRecord: null
}

class TaskSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            task: defaultTask
        }
        this.contactService = new ContactService();
        this.taskService = new TaskService();
    }


    render() {

        return (<div className="container">
            <div className="row">
                <div className="col">
                    {/*<AvGroup>*/}
                    {/*    <Label for="requestId">*/}
                    {/*        <Translate contentKey="taskManagement.requestId">İlgili Talep No</Translate>*/}
                    {/*    </Label>*/}
                    {/*    <AvField*/}
                    {/*        type="text"*/}
                    {/*        className="form-control"*/}
                    {/*        name="requestId"*/}
                    {/*        disabled={true}*/}
                    {/*        value={this.state.task.requestId}/>*/}
                    {/*</AvGroup>*/}

                    <Row>
                        <Col md={6}>
                            <AvGroup>
                                <Label for="name">
                                    <Translate contentKey="taskManagement.name">Görev Adı</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvField type="select" className="form-control" name="name"
                                         value={this.state.task.name} onChange={(e) => {
                                    this.__handleChangeTaskName(e)
                                }}
                                         validate={{
                                             required: {
                                                 value: true,
                                                 errorMessage: 'Bu alan zorunlu.'
                                             },
                                             minLength: {
                                                 value: 1,
                                                 errorMessage: 'Bu alan en az 1 karakterden oluşabilir.'
                                             },
                                             maxLength: {
                                                 value: 50,
                                                 errorMessage: 'Bu alan en fazla 50 karakterden oluşabilir.'
                                             }
                                         }}
                                         disabled={this.state.task.status === 'CLOSED' || this.state.pageMode === PAGE_MODE.VIEW || this.state.pageMode === PAGE_MODE.UPDATE}>
                                    <option value=""/>
                                    {TASK_NAME.map(element => (
                                        <option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>
                        </Col>
                        <Col md={6}>
                            <AvGroup>
                                <Label for="status">
                                    <Translate contentKey="taskManagement.status">Durum</Translate>
                                </Label>
                                <AvField type="select" className="form-control" name="status"
                                         value={this.state.task.status} onChange={this.__handleChangeStatus}
                                         disabled={this.state.task.status === 'CLOSED' || !(this.state.pageMode === PAGE_MODE.VIEW || this.state.pageMode === PAGE_MODE.UPDATE)}>
                                    {TASK_STATUS.map(element => (
                                        <option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>
                        </Col>
                    </Row>

                    <AvGroup>
                        <Label for="description">
                            <Translate contentKey="taskManagement.description">Açıklama</Translate>
                            <span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="textarea"
                            className="form-control textAreaHermes"
                            name="description"
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                },
                                maxLength: {
                                    value: 4000,
                                    errorMessage: 'Bu alan en fazla 4000 karakterden oluşabilir.'
                                }
                            }}
                            value={this.state.task.description}
                            onChange={this.__handleChange}
                            disabled={this.state.task.status === 'CLOSED'}
                        />
                    </AvGroup>

                    <div className="position-relative form-group">
                        <Row>
                            <Col>
                                <Label for="plannedStartDate">
                                    Planlanan Başlangıç Tarihi
                                </Label>
                                <DateInput
                                    name="plannedStartDate"
                                    onChange={this.__handleChange}
                                    value={this.state.task.plannedStartDate}
                                    disabled={this.state.task.status === 'CLOSED'}/>
                            </Col>
                            <Col>
                                <Label for="plannedEndDate">
                                    Planlanan Bitiş Tarihi
                                </Label>
                                <DateInput
                                    name="plannedEndDate"
                                    onChange={this.__handleChange}
                                    value={this.state.task.plannedEndDate}
                                    disabled={this.state.task.status === 'CLOSED'}/>
                            </Col>
                            {this.state.task.status === 'CLOSED' &&
                            <Col>
                                <Label for="completionDate">
                                    Tamamlanma Tarihi
                                </Label>
                                <DateInput
                                    name="completionDate"
                                    onChange={this.__handleChange}
                                    value={this.state.task.completionDate}
                                    disabled={true}/>
                            </Col>
                            }
                        </Row>
                    </div>
                    <Row>
                        <Col md={6}>
                            <TeamGroup
                                label="Atanan Ekip"
                                value={this.state.team}
                                onDataSelect={this.onTeamSelect}
                                disabled={this.state.task.status === 'CLOSED'}
                            />
                        </Col>
                        <Col md={6}>
                            <ContactGroup
                                value={this.state.assignedTo}
                                onDataSelect={this.onAssignedToSelect}
                                label="Atanan Kişi"
                                dataTable={{
                                    dataURL: "contact/findContactsInSameTeamByTeamId",
                                    parameter: (this.state.task.teamId ? this.state.task.teamId : 0),
                                    fields: ContactColumnForDialog.fields
                                }}
                                autoRefrestData={true}
                                disabled={this.state.task.status === 'CLOSED' || this.state.pageMode === PAGE_MODE.VIEW || GeneralUtils.isNullOrEmpty(this.state.task.teamId)}
                            />

                        </Col>
                    </Row>
                </div>
            </div>
        </div>);
    }


    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let task = this.state.task;
        task[e.target.name] = value;
        this.setState({task: task});
    }

    __handleChangeTaskName = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let task = this.state.task;
        task[e.target.name] = value;
        this.setState({task: task});
        this.props.onChangeDiscoveryDisabled(e.target.value);
    }
    __handleChangeStatus = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let task = this.state.task;
        task[e.target.name] = value;
        if (task.status === 'CLOSED') {
            task.completionDate = new Date();
        }
        this.setState({task: task});
    }

    getData = () => {
        return this.state.task;
    }

    setData = (data, pageMode) => {
        let assignedTo = data.assignedTo.givenNames + ' ' + data.assignedTo.familyName;
        let team = data.teamId ? data.teamId.name : null;
        data.teamId = data.teamId ? data.teamId.id : null;
        data.assignedTo = data.assignedTo ? data.assignedTo.id : null;
        this.setState({task: Object.assign({}, data), pageMode: pageMode, assignedTo: assignedTo, team: team});
    }

    clearData = () => {
        this.setState({task: Object.assign({}, defaultTask), pageMode: null, assignedTo: null, team: null});
    }

    onTeamSelect = (dataTemp) => {
        let task = this.state.task;
        task.teamId = dataTemp.id;
        task.assignedTo = null;
        let team = dataTemp.name;
        this.setState({task: task, team: team, assignedTo: null});
    }

    onAssignedToSelect = (dataTemp) => {
        let task = this.state.task;
        task.assignedTo = dataTemp.id;
        let assignedTo = dataTemp.givenNames + ' ' + dataTemp.familyName;
        this.setState({task: task, assignedTo: assignedTo});
    }

    haveAnyRecord = (requestType, requestId) => {
        this.taskService.haveAnyRecord(requestType, requestId).then(res => {
            this.setState({haveAnyRecord: res});
        });
    }

    setRequestTypeAndRequestId = (requestType, requestId) => {
        let task = Object.assign({}, defaultTask);
        task.requestType = requestType;
        task.requestId = requestId;
        this.setState({
            task
        });

    }

    changeStatus = (status) => {
        let task = this.state.task;
        task.status = status;
        this.setState({task: task});
    }

}

export default TaskSave;
