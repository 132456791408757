import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";
import Service from "./Service";

export default class IpPlanListReportService extends Service {
    constructor() {
        super();
        this.baseURL = 'ipPlanListReport';
    }

    findCrossTabIpListForLocType(bbProductLocationType) {
        return fetch(SERVER_URL + this.baseURL + '/findCrossTabIpListForLocType/' + bbProductLocationType , {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

}