import React from "react";
import PropTypes from 'prop-types';
import SelectDataTable from '../form/SelectDataTable';
import MailTemplateService from "../../service/MailTemplateService";
import MailTemplateColumn from "../../../shared/component/dataTable/columns/MailTemplateColumn";

export default class MailTemplateGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value,
            validate: this.props.validate,
            dataTable: this.props.dataTable,
            disabled: this.props.disabled,
            label : this.props.label
        };
        this.service = new MailTemplateService();
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({
            validate: nextProps.validate,
            value: nextProps.value,
            dataTable: nextProps.dataTable,
            label: nextProps.label,
            disabled: nextProps.disabled,
        });
    }

    render() {
        let validate = this.state.validate;
        if (!validate) {
            validate = {
                required: {
                    value: true,
                    errorMessage: "Bu alanın doldurulması zorunludur."
                }
            };
        }

        let label = this.state.label;
        if (!label) {
            label = "Mail Şablonu"
        }

        let dataTable = this.state.dataTable;
        if (!dataTable) {
            dataTable = {
                dataURL : "mailTemplate/findAll",
                fields: MailTemplateColumn.fields
            };
        }
        return (
            <SelectDataTable
                value={this.state.value}
                validate={validate}
                dataTable={dataTable}
                disabled={this.state.disabled}
                name="mailTemplate"
                translateKey="mailTemplate"
                label={label}
                dialogHeader="Mail Şablon Listesi"
                onDataSelect={this.props.onDataSelect}
                autoRefrestData={this.props.autoRefrestData ? this.props.autoRefrestData : false}
            />
        );
    }
}
MailTemplateGroup.propTypes = {
    value: PropTypes.string,
    validate: PropTypes.object,
    dataTable: PropTypes.object
}