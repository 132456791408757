import React from 'react';
import {Button, Row} from 'reactstrap';
import {Panel} from "primereact/panel";
import DataTable from "../../shared/component/dataTable/DataTable";
import CityColumn from "../../shared/component/dataTable/columns/CityColumn.json"
import Toolbar from "../../shared/component/toolbar/Toolbar";
import GeneralUtils from "../../shared/utils/GeneralUtils";
import Translate from "../../shared/language/Translate";
import Dialog from "../../shared/component/dialog/Dialog";
import Service from "../../shared/service/Service";
import {PAGE_MODE} from "../../shared/utils/Constants";
import CitySave from "./CitySave";
import {AvForm} from "availity-reactstrap-validation";



class City extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageMode: null
        };
        this.service = new Service('city');
    }

    render() {
        return (
            <Row className="justify-content-center">
                <Panel header="İl Tanımları" style={{width: '75%'}}>
                    <Toolbar display={true}>
                        <div className="p-toolbar-group-right">
                            <Button color="primary" onClick={this.addNew}>
                                <Translate contentKey="entity.action.add">Yeni Kayıt</Translate>
                            </Button>
                        </div>
                    </Toolbar>

                    <DataTable ref={(elem) => this.refDataTable = elem} fields={CityColumn.fields} dataURL="city/findAll"
                               privilege="allow"
                               selectionMode="single" onDataSelect={this.onDataSelect} export={true}/>

                    <Dialog visible={this.state.displayDialog}
                            header={GeneralUtils.getDialogHeader('İl', this.state.pageMode)}
                            onHide={() => this.setState({displayDialog: false})} style={{width: '50%'}}>
                        <AvForm onValidSubmit={this.save}>
                            <Toolbar
                                display={true}>
                                <div className="p-toolbar-group-right">
                                    <Button color="primary" type="submit" className="float-right">
                                        <Translate contentKey="entity.action.save">Kaydet</Translate>
                                    </Button>
                                    <Button color="primary" className="float-right"
                                            onClick={() => this.setState({displayDialog: false})}>
                                        <Translate contentKey="entity.action.save">Vazgeç</Translate>
                                    </Button></div>

                            </Toolbar>
                            <CitySave ref={elem => this.refCitySave = elem}/>
                        </AvForm>
                    </Dialog>
                </Panel>
            </Row>
        );
    }

    refresh = () => {
        this.refDataTable.refresh();
    }

    addNew = () => {
        this.setState({displayDialog: true, pageMode: PAGE_MODE.CREATE});
        this.refCitySave.clearData();
    }

    onDataSelect = (data) => {
        let pageMode = GeneralUtils.getPageModeFromPrivilege(CityColumn.name);
        this.setState({displayDialog: true, pageMode: pageMode});
        this.refCitySave.clearData();
        this.refCitySave.setData(Object.assign({}, data), pageMode);
    }

    save = () => {
        let city = this.refCitySave.getData();
        if (!GeneralUtils.isNullOrEmpty(city.id)) {
            this.service.update(city).then((response) => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    this.setState({displayDialog: false});
                    this.refresh();
                }
            });
        } else {
            this.service.create(city).then((response) => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    this.setState({displayDialog: false});
                    this.refresh();
                }
            });
        }
    }
}


export default City;
