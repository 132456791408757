import React from 'react';
import {Panel} from 'primereact/panel';
import DataTable from "../../../shared/component/dataTable/DataTable";
import {PAGE_MODE} from "../../../shared/utils/Constants";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import TtvpnDetailReportColumn from "../../../shared/component/dataTable/columns/TtvpnDetailReportColumn";
import TtvpnDetailReportService from "../../../shared/service/TtvpnDetailReportService";

export default class TtvpnDetailReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            selected: {},
            pageMode: null
        };
        this.service = new TtvpnDetailReportService();
    }


    componentDidMount =()=> {
        this.service.checkPage();
        this.findAll();
    }

    render() {
        return (
            <Panel header="Aylık TTVPN Ücret ve Ceza Bilgileri Detay Raporu">
                <DataTable ref={(elem) => this.refDataTable = elem} fields={TtvpnDetailReportColumn.fields} dataURL="ttvpnDetailReport/findTemosByMonth" objectName={TtvpnDetailReportColumn.name} loading={this.state.loading} export={true}
                           // selectionMode="single"
                    // onDataSelect={this.onDataSelect}
                           autoLoad={false}/>
                {/*<Dialog visible={this.state.displayDialog} header="Genişbant Ürün Görüntüleme" modal={true}*/}
                {/*resizable={true} maximizable={true} responsive={true}*/}
                {/*onHide={() => this.setState({displayDialog: false})} style={{width: '50vw'}}>*/}
                {/*<AvForm>*/}
                {/*<Toolbar>*/}
                {/*<div className="p-toolbar-group-right">*/}
                {/*<div>*/}
                {/*<Button color="primary" className="float-right"*/}
                {/*onClick={() => this.closeDisplayDialog()}>Vazgeç</Button>*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*</Toolbar>*/}
                {/*<TabView activeIndex={this.state.activeIndex}*/}
                {/*onTabChange={(e) => this.setState({activeIndex: e.index})} renderActiveOnly={false}>*/}
                {/*<TabPanel header="Genişbant Ürün" rightIcon="pi pi-user">*/}
                {/*<BbProductSave save={this.findAll} ref={(elem) => this.refBbProductSave = elem}*/}
                {/*closeDisplayDialog={this.closeDisplayDialog}/>*/}
                {/*</TabPanel>*/}
                {/*<TabPanel header="Tarihçe" rightIcon="pi pi-user"*/}
                {/*disabled={this.state.pageMode === PAGE_MODE.CREATE}>Tarihçe</TabPanel>*/}
                {/*</TabView>*/}
                {/*</AvForm>*/}
                {/*</Dialog>*/}
            </Panel>
        );
    }

    findAll = () => {
        this.setState({loading: true});
        let month = window.location.href.split('?')[1];
        if (month !== null) {
            let value = month.split('=')[1];
            if (!GeneralUtils.isNullOrEmpty(value)) {
                this.refDataTable.setParameter(value);
                // this.service.findTemosByMonth(value).then(response => {
                //     this.setState({data: response, loading: false})
                // })
            }
        }
    }

    onDataSelect = (data) => {
        this.setState({displayDialog: true, pageMode: PAGE_MODE.UPDATE, activeIndex: 0});
        this.refBbProductSave.setData(Object.assign({}, data), PAGE_MODE.UPDATE);
    }

    closeDisplayDialog = () => {
        this.setState({displayDialog: false});
    }
}
