import React from 'react';
import DashboardService from "../../shared/service/DashboardService";
import GeneralUtils from "../../shared/utils/GeneralUtils";
import {REQUEST_TYPE, TENANT_ID} from "../../shared/utils/Constants";
import {Fieldset} from "primereact/fieldset";
import ReactCountdownClock from "react-countdown-clock";
import {Row} from "reactstrap";
import ServiceSubcategoryService from "../../shared/service/ServiceSubcategoryService";
import CardChart from "../../shared/component/card/CardChart";
import CardUtils from "../../shared/component/card/CardUtils";
import TenantService from "../../shared/service/TenantService";
import Overview from "./Overview";


export default class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            serviceCategoryId: 1,
            serviceSubcategoryListForIncident: [],
            serviceSubcategoryListForServiceRequest: [],

            countOpenSR: 0,
            countMyOpenSR: 0,
            countMyTeamOpenSR: 0,
            countUnassignedOpenSR: 0,
            countAwatingApprovalSR: 0,
            countClosedSR: 0,

            countOpenI: 0,
            countMyOpenI: 0,
            countMyTeamOpenI: 0,
            countUnassignedOpenI: 0,
            countAwatingApprovalI: 0,
            countWaitingConfirmationI: 0,
            countClosedI: 0,

            countOpenBB: 0,
            countUninstalledBB: 0,
            countInstalledBB: 0,
            countSuspendedBB: 0,
            countActiveBB: 0,

            countForSubCategoryMap: new Map(),
            countForIncidentSubCategoryMap: new Map()
        };
        this.dashboardService = new DashboardService();
        this.serviceSubategoryService = new ServiceSubcategoryService();
        this.tenantService = new TenantService();
    }

    componentDidMount = () => {
        this.dashboardService.checkPage();
        this.refresh();
        this.tenantService.getTenant().then(result => {
            if(!GeneralUtils.isNullOrEmpty(result)){
                this.setState({tenantId: result});
            }
        });
    }

    render() {
        return (
            <div className="dashboard">

                {GeneralUtils.checkPrivilege('Dashboard', 'View') &&
                <div>
                    <Row className="justify-content-center">
                        {this.state.countDown != false &&
                        <ReactCountdownClock seconds={60}
                                             color="#1a1a1a"
                                             alpha={0.9}
                                             size={50}
                                             onComplete={this.countDownClock}/>
                        }
                    </Row>

                    <Overview ref={(elem) => this.refOverview = elem}/>


                    {GeneralUtils.checkPrivilege('Incident', 'ViewList') &&
                    <Fieldset legend="Arızalar" toggleable={true} collapsed={this.state.panelCollapsedI}
                              onToggle={(e) => this.setState({panelCollapsedI: e.value})}>
                        <div className="p-grid p-fluid dashboard">
                            <CardChart
                                name="Tüm Arızalar"
                                labels={['AÇIK', 'BENDE', 'EKİPLERİMDE', 'ATANMAMIŞ', 'ONAYDA', 'TEYİTTE', 'KAPALI']}
                                backgroundColor={['#24F52A', '#90A4AE', '#01579B', '#009688', '#FF7043', '#81C784', '#42A5F5']}
                                data={[this.state.countOpenI, this.state.countMyOpenI, this.state.countMyTeamOpenI, this.state.countUnassignedOpenI, this.state.countAwatingApprovalI, this.state.countWaitingConfirmationI, this.state.countClosedI]}
                                link={['#/incidentList?parameter=OpenI', '#/incidentList?parameter=MyOpenI', '#/incidentList?parameter=MyTeamOpenI', '#/incidentList?parameter=UnassignedOpenI', '#/incidentList?parameter=AwatingApprovalI', '#/incidentList?parameter=WaitingConfirmationI', '#/incidentList?parameter=OnlyClosed']}/>
                            {this.getCardDashboard(REQUEST_TYPE.INCIDENT)}
                        </div>
                    </Fieldset>}

                    {GeneralUtils.checkPrivilege('ServiceRequest', 'ViewList') &&
                    <Fieldset legend="Hizmet Talepleri" toggleable={true} collapsed={this.state.panelCollapsedSR}
                              onToggle={(e) => this.setState({panelCollapsedSR: e.value})}>
                        <div className="p-grid p-fluid dashboard">
                            <CardChart
                                name="Tüm Hizmet Talepleri"
                                labels={['AÇIK', 'BENDE', 'EKİPLERİMDE', 'ATANMAMIŞ', 'ONAYDA', 'KAPALI']}
                                backgroundColor={['#24F52A', '#90A4AE', '#01579B', '#009688', '#FF7043', '#42A5F5']}
                                data={[this.state.countOpenSR, this.state.countMyOpenSR, this.state.countMyTeamOpenSR, this.state.countUnassignedOpenSR, this.state.countAwatingApprovalSR, this.state.countClosedSR]}
                                link={['#/serviceRequestList?parameter=OpenSR', '#/serviceRequestList?parameter=MyOpenSR', '#/serviceRequestList?parameter=MyTeamOpenSR', '#/serviceRequestList?parameter=UnassignedOpenSR', '#/serviceRequestList?parameter=AwatingApprovalSR', '#/serviceRequestList?parameter=OnlyClosed']}/>
                            {this.getCardDashboard(REQUEST_TYPE.SERVICE_REQUEST)}
                        </div>
                    </Fieldset>}

                    {GeneralUtils.checkPrivilege('BbProduct', 'ViewList') &&
                    <Fieldset legend="Varlıklar" toggleable={true} collapsed={this.state.panelCollapsedBB}
                              onToggle={(e) => this.setState({panelCollapsedBB: e.value})}>
                        <div className="p-grid p-fluid dashboard">
                            <CardChart
                                name="TTVPN"
                                labels={['AKTİF', 'FATURA ONAYINDA', 'ASKIDA','İPTAL']}
                                backgroundColor={['#24F52A', '#8BC34A', '#FF7043', '#FF0000']}
                                data={[this.state.countActiveBB, this.state.countInstalledBB,this.state.countSuspendedBB, this.state.countUninstalledBB]}
                                link={['#/broadbandProduct?parameter=ActiveBB', '#/broadbandProduct?parameter=InstalledBB', '#/broadbandProduct?parameter=SuspendBB', '#/broadbandProduct?parameter=UninstalledBB']}/>
                            {this.state.tenantId === TENANT_ID.KB &&
                            <CardChart
                                name="Lokal Aktarım Formu"
                                labels={['ONAYLI', 'ONAY BEKLEYEN', 'ASKIDA']}
                                backgroundColor={['#42A5F5', '#8BC34A', '#FF7043']}
                                data={[this.state.countApprovedLMF, this.state.countAwaitingApprovalLMF, this.state.countSuspendedLMF]}
                                link={['#/bbProductDocumentList', '#/bbProductDocumentList', '#/bbProductDocumentList']}/>}
                        </div>
                    </Fieldset>}

                </div>}
            </div>
        );
    }

    getCardDashboard = (requestType) => {
        let form = [];
        if (requestType == REQUEST_TYPE.INCIDENT || requestType == REQUEST_TYPE.SERVICE_REQUEST) {
            let serviceSubcategoryList = requestType == REQUEST_TYPE.INCIDENT ? this.state.serviceSubcategoryListForIncident : this.state.serviceSubcategoryListForServiceRequest;
            let map = requestType == REQUEST_TYPE.INCIDENT ? this.state.countForIncidentSubCategoryMap : this.state.countForSubCategoryMap;

            if (map.size == serviceSubcategoryList.length) {
                serviceSubcategoryList.forEach(element => {
                    let countObject = map.get(element.id);
                    if (!GeneralUtils.__isValueEmpty(countObject)) {
                        let result = CardUtils.getCardContent(requestType, element.id, countObject);
                        form.push(
                            <CardChart key={element.name} name={element.name}
                                       labels={result.labels}
                                       backgroundColor={result.backgroundColor}
                                       hoverBackgroundColor={result.hoverBackgroundColor}
                                       data={result.data}
                                       link={result.link}/>
                        );
                    }
                });
            }
        }
        return form;
    }


    countDownClock = () => {
        this.setState({countDown: false});
        this.refresh();
    }

    getCount = async (serviceSubcategoryList, requestType) => {
        for (const element of serviceSubcategoryList) {
            const getPromises = [
                 this.dashboardService.getCountForDashboard(requestType, 'dashboard', element.id, 'OPEN'),
                 this.dashboardService.getCountForDashboard(requestType, 'dashboard', element.id, 'CLOSED'),
                 this.dashboardService.getCountForDashboard(requestType, 'dashboard', element.id, 'PENDING'),
                 this.dashboardService.getCountForDashboard(requestType, 'dashboard', element.id, 'STATUS', 'POST_INSTALL_APPROVAL'),
                 this.dashboardService.getCountForDashboard(requestType, 'dashboard', element.id, 'STATUS', 'AWAITING_CLOSURE')
            ]
            const countResponse = await Promise.all(getPromises);
            let countObject = {
                countOpen: countResponse[0],
                countClosed: countResponse[1],
                countPending: countResponse[2],
                countInstallApproval: countResponse[3],
                countAwaitingClosure: countResponse[4]
            }
            let countForSubCategoryMap = requestType == REQUEST_TYPE.INCIDENT ? this.state.countForIncidentSubCategoryMap : this.state.countForSubCategoryMap;
            countForSubCategoryMap.set(element.id, countObject);
            if (requestType == REQUEST_TYPE.INCIDENT) {
                this.setState({countForIncidentSubCategoryMap: countForSubCategoryMap})
            } else {
                this.setState({countForSubCategoryMap: countForSubCategoryMap})
            }

            // this.dashboardService.getCountForDashboard(requestType, 'dashboard', element.id, 'OPEN').then(response => {
            //     let countOpen = response;
            //     this.dashboardService.getCountForDashboard(requestType, 'dashboard', element.id, 'CLOSED').then(response => {
            //         let countClosed = response;
            //         this.dashboardService.getCountForDashboard(requestType, 'dashboard', element.id, 'PENDING').then(response => {
            //             let countPending = response;
            //             this.dashboardService.getCountForDashboard(requestType, 'dashboard', element.id, 'STATUS', 'POST_INSTALL_APPROVAL').then(response => {
            //                 let countInstallApproval = response;
            //                 this.dashboardService.getCountForDashboard(requestType, 'dashboard', element.id, 'STATUS', 'AWAITING_CLOSURE').then(response => {
            //                     let countAwaitingClosure = response;
            //                     let countObject = {
            //                         countOpen: countOpen,
            //                         countPending: countPending,
            //                         countClosed: countClosed,
            //                         countInstallApproval: countInstallApproval,
            //                         countAwaitingClosure: countAwaitingClosure
            //                     }
            //
            //                     let countForSubCategoryMap = requestType == REQUEST_TYPE.INCIDENT ? this.state.countForIncidentSubCategoryMap : this.state.countForSubCategoryMap;
            //                     countForSubCategoryMap.set(element.id, countObject);
            //                     if (requestType == REQUEST_TYPE.INCIDENT) {
            //                         this.setState({countForIncidentSubCategoryMap: countForSubCategoryMap})
            //                     } else {
            //                         this.setState({countForSubCategoryMap: countForSubCategoryMap})
            //                     }
            //
            //                 });
            //             });
            //         });
            //     });
            // });
        }
    }

    refresh = () => {

        this.dashboardService.getCountForDashboard(REQUEST_TYPE.SERVICE_REQUEST, 'OpenSR', 0).then(response => this.setState({countOpenSR: response}));
        this.dashboardService.getCountForDashboard(REQUEST_TYPE.SERVICE_REQUEST, 'MyOpenSR', 0).then(response => this.setState({countMyOpenSR: response}));
        this.dashboardService.getCountForDashboard(REQUEST_TYPE.SERVICE_REQUEST, 'MyTeamOpenSR', 0).then(response => this.setState({countMyTeamOpenSR: response}));
        this.dashboardService.getCountForDashboard(REQUEST_TYPE.SERVICE_REQUEST, 'UnassignedOpenSR', 0).then(response => this.setState({countUnassignedOpenSR: response}));
        this.dashboardService.getCountForDashboard(REQUEST_TYPE.SERVICE_REQUEST, 'AwatingApprovalSR', 0).then(response => this.setState({countAwatingApprovalSR: response}));
        this.dashboardService.getCountForDashboard(REQUEST_TYPE.SERVICE_REQUEST, 'OnlyClosed', 0).then(response => this.setState({countClosedSR: response}));

        this.dashboardService.getCountForDashboard(REQUEST_TYPE.INCIDENT, 'OpenI', 0).then(response => this.setState({countOpenI: response}));
        this.dashboardService.getCountForDashboard(REQUEST_TYPE.INCIDENT, 'MyOpenI', 0).then(response => this.setState({countMyOpenI: response}));
        this.dashboardService.getCountForDashboard(REQUEST_TYPE.INCIDENT, 'MyTeamOpenI', 0).then(response => this.setState({countMyTeamOpenI: response}));
        this.dashboardService.getCountForDashboard(REQUEST_TYPE.INCIDENT, 'UnassignedOpenI', 0).then(response => this.setState({countUnassignedOpenI: response}));
        this.dashboardService.getCountForDashboard(REQUEST_TYPE.INCIDENT, 'AwatingApprovalI', 0).then(response => this.setState({countAwatingApprovalI: response}));
        this.dashboardService.getCountForDashboard(REQUEST_TYPE.INCIDENT, 'WaitingConfirmationI', 0).then(response => this.setState({countWaitingConfirmationI: response}));
        this.dashboardService.getCountForDashboard(REQUEST_TYPE.INCIDENT, 'OnlyClosed', 0).then(response => this.setState({countClosedI: response}));

        this.dashboardService.getCountForDashboard(REQUEST_TYPE.BB_PRODUCT, 'ActiveBB', 0).then(response => this.setState({countActiveBB: response}));
        this.dashboardService.getCountForDashboard(REQUEST_TYPE.BB_PRODUCT, 'UninstalledBB', 0).then(response => this.setState({countUninstalledBB: response}));
        this.dashboardService.getCountForDashboard(REQUEST_TYPE.BB_PRODUCT, 'InstalledBB', 0).then(response => this.setState({countInstalledBB: response}));
        this.dashboardService.getCountForDashboard(REQUEST_TYPE.BB_PRODUCT, 'SuspendBB', 0).then(response => this.setState({countSuspendedBB: response}));

        this.dashboardService.getCountForDashboard('HSM_DOCUMENT', 'AwaitingApprovalLMF', 0).then(response => this.setState({countAwaitingApprovalLMF: response}));
        this.dashboardService.getCountForDashboard('HSM_DOCUMENT', 'ApprovedLMF', 0).then(response => this.setState({countApprovedLMF: response}));
        this.dashboardService.getCountForDashboard('HSM_DOCUMENT', 'SuspendedLMF', 0).then(response => this.setState({countSuspendedLMF: response}));

        this.serviceSubategoryService.findIncidentSubCategories(this.state.serviceCategoryId).then(response => {
            let serviceSubcategoryListForIncident = this.checkSubcategory(response);
            this.setState({serviceSubcategoryListForIncident: serviceSubcategoryListForIncident});
            this.getCount(serviceSubcategoryListForIncident, REQUEST_TYPE.INCIDENT);

        });
        this.serviceSubategoryService.findServiceRequestSubCategories(this.state.serviceCategoryId).then(response => {
            this.setState({serviceSubcategoryListForServiceRequest: response});
            this.getCount(response, REQUEST_TYPE.SERVICE_REQUEST);
        });

        this.refOverview.refresh();
        this.setState({countDown: true});
    }

    checkSubcategory = (serviceSubcategoryList) => {
        let subcategoryList = [];
        serviceSubcategoryList.forEach((element) => {
            if (element.status == 'A') {
                subcategoryList.push(element);
            }
        });
        return subcategoryList;
    }

}
