import React, {Component} from 'react';
import Doughnut from "../chart/Doughnut";
import "./CardChart.css";

export default class CardChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serviceSubcategoryId: this.props.serviceSubcategoryId,
            data: {
                labels: this.props.labels,
                datasets: [{
                    data: this.props.data,
                    backgroundColor: this.props.backgroundColor,
                    hoverBackgroundColor: this.props.backgroundColor,
                    link: this.props.link
                }]
            },
            empty: false
        };
    }

    componentWillReceiveProps=(nextProps)=> {
        let data = this.state.data;
        data.datasets[0].data = nextProps.data;
        if(data.labels !== nextProps.labels){
            data.labels = nextProps.labels;
        }
        if(data.datasets[0].backgroundColor !== nextProps.backgroundColor){
            data.datasets[0].backgroundColor = nextProps.backgroundColor;
            data.datasets[0].hoverBackgroundColor = nextProps.backgroundColor;
        }
        this.setState({data: data});
    }

    render() {
        return (
            this.getContent()
        );
    }


    getContent=()=> {
        return <div className="p-col-12 p-lg-3">
            <div className="card summary card-chart">
                <div className="card-title">{this.props.name}</div>
                <div className="p-grid">
                    <div className="p-col-7 connection-chart">
                        <Doughnut
                            data={this.state.data}
                            options={
                                {legend: {display: false}, responsive: true, cutoutPercentage: 0}
                            }/>
                    </div>
                    <div className="p-col-7 connection-chart-legend">
                        {this.getLegendAll()}
                    </div>
                </div>
            </div>
        </div>;
    }

    getLegendAll =() =>{
        let legendAll = [];
        for(var i=0; i<this.state.data.labels.length; i++){
            legendAll.push(this.getLegend(this.state.data.datasets[0].backgroundColor[i], this.state.data.labels[i],  this.state.data.datasets[0].data[i], this.state.data.datasets[0].link[i]));
        }
        return legendAll;
    }

    getLegend = (color, label, count, link) => {
        return <div className="p-grid">
            <div className="p-col-9">
                    <span className="dot" style={{background: color}}>

                    </span>
                <span>
                        <a style={{color: '#000'}} href={link}>{label}</a>
                    </span>
            </div>
            <div className="p-col-3">
                <span>{count}</span>
            </div>
        </div>;
    }
}
