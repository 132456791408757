import React from 'react';
import {Panel} from 'primereact/panel';
import DataTable from '../../../shared/component/dataTable/DataTable';
import HsmAssetService from "../../../shared/service/HsmAssetService";
import HsmAssetColumn from '../../../shared/component/dataTable/columns/HsmAssetColumn';
import Dialog from '../../../shared/component/dialog/Dialog';
import Translate from "../../../shared/language/Translate";
import {Button, Col, Label, Row} from 'reactstrap';
import {NETWORK_INTERFACE_NAME, PAGE_MODE} from "../../../shared/utils/Constants";
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import HsmAsset from "./HsmAsset";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import {Fieldset} from "primereact/fieldset";
import OrganizationGroup from "../../../shared/component/hsmGroup/OrganizationGroup";
import HsmOrganizationColumnForDialog from "../../../shared/component/dataTable/columns/HsmOrganizationColumnForDialog";
import DataTableForExcel from "../../../shared/component/dataTable/DataTableForExcel";
import InventoryColumnForExcel from "../../../shared/component/dataTable/columns/InventoryColumnForExcel";
import Loading from "../../../shared/component/others/Loading";
import {AvForm, AvGroup} from "availity-reactstrap-validation";
import * as XLSX from "xlsx";
import {toast} from "react-toastify";
import RequestAssetService from "../../../shared/service/RequestAssetService";
import AvField from "../../../shared/component/form/AvField";

const assetTypeConst = {
    AP: "Access Point",
    KABINET: "Cabinet",
    SWITCH: "Switch",
    SFP: "SFP Transceiver Module"
}

const fileError = {
    CHECK_UPLOAD_FILE: "CHECK_UPLOAD_FILE",
    FILL_ALL_ELEMENT: "FILL_ALL_ELEMENT",
}

export default class HsmAssetList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            excelData: [],
            pageMode: null,
            assetCategory: null,
            displayDialogForExcel:false,
            organization:''
        };
        this.service = new HsmAssetService();
        this.requestAssetService = new RequestAssetService();

    }

    componentDidMount = () => {
        this.service.checkPage();
    }

    componentDidMount= () => {
        let parameter = window.location.href.split('?')[1];
        if (parameter) {
            let value = parameter.split('=')[1];
            if (!GeneralUtils.isNullOrEmpty(value)) {
                this.refDataTable.setParameter(value);
                this.setState({assetCategory: value, parameter:value});
            }
        }
    }

    render() {
        return (
            <Panel header="Varlık Yönetimi">
                {this.state.parameter !== 'STOCK' &&
                <Toolbar display={GeneralUtils.checkPrivilege(HsmAssetColumn.name, 'Create')}>
                    <div className="p-toolbar-group-right">
                        <Button color="primary" onClick={this.addNew}>
                            <Translate contentKey="entity.action.add">Yeni Kayıt</Translate>
                        </Button>
                        {GeneralUtils.checkPrivilege("RequestAsset","Add") &&
                        <div className="p-toolbar-group-left">
                            <Button color="primary" onClick={()=> {
                                this.setState({displayDialogForExcel: true});
                                this.clearDataExcel();
                            }
                            }>
                                Dosya ile Yükle
                            </Button>
                        </div>}
                    </div>
                </Toolbar>}
                <DataTable ref={(elem) => this.refDataTable = elem} fields={HsmAssetColumn.fields} dataURL="hsmAsset/findAll"
                           objectName={HsmAssetColumn.name}
                           selectionMode="single" onDataSelect={this.onDataSelect} loading={this.state.loading} export={true} autoLoad={false}/>
                <Dialog visible={this.state.displayDialog}
                        header={GeneralUtils.getDialogHeader('Varlık', this.state.pageMode)}
                        onHide={() => this.setState({displayDialog: false})} style={{width: '50vw'}}>
                    <HsmAsset ref={(elem) => this.refHsmAsset = elem}
                              closeDisplayDialog={this.closeDisplayDialog} refresh={this.refresh}/>
                </Dialog>

                <Dialog visible={this.state.displayDialogForExcel} header="Organizasyon & Lokasyon" modal={true}
                        resizable={true} maximizable={true} responsive={true}
                        onHide={() => this.setState({displayDialogForExcel: false})}
                        style={{width: '100%'}}>
                    <AvForm>
                        <Toolbar display={true}>
                            <div className="p-toolbar-group-right">
                                <Button color="primary" onClick={()=> this.setState({displayDialogForExcel:false})}>
                                    Vazgeç
                                </Button>
                                <Button color="primary" onClick={this.clearDataExcel}>
                                    Temizle
                                </Button>
                                <Button color="primary" onClick={this.saveForExcel}>
                                    Kaydet
                                </Button>
                            </div>
                        </Toolbar>
                        <Fieldset legend="Organizasyon & Lokasyon Bilgileri">
                            <Row>
                                <Col md="6">
                                    <OrganizationGroup
                                        value={this.state.organization}
                                        onDataSelect={this.onOrganizationSelect}
                                        dataTable={{
                                            dataURL: "hsmOrganization/findAllOrganizations",
                                            fields: HsmOrganizationColumnForDialog.fields
                                        }}
                                    />
                                </Col>
                                {this.state.displayRegions&&
                                <Col md="6">
                                    <AvGroup>
                                        <Label for="temosId">
                                            <Translate contentKey="excelData.temosId">Hizmet No</Translate>
                                        </Label>
                                        <AvField type="text"
                                                 className="form-control"
                                                 name="temosId"
                                                 value={!GeneralUtils.__isValueEmpty(this.state.excelData[0])
                                                     ?this.state.excelData[0].temosId:''}
                                                 disabled={true}>
                                        </AvField>
                                    </AvGroup>
                                </Col>}
                            </Row>
                            {this.state.displayRegions&&
                            <div>
                                {/*<Row>*/}
                                {/*    <Col md="6">*/}
                                {/*        <AvGroup>*/}
                                {/*            <Label for="ttRegion">*/}
                                {/*                <Translate contentKey="excelData.ttRegion">TT Bölge</Translate>*/}
                                {/*            </Label>*/}
                                {/*            <AvField type="text"*/}
                                {/*                     className="form-control"*/}
                                {/*                     name="ttRegion"*/}
                                {/*                     value={!GeneralUtils.__isValueEmpty(this.state.excelData[0])*/}
                                {/*                         ?this.state.excelData[0].ttRegion:''}*/}
                                {/*                     disabled={true}>*/}
                                {/*            </AvField>*/}
                                {/*        </AvGroup>*/}
                                {/*    </Col>*/}
                                {/*    <Col md="6">*/}
                                {/*        <AvGroup>*/}
                                {/*            <Label for="kykRegion">*/}
                                {/*                <Translate contentKey="excelData.kykRegion">KYK Bölge</Translate>*/}
                                {/*            </Label>*/}
                                {/*            <AvField type="text"*/}
                                {/*                     className="form-control"*/}
                                {/*                     name="kykRegion"*/}
                                {/*                     value={!GeneralUtils.__isValueEmpty(this.state.excelData[0])*/}
                                {/*                         ?this.state.excelData[0].kykRegion:''}*/}
                                {/*                     disabled={true}>*/}
                                {/*            </AvField>*/}
                                {/*        </AvGroup>*/}
                                {/*    </Col>*/}
                                {/*</Row>*/}
                                <Row>
                                    <Col md="6">
                                        <AvGroup>
                                            <Label for="organizationCode">
                                                <Translate contentKey="excelData.organizationCode">Organizasyon Kodu</Translate>
                                            </Label>
                                            <AvField type="text"
                                                     className="form-control"
                                                     name="organizationCode"
                                                     value={!GeneralUtils.__isValueEmpty(this.state.excelData[0])
                                                         ?this.state.excelData[0].organizationId.code:''}
                                                     disabled={true}>
                                            </AvField>
                                        </AvGroup>
                                    </Col>
                                    <Col md="6">
                                        <AvGroup>
                                            <Label for="locationCode">
                                                <Translate contentKey="excelData.locationCode">Lokasyon Kodu</Translate>
                                            </Label>
                                            <AvField type="text"
                                                     className="form-control"
                                                     name="locationCode"
                                                     value={!GeneralUtils.__isValueEmpty(this.state.excelData[0])
                                                         ?this.state.excelData[0].locationId.code:''}
                                                     disabled={true}>
                                            </AvField>
                                        </AvGroup>
                                    </Col>
                                </Row>
                            </div>}
                        </Fieldset>
                        {!GeneralUtils.isNullOrEmpty(this.state.organization) &&
                        <Row className="justify-content-center">
                            <br/>
                            <Button color="primary" onClick={this.downloadTaskExcel}>
                                Taslak Dosya İndir
                            </Button>
                            <input ref={(elem) => this.refFile = elem} type='file' accept='.xlsx, .xls' onChange={this.onImportExcel}
                                   onClick={()=>this.refFile.value=null}
                            />
                            <br/>
                            <DataTableForExcel ref={(elem) => this.refDataTable = elem}
                                               fields={InventoryColumnForExcel.fields}
                                               data={this.state.excelData}
                                               loading={this.state.loading}
                                               privilege="allow"/>
                        </Row>
                        }
                        {this.state.loading && <Loading/>}
                    </AvForm>
                </Dialog>
            </Panel>
        );
    }

    refresh = () => {
        this.refDataTable.refresh();
    }

    onDataSelect = (data) => {
        let pageMode = GeneralUtils.getPageModeFromPrivilege(HsmAssetColumn.name);
        this.setState({displayDialog: true, pageMode: pageMode});
        this.refHsmAsset.setData(data, pageMode);
    }

    addNew = () => {
        this.setState({displayDialog: true, pageMode: PAGE_MODE.CREATE});
        this.refHsmAsset.addNew();
        this.refHsmAsset.setAssetCategory(this.state.assetCategory);
    }

    closeDisplayDialog = () => {
        this.setState({displayDialog: false});
    }

    clearDataExcel = () =>{
        this.setState({excelData:[],organization:'',displayRegions:false});
    }

    saveForExcel =()=>{

        if(GeneralUtils.isNullOrEmpty(this.state.organization)){
            return toast.warn('Lütfen organizasyon seçiniz.');
        }else{
            if(GeneralUtils.__isValueEmpty(this.refFile.value)){
                return toast.warn('Lütfen dosya yükleyiniz.');
            }
        }

        this.setState({loading: true});

        let data = this.state.excelData.map((element) => GeneralUtils.addCreateInfo(element));

        this.requestAssetService.createForExcel(data, 'this.state.requestType', 'this.state.requestId',this.state.inventoryOrganizationCode).then(response =>
            {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {

                    let errorCheckCount = 0;
                    response.forEach(element => {
                        if (element.hasError) {
                            errorCheckCount++;
                        }
                    })

                    if (errorCheckCount != 0) {
                        this.setState({loading: false, excelData: response});
                        return toast.warn('Lütfen hatalı kayıtları düzelterek kaydedin.');
                    }

                    toast.success("İşlem başarıyla gerçekleştirilmiştir.");
                    this.setState({loading: false,displayDialogForExcel: false});

                }
            }
        );
    }

    onOrganizationSelect = (data) => {
        let organization = data.code + ' - ' + data.name;

        this.setState({organization,inventoryOrganizationCode: data.code});
    }

    onImportExcel = (file) => {
        let {files} = file.target;
        if (GeneralUtils.isNullOrEmpty(files)) {
            return;
        }
        let fileReader = new FileReader();
        fileReader.onload = event => {
            try {
                let {result} = event.target;
                let workbook = XLSX.read(result, {type: 'binary'});
                let data = [];
                for (let sheet in workbook.Sheets) {
                    if (workbook.Sheets.hasOwnProperty(sheet)) {
                        data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
                    }
                }

                let newDataArray = this.getNewDataArray(data);

                if (newDataArray.length == 0) {
                    this.deleteSelectedFile();
                    return toast.warn('Dosyada kayıt bulunamadı.');
                }

                this.setState({loading: true});
                this.requestAssetService.checkForExcel(newDataArray, this.state.inventoryOrganizationCode).then(response => {
                    if (!GeneralUtils.isNullOrEmpty(response)) {
                        this.setState({
                            excelData: response,
                            loading: false,
                            displayRegions:true
                        });
                    }
                });
            } catch (e) {
                console.log(e);
                let errorMessage = "";
                if(e === fileError['CHECK_UPLOAD_FILE']){
                    errorMessage = "Lütfen seçilen dosyayı kontrol ediniz.";
                }else if(e === fileError['FILL_ALL_ELEMENT']){
                    errorMessage = "Lütfen her bir envanter için tüm alanların dolu olduğudan emin olunuz.";
                }
                this.setState({
                    loading: false,excelData:[]
                });
                this.deleteSelectedFile();
                return toast.warn(errorMessage);
            }
        };
// Open the file in binary mode
        fileReader.readAsBinaryString(files[0]);
    }


    ExcelDateToJSDate = (serial) => {
        let utc_days = Math.floor(serial - 25569);
        let utc_value = utc_days * 86400;
        let date_info = new Date(utc_value * 1000);
        let fractional_day = serial - Math.floor(serial) + 0.0000001;
        let total_seconds = Math.floor(86400 * fractional_day);
        let seconds = total_seconds % 60;
        total_seconds -= seconds;
        let hours = Math.floor(total_seconds / (60 * 60));
        let minutes = Math.floor(total_seconds / 60) % 60;

        return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
    }

    getNewDataArray=(data) =>{
        let newDataArray=[];
        data.map(element => {
            let newData = {
                organizationId: {
                    code: ""
                },
                locationId: {
                    code: ""
                },
                floorId: {
                    name: ""
                },
                roomId: {
                    name: ""
                },
                brandId: {
                    name: ""
                },
                modelId: {
                    name: ""
                },
                assetTag: "",
                serialNumber: "",
                dateOfLive: "",
                macAddress: "",
                nature: "",
                assetType: "",
                temosId: "",
                acceptanceDate: "",
                activatedDate: "",
                hsmProjectId: {
                    name: ""
                },
                createdBy: GeneralUtils.getUserId()
            }
            newData.organizationId.code = element['ORGANIZASYON KODU'];
            newData.locationId.code = element['LOKASYON KODU'];
            newData.floorId.name = element['KAT'];
            newData.roomId.name = element['KONUM'];
            newData.assetType =  GeneralUtils.__isValueEmpty(element['NATURE']) ? null :
                (GeneralUtils.__isValueEmpty(assetTypeConst[element['NATURE']] ) ? element['NATURE'] :
                    assetTypeConst[element['NATURE']])  ;
            newData.nature = element['TÜR'];
            newData.brandId.name = element['MARKA'];
            newData.modelId.name = element['MODEL'];
            newData.assetTag = element['BARKOD'];
            newData.serialNumber = element['SERI NO'];
            newData.macAddress = element['MAC AD.']
            newData.temosId = element['TEMOS ID'];
            newData.acceptanceDate = this.ExcelDateToJSDate(element['KABUL TARIHI']);
            newData.activatedDate = this.ExcelDateToJSDate(element['AKTIFLEME TARIHI']);
            newData.hsmProjectId.name = element['Faz'];
            this.checkFile(newData);
            newDataArray.push(newData);
            return element;
        });

        return newDataArray;
    }

    checkFile = (data) => {
        if (data.organizationId.code === undefined &&
            data.locationId.code === undefined &&
            data.floorId.name === undefined &&
            data.roomId.name === undefined &&
            data.brandId.name === undefined &&
            data.modelId.name === undefined &&
            data.assetTag === undefined &&
            data.serialNumber === undefined &&
            data.dateOfLive === undefined &&
            data.macAddress === undefined &&
            data.nature === undefined &&
            data.assetType === undefined &&
            data.temosId === undefined &&
            data.acceptanceDate === undefined &&
            data.activatedDate === undefined &&
            data.hsmProjectId.name === undefined &&
            data.createdBy === undefined
        ) {
            throw 'CHECK_UPLOAD_FILE';
        }else if (data.organizationId.code === undefined ||
            data.locationId.code === undefined ||
            data.floorId.name === undefined ||
            data.roomId.name === undefined ||
            data.brandId.name === undefined ||
            data.modelId.name === undefined ||
            data.assetTag === undefined ||
            data.serialNumber === undefined ||
            data.dateOfLive === undefined ||
            data.macAddress === undefined ||
            data.nature === undefined ||
            data.assetType === undefined ||
            data.temosId === undefined ||
            data.acceptanceDate === undefined ||
            data.activatedDate === undefined ||
            data.hsmProjectId.name === undefined ||
            data.createdBy === undefined
        ){
            throw 'FILL_ALL_ELEMENT';
        }
    }

    downloadTaskExcel = () => {
        let url = 'data/taskExcel.xlsx'
        let a = document.createElement('a')
        a.href = url
        a.download = 'taskExcel.xlsx'
        a.click()
        window.URL.revokeObjectURL(url)
    }

    deleteSelectedFile = () => {
        this.refFile.value=null;
    }
}
