import React from 'react';
import {Panel} from 'primereact/panel';
import IpPlanListReportService from "../../../shared/service/IpPlanListReportService";
import {AvForm, AvGroup} from "availity-reactstrap-validation";
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import {Button} from "reactstrap";
import Translate from "../../../shared/language/Translate";
import AvField from "../../../shared/component/form/AvField";
import {BB_PRODUCT_LOCATION_TYPE, CHANNEL_TYPE} from "../../../shared/utils/Constants";
import DataTableForPivot from "../../../shared/component/dataTable/DataTableForPivot";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import {toast} from "react-toastify";

const fieldList = {
    tt_region_name: 'Bölge',
    city_name: 'İl',
    dist_name: 'İlçe',
    org_name: 'Organizasyon Adı',
    loc_name: 'Ad (Blok Adı)',
    address_line: 'Adres',
    org_code: 'Organizasyon Kodu',
    loc_code: 'Tesis Kodu (Bina Kodu)',
    bb_status: 'Durum',
    bb_loc_status: 'Kurulum Durumu',
    installed_date: 'Kurulum Tarihi',
    activated_date: 'Aktivasyon Tarihi',
    uninstalled_date: 'İptal Tarihi',
    service_number: 'Hizmet No (Temos No)',
    product_id: 'No'
}

export default class IpPlanListReport extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            selected: {},
            pageMode: null,
            loading: false,
            bbProductLocationType: ""
        };
        this.service = new IpPlanListReportService();
    }

    componentDidMount = () => {
        this.service.checkPage();
    }

    render() {
        return (
            <Panel header="İp Plan Tablosu">
                <AvForm onValidSubmit={this.submit}>
                    <Toolbar display={true}>
                        <div className="p-toolbar-group-right ">
                            <Button color="primary" type="submit" className="float-right">
                                <Translate>Göster</Translate>
                            </Button>
                        </div>
                    </Toolbar>
                    <div className="container-fluid">
                        <h3>Filtre</h3>
                        <br/>
                        <br/>
                        <div className="row">
                            <div className="col-4" style={{padding: 0}}>
                                <div style={{width: 300}}>
                                    <div className='p-grid p-fluid '>
                                        <AvGroup>
                                            <AvField type="select" className="form-control" name="bb_location_type"
                                                     onChange={this.__handleChange}>
                                                <option value='' key=''/>
                                                {BB_PRODUCT_LOCATION_TYPE.map(element => (
                                                    <option value={element.value} key={element.value}>
                                                        {element.name}
                                                    </option>
                                                ))}
                                            </AvField>
                                        </AvGroup>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </AvForm>
                <br/>
                <DataTableForPivot ref={(el) => {
                    this.refDataTableForPivot = el;
                }} data={this.state.data} loading={this.state.loading}
                                   export={true} fieldList={fieldList}/>
            </Panel>
        );
    }

    submit = () => {
        this.setState({loading: true});
        let bbProductLocationType = this.state.bbProductLocationType;
        if (GeneralUtils.isNullOrEmpty(bbProductLocationType)) {
            toast.warn("Lütfen Kurulum Durumunu Seçiniz.");
            this.setState({loading: false})
            return;
        }

        this.service.findCrossTabIpListForLocType(bbProductLocationType).then(response => {
            console.log(response.item)
            this.setState({data: response.item, displayDialog: false, loading: false})
            this.refDataTableForPivot.setData(response.item);
        });
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let bbProductLocationType = this.state.bbProductLocationType;
        e.target.name = value;
        this.setState({bbProductLocationType: value});
    }

    closeDisplayDialog = () => {
        this.setState({displayDialog: false});
    }

}
