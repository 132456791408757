import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class CacheService{
    constructor() {
        this.baseURL = 'cache';
    }

    clearCache() {
        return fetch(SERVER_URL + this.baseURL+ '/clearCache', {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response);
            return result.json();
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    getCacheNames() {
        return fetch(SERVER_URL + this.baseURL+ '/getCacheNames', {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    getCache(name) {
        return fetch(SERVER_URL + this.baseURL+ '/getCache/' + name, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

}