import React from 'react';
import CacheService from "../../shared/service/CacheService";
import Toolbar from "../../shared/component/toolbar/Toolbar";
import {Button, Col, Label} from "reactstrap";
import {Panel} from "primereact/panel";
import Dialog from "../../shared/component/dialog/Dialog";
import AvField from "../../shared/component/form/AvField";
import {AvForm, AvGroup} from "availity-reactstrap-validation";
import ReactJson from "react-json-view";

export default class Cache extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            detail: null
        };
        this.cahceService = new CacheService();
    }

    componentDidMount = () => {
        this.cahceService.getCacheNames().then(response => this.setState({data: response}));
    }

    render() {
        return (
            <Panel header="Cahce Yönetimi">
                <Toolbar display={true}>
                    <div className="p-toolbar-group-right">
                        <Button color="primary" onClick={this.clearCache}>Cache Temizle</Button>
                    </div>
                </Toolbar>
                <AvForm>
                {this.state.data.map((element) => {
                    return <AvGroup>
                        <Col md="3">
                            <AvField
                                type="text"
                                className="form-control"
                                name="cache"
                                disabled={true}
                                value={element}/>
                            <Button color="primary" onClick={() => this.getCache(element)}
                                    className="pi pi-search selectTableButton"
                                    size="sm"></Button>
                            <span><br/></span>
                        </Col>
                    </AvGroup>
                })}
                </AvForm>
                <Dialog visible={this.state.displayDialog}
                        header="Cache Detay"
                        onHide={() => this.setState({displayDialog: false})} style={{width: '50vw'}}>
                    {this.state.detail ? <ReactJson src={this.state.detail}  /> : 'Kayıt Bulunamadı.'}
                </Dialog>
            </Panel>
        );
    }

    clearCache = () => {
        this.cahceService.clearCache();
        this.cahceService.getCacheNames().then(response => this.setState({data: response}));
    }

    getCache = (name) => {
        this.cahceService.getCache(name).then(response => this.setState({detail: response}));
        this.setState({displayDialog: true});
    }
}

