import React from 'react';
import {Panel} from 'primereact/panel';
import DataTable from '../../../shared/component/dataTable/DataTable';
import LogMailColumn from '../../../shared/component/dataTable/columns/LogMailColumn';
import {PAGE_MODE} from "../../../shared/utils/Constants";
import {TabPanel, TabView} from "primereact/tabview";
import LogMailContent from "./LogMailContent";
import LogMailDetail from "./LogMailDetail";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import {AvForm} from "availity-reactstrap-validation";
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import {Button} from "reactstrap";
import Translate from "../../../shared/language/Translate";
import LogMailService from "../../../shared/service/LogMailService";
import Dialog from "../../../shared/component/dialog/Dialog";

export default class LogMail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageMode: null
        };
        this.service = new LogMailService();
    }

    componentDidMount = () => {
        this.service.checkPage();
    }

    render() {
        return (
            <Panel header="E-Posta Günlüğü">
                <DataTable fields={LogMailColumn.fields} dataURL="logMail/findAll" objectName={LogMailColumn.name}
                           selectionMode="single" onDataSelect={this.onDataSelect} export={true}/>

                <Dialog visible={this.state.displayDialog} header="E-Posta Detay"
                        onHide={() => this.setState({displayDialog: false})} style={{width: '60%'}}>
                    <AvForm onValidSubmit={this.sendMail}>
                        {(this.state.critical) &&
                        <Toolbar display={GeneralUtils.checkPrivilege(LogMailColumn.name, 'SendMail')}>
                            <div className="p-toolbar-group-right">
                                <Button color="primary" type="submit" className="float-right">
                                    <Translate contentKey="entity.action.save">Gönder</Translate>
                                </Button>

                                <Button color="primary" className="float-right"
                                        onClick={() => this.setState({displayDialog: false})}>
                                    <Translate contentKey="entity.action.save">Vazgeç</Translate>
                                </Button>
                            </div>
                        </Toolbar>
                        }
                        <TabView activeIndex={this.state.activeIndex} renderActiveOnly={false}
                                 onTabChange={(e) => this.setState({activeIndex: e.index})}>

                            <TabPanel header="E-Posta Bilgisi">
                                <LogMailContent ref={elem => this.refLogMailContent = elem}/>
                            </TabPanel>

                            <TabPanel header="E-Posta Gönderim Detayı" rightIcon="pi pi-user"
                                      disabled={this.state.pageMode === PAGE_MODE.CREATE}
                                      headerClassName={this.state.pageMode === PAGE_MODE.CREATE && 'displayNone'}>
                                <LogMailDetail ref={elem => this.refLogLogMailDetail = elem}/>
                            </TabPanel>

                        </TabView>
                    </AvForm>
                </Dialog>
            </Panel>
        );
    }

    onDataSelect = (data) => {
        let pageMode = GeneralUtils.getPageModeFromPrivilege(LogMailColumn.name);
        this.setState({displayDialog: true, pageMode: pageMode, activeIndex: 0, critical: data.critical});
        this.refLogMailContent.clearData();
        this.refLogMailContent.setData(Object.assign({}, data), pageMode);
        this.refLogLogMailDetail.clearData();
        this.refLogLogMailDetail.setData(Object.assign({}, data));
    }

    sendMail = () => {
        let mail = this.refLogMailContent.getData();
        this.service.sendMail(mail).then((result) => {
            if (!GeneralUtils.isNullOrEmpty(result) && GeneralUtils.isNullOrEmpty(result.type)) {
                this.setState({displayDialog: false});
            }
            this.setState({loading: true});
            this.service.findAll().then(response => this.setState({data: response, loading: false}));
        });
    }
}
