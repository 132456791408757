import React from 'react';
import HsmAssetService from "../../../shared/service/HsmAssetService";
import HsmAssetSave from "./HsmAssetSave";
import Translate from "../../../shared/language/Translate";
import {Button} from 'reactstrap';
import {TabPanel, TabView} from "primereact/tabview";
import {ASSET_CATEGORY, PAGE_MODE, REQUEST_TYPE} from "../../../shared/utils/Constants";
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import {AvForm} from 'availity-reactstrap-validation';
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import AuditEvent from "../../administration/AuditEvent";
import HsmAssetColumn from '../../../shared/component/dataTable/columns/HsmAssetColumn';
import Attachment from "../../request/attacheddocument/Attachment";
import HsmDocumentService from "../../../shared/service/HsmDocumentService";

export default class HsmAsset extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageMode: null,
            documentIdList:[]
        };
        this.service = new HsmAssetService();
        this.documentService= new HsmDocumentService();
    }

    render() {
        return (
            <AvForm onValidSubmit={this.save} beforeSubmitValidation={() => this.setState({activeIndex: 0})}>
                {this.state.displayButton !== false &&
                <Toolbar
                    display={GeneralUtils.getDialogToolbarDisplay(HsmAssetColumn.name, this.state.pageMode)}>
                    <div className="p-toolbar-group-right">
                        <Button color="primary" type="submit" className="float-right">
                            <Translate contentKey="entity.action.save">Kaydet</Translate>
                        </Button>
                        <Button color="primary" className="float-right"
                                onClick={this.props.closeDisplayDialog}>
                            <Translate contentKey="entity.action.save">Vazgeç</Translate>
                        </Button>
                        {this.state.pageMode !== PAGE_MODE.CREATE &&
                        <Button color="primary" className="float-right" onClick={this.sendToStock}>
                            Stoğa Gönder
                        </Button>}
                    </div>
                </Toolbar>
                }
                <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({activeIndex: e.index})}
                         renderActiveOnly={false}>
                    <TabPanel header="Varlık Bilgileri"
                              rightIcon="pi pi-user">
                        <HsmAssetSave ref={(elem) => this.refHsmAssetSave = elem}/>
                    </TabPanel>

                    <TabPanel
                        header={GeneralUtils.isNullOrEmpty(this.state.attachmentLength) ? "Dokümanlar" : "Dokümanlar (" + this.refAttachedDocument.getData().length + ")"}
                        disabled={this.state.disabledTabs}
                        headerClassName={this.state.disabledTabs && 'displayNone'}>

                        <Attachment ref={elem => this.refAttachedDocument = elem}
                                    setAttachmentLength={(length) => this.setState({attachmentLength: length})}
                                    setDocumentId={(documentId)=>{
                                        let documentIdList = this.state.documentIdList;
                                        documentIdList.push(documentId);
                                        this.setState({documentIdList});
                                    }
                                    }
                                    deleteDocumentId={(documentId)=>{
                                        let documentIdList = this.deleteDocumentId(documentId);
                                        this.setState({
                                            documentIdList
                                        });
                                    }}
                        />
                    </TabPanel>

                    <TabPanel header="Tarihçe" rightIcon="pi pi-user"
                              disabled={this.state.pageMode === PAGE_MODE.CREATE}
                              headerClassName={this.state.pageMode === PAGE_MODE.CREATE && 'displayNone'}>
                        <AuditEvent ref={elem => this.refAuditEvent = elem} objectName={HsmAssetColumn.name}/>
                    </TabPanel>
                </TabView>
            </AvForm>
        );
    }

    sendToStock = () => {
        this.setState({loading: true});
        let hsmAsset = this.refHsmAssetSave.getData();
        this.service.sendToStock(hsmAsset).then((result) => {
            if (this.props.refresh) {
                this.props.refresh();
            }
            this.props.closeDisplayDialog();
            this.setState({loading: false});
        });
    }


    setData = (data, pageMode, displayButton) => {
        this.setState({pageMode: pageMode, activeIndex: 0, displayButton: displayButton});
        this.refHsmAssetSave.setData(Object.assign({}, data), pageMode, displayButton);
        this.refAttachedDocument.setData(Object.assign({}, data), REQUEST_TYPE.HSM_ASSET, pageMode, null);
        this.refAuditEvent.setData(HsmAssetColumn.name, data.id);
    }


    save = () => {
        let hsmAsset = this.refHsmAssetSave.getData();
        let data = {hsmAsset: hsmAsset}
        if (hsmAsset.assetCategory == ASSET_CATEGORY.NETWORK_DEVICE) {
            let hsmNetworkInterfaceList = this.refHsmAssetSave.getHsmNetworkInterfaceList();
            let hsmNetworkInterfaceRemovedList = this.refHsmAssetSave.getHsmNetworkInterfaceRemovedList();
            data = {
                hsmAsset: hsmAsset,
                hsmNetworkInterfaceList: hsmNetworkInterfaceList,
                hsmNetworkInterfaceRemovedList: hsmNetworkInterfaceRemovedList
            };
        }
        if (!GeneralUtils.isNullOrEmpty(hsmAsset.id)) {
            this.service.update(data).then((response) => {
                data.hsmAsset = GeneralUtils.addUpdateInfo(data.hsmAsset);
                if (hsmAsset.assetCategory == ASSET_CATEGORY.NETWORK_DEVICE) {
                    data.hsmNetworkInterfaceList = data.hsmNetworkInterfaceList.map((element) => GeneralUtils.addUpdateInfo(element));
                }
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    if(!GeneralUtils.isNullOrEmpty(this.state.documentIdList)) {
                        this.documentService.createOrUpdateHsmAttachDocument(REQUEST_TYPE.HSM_ASSET, response.hsmAsset.id, this.state.documentIdList);
                    }
                    if (this.props.refresh) {
                        this.props.refresh();
                    }
                    this.props.closeDisplayDialog();
                }
            });
        } else {
            data.hsmAsset = GeneralUtils.addCreateInfo(data.hsmAsset);
            if (hsmAsset.assetCategory == ASSET_CATEGORY.NETWORK_DEVICE) {
                data.hsmNetworkInterfaceList = data.hsmNetworkInterfaceList.map((element) => GeneralUtils.addCreateInfo(element));
            }
            this.service.create(data)
                .then(response => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        if(!GeneralUtils.isNullOrEmpty(this.state.documentIdList)) {
                            this.documentService.createOrUpdateHsmAttachDocument(REQUEST_TYPE.HSM_ASSET, response.hsmAsset.id, this.state.documentIdList);
                        }
                        if (this.props.refresh) {
                            this.props.refresh();
                        }
                        if (this.props.setHsmAsset) {
                            this.props.setHsmAsset(response.hsmAsset);
                        }
                        this.props.closeDisplayDialog();
                    }
                });
        }
    }

    addNew = () => {
        this.setState({anchestorAssetTag: null, pageMode: PAGE_MODE.CREATE, activeIndex: 0});
        this.refHsmAssetSave.clearData();
        this.refHsmAssetSave.setPageMode(PAGE_MODE.CREATE);
    }

    setOrgAndLocAndGeneric(organizationId, locationId) {
        this.refHsmAssetSave.setOrgAndLocAndGeneric(organizationId, locationId);
    }

    setPageMode = (pageMode) => {
        this.refHsmAssetSave.setPageMode(pageMode);
        this.setState({pageMode: pageMode})
    }

    setAssetCategory = (assetCategory) => {
        this.refHsmAssetSave.setAssetCategory(assetCategory);
    }

    deleteDocumentId(documentId) {
        let documentIdList = this.state.documentIdList;
        return documentIdList.filter(function (el) {
            return el != documentId;
        });
    }
}
