import React from 'react';
import {Col, Container, Row} from 'reactstrap';
import IncidentChartReport from "./IncidentChartReport";
import GeneralUtils from "../../../shared/utils/GeneralUtils";

export default class IncidentStatusReport extends React.Component {

    render() {
        return (
            <Container>
                {GeneralUtils.checkPrivilege('IncidentStatusReport', 'View') &&
                <Row>
                    <Col>
                        <IncidentChartReport/>
                    </Col>
                </Row>
                }
            </Container>
        );
    }
}
