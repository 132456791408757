import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import {CSSTransition} from 'react-transition-group';
import {Dialog} from 'primereact/dialog';

/**
 * Ümit Yıldırım ._.
 * dialoglara ref kullanarak erişimde sorun olduğu için primereact dialogu güncellendi.
 */
export class DialogPR extends Dialog {


    renderElement() {
        const className = classNames('p-dialog p-component', this.props.className, {
            'p-dialog-rtl': this.props.rtl,
            'p-dialog-maximized': this.maximized
        });

        const maskClassName = classNames('p-dialog-mask', {
            'p-component-overlay': this.props.modal,
            'p-dialog-visible': this.state.maskVisible
        }, this.props.maskClassName, this.getPositionClass());

        const header = this.renderHeader();
        const content = this.renderContent();
        const footer = this.renderFooter();

        let transitionTimeout = {
            enter: this.props.position === 'center' ? 150 : 300,
            exit: this.props.position === 'center' ? 150 : 300,
        };

        return (
            <div ref={(el) => this.mask = el} className={maskClassName} onClick={this.onMaskClick}>
                <CSSTransition classNames="p-dialog" timeout={transitionTimeout} in={this.props.visible}
                               onEntered={this.onEntered} onExit={this.onExit} onExited={this.onExited}>
                    <div ref={el => this.dialog = el} id={this.id} className={className} style={this.props.style}
                         onClick={this.onDialogClick}
                         aria-labelledby={this.id + '_label'} role="dialog" aria-modal={this.props.model}>
                        {header}
                        {content}
                        {footer}
                    </div>
                </CSSTransition>
            </div>
        );
    }

    render() {
        const element = this.renderElement();

        if (this.props.appendTo)
            return ReactDOM.createPortal(element, this.props.appendTo);
        else
            return element;
    }
}
