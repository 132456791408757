import React from 'react';
import {Label} from 'reactstrap';
import {AvGroup} from 'availity-reactstrap-validation';
import AvField from '../../../shared/component/form/AvField';
import PropTypes from 'prop-types';
import Translate from "../../../shared/language/Translate";
import {PAGE_MODE, STATUS} from "../../../shared/utils/Constants";


const defaultHsmJob = {
    id: '',
    name: '',
    status: 'A',
    description: ''
};

export default class HsmJobSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hsmJob: defaultHsmJob

        }
    }

    render() {
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col">

                            <AvGroup>
                                <Label for="name">
                                    <Translate contentKey="hsmJob.name">Job Adı</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="name"
                                    value={this.state.hsmJob.name}
                                    disabled={true}/>
                            </AvGroup>

                            <AvGroup>
                                <Label for="description">
                                    <Translate contentKey="hsmJob.description">Açıklama</Translate>
                                </Label>
                                <AvField type="textarea" className="form-control textAreaHermes" name="description"
                                         value={this.state.hsmJob.description}
                                         validate={{
                                             maxLength: {
                                                 value: 4000,
                                                 errorMessage: 'Bu alan en fazla 4000 karakterden oluşabilir.'
                                             }
                                         }}
                                         disabled={this.state.pageMode === PAGE_MODE.VIEW}
                                         onChange={this.__handleChange}>

                                </AvField>
                            </AvGroup>
                            <AvGroup>
                                <Label for="status">
                                    <Translate contentKey="hsmJob.status">Durum</Translate>
                                </Label>
                                <AvField type="select" className="form-control" name="status"
                                         value={this.state.hsmJob.status} onChange={this.__handleChange}
                                         disabled={this.state.pageMode === PAGE_MODE.VIEW}>
                                    {STATUS.map(element => (
                                        <option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>

                        </div>

                    </div>
                </div>


            </div>
        );
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let hsmJob = this.state.hsmJob;
        hsmJob[e.target.name] = value;
        this.setState({hsmJob: hsmJob});
    }

    setData = (data, pageMode) => {
        this.setState({hsmJob: data, pageMode: pageMode});
    }

    getData = () => {
        return this.state.hsmJob;
    }

    clearData = () => {
        this.setState({hsmJob: Object.assign({}, defaultHsmJob), pageMode: null});

    }

}

HsmJobSave.propTypes = {
    HsmJob: PropTypes.object
};
