import Service from './Service';
import GeneralUtils from "../utils/GeneralUtils";
import {SERVER_URL} from "../utils/Constants";

export default class CalculateSlaService extends Service {
    constructor() {
        super();
        this.baseURL = 'calculateSla';
    }

    calculateSla = (month, year) => {
        return fetch(SERVER_URL + this.baseURL+ '/calculateSla/'+month+'/'+year, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response);
            return result.json();
        }).catch(error => {
        GeneralUtils.notification(error);
        });
    }

    lockPeriod = (month, year) => {
        return fetch(SERVER_URL + this.baseURL+ '/lockPeriod/'+month+'/'+year, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response);
            return result.json();
        }).catch(error => {
        GeneralUtils.notification(error);
        });
    }

    isPeriodLocked = (month, year) => {
        return fetch(SERVER_URL + this.baseURL +'/'+month+'/'+year, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => {
            let result = response.clone();
            return result.json();
        }).catch(error => {
        GeneralUtils.notification(error);
        });
    }
    
}