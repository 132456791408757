import React from 'react';
import './App.css';
import GeneralUtils from './shared/utils/GeneralUtils';
import Login from './module/login/Login';
import AppMaster from './shared/layaout/sigma/AppMaster';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoginService from "./shared/service/LoginService";
import NoticeService from "./shared/service/NoticeService";
import {Accordion, AccordionTab} from "primereact/accordion";
import {NOTICE_TYPE} from "./shared/utils/Constants";
import Dialog from "./shared/component/dialog/Dialog";
import dompurify from 'dompurify';


class App extends React.Component {
    constructor(props) {
        super(props);
        let isAuth = localStorage.getItem("isAuthenticated") && !GeneralUtils.isNullOrEmpty(localStorage.getItem("isAuthenticated"));
        this.state = {
            isAuthenticated: isAuth,
            noticeList: []
        };
        this.loginService = new LoginService();
        this.noticeService = new NoticeService();
    }

    componentDidMount = () => {
        window.location.hash = "/";
    }

    render() {
        if (this.state.isAuthenticated === true) {
            return this.getHasAuth();
        } else {
            return this.getLogin();
        }
    }

    getLogin = () => {
        return <div>
            <Login loginSuccess={() => {
                this.setState({isAuthenticated: true});
                this.noticeService.findAllActiveAndUnread().then(response =>
                    this.setState({
                        noticeList: (!GeneralUtils.isNullOrEmpty(response) ? response : []),
                        displayDialog: (!GeneralUtils.isNullOrEmpty(response) && response.length > 0)
                    }));
            }}
            />
            {this.getToastContainer()}
        </div>;
    }

    logout = () => {
        let data = {eventType: "LOGOUT"};
        this.loginService.logout(data);
        localStorage.clear();
        window.location.hash = "/";
        this.setState({isAuthenticated: false})
    }

    getHasAuth = () => {
        return <div>
            <AppMaster logout={this.logout}/>
            {this.getToastContainer()}
            {this.getNotice()}
        </div>;
    }

    getToastContainer = () => {
        return <ToastContainer
            position={toast.POSITION.TOP_CENTER}
            className="toastify-container"
            toastClassName="toastify-toast"
        />;
    }

    getNotice = () => {
        return <Dialog visible={this.state.displayDialog}
                       header="Duyurular"
                       onHide={() => this.setState({displayDialog: false})} style={{width: '50vw'}}>
            <Accordion activeIndex={this.state.activeIndex}
                       onTabChange={(e) => this.setState({activeIndex: e.index})}>
                {this.state.noticeList.map((notice, i) => {
                    return <AccordionTab
                        header={GeneralUtils.isNullOrEmpty(notice.noticeType) ? notice.title : NOTICE_TYPE.map(element => {
                            if (element.value === notice.noticeType) return '(' + element.name + ') - ' + notice.title;
                        })}
                        toggleable={true} collapsed={this.state.panelCollapsed}
                        onToggle={(e) => this.setState({panelCollapsed: e.value})}
                        style={{
                            'backgroundColor': '#' + notice.backgroundColor,
                            'color': '#' + notice.textColor,
                            width: '25%',
                            marginTop: '2em'
                        }}>
                        <div dangerouslySetInnerHTML={{__html: dompurify.sanitize(notice.content)}}/>
                    </AccordionTab>;
                })}
            </Accordion>
        </Dialog>;
    }
}

export default App;

