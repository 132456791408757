import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class RequestContactService extends Service{
    constructor() {
        super();
        this.baseURL = 'requestAsset';
    }

    createForExcel(data, requestType, requestId,organizationCode) {
        return fetch(SERVER_URL + this.baseURL + '/createForExcel/' +requestType+ '/' +requestId+'/'+organizationCode, {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")},
            body: JSON.stringify(GeneralUtils.addCreateInfo(data))
        }).then(response => {
            let result = response.clone();
            return result.json();
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }
    checkForExcel(data, organizationCode) {
        return fetch(SERVER_URL + this.baseURL + '/checkForExcel/'+organizationCode, {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")},
            body: JSON.stringify(GeneralUtils.addCreateInfo(data))
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response);
            return result.json();
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    findByRequestTypeAndRequestId(requestType, requestId) {
        return fetch(SERVER_URL + this.baseURL+ '/findByRequestTypeAndRequestId/' +requestType+ '/'+requestId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
    });
    }


    findByOrganizationIdAndLocationId(organizationId, locationId) {
        return fetch(SERVER_URL + this.baseURL + '/findByOrganizationIdAndLocationId/' + organizationId + '/' + locationId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        })
            .then(response => response.json())
            .catch(error => {
                GeneralUtils.notification(error);
            });
    }

    findAllAssetByRequestTypeAndRequestId(requestType, requestId) {
        return fetch(SERVER_URL + this.baseURL+ '/findAllAssetByRequestTypeAndRequestId/' +requestType+ '/'+requestId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }
}