import React from 'react';
import {Button} from 'reactstrap';
import BbProductSave from "./BbProductSave";
import {TabPanel, TabView} from "primereact/tabview";
import {PAGE_MODE, REQUEST_TYPE} from "../../../shared/utils/Constants";
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import {AvForm} from 'availity-reactstrap-validation';
import Price from "./Price";
import AuditEvent from "../../administration/AuditEvent";
import BbProductColumn from '../../../shared/component/dataTable/columns/BbProductColumn';
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import Translate from "../../../shared/language/Translate";
import BbProductService from "../../../shared/service/BbProductService";
import HsmEdr from "./HsmEdr";
import HsmSubnet from "./HsmSubnet";
import {toast} from "react-toastify";
import Attachment from "../../request/attacheddocument/Attachment";
import HsmDocumentService from "../../../shared/service/HsmDocumentService";

export default class BbProduct extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            selected: {},
            pageMode: null,
            documentIdList:[]
        };
        this.service = new BbProductService();
        this.documentService= new HsmDocumentService();
    }


    render() {
        return (
            <AvForm onValidSubmit={this.save} beforeSubmitValidation={() => this.setState({activeIndex: 0})}>
                <Toolbar
                    display={GeneralUtils.getDialogToolbarDisplay(BbProductColumn.name, this.state.pageMode)}>
                    <div className="p-toolbar-group-right">
                        <Button color="primary" type="submit" className="float-right">
                            <Translate contentKey="entity.action.save">Kaydet</Translate>
                        </Button>
                        <Button color="primary" className="float-right"
                                onClick={this.props.closeDisplayDialog}>
                            <Translate contentKey="entity.action.save">Vazgeç</Translate>
                        </Button>
                    </div>
                </Toolbar>
                <TabView activeIndex={this.state.activeIndex}
                         onTabChange={(e) => this.setState({activeIndex: e.index})} renderActiveOnly={false}>
                    <TabPanel header="Genişbant Ürün" rightIcon="pi pi-user">
                        <BbProductSave ref={(elem) => this.refBbProductSave = elem}/>
                    </TabPanel>
                    <TabPanel header="Subnet Bilgileri" rightIcon="pi pi-user">
                        <HsmSubnet ref={(elem) => this.refHsmSubnet = elem}/>
                    </TabPanel>
                    {/*<TabPanel header="Ürün Ücret" rightIcon="pi pi-user">*/}
                    {/*    <Price ref={(elem) => this.refPrice = elem}/>*/}
                    {/*</TabPanel>*/}
                    {/*<TabPanel header="Ücretlendirme Kayıtları" rightIcon="pi pi-user">*/}
                    {/*    <HsmEdr ref={(elem) => this.refHsmEdr = elem}/>*/}
                    {/*</TabPanel>*/}

                    <TabPanel
                        header={GeneralUtils.isNullOrEmpty(this.state.attachmentLength) ? "Dokümanlar" : "Dokümanlar (" + this.refAttachedDocument.getData().length + ")"}
                        disabled={this.state.disabledTabs}
                        headerClassName={this.state.disabledTabs && 'displayNone'}>

                        <Attachment ref={elem => this.refAttachedDocument = elem}
                                    setAttachmentLength={(length) => this.setState({attachmentLength: length})}
                                    setDocumentId={(documentId)=>{
                                        let documentIdList = this.state.documentIdList;
                                        documentIdList.push(documentId);
                                        this.setState({documentIdList});
                                    }
                                    }
                                    deleteDocumentId={(documentId)=>{
                                        let documentIdList = this.deleteDocumentId(documentId);
                                        this.setState({
                                            documentIdList
                                        });
                                    }}
                        />
                    </TabPanel>
                    <TabPanel header="Tarihçe" rightIcon="pi pi-user"
                              disabled={this.state.pageMode === PAGE_MODE.CREATE}
                              headerClassName={this.state.pageMode === PAGE_MODE.CREATE && 'displayNone'}>
                        <AuditEvent ref={elem => this.refAuditEvent = elem} objectName={BbProductColumn.name}/>
                    </TabPanel>
                </TabView>
            </AvForm>
        );
    }

    setData = (data, pageMode) => {
        this.setState({pageMode: pageMode, activeIndex: 0});
        this.refBbProductSave.setData(Object.assign({}, data), pageMode);
        this.refHsmSubnet.setData(Object.assign({}, data), pageMode);
        // this.refPrice.setData(Object.assign({}, data));
        // this.refHsmEdr.setData(Object.assign({}, data));
        this.refAttachedDocument.setData(Object.assign({}, data), REQUEST_TYPE.BB_PRODUCT, pageMode, null);
        this.refAuditEvent.setData(BbProductColumn.name, data.id);
    }

    save = () => {
        let bbProduct = this.refBbProductSave.getData();

        let dateConstrait = new Date();
        dateConstrait.setDate(dateConstrait.getDate() - 20);

        let currentDate = new Date().getTime();
        if (new Date(bbProduct.installedDate).getTime() > currentDate) {
            return toast.warn("Kurulum tarihi, bugünün tarihinden sonra olamaz!");
        }

        // if (!GeneralUtils.isNullOrEmpty(bbProduct.installedDate)
        //     && new Date(bbProduct.installedDate).getTime() < dateConstrait.getTime() && GeneralUtils.isNullOrEmpty(bbProduct.activatedDate)) {
        //     return toast.warn("Kurulum tarihi, en fazla 20 gün öncesine kadar girilebilir!");
        // }

        if (GeneralUtils.isNullOrEmpty(bbProduct.installedDate) && !GeneralUtils.isNullOrEmpty(bbProduct.activatedDate)) {
            return toast.warn("Aktivasyon / fatura başlatma tarihinden önce kurulum tarihi giriniz!");
        }

        if (!GeneralUtils.isNullOrEmpty(bbProduct.installedDate) && !GeneralUtils.isNullOrEmpty(bbProduct.activatedDate)
            && new Date(bbProduct.installedDate).getTime() > new Date(bbProduct.activatedDate).getTime()) {
            return toast.warn("Kurulum tarihi, aktivasyon / fatura başlatma tarihinden sonra olamaz!");
        }
        if (GeneralUtils.isNullOrEmpty(bbProduct.installedDate) && GeneralUtils.isNullOrEmpty(bbProduct.activatedDate)
            && GeneralUtils.isNullOrEmpty(bbProduct.uninstalledDate)){
            bbProduct.status = 'PASSIVE';
        }
        if (!GeneralUtils.isNullOrEmpty(bbProduct.installedDate)){
            bbProduct.status = 'INSTALLED';
        }
        if (!GeneralUtils.isNullOrEmpty(bbProduct.activatedDate)){
            bbProduct.status = 'ACTIVE';
        }
        if (!GeneralUtils.isNullOrEmpty(bbProduct.uninstalledDate)){
            bbProduct.status = 'UNINSTALLED';
        }
        if(bbProduct.isSuspend){
            if (GeneralUtils.isNullOrEmpty(bbProduct.description)) {
                return toast.warn("Faturalamaya onay verilmediğinde 'Açıklama' alanı zorunludur. Lütfen açıklama giriniz.");
            }
            bbProduct.status = 'SUSPENDED';
        } else {
            bbProduct.description = null;
        }

        let hsmSubnetList = this.refHsmSubnet.getData();
        hsmSubnetList.forEach((element) => {
            element.organizationId = bbProduct.organizationId;
        });
        let hsmSubnetRemovedList = this.refHsmSubnet.getDataRemoved();
        let data = {bbProduct: bbProduct, hsmSubnetList: hsmSubnetList, hsmSubnetRemovedList: hsmSubnetRemovedList};
        if (!GeneralUtils.isNullOrEmpty(bbProduct.id)) {
            data.bbProduct = GeneralUtils.addUpdateInfo(data.bbProduct);
            data.hsmSubnetList = data.hsmSubnetList.map((element) => GeneralUtils.addUpdateInfo(element));
            this.service.update(data).then((response) => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    if(!GeneralUtils.isNullOrEmpty(this.state.documentIdList)) {
                        this.documentService.createOrUpdateHsmAttachDocument(REQUEST_TYPE.BB_PRODUCT, response.bbProduct.id, this.state.documentIdList);
                    }
                    this.props.save();
                    this.props.closeDisplayDialog();
            }
            });
        } else {
            data.bbProduct = GeneralUtils.addCreateInfo(data.bbProduct);
            data.hsmSubnetList = data.hsmSubnetList.map((element) => GeneralUtils.addCreateInfo(element));
            this.service.create(data).then((response) => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    if(!GeneralUtils.isNullOrEmpty(this.state.documentIdList)) {
                        this.documentService.createOrUpdateHsmAttachDocument(REQUEST_TYPE.BB_PRODUCT, response.id, this.state.documentIdList);
                    }
                    this.props.save();
                    this.props.closeDisplayDialog();
                }
            });
        }
    }

    setPageMode = (pageMode) => {
        this.setState({pageMode: pageMode});
    }

    deleteDocumentId(documentId) {
        let documentIdList = this.state.documentIdList;
        return documentIdList.filter(function (el) {
            return el != documentId;
        });
    }


}
