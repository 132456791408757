import React from 'react';
import {Panel} from 'primereact/panel';
import DataTable from '../../../shared/component/dataTable/DataTable';
import TeamContactColumn from '../../../shared/component/dataTable/columns/TeamContactColumn';
import TeamContactService from "../../../shared/service/TeamContactService";


export default class TeamContactList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
        this.service = new TeamContactService();
    }

    componentDidMount = () => {
        this.setState({loading: true});
        this.service.checkPage();
    }

    render() {
        return (
            <Panel header="Ekip Üye Listesi">
                <DataTable fields={TeamContactColumn.fields} dataURL="teamContact/findAllFilteredOrganization" objectName={TeamContactColumn.name} loading={this.state.loading}
                export={true}/>
            </Panel>
        );
    }


}
