import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class RequestLocationService extends Service{
    constructor() {
        super();
        this.baseURL = 'requestLocation';
    }

    findByRequestTypeAndRequestId(requestType, requestId) {
        return fetch(SERVER_URL + this.baseURL+ '/findByRequestTypeAndRequestId/' +requestType+ '/'+requestId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
    });
    }


}