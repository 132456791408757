import React from 'react';
import AuditEventColumn from '../../shared/component/dataTable/columns/AuditEventColumn';
import AuditEventService from "../../shared/service/AuditEventService";
import GeneralUtils from "../../shared/utils/GeneralUtils";
import Dialog from "../../shared/component/dialog/Dialog";
import AuditEventLog from "./AuditEventLog";
import DataTableLog from "../../shared/component/dataTable/DataTableLog";

export default class AuditEvent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            tableName: this.props.tableName,
            tableId: this.props.tableId
        };
        this.service = new AuditEventService();
    }

    render() {
        return (
            <div>
                {GeneralUtils.checkPrivilege(this.props.objectName, 'ViewHistory') &&
                <DataTableLog ref={(elem) => this.refDataTable = elem} fields={AuditEventColumn.fields} dataURL="auditEvent/findByEntityNameAndEntityId" objectName={this.props.objectName}
                    // selectionMode="single" onDataSelect={this.onDataSelect}
                              autoLoad={false}/>
                }

                <Dialog visible={this.state.displayDialog} header="Tarihçe Detay İzleme" modal={true} resizable={true}
                        maximizable={true}
                        responsive={true} onHide={() => this.setState({displayDialog: false})} style={{width: '60%'}}>
                    <AuditEventLog ref={elem => this.refAuditEventLog = elem}/>
                </Dialog>
            </div>
        );
    }

    // onDataSelect = (data) => {
    //     if(data.eventType === 'ADD'){
    //
    //     }else {
    //         this.setState({displayDialog: true});
    //         this.refAuditEventLog.setData(data.id);
    //     }
    // }

    setData = (entityName, entityId) => {
        if (!GeneralUtils.isNullOrEmpty(entityName) && !GeneralUtils.isNullOrEmpty(entityId) && GeneralUtils.checkPrivilege(this.props.objectName, 'ViewHistory')) {
            this.refDataTable.setParameter(entityName, entityId);
            // this.service.findByEntityNameAndEntityId(entityName, entityId).then(response => this.setState({data: response}));
        }
    }
}

