import React from 'react';
import {AvGroup} from "availity-reactstrap-validation";
import {Col, Label, Row} from "reactstrap";
import AvField from "../../shared/component/form/AvField";

const defaultBandwidth = {
    id: '',
    accessTechnology: '',
    downloadSpeed: '',
    uploadSpeed: ''
    
};
export default class BandwidthSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bandwidth: defaultBandwidth,
            pageMode: null
        };
    }

    render() {
        return (
            <Row className="justify-content-center">
                <Col>
                    <AvGroup>
                        <Label for="accessTechnology">Erişim Teknolojisi<span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="accessTechnology"
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                },
                                minLength: {
                                    value: 1,
                                    errorMessage: 'Bu alan en az 1 karakterden oluşabilir.'
                                },
                                maxLength: {
                                    value: 20,
                                    errorMessage: 'Bu alan en fazla 20 karakterden oluşabilir.'
                                }
                            }}
                            value={this.state.bandwidth.accessTechnology}
                            onChange={this.__handleChange}
                        />
                    </AvGroup>
                    <AvGroup>
                        <Label for="downloadSpeed">İndirme Hızı<span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="downloadSpeed"
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                },
                                minLength: {
                                    value: 1,
                                    errorMessage: 'Bu alan en az 1 karakterden oluşabilir.'
                                },
                                maxLength: {
                                    value: 20,
                                    errorMessage: 'Bu alan en fazla 20 karakterden oluşabilir.'
                                }
                            }}
                            value={this.state.bandwidth.downloadSpeed}
                            onChange={this.__handleChange}
                        />
                    </AvGroup>
                    <AvGroup>
                        <Label for="uploadSpeed">Yükleme Hızı<span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="uploadSpeed"
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                },
                                minLength: {
                                    value: 1,
                                    errorMessage: 'Bu alan en az 1 karakterden oluşabilir.'
                                },
                                maxLength: {
                                    value: 20,
                                    errorMessage: 'Bu alan en fazla 20 karakterden oluşabilir.'
                                }
                            }}
                            value={this.state.bandwidth.uploadSpeed}
                            onChange={this.__handleChange}
                        />
                    </AvGroup>
                </Col>
            </Row>
        );
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let bandwidth = this.state.bandwidth;
        bandwidth[e.target.name] = value;
        this.setState({bandwidth: bandwidth});
    }

    setData = (data, pageMode) => {
        this.setState({bandwidth: data, pageMode: pageMode});
    }

    getData = () => {
        return this.state.bandwidth;
    }

    clearData = () => {
        this.setState({bandwidth: Object.assign({}, defaultBandwidth), pageMode: null});
    }

}
