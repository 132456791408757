import React from 'react';
import {Panel} from 'primereact/panel';
import Service from "../../../shared/service/Service";
import DataTable from "../../../shared/component/dataTable/DataTable";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import AssetRatingColumn from "../../../shared/component/dataTable/columns/AssetRatingColumn";
import AssetRatingGeneralColumn from "../../../shared/component/dataTable/columns/AssetRatingGeneralColumn";
import AssetRatingColumnForCustomer from "../../../shared/component/dataTable/columns/AssetRatingColumnForCustomer";
import AssetRatingGeneralColumnForCustomer from "../../../shared/component/dataTable/columns/AssetRatingGeneralColumnForCustomer";
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import {Button, Label} from "reactstrap";
import Translate from "../../../shared/language/Translate";
import {Calendar} from "primereact/calendar";
import {AvForm, AvGroup} from "availity-reactstrap-validation";
import {toast} from "react-toastify";
import AvField from "../../../shared/component/form/AvField";

const REPORT_TYPE = [
    {name: 'Genel Rapor', value: true},
    {name: 'Detay Rapor', value: false}
];
export default class AssetRating extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            selected: {},
            dateStart: null,
            dateEnd: null,
            dateStartDate: null,
            dateEndDate: null,
            isGeneral: null,
            fields : AssetRatingColumn.fields,
            fieldsGeneral : AssetRatingGeneralColumn.fields
        };
        this.service = new Service('assetRating');
    }

    componentDidMount = () => {
        this.service.checkPage();
        // this.defaultDateAndData();

        let parameter = window.location.href.split('#')[1];
        if (parameter === '/assetRatingForCustomer') {
                this.state.fields=AssetRatingColumnForCustomer.fields;
                this.state.fieldsGeneral=AssetRatingGeneralColumnForCustomer.fields;
        }
    }


    render() {

        const tr = {
            firstDayOfWeek: 1,
            monthNames: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
            monthNamesShort: ['Oca', 'Şub', 'Mar', 'Nis', 'May', 'Haz', 'Tem', 'Ağu', 'Eyl', 'Eki', 'Kas', 'Ara'],
            dayNames: ['Pazartesi,', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi', 'Pazar'],
            dayNamesShort: ['Pzt', 'Sal', 'Çar', 'Prş', 'Cm', 'Cmr', 'Paz'],
            dayNamesMin: ['P', 'S', 'Ç', 'P', 'C', 'Ct', 'Pz'],
            today: ['Bugün'],
            clear: ['Temizle']
        };

        return (
            <Panel header="Varlık Hakediş Raporu">
                <AvForm onValidSubmit={this.submit}>
                    <Toolbar display={true}>
                        <div className="p-toolbar-group-right ">
                            <Button color="primary" type="submit" className="float-right" disabled={GeneralUtils.isNullOrEmpty(this.state.isGeneral)}>
                                <Translate>Göster</Translate>
                            </Button>
                        </div>
                    </Toolbar>
                    <div className="container-fluid">
                        <h3>Filtre</h3>
                        <div className="row">
                            <div className="col-4" style={{padding: 0}}>
                                <div style={{width: 300}}>
                                    <div className='p-grid p-fluid '>
                                        <Calendar
                                            tooltip="Başlangıç Tarihi"
                                            required={true}
                                            style={{margin: 20}}
                                            placeholder="Başlangıç Tarihi Seç"
                                            value={this.state.dateStartDate}
                                            onChange={e => this._onChangeDateStart(e)}
                                            dateFormat='dd-mm-yy'
                                            showButtonBar={true}
                                            readOnlyInput={true}
                                            showIcon={true}
                                            locale={tr}
                                            onClearButtonClick={e => this.setState({
                                                dateStart: "",
                                            })
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-4" style={{padding: 0}}>
                                <div style={{width: 300}}>
                                    <div className='p-grid p-fluid '>
                                        <Calendar
                                            tooltip="Bitiş Tarihi"
                                            required={true}
                                            style={{margin: 20}}
                                            placeholder="Bitiş Tarihi Seç"
                                            value={this.state.dateEndDate}
                                            onChange={e => this._onChangeDateEnd(e)}
                                            dateFormat='dd-mm-yy'
                                            showButtonBar={true}
                                            readOnlyInput={true}
                                            showIcon={true}
                                            locale={tr}
                                            onClearButtonClick={e => this.setState({
                                                dateEnd: "",
                                            })
                                            }
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-4" className="position-relative form-group">
                                <div style={{width: 300}}>
                                    <div className='p-grid p-fluid '>
                                        <AvGroup>
                                            <Label for="isGeneral">
                                                Rapor Tipi
                                                <span style={{color: 'red'}}> (*)</span>
                                            </Label>
                                            <AvField
                                                type="select"
                                                // type="text"
                                                className="form-control"
                                                name="isGeneral"
                                                value={this.state.isGeneral}
                                                validate={{
                                                    required: {
                                                        value: "true",
                                                        errorMessage: "Bu alanın doldurulması zorunludur."
                                                    }
                                                }}
                                                onChange={this.__handleChange}>
                                                <option value='' key=''/>
                                                {REPORT_TYPE.map(element => (
                                                    <option value={element.value} key={element.value}>
                                                        {element.name}
                                                    </option>
                                                ))}
                                            </AvField>
                                        </AvGroup>


                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <Label>
                                Başlangıç ve Bitiş Tarihleri Arasında Aktivasyonu Yapılan TTVPN Devresine Ait Kurulan Varlıklar ve Verilen Hizmetler Raporudur.
                            </Label>
                        </div>
                    </div>

                </AvForm>


                {this.state.isGeneral === 'true' &&
                <DataTable ref={(elem) => this.refDataTable = elem} fields={this.state.fieldsGeneral}
                           dataURL="assetRating/findByStartDateAndEndDate"
                           objectName={AssetRatingGeneralColumn.name}
                           export={true} autoLoad={false} refresh={false}/>}

                {this.state.isGeneral === 'false' &&
                <DataTable ref={(elem) => this.refDataTable = elem} fields={this.state.fields}
                           dataURL="assetRating/findByStartDateAndEndDate"
                           objectName={AssetRatingColumn.name}
                           export={true} autoLoad={false} refresh={false}/>}
            </Panel>
        );
    }


    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        this.setState({isGeneral: value});
    }

    submit = () => {
        let dateStart = this.state.dateStart;
        let dateEnd = this.state.dateEnd;
        let dateStartDate = this.state.dateStartDate;
        let dateEndDate = this.state.dateEndDate;
        let now = Date.now();

        if ((!GeneralUtils.isNullOrEmpty(dateStartDate) && !GeneralUtils.isNullOrEmpty(dateEndDate))) {
            if ((dateStartDate > now || dateEndDate > now)) {
                toast.warn("Başlangıç ve/veya Bitiş Tarihi Bugünün Tarihinde İleri Olamaz.");
                return;
            }
            if (dateStartDate > dateEndDate) {
                toast.warn("Başlangıç Tarihi Bitiş Tarihinden Sonra Olamaz.");
                return;
            }
        }

        if (GeneralUtils.isNullOrEmpty(dateStartDate) || GeneralUtils.isNullOrEmpty(dateEndDate)) {
            toast.warn("Lütfen Başlangıç ve Bitiş Tarihi Alanlarını Doldurunuz.");
            return;
        }

        this.refDataTable.setParameter(dateStart, dateEnd, this.state.isGeneral);

    }

    // defaultDateAndData = () => {
    //     let date = new Date(), y = date.getFullYear(), m = date.getMonth();
    //     let firstDay = new Date(y, m, 1);
    //     let lastDay = new Date();
    //
    //     let fullFirstDay = this.getDate(firstDay);
    //     let fullLastDay = this.getDate(lastDay);
    //
    //     this.setState({
    //         dateStart: fullFirstDay, dateStartDate: firstDay, dateEnd: fullLastDay, dateEndDate: lastDay
    //     });
    //
    //     this.refDataTable.setParameter(fullFirstDay, fullLastDay);
    // }


    _onChangeDateStart = (value) => {
        let fullDate = this.getDate(value.value);

        this.setState({
            dateStart: fullDate, dateStartDate: value.value
        });
    }

    _onChangeDateEnd = (value) => {
        let fullDate = this.getDate(value.value);

        this.setState({
            dateEnd: fullDate, dateEndDate: value.value
        });
    }

    getDate(dateValue) {
        let date = new Date(dateValue);
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        return day + "-" + month + "-" + year;
    }
}
