import React from 'react';
import {AvGroup} from "availity-reactstrap-validation";
import {Col, Label, Row} from "reactstrap";
import AvField from "../../shared/component/form/AvField";
import ServiceCategoryService from "../../shared/service/ServiceCategoryService"
import ServiceSubcategoryService from "../../shared/service/ServiceSubcategoryService"
import SltGroup from "../../shared/component/hsmGroup/SltGroup"
import {REGION_TYPE,ACCESS_TECHNOLOGY} from "../../shared/utils/Constants"


const defaultSltScope = {
    id: '',
    sltId: 1,
    serviceCategoryId: 1,
    serviceSubcategoryId: '',
    regionType: '',
    accessTechnology: '',
};
export default class SltScopeSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sltScope: defaultSltScope,
            serviceSubcategoryList: [],
            serviceCategoryList:[],
            slt: null,
            pageMode: null
        };
        this.serviceCategoryService=new ServiceCategoryService();
        this.serviceSubcategoryService=new ServiceSubcategoryService();

    }
    componentDidMount = () => {
        this.clearData();
        this.serviceCategoryService.findAll().then(response => this.setState({serviceCategoryList: response}));
        this.findServiceSubcategoryByChangedServiceCategoryId(this.state.sltScope.serviceCategoryId); 
    }

    render() {
        return (
            <Row className="justify-content-center">
                <Col>
                    <SltGroup
                        value={this.state.slt}
                        onDataSelect={this.onSltSelect}
                        name="slt"
                    />
                    <AvGroup>
                        <Label for="serviceCategoryId">Kategori<span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="select"
                            className="form-control"
                            name="serviceCategoryId"
                            value={this.state.sltScope.serviceCategoryId}
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                }
                            }}
                            onChange={this.__handleChangeServiceCategory}>
                        {this.state.serviceCategoryList.map(element => (
                            <option value={element.id} key={element.id}>
                                {element.name}
                            </option>
                        ))}
                        </AvField>
                    </AvGroup>
                    <AvGroup>
                        <Label for="serviceSubcategoryId">Alt Kategori<span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="select"
                            className="form-control"
                            name="serviceSubcategoryId"
                            value={this.state.sltScope.serviceSubcategoryId}
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                }
                            }}
                            onChange={this.__handleChange}>
                        {this.state.serviceSubcategoryList.map(element => (
                            <option value={element.id} key={element.id}>
                                {element.name}
                            </option>
                        ))}
                        </AvField>
                    </AvGroup>
                    <AvGroup>
                        <Label for="regionType">Bölge<span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="select"
                            className="form-control"
                            name="regionType"
                            value={this.state.sltScope.regionType}
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                },
                                minLength: {
                                    value: 1,
                                    errorMessage: 'Bu alan en az 1 karakterden oluşabilir.'
                                },
                                maxLength: {
                                    value: 50,
                                    errorMessage: 'Bu alan en fazla 50 karakterden oluşabilir.'
                                }
                            }}
                            onChange={this.__handleChange}>
                        <option value='' key=''/>
                        {REGION_TYPE.map(element => (
                            <option value={element.value} key={element.value}>
                                {element.name}
                            </option>
                        ))}
                        </AvField>
                    </AvGroup>
                    <AvGroup>
                        <Label for="accessTechnology">Erişim Teknolojisi<span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="select"
                            className="form-control"
                            name="accessTechnology"
                            value={this.state.sltScope.accessTechnology}
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                },
                                minLength: {
                                    value: 1,
                                    errorMessage: 'Bu alan en az 1 karakterden oluşabilir.'
                                },
                                maxLength: {
                                    value: 50,
                                    errorMessage: 'Bu alan en fazla 50 karakterden oluşabilir.'
                                }
                            }}
                            onChange={this.__handleChange}>
                        <option value='' key=''/>
                        {Object.entries(ACCESS_TECHNOLOGY).map(([key,value]) => (
                                <option value={key} key={key}>
                                    {value}
                                </option>
                        ))}
                        </AvField>
                    </AvGroup>
                </Col>
            </Row>
        );
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let sltScope = this.state.sltScope;
        sltScope[e.target.name] = value;
        this.setState({sltScope: sltScope});
    }

    setData = (data, pageMode) => {
        let slt = data.sltId ? data.sltId.name : null;
        data.serviceCategoryId = data.serviceCategoryId ? data.serviceCategoryId.id : null;
        data.serviceSubcategoryId = data.serviceSubcategoryId ? data.serviceSubcategoryId.id : null;
        this.setState({sltScope: data, slt:slt, pageMode: pageMode});
        this.findServiceSubcategoryByServiceCategoryId(data.serviceCategoryId);
    }

    getData = () => {
        return this.state.sltScope;
    }

    clearData = () => {
        this.setState({sltScope: Object.assign({}, defaultSltScope), slt:null, pageMode: null});
        this.findServiceSubcategoryByServiceCategoryId(defaultSltScope.serviceCategoryId);
    }
    
    onSltSelect = (data) => {
        let sltScope = this.state.sltScope;
        sltScope.sltId = data;
        this.setState({sltScope: sltScope, slt: data.name, displayDialog: false});
    }
    __handleChangeServiceCategory = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let sltScope = this.state.sltScope;
        sltScope[e.target.name] = value;
        this.findServiceSubcategoryByChangedServiceCategoryId(value);
    }
    findServiceSubcategoryByServiceCategoryId(serviceCategoryId){
        this.serviceSubcategoryService.findByCategoryId(serviceCategoryId).then(response => {
            this.setState({serviceSubcategoryList: response});
        });
    }
    findServiceSubcategoryByChangedServiceCategoryId=(serviceCategoryId)=>{
        this.serviceSubcategoryService.findByCategoryId(serviceCategoryId).then(response => {
            let sltScope = this.state.sltScope;
            sltScope.serviceSubcategoryId = response[0].id;
            this.setState({serviceSubcategoryList: response, sltScope: sltScope});
        });

    }

}
