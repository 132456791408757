import React, {Component} from 'react';
import {DataTable as DataTablePR} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Badge, Button} from 'reactstrap';
import {Button as ButtonPR} from "primereact/button";
import Translate from "../../../shared/language/Translate";
import PropTypes from 'prop-types';
import TextFormat from "../textFormat/TextFormat";
import DataModelForm from "../form/DataModelForm";
import {APP_DATE_FORMAT, convertDateTimeWithFormat, SERVER_URL} from '../../utils/Constants';
import {Dropdown} from "primereact/dropdown";
import Service from "../../service/Service";
import GeneralUtils from "../../utils/GeneralUtils";
import InputText from "../form/InputText";
import exportToExcel from "./export/exportToExcel";
import Loading from "../others/Loading";
import NumberFormat from "../form/NumberFormat";
import {MultiSelect} from "primereact/multiselect";

/**
 * Ümit Yıldırım ._.
 */
var filterTimeout = null;
export default class DataTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cols: this.props.fields,
            data: this.props.data ? this.props.data : [],
            loading: this.props.loading,
            selected: {},
            filterElement: [],
            first: 0,
            rows: 10,
            totalRecords: 0,
            sort: [],
            filter: []
        };
        // this.columns = this.props.fields;
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({cols: nextProps.fields});
        if (!this.props.dataURL) {
            this.setState({data: nextProps.data, loading: nextProps.loading});
        }
    }

    componentDidMount = () => {
        if (this.props.autoLoad !== false) {
            this.init();
        }
        if (this.props.save) {
            this.refForm.setColumns(this.state.cols);
            this.refForm.setData(this.state.selected);
        }
    }

    render() {
        return (
            <div className="content-section implementation">
                {(GeneralUtils.checkPrivilege(this.props.objectName, 'ViewList') || this.props.privilege === "allow") &&
                <DataTablePR {...this.props} value={this.state.data} header={this.getHeader()} footer={'Kayıt Sayısı : '+this.state.totalRecords}
                             // className="p-datatable-custom"
                             responsive={true}
                             paginator={true}
                             rows={this.state.rows} loading={this.state.loading}
                             totalRecords={this.state.totalRecords}
                             lazy={true}
                             first={this.state.first}
                    // onPageChange={this.onPageChange}
                             onPage={this.onPage}
                             sortField={this.state.sortField} sortOrder={this.state.sortOrder} onSort={this.onSort}
                             resizableColumns={true}
                             ref={(el) => {
                                 this.dt = el;
                             }}
                             emptyMessage="Kayıt Bulunamadı"
                             rowsPerPageOptions={[5, 10, 20, 50, 100]}
                             selectionMode={(GeneralUtils.checkPrivilege(this.props.objectName, 'View') || GeneralUtils.checkPrivilege(this.props.objectName, 'Modify') || this.props.privilege === "allow") ? this.props.selectionMode : null}
                             selection={(GeneralUtils.checkPrivilege(this.props.objectName, 'View') || GeneralUtils.checkPrivilege(this.props.objectName, 'Modify') || this.props.privilege === "allow") ? this.state.selected : null}
                             onSelectionChange={e => this.setState({selected: Object.assign({}, e.value)})}
                             onRowSelect={this.onDataSelect}
                             rowClassName={this.rowClassName}>
                    {this.getColumns()}
                </DataTablePR>
                }
                {this.getDataModelForm()}
                {this.state.loadingGlobal && <Loading/>}
            </div>
        );
    }

    init = () => {
        if (this.props.dataURL) {
            this.setState({
                first: 0,
                rows: 10,
                filter: [],
                filterElement: [],
                sort: [],
                sortField: null,
                sortOrder: null,
                loading: true
            });

            let gridModel = {"filter": [], "sort": [], "pageNumber": 0, "limit": 10};
            this.findAll(gridModel).then(response => {
                if(!GeneralUtils.isNullOrEmpty(response)) {
                    this.setState({data: response.data, totalRecords: response.totalCount ? response.totalCount : 0, loading: false});
                }
            });
        }
    }

    refresh = () => {
        this.init();
    }

    clear = () =>{
        this.setState({
            data: [],
            first: 0,
            rows: 10,
            filter: [],
            filterElement: [],
            sort: [],
            sortField: null,
            sortOrder: null
        });
    }


    onPage = (event) => {
        this.setState({first: event.first, rows: event.rows, loading: true});

        let gridModel = {"filter": this.state.filter, "sort": this.state.sort, "pageNumber": event.page, "limit": event.rows};
        this.findAll(gridModel).then(response => {
                if(!GeneralUtils.isNullOrEmpty(response)) {
                    this.setState({
                        data: response.data,
                        totalRecords: response.totalCount ? response.totalCount : 0,
                        loading: false
                    });
                }
            }
        );
    }

    onSort = (e) => {
        let sort = [{name: e.sortField, ascending: e.sortOrder === 1 ? true : false}];
        this.setState({sortField: e.sortField, sortOrder: e.sortOrder, sort: sort, first: 0, loading: true});

        let gridModel = {"filter": this.state.filter, "sort": sort, "pageNumber": 0, "limit": this.state.rows};
        this.findAll(gridModel).then(response => {
            if(!GeneralUtils.isNullOrEmpty(response)) {
                this.setState({
                    data: response.data,
                    totalRecords: response.totalCount ? response.totalCount : 0,
                    loading: false
                });
            }
        });
    }

    onModelFilterChange = (event, transient, filterMatchMode) => {
        let name = event.target.name;
        let value = event.value ? event.value : event.target.value;
        let type = event.target.type;
        let filter = this.state.filter;
        filter = filter.filter((e) => e.name !== name);
        filter = filter.filter((e) => e.name !== transient);
        if (!GeneralUtils.__isValueEmpty(value)){
            if(transient) {
                filter.push({name: transient, value: value, type: GeneralUtils.isNullOrEmpty(type) ? "string" : type, filterMatchMode: filterMatchMode});
            }else{
                filter.push({name: name, value: value, type: GeneralUtils.isNullOrEmpty(type) ? "string" : type, filterMatchMode: filterMatchMode});
            }
        }
        this.setState({filter: filter, first: 0, loading: true});

        let filterElement = this.state.filterElement;
        filterElement[name] = value;
        this.setState({filterElement: filterElement});

        //implementation goes here
        if (filterTimeout != null) {
            clearTimeout(filterTimeout);
        }
        filterTimeout = setTimeout(() => {
            let gridModel = {"filter": filter, "sort": this.state.sort, "pageNumber": 0, "limit": this.state.rows};
            this.findAll(gridModel).then(response => {
                if(!GeneralUtils.isNullOrEmpty(response)) {
                    this.setState({
                        data: response.data,
                        totalRecords: response.totalCount ? response.totalCount : 0,
                        loading: false
                    });
                }
            });
            filterTimeout = null;
        }, 500);
    }


    findAll(gridModel) {
        let URL = this.props.dataURL;
        if (this.state.parameter) {
            URL = URL + '/' + this.state.parameter
        }
        return fetch(SERVER_URL + URL, {
            crossDomain: true,
            method: 'put',
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")},
            body: JSON.stringify(gridModel)
        }).then(response => {
                let result = response.clone();
                GeneralUtils.checkUnauthorized(response);
                return result.json();
            }
        ).catch(error => {
            GeneralUtils.notification(error);
        });
    }


    save = (data) => {
        if (this.props.save) {
            this.props.save(data);
            this.refForm.setData({});
            this.refForm.close();
        }
        this.setState({selected: {}});
    }

    delete = (data) => {
        this.props.delete(data);
        this.setState({selected: {}});
        this.refForm.setData({});
        this.refForm.close();
    }

    onDataSelect = (e) => {
        if (this.props.onDataSelect) {
            this.props.onDataSelect(e.data);
        } else {
            let selected = Object.assign({}, e.data);
            this.setState({
                selected: selected
            });
            if (this.props.save) {
                this.refForm.setData(selected);
                this.refForm.open();
            }
        }
    }

    addNew = () => {
        this.setState({
            selected: {}
        });
        if (this.props.save) {
            this.refForm.setData({});
            this.refForm.open();
        }
    }

    complexCell = (rowData, column, col) => {
        return this.complexCellMultiField(rowData, column, col);
    }

    complexCellMultiField = (rowData, column, col) => {
        let objectName = column.field.split('.')[0];
        let fieldName = column.field.split('.')[1];
        let fieldNameOther = column.field.split('.')[2];
        let content = [];
        if (Array.isArray(rowData[objectName])) {
            rowData[objectName].forEach((obj) => {
                content.push(<Badge style={{marginRight: '5px', color: 'white', lineHeight: '1.5', letterSpacing: '.3px' }} color="info">&nbsp;{obj[fieldName]}&nbsp;</Badge>);
            });
            return <span>{content}</span>;
        } else {
            if (rowData[objectName]) {
                if (fieldNameOther) {
                    if (rowData[objectName][fieldName]) {
                        if (col.dataElement) {
                            content.push(col.dataElement[rowData[objectName][fieldName][fieldNameOther]]);
                        } else {
                            content.push(rowData[objectName][fieldName][fieldNameOther]);
                        }
                    }
                } else {
                    if (col.dataElement) {
                        content.push(col.dataElement[rowData[objectName][fieldName]]);
                    } else{
                        content.push(rowData[objectName][fieldName]);
                    }
                }
            }
            return <span style={{whiteSpace: "nowrap"}} title={content}>{content}</span>;
        }
    }

    dateCell = (rowData, column) => {
        return <TextFormat value={rowData[column.field]} type="date" format={APP_DATE_FORMAT} blankOnInvalid/>;
    }

    imageCell = (rowData, column) => {
        let image = rowData[column.field];
        console.log(image)
        if(!GeneralUtils.isNullOrEmpty(image)){
            return (<img src={"data:image/jpeg;base64,"+image}  alt="" className="datatable-img"/>);
        } else{
            return <img src="assets/layout/images/default-image.jpg" alt="" className="datatable-img"/>;
        }
    }


    moneyCell = (rowData, column) => {
        return <NumberFormat value={rowData[column.field]} displayType={'text'}
                             thousandSeparator="."
                             decimalSeparator=","
                             suffix={' TL'}
                             decimalScale={2} fixedDecimalScale={true}
                             renderText={value => <span style={{float: "right"}}>{value}</span>}/>
    }

    durationCell = (rowData, column) => {
        return <NumberFormat value={rowData[column.field]} displayType={'text'}
                             decimalSeparator=","
                             decimalScale={2} fixedDecimalScale={true}/>
    }

    percentCell = (rowData, column) => {
        return <NumberFormat value={rowData[column.field]} displayType={'text'} thousandSeparator={true} prefix={'% '}
                             decimalScale={2} fixedDecimalScale={true}
                             renderText={value => <span style={{float: "right"}}>{value}</span>}/>
    }

    dataElementCell = (rowData, column) => {
        return rowData[column.field];
    }

    onStatusChange = (event) => {
        this.dt.filter(event.value, event.target.name, 'equals');
        let filterElement = this.state.filterElement;
        filterElement[event.target.name] = event.value;
        this.setState({filterElement: filterElement});
        this.onModelFilterChange(event);
    }

    getOptions = (restURL) => {
        let options = null;
        this.service = new Service(restURL);
        this.service.findAll().then(response => options = response);
        return options;
    }


    export = () => {
        this.exportXLSX();
    }

    exportXLSX = ()=>{
        this.setState({loadingGlobal:true});
        let exportFilename = "download" + convertDateTimeWithFormat(Date.now(), 'DD.MM.YYYY_HH.mm');
        let gridModel = {"filter": this.state.filter, "sort": this.state.sort, "pageNumber": 0, "limit": -1};
        this.findAll(gridModel).then(response => {
            let data = response.data;
            let columns = this.state.cols;

            let dataAll = [];
            var len = columns.length;
            data.forEach((record, j) => {
                let dataTemp = {};
                for (var i = 0; i < len; i++) {
                    if (columns[i].field && columns[i].visible !== false && columns[i].isButton !==true) {
                        // '"' + (columns[i].header || columns[i].field) + '"'
                        // '"' + this.check(this.resolveFieldData(record, columns[i])) + '"'
                        dataTemp[(columns[i].header || columns[i].field)]=this.check(this.resolveFieldData(record, columns[i]));
                    }
                }
                dataAll.push(dataTemp);
            });
            exportToExcel(dataAll, exportFilename);
            this.setState({loadingGlobal: false});
        });
    }

    exportCSV() {
        this.setState({loadingGlobal:true});
        let exportFilename = "download" + convertDateTimeWithFormat(Date.now(), 'DD.MM.YYYY_HH.mm');
        let csvSeparator = ',';
        let gridModel = {"filter": this.state.filter, "sort": this.state.sort, "pageNumber": 0, "limit": -1};
        this.findAll(gridModel).then(response => {
            let data = response.data;
            let csv = '\ufeff';
            let columns = this.state.cols;

            //headers
            for (let i = 0; i < columns.length; i++) {
                if (columns[i].field && columns[i].visible !== false) {
                    csv += '"' + (columns[i].header || columns[i].field) + '"';

                    if (i < (columns.length - 1)) {
                        csv += csvSeparator;
                    }
                }
            }

            //body
            data.forEach((record, j) => {
                csv += '\n';
                for (let i = 0; i < columns.length; i++) {
                    if (columns[i].field && columns[i].visible !== false) {
                        csv += '"' + this.check(this.resolveFieldData(record, columns[i])) + '"';

                        if (i < (columns.length - 1)) {
                            csv += csvSeparator;
                        }
                    }
                }
            });

            let blob = new Blob([csv], {
                type: 'text/csv;charset=utf-8;'
            });

            if (window.navigator.msSaveOrOpenBlob) {
                navigator.msSaveOrOpenBlob(blob, exportFilename + '.csv');
            } else {
                let link = document.createElement("a");
                link.style.display = 'none';
                document.body.appendChild(link);
                if (link.download !== undefined) {
                    link.setAttribute('href', URL.createObjectURL(blob));
                    link.setAttribute('download', exportFilename + '.csv');
                    link.click();
                } else {
                    csv = 'data:text/csv;charset=utf-8,' + csv;
                    window.open(encodeURI(csv));
                }
                document.body.removeChild(link);
            }
            this.setState({loadingGlobal: false});
        });

    }

    resolveFieldData(data, col) {
        if (data && col.field) {
            if (col.formatMoney) {
                return data[col.field];
            } else if (col.formatPercent) {
                return data[col.field];
            } else if (col.field.includes('.')) {
                let fields = col.field.split('.');
                let value = data;
                for (var i = 0, len = fields.length; i < len; ++i) {
                    if (value == null) {
                        return null;
                    }
                    value = value[fields[i]];
                }
                return value;
            } else if (col.field.toUpperCase().includes('DATE')) {
                return convertDateTimeWithFormat(data[col.field], APP_DATE_FORMAT)
            } else if (col.field.toUpperCase().includes('IMAGE')) {
                return null;
            }
            if (col.dataElement) {
                return col.dataElement[data[col.field]]
            } else if (this.isFunction(col.field)) {
                return col.field(data);
            } else {
                return data[col.field];
            }
        } else {
            return null;
        }
    }

    isFunction(obj) {
        return !!(obj && obj.constructor && obj.call && obj.apply);
    }

    check(value) {
        return !GeneralUtils.__isValueEmpty(value) ? value : "";
    }

    rowClassName(rowData) {
        let status = rowData.status;
        return {'p-disabled': (status === 'P')};
    }

    getColumns = () => {
        let columns = [];
        // if (this.props.action) {
        // columns.push(<Column body={this.actionButton} style={{textAlign: 'center', width: '8em'}} />);
        // }
        this.state.cols.forEach((col) => {

            if (col.visible !== false) {
                if (col.field.includes('.')) {
                    let modelFilter = <InputText style={{width: '100%'}} className="ui-column-filter" name={col.field} type={col.type}
                                                 value={this.state.filterElement[col.field]}
                                                 onChange={e => this.onModelFilterChange(e, col.transient)}/>
                    columns.push(<Column key={col.field} field={col.field} header={col.header} filter={col.filter}
                                         body={(rowData, column) => this.complexCell(rowData, column, col)}
                                         filterMatchMode="contains" sortable={col.sortable} style={col.style}
                                         filterElement={modelFilter}/>)
                } else if (col.field.toUpperCase().includes('DATE')) {
                    let modelFilter = <InputText style={{width: '100%'}} className="ui-column-filter" name={col.field} type={col.type}
                                                 value={this.state.filterElement[col.field]}
                                                 onChange={this.onModelFilterChange}/>
                    columns.push(<Column key={col.field} field={col.field} header={col.header} filter={col.filter}
                                         body={this.dateCell}
                                         filterMatchMode="contains" sortable={col.sortable} style={col.style}
                                         filterElement={modelFilter}/>)
                } else if (col.filterElement) {
                    let options = null;
                    if (col.filterElement.restURL) {
                        this.getOptions(col.filterElement.restURL);
                    } else {
                        options = col.filterElement.options;
                    }
                    let dropdownFilter = <Dropdown style={{width: '100%'}} name={col.field}
                                                   value={this.state.filterElement[col.field]} options={options}
                                                   onChange={e => this.onModelFilterChange(e, null, col.filterMatchMode)}/>

                    if (col.dataElement) {
                        columns.push(<Column key={col.field} field={col.field} header={col.header} filter={col.filter}
                                             filterElement={dropdownFilter}
                                             body={(rowData, column) => {
                                                 if(column.field.includes('status')){
                                                     return <span className={`p-component status status-${rowData[column.field]}`}>
                                                         {col.dataElement[this.dataElementCell(rowData, column)]}</span>;
                                                 }
                                                 return col.dataElement[this.dataElementCell(rowData, column)]
                                             }}
                                             filterMatchMode="equals" sortable={col.sortable} style={col.style}
                                             editor={this.props[col.editor]}/>)
                    } else {
                        let modelFilter = <InputText style={{width: '100%'}} className="ui-column-filter" type={col.type}
                                                     name={col.field} value={this.state.filterElement[col.field]}
                                                     onChange={this.onModelFilterChange}/>
                        columns.push(<Column key={col.field} field={col.field} header={col.header} filter={col.filter}
                                             filterMatchMode="contains" sortable={col.sortable} style={col.style}
                                             filterElement={modelFilter}/>);
                    }
                } else if (col.formatMoney) {
                    let modelFilter = <InputText style={{width: '100%'}} className="ui-column-filter" name={col.field} type={col.type}
                                                 value={this.state.filterElement[col.field]}
                                                 onChange={this.onModelFilterChange}/>
                    columns.push(<Column key={col.field} field={col.field} header={col.header} filter={col.filter}
                                         body={this.moneyCell}
                                         filterMatchMode="contains" sortable={col.sortable} style={col.style}
                                         filterElement={modelFilter}/>)
                } else if (col.formatDuration) {
                    let modelFilter = <InputText style={{width: '100%'}} className="ui-column-filter" name={col.field} type={col.type}
                                                 value={this.state.filterElement[col.field]}
                                                 onChange={this.onModelFilterChange}/>
                    columns.push(<Column key={col.field} field={col.field} header={col.header} filter={col.filter}
                                         body={this.durationCell}
                                         filterMatchMode="contains" sortable={col.sortable} style={col.style}
                                         filterElement={modelFilter}/>)
                } else if (col.formatPercent) {
                    let modelFilter = <InputText style={{width: '100%'}} className="ui-column-filter" name={col.field} type={col.type}
                                                 value={this.state.filterElement[col.field]}
                                                 onChange={this.onModelFilterChange}/>
                    columns.push(<Column key={col.field} field={col.field} header={col.header} filter={col.filter}
                                         body={this.percentCell}
                                         filterMatchMode="contains" sortable={col.sortable} style={col.style}
                                         filterElement={modelFilter}/>)
                } else if (col.field.toUpperCase().includes('IMAGE')) {
                    columns.push(<Column key={col.field} field={col.field} header={col.header}
                                         body={this.imageCell}
                                         filterMatchMode="contains" style={col.style}/>)
                } else {
                    if (col.dataElement) {
                        let modelFilter = <InputText style={{width: '100%'}} className="ui-column-filter" type={col.type}
                                                     name={col.field} value={this.state.filterElement[col.field]}
                                                     onChange={this.onModelFilterChange}/>
                        columns.push(<Column key={col.field} field={col.field} header={col.header} filter={col.filter}
                                             body={(rowData, column) => {
                                                 return col.dataElement[this.dataElementCell(rowData, column)]
                                             }}
                                             filterMatchMode="equals" sortable={col.sortable} style={col.style}
                                             filterElement={modelFilter}/>)
                    } else {
                        let modelFilter = <InputText style={{width: '100%'}} className="ui-column-filter" type={col.type}
                                                     name={col.field} value={this.state.filterElement[col.field]}
                                                     onChange={e => this.onModelFilterChange(e, col.transient)}/>
                        columns.push(<Column key={col.field} field={col.field} header={col.header} filter={col.filter}
                                             filterMatchMode="contains" sortable={col.sortable} style={col.style}
                                             body={(rowData, column) => {
                                                 let text = rowData[column.field]
                                                 return <span style={{whiteSpace: "nowrap"}} title={text}>{text}</span>
                                             }}
                                             editor={this.props[col.editor]} filterElement={modelFilter}/>);
                    }
                }
            }
        });
        return columns;
    }


    getHeader = () => {
        if (this.props.header || this.props.export || this.props.refresh) {
            return <div className="p-clearfix"
                        style={{lineHeight: '1.87em'}}>
                {this.state.totalRecords > 0 && this.getExportButton()}
                {this.props.header}
                {this.getAddNewButton()}
                {this.props.refresh != false ? this.getRefreshButton() : ''}
                {/*<div style={{ textAlign:'left' }}>*/}
                {/*    <MultiSelect value={this.state.cols} options={this.columns} optionLabel="header" onChange={this.onColumnToggle} filter={true} style={{width:'20em !important'}}*/}
                {/*                 emptyFilterMessage="Seçili Kayıt Bulunamadı" selectedItemsLabel="{0} Kayıt Seçildi"/>*/}
                {/*</div>*/}
            </div>;
        }
    }

    // onColumnToggle=(event)=> {
    //     let selectedColumns = event.value;
    //     let orderedSelectedColumns = this.columns.filter(col => selectedColumns.some(sCol => sCol.field === col.field));
    //     this.setState({ cols: orderedSelectedColumns });
    // }


    getAddNewButton = () => {
        if (this.props.save) {
            return <Button style={{float: 'right'}} color="primary" onClick={this.addNew}>
                &nbsp;
                <Translate contentKey="entity.action.add">Yeni Kayıt</Translate>
            </Button>;
        }
    }

    getExportButton = () => {
        if (this.props.export) {
            return <Button style={{float: 'left'}} color="success" onClick={this.export} className="pi pi-file-excel">
                &nbsp;
                <Translate contentKey="entity.action.csv">İndir</Translate>
            </Button>;
        }
    }

    getRefreshButton = () => {
        return <ButtonPR icon="pi pi-refresh" style={{'float': 'right'}} onClick={this.refresh}/>
    }

    getDataModelForm = () => {
        let result;
        if (this.props.save) {
            result = (<DataModelForm ref={(elem) => this.refForm = elem} save={this.save} delete={this.props.delete}
                                     inDialog={this.props.inDialog}/>);
        }
        return result;
    }

    // actionButton=(rowData, column)=> {
    //     return <div>
    //         <ButtonPR type="button" icon="pi pi-search" className="p-button-success" style={{marginRight: '1em'}}></ButtonPR>
    //         <ButtonPR type="button" icon="pi pi-pencil" className="p-button-warning" onClick={(e) =>this.onDataSelect(e, rowData)}></ButtonPR>
    //     </div>;
    // }

    setParameter = (...parameter) => {
        this.state.parameter = parameter.reduce((previous, current) => {
            return previous + '/' + current;
        });
        this.forceUpdate();

        this.refresh();
    }

    getData = ()=>{
        return this.state.data;
    }

    setData =(data) =>{
        this.setState({data: data})
    }
}
DataTable.propTypes = {
    header: PropTypes.string,
    fields: PropTypes.array.isRequired,
    dataURL: PropTypes.string,
    save: PropTypes.func,
    delete: PropTypes.func,
    selectionMode: PropTypes.string,
    onDataSelect: PropTypes.func,
    export: PropTypes.bool
};

DataTable.defaultProps = {
    header: null,
    fields: [],
    data: []
};
