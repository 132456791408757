import React from 'react';
import {AvGroup} from "availity-reactstrap-validation";
import {Col, Label, Row} from "reactstrap";
import AvField from "../../shared/component/form/AvField";
import CityService from "../../shared/service/CityService";
import {STATUS} from "../../shared/utils/Constants"
const defaultDistrict = {
    id: '',
    name:'',
    cityId: 1,
    status: 'A',
    
};
export default class DistrictSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            district: defaultDistrict,
            cityList: [],
            pageMode: null
        };
        this.cityService = new CityService();
    }
    componentDidMount = () => {
        this.clearData();
        this.cityService.findAll().then(response => this.setState({cityList: response}));
    }

    render() {
        return (
            <Row className="justify-content-center">
                <Col>
                <AvGroup>
                        <Label for="cityId">İl Adı<span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="select"
                            className="form-control"
                            name="cityId"
                            value={this.state.district.cityId}
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                }
                            }}
                            onChange={this.__handleChange}>
                        {this.state.cityList.map(element => (
                            <option value={element.id} key={element.id}>
                                {element.name}
                            </option>
                        ))}
                        </AvField>
                    </AvGroup>
                    <AvGroup>
                        <Label for="name">İlçe Adı<span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="name"
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                },
                                minLength: {
                                    value: 1,
                                    errorMessage: 'Bu alan en az 1 karakterden oluşabilir.'
                                },
                                maxLength: {
                                    value: 100,
                                    errorMessage: 'Bu alan en fazla 100 karakterden oluşabilir.'
                                }
                            }}
                            value={this.state.district.name}
                            onChange={this.__handleChange}
                        />
                    </AvGroup>
                    
                    <AvGroup>
                        <Label for="status">Durum<span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="select"
                            className="form-control"
                            name="status"
                            value={this.state.district.status}
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                }
                            }}
                            onChange={this.__handleChange}>
                        {STATUS.map(element => (
                            <option value={element.value} key={element.value}>
                                {element.name}
                            </option>
                        ))}
                        </AvField>
                    </AvGroup>
                </Col>
            </Row>
        );
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let district = this.state.district;
        district[e.target.name] = value;
        this.setState({district: district});
    }

    setData = (data, pageMode) => {
        data.cityId = data.cityId ? data.cityId.id : null;
        this.setState({district: data, pageMode: pageMode});
    }

    getData = () => {
        return this.state.district;
    }

    clearData = () => {
        this.setState({district: Object.assign({}, defaultDistrict), pageMode: null});
    }

}
