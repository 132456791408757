import React from 'react';
import {AvForm, AvGroup} from "availity-reactstrap-validation";
import {Label} from "reactstrap";
import Translate from "../../../shared/language/Translate";
import AvField from "../../../shared/component/form/AvField";



const defaultLogMail = {
    id: '',
    subject: '',
    content: '',
    status: 'A'
};
export default class LogMailContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: defaultLogMail,
            pageMode: null
        };
    }

    render() {
        return (<div>
                <AvForm disabled={true}>
                    <AvGroup>
                        <Label for="mailFrom">
                            <Translate contentKey="logMailContent.mailFrom">Gönderen</Translate>
                            <span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="mailFrom"
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                },
                                minLength: {
                                    value: 1,
                                    errorMessage: 'Bu alan en az 1 karakterden oluşabilir.'
                                },
                                maxLength: {
                                    value: 256,
                                    errorMessage: 'Bu alan en fazla 256 karakterden oluşabilir.'
                                }
                            }}
                            value={this.state.data.mailFrom}
                            onChange={this.__handleChange}
                        />
                    </AvGroup>
                    <AvGroup>
                        <Label for="mailTo">
                            <Translate contentKey="logMailContent.mailTo">Alıcı</Translate>
                            <span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="mailTo"
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                },
                                minLength: {
                                    value: 1,
                                    errorMessage: 'Bu alan en az 1 karakterden oluşabilir.'
                                },
                                maxLength: {
                                    value: 256,
                                    errorMessage: 'Bu alan en fazla 256 karakterden oluşabilir.'
                                }
                            }}
                            value={this.state.data.mailTo}
                            onChange={this.__handleChange}
                        />
                    </AvGroup>
                    <AvGroup>
                        <Label for="cc">
                            <Translate contentKey="logMailContent.cc">CC</Translate>
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="cc"
                            value={this.state.data.cc}
                            onChange={this.__handleChange}
                        />
                    </AvGroup>
                    <AvGroup>
                        <Label for="bcc">
                            <Translate contentKey="logMailContent.bcc">BCC</Translate>
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="bcc"
                            value={this.state.data.bcc}
                            onChange={this.__handleChange}
                        />
                    </AvGroup>
                    <AvGroup>
                        <Label for="subject">
                            <Translate contentKey="logMailContent.subject">Konu</Translate>
                            <span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="subject"
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                },
                                minLength: {
                                    value: 1,
                                    errorMessage: 'Bu alan en az 1 karakterden oluşabilir.'
                                },
                                maxLength: {
                                    value: 256,
                                    errorMessage: 'Bu alan en fazla 256 karakterden oluşabilir.'
                                }
                            }}
                            value={this.state.data.subject}
                            onChange={this.__handleChange}
                        />
                    </AvGroup>
                    <AvGroup>
                        <Label for="content">
                            <Translate contentKey="logMailContent.content">İçerik</Translate>
                            <span style={{color: 'red'}}> (*)</span>
                            <div dangerouslySetInnerHTML={{__html: this.state.data.content}}
                                 onChange={this.__handleChangeEditor}

                            />
                        </Label>

                    </AvGroup>
                </AvForm>
            </div>
        );
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let data = this.state.data;
        data[e.target.name] = value;
        this.setState({data: data});
    }

    __handleChangeEditor = (e) => {
        let data = this.state.data;
        data.content = e;
        this.setState({data: data});
    }

    setData = (data, pageMode) => {
        let tempData;
        if(data.content.includes("cid:header")){
            tempData=data.content.toString().replace("cid:header","assets/header.jpg");
            data.content = tempData;
        }
        if(data.content.includes("cid:footer")){
            tempData=data.content.toString().replace("cid:footer","assets/footer.jpg");
            data.content =tempData;
        }

        this.setState({data: data, pageMode: pageMode});
    }

    getData = () =>{
        return this.state.data;
    }

    clearData = () => {
        this.setState({data: defaultLogMail})
    }
}
