import React from 'react';
import PropTypes from 'prop-types';
import TeamContactService from "../../../shared/service/TeamContactService";
import {Button, Col, Label, Row} from "reactstrap";
import {AvGroup} from "availity-reactstrap-validation";
import Translate from "../../../shared/language/Translate";
import {Fieldset} from "primereact/fieldset";
import AvField from "../../../shared/component/form/AvField";
import {TEAM_CONTACT_TYPE, PAGE_MODE} from "../../../shared/utils/Constants";
import ContactService from "../../../shared/service/ContactService";
import ContactColumn from '../../../shared/component/dataTable/columns/ContactColumn';
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import {toast} from "react-toastify";
import ContactGroup from "../../../shared/component/hsmGroup/ContactGroup";

export default class TeamContactSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            teamContact: {teamId: null, contactId: null},
            pageMode: null,
            contactData: null
        };
        this.service = new TeamContactService();
        this.contactService = new ContactService();
    }

    render() {
        return (
            <div>

                <Toolbar display={true}>
                    <div className="p-toolbar-group-right">
                        <Button color="primary" className="float-right" onClick={this.addTeamContact}>
                            <Translate
                                contentKey="entity.action.save">{this.state.pageMode === PAGE_MODE.UPDATE ? 'Kaydet' : 'Ekle'}</Translate>
                        </Button>
                        <Button color="primary" className="float-right"
                                onClick={this.props.closeDialog}>
                            <Translate contentKey="entity.action.save">Vazgeç</Translate>
                        </Button>
                        {this.state.pageMode === PAGE_MODE.UPDATE &&
                        <Button color="primary" className="float-right" onClick={this.deleteTeamContact}>
                            <Translate contentKey="entity.action.save">Sil</Translate>
                        </Button>
                        }
                    </div>
                </Toolbar>
                <Fieldset legend="Üye Bilgileri">
                    <Row>
                        <Col>
                            <ContactGroup
                                value={this.state.contact}
                                onDataSelect={this.onContactSelect}
                                dataTable={{
                                    dataURL: "contact/findNotUsedInTeamContact",
                                    parameter: this.state.teamContact.teamId ? this.state.teamContact.teamId.id : 0,
                                    fields: ContactColumn.fields
                                }}
                                disabled={this.state.pageMode === PAGE_MODE.VIEW}
                                validate={{}}
                            />
                        </Col>
                        <Col>
                            <AvGroup>
                                <Label for="roleTC">
                                    <Translate contentKey="teamContactManagement.role">Ekipteki Rolü</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvField type="select" className="form-control" name="roleTC"
                                         value={this.state.teamContact.role} onChange={this.__handleChange}>
                                    <option value='' key=''/>
                                    {TEAM_CONTACT_TYPE.map(element => (
                                        <option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>
                        </Col>
                    </Row>

                </Fieldset>
            </div>
        );
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let teamContact = this.state.teamContact;
        teamContact[e.target.name.replace("TC", "")] = value;
        this.setState({teamContact: teamContact});
    }

    setData = (data, pageMode) => {
        let contact = data.contactId.givenNames + ' ' + data.contactId.familyName;
        this.setState({teamContact: data, contact: contact, pageMode: pageMode});
    }

    clearData = () => {
        this.setState({
            teamContact: {teamId: null, contactId: null},
            contact: null,
            pageMode: null
        });
    }


    onContactSelect = (data) => {
        let teamContact = this.state.teamContact;
        teamContact.contactId = data;
        let contact = data.givenNames + ' ' + data.familyName;
        this.setState({teamContact: teamContact, contact: contact, displayDialog: false});
    }

    addTeamContact = () => {
        if (GeneralUtils.isNullOrEmpty(this.state.teamContact.contactId) || GeneralUtils.isNullOrEmpty(this.state.teamContact.role)) {
            return toast.warn("Zorunlu Alan : Lütfen Kontak ve Rol Seçiniz!");
        }
        if (this.props.add) {
            this.props.add(this.state.teamContact);
        }
        if (this.props.closeDialog) {
            this.props.closeDialog();
        }
    }

    deleteTeamContact = () => {
        if (this.props.delete) {
            this.props.delete(this.state.teamContact);
        }
        if (this.props.closeDialog) {
            this.props.closeDialog();
        }
    }

    // save = () => {
    //     let teamContact = this.state.teamContact;
    //     if (!GeneralUtils.isNullOrEmpty(this.state.teamContact.id)) {
    //         this.service.update(this.state.teamContact).then(() => {
    //             this.setState({teamContact: teamContact, pageMode: PAGE_MODE.UPDATE});
    //             if (this.props.refresh) {
    //                 this.props.refresh();
    //             }
    //         });
    //     } else {
    //         this.service.create(this.state.teamContact).then((response) => {
    //             teamContact.id = response.id;
    //             this.setState({teamContact: teamContact, pageMode: PAGE_MODE.UPDATE});
    //             if (this.props.refresh) {
    //                 this.props.refresh();
    //             }
    //         });
    //     }
    // }
    //
    // delete = () => {
    //     this.service.delete(this.state.teamContact.id).then(() => {
    //         if (this.props.refresh) {
    //             this.props.refresh();
    //         }
    //     });
    // }
}
TeamContactSave.propTypes = {
    add: PropTypes.func,
    delete: PropTypes.func
};