import React from "react";
import PropTypes from 'prop-types';
import SelectDataTable from '../form/SelectDataTable';
import HsmOrganizationService from "../../../shared/service/HsmOrganizationService";
import HsmOrganizationColumnForDialog from '../../../shared/component/dataTable/columns/HsmOrganizationColumnForDialog';
import Dialog from "../dialog/Dialog";
import {PAGE_MODE} from "../../utils/Constants";
import {TabPanel, TabView} from "primereact/tabview";
import BillingAccountView from "../../../module/asset-management/billing-account/BillingAccountView";
import GeneralUtils from "../../utils/GeneralUtils";
import HsmOrganizationView from "../../../module/account/organization/HsmOrganizationView";
import LocationColumn from "../dataTable/columns/LocationColumn";
import LocationService from "../../service/LocationService";

export default class LocationGroupWithSearchButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            value: this.props.value,
            validate: this.props.validate,
            dataTable: this.props.dataTable,
            label: this.props.label,
            disabled: this.props.disabled,
            name: this.props.name
        };
        this.service = new LocationService();
    }

    componentDidMount = () => {
        // if(this.state.data && GeneralUtils.checkPrivilege(LocationColumn.name, 'View')) {
        //     this.refHsmOrganizationView.setPageMode(PAGE_MODE.VIEW);
        //     this.refBillingAccountView.setPageMode(PAGE_MODE.VIEW);
        // }
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({
            data: this.props.data,
            validate: nextProps.validate,
            value: nextProps.value,
            dataTable: nextProps.dataTable,
            label: nextProps.label,
            disabled: nextProps.disabled,
            name: nextProps.name
        });
    }

    render() {
        let validate = this.state.validate;
        if (!validate) {
            validate = {
                required: {
                    value: true,
                    errorMessage: "Bu alanın doldurulması zorunludur."
                }
            };
        }

        let dataTable = this.state.dataTable;
        if (!dataTable) {
            dataTable = {
                dataURL : "location/findAll",
                fields: LocationColumn.fields
            };
        }
        let label = this.state.label;
        if (!label) {
            label = "Lokasyon Adı"
        }
        let name = this.state.name;
        if (!name) {
            name = "locationId"
        }
        let openDetailDialog = (this.state.data && GeneralUtils.checkPrivilege(LocationColumn.name, 'View')) ? this.openDetailDialog : false;
        return (
            <div>
                <SelectDataTable
                    value={this.state.value}
                    validate={validate}
                    dataTable={dataTable}
                    disabled={this.state.disabled}
                    name={name}
                    translateKey="asset-management.locationId"
                    label={label}
                    dialogHeader="Lokasyon Listesi"
                    onDataSelect={this.props.onDataSelect}
                    autoRefrestData={false}
                    openDetailDialog={openDetailDialog}
                />
                {(this.state.data && GeneralUtils.checkPrivilege(LocationColumn.name, 'View')) && this.getDetailDialog()}
            </div>
        );
    }

    openDetailDialog = () => {
        this.refHsmOrganizationView.setData(Object.assign({}, this.state.data), PAGE_MODE.VIEW);
        this.refBillingAccountView.setDataByOrganizationId(this.state.data.id, PAGE_MODE.VIEW);
        this.setState({displayDialog: true});
    }

    getDetailDialog = () => {
        let result = (
            <Dialog style={{width: '70%'}}
                    visible={this.state.displayDialog}
                    header="Organizasyon ve Fatura Hesabı Bilgileri"
                    onHide={() => this.setState({displayDialog: false})}>
                <TabView activeIndex={this.state.activeIndex}
                         onTabChange={(e) => this.setState({activeIndex: e.index})} renderActiveOnly={false}>
                    <TabPanel
                        header="Organizasyon Bilgileri">
                        <HsmOrganizationView save={this.findAll} ref={(elem) => this.refHsmOrganizationView = elem}
                                             closeDisplayDialog={() => this.setState({displayDialog: false})}/>
                    </TabPanel>

                    <TabPanel header="Fatura Hesabı">
                        <BillingAccountView ref={(elem) => this.refBillingAccountView = elem}/>
                    </TabPanel>
                </TabView>
            </Dialog>
        );
        return result;
    }

}
LocationGroupWithSearchButton.propTypes = {
    data: PropTypes.object,
    value: PropTypes.string,
    validate: PropTypes.object,
    dataTable: PropTypes.object,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    name: PropTypes.string
}