import React, {Component} from 'react';
import './OverviewBox.css';
export default class OverviewBox extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: {
                title: this.props.title,
                count: this.props.count,
                link: this.props.link,
                icon: this.props.icon
            }
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ data: nextProps });
    }

    render() {
        return (
            <div className="p-col-12 p-lg-2">
                <a style={{color: '#000'}} href={this.state.data.link}>
                    <div className="card-overview overview-box">
                        <div className="overview-info"><h6>{this.state.data.title}</h6><h3>{this.state.data.count}</h3>
                        </div>
                        <img src={this.state.data.icon} height={60}/>
                    </div>
                </a>
            </div>
        );
    }
}