import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";
import {toast} from "react-toastify";

export default class HsmJobservice extends Service {
    constructor() {
        super();
        this.baseURL = 'hsmJob';
    }


    run (name) {
        return fetch(SERVER_URL + this.baseURL + '/run/' + name, {
            crossDomain: true,
            method: 'get',
            headers: {'Content-Type': 'application/json',Authorization: localStorage.getItem("Authorization")},
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }


}