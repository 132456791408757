import React from "react";
import {Dialog} from 'primereact/dialog';
import {AvField, AvForm, AvGroup} from 'availity-reactstrap-validation';
import Translate from "../../../shared/language/Translate";
import {Button, Label} from 'reactstrap';
import PropTypes from 'prop-types';

export default class DataModelForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            displayDialog: false,
            data: {}
        };
    }

    render() {
        return (
            this.getFormComponent()
        );
    }

    getFormComponent = () => {
        let result;
        if (this.props.inDialog) {
            result = (
                <AvForm onValidSubmit={this.save}>
                    <Dialog visible={this.state.displayDialog} width="450px" header={this.props.header} modal={true}
                        footer={this.getDialogFooter()} onHide={() => this.setState({ displayDialog: false })}
                        blockScroll={false}>
                        {this.getForm()}
                    </Dialog>
                </AvForm>
            );
        } else {
            result = (
                <AvForm onValidSubmit={this.save}>
                    {this.getForm()}
                    {this.getSaveButton()}
                    {this.getDeleteButton()}
                </AvForm>
            );
        }
        return result;
    }

    save = () => {
        if (this.props.save) {
            this.props.save(this.state.data);
        }
    }

    getForm = () => {
        let form = [];
        if (this.state.columns) {
            this.state.columns.forEach((col) => {
                form.push(
                    <AvGroup>
                        <Label for={col.field}>
                            <Translate contentKey={col.field}>{col.header}</Translate>
                        </Label>
                        <AvField type="text" className="form-control" name={col.field}
                            value={this.state.data[col.field] ? this.state.data[col.field] : ""}
                            validate={col.validate}
                            onChange={(e) => this.updateProperty(col.field, e.target.value)}
                            placeholder={col.placeholder} />
                    </AvGroup>
                );
            });
        }
        return form;
    }

    getDialogFooter = () => {
        return <div className="ui-dialog-buttonpane p-clearfix">
            {this.getDeleteButton()}
            {this.getSaveButton()}
        </div>;
    }

    updateProperty = (property, value) => {
        let data = this.state.data;
        data[property] = value;
        this.setState({ data: data });
    }

    getDeleteButton = () => {
        if (this.props.delete) {
            return <Button color="primary" onClick={this.delete}>
                &nbsp;
                <Translate contentKey="entity.action.delete">Sil</Translate>
            </Button>;
        }
    }

    getSaveButton = () => {
        if (this.props.save) {
            return <Button color="primary" type="submit">
                &nbsp;
                <Translate contentKey="entity.action.save">Kaydet</Translate>
            </Button>;
        }
    }

    open = () => {
        this.setState({ displayDialog: true });
    }

    close = () => {
        this.setState({ displayDialog: false });
    }

    setData = (data) => {
        this.setState({ data: data });
    }

    setColumns = (columns) => {
        this.setState({ columns: columns });
    }
}

DataModelForm.propTypes = {
    inDialog: PropTypes.bool,
    header: PropTypes.string,
    save: PropTypes.func,
    delete: PropTypes.func,
};

DataModelForm.defaultProps = {
    header: null,
    data: {}
};
