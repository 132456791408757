import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class HsmSystemParameterService {
    constructor() {
        this.baseURL = 'hsmSystemParameter';
    }

    findByKey(key) {
        return fetch(SERVER_URL + this.baseURL + '/findByKey/'+ key, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }


    doesSystemWorkInLiveMode() {
        return fetch(SERVER_URL + this.baseURL + '/doesSystemWorkInLiveMode', {
            headers: {'Content-Type': 'application/json'}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    findMaxReopenCountByKey() {
        return fetch(SERVER_URL + this.baseURL + '/findMaxReopenCountByKey' , {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

}
