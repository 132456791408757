import React from 'react';
import {Input} from "reactstrap";
import {AvField} from "availity-reactstrap-validation";
import InputMask from "react-input-mask";
import PropTypes from "prop-types";
import GeneralUtils from "../../utils/GeneralUtils";


export class PhoneField extends React.Component {

    render() {
        let value = this.props.value;
        if (GeneralUtils.__isValueEmpty(value)) {
            value = '';
        }

        return (<div>
                {this.props.kind === "mobile" &&
                <AvField
                    {...this.props}
                    mask="(599) 999-9999"
                    maskChar="-"
                    tag={[Input, InputMask]}
                    helpMessage={this.props.helpMessageDisplay ? "Mobil telefon numarası 5 ile başlayabilir" : null}
                    onChange= {this.onChange}
                />}

                {this.props.kind === "business" &&
                <AvField
                    {...this.props}
                    mask="(x99) 999-9999"
                    maskChar="-"
                    formatChars={{
                        '9': '[0-9]',
                        'x': '[2-4]|[8]'
                    }}
                    tag={[Input, InputMask]}
                    helpMessage={this.props.helpMessageDisplay ?  "İş telefonu  2 , 3 , 4  veya 8 ile başlayabilir" : null}
                    onChange= {this.onChange}
                />}

                {this.props.kind === "any" &&
                <AvField
                    {...this.props}
                    mask="(x99) 999-9999"
                    maskChar="-"
                    formatChars={{
                        '9': '[0-9]',
                        'x': '[2-9]'
                    }}
                    tag={[Input, InputMask]}
                    onChange= {this.onChange}
                />}
            </div>
        );
    }

    onChange=(e)=>{
        if(e.target.parsedValue !== undefined){
            e.target.parsedValue = GeneralUtils.getReplaceValue(e.target.parsedValue)
        }else{
            e.target.value = GeneralUtils.getReplaceValue( e.target.value)
        }
        if(this.props.onChange) {
            this.props.onChange(e);
        }
    }
}

PhoneField.propTypes = {
    kind: PropTypes.string,
    helpMessageDisplay: PropTypes.bool
};
PhoneField.defaultProps = {
    kind: "business"
};