import Service from './Service';
import GeneralUtils from "../utils/GeneralUtils";
import {SERVER_URL} from "../utils/Constants";

export default class HsmEdrService extends Service {
    constructor() {
        super();
        this.baseURL = 'hsmEdr';
    }

    //assetType kullanımı doğru
    findByAssetTypeAndAssetId(assetType, assetId) {
        return fetch(SERVER_URL + this.baseURL + '/findByAssetTypeAndAssetId/' + assetType +'/'+assetId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }
}