import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class MonitoringPageService extends Service {
    constructor() {
        super();
        this.baseURL = 'monitoringPage';
    }

    findByOrgId(organizationId) {
        return fetch(SERVER_URL + this.baseURL + '/findByOrgId/' + organizationId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")},
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    findByLocId(locationId) {
        return fetch(SERVER_URL + this.baseURL + '/findByLocId/' + locationId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")},
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    findLocByOrgId(organizationId) {
        return fetch(SERVER_URL + this.baseURL + '/findLocByOrgId/', +organizationId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")},
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }


    findAllBBProductByOrgId(organizationId) {
        return fetch(SERVER_URL + this.baseURL + '/findAllBBProductByOrgId/' + organizationId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        })
            .then(response => response.json())
            .catch(error => {
                GeneralUtils.notification(error);
            });
    }


    //---

    findAllUsersByOrgId(organizationId) {
        return fetch(SERVER_URL + this.baseURL + '/' + organizationId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        })
            .then(response => response.json())
            .catch(error => {
                GeneralUtils.notification(error);
            });
    }

    findAllContactByOrgId(organizationId) {
        return fetch(SERVER_URL + this.baseURL + '/' + organizationId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        })
            .then(response => response.json())
            .catch(error => {
                GeneralUtils.notification(error);
            });
    }

    //---

    findAllServiceRequestByOrgId(organizationId) {
        return fetch(SERVER_URL + this.baseURL + '/findAllServiceRequestByOrgId/' + organizationId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        })
            .then(response => response.json())
            .catch(error => {
                GeneralUtils.notification(error);
            });
    }

    findAllIncidentByOrgId(organizationId) {
        return fetch(SERVER_URL + this.baseURL + '/findAllIncidentByOrgId/' + organizationId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        })
            .then(response => response.json())
            .catch(error => {
                GeneralUtils.notification(error);
            });
    }

    findOrgIdByLocId(locationId) {
        return fetch(SERVER_URL + this.baseURL + '/findAllOrgByLocId/' + locationId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")},
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }


    findAllBBProductByLocId(locationId) {
        return fetch(SERVER_URL + this.baseURL + '/findAllBBProductByLocId/' + locationId , {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        })
            .then(response => response.json())
            .catch(error => {
                GeneralUtils.notification(error);
            });
    }

    findAllUsersByLocId(locationId) {
        return fetch(SERVER_URL + this.baseURL + '/' + locationId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        })
            .then(response => response.json())
            .catch(error => {
                GeneralUtils.notification(error);
            });
    }

    findAllContactByLocId(locationId) {
        return fetch(SERVER_URL + this.baseURL + '/findAllContactByLocId/' + locationId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        })
            .then(response => response.json())
            .catch(error => {
                GeneralUtils.notification(error);
            });
    }

    findAllServiceRequestByLocId(locationId) {
        return fetch(SERVER_URL + this.baseURL + '/findAllServiceRequestByLocId/' + locationId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        })
            .then(response => response.json())
            .catch(error => {
                GeneralUtils.notification(error);
            });
    }

    findAllIncidentByLocId(locationId) {
        return fetch(SERVER_URL + this.baseURL + '/findAllIncidentByLocId/' + locationId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        })
            .then(response => response.json())
            .catch(error => {
                GeneralUtils.notification(error);
            });
    }
//bb_product search services

    findLocationByBbProductId(bbProductId) {
        return fetch(SERVER_URL + this.baseURL + '/findLocationByBbProductId/' + bbProductId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")},
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }


    findByBbProductId(bbProductId) {
        return fetch(SERVER_URL + this.baseURL + '/findByBbProductId/' + bbProductId , {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        })
            .then(response => response.json())
            .catch(error => {
                GeneralUtils.notification(error);
            });
    }


}
