import React from 'react';
import {Col, Label} from 'reactstrap';
import {AvFeedback, AvGroup} from 'availity-reactstrap-validation';
import AvField from '../../../shared/component/form/AvField';
import PropTypes from 'prop-types';
import Translate from "../../../shared/language/Translate";
import GeneralUtils from '../../../shared/utils/GeneralUtils';
import ContactService from "../../../shared/service/ContactService";
import LocationService from "../../../shared/service/LocationService";
import {CONTACT_TYPE, PAGE_MODE, STATUS, TEAM_CONTACT_TYPE} from "../../../shared/utils/Constants";
import {Fieldset} from "primereact/fieldset";
import ModalConfirm from "../../request/hsmDialog/ModalConfirm";
import OrganizationGroup from "../../../shared/component/hsmGroup/OrganizationGroup";
import {PhoneField} from "../../../shared/component/form/PhoneField";
import HsmOrganizationService from "../../../shared/service/HsmOrganizationService";
import AvFieldUpper from "../../../shared/component/form/AvFieldUpper";
import {PickList} from "primereact/picklist";
import HsmRoleService from "../../../shared/service/HsmRoleService";
import TeamService from "../../../shared/service/TeamService";

const defaultContact = {
    userName:'',
    id: '',
    hsmRoleList: [],
    teamList: [],
    turkishIdentityNo: null,
    name: '',
    familyName: '',
    givenNames: '',
    status: 'P',
    contactType: '',
    email: '',
    mobilePhone: '',
    businessPhone: '',
    faxNumber: '',
    workTitle: '',
    employeeNo: '',
    taxOffice: '',
    organizationId: null,
    locationId: null,
    role: ''
};

export default class HsmUserQuickSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contact: defaultContact,
            organizationList: [],
            roleList: [],
            teamList: [],
            locationList: [],
            pageMode: null,
            organization: null,
            avaibleRoleList: [],
            selectedRoleList: [],
            avaibleTeamList: [],
            selectedTeamList: [],
            locationHidden: true
        }
        this.service = new ContactService();
        this.hsmRoleService = new HsmRoleService();
        this.teamService = new TeamService();
        this.locationService = new LocationService();
        this.hsmOrganizationService = new HsmOrganizationService();
    }

    componentDidMount = () => {
        this.hsmRoleService.findAll().then(response => this.setState({roleList: response}));
        this.teamService.findAll().then(response => this.setState({teamList: response}));
    }
    render() {
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <AvGroup>
                                <Label for="userName">
                                    <Translate contentKey="userManagement.userName">Kullanıcı</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvField disabled={this.state.pageMode === PAGE_MODE.UPDATE}
                                         type="text"
                                         className="form-control"
                                         name="userName"
                                         validate={{
                                             required: {
                                                 value: true,
                                                 errorMessage: 'Bu alan zorunlu.'
                                             },
                                             pattern: {
                                                 value: '^[_.@A-Za-z0-9-]*$',
                                                 errorMessage: 'Bu alan ^[_.@A-Za-z0-9-]*$ şablonuna uymuyor.'
                                             },
                                             minLength: {
                                                 value: 1,
                                                 errorMessage: 'Bu alan en az 1 karakterden oluşabilir.'
                                             },
                                             maxLength: {
                                                 value: 50,
                                                 errorMessage: 'Bu alan en fazla 50 karakterden oluşabilir.'
                                             }
                                         }}
                                         value={this.state.contact.userName}
                                         onChange={this.__handleChange}
                                />
                            </AvGroup>
                            <AvGroup>
                                <Label for="turkishIdentityNo">
                                    <Translate contentKey="contactManagement.turkishIdentityNo">TCK No</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="turkishIdentityNo"
                                    validate={{
                                        minLength: {
                                            value: 11,
                                            errorMessage: "Bu alana 11 karakter girmelisiniz."
                                        },
                                        maxLength: {
                                            value: 11,
                                            errorMessage: "Bu alana 11 karakter girmelisiniz."
                                        }
                                    }}
                                    value={this.state.contact.turkishIdentityNo} onChange={this.__handleChange}/>
                            </AvGroup>
                            <AvGroup>
                                <Label for="givenNames">
                                    <Translate contentKey="contactManagement.givenNames">Ad</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvFieldUpper
                                    type="text"
                                    className="form-control"
                                    name="givenNames"
                                    validate={{
                                        maxLength: {
                                            value: 50,
                                            errorMessage: "Bu alana en fazla 50 karakter girebilirsiniz."
                                        },
                                        required: {
                                            value: "true",
                                            errorMessage: "Bu alanın doldurulması zorunludur."
                                        }
                                    }}
                                    value={this.state.contact.givenNames} onChange={this.__handleChange}/>
                                <AvFeedback>This field cannot be longer than 50 characters.</AvFeedback>
                            </AvGroup>
                            <AvGroup>
                                <Label for="familyName">
                                    <Translate contentKey="contactManagement.familyName">Soyad</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvFieldUpper
                                    type="text"
                                    className="form-control"
                                    name="familyName"
                                    validate={{
                                        maxLength: {
                                            value: 50,
                                            errorMessage: "Bu alana en fazla 50 karakter girebilirsiniz."
                                        },
                                        required: {
                                            value: "true",
                                            errorMessage: "Bu alanın doldurulması zorunludur."
                                        }
                                    }}
                                    value={this.state.contact.familyName} onChange={this.__handleChange}/>
                                <AvFeedback>This field cannot be longer than 50 characters.</AvFeedback>
                            </AvGroup>
                            <AvGroup>
                                <Label for="contactType">
                                    <Translate contentKey="contactManagement.contactType">Kontak Tipi</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvField type="select" className="form-control" name="contactType"
                                         value={this.state.contact.contactType} onChange={this.__handleChange}
                                         validate={{
                                             required: {
                                                 value: "true",
                                                 errorMessage: "Bu alanın doldurulması zorunludur."
                                             }
                                         }}>
                                    <option value='' key=''/>
                                    {CONTACT_TYPE.map(element => (
                                        <option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>

                            <AvGroup>
                                <Label for="workTitle">
                                    <Translate contentKey="contactManagement.workTitle">Ünvan</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="workTitle"
                                    validate={{
                                        maxLength: {
                                            value: 254,
                                            errorMessage: "Bu alana en fazla 254 karakter girebilirsiniz."
                                        },
                                        required: {
                                            value: "true",
                                            errorMessage: "Bu alanın doldurulması zorunludur."
                                        }
                                    }}
                                    value={this.state.contact.workTitle} onChange={this.__handleChange}/>
                            </AvGroup>

                            <div className="col">
                                <Fieldset legend="Kullanıcı Rol Bilgileri">

                                    <AvGroup>
                                        <PickList source={this.state.avaibleRoleList} target={this.state.selectedRoleList}
                                                  itemTemplate={this.pickListTemplate}
                                                  onChange={(e) => {this.setState({
                                                      avaibleRoleList: e.source,
                                                      selectedRoleList: e.target
                                                  });
                                                      // this.checkAllowedOrganization(e.target);
                                                  }
                                                  }
                                                  sourceHeader="Roller" targetHeader="Seçili Roller" responsive={true}
                                                  showSourceControls={false} showTargetControls={false}
                                                  sourceStyle={{height: '150px'}} targetStyle={{height: '150px'}}/>
                                    </AvGroup>
                                </Fieldset>
                            </div>
                        </div>
                        <div className="col">
                            <Fieldset legend="Organizasyon & Lokasyon Bilgileri">
                                <OrganizationGroup
                                    value={this.state.organization}
                                    onDataSelect={this.onOrganizationSelect}
                                    disabled={this.state.pageMode === PAGE_MODE.VIEW}
                                />
                                <AvGroup>
                                    <Label for="locationId">
                                        <Translate contentKey="contactManagement.locationId">Lokasyon
                                            Adı</Translate>
                                    </Label>
                                    <AvField type="select" className="form-control" name="locationId"
                                             disabled={this.state.locationHidden}
                                             value={this.state.contact.locationId} onChange={this.__handleChange}>
                                        <option value=""/>
                                        {this.state.locationList.map(element => (
                                            <option value={element.id} key={element.id}>
                                                {element.name}
                                            </option>
                                        ))}
                                    </AvField>
                                </AvGroup>
                            </Fieldset>
                            <br/>
                            <AvGroup>
                                <Label for="email">
                                    <Translate contentKey="billing-account.email">E-Posta</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvField
                                    name="email"
                                    type="email"
                                    validate={{
                                        required: {
                                            value: "true",
                                            errorMessage: "Bu alanın doldurulması zorunludur."
                                        },
                                        minLength: {
                                            "value": 5,
                                            "errorMessage": "Geçersiz E-Posta."
                                        },
                                        maxLength: {
                                            value: 50,
                                            errorMessage: "E-posta 50 karakterden uzun olamaz."
                                        }
                                    }}
                                    value={this.state.contact.email} onChange={this.__handleChange}/>
                            </AvGroup>
                            <AvGroup>
                                <Label for="mobilePhone">
                                    <Translate contentKey="contactManagement.mobilePhone">GSM No</Translate>
                                </Label>
                                <PhoneField kind={'mobile'}
                                            name="mobilePhone" errorMessage="Geçersiz Numara Girişi"
                                            value={this.state.contact.mobilePhone}
                                            onChange={this.__handleChange}
                                />
                            </AvGroup>


                            <AvGroup>
                                <Label for="status">
                                    <Translate contentKey="contactManagement.status">Durum</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvField type="select" className="form-control" name="status"
                                         disabled={true}
                                         value={this.state.contact.status} onChange={this.__handleChange}>
                                    {STATUS.map(element => (
                                        <option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>




                            <div className="col">
                                <Fieldset legend="Kullanıcı Ekip Bilgileri">
                                    <AvGroup>
                                        <PickList source={this.state.avaibleTeamList}
                                                  target={this.state.selectedTeamList}
                                                  itemTemplate={this.pickListTemplate}
                                                  onChange={(e) => {
                                                      this.setState({
                                                          avaibleTeamList: e.source,
                                                          selectedTeamList: e.target
                                                      });
                                                      // this.checkAllowedOrganization(e.target);
                                                  }
                                                  }
                                                  sourceHeader="Ekipler" targetHeader="Seçili Ekipler" responsive={true}
                                                  showSourceControls={false} showTargetControls={false}
                                                  sourceStyle={{height: '150px'}} targetStyle={{height: '150px'}}/>
                                    </AvGroup>
                                    <AvGroup>
                                        <Label for="roleTC">
                                            <Translate contentKey="teamContactManagement.role">Ekipteki Rolü</Translate>
                                            <span style={{color: 'red'}}> (*)</span>
                                        </Label>
                                        <AvField type="select" className="form-control" name="role"
                                                 validate={{
                                                     required: {
                                                         value: "true",
                                                         errorMessage: "Bu alanın doldurulması zorunludur."
                                                     }
                                                 }}
                                                 value={this.state.contact.role} onChange={this.__handleChange}>
                                            <option value='' key=''/>
                                            {TEAM_CONTACT_TYPE.map(element => (
                                                <option value={element.value} key={element.value}>
                                                    {element.name}
                                                </option>
                                            ))}
                                        </AvField>
                                    </AvGroup>
                                </Fieldset>
                            </div>

                        </div>
                    </div>
                </div>

                <ModalConfirm header='Onay' onOkClick={this.saveQuickUser}
                              onCancelClick={() => this.setState({displayConfirmDialog: false})}
                              show={this.state.displayConfirmDialog}
                              message='Bu Kontak için sistemde tanımlı bir kullanıcı bulunmaktadır. Devam ederseniz bu kullanıcı da pasife çekilecektir. Onaylıyor musunuz?'
                />
            </div>
        );
    }

    getData = () => {
        let contact = this.state.contact;
        contact.hsmRoleList = this.state.selectedRoleList;
        contact.teamList = this.state.selectedTeamList;
        return contact;
    }

    clearData = () => {
        let contact = Object.assign({}, defaultContact);
        this.setState({
            contact: contact,
            selectedRoleList: [],
            selectedTeamList: [],
            avaibleRoleList: this.state.roleList,
            avaibleTeamList: this.state.teamList,
            pageMode: null,
            organization: null,
            locationList: [],
            locationHidden: true
        });
    }


    pickListTemplate(data) {
        return (
            <div className="p-clearfix">
                <div>{data.name}</div>
            </div>
        );
    }


    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let contact = this.state.contact;
        contact[e.target.name] = value;
        this.setState({contact: contact});
    }

    onOrganizationSelect = (data) => {
        let contact = this.state.contact;
        contact.organizationId = data.id;
        contact.locationId=null;
        let organization = data.name;
        this.setOrgAndLoc(data.id);
        this.setState({contact: contact, organization: organization, displayDialog: false, locationHidden: false});
    }

    setOrgAndLoc = (orgId) => {
        this.locationService.findByOrganizationId(orgId).then(response => {
                if (!GeneralUtils.isNullOrEmpty(response)) {
                    this.setState({locationList: response});
                }
            }
        )
    }

    setOrgAndLocAndGeneric(organizationId, locationId,pageMode) {
        this.hsmOrganizationService.findById(organizationId).then((response) => {
            let contact = this.state.contact;
            contact.organizationId = organizationId;
            contact.locationId = locationId;
            this.setOrgAndLoc(organizationId);
            this.setState({contact: contact, organization: response.name ,pageMode: pageMode, locationHidden: false});
        });
    }
}

HsmUserQuickSave.propTypes = {
    contact: PropTypes.object
};
