import React from 'react';
import {AvGroup} from "availity-reactstrap-validation";
import {Col, Label, Row} from "reactstrap";
import AvField from "../../shared/component/form/AvField";
import {TRUEFALSE} from "../../shared/utils/Constants";


const defaultHsmResolutionCodeParameter = {
    id: '',
    requestType: '',
    code: '',
    shortName: '',
    slaApplied:''
};
const requestType={SERVICE_REQUEST: "Hizmet Talebi", INCIDENT : "Arıza Talebi"}
export default class HsmResolutionCodeSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hsmResolutionCodeParameter: defaultHsmResolutionCodeParameter,
            pageMode: null
        };
    }

    render() {
        return (
            <Row className="justify-content-center">
                <Col>
                    <AvGroup>
                        <Label for="requestType">Talep Tipi<span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="select"
                            className="form-control"
                            name="requestType"
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                },
                                minLength: {
                                    value: 1,
                                    errorMessage: 'Bu alan en az 1 karakterden oluşabilir.'
                                },
                                maxLength: {
                                    value: 40,
                                    errorMessage: 'Bu alan en fazla 40 karakterden oluşabilir.'
                                }
                            }}
                            value={this.state.hsmResolutionCodeParameter.requestType}
                            onChange={this.__handleChange}
                        >
                        <option value='' key=''/>
                        {Object.entries(requestType).map(([key,value]) => (
                                            <option value={key} key={key}>
                                                {value}
                                            </option>
                                        ))}
                        </AvField>
                    </AvGroup>

                    <AvGroup>
                        <Label for="code">Kod<span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="code"
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                },
                                minLength: {
                                    value: 1,
                                    errorMessage: 'Bu alan en az 1 karakterden oluşabilir.'
                                },
                                maxLength: {
                                    value: 20,
                                    errorMessage: 'Bu alan en fazla 20 karakterden oluşabilir.'
                                }
                            }}
                            value={this.state.hsmResolutionCodeParameter.code}
                            onChange={this.__handleChange}
                        />
                    </AvGroup>

                    <AvGroup>
                        <Label for="shortName">Kısa Ad</Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="shortName"
                            validate={{
                                maxLength: {
                                    value: 255,
                                    errorMessage: 'Bu alan en fazla 255 karakterden oluşabilir.'
                                }
                            }}
                            value={this.state.hsmResolutionCodeParameter.shortName}
                            onChange={this.__handleChange}
                        />
                    </AvGroup>

                    <AvGroup>
                        <Label for="slaApplied">SLA</Label>
                        <AvField type="select" className="form-control" name="slaApplied"
                                 value={this.state.hsmResolutionCodeParameter.slaApplied}
                                 onChange={this.__handleChange}>
                            {TRUEFALSE.map(element => (
                                <option value={element.value} key={element.value}>
                                    {element.name}
                                </option>
                            ))}
                        </AvField>
                    </AvGroup>
                </Col>
            </Row>
        );
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let hsmResolutionCodeParameter = this.state.hsmResolutionCodeParameter;
        hsmResolutionCodeParameter[e.target.name] = value;
        this.setState({hsmResolutionCodeParameter: hsmResolutionCodeParameter});
    }

    setData = (data, pageMode) => {
        this.setState({hsmResolutionCodeParameter: data, pageMode: pageMode});
    }

    getData = () => {
        return this.state.hsmResolutionCodeParameter;
    }

    clearData = () => {
        this.setState({hsmResolutionCodeParameter: Object.assign({}, defaultHsmResolutionCodeParameter), pageMode: null});
    }

}
