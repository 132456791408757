import React from 'react';
import {Button, Row} from 'reactstrap';
import {Panel} from "primereact/panel";
import DataTable from "../../../shared/component/dataTable/DataTable";
import WorkFlowProcessColumn from "../../../shared/component/dataTable/columns/WorkFlowProcessColumn"
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import Translate from "../../../shared/language/Translate";
import Dialog from "../../../shared/component/dialog/Dialog";
import {PAGE_MODE} from "../../../shared/utils/Constants";
import {AvForm} from "availity-reactstrap-validation";
import WorkFlowProcessService from "../../../shared/service/WorkFlowProcessService";
import WorkFlowProcessSave from "./WorkFlowProcessSave";
import WorkFlowModelColumn from "../../../shared/component/dataTable/columns/WorkFlowModelColumn.json";


class WorkFlowProcess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageMode: null
        };
        this.service = new WorkFlowProcessService();
    }

    render() {
        return (
            <Row className="justify-content-center">
                <Panel header="İş Akışı Süreç Tanımları" style={{width: '90%'}}>
                    <Toolbar display={true}>
                        <div className="p-toolbar-group-right">
                            <Button color="primary" onClick={this.addNew}>
                                <Translate contentKey="entity.action.add">Yeni Kayıt</Translate>
                            </Button>
                        </div>
                    </Toolbar>

                    <DataTable ref={(elem) => this.refDataTable = elem} fields={WorkFlowProcessColumn.fields}
                               dataURL="workFlowProcess/findByCategoryIdAndSubcategoryId"
                               onDataSelect={this.onDataSelect} export={true} autoLoad={false} selectionMode="single"
                               privilege="allow"/>

                    <Dialog visible={this.state.displayDialog}
                            header={GeneralUtils.getDialogHeader('İş Akışı Süreci', this.state.pageMode)}
                            onHide={() => this.setState({displayDialog: false})} style={{width: '60%'}}>
                        <AvForm onValidSubmit={this.save}>
                            <Toolbar
                                display={true}>
                                <div className="p-toolbar-group-right">
                                    <Button color="primary" type="submit" className="float-right">
                                        <Translate contentKey="entity.action.save">Kaydet</Translate>
                                    </Button>
                                    <Button color="primary" className="float-right"
                                            onClick={() => this.setState({displayDialog: false})}>
                                        <Translate contentKey="entity.action.save">Vazgeç</Translate>
                                    </Button></div>
                            </Toolbar>
                            <WorkFlowProcessSave ref={elem => this.refWorkFlowProcessSave = elem}/>
                        </AvForm>
                    </Dialog>
                </Panel>
            </Row>
        );
    }

    refresh = () => {
        this.refDataTable.refresh();
    }

    addNew = () => {
        this.setState({displayDialog: true, pageMode: PAGE_MODE.CREATE});
        this.refWorkFlowProcessSave.clearData();
    }

    onDataSelect = (data) => {
        let pageMode = GeneralUtils.getPageModeFromPrivilege(WorkFlowProcessColumn.name);
        this.setState({displayDialog: true, pageMode: pageMode});
        this.refWorkFlowProcessSave.clearData();
        this.refWorkFlowProcessSave.setData(Object.assign({}, data), pageMode);
    }

    setData = (data) => {
        this.setState({categoryId:data.categoryId.id , subCategoryId:data.id})
        this.refDataTable.setParameter(data.categoryId.id,data.id);
    }

    save = () => {
        let workFlowProcess = this.refWorkFlowProcessSave.getData();
        if (!GeneralUtils.isNullOrEmpty(workFlowProcess.id)) {
            this.service.update(workFlowProcess).then((response) => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    this.setState({displayDialog: false});
                    this.refresh();
                }
            });
        } else {
            this.service.create(workFlowProcess).then((response) => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    this.setState({displayDialog: false});
                    this.refresh();
                }
            });
        }
    }
}


export default WorkFlowProcess;
