import React from 'react';
import DataTableAll from "./DataTableAll";
import {Button} from 'primereact/button';

/**
 * @author suslu
 */
export default class DataTableWithButton extends DataTableAll {

    constructor(props) {
        super(props);
    }

    complexCell = (rowData, column, col) => {
        if (col.hasOwnProperty('button') == true) {
            let activeKey;
            let isButton = true;
            let className = "p-button-primary"
            if (col.button.hasOwnProperty('activeKey') == true) {
                activeKey = col.button.activeKey;
                if (rowData.hasOwnProperty(activeKey) == false) {
                    isButton = false;
                }
            }
            if (col.button.hasOwnProperty('className')) {
                className =  col.button.className;
            }

            if (isButton) {
                let icon = null;
                let text = null;
                if (col.button.icon) {
                    icon = col.button.icon;
                } else {
                    text = col.button.text;
                }
                let content = [];
                content.push(
                    <Button type="button" label={text} icon={icon} className={className} onClick={() => this.actionOnclick(rowData, col.button.function)}></Button>
                );
                return <span>{content}</span>;
            }
        } else {
            return this.complexCellMultiField(rowData, column, col);
        }
    }

    actionOnclick = (rowData, functionName) => {
        if (this.props[functionName]) {
            this.props[functionName](rowData);
        }
    }

}
