import React from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Label, Row} from 'reactstrap';
import {AvForm, AvGroup} from 'availity-reactstrap-validation';
import Translate from "../../../shared/language/Translate";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import {STATUS, NOTICE_TYPE, NULLYESNO_STRING} from "../../../shared/utils/Constants";
import NoticeService from "../../../shared/service/NoticeService";
import AvField from "../../../shared/component/form/AvField";
import {toast} from "react-toastify";
import ReactQuill from "react-quill";
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import DateInput from "../../../shared/component/form/DateInput";
import NoticeColumn from '../../../shared/component/dataTable/columns/NoticeColumn';
import BusinessRuleService from "../../../shared/service/BusinessRuleService";

const defaultNotice = {
    id: '',
    title: '',
    content: '',
    status: 'A',
    startDate: null,
    endDate: null,
    noticeType:"",
    serviceOutage:''
};

export default class NoticeSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notice: defaultNotice,
            pageMode: null
        };
        this.service = new NoticeService();
    }

    render() {
        const {noticeEntity, loading, updating} = this.props;
        const {isNew} = this.state;

        return (
            <div>
                <Row className="justify-content-center">
                    <Col md="9">

                        <AvForm onValidSubmit={this.save}>
                            <Toolbar display={GeneralUtils.getDialogToolbarDisplay(NoticeColumn.name, this.state.pageMode)}>
                                <div className="p-toolbar-group-right">
                                    <Button color="primary" type="submit" className="float-right">
                                        <Translate contentKey="entity.action.save">Kaydet</Translate>
                                    </Button>

                                    <Button color="primary" className="float-right"
                                            onClick={this.props.closeDialog}>
                                        <Translate contentKey="entity.action.save">Vazgeç</Translate>
                                    </Button>
                                </div>
                            </Toolbar>
                            <AvGroup>
                                <Label for="title">
                                    <Translate contentKey="noticeManagement.title">Başlık</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="title"
                                    validate={{
                                        required: {
                                            value: true,
                                            errorMessage: 'Bu alan zorunlu.'
                                        },
                                        minLength: {
                                            value: 1,
                                            errorMessage: 'Bu alan en az 1 karakterden oluşabilir.'
                                        },
                                        maxLength: {
                                            value: 50,
                                            errorMessage: 'Bu alan en fazla 50 karakterden oluşabilir.'
                                        }
                                    }}
                                    value={this.state.notice.title}
                                    onChange={this.__handleChange}
                                />
                            </AvGroup>

                            <AvGroup>
                                <Label for="noticeType">
                                    <Translate contentKey="noticeManagement.noticeType">Duyuru Tipi</Translate>
                                </Label>
                                <AvField type="select" className="form-control" name="noticeType"
                                         value={this.state.notice.noticeType} onChange={this.__handleChange}>
                                    {NOTICE_TYPE.map(element => (
                                        <option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>
                            {this.state.notice.noticeType === 'Planned Maintenance' &&
                            <AvGroup>
                                <Label for="serviceOutage">
                                    <Translate contentKey="noticeManagement.serviceOutage">Hizmet Kesintisi Var Mı?</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvField type="select" className="form-control" name="serviceOutage"
                                         value={this.state.notice.serviceOutage} onChange={this.__handleChange}>
                                    {NULLYESNO_STRING.map(element => (
                                        <option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>}
                            <AvGroup>
                                <Label for="content">
                                    <Translate contentKey="noticeManagement.content">İçerik</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <ReactQuill
                                    name="content"
                                    value={this.state.notice.content}
                                    onChange={this.__handleChangeEditor}
                                    style={{height: '320px', marginBottom: '50px'}}
                                />
                            </AvGroup>
                            <div className="position-relative form-group">
                                <Row>
                                    <Col>
                                        <Label for="startDate">
                                            Başlangıç Tarihi
                                            {(this.state.notice.noticeType === 'Planned Maintenance' || this.state.notice.noticeType === 'General Network Trouble') &&
                                            <span style={{color: 'red'}}> (*)</span>
                                            }                                        </Label>
                                        <DateInput
                                            name="startDate"
                                            onChange={this.__handleChange}
                                            value={this.state.notice.startDate}/>
                                    </Col>
                                    <Col>
                                        <Label for="endDate">
                                            Bitiş Tarihi
                                            {(this.state.notice.noticeType === 'Planned Maintenance' || this.state.notice.noticeType === 'General Network Trouble') &&
                                            <span style={{color: 'red'}}> (*)</span>
                                            }
                                        </Label>
                                        <DateInput
                                            name="endDate"
                                            onChange={this.__handleChange}
                                            value={this.state.notice.endDate}/>
                                    </Col>
                                </Row>
                            </div>


                            <AvGroup>
                                <Label for="status">
                                    <Translate contentKey="userManagement.status">Durum</Translate>
                                </Label>
                                <AvField type="select" className="form-control" name="status"
                                         value={this.state.notice.status} onChange={this.__handleChange}>
                                    {STATUS.map(element => (
                                        <option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>
                        </AvForm>
                    </Col>
                </Row>
            </div>
        );
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let notice = this.state.notice;
        notice[e.target.name] = value;
        this.setState({notice: notice});
    }

    __handleChangeEditor = (e) => {
        let notice = this.state.notice;
        notice.content = e;
        this.setState({notice: notice});
    }

    save = () => {

        if (this.state.notice.noticeType === 'Planned Maintenance' && GeneralUtils.isNullOrEmpty(this.state.notice.serviceOutage)) {
            return toast.warn("Zorunlu Alan : Lütfen Hizmet Kesintisi Var Mı Alanını Seçiniz!");
        }

        if (this.state.notice.noticeType === 'Planned Maintenance' || this.state.notice.noticeType === 'General Network Trouble') {
            if (GeneralUtils.isNullOrEmpty(this.state.notice.startDate)){
                return toast.warn("Zorunlu Alan : Lütfen Başlangıç Tarihi Seçiniz!");
            }
            if (GeneralUtils.isNullOrEmpty(this.state.notice.endDate)){
                return toast.warn("Zorunlu Alan : Lütfen Bitiş Tarihi Seçiniz!");
            }
        }

        if (GeneralUtils.isNullOrEmpty(this.state.notice.content)) {
            return toast.warn("Zorunlu Alan : Lütfen İçerik Giriniz!");
        }
        let startDate=new Date(this.state.notice.startDate).getTime();
        let endDate=new Date(this.state.notice.endDate).getTime();
        if (startDate > endDate) {
            return toast.warn("Başlangıç tarihi bitiş tarihinden sonra olamaz!");
        }

        if (this.state.notice.noticeType != 'Planned Maintenance'){
            let notice = this.state.notice;
            notice['serviceOutage'] = '';
        }

            if (!GeneralUtils.isNullOrEmpty(this.state.notice.id)) {
            this.service.update(this.state.notice).then((response) => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    if (this.props.closeDialog) {
                        this.props.closeDialog();
                    }
                    if (this.props.refresh) {
                        this.props.refresh();
                    }
                }
            });
        } else {
            this.service.create(this.state.notice).then((response) => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    if (this.props.closeDialog) {
                        this.props.closeDialog();
                    }
                    if (this.props.refresh) {
                        this.props.refresh();
                    }
                }
            });
        }
    }

    setData = (data, pageMode) => {
        this.setState({
            notice: data,
            pageMode: pageMode
        });
    }

    clearData = () => {
        this.setState({
            notice: Object.assign({}, defaultNotice),
            pageMode: null
        });
    }

    setPageMode = (pageMode) => {
        this.setState({pageMode: pageMode});

    }


};
NoticeSave.propTypes = {
    closeDialog: PropTypes.func,
    refresh: PropTypes.func,
};