import React from 'react';
import PropTypes from 'prop-types';
import SelectInput from '../../../shared/component/form/SelectInput';
import {STATUS} from "../../../shared/utils/Constants";

export default class StatusGroup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value,
            disabled: this.props.disabled
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            value: nextProps.value,
            disabled: nextProps.disabled,
        });
    }

    render() {
        let dateComp = (
            <SelectInput
                name="status"
                value={this.state.value}
                label="Durum"
                translateKey="asset-management.status"
                onChange={this.props.onChange}
                optionList={STATUS}
                keyText="value"
                valueKeyText="name"
                disabled={this.state.disabled}
            />
        );
        return (dateComp);
    }
}

StatusGroup.propTypes = {
    value: PropTypes.string,
    disabled: PropTypes.bool
};