import React from 'react';
import DataTable from "./DataTable";
import {Button} from "primereact/button";
import GeneralUtils from "../../utils/GeneralUtils";

export default class DataTableRequest extends DataTable {

    constructor(props) {
        super(props);
    }

    rowClassName(rowData) {
        let status = rowData.status;
        let isSltBreach = rowData.sltBreach;

        let lastCommentDateMs = rowData.lastCommentDate;
        let now = new Date();
        let dateDiff = now.getTime() - lastCommentDateMs;
        if((dateDiff <= 3600000*24) && (GeneralUtils.getUserId() != rowData.requestComment.createdBy.id)){
            rowData.isComment=true;
        }

        if(rowData.status === 'PENDING'){
            return {'highlightTableRowPending' : (status === 'PENDING')};
        }

        if(!isSltBreach){
            if(status === 'P'){
                return {'p-disabled': (status === 'P')};
            }
        }else if(isSltBreach){
            return {'highlightTableRow' : (isSltBreach)};
        }

    }

    complexCell = (rowData, column, col) => {
        if (col.hasOwnProperty('button') == true) {
            let activeKey;
            let isButton = true;
            if (col.button.hasOwnProperty('activeKey') == true) {
                activeKey = col.button.activeKey;
                if (rowData.hasOwnProperty(activeKey) == false) {
                    isButton = false;
                }
            }

            if (isButton) {
                let icon = null;
                let text = null;
                if (col.button.icon) {
                    icon = col.button.icon;
                } else {
                    text = col.button.text;
                }
                let content = [];
                content.push(
                    <Button type="button" label={text} icon={icon} style={{width:'90%',height:'100%'}} className="p-button-primary"></Button>
                );
                return <span>{content}</span>;
            }
        } else {
            return this.complexCellMultiField(rowData, column, col);
        }
    }

}
