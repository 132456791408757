import React, {Component} from "react";
import {PAGE_MODE} from "../../../shared/utils/Constants";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import {SplitButton} from "primereact/splitbutton";
import RequestDiscoveryItemColumn from '../../../shared/component/dataTable/columns/RequestDiscoveryItemColumn';
import DiscoveryItemSelectableColumn from '../../../shared/component/dataTable/columns/DiscoveryItemSelectableColumn';
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import Dialog from "../../../shared/component/dialog/Dialog";
import DiscoveryItemSelectableService from "../../../shared/service/DiscoveryItemSelectableService";
import RequestDiscoveryItemService from "../../../shared/service/RequestDiscoveryItemService";
import {InputText} from 'primereact/inputtext';
import DataTableAll from "../../../shared/component/dataTable/DataTableAll";
import DataTableWithButton from "../../../shared/component/dataTable/DataTableWithButton";

export class DiscoveryOperation extends Component {

    constructor(props) {
        super(props);

        this.state = {
            pageMode: {},
            discoverySelectableItem: {},
            requestDiscoveryItems: [],
            requestId: {},
            requestType: {},
            items: [{
                label: 'Keşif Cihazı Ekle',
                icon: 'pi pi-wifi',
                command: (e) => {
                    this.setState({displayDialog: true});
                    this.addNew();
                },
            }],
        };
        this.discoveryItemSelectableService = new DiscoveryItemSelectableService();
        this.requestDiscoveryItemService = new RequestDiscoveryItemService();
    }

    componentDidMount() {
        this.discoveryItemSelectableService.findAll().then(data => this.setState({
            discoverySelectableItem: data
        }));
    }

    render() {
        return (<div className="container">
                <div className="row">
                    <div className="col">
                        {GeneralUtils.checkPrivilege("RequestDiscoveryItem","Add")&& this.state.taskStatus != 'CLOSED' &&
                        <Toolbar display={true}>
                            <div className="p-toolbar-group-right">
                                <SplitButton ref={elem => this.refSplitButton = elem} label="Ekle" icon="pi pi-plus"
                                             onClick={this.showSplitButtonMenu}
                                             model={this.state.items}></SplitButton>
                            </div>
                        </Toolbar>
                            }
                        <DataTableWithButton
                            fields={RequestDiscoveryItemColumn.fields}
                            objectName={RequestDiscoveryItemColumn.name}
                            data={this.state.requestDiscoveryItems}
                            editorQuantity={this.editorQuantity}
                            delete={this.deleteRow}
                            loading={this.state.loading}
                        />


                        <Dialog visible={this.state.displayDialog}
                                header={GeneralUtils.getDialogHeader('Keşif Cihazı', PAGE_MODE.VIEW)}
                                onHide={() => this.setState({displayDialog: false})} style={{width: '60%'}}>

                            <DataTableAll fields={DiscoveryItemSelectableColumn.fields}
                                       objectName={RequestDiscoveryItemColumn.name}
                                       data={this.state.discoverySelectableItem} selectionMode="single"
                                       onDataSelect={this.onDataSelect}
                                       loading={this.state.loading}/>

                        </Dialog>

                    </div>
                </div>
            </div>
        );
    }

    addNew = () => {
        this.setState({displayDialog: true, pageMode: PAGE_MODE.VIEW});
        this.deleteSelectableItems();
    }

    onDataSelect = (data) => {
        this.deleteSelectableItemFromList(data);
        this.state.requestDiscoveryItems.push(data);
        data.isDeleted = true;
        data.quantity = 0;
        this.setState({displayDialog: false});
        if(this.props.setLength){
            this.props.setLength(this.state.requestDiscoveryItems.length);
        }
    }

    getData = () => {
        return this.state.requestDiscoveryItems;
    }

    clearData = () => {
        this.discoveryItemSelectableService.findAll().then(data => this.setState({
            discoverySelectableItem: data
        }));
    }

    setData = (requestType,requestId,taskStatus) => {
        this.setState({requestType:requestType,requestId:requestId,taskStatus});
        this.findByRequestTypeAndRequestId(requestType,requestId);
    }

    findByRequestTypeAndRequestId = (requestType,requestId) => {
        this.requestDiscoveryItemService.findByRequestTypeAndRequestId(requestType, requestId)
            .then(data => {
                this.setState({requestDiscoveryItems: data});
                if(this.props.setLength){
                    this.props.setLength(data.length);
                }
            });
    }

    deleteSelectableItems = () => {
        if (this.state.requestDiscoveryItems.length > 0) {
            let newArray = this.state.discoverySelectableItem;
            for (var i = 0; i < this.state.requestDiscoveryItems.length; i++) {
                for (var j = 0; j < this.state.discoverySelectableItem.length; j++) {
                    if (this.state.requestDiscoveryItems[i].id == this.state.discoverySelectableItem[j].id) {
                        newArray = this.arrayRemove(newArray, this.state.requestDiscoveryItems[i]);
                    }
                }
            }
            this.setState({discoverySelectableItem: newArray});
        }
    }

    deleteRow = (rowData) => {
        let requestList = this.state.requestDiscoveryItems;
        let newRequestList = this.arrayRemove(requestList, rowData);
        this.setState({requestDiscoveryItems: newRequestList});
        this.state.discoverySelectableItem.push(rowData);
        if(this.props.setLength){
            this.props.setLength(newRequestList.length);
        }
    }

    arrayRemove = (arr, element) => {
        return arr.filter(function (el) {
            return el.id != element.id;
        });
    }

    checkQuantityValue = () => {
        if (GeneralUtils.isNullOrEmpty(this.state.requestDiscoveryItems)) {
            return false;
        } else {
            for (var i = 0; i < this.state.requestDiscoveryItems.length; i++) {
                if (GeneralUtils.isNullOrEmpty(this.state.requestDiscoveryItems[i].quantity) || this.state.requestDiscoveryItems[i].quantity == 0) {
                    return true;
                }
            }
        }
    }

    deleteSelectableItemFromList = (data) => {
        data.requestId = this.state.requestId;
        data.requestType = this.state.requestType;

        var removeIndex = this.state.discoverySelectableItem.map(function (item) {
            return item.id;
        }).indexOf(data.id);
        this.state.discoverySelectableItem.splice(removeIndex, 1);
    }

    showSplitButtonMenu = () => {
        this.refSplitButton.onDropdownButtonClick();
    }

    editorQuantity = (props) => {
        return (
            <div>
                <InputText type="text" value={props.value[props.rowIndex].quantity}
                           onChange={(e) => this.onEditorValueChange(props, e.target.value)} style={{width: '100%'}}/>
            </div>
        );

    }

    onEditorValueChange = (props, value) => {
        let requestItems = [...props.value];
        requestItems[props.rowIndex][props.field] = value;
        this.setState({requestDiscoveryItems: requestItems});
    }

}
