import React from 'react';
import {Panel} from 'primereact/panel';
import TaskService from "../../../shared/service/TaskService";
import Task from "./Task";


export default class TaskList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.service = new TaskService();
    }

    componentDidMount = () => {
        this.service.checkPage();
    }

    render() {
        return (
                <div>
                    <Task inMenu={true}/>
                </div>
        );
    }
}
