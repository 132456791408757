import React from 'react';
import {Panel} from 'primereact/panel';
import DataTable from '../../../shared/component/dataTable/DataTable';
import BillingAccountService from "../../../shared/service/BillingAccountService";
import BillingAccountColumn from '../../../shared/component/dataTable/columns/BillingAccountColumn';
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import {Button} from 'reactstrap';
import Translate from "../../../shared/language/Translate";
import BillingAccountSave from "./BillingAccountSave";
import {TabPanel, TabView} from "primereact/tabview";
import {PAGE_MODE} from "../../../shared/utils/Constants";
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import {AvForm} from 'availity-reactstrap-validation';
import AuditEvent from "../../administration/AuditEvent";
import Dialog from "../../../shared/component/dialog/Dialog";

export default class BillingAccount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            selected: {},
            pageMode: null
        };
        this.service = new BillingAccountService();
    }

    componentDidMount = () => {
        this.service.checkPage();
    }

    render() {
        return (
            <Panel header="Fatura Hesabı Yönetimi">
                <Toolbar display={GeneralUtils.checkPrivilege(BillingAccountColumn.name, 'Create')}>
                    <div className="p-toolbar-group-right">
                        <Button color="primary" onClick={this.addNew}>
                            <Translate contentKey="entity.action.add">Yeni Kayıt</Translate>
                        </Button>
                    </div>
                </Toolbar>

                <DataTable ref={(elem) => this.refDataTable = elem} fields={BillingAccountColumn.fields} dataURL="billingAccount/findAll"
                           objectName={BillingAccountColumn.name}
                           selectionMode="single" onDataSelect={this.onDataSelect} loading={this.state.loading}/>

                <Dialog visible={this.state.displayDialog}
                        header={GeneralUtils.getDialogHeader('Fatura Hesabı', this.state.pageMode)}
                        onHide={() => this.setState({displayDialog: false})} style={{width: '50vw'}}>

                    <AvForm onValidSubmit={this.submit} beforeSubmitValidation={() => this.setState({activeIndex: 0})}>
                        <Toolbar
                            display={GeneralUtils.getDialogToolbarDisplay(BillingAccountColumn.name, this.state.pageMode)}>
                            <div className="p-toolbar-group-right">
                                <Button color="primary" type="submit" className="float-right">
                                    &nbsp;
                                    <Translate contentKey="entity.action.save">Kaydet</Translate>
                                </Button>
                                <Button color="primary" className="float-right"
                                        onClick={() => this.closeDisplayDialog()}>
                                    &nbsp;
                                    <Translate contentKey="entity.action.save">Vazgeç</Translate>
                                </Button>
                            </div>
                        </Toolbar>
                        <TabView activeIndex={this.state.activeIndex}
                                 onTabChange={(e) => this.setState({activeIndex: e.index})} renderActiveOnly={false}>
                            <TabPanel header="Fatura Hesabı">
                                <BillingAccountSave save={this.refresh}
                                                    ref={(elem) => this.refBillingAccountSave = elem}
                                                    closeDisplayDialog={this.closeDisplayDialog}/>
                            </TabPanel>

                            <TabPanel header="Tarihçe" rightIcon="pi pi-user"
                                      disabled={this.state.pageMode === PAGE_MODE.CREATE}
                                      headerClassName={this.state.pageMode === PAGE_MODE.CREATE && 'displayNone'}>
                                <AuditEvent ref={elem => this.refAuditEvent = elem}
                                            objectName={BillingAccountColumn.name}/>
                            </TabPanel>
                        </TabView>
                    </AvForm>
                </Dialog>
            </Panel>
        );
    }

    addNew = () => {
        this.setState({displayDialog: true, pageMode: PAGE_MODE.CREATE, activeIndex: 0});
        this.refBillingAccountSave.clearData();
        this.refBillingAccountSave.setPageMode(PAGE_MODE.CREATE);
    }

    submit = () => {
        this.refBillingAccountSave.save();
    }

    refresh = () => {
        this.refDataTable.refresh();
        this.setState({displayDialog: false});
    }

    save = (newData) => {
        if (!GeneralUtils.isNullOrEmpty(newData.id)) {
            this.service.update(newData).then(() => {
                this.refBillingAccountSave.setData(PAGE_MODE.UPDATE);
                this.refresh();
            });
        } else {
            this.service.create(newData).then(() => {
                this.refBillingAccountSave.setData(PAGE_MODE.CREATE);
                this.refresh();
            });
        }
    }

    onDataSelect = (data) => {
        let pageMode = GeneralUtils.getPageModeFromPrivilege(BillingAccountColumn.name);
        this.setState({
            displayDialog: true,
            pageMode: pageMode,
            activeIndex: 0
        });
        this.refBillingAccountSave.setData(Object.assign({}, data), pageMode);
        this.refAuditEvent.setData(BillingAccountColumn.name, data.id);
    }

    closeDisplayDialog = () => {
        this.setState({displayDialog: false});
    }
}
