import React from 'react';
import {DataTable as DataTablePR} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Dropdown} from "primereact/dropdown";
import GeneralUtils from "../../utils/GeneralUtils";
import DataTable from "./DataTable";
import InputText from "../form/InputText";

/**
 * Ümit Yıldırım ._.
 */
export default class DataTableAll extends DataTable {

    render() {
        return (
            <div className="content-section implementation">
                {(GeneralUtils.checkPrivilege(this.props.objectName, 'ViewList') || this.props.privilege === "allow") &&
                <DataTablePR {...this.props} value={this.state.data} header={this.getHeader()} responsive={true}
                            /* footer={'Kayıt Sayısı : '+this.state.totalRecords}
                             totalRecords={this.state.totalRecords}*/
                             paginator={true}
                             rows={10} loading={this.state.loading}
                             ref={(el) => {
                                 this.dt = el;
                             }}
                             emptyMessage="Kayıt Bulunamadı"
                             resizableColumns={true}
                             rowsPerPageOptions={[5, 10, 20, 50, 100]}
                             selectionMode={(GeneralUtils.checkPrivilege(this.props.objectName, 'View') || GeneralUtils.checkPrivilege(this.props.objectName, 'Modify') || this.props.privilege === "allow") ? this.props.selectionMode : null}
                             selection={(GeneralUtils.checkPrivilege(this.props.objectName, 'View') || GeneralUtils.checkPrivilege(this.props.objectName, 'Modify') || this.props.privilege === "allow") ? this.state.selected : null}
                             onSelectionChange={e => this.setState({selected: Object.assign({}, e.value)})}
                             onRowSelect={this.onDataSelect}
                             rowClassName={this.rowClassName}>
                    {this.getColumns()}
                </DataTablePR>
                }
                {this.getDataModelForm()}
            </div>
        );
    }


    getColumns = () => {
        let columns = [];
        // if (this.props.action) {
        // columns.push(<Column body={this.actionButton} style={{textAlign: 'center', width: '8em'}} />);
        // }
        this.state.cols.forEach((col) => {

            if (col.visible !== false) {
                if (col.formatMoney) {
                    columns.push(<Column key={col.field} field={col.field} header={col.header} filter={col.filter}
                                         body={this.moneyCell}
                                         filterMatchMode="contains" sortable={col.sortable} style={col.style}/>)
                } else if (col.formatDuration) {
                    let modelFilter = <InputText style={{width: '100%'}} className="ui-column-filter" name={col.field} type={col.type}
                                                 value={this.state.filterElement[col.field]}
                                                 onChange={this.onModelFilterChange}/>
                    columns.push(<Column key={col.field} field={col.field} header={col.header} filter={col.filter}
                                         body={this.durationCell}
                                         filterMatchMode="contains" sortable={col.sortable} style={col.style}
                                         filterElement={modelFilter}/>)
                } else if (col.formatPercent) {
                    columns.push(<Column key={col.field} field={col.field} header={col.header} filter={col.filter}
                                         body={this.percentCell}
                                         filterMatchMode="contains" sortable={col.sortable} style={col.style}/>)
                } else if (col.field.includes('.')) {
                    columns.push(<Column key={col.field} field={col.field} header={col.header} filter={col.filter}
                                         body={(rowData, column) => this.complexCell(rowData, column, col)}
                                         filterMatchMode="contains" sortable={col.sortable} style={col.style}/>)
                } else if (col.field.toUpperCase().includes('DATE')) {
                    columns.push(<Column key={col.field} field={col.field} header={col.header} filter={col.filter}
                                         body={this.dateCell}
                                         filterMatchMode="contains" sortable={col.sortable} style={col.style}/>)
                } else if (col.filterElement) {
                    let options = null;
                    if (col.filterElement.restURL) {
                        this.getOptions(col.filterElement.restURL);
                    } else {
                        options = col.filterElement.options;
                    }
                    let dropdownFilter = <Dropdown style={{width: '100%'}} name={col.field}
                                                   value={this.state.filterElement[col.field]} options={options}
                                                   onChange={this.onStatusChange}/>

                    if (col.dataElement) {
                        columns.push(<Column key={col.field} field={col.field} header={col.header} filter={col.filter}
                                             filterElement={dropdownFilter}
                                             body={(rowData, column) => {
                                                 return col.dataElement[this.dataElementCell(rowData, column)]
                                             }}
                                             filterMatchMode="equals" sortable={col.sortable} style={col.style}
                                             editor={this.props[col.editor]}/>)
                    } else {
                        columns.push(<Column key={col.field} field={col.field} header={col.header} filter={col.filter}
                                             filterElement={dropdownFilter}
                                             filterMatchMode="contains" sortable={col.sortable} style={col.style}/>);
                    }
                } else {
                    if (col.dataElement) {
                        columns.push(<Column key={col.field} field={col.field} header={col.header} filter={col.filter}
                                             body={(rowData, column) => {
                                                 return col.dataElement[this.dataElementCell(rowData, column)]
                                             }}
                                             filterMatchMode="equals" sortable={col.sortable} style={col.style}/>)
                    } else {
                        columns.push(<Column key={col.field} field={col.field} header={col.header} filter={col.filter}
                                             filterMatchMode="contains" sortable={col.sortable} style={col.style}
                                             body={(rowData, column) => {
                                                 let text = rowData[column.field]
                                                 return <span style={{whiteSpace: "nowrap"}} title={text}>{text}</span>
                                             }}
                                             editor={this.props[col.editor]}/>);
                    }
                }
            }
        });
        return columns;
    }

    onStatusChange = (event) => {
        this.dt.filter(event.value, event.target.name, 'equals');
        let filterElement = this.state.filterElement;
        filterElement[event.target.name] = event.value;
        this.setState({filterElement: filterElement});
    }

}

