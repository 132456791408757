import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class LoginService {
    constructor() {
        this.baseURL = 'login';
    }

    resetPasswordInit(email){
        fetch(SERVER_URL + this.baseURL +'/resetPasswordInit', {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: email
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response);
            return result.json();
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    resetPasswordFinish(data){
        fetch(SERVER_URL + this.baseURL +'/resetPasswordFinish', {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response);
            return result.json();
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    activateUser(data){
        fetch(SERVER_URL + this.baseURL +'/activateUser', {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response);
            return result.json();
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    logout(data){
        fetch(SERVER_URL + this.baseURL +'/logout', {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")},
            body: JSON.stringify(data)
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

}
