import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class BandwidthService extends Service {
    constructor() {
        super();
        this.baseURL = 'bandwidth';
    }

    findAllByOrgIdAndLocIdAndLessDownloadSpeed(parameter) {
        return fetch(SERVER_URL + this.baseURL + '/findAllByOrgIdAndLocIdAndLessDownloadSpeed/' + parameter.organizationId + '/' + parameter.locationId + '/' + parameter.downloadSpeed, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        })
            .then(response => response.json())
            .catch(error => {
                GeneralUtils.notification(error);
            });
    }

    findAllByOrgIdAndLocIdAndMoreDownloadSpeed(parameter) {
        return fetch(SERVER_URL + this.baseURL + '/findAllByOrgIdAndLocIdAndMoreDownloadSpeed/' + parameter.organizationId + '/' + parameter.locationId + '/' + parameter.downloadSpeed, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        })
            .then(response => response.json())
            .catch(error => {
                GeneralUtils.notification(error);
            });
    }

}