import React from 'react';
import {Button, Col, Label, Row} from 'reactstrap';
import {AvFeedback, AvForm, AvGroup} from 'availity-reactstrap-validation';
import AvField from '../../../shared/component/form/AvField';
import PropTypes from 'prop-types';
import Translate from "../../../shared/language/Translate";
import BrandService from "../../../shared/service/BrandService";
import Dialog from "../../../shared/component/dialog/Dialog";
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import {toast} from "react-toastify";
import GeneralUtils from "../../../shared/utils/GeneralUtils";

const data = {
    id: '',
    description: ''
};

export default class StatusChangeDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: data,
            pageMode: null
        }
        this.service = new BrandService();
    }

    render() {
        return (
            <Row className="justify-content-center">
                <Col md="8">
                    <Dialog
                        header={'Durum Değişikliği'} modal={true} resizable={true} visible={this.state.displayDialog}
                        maximizable={true} responsive={true} onHide={() => {
                        this.setState({displayDialog: false});
                    }}
                        style={{width: '50vw'}}>

                        <AvForm onValidSubmit={this.statusChanged}>
                            <Toolbar display={true}>
                                <div className="p-toolbar-group-right">
                                    <Button color="primary" className="float-right" type="submit">
                                        Kaydet
                                    </Button>
                                    <Button color="primary" className="float-right"
                                            onClick={() => {
                                                this.setState({displayDialog: false});
                                            }}>
                                        Vazgeç
                                    </Button>
                                </div>
                            </Toolbar>


                            <AvGroup>
                                <Label for="description">
                                    <Translate contentKey="description">Açıklama</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvField
                                    type="textarea"
                                    className="form-control textAreaHermes"
                                    name="description"
                                    validate={{
                                        maxLength: {
                                            value: 1000,
                                            errorMessage: "Bu alana en fazla 1000 karakter girebilirsiniz."
                                        },
                                        required: {
                                            value: "true",
                                            errorMessage: "Bu alanın doldurulması zorunludur."
                                        }
                                    }}
                                    value={this.state.data.description}
                                    onChange={this.__handleChange}
                                />
                                <AvFeedback>Bu alana en fazla 4000 karakter girebilirsiniz.</AvFeedback>
                            </AvGroup>

                        </AvForm>
                    </Dialog>
                </Col>
            </Row>
        );
    }

    statusChanged = () => {
        if (GeneralUtils.isNullOrEmpty(this.state.data.description)) {
            return toast.warn("Zorunlu Alan : Açıklama Giriniz!");
        }
        this.props.statusChange();
        this.setState({displayDialog: false});
    }

    setData = (data) => {
        this.setState({data: data});
    }

    clearData = () => {
        this.setState({data: Object.assign({}, data)});
    }

    getData = () => {
        return this.state.data;
    }


    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let data = this.state.data;
        data[e.target.name] = value;
        this.setState({data: data});
    }

    setDialog = (value) => {
        this.clearData();
        this.setState({displayDialog: value});
    }
}

StatusChangeDialog
    .propTypes = {
    data: PropTypes.object
};
