import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class HsmSurveyService extends Service{
    constructor() {
        super();
        this.baseURL = 'hsmSurvey';
    }

    findByCategoryIdSubCategoryId(serviceCategoryId, serviceSubcategoryId) {
        return fetch(SERVER_URL + this.baseURL+ '/findByCategoryIdSubCategoryId/'+ serviceCategoryId + '/' + serviceSubcategoryId,  {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")},
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

}