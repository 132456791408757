import React from 'react';
import DataTableAll from '../../shared/component/dataTable/DataTableAll';
import RequestSltColumnForDialog from '../../shared/component/dataTable/columns/RequestSltColumnForDialog';
import {PAGE_MODE} from "../../shared/utils/Constants";
import RequestSltService from '../../shared/service/RequestSltService';


export default class RequestSlt extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contactData: [],
            pageMode: PAGE_MODE.VIEW,
            requestSltList: []
        };
        this.requestSltService = new RequestSltService();
    }


    render() {
            return (
            <DataTableAll  fields={RequestSltColumnForDialog.fields } objectName={RequestSltColumnForDialog.name}
                       data={this.state.requestSltList}/>
        );
    }

    setData = (requestId, requestType) => {

        this.requestSltService.findRequestSltsByRequestIdAndRequestType(requestType, requestId).then((response) => {
            this.setState({requestSltList: response ? response : []});

            if (this.props.setRequestSltList) {
                this.props.setRequestSltList(response);
            }

        });
    }

    getData = () => {
        return this.state.requestSltList;
    }

    setDisabledFlagServiceRequestFollow = (pageMode,requestId) =>{
        this.requestSltService.haveAnyRecord(requestId).then(res => {
            /*Yeni hizmet talebi eklenirken hizmet talebi takip tabı disabled olacak.*/
            if(pageMode === PAGE_MODE.CREATE){
                this.props.disabledServiceRequestFollowTab(true);
            }else if(pageMode === PAGE_MODE.VIEW){/*hizmet görüntülenirken hizmet talebine ait bir kayıt requestslt tablosunda var tab aktif akis durumda pasif olucak */
                if(res == false ){
                    this.props.disabledServiceRequestFollowTab(true);
                }else if(res == true ){
                    this.props.disabledServiceRequestFollowTab(false);
                }
            }

        });

    }

}
