import React from 'react';
import Service from '../../shared/service/Service';
import {Button, Col, Label, Row} from "reactstrap";
import {AvForm, AvGroup} from "availity-reactstrap-validation";
import Translate from "../../shared/language/Translate";
import AvField from "../../shared/component/form/AvField";
import Toolbar from "../../shared/component/toolbar/Toolbar";
import GeneralUtils from "../../shared/utils/GeneralUtils";
import DateInput from "../../shared/component/form/DateInput";

const defaultData = {
    serviceName: '',
    requestId: '',
    requestDate: '',
    requestMsg: '',
    replyDate: '',
    replyMsg: '',
    callStatus: '',
    replyStatus: ''
};

const status = {
    Success: "Başarılı",
    Error: "Hatalı"
}
export default class WebServiceLogContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: defaultData
        };
        this.service = new Service('webServiceLog');
    }

    componentDidMount = () => {
        this.setState({loading: true});
        this.service.checkPage();
    }

    render() {
        return (
            <div>
                <div>
                    <Row className="justify-content-center">
                        <Col md="8">
                            <Toolbar display={true}>
                                <div className="p-toolbar-group-right">
                                    <Button color="primary" onClick={()=>this.downloadString(this.state.data.requestMsg, 'Request')}>
                                        İstek İndir
                                    </Button>
                                    <Button color="primary" onClick={()=>this.downloadString(this.state.data.replyMsg, 'Response')}>
                                        Cevap İndir
                                    </Button>
                                </div>
                            </Toolbar>
                            <AvForm ref={(e) => this.refForm = e}>
                                <AvGroup>
                                    <Label for="serviceName">
                                        <Translate contentKey="data.serviceName">Hizmet Türü</Translate>
                                    </Label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="serviceName"
                                        value={this.state.data.serviceName}
                                        disabled={true}
                                    />
                                </AvGroup>
                                <AvGroup>
                                    <Label for="serviceName">
                                        <Translate contentKey="data.requestId">Arıza No</Translate>
                                    </Label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="requestId"
                                        value={this.state.data.requestId}
                                        disabled={true}
                                    />
                                </AvGroup>
                                <AvGroup>
                                    <Label for="requestDate">
                                        <Translate contentKey="data.requestDate">İstek Zamanı</Translate>
                                    </Label>
                                    <DateInput
                                        name="requestDate"
                                        disabled={true}
                                        value={this.state.data.requestDate}
                                        format="DD/MM/YYYY HH:mm:ss"/>
                                </AvGroup>
                                <AvGroup>
                                    <Label for="callStatus">
                                        <Translate contentKey="data.callStatus">İstek Durumu</Translate>
                                    </Label>
                                    <AvField
                                        type="text"
                                        className={`form-control status-${!GeneralUtils.isNullOrEmpty(this.state.data.callStatus) 
                                        && this.state.data.callStatus.toUpperCase() === "SUCCESS" ? "Success" : "Error" }`}
                                        name="callStatus"
                                        value={this.state.data.callStatus}
                                        disabled={true}
                                    />
                                </AvGroup>
                                <AvGroup>
                                    <Label for="requestMsg">
                                        <Translate contentKey="data.requestMsg">İstek</Translate>
                                    </Label>
                                    <AvField
                                        type="textarea"
                                        className="form-control textAreaHermesBig"
                                        name="requestMsg"
                                        value={this.state.data.requestMsg}
                                        disabled={true}
                                    />
                                </AvGroup>
                                <AvGroup>
                                    <Label for="replyDate">
                                        <Translate contentKey="data.replyDate">Cevap Zamanı</Translate>
                                    </Label>
                                    <DateInput
                                        name="replyDate"
                                        disabled={true}
                                        value={this.state.data.replyDate}
                                        format="DD/MM/YYYY HH:mm:ss"/>
                                </AvGroup>
                                <AvGroup>
                                    <Label for="replyStatus">
                                        <Translate contentKey="data.replyStatus">Cevap Durumu</Translate>
                                    </Label>
                                    <AvField
                                        type="text"
                                        className={`form-control status-${!GeneralUtils.isNullOrEmpty(this.state.data.replyStatus)
                                        && this.state.data.replyStatus.toUpperCase() === "SUCCESS" ? "Success" : "Error"}`}
                                        name="replyStatus"
                                        value={this.state.data.replyStatus}
                                        disabled={true}
                                    />
                                </AvGroup>
                                <AvGroup>
                                    <Label for="replyMsg">
                                        <Translate contentKey="data.replyMsg">Cevap</Translate>
                                    </Label>
                                    <AvField
                                        type="textarea"
                                        className="form-control textAreaHermesBig"
                                        name="replyMsg"
                                        value={this.state.data.replyMsg}
                                        disabled={true}
                                    />
                                </AvGroup>
                            </AvForm>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }


    setData = (data) => {
        this.setState({
            data
        });
    }

// downloadString("a,b,c\n1,2,3", "text/csv", "myCSV.csv")
    downloadString=(text, type)=> {
        let fileType = 'application/xml';
        let fileExtension = '.xml';
        if(GeneralUtils.isJSON(text)){
            fileType = 'application/json';
            fileExtension = '.json';
        }
        let fileName = this.state.data.requestId + ' ' + type + fileExtension

        var blob = new Blob([text], { type: fileType });

        var a = document.createElement('a');
        a.download = fileName;
        a.href = URL.createObjectURL(blob);
        a.dataset.downloadurl = [fileType, a.download, a.href].join(':');
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setTimeout(function() { URL.revokeObjectURL(a.href); }, 1500);
    }

}
