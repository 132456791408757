import React from 'react';
import {Col, Label, Row} from 'reactstrap';
import {AvGroup} from 'availity-reactstrap-validation';
import AvField from '../../../shared/component/form/AvField';
import PropTypes from 'prop-types';
import Translate from "../../../shared/language/Translate";
import HsmAssetService from "../../../shared/service/HsmAssetService";
import HsmOrganizationService from "../../../shared/service/HsmOrganizationService";
import LocationService from "../../../shared/service/LocationService";
import BrandService from "../../../shared/service/BrandService";
import ModelService from "../../../shared/service/ModelService";
import OrganizationGroup from "../../../shared/component/hsmGroup/OrganizationGroup";
import HsmOrganizationColumnForDialog from '../../../shared/component/dataTable/columns/HsmOrganizationColumnForDialog';
import {
    ASSET_CATEGORY,
    GENERIC_BRAND_ID,
    GENERIC_MODEL_ID,
    PAGE_MODE
} from "../../../shared/utils/Constants";
import {Fieldset} from "primereact/fieldset";
import DateInput from "../../../shared/component/form/DateInput";
import StatusGroup from "../../../shared/component/hsmGroup/StatusGroup";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import FloorService from "../../../shared/service/FloorService";
import RoomService from "../../../shared/service/RoomService";
import AssetTypeService from "../../../shared/service/AssetTypeService";
import HsmNetworkInterface from "./network-interface/HsmNetworkInterface";
import HsmProjectService from "../../../shared/service/HsmProjectService";


const defaultHsmAsset = {
    assetCategory: '',
    id: '',
    name: '',
    assetType: '',
    status: 'A',
    serialNumber: '',
    assetTag: '',
    installedDate: '',
    activatedDate: '',
    acceptanceDate: '',
    uninstalledDate: '',
    brandId: '',
    modelId: '',
    locationId: '',
    organizationId: '',
    floorId: '',
    roomId: '',
    rackUnit: '',
    hsmProjectId:null
};
const ASSET_CATEGORY_LIST = [
    {name: 'Ağ Cihazı', value: 'NETWORK_DEVICE', id: 1},
    {name: 'Muhafaza', value: 'ENCLOSURE', id: 4},
    {name: 'Kullanıcı Cihazları', value: 'USER_DEVICE', id: 5 },
    {name: 'Diğer', value: 'OTHER', id: 10 }
];

export default class HsmAssetSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hsmAsset: Object.assign({}, defaultHsmAsset),
            organizationList: [{id: 0, name: 'default'}],
            locationList: [{id: 0, name: 'default'}],
            brandList: [{id: 0, name: 'CISCO'}],
            modelList: [],
            pageMode: null,
            organization: null,
            location: null,
            locationHidden: true,
            disabledGeneric: false,
            floorList: [],
            roomList: [],
            assetTypeList:[],
            hsmProjectList:[]
        }
        this.service = new HsmAssetService();
        this.hsmOrganizationService = new HsmOrganizationService();
        this.locationService = new LocationService();
        this.brandService = new BrandService();
        this.modelService = new ModelService();
        this.floorService = new FloorService();
        this.roomService = new RoomService();
        this.assetTypeService = new AssetTypeService();
        this.hsmProjectService = new HsmProjectService();

    }

    componentDidMount = () => {
        // this.locationService.findAll().then(response => this.setState({locationList: response}));
        this.brandService.findBrandsHasModel().then(response => this.setState({brandList: response}));
        this.floorService.findAll().then(response => this.setState({floorList: response}));
        this.roomService.findAll().then(response => this.setState({roomList: response}));

        this.hsmProjectService.findAllActive().then(response =>{
            if(!GeneralUtils.isNullOrEmpty(response) && response.length == 1){
                let hsmAsset = this.state.hsmAsset;
                hsmAsset.hsmProjectId = response[0].id;
                this.setState({hsmAsset});
            }
            this.setState({hsmProjectList: response});
        });


        // this.modelService.findModelsByBrandId(this.state.hsmAsset.brandId).then(response => {
        //     let hsmAsset = this.state.hsmAsset;
        //     hsmAsset.modelId = response[0].id;
        //     this.setState({modelList: response, hsmAsset: hsmAsset});
        // });
    }

    render() {
        return (
            <div>
                    <Row className="container">
                        <Col>
                            <AvGroup>
                                <Label for="assetCategory">
                                    <Translate contentKey="asset-management.assetCategory">Varlık Sınıfı</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvField
                                    type="select"
                                    // type="text"
                                    className="form-control"
                                    name="assetCategory"
                                    value={this.state.hsmAsset.assetCategory}
                                    validate={this.state.pageMode === PAGE_MODE.VIEW ? {} : {
                                        required: {
                                            value: "true",
                                            errorMessage: "Bu alanın doldurulması zorunludur."
                                        }
                                    }}
                                    onChange={this.__handleChangeAssetCategory}
                                    disabled={this.state.pageMode === PAGE_MODE.VIEW || this.state.disabledAssetCategory}>
                                    <option value='' key=''/>
                                    {ASSET_CATEGORY_LIST.map(element => (
                                        <option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>

                            <AvGroup>
                                <Label for="assetType">
                                    <Translate contentKey="asset-management.assetType">Varlık Tipi</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvField
                                    type="select"
                                    // type="text"
                                    className="form-control"
                                    name="assetType"
                                    value={this.state.hsmAsset.assetType}
                                    validate={this.state.pageMode === PAGE_MODE.VIEW ? {} : {
                                        required: {
                                            value: "true",
                                            errorMessage: "Bu alanın doldurulması zorunludur."
                                        }
                                    }}
                                    onChange={this.__handleChange}
                                    disabled={this.state.pageMode === PAGE_MODE.VIEW}>
                                    <option value='' key=''/>
                                    {this.state.assetTypeList.map(element => (
                                        <option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>

                            <AvGroup>
                                <Label for="assetTag">
                                    <Translate contentKey="asset-management.assetTag">Barkod</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="assetTag"
                                    validate={this.state.pageMode === PAGE_MODE.VIEW ? {} : {
                                        maxLength: {
                                            value: 50,
                                            errorMessage: "Bu alana en fazla 50 karakter girebilirsiniz."
                                        }
                                    }}
                                    value={this.state.hsmAsset.assetTag}
                                    onChange={this.__handleChange}
                                    disabled={this.state.pageMode === PAGE_MODE.VIEW}
                                />
                            </AvGroup>
                            <AvGroup>
                                <Label for="name">
                                    <Translate contentKey="asset-management.name">Envanter Adı</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="name"
                                    validate={this.state.pageMode === PAGE_MODE.VIEW ? {} : {
                                        maxLength: {
                                            value: 255,
                                            errorMessage: "Bu alana en fazla 255 karakter girebilirsiniz."
                                        },
                                        required: {
                                            value: "true",
                                            errorMessage: "Bu alanın doldurulması zorunludur."
                                        }
                                    }}
                                    value={this.state.hsmAsset.name}
                                    onChange={this.__handleChange}
                                    disabled={this.state.pageMode === PAGE_MODE.VIEW}
                                />
                            </AvGroup>
                            <AvGroup>
                                <Label for="serialNumber">
                                    <Translate contentKey="asset-management.serialNumber">Seri No</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="serialNumber"
                                    validate={this.state.pageMode === PAGE_MODE.VIEW ? {} : {
                                        maxLength: {
                                            value: 100,
                                            errorMessage: "Bu alana en fazla 100 karakter girebilirsiniz."
                                        }
                                    }}
                                    value={this.state.hsmAsset.serialNumber}
                                    onChange={this.__handleChange}
                                    disabled={this.state.pageMode === PAGE_MODE.VIEW}
                                />
                            </AvGroup>

                            <StatusGroup value={this.state.hsmAsset.status} onChange={this.__handleChange}
                                         disabled={this.state.pageMode === PAGE_MODE.VIEW}/>

                            <div className="position-relative form-group">
                                <Label for="installedDate">
                                    <Translate contentKey="asset-management.installedDate">Kurulum Tarihi</Translate>
                                </Label>
                                <DateInput
                                    name="installedDate"
                                    onChange={this.__handleChange}
                                    value={this.state.hsmAsset.installedDate}
                                    disabled={this.state.pageMode === PAGE_MODE.VIEW}
                                />
                            </div>
                            <div className="position-relative form-group">
                                <Label for="activatedDate" style={{color: "green"}}>
                                    <Translate contentKey="asset-management.activatedDate">Aktivasyon Tarihi</Translate>
                                </Label>
                                <DateInput
                                    name="activatedDate"
                                    onChange={this.__handleChange}
                                    value={this.state.hsmAsset.activatedDate}
                                    disabled={this.state.pageMode === PAGE_MODE.VIEW}
                                />
                            </div>

                            <div className="position-relative form-group">
                                <Label for="acceptanceDate">
                                    <Translate contentKey="asset-management.acceptanceDate">Kabul
                                        Tarihi</Translate>
                                </Label>
                                <DateInput
                                    name="acceptanceDate"
                                    onChange={this.__handleChange}
                                    value={this.state.hsmAsset.acceptanceDate}
                                    disabled={this.state.pageMode === PAGE_MODE.VIEW}
                                />
                            </div>

                            <div className="position-relative form-group">
                                <Label for="uninstalledDate" style={{color: "red"}}>
                                    <Translate contentKey="asset-management.uninstalledDate">İptal Tarihi</Translate>
                                </Label>
                                <DateInput
                                    name="uninstalledDate"
                                    onChange={this.__handleChange}
                                    value={this.state.hsmAsset.uninstalledDate}
                                    disabled={this.state.pageMode === PAGE_MODE.VIEW}
                                />
                            </div>


                        </Col>
                        <Col>
                            {/*<AvGroup>*/}
                            {/*<Label for="managementIp">*/}
                            {/*<Translate contentKey="asset-management.managementIp">Yönetim IPsi</Translate>*/}
                            {/*</Label>*/}
                            {/*<AvField*/}
                            {/*type="text"*/}
                            {/*className="form-control"*/}
                            {/*name="managementIp"*/}
                            {/*validate={{*/}
                            {/*maxLength: {*/}
                            {/*value: 15,*/}
                            {/*errorMessage: translate('entity.validation.maxlength', {max: 15})*/}
                            {/*}*/}
                            {/*}}*/}
                            {/*value={this.state.hsmAsset.managementIp}*/}
                            {/*onChange={this.__handleChange}*/}
                            {/*/>*/}
                            {/*<AvFeedback>This field too much longer than expected.</AvFeedback>*/}
                            {/*</AvGroup>*/}
                            <Fieldset legend="Marka & Model Bilgileri">
                                <AvGroup>
                                    <Label for="brandId">
                                        <Translate contentKey="asset-management.brandId">Marka Adı</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>
                                    <AvField type="select" className="form-control" name="brandId"
                                             value={this.state.hsmAsset.brandId}
                                             onChange={this.__handleChangeBrand}
                                             validate={this.state.pageMode === PAGE_MODE.VIEW ? {} : {
                                                 required: {
                                                     value: true,
                                                     errorMessage: "Bu alanın doldurulması zorunludur."
                                                 }
                                             }}
                                             disabled={this.state.pageMode === PAGE_MODE.VIEW}>

                                        <option value='' key=''/>
                                        {this.state.brandList.map(element => (
                                            <option value={element.id} key={element.id}>
                                                {element.name}
                                            </option>
                                        ))}
                                    </AvField>
                                </AvGroup>


                                <AvGroup>
                                    <Label for="modelId">
                                        <Translate contentKey="asset-management.modelId">Model Adı</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>
                                    <AvField type="select" className="form-control" name="modelId"
                                             value={this.state.hsmAsset.modelId}
                                             onChange={this.__handleChangeModel}
                                             validate={this.state.pageMode === PAGE_MODE.VIEW ? {} : {
                                                 required: {
                                                     value: true,
                                                     errorMessage: "Bu alanın doldurulması zorunludur."
                                                 }
                                             }}
                                             disabled={this.state.pageMode === PAGE_MODE.VIEW}>
                                        <option value='' key=''/>
                                        {this.state.modelList.map(element => (
                                            <option value={element.id} key={element.id}>
                                                {element.name}
                                            </option>
                                        ))}
                                    </AvField>
                                </AvGroup>

                            </Fieldset>
                            <Fieldset legend="Organizasyon & Lokasyon Bilgileri">
                                <OrganizationGroup
                                    value={this.state.organization}
                                    onDataSelect={this.onOrganizationSelect}
                                    dataTable={{
                                        dataURL: "hsmOrganization/findFilteredOrgs",
                                        fields: HsmOrganizationColumnForDialog.fields
                                    }}
                                    disabled={this.state.pageMode === PAGE_MODE.VIEW || this.state.disabledGeneric}
                                />
                                <AvGroup>
                                    <Label for="locationId">
                                        <Translate contentKey="asset-management.locationId">Lokasyon Adı</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>
                                    <AvField type="select" className="form-control" name="locationId"
                                             disabled={this.state.locationHidden || this.state.disabledGeneric || this.state.pageMode === PAGE_MODE.VIEW}
                                             validate={this.state.pageMode === PAGE_MODE.VIEW ? {} : {
                                                 required: {
                                                     value: true,
                                                     errorMessage: "Bu alanın doldurulması zorunludur."
                                                 }
                                             }}
                                             value={this.state.hsmAsset.locationId}
                                             onChange={this.__handleChange}>
                                        {this.state.locationList.map(element => (
                                            <option value={element.id} key={element.id}>
                                                {element.name}
                                            </option>
                                        ))}
                                    </AvField>
                                </AvGroup>

                            </Fieldset>
                            <AvGroup>
                                <Label for="hsmProjectId">
                                    Proje
                                </Label>
                                <AvField type="select" className="form-control" name="hsmProjectId"
                                         value={this.state.hsmAsset.hsmProjectId}
                                         onChange={this.__handleChange}>
                                    <option value='' key=''/>
                                    {this.state.hsmProjectList.map(element => (
                                        <option value={element.id} key={element.id}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>
                            <AvGroup>
                                <Label for="floorId">
                                    <Translate contentKey="asset-management.floorId">Kat</Translate>
                                </Label>
                                <AvField type="select" className="form-control" name="floorId"
                                         value={this.state.hsmAsset.floorId}
                                         onChange={this.__handleChange}
                                         disabled={this.state.pageMode === PAGE_MODE.VIEW}>

                                    <option value='' key=''/>
                                    {this.state.floorList.map(element => (
                                        <option value={element.id} key={element.id}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>


                            <AvGroup>
                                <Label for="roomId">
                                    <Translate contentKey="asset-management.roomId">Oda</Translate>
                                </Label>
                                <AvField type="select" className="form-control" name="roomId"
                                         value={this.state.hsmAsset.roomId}
                                         onChange={this.__handleChange}
                                         disabled={this.state.pageMode === PAGE_MODE.VIEW}>
                                    <option value='' key=''/>
                                    {this.state.roomList.map(element => (
                                        <option value={element.id} key={element.id}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>
                            {this.state.hsmAsset.assetCategory === ASSET_CATEGORY.ENCLOSURE &&
                            <AvGroup>
                                <Label for="rackUnit">
                                    <Translate contentKey="asset-management.rackUnit">Boyut</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="rackUnit"
                                    validate={this.state.pageMode === PAGE_MODE.VIEW ? {} : {
                                        maxLength: {
                                            value: 20,
                                            errorMessage: "Bu alana en fazla 20 karakter girebilirsiniz."
                                        }
                                    }}
                                    value={this.state.hsmAsset.rackUnit}
                                    onChange={this.__handleChange}
                                    disabled={this.state.pageMode === PAGE_MODE.VIEW}
                                />
                            </AvGroup>}
                        </Col>
                </Row>
                <Row style={{display: (this.state.hsmAsset.assetCategory === ASSET_CATEGORY.NETWORK_DEVICE)  ? 'inline' : 'none'}}>
                    <Fieldset legend="Ağ Adaptörü Bilgileri" >
                    <HsmNetworkInterface ref={(elem) => this.refHsmNetworkInterface = elem}
                                         closeDisplayDialog={this.props.closeDisplayDialog}/>
                    </Fieldset>
                </Row>
            </div>
        );
    }

    getData = () => {
        let data = Object.assign({}, this.state.hsmAsset);
        data.hsmProjectId = data.hsmProjectId ? {id: data.hsmProjectId} : null;
        data.brandId = {id: this.state.hsmAsset.brandId};
        data.modelId = {id: this.state.hsmAsset.modelId};
        data.locationId = {id: this.state.hsmAsset.locationId};
        data.organizationId = {id: this.state.hsmAsset.organizationId};
        data.floorId = this.state.hsmAsset.floorId ? {id: this.state.hsmAsset.floorId} : null;
        data.roomId = this.state.hsmAsset.roomId ? {id: this.state.hsmAsset.roomId} : null;
        return data;
    }

    getHsmNetworkInterfaceList=()=>{
        return this.refHsmNetworkInterface.getData();
    }

    getHsmNetworkInterfaceRemovedList=()=>{
        return this.refHsmNetworkInterface.getDataRemoved();
    }

    setData = (data, pageMode, displayButton) => {
        this.clearData();
        let organization = data.organizationId.name;
        data.organizationId = data.organizationId.id;
        data.locationId = data.locationId.id;
        data.floorId = data.floorId ? data.floorId.id : null;
        data.roomId = data.roomId ? data.roomId.id : null;
        data.brandId = data.brandId ? data.brandId.id : null;
        data.modelId = data.modelId ? data.modelId.id : null;
        data.hsmProjectId = data.hsmProjectId ? data.hsmProjectId.id : null;

        this.setState({
            hsmAsset: data,
            pageMode: pageMode,
            organization: organization,
            locationHidden: false
        });

        this.modelService.findModelsByBrandId(data.brandId).then(response => {
            this.setState({modelList: response});
        });

        this.locationService.findByOrganizationId(data.organizationId).then(response => {
            this.setState({
                locationList: response
            });
        });
        this.getAssetTypeList(data.assetCategory);
        if(data.assetCategory === ASSET_CATEGORY.NETWORK_DEVICE){
            this.refHsmNetworkInterface.setData(Object.assign({}, data), pageMode, displayButton);
        }
    }

    clearData = () => {
        this.setState({
            hsmAsset: Object.assign({}, defaultHsmAsset),
            pageMode: null,
            organization: null,
            locationList: [],
            locationHidden: true,
            disabledAssetCategory: false,
            assetTypeList: [],
            modelList: []
        });
        this.refHsmNetworkInterface.clearData();
    }

    __handleChangeAssetCategory = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let hsmAsset = this.state.hsmAsset;
        hsmAsset[e.target.name] = value;
        this.setState({hsmAsset: hsmAsset});
        this.getAssetTypeList(value);
    }

    getAssetTypeList=(value)=> {
        let assetCategoryId = 0;
        ASSET_CATEGORY_LIST.map(el => {
            if (el.value === value) {
                assetCategoryId = el.id;
            }
        });
        this.assetTypeService.findByAssetCategoryId(assetCategoryId).then(response => this.setState({assetTypeList: response}));
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let hsmAsset = this.state.hsmAsset;
        hsmAsset[e.target.name] = value;
        this.setState({hsmAsset: hsmAsset});
    }

    __handleChangeBrand = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let hsmAsset = this.state.hsmAsset;
        hsmAsset[e.target.name] = value;

        if (value) {
            this.getModelsByBrandId(value);
        } else {
            this.getModelsByBrandId(-1);
        }

    }

    __handleChangeModel = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let hsmAsset = this.state.hsmAsset;
        hsmAsset[e.target.name] = value;
       /* this.setAssetType(value);*/
    }

    getModelsByBrandId = (brandId, modelId) => {
        if(brandId != '-1') {
            this.modelService.findModelsByBrandId(brandId).then(response => {
                let hsmAsset = this.state.hsmAsset;
                hsmAsset.brandId = brandId;
                if (modelId) {
                    hsmAsset.modelId = modelId;
               /*     if (response.length == 1) {
                        hsmAsset.assetType = response[0].assetType;
                    }*/
                } else {
                    hsmAsset.modelId = '';
                    /*this.setAssetType(this.state.hsmAsset.modelId);*/
                }
                this.setState({modelList: response, hsmAsset: hsmAsset});
            });
        }
    }

/*    setAssetType = (model) => {
        this.modelService.findByModelIdAndBrandId(model).then(response => {
            let hsmAsset = this.state.hsmAsset;
            hsmAsset.assetType = response.assetType;
            this.setState({hsmAsset: hsmAsset});
        });
    }*/

    onOrganizationSelect = (data) => {
        let hsmAsset = this.state.hsmAsset;
        hsmAsset.organizationId = data.id;
        let organization = data.name;
        this.setOrgAndLoc(data.id);
        this.setState({
            hsmAsset: hsmAsset,
            organization: organization,
            locationHidden: false
        });
    }

    setOrgAndLoc = (orgId,locationId) => {
        this.locationService.findByOrganizationId(orgId).then(response => {
                let hsmAsset = this.state.hsmAsset;
                if (this.state.pageMode === PAGE_MODE.UPDATE) {
                    hsmAsset.locationId = response[0].id;
                } else {
                    if (!GeneralUtils.isNullOrEmpty(response)) {
                        if(!GeneralUtils.isNullOrEmpty(locationId)){
                            hsmAsset.locationId=locationId;
                        }else{
                            hsmAsset.locationId = response[0].id;
                        }
                    }
                }
                if (!GeneralUtils.isNullOrEmpty(response)) {
                    this.setState({hsmAsset: hsmAsset, locationList: response});
                }else{
                    this.setState({hsmAsset: hsmAsset});
                }
            }
        )
    }

    setOrgAndLocAndGeneric(organizationId, locationId) {
        this.hsmOrganizationService.findById(organizationId).then((response) => {
            let hsmAsset = this.state.hsmAsset;
            hsmAsset.organizationId = organizationId;
            hsmAsset.locationId = locationId;
            this.setOrgAndLoc(organizationId,locationId);
            this.getModelsByBrandId(GENERIC_BRAND_ID, GENERIC_MODEL_ID);
            this.setState({hsmAsset: hsmAsset, disabledGeneric: true, organization: response.name});
        });
    }

    setPageMode = (pageMode) => {
        this.setState({pageMode: pageMode});
    }

    setAssetCategory=(assetCategory)=>{
        if(assetCategory === ASSET_CATEGORY.NETWORK_DEVICE || assetCategory === ASSET_CATEGORY.ENCLOSURE || assetCategory === ASSET_CATEGORY.USER_DEVICE) {
            let hsmAsset = Object.assign({}, defaultHsmAsset);
            hsmAsset.assetCategory = assetCategory;
            this.setState({hsmAsset: hsmAsset, disabledAssetCategory: true});
            this.getAssetTypeList(assetCategory);
        }
    }
}

HsmAssetSave.propTypes = {
    hsmAsset: PropTypes.object
};




