import Service from './Service';
import GeneralUtils from "../utils/GeneralUtils";
import {SERVER_URL} from "../utils/Constants";

export default class HsmUserService extends Service {
    constructor() {
        super();
        this.baseURL = 'hsmUser';
    }

    resetLoginRetry(data) {
        return fetch(SERVER_URL + this.baseURL + '/resetLoginRetry', {
            crossDomain: true,
            method: 'put',
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")},
            body: JSON.stringify(data)
        }).then(response => GeneralUtils.notification(response)).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    sendActivationMail(data) {
        return fetch(SERVER_URL + this.baseURL + '/sendActivationMail', {
            crossDomain: true,
            method: 'put',
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")},
            body: JSON.stringify(data)
        }).then(response => GeneralUtils.notification(response)).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    changePassword(data) {
        return fetch(SERVER_URL + this.baseURL + '/changePassword', {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")},
            body: JSON.stringify(data)
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response);
            return result.json();
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    createHsmUserContact(data) {
        return fetch(SERVER_URL + this.baseURL + '/createHsmUserContact', {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")},
            body: JSON.stringify(GeneralUtils.addCreateInfo(data))
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response);
            return result.json();
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    updateHsmUserContact(data) {
        return fetch(SERVER_URL + this.baseURL + '/updateHsmUserContact', {
            crossDomain: true,
            method: 'put',
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")},
            body: JSON.stringify(GeneralUtils.addUpdateInfo(data))
        }).then(response => {
                let result = response.clone();
                GeneralUtils.notification(response);
                return result.json();
            }
        ).catch(error => {
            GeneralUtils.notification(error);
        });
    }
}
