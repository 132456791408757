import Service from './Service';
import GeneralUtils from "../utils/GeneralUtils";
import {SERVER_URL} from "../utils/Constants";

export default class LogViewService extends Service {
    constructor() {
        super();
        this.baseURL = 'logView';
    }

    checkPage() {
        return fetch(SERVER_URL + this.baseURL + "/checkPage", {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")},
        }).then(response => {
            GeneralUtils.checkUnauthorized(response);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    getLogFile() {
        return fetch(SERVER_URL + this.baseURL+ '/getLogFile/' , {
            headers: { Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }
}