import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class HsmSubnetPoolService extends Service {
    constructor() {
        super();
        this.baseURL = 'hsmSubnetPool';
    }

    saveOrUpdate(data) {
        return fetch(SERVER_URL + this.baseURL, {
            crossDomain: true,
            method: 'put',
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")},
            body: JSON.stringify(GeneralUtils.addUpdateInfo(data))
        }).then(response => {
                let result = response.clone();
                GeneralUtils.notification(response);
                return result.json();
            }
        ).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    findByGroupId(groupId) {
        return fetch(SERVER_URL + this.baseURL + '/findByGroupId/' + groupId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }


    findByGroupIdSubnetPool(organizationId, locationId) {
        return fetch(SERVER_URL + this.baseURL + '/findByGroupIdSubnetPool/'  + organizationId + '/' + locationId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    findByOrganizationId(organizationId) {
        return fetch(SERVER_URL + this.baseURL + '/findByOrganizationId/' + organizationId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    findByGroupIdSubnetPool(locationId) {
        return fetch(SERVER_URL + this.baseURL + '/findByGroupIdSubnetPool/' +  locationId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    findByIpAddress(ipAddress) {
        return fetch(SERVER_URL + this.baseURL + '/findByIpAddress/' +  ipAddress, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

}