import React from 'react';
import DataTableAll from '../../shared/component/dataTable/DataTableAll';
import DataTableWithButton from '../../shared/component/dataTable/DataTableWithButton';
import HsmAssetService from '../../shared/service/HsmAssetService';
import RequestAssetColumn from '../../shared/component/dataTable/columns/RequestAssetColumn';
import BbProductColumnForDialog from '../../shared/component/dataTable/columns/BbProductColumnForDialog';
import {ASSET_CATEGORY, PAGE_MODE, REQUEST_TYPE, SERVICE_SUBCATEGORY} from "../../shared/utils/Constants";
import RequestAssetService from '../../shared/service/RequestAssetService';
import Toolbar from "../../shared/component/toolbar/Toolbar";
import {SplitButton} from "primereact/splitbutton";
import GeneralUtils from "../../shared/utils/GeneralUtils";
import BbProductService from "../../shared/service/BbProductService";
import ServiceRequestService from "../../shared/service/ServiceRequestService";
import BusinessRuleService from "../../shared/service/BusinessRuleService";
import Dialog from "../../shared/component/dialog/Dialog";
import HsmAsset from "../asset-management/asset/HsmAsset";
import BbProduct from "../asset-management/bb-product/BbProduct";
import HsmAssetColumn from '../../shared/component/dataTable/columns/HsmAssetColumn';
import BbProductColumn from '../../shared/component/dataTable/columns/BbProductColumn';
import HermesSweetAlert from "../../shared/component/sweetAlert/HermesSweetAlert";
import InventoryColumnForExcel from '../../shared/component/dataTable/columns/InventoryColumnForExcel';
import HsmOrganizationColumnForDialog from '../../shared/component/dataTable/columns/HsmOrganizationColumnForDialog';
import {Button, Col, Row} from "reactstrap";
import HsmOrganizationService from "../../shared/service/HsmOrganizationService";
import LocationService from "../../shared/service/LocationService";
import Loading from "../../shared/component/others/Loading";
import {Fieldset} from "primereact/fieldset";
import OrganizationGroup from "../../shared/component/hsmGroup/OrganizationGroup";
import LocationGroup from "../../shared/component/hsmGroup/LocationGroup";
import {toast} from "react-toastify";
import * as XLSX from "xlsx";
import ModelService from "../../shared/service/ModelService";
import DataTableForExcel from "../../shared/component/dataTable/DataTableForExcel";

const assetTypeConst = {
    AP: "Access Point",
    KABINET: "Cabinet",
    SWITCH: "Switch",
    SFP: "SFP Transceiver Module"
}

export default class RequestAsset extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            assetData: [],
            assetBbProductData: [],
            displayHsmAssetListDialog: false,
            displayProductListDialog: false,
            pageMode: PAGE_MODE.CREATE,
            requestType: this.props.requestType,
            status: '',
            assetList: [],
            items: [],
            organizationId: null,
            locationId: null,
            showMode: '',
            displayAutoOrgAndLocInf:false,
            locationList: [{id: 0, name: ''}],
            organization:"Default",
            inventoryLocationId:null,
            displayDialogForExcel:false
        };
        this.hsmAssetService = new HsmAssetService();
        this.requestAssetService = new RequestAssetService();
        this.bbProductService = new BbProductService();
        this.serviceRequestService = new ServiceRequestService();
        this.businessRuleService = new BusinessRuleService();
        this.organizationService = new HsmOrganizationService();
        this.locationService = new LocationService();
        this.modelService = new ModelService();
    }


    componentDidMount = () => {
        let showMode = this.state.showMode;
        if (this.props.showMode) {
            showMode = 'INVENTORY';
        } else {
            showMode = 'ASSET'
        }

        this.setState({showMode});

        if (this.state.requestType == REQUEST_TYPE.SERVICE_REQUEST) {
            let items = [];
            this.addHsmAssetButton(items);
            this.setState({items: items})
        } else {
            this.setState({items: []})
        }
        this.refHsmAsset.setPageMode(PAGE_MODE.VIEW);
    }

    componentWillReceiveProps = (nextProps) => {
        let items = [];
        if (this.state.requestType == REQUEST_TYPE.SERVICE_REQUEST) {
            if (nextProps.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CONNECT) {
                this.addHsmAssetButton(items);
                this.createHsmAssetButton(items);
            } else if (nextProps.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_BILLING_APPROVAL || nextProps.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CHANGE_TITLE) {
                this.addBbProductButton(items);
            } else {
                this.addBbProductButton(items);
                this.addHsmAssetButton(items);
            }
        }
        if(this.state.showMode === 'ASSET'){
            this.setState({
                items: items,
                organizationId: nextProps.organizationId,
                locationId: nextProps.locationId,
                serviceSubcategoryId: nextProps.serviceSubcategoryId
                // assetList: !GeneralUtils.isNullOrEmpty(nextProps.getAssetList) ? nextProps.getAssetList : []
            });
        }else{
            this.setState({
                items: items,
                serviceSubcategoryId: nextProps.serviceSubcategoryId,
            });
        }
    }


    render() {
        return (
            <div>
                {(this.state.pageMode !== PAGE_MODE.VIEW || this.props.status === 'DRAFT' || this.state.showMode === 'INVENTORY') &&
                <Toolbar display={true}>
                    <div className="p-toolbar-group-right">
                        {this.state.items.length > 0  && this.props.taskStatus != 'CLOSED' &&
                        <SplitButton ref={elem => this.refSplitButton = elem} label="Ekle" icon="pi pi-plus"
                                     disabled={!GeneralUtils.checkPrivilege(RequestAssetColumn.name, 'Add')}
                                     onClick={this.showSplitButtonMenu}
                                     model={this.state.items}></SplitButton>
                        }
                    </div>
                    {GeneralUtils.checkPrivilege("RequestAsset","Add") && this.state.showMode === 'INVENTORY' &&
                    this.props.taskStatus != 'CLOSED' &&
                    <div className="p-toolbar-group-right">
                        <Button color="primary" onClick={()=> this.setState({
                            displayAutoOrgAndLocInf:true
                        })
                        }>
                            Otomatik Getir
                        </Button>
                    </div>}
                    {GeneralUtils.checkPrivilege("RequestAsset","Add") && this.state.showMode === 'INVENTORY' &&
                    this.props.taskStatus != 'CLOSED' &&
                    <div className="p-toolbar-group-right">
                        <Button color="primary" onClick={()=> {
                            this.setState({displayDialogForExcel: true});
                            this.clearDataExcel();
                        }
                        }>
                            Dosya ile Yükle
                        </Button>
                    </div>}
                    {GeneralUtils.checkPrivilege("RequestAsset","Add") &&this.state.showMode === 'INVENTORY' &&
                    this.props.taskStatus != 'CLOSED' &&
                    <div className="p-toolbar-group-right">
                        <Button color="primary" onClick={this.clearDataEnvanter}>
                            Temizle
                        </Button>
                    </div>}
                </Toolbar>
                }

                <DataTableWithButton fields={RequestAssetColumn.fields} objectName={RequestAssetColumn.name}
                                     data={this.state.assetList}
                                     delete={this.deleteRow}
                                     view={this.view}
                    // selectionMode="single" onDataSelect={this.onDataSelectMain}
                />

                <Dialog visible={this.state.displayHsmAssetListDialog} header="Cihaz Listesi" modal={true}
                        resizable={true}
                        maximizable={true} responsive={true}
                        onHide={() => this.setState({displayHsmAssetListDialog: false})} style={{width: '80%'}}>
                    <DataTableAll fields={HsmAssetColumn.fields} data={this.state.assetData}
                                  privilege="allow"
                                  selectionMode="single" onDataSelect={this.onDataSelect}/>
                </Dialog>

                <Dialog visible={this.state.displayProductListDialog} header="Genişbant Ürün Listesi" modal={true}
                        resizable={true}
                        maximizable={true} responsive={true}
                        onHide={() => this.setState({displayProductListDialog: false})} style={{width: '80%'}}>
                    <DataTableAll fields={BbProductColumnForDialog.fields} data={this.state.assetBbProductData}
                                  privilege="allow"
                                  selectionMode="single" onDataSelect={this.onDataSelect}/>
                </Dialog>


                <Dialog visible={this.state.displayHsmAsset} header="Cihaz Bilgisi" modal={true} resizable={true}
                        maximizable={true} responsive={true} onHide={() => this.setState({displayHsmAsset: false})}
                        style={{width: '50vw'}}>
                    <HsmAsset ref={(elem) => this.refHsmAsset = elem}
                              closeDisplayDialog={() => this.setState({displayHsmAsset: false})}/>
                </Dialog>

                <Dialog visible={this.state.displayProductDialog} header="Genişbant Ürün Görüntüleme" modal={true}
                        resizable={true} maximizable={true} responsive={true}
                        onHide={() => this.setState({displayProductDialog: false})} style={{width: '50vw'}}>
                    <BbProduct ref={(elem) => this.refBbProduct = elem}
                               closeDisplayDialog={() => this.setState({displayProductDialog: false})}/>
                </Dialog>


                {this.state.showMode === 'INVENTORY' &&
                <Dialog visible={this.state.displayAutoOrgAndLocInf} header="Organizasyon & Lokasyon" modal={true}
                        resizable={true} maximizable={true} responsive={true}
                        onHide={() => this.setState({displayAutoOrgAndLocInf: false})} style={{width: '50vw', height: '47vw'}}>
                    <Toolbar display={true}>
                        <div className="p-toolbar-group-right">
                            <Button color="primary" onClick={this.getAuto}>
                                Tamam
                            </Button>
                        </div>
                    </Toolbar>
                    <Fieldset legend="Organizasyon & Lokasyon Bilgileri">
                        <OrganizationGroup
                            value={this.state.organization}
                            dataTable={{
                                dataURL: "hsmOrganization/findFilteredOrgs",
                                fields: HsmOrganizationColumnForDialog.fields
                            }}
                            disabled={true}
                        />
                        <LocationGroup
                            value={this.state.location}
                            optionList={this.state.locationList}
                            disabled={false}
                            validate={true}
                            onChange={this.__handleChangeLocation}
                        />
                    </Fieldset>
                </Dialog>}

                {this.state.showMode === 'INVENTORY' &&
                <Dialog visible={this.state.displayDialogForExcel} header="Organizasyon & Lokasyon" modal={true}
                        resizable={true} maximizable={true} responsive={true}
                        onHide={() => this.setState({displayDialogForExcel: false})}
                        style={{width: '100%'}}>
                    <Toolbar display={true}>
                        <div className="p-toolbar-group-right">
                            <Button color="primary" onClick={()=> this.setState({displayDialogForExcel:false})}>
                                Vazgeç
                            </Button>
                            <Button color="primary" onClick={this.clearDataExcel}>
                                Temizle
                            </Button>
                            <Button color="primary" onClick={this.saveForExcel}>
                                Kaydet
                            </Button>
                        </div>
                    </Toolbar>
                    <Fieldset legend="Organizasyon & Lokasyon Bilgileri">
                        <Row>
                            <Col md="6">
                                <OrganizationGroup
                                    value={this.state.organization}
                                    dataTable={{
                                        dataURL: "hsmOrganization/findFilteredOrgs",
                                        fields: HsmOrganizationColumnForDialog.fields
                                    }}
                                    disabled={true}/>
                            </Col>
                        </Row>
                    </Fieldset>
                    {!GeneralUtils.isNullOrEmpty(this.state.organization) &&
                    <Row className="justify-content-center">
                        <br/>
                        <Button color="primary" onClick={this.downloadTaskExcel}>
                            Taslak Dosya İndir
                        </Button>
                        <input type='file' accept='.xlsx, .xls' onChange={this.onImportExcel}/>
                        <br/>
                        <DataTableForExcel ref={(elem) => this.refDataTable = elem}
                                           fields={InventoryColumnForExcel.fields}
                                           data={this.state.data}
                                           loading={this.state.loading}
                                           privilege="allow"/>
                    </Row>
                    }
                    {this.state.loading && <Loading/>}
                </Dialog>}
                {this.state.loading && <Loading/>}
            </div>
        );
    }

    showSplitButtonMenu = () => {
        this.refSplitButton.onDropdownButtonClick();
    }

    addNewHsmAsset = () => {
        if (!GeneralUtils.checkPrivilege('HsmAsset', 'Add')) {
            return;
        }
        if (GeneralUtils.isNullOrEmpty(this.state.organizationId) || GeneralUtils.isNullOrEmpty(this.state.locationId)) {
            return HermesSweetAlert.warn("Varlık eklemek için organizasyon ve lokasyon seçimi yapmalısınız");
        }
        this.hsmAssetService.findByOrganizationIdAndLocationId(this.state.organizationId, this.state.locationId)
            .then(response => this.setState({assetData: response, displayHsmAssetListDialog: true}));
    }

    addNewBbProduct = () => {
        if (!GeneralUtils.checkPrivilege('BbProduct', 'Add')) {
            return;
        }
        if (GeneralUtils.isNullOrEmpty(this.state.organizationId) || GeneralUtils.isNullOrEmpty(this.state.locationId)) {
            return HermesSweetAlert.warn("Varlık eklemek için organizasyon ve lokasyon seçimi yapmalısınız");
        }
        if (this.isExistMoreBbProduct()) {
            return HermesSweetAlert.warn("Bu talep için birden fazla genişbant ürünü seçilemez.");
        }
        if (this.state.requestType == REQUEST_TYPE.SERVICE_REQUEST) {
            this.bbProductService.findByOrganizationIdAndLocationIdAndStatus(this.state.organizationId, this.state.locationId, GeneralUtils.getAssetStatus(this.state.serviceSubcategoryId))
                .then(response => this.setState({assetBbProductData: response, displayProductListDialog: true}));
        } else {
            this.bbProductService.findByOrganizationIdAndLocationIdAndStatus(this.state.organizationId, this.state.locationId, ['ACTIVE'])
                .then(response => this.setState({assetBbProductData: response, displayProductListDialog: true}));
        }
    }


    view = (data) => {
        if (data.assetCategory === ASSET_CATEGORY.BROADBAND_PRODUCT) {
            this.bbProductService.findById(data.id).then((data) => {
                this.refBbProduct.setData(Object.assign({}, data), PAGE_MODE.VIEW);
                this.setState({displayProductDialog: true});
            });
        } else {
            this.hsmAssetService.findById(data.id).then((data) => {
                this.refHsmAsset.setData(data, PAGE_MODE.VIEW, false);
                this.setState({displayHsmAsset: true});
            });
        }
    }

    onDataListSelect =(dataList)=> {
        let assetList = this.state.assetList;
        dataList.forEach(data =>{
            if (GeneralUtils.checkPrivilege(BbProductColumn.name, 'View')) {
                data.isView = true;
            }
            data.assetCategory = ASSET_CATEGORY.BROADBAND_PRODUCT;
            data.deviceType = data.productType;
            assetList.push(data);
        });
        this.setState({assetList: assetList});
        if (this.props.setAssetList) {
            this.props.setAssetList(assetList);
        }
    }

    onDataSelect = (data, isDeleted) => {
        let assetList = this.state.assetList;
        if (isDeleted === undefined) {
            data.isDeleted = true;
        }
        if (this.state.requestType == REQUEST_TYPE.INCIDENT) {
            data.isControl = true;
        }

        if (!this.isExistsInList(assetList, data)) {
            if (this.state.displayHsmAssetListDialog === true ) {
                if (GeneralUtils.checkPrivilege(HsmAssetColumn.name, 'View')) {
                    data.isView = true;
                }
                assetList.push(data);
                this.setState({assetList: assetList});
            } else {
                if (GeneralUtils.checkPrivilege(BbProductColumn.name, 'View')) {
                    data.isView = true;
                }
                data.assetCategory = ASSET_CATEGORY.BROADBAND_PRODUCT;
                data.deviceType = data.productType;
                if (this.state.requestType == REQUEST_TYPE.SERVICE_REQUEST) {
                    this.businessRuleService.checkSR(this.state.serviceSubcategoryId, ASSET_CATEGORY.BROADBAND_PRODUCT, data.id).then(response => {
                        if (response === true) {
                            assetList.push(data);
                            this.setState({assetList: assetList});
                            if (this.props.setProductOfferingId) {
                                this.props.setProductOfferingId(data.productOfferingId);
                            }
                        }
                    });
                } else if (this.state.requestType == REQUEST_TYPE.INCIDENT) {
                    this.businessRuleService.checkIncident(ASSET_CATEGORY.BROADBAND_PRODUCT, data.id).then(response => {
                        if (response === true) {
                            assetList.push(data);
                            this.setState({assetList: assetList});
                        }
                    });
                } else {
                    assetList.push(data);
                    this.setState({assetList: assetList});
                }
            }
        }
        this.setState({displayHsmAssetListDialog: false, displayProductListDialog: false});
        if (this.props.setAssetList) {
            this.props.setAssetList(assetList);
        }
    }

    onHsmAssetSelect = (data) => {
        // this.hsmAssetService.findById(data.id)
        //     .then(data => {
        let assetList = this.state.assetList;
        if (!this.isExistsInList(assetList, data)) {
            if (GeneralUtils.checkPrivilege(HsmAssetColumn.name, 'View')) {
                data.isView = true;
            }
            data.isDeleted = true;
            assetList.unshift(data);
            this.setState({assetList: assetList});
            if (this.props.setAssetList) {
                this.props.setAssetList(assetList);
            }
            if (this.props.setLength) {
                this.setEnvanterLength(assetList);
            }
        }
        // });
    }

    setData = (data, requestType, pageMode, status, showMode) => {
        if (!GeneralUtils.isNullOrEmpty(data)) {
            this.requestAssetService.findAllAssetByRequestTypeAndRequestId(requestType, data.id).then((response) => {
                let assetList = this.addButton(response);
                this.setState({
                    requestType: requestType,
                    requestId: data.id,
                    assetList: assetList,
                    pageMode: pageMode,
                    status: status,
                    organizationId: data.organizationId,
                    locationId: data.locationId
                });

                if (this.props.setAssetList) {
                    this.props.setAssetList(assetList);
                }
                if(showMode === 'INVENTORY'){
                    this.organizationService.findById(data.organizationId).then(data=>{
                        let organization = data.code + ' - ' + data.name;
                        this.setState({
                            showMode,
                            organization,
                            inventoryOrganizationCode: data.code
                        });
                    })
                    this.setOrgAndLoc(data.organizationId);
                    this.setEnvanterLength(assetList);
                }
            });
        }
    }
    getData = () => {
        return this.state.assetList;
    }

    clearData = () => {
        this.setState({assetList: []});
    }

    clearDataEnvanter = () => {
        let assetList = this.state.assetList;
        assetList.forEach( (element) =>{
            if(element.isDeleted){
                assetList=this.arrayRemove(assetList,element)
            }
        });
        this.setEnvanterLength(assetList);
        this.setState({assetList});

    }

    setPageModeAndStatus = (pageMode, status) => {
        this.setState({pageMode: pageMode, status: status});
    }

    deleteRow = (rowData) => {
        let assetList = this.state.assetList;
        let newAssetList = this.arrayRemove(assetList, rowData);
        this.setState({assetList: newAssetList});
        if (this.props.setAssetList) {
            this.props.setAssetList(newAssetList);
        }
        if (this.state.requestType == REQUEST_TYPE.SERVICE_REQUEST && rowData.assetCategory === ASSET_CATEGORY.BROADBAND_PRODUCT) {
            if (this.props.setProductOfferingId) {
                this.props.setProductOfferingId(null);
            }
        }
        this.setEnvanterLength(newAssetList);
    }

    ////////////////////////////////////////////////UTILS FUNC//////////////////////////////////////////////////////////

    isExistsInListForAC = (assetList, data) => {
        var existsInList = false;
        for (var i = 0; i < assetList.length; i++) {
            if (data.id == assetList[i].id && data.assetCategory == assetList[i].assetCategory) {
                existsInList = true;
            }
        }
        return existsInList;
    }

    isExistsInList = (assetList, data) => {
        var existsInList = false;
        for (var i = 0; i < assetList.length; i++) {
            if (data.id == assetList[i].id) {
                existsInList = true;
            }
        }
        return existsInList;
    }

    arrayRemove = (arr, element) => {
        return arr.filter(function (el) {
            return el.id != element.id;
        });
    }

    arrayRemoveDeleted = (arr, element) => {
        return arr.filter(function (el) {
            return element.isDeleted;
        });
    }

    addButton = (response) => {
        return response.map((el) => {
            var o = Object.assign({}, el);
            if (this.state.requestType == REQUEST_TYPE.INCIDENT) {
                o.isControl = true;
            }
            if (o.assetCategory === ASSET_CATEGORY.BROADBAND_PRODUCT && GeneralUtils.checkPrivilege(BbProductColumn.name, 'View')) {
                o.isView = true;
            } else {
                if (GeneralUtils.checkPrivilege(HsmAssetColumn.name, 'View')) {
                    o.isView = true;
                }
            }
            return o;
        });
    }

    //KURAL 3
    isExistMoreBbProduct = () => {
        if (this.state.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CONNECT || this.state.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_INCREASE_BW || this.state.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_REDUCE_BW || this.state.requestType == REQUEST_TYPE.INCIDENT) {
            for (var i = 0; i < this.state.assetList.length; i++) {
                if (this.state.assetList[i].assetCategory == ASSET_CATEGORY.BROADBAND_PRODUCT) {
                    return true;
                }
            }
        }
    }

    ////////////////////////////////////////////////BUTTON FUNC//////////////////////////////////////////////////////////
    getAuto=()=>{
        if(GeneralUtils.isNullOrEmpty(this.state.inventoryLocationId)){
            return toast.warn('Lütfen lokasyon seçiniz.');
        }
        this.setState({loading: true,displayAutoOrgAndLocInf:false})
        this.requestAssetService.findByOrganizationIdAndLocationId(this.state.organizationId,this.state.inventoryLocationId).then(response => {
                let assetList = this.state.assetList;
                if(!GeneralUtils.isNullOrEmpty(response)) {
                    let newItemCount=0;
                    if(assetList.length==0 && response.length>0){//reques assette hiç asset yok ama organanizasyon ve lokasyonda tanımlı envarterler var ise hepsini yeni olarak ekle
                        response.forEach( (element) =>{
                            element.isDeleted = true;
                            element.isView = true;
                            assetList.unshift(element);
                        });
                        toast.info(response.length + ' Adet Yeni Envanter Bulunmuştur.');
                    }else{//request assette de envanter var ve yeni envanterler otomatik getirildiğinde
                        response.forEach( (element) =>{
                            if(!this.isExistsInListForAC(assetList, element)){
                                element.isDeleted = true;
                                element.isView = true;
                                assetList.unshift(element);
                                newItemCount++;
                            }
                        });
                        toast.info(newItemCount + ' Adet Yeni , '+(response.length-newItemCount)+' Adet Mevcut Envanter Bulunmuştur.');
                    }
                }else{
                    toast.info('İlgili organazisyon ve lokasyona ait envanter bulunamamıştır.');
                }
                this.setEnvanterLength(assetList);
                this.setState({assetList, loading:false});
            }
        );

    }

    addHsmAssetButton = (items) => {
        if (GeneralUtils.checkPrivilege('HsmAsset', 'Add')) {
            items.push( {
                label: 'Varlık Ekle',
                icon: 'pi pi-wifi',
                command: (e) => {
                    this.addNewHsmAsset();
                }
                /*disabled: !GeneralUtils.checkPrivilege('HsmAsset', 'Add')*/
            });
        }
    }

    createHsmAssetButton = (items) => {
        if (GeneralUtils.checkPrivilege('HsmAsset', 'Create')) {
            items.push( {
                label: 'Yeni Varlık Oluştur',
                icon: 'pi pi-wifi',
                command: (e) => {
                    if (!GeneralUtils.checkPrivilege('HsmAsset', 'Create')) {
                        return;
                    }
                    this.props.createNewHsmAsset();
                }
                /* disabled: !GeneralUtils.checkPrivilege('HsmAsset', 'Create')*/
            });
        }
    }

    addBbProductButton = (items) => {
        if (GeneralUtils.checkPrivilege('BbProduct', 'Add')) {
            items.push( {
                label: 'Genişbant Ekle',
                icon: 'pi pi-wifi',
                command: (e) => {
                    this.addNewBbProduct();
                }
                /*disabled: !GeneralUtils.checkPrivilege('BbProduct', 'Add')*/
            });
        }
    }


    getAssetsLength = () => {
        return this.state.assetList.length;
    }
    setEnvanterLength = (assetList) => {
        if(this.state.showMode === 'INVENTORY'){
            if (this.props.setLength) {
                this.props.setLength(assetList.length);
            }
        }
    }
    setOrgAndLoc = (orgId) => {
        this.locationService.findByOrganizationId(orgId).then(response => {
                if (!GeneralUtils.isNullOrEmpty(response)) {
                    this.setState({locationList: response});
                }
            }
        )
    }
    __handleChangeLocation = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        this.setState({inventoryLocationId: value});
    }


    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let state = this.state;
        state[e.target.name] = value;
        this.setState(state);
    }

    clearDataExcel = () =>{
        this.setState({data:[]});
    }

    saveForExcel =()=>{

        if(GeneralUtils.isNullOrEmpty(this.state.data)){
            return toast.warn('Lütfen dosya yükleyiniz.');
        }
        this.setState({loading: true});

        let data = this.state.data.map((element) => GeneralUtils.addCreateInfo(element));

        this.requestAssetService.createForExcel(data, this.state.requestType, this.state.requestId,this.state.inventoryOrganizationCode).then(response =>
            {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    /*
                                    this.setState({displayDialogForExcel: false});
                */
                    let errorCheckCount = 0;
                    response.forEach(element => {
                        if (element.hasError) {
                            errorCheckCount++;
                        }
                    })

                    if (errorCheckCount != 0) {
                        this.setState({loading: false, data: response});
                        return toast.warn('Lütfen hatalı kayıtları düzelterek kaydedin.');
                    }

                    this.requestAssetService.findAllAssetByRequestTypeAndRequestId(this.state.requestType, this.state.requestId).then((response) => {
                        let assetList = this.addButton(response);
                        this.setState({
                            assetList: assetList
                        });
                        if (this.props.setAssetList) {
                            this.props.setAssetList(assetList);
                        }
                        toast.success("İşlem başarıyla gerçekleştirilmiştir.");

                        this.setState({loading: false,displayDialogForExcel: false});
                    });
                }
            }
        );
    }

    onImportExcel = (file) => {
        let {files} = file.target;
        if (GeneralUtils.isNullOrEmpty(files)) {
            return;
        }
        let fileReader = new FileReader();
        fileReader.onload = event => {
            try {
                let {result} = event.target;
                let workbook = XLSX.read(result, {type: 'binary'});
                let data = [];
                for (let sheet in workbook.Sheets) {
                    if (workbook.Sheets.hasOwnProperty(sheet)) {
                        data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
                    }
                }

                let newDataArray = this.getNewDataArray(data);

                if (newDataArray.length == 0) {
                    return toast.warn('Dosyada kayıt bulunamadı.');
                }

                this.setState({loading: true});
                this.requestAssetService.checkForExcel(newDataArray, this.state.inventoryOrganizationCode).then(response => {
                    if (!GeneralUtils.isNullOrEmpty(response)) {
                        this.setState({
                            data: response,
                            loading: false
                        });
                    }
                });
            } catch (e) {
                this.setState({
                    loading: false
                });
                return toast.warn('Lütfen seçilen dosyayı kontrol ediniz.');
            }
        };
// Open the file in binary mode
        fileReader.readAsBinaryString(files[0]);
    }

    checkFile = (data) => {
        if (data.organizationId.code === undefined ||
            data.locationId.code === undefined ||
            data.floorId.name === undefined ||
            data.roomId.name === undefined ||
            data.brandId.name === undefined ||
            data.modelId.name === undefined ||
            data.assetTag === undefined ||
            data.serialNumber === undefined ||
            data.dateOfLive === undefined ||
            data.macAddress === undefined ||
            data.nature === undefined ||
            data.assetType === undefined ||
            data.temosId === undefined ||
            data.acceptanceDate === undefined ||
            data.activatedDate === undefined ||
            data.hsmProjectId.name === undefined ||
            data.createdBy === undefined
        ) {
            throw 'file type is incorrect';
        }
    }

    ExcelDateToJSDate = (serial) => {
        let utc_days = Math.floor(serial - 25569);
        let utc_value = utc_days * 86400;
        let date_info = new Date(utc_value * 1000);
        let fractional_day = serial - Math.floor(serial) + 0.0000001;
        let total_seconds = Math.floor(86400 * fractional_day);
        let seconds = total_seconds % 60;
        total_seconds -= seconds;
        let hours = Math.floor(total_seconds / (60 * 60));
        let minutes = Math.floor(total_seconds / 60) % 60;

        return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
    }

    getNewDataArray=(data) =>{
        let newDataArray=[];
        data.map(element => {
            let newData = {
                organizationId: {
                    code: ""
                },
                locationId: {
                    code: ""
                },
                floorId: {
                    name: ""
                },
                roomId: {
                    name: ""
                },
                brandId: {
                    name: ""
                },
                modelId: {
                    name: ""
                },
                assetTag: "",
                serialNumber: "",
                dateOfLive: "",
                macAddress: "",
                nature: "",
                assetType: "",
                temosId: "",
                acceptanceDate: "",
                activatedDate: "",
                hsmProjectId: {
                    name: ""
                },
                createdBy: GeneralUtils.getUserId()
            }
            newData.organizationId.code = element['ORGANIZASYON KODU'];
            newData.locationId.code = element['LOKASYON KODU'];
            newData.floorId.name = element['KAT'];
            newData.roomId.name = element['KONUM'];
            newData.assetType = assetTypeConst[element['NATURE']];
            newData.nature = element['TÜR'];
            newData.brandId.name = element['MARKA'];
            newData.modelId.name = element['MODEL'];
            newData.assetTag = element['BARKOD'];
            newData.serialNumber = element['SERI NO'];
            newData.macAddress = element['MAC AD.']
            newData.temosId = element['TEMOS ID'];
            newData.acceptanceDate = this.ExcelDateToJSDate(element['KABUL TARIHI']);
            newData.activatedDate = this.ExcelDateToJSDate(element['AKTIFLEME TARIHI']);
            newData.hsmProjectId.name = element['Faz'];
            this.checkFile(newData);
            newDataArray.push(newData);
            return element;
        });

        return newDataArray;
    }

    downloadTaskExcel = () => {
        let url = 'data/taskExcel.xlsx'
        let a = document.createElement('a')
        a.href = url
        a.download = 'taskExcel.xlsx'
        a.click()
        window.URL.revokeObjectURL(url)
    }


}
