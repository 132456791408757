import React from "react";
import {AvField as AvFieldRS} from 'availity-reactstrap-validation';
import GeneralUtils from "../../../shared/utils/GeneralUtils";

export default class AvField extends React.Component {

    render() {
        return (
            <AvFieldRS {...this.props} onChange={this.onChange} value={GeneralUtils.__isValueEmpty(this.props.value) ? '' : this.props.value.toLocaleUpperCase('TR')}/>
        );

    }
    onChange=(e)=>{
        e.target.value=e.target.value.toLocaleUpperCase('TR');
        if(this.props.onChange) {
            this.props.onChange(e);
        }
    }
}