import React from 'react';
import {Button, Label} from 'reactstrap';
import {AvGroup} from 'availity-reactstrap-validation';
import AvField from '../../../shared/component/form/AvField';
import PropTypes from 'prop-types';
import Translate from "../../../shared/language/Translate";
import HsmOrganizationService from "../../../shared/service/HsmOrganizationService";
import DistrictService from "../../../shared/service/DistrictService";
import CityService from "../../../shared/service/CityService";
import HsmOrganizationTypeService from "../../../shared/service/HsmOrganizationTypeService";
import {ORGANIZATION_ROLE, ORGANIZATION_SECTOR, PAGE_MODE, STATUS, SYS_ADMIN} from '../../../shared/utils/Constants';
import {Fieldset} from "primereact/fieldset";
import {PhoneField} from "../../../shared/component/form/PhoneField";
import Dialog from "../../../shared/component/dialog/Dialog";
import DataTable from "../../../shared/component/dataTable/DataTable";
import HsmOrganizationColumnForDialog from "../../../shared/component/dataTable/columns/HsmOrganizationColumnForDialog";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import BbProductService from "../../../shared/service/BbProductService";
import AvFieldUpper from "../../../shared/component/form/AvFieldUpper";

const defaultHsmOrganization = {
    id: '',
    name: '',
    parentOrganizationId: '',
    cityId: 1,
    districtId: '',
    status: 'A',
    code: '',
    nameIsDisabled: false,
    statusIsDisabled: false,
    email: '',
    businessPhone: '',
    hsmOrganizationType: null,
    description: '',
    role: '',
    taxNumber: '',
    taxOffice: '',
    commercialRegistrationNumber: '',
    sector: ''
};

export default class HsmOrganizationSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hsmOrganization: defaultHsmOrganization,
            cityList: [],
            hsmOrganizationTypeList: [],
            districtList: [],
            pageMode: null,
            disabledRole: false
        }
        this.service = new HsmOrganizationService();
        this.hsmOrganizationService = new HsmOrganizationService();
        this.cityService = new CityService();
        this.districtService = new DistrictService();
        this.hsmOrganizationTypeService = new HsmOrganizationTypeService();
        this.bbProductservice = new BbProductService();
    }

    componentDidMount = () => {
        this.clearData();
        this.cityService.findAll().then(response => this.setState({cityList: response}));
        this.hsmOrganizationTypeService.findAll().then(response => this.setState({hsmOrganizationTypeList: response}));
        this.findDistrictByChangedCityId(this.state.hsmOrganization.cityId);
    }

    render() {
        return (
            <div>

                <div className="container">
                    <div className="row">
                        <div className="col">
                            <AvGroup>
                                <Label for="code">
                                    <Translate contentKey="hsmOrganizationManagement.code">Organizasyon Kodu</Translate>
                                    <span style={{color: 'red'}}>(*)</span>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="code"
                                    validate={this.state.pageMode === PAGE_MODE.VIEW ? {} : {
                                        maxLength: {
                                            value: 20,
                                            errorMessage: "Bu alana en fazla 20 karakter girebilirsiniz."
                                        },
                                        required: {
                                            value: "true",
                                            errorMessage: "Bu alanın doldurulması zorunludur."
                                        }
                                    }}
                                    value={this.state.hsmOrganization.code}
                                    onChange={this.__handleChange}
                                    disabled={this.state.pageMode === PAGE_MODE.VIEW || this.state.nameIsDisabled}
                                />
                            </AvGroup>
                            <AvGroup>
                                <Label for="name">
                                    <Translate contentKey="hsmOrganizationManagement.name">Organizasyon Adı</Translate>
                                    <span style={{color: 'red'}}>(*)</span>
                                </Label>
                                <AvFieldUpper
                                    type="text"
                                    className="form-control"
                                    name="name"
                                    validate={this.state.pageMode === PAGE_MODE.VIEW ? {} : {
                                        maxLength: {
                                            value: 255,
                                            errorMessage: "Bu alana en fazla 255 karakter girebilirsiniz."
                                        },
                                        required: {
                                            value: "true",
                                            errorMessage: "Bu alanın doldurulması zorunludur."
                                        }
                                    }}
                                    disabled={this.state.pageMode === PAGE_MODE.VIEW}
                                    value={this.state.hsmOrganization.name}
                                    onChange={this.__handleChange}/>
                            </AvGroup>

                            <Fieldset legend="Bağlı Olduğu Organizasyon Bilgileri">
                                <AvGroup>
                                    <Label for="parentOrganizationId">
                                        <Translate contentKey="asset-management.parentOrganizationId">Bağlı Olduğu
                                            Organizasyon Adı</Translate>
                                        <span style={{color: 'red'}}>(*)</span>
                                    </Label>
                                    <div style={{position: "relative"}}>
                                        <AvField type="text" className="form-control" name="parentOrganizationId"
                                                 disabled
                                                 validate={this.state.pageMode === PAGE_MODE.VIEW ? {} : {
                                                     required: {
                                                         value: true,
                                                         errorMessage: "Bu alanın doldurulması zorunludur."
                                                     }
                                                 }}
                                                 value={this.state.hsmOrganization.parentOrganizationName}/>{' '}
                                        {this.state.pageMode !== PAGE_MODE.VIEW &&
                                        <Button color="primary" onClick={this.findAllOrganization}
                                                className="pi pi-search selectTableButton" size="sm"/>}
                                    </div>
                                </AvGroup>
                            </Fieldset>

                            {/*<Fieldset legend="Bağlı Olduğu Organizasyon Bilgileri">*/}
                            {/*    <OrganizationGroup*/}
                            {/*        name="parentOrganizationId"*/}
                            {/*        label="Bağlı Olduğu Organizasyon Adı"*/}
                            {/*        value={this.state.hsmOrganization.parentOrganizationName}*/}
                            {/*        onDataSelect={this.onOrganizationSelect}*/}
                            {/*        disabled={this.state.pageMode === PAGE_MODE.VIEW}*/}
                            {/*        validate={this.state.pageMode === PAGE_MODE.VIEW ? {} : {*/}
                            {/*            required: {*/}
                            {/*                value: true,*/}
                            {/*                errorMessage: "Bu alanın doldurulması zorunludur."*/}
                            {/*            }*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*</Fieldset>*/}
                            <br/>
                            <AvGroup>
                                <Label for="cityId">
                                    <Translate contentKey="hsmOrganizationManagement.cityId">İl Adı</Translate>
                                    <span style={{color: 'red'}}>(*)</span>
                                </Label>
                                <AvField type="select" className="form-control" name="cityId"
                                         value={this.state.hsmOrganization.cityId}
                                         onChange={this.__handleChangeCity}
                                         disabled={this.state.pageMode === PAGE_MODE.VIEW}>
                                    {this.state.cityList.map(element => (
                                        <option value={element.id} key={element.id}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>
                            <AvGroup>
                                <Label for="districtId">
                                    <Translate contentKey="hsmOrganizationManagement.districtId">İlçe Adı</Translate>
                                    <span style={{color: 'red'}}>(*)</span>
                                </Label>
                                <AvField type="select" className="form-control" name="districtId"
                                         value={!GeneralUtils.isNullOrEmpty(this.state.hsmOrganization.districtId) ? this.state.hsmOrganization.districtId : null }
                                         onChange={this.__handleChange}
                                         disabled={this.state.pageMode === PAGE_MODE.VIEW}
                                         validate={this.state.pageMode === PAGE_MODE.VIEW ? {} : {
                                             required: {
                                                 value: "true",
                                                 errorMessage: "Bu alanın doldurulması zorunludur."
                                             }
                                         }}>
                                    <option value='' key=''/>
                                    {this.state.districtList.map(element => (
                                        <option value={element.id} key={element.id}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>
                            <AvGroup>
                                <Label for="status">
                                    <Translate contentKey="hsmOrganizationManagement.status">Durum</Translate>
                                    <span style={{color: 'red'}}>(*)</span>
                                </Label>
                                <AvField type="select" className="form-control" name="status"
                                         disabled={this.state.pageMode === PAGE_MODE.VIEW || this.state.statusIsDisabled}
                                         value={this.state.hsmOrganization.status} onChange={this.__handleChange}>
                                    {STATUS.map(element => (
                                        <option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>
                            <AvGroup>
                                <Label for="email">
                                    <Translate contentKey="hsmOrganizationManagement.email">E-Posta</Translate>
                                    <span style={{color: 'red'}}>(*)</span>
                                </Label>
                                <AvField
                                    name="email"
                                    type="email"
                                    validate={this.state.pageMode === PAGE_MODE.VIEW ? {} : {
                                        required: {
                                            value: "true",
                                            errorMessage: "Bu alanın doldurulması zorunludur."
                                        },
                                        minLength: {
                                            "value": 5,
                                            "errorMessage": "Geçersiz E-Posta."
                                        },
                                        maxLength: {
                                            value: 50,
                                            errorMessage: "E-posta 50 karakterden uzun olamaz."
                                        }
                                    }}
                                    value={this.state.hsmOrganization.email} onChange={this.__handleChange}
                                    disabled={this.state.pageMode === PAGE_MODE.VIEW}/>
                            </AvGroup>
                            <AvGroup>
                                <Label for="businessPhone">
                                    <Translate contentKey="hsmOrganizationManagement.businessPhone">İş
                                        Telefonu</Translate>
                                </Label>
                                <PhoneField kind={'business'}
                                            name="businessPhone" errorMessage="Geçersiz Numara Girişi"
                                            value={this.state.hsmOrganization.businessPhone}
                                            onChange={this.__handleChange}
                                            disabled={this.state.pageMode === PAGE_MODE.VIEW}
                                />
                            </AvGroup>
                        </div>
                        <div className="col">
                            <AvGroup>

                                <Label for="hsmOrganizationType">
                                    <Translate contentKey="hsmOrganizationManagement.hsmOrganizationType">Organizasyon
                                        Tipi</Translate>
                                </Label>
                                <AvField type="select" className="form-control" name="hsmOrganizationType"
                                         value={this.state.hsmOrganization.hsmOrganizationType}
                                         onChange={this.__handleChange}
                                         disabled={this.state.pageMode === PAGE_MODE.VIEW}>
                                    <option value='' key=''/>
                                    {this.state.hsmOrganizationTypeList.map(element => (
                                        <option value={element.id} key={element.id}>
                                            {element.name}
                                        </option>
                                    ))}

                                </AvField>
                            </AvGroup>


                            <AvGroup>
                                <Label for="description">
                                    <Translate
                                        contentKey="hsmOrganizationManagement.description">Açıklama</Translate>
                                </Label>
                                <AvField
                                    type="textarea"
                                    className="form-control textAreaHermes"
                                    name="description"
                                    validate={this.state.pageMode === PAGE_MODE.VIEW ? {} : {
                                        maxLength: {
                                            value: 200,
                                            errorMessage: "Bu alana en fazla 200 karakter girebilirsiniz."
                                        }
                                    }}
                                    value={this.state.hsmOrganization.description}
                                    onChange={this.__handleChange}
                                    disabled={this.state.pageMode === PAGE_MODE.VIEW}
                                />
                            </AvGroup>
                            <AvGroup>
                                <Label for="role">
                                    <Translate contentKey="hsmOrganizationManagement.role">Rol</Translate>
                                    <span style={{color: 'red'}}>(*)</span>
                                </Label>
                                <AvField type="select" className="form-control" name="role"
                                         value={this.state.hsmOrganization.role} onChange={this.__handleChange}
                                         validate={this.state.pageMode === PAGE_MODE.VIEW ? {} : {
                                             required: {
                                                 value: "true",
                                                 errorMessage: "Bu alanın doldurulması zorunludur."
                                             }
                                         }}
                                         disabled={this.state.pageMode === PAGE_MODE.VIEW || this.state.disabledRole}>
                                    <option value='' key=''/>
                                    {ORGANIZATION_ROLE.map(element => (
                                        <option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>
                            <AvGroup>
                                <Label for="sector">
                                    <Translate contentKey="hsmOrganizationManagement.sector">Sektör</Translate>
                                    <span style={{color: 'red'}}>(*)</span>
                                </Label>
                                <AvField type="select" className="form-control" name="sector"
                                         value={this.state.hsmOrganization.sector} onChange={this.__handleChange}
                                         validate={this.state.pageMode === PAGE_MODE.VIEW ? {} : {
                                             required: {
                                                 value: "true",
                                                 errorMessage: "Bu alanın doldurulması zorunludur."
                                             }
                                         }}
                                         disabled={this.state.pageMode === PAGE_MODE.VIEW}>
                                    <option value='' key=''/>
                                    {ORGANIZATION_SECTOR.map(element => (
                                        <option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>
                            <Fieldset legend="Organizasyon Ticari Bilgileri">
                                <AvGroup>
                                    <Label for="taxNumber">
                                        <Translate contentKey="hsmOrganizationManagement.taxNumber">Vergi
                                            Numarası</Translate>
                                    </Label>
                                    <AvField
                                        type="number"
                                        min="1000000000"
                                        max="9999999999"
                                        className="form-control"
                                        name="taxNumber"
                                        validate={this.state.pageMode === PAGE_MODE.VIEW ? {} : {
                                            maxLength: {
                                                value: 50,
                                                errorMessage: "Bu alana en fazla 10 karakter girebilirsiniz."
                                            }
                                        }}
                                        disabled={this.state.pageMode === PAGE_MODE.VIEW || this.state.nameIsDisabled}
                                        value={this.state.hsmOrganization.taxNumber}
                                        onChange={this.__handleChange}
                                    />
                                </AvGroup>
                                <AvGroup>
                                    <Label for="taxOffice">
                                        <Translate contentKey="hsmOrganizationManagement.taxOffice">Vergi Dairesi
                                            Adı</Translate>
                                    </Label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="taxOffice"
                                        validate={this.state.pageMode === PAGE_MODE.VIEW ? {} : {
                                            maxLength: {
                                                value: 50,
                                                errorMessage: "Bu alana en fazla 50 karakter girebilirsiniz."
                                            }
                                        }}
                                        disabled={this.state.pageMode === PAGE_MODE.VIEW || this.state.nameIsDisabled}
                                        value={this.state.hsmOrganization.taxOffice}
                                        onChange={this.__handleChange}
                                    />
                                </AvGroup>
                                <AvGroup>
                                    <Label for="commercialRegistrationNumber">
                                        <Translate contentKey="hsmOrganizationManagement.commercialRegistrationNumber">Ticari
                                            Sicil Numarası</Translate>
                                    </Label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="commercialRegistrationNumber"
                                        validate={this.state.pageMode === PAGE_MODE.VIEW ? {} : {
                                            maxLength: {
                                                value: 50,
                                                errorMessage: "Bu alana en fazla 50 karakter girebilirsiniz."
                                            }
                                        }}
                                        value={this.state.hsmOrganization.commercialRegistrationNumber}
                                        onChange={this.__handleChange}
                                        disabled={this.state.pageMode === PAGE_MODE.VIEW}
                                    />
                                </AvGroup>
                            </Fieldset>
                        </div>
                    </div>
                </div>
                <Dialog id="OrganizationDialog" key="OrganizationDialogKey" visible={this.state.displayDialog}
                        header="Bağlı Olduğu Organizasyon Listesi" onHide={() => this.setState({displayDialog: false})}
                        style={{width: '90%'}}>
                    <DataTable ref={(elem) => this.refDataTable = elem} fields={HsmOrganizationColumnForDialog.fields} privilege="allow"
                               dataURL="hsmOrganization/findAll"
                               selectionMode="single" onDataSelect={this.onOrganizationSelect} autoLoad={false}/>
                </Dialog>
            </div>
        );
    }

    findAllOrganization = () => {
        this.refDataTable.refresh();
        this.setState({displayDialog: true});
    }

    setData = (data, pageMode) => {
        data.parentOrganizationName = data.parentOrganizationId ? data.parentOrganizationId.name : null;
        data.hsmOrganizationType = data.hsmOrganizationType ? data.hsmOrganizationType.id : null;
        data.cityId = data.cityId ? data.cityId.id : null;
        data.districtId = data.districtId ? data.districtId.id : null;

        this.setState({
            hsmOrganization: data,
            pageMode: pageMode
        });
        this.findDistrictByCityId(data.cityId);
        this.findNameAndStatusIsDisable(data);
    }

    getData = () => {
        let hsmOrganization = Object.assign({}, this.state.hsmOrganization);
        hsmOrganization.cityId = {id: this.state.hsmOrganization.cityId};
        hsmOrganization.districtId = {id: this.state.hsmOrganization.districtId};
        hsmOrganization.hsmOrganizationType = !GeneralUtils.isNullOrEmpty(hsmOrganization.hsmOrganizationType) ? {id: hsmOrganization.hsmOrganizationType} : null;
        return hsmOrganization;
    }

    clearData = () => {
        this.setState({
            hsmOrganization: Object.assign({}, defaultHsmOrganization),
            pageMode: null,
            nameIsDisabled: false,
            statusIsDisabled: false,
        });
        if(GeneralUtils.getUserId() != SYS_ADMIN){
            let hsmOrganization = Object.assign({}, defaultHsmOrganization);
            hsmOrganization.role = 'CustomerOrganization';
            this.setState({hsmOrganization: hsmOrganization, disabledRole: true});
        }else{
            let hsmOrganization = Object.assign({}, defaultHsmOrganization);
            hsmOrganization.role = '';
            this.setState({hsmOrganization: hsmOrganization, disabledRole: false});
        }
        this.findDistrictByChangedCityId(defaultHsmOrganization.cityId);
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let hsmOrganization = this.state.hsmOrganization;
        hsmOrganization[e.target.name] = value;
        this.setState({hsmOrganization: hsmOrganization});
    }

    __handleChangeCity = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let hsmOrganization = this.state.hsmOrganization;
        hsmOrganization[e.target.name] = value;
        this.findDistrictByChangedCityId(value);
    }

    findDistrictByCityId = (cityId) => {
        this.districtService.findByCityId(cityId).then(response => {
            this.setState({districtList: response});
        });
    }

    findDistrictByChangedCityId = (cityId) => {
        this.districtService.findByCityId(cityId).then(response => {
            let organizaton = this.state.hsmOrganization;
            organizaton.districtId = response[0].id;
            this.setState({districtList: response,hsmOrganization:organizaton});
        });
    }

    onOrganizationSelect = (data) => {
        let hsmOrganization = this.state.hsmOrganization;
        hsmOrganization.parentOrganizationId = data.id;
        hsmOrganization.parentOrganizationName = data.name;
        this.setState({hsmOrganization: hsmOrganization, displayDialog: false});
    }

    setPageMode = (pageMode) => {
        this.setState({pageMode: pageMode});
    }

    findNameAndStatusIsDisable = (data) => {
        this.bbProductservice.hasBbProductOnOrganization(data.id).then((response => {
            let statusIsDisabled = false;
            let nameIsDisabled = false;
            if (response) {
                nameIsDisabled = true;
                if (data.status == 'A') {
                    statusIsDisabled = true;
                }
            }
            this.setState({nameIsDisabled: nameIsDisabled, statusIsDisabled: statusIsDisabled})

        }));
    }
}

HsmOrganizationSave.propTypes = {
    hsmOrganization: PropTypes.object
};




