import React from 'react';
import {Panel} from 'primereact/panel';
import DataTable from '../../../shared/component/dataTable/DataTable';
import TeamService from '../../../shared/service/TeamService';
import TeamColumn from '../../../shared/component/dataTable/columns/TeamColumn';
import {PAGE_MODE} from "../../../shared/utils/Constants";
import {Button} from "reactstrap";
import Translate from "../../../shared/language/Translate";
import {TabPanel, TabView} from "primereact/tabview";
import TeamSave from "./TeamSave";
import TeamContact from "./TeamContact";
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import {AvForm} from "availity-reactstrap-validation";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import AuditEvent from "../../administration/AuditEvent";
import Dialog from "../../../shared/component/dialog/Dialog";

export default class Team extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageMode: null
        };
        this.service = new TeamService();
    }

    componentDidMount = () => {
        this.service.checkPage();
    }

    render() {
        return (
            <Panel header="Ekip Yönetimi">
                <Toolbar display={GeneralUtils.checkPrivilege(TeamColumn.name, 'Create')}>
                    <div className="p-toolbar-group-right">
                        <Button color="primary" onClick={this.addNew}>
                            <Translate contentKey="entity.action.add">Yeni Kayıt</Translate>
                        </Button>
                    </div>
                </Toolbar>

                <DataTable ref={(elem) => this.refDataTable = elem} fields={TeamColumn.fields} dataURL="team/findAllFilteredOrganization" objectName={TeamColumn.name}
                           selectionMode="single" onDataSelect={this.onDataSelect} loading={this.state.loading} export={true}/>

                <Dialog visible={this.state.displayDialog}
                        header={GeneralUtils.getDialogHeader('Ekip', this.state.pageMode)}
                        onHide={() => this.setState({displayDialog: false})} style={{width: '80%'}}>
                    <AvForm onValidSubmit={this.save} beforeSubmitValidation={() => this.setState({activeIndex: 0})}>
                        <Toolbar display={GeneralUtils.getDialogToolbarDisplay(TeamColumn.name, this.state.pageMode)}>
                            <div className="p-toolbar-group-right">
                                <Button color="primary" type="submit" className="float-right">
                                    <Translate contentKey="entity.action.save">Kaydet</Translate>
                                </Button>
                                <Button color="primary" className="float-right"
                                        onClick={() => this.setState({displayDialog: false})}>
                                    <Translate contentKey="entity.action.save">Vazgeç</Translate>
                                </Button>
                            </div>
                        </Toolbar>
                        <TabView activeIndex={this.state.activeIndex}
                                 onTabChange={(e) => this.setState({activeIndex: e.index})} renderActiveOnly={false}>
                            <TabPanel header="Ekip Bilgisi">
                                <TeamSave ref={elem => this.refTeamSave = elem}/>
                            </TabPanel>

                            <TabPanel header="Ekip Üyeleri" rightIcon="pi pi-user"
                                      disabled={this.state.pageMode === PAGE_MODE.CREATE}
                                      headerClassName={this.state.pageMode === PAGE_MODE.CREATE && 'displayNone'}>
                                <TeamContact ref={elem => this.refTeamContact = elem}/>
                            </TabPanel>

                            <TabPanel header="Tarihçe" rightIcon="pi pi-user"
                                      disabled={this.state.pageMode === PAGE_MODE.CREATE}
                                      headerClassName={this.state.pageMode === PAGE_MODE.CREATE && 'displayNone'}>
                                <AuditEvent ref={elem => this.refAuditEvent = elem} objectName={TeamColumn.name}/>
                            </TabPanel>
                        </TabView>
                    </AvForm>
                </Dialog>
            </Panel>
        );
    }

    refresh = () => {
        this.refDataTable.refresh();
    }

    onDataSelect = (data) => {
        let pageMode = GeneralUtils.getPageModeFromPrivilege(TeamColumn.name);
        this.setState({displayDialog: true, pageMode: pageMode, activeIndex: 0});
        this.refTeamSave.clearData();
        this.refTeamSave.setData(Object.assign({}, data), pageMode);
        this.refTeamContact.clearData();
        this.refTeamContact.setData(Object.assign({}, data), pageMode);
        this.refAuditEvent.setData(TeamColumn.name, data.id);
    }

    addNew = () => {
        this.setState({displayDialog: true, pageMode: PAGE_MODE.CREATE, activeIndex: 0});
        this.refTeamSave.clearData();
        this.refTeamContact.clearData();
    }

    save = () => {
        let team = this.refTeamSave.getData();
        let teamContactList = this.refTeamContact.getData();
        let teamContactRemovedList = this.refTeamContact.getDataRemoved();
        let data = {team: team, teamContactList: teamContactList, teamContactRemovedList: teamContactRemovedList};
        if (!GeneralUtils.isNullOrEmpty(team.id)) {
            data.team = GeneralUtils.addUpdateInfo(data.team);
            data.teamContactList = data.teamContactList.map((element) => GeneralUtils.addUpdateInfo(element));
            this.service.update(data).then((response) => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    this.setState({displayDialog: false});
                    this.refresh();
                }
            });
        } else {
            data.team = GeneralUtils.addCreateInfo(data.team);
            data.teamContactList = data.teamContactList.map((element) => GeneralUtils.addCreateInfo(element));
            this.service.create(data).then((response) => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    this.setState({displayDialog: false});
                    this.refresh();
                }
            });
        }
    }
}
