import React from 'react';
import {AvGroup} from "availity-reactstrap-validation";
import {Col, Label, Row} from "reactstrap";
import AvField from "../../../shared/component/form/AvField";
import {FLOW_ID, INCIDENT_STATUS, INCIDENT_STATUS_REASON, TRUEFALSE} from "../../../shared/utils/Constants";
import MailTemplateGroup from "../../../shared/component/hsmGroup/MailTemplateGroup";
import WorkFlowModelGroup from "../../../shared/component/hsmGroup/WorkFlowModelGroup";

const defaultWorkFlowProcess = {
    id: '',
    workflowModelId:'',
    statusAfter: '',
    statusReasonAfter: '',
    nextStep: '',
    nextFlow: '',
    mailTemplate: '',
    otoAssign: true,
    otoAssignTime: ''

};
export default class WorkFlowProcessSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            workFlowProcess: defaultWorkFlowProcess,
            team: null,
            mailTemplate: null,
            workflowModel: null,
            pageMode: null,
            // workflowModelId: null
        };
    }

    componentDidMount = () => {
        this.clearData();
    }

    render() {

        return (
            <Row className="justify-content-center">
                <Col>
                    <WorkFlowModelGroup
                        value={this.state.workflowModel}
                        onDataSelect={this.onWorkFlowModelSelect}
                        name="workFlowModel"
                    />
                    <MailTemplateGroup
                        value={this.state.mailTemplate}
                        onDataSelect={this.onMailTemplateSelect}
                        name="mailTemplate"
                    />
                    <AvGroup>
                        <Label for="nextStep">Sonraki Adım<span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="number"
                            className="form-control"
                            name="nextStep"
                            value={this.state.workFlowProcess.nextStep}
                            max="999999"
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                }
                            }}
                            onChange={this.__handleChange}
                        />
                    </AvGroup>
                    <AvGroup>
                        <Label for="nextFlow">Flow Id<span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="select"
                            className="form-control"
                            name="nextFlow"
                            value={this.state.workFlowProcess.nextFlow}
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                },
                                minLength: {
                                    value: 1,
                                    errorMessage: 'Bu alan en az 1 karakterden oluşabilir.'
                                },
                                maxLength: {
                                    value: 100,
                                    errorMessage: 'Bu alan en fazla 100 karakterden oluşabilir.'
                                }
                            }}
                            onChange={this.__handleChange}>
                            <option value='' key=''/>
                            {FLOW_ID.map(element => (
                                <option value={element.value} key={element.value}>
                                    {element.name}
                                </option>
                            ))}
                        </AvField>
                    </AvGroup>
                    <AvGroup>
                        <Label for="statusAfter">Yeni Durum Bilgisi<span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="select"
                            className="form-control"
                            name="statusAfter"
                            value={this.state.workFlowProcess.statusAfter}
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                }
                            }}
                            onChange={this.__handleChange}>
                            <option value='' key=''/>
                            {INCIDENT_STATUS.map(element => (   //burası servıcerequeste gore olacak
                                <option value={element.value} key={element.value}>
                                    {element.name}
                                </option>
                            ))}
                        </AvField>
                    </AvGroup>
                    <AvGroup>
                        <Label for="statusReasonAfter">Durum Nedeni
                        </Label>
                        <AvField
                            type="select"
                            className="form-control"
                            name="statusReasonAfter"
                            value={this.state.workFlowProcess.statusReasonAfter}
                            onChange={this.__handleChange}>
                            <option value='' key=''/>
                            {INCIDENT_STATUS_REASON.map(element => (   //burası servıcerequeste gore olacak
                                <option value={element.value} key={element.value}>
                                    {element.name}
                                </option>
                            ))}
                        </AvField>
                    </AvGroup>
                    {/*<AvGroup>*/}
                    {/*    <Label for="autoAssign">Otomatik Atama<span style={{color: 'red'}}> (*)</span>*/}
                    {/*    </Label>*/}
                    {/*    <AvField type="select" className="form-control" name="autoAssign"*/}
                    {/*             value={this.state.workFlowProcess.autoAssign}*/}
                    {/*             validate={{*/}
                    {/*                 required: {*/}
                    {/*                     value: false,*/}
                    {/*                     errorMessage: 'Bu alan zorunlu.'*/}
                    {/*                 }*/}
                    {/*             }}*/}
                    {/*             onChange={this.__handleChange}>*/}
                    {/*        {TRUEFALSE.map(element => (*/}
                    {/*            <option value={element.value} key={element.value}>*/}
                    {/*                {element.name}*/}
                    {/*            </option>*/}
                    {/*        ))}*/}
                    {/*    </AvField>*/}
                    {/*</AvGroup>*/}
                    {/*<AvGroup>*/}
                    {/*    <Label for="otoAssignTime">Otomatik Atama Süresi<span style={{color: 'red'}}> (*)</span>*/}
                    {/*    </Label>*/}
                    {/*    <AvField*/}
                    {/*        type="number"*/}
                    {/*        className="form-control"*/}
                    {/*        name="otoAssignTime"*/}
                    {/*        value={this.state.workFlowProcess.otoAssignTime}*/}
                    {/*        max="999999"*/}
                    {/*        validate={{*/}
                    {/*            required: {*/}
                    {/*                value: true,*/}
                    {/*                errorMessage: 'Bu alan zorunlu.'*/}
                    {/*            }*/}
                    {/*        }}*/}
                    {/*        onChange={this.__handleChange}*/}
                    {/*    />*/}
                    {/*</AvGroup>*/}
                </Col>
            </Row>
        );
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let workFlowProcess = this.state.workFlowProcess;
        workFlowProcess[e.target.name] = value;
        this.setState({workFlowProcess: workFlowProcess});
    }

    setData = (data, pageMode) => {
        console.log(data);
        data.statusAfter = data.statusAfter ? data.statusAfter : null;
        data.statusReasonAfter = data.statusReasonAfter ? data.statusReasonAfter : null;
        data.nextStep = data.nextStep ? data.nextStep : null;
        data.nextFlow = data.nextFlow ? data.nextFlow : null;
        data.mailTemplate = data.mailTemplate ? data.mailTemplate : null
        data.otoAssign = data.otoAssign ? data.otoAssign : null
        data.otoAssignTime = data.otoAssignTime ? data.otoAssignTime : null
        let workflowModel = data.workflowModelId ? data.workflowModelId.categoryId.name + " / " + data.workflowModelId.subcategoryId.name + " / " + data.workflowModelId.step : null;
        this.setState({workFlowProcess: data, workflowModel, mailTemplate: data.mailTemplate, pageMode: pageMode});
    }

    getData = () => {
        return this.state.workFlowProcess;
    }

    clearData = () => {
        this.setState({
            workFlowProcess: Object.assign({}, defaultWorkFlowProcess),
            mailTemplate: null,
            workflowModel: null,
            // workflowModelId:null,
            pageMode: null
        });
    }

    onWorkFlowModelSelect = (data) => {
        let workFlowProcess = this.state.workFlowProcess;
        workFlowProcess.workflowModelId = data;
        this.setState({
            workFlowProcess: workFlowProcess,
            workflowModel: data.categoryId.name + " / " + data.subcategoryId.name + " / " + data.step+ " / " +data.teamId.name,
            displayDialog: false
        });
    }

    onMailTemplateSelect = (data) => {
        let workFlowProcess = this.state.workFlowProcess;
        workFlowProcess.mailTemplate = data.name;
        this.setState({workFlowProcess: workFlowProcess, mailTemplate: data.name, displayDialog: false});
    }

}
