import React from 'react';
import {AvGroup} from "availity-reactstrap-validation";
import {Col, Label, Row} from "reactstrap";
import AvField from "../../shared/component/form/AvField";
import OrganizationGroup from "../../shared/component/hsmGroup/OrganizationGroup";

const defaultSla = {
    id: '',
    name: '',
    customerOrgId: null,
    providerOrgId: null
    
};
export default class SlaSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sla: defaultSla,
            customerOrg:null,
            providerOrg:null,
            pageMode: null
        };
    }

    render() {
        return (
            <Row className="justify-content-center">
                <Col>
                    <AvGroup>
                        <Label for="name">SLA<span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="name"
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                },
                                minLength: {
                                    value: 1,
                                    errorMessage: 'Bu alan en az 1 karakterden oluşabilir.'
                                },
                                maxLength: {
                                    value: 255,
                                    errorMessage: 'Bu alan en fazla 255 karakterden oluşabilir.'
                                }
                            }}
                            value={this.state.sla.name}
                            onChange={this.__handleChange}
                        />
                    </AvGroup>
                    <OrganizationGroup
                            value={this.state.customerOrg}
                            onDataSelect={this.onCustomerOrganizationSelect}
                            label="Müşteri"
                            name="customerOrg"
                        />
                    <OrganizationGroup
                            value={this.state.providerOrg}
                            onDataSelect={this.onProviderOrganizationSelect}
                            label="Servis Sağlayıcı"
                            name="providerOrg"
                        />
                </Col>
            </Row>
        );
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let sla = this.state.sla;
        sla[e.target.name] = value;
        this.setState({sla: sla});
    }

    setData = (data, pageMode) => {
        let customerOrg = data.customerOrgId ? data.customerOrgId.name : null;
        let providerOrg = data.providerOrgId ? data.providerOrgId.name : null;
        this.setState({sla: data, customerOrg:customerOrg,providerOrg:providerOrg, pageMode: pageMode});
    }

    getData = () => {
        return this.state.sla;
    }

    clearData = () => {
        this.setState({sla: Object.assign({}, defaultSla),customerOrg:null,providerOrg:null, pageMode: null});
    }
    onCustomerOrganizationSelect = (data) => {
        let sla = this.state.sla;
        sla.customerOrgId = data;
        this.setState({sla: sla, customerOrg: data.name, displayDialog: false});
    }
    onProviderOrganizationSelect = (data) => {
        let sla = this.state.sla;
        sla.providerOrgId = data;
        this.setState({sla: sla, providerOrg: data.name, displayDialog: false});
    }

}
