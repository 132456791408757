import React from 'react';
import {PAGE_MODE} from "../../../shared/utils/Constants";
import HsmDocumentService from "../../../shared/service/HsmDocumentService";
import HsmAssetDocumentColumn from '../../../shared/component/dataTable/columns/HsmAssetDocumentColumn.json';
import Dialog from "../../../shared/component/dialog/Dialog";
import AttachmentSave from "../../request/attacheddocument/AttachmentSave";
import {Panel} from "primereact/panel";
import DataTable from "../../../shared/component/dataTable/DataTable";
import Loading from "../../../shared/component/others/Loading";

export default class HsmAssetDocumentList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.service = new HsmDocumentService();

    }

    componentDidMount = () => {
        this.service.checkPage();
    }

    render() {
        return (
            <div>

                <Panel header="Varlık Dokümanları Listesi">
                    <DataTable ref={(elem) => this.refDataTable = elem} fields={HsmAssetDocumentColumn.fields}
                               dataURL="hsmDocument/findAllHsmAssetDocument"
                               privilege="allow"
                               selectionMode="single" onDataSelect={this.onDataSelect} refresh={true}  export={true}/>
                </Panel>

                <Dialog visible={this.state.displayDialog}
                        header='Doküman Görüntüleme'
                        onHide={() => this.setState({displayDialog: false})} style={{width: '50vw'}}>
                    <AttachmentSave ref={(elem) => this.refAttachmentSave = elem}
                                    closeDisplayDialog={this.closeDisplayDialog}/>

                </Dialog>
                {this.state.loading && <Loading/>}
            </div>
        );
    }


    onDataSelect = (data) => {
        this.setState({loading: true});
        let documentId = data.documentId;

        this.service.findDocumentPartsByDocumentId(documentId).then(res => {
            let documentParts = res;
            this.setState({displayDialog: true, loading: false});
            this.refAttachmentSave.setData(Object.assign({}, data), PAGE_MODE.VIEW, documentParts);
        });
    }

    closeDisplayDialog = () => {
        this.refDataTable.refresh();
        this.setState({displayDialog: false});
    }
}
