import React from 'react';
import DataTableAll from "../../../shared/component/dataTable/DataTableAll";
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import {Button} from "reactstrap";
import Translate from "../../../shared/language/Translate";
import BbProductColumn from "../../../shared/component/dataTable/columns/BbProductColumn.json";
import {PAGE_MODE, TENANT_ID} from "../../../shared/utils/Constants";
import Dialog from "../../../shared/component/dialog/Dialog";
import HsmSubnetPoolSave from "./HsmSubnetPoolSave";
import HsmSubnetPoolService from "../../../shared/service/HsmSubnetPoolService";
import TenantService from "../../../shared/service/TenantService";
import {Fieldset} from "primereact/fieldset";
import HsmSubnetColumn from "../../../shared/component/dataTable/columns/HsmSubnetColumn.json";


export default class HsmSubnetPool extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hsmSubnetPoolList: [],
            hsmSubnetPoolRemovedList: [],
            pageMode: PAGE_MODE.UPDATE,
            fields: HsmSubnetColumn.fields
        };
        this.service = new HsmSubnetPoolService();
        this.tenantService = new TenantService();
    }

    componentDidMount = () => {
        this.tenantService.getTenant().then(result => {
            if (!GeneralUtils.isNullOrEmpty(result) && result == TENANT_ID.MEB) {
                this.setState({fields: HsmSubnetColumn.fields});
            }
        });
    }

    componentWillReceiveProps = (nextProps) => {
        if (this.props.data) {
            this.setState({hsmSubnetPoolList: nextProps.data, pageMode: nextProps.pageMode, status: nextProps.status});
        }
    }


    render() {
        return (
            <Fieldset legend="Subnet Bilgileri">
                <div>
                    {(this.state.pageMode !== PAGE_MODE.VIEW || this.state.status === 'DRAFT') &&
                    <Toolbar display={true}>
                        <div className="p-toolbar-group-right">
                            <Button color="primary" onClick={this.addNew}>
                                <Translate contentKey="entity.action.add">Subnet Ekle</Translate>
                            </Button>
                        </div>
                    </Toolbar>}
                    <DataTableAll ref={(elem) => this.refDataTable = elem} fields={HsmSubnetColumn.fields}
                                  data={this.state.hsmSubnetPoolList} objectName={HsmSubnetColumn.name}
                                  autoLoad={false}
                                  selectionMode={(this.state.pageMode !== PAGE_MODE.VIEW || this.state.status === 'DRAFT') ? "single" : null}
                                  onDataSelect={this.onDataSelect} privilege="allow"/>

                    <Dialog visible={this.state.displayDialog}
                            header={GeneralUtils.getDialogHeader('Subnet ', this.state.pageMode)} modal={true}
                            onHide={() => this.setState({displayDialog: false})} style={{width: '50vw'}}>
                        <HsmSubnetPoolSave
                            ref={(elem) => this.refHsmSubnetPoolSave = elem}
                            closeDisplayDialog={this.closeDisplayDialog}
                            refresh={this.refresh}
                            add={this.getListItem} delete={this.deleteListItem}
                            checkName={this.checkName}
                            checkIpAddress={this.checkIpAddress}
                        />
                    </Dialog>
                </div>
            </Fieldset>
        );
    }

    addNew = () => {
        this.setState({displayDialog: true, pageMode: PAGE_MODE.UPDATE, activeIndex: 0});
        this.refHsmSubnetPoolSave.clearData();
        this.refHsmSubnetPoolSave.setAssetIdAndPageMode(this.state.productId, PAGE_MODE.UPDATE);
    }

    setData = (data, pageMode) => {
        this.clearData();
        this.setState({hsmSubnetPoolList: data ? data : [], pageMode: pageMode});
    }

    getData = () => {
        return this.state.hsmSubnetPoolList;
    }

    getDataRemoved = () => {
        return this.state.hsmSubnetPoolRemovedList;
    }

    clearData = () => {
        this.setState({
            hsmSubnetPoolList: [],
            hsmSubnetPoolRemovedList: []
        })
    }

    onDataSelect = (data) => {
        let pageMode = GeneralUtils.getPageModeFromPrivilege(BbProductColumn.name);
        this.setState({displayDialog: true, pageMode: pageMode});
        this.refHsmSubnetPoolSave.clearData();
        this.refHsmSubnetPoolSave.setData(Object.assign({}, data), pageMode);
    }

    closeDisplayDialog = () => {
        this.setState({displayDialog: false});
    }

    setPageMode = (pageMode) => {
        this.setState({pageMode: pageMode});
    }

    getListItem = (data) => {
        let hsmSubnetPoolList = this.arrayRemove(this.state.hsmSubnetPoolList, data);
        hsmSubnetPoolList.push(data);
        this.setState({hsmSubnetPoolList: hsmSubnetPoolList});
        if (this.props.setHsmSubnetPoolList) {
            this.props.setHsmSubnetPoolList(hsmSubnetPoolList);
        }
    }

    deleteListItem = (data) => {
        let hsmSubnetPoolRemovedList = this.state.hsmSubnetPoolRemovedList;
        hsmSubnetPoolRemovedList.push(data);
        let hsmSubnetPoolList = this.arrayRemove(this.state.hsmSubnetPoolList, data);
        this.setState({hsmSubnetPoolList: hsmSubnetPoolList, hsmSubnetPoolRemovedList: hsmSubnetPoolRemovedList})
    }

    arrayRemove = (arr, element) => {
        return arr.filter(function (el) {
            return el.name !== element.name;
        });
    }

    checkName = (selectedName) => {
        let hsmSubnetPoolList = this.state.hsmSubnetPoolList;
        for (let i = 0; i < hsmSubnetPoolList.length; i++) {
            if (hsmSubnetPoolList[i].name === selectedName) {
                return true;
            }
        }
        return false;
    }

    checkIpAddress = (selectedIpAddress) => {
        let hsmSubnetPoolList = this.state.hsmSubnetPoolList;
        for (let i = 0; i < hsmSubnetPoolList.length; i++) {
            if (hsmSubnetPoolList[i].ipAddress === selectedIpAddress) {
                return true;
            }
        }
        return false;
    }


    setPageModeAndStatus = (pageMode, status) => {
        this.setState({pageMode: pageMode, status: status});
    }

}


