import React from "react";
import IncidentService from "../../shared/service/IncidentService";
import ServiceRequestService from "../../shared/service/ServiceRequestService";
import HsmOrganizationService from "../../shared/service/HsmOrganizationService";
import LocationService from "../../shared/service/LocationService";
import BbProductService from "../../shared/service/BbProductService";
import HsmAssetService from "../../shared/service/HsmAssetService";
import GeneralUtils from "../../shared/utils/GeneralUtils";
import OverviewBox from "../../shared/component/OverviewBox/OverviewBox";

export default class Overview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            countIncident: 0,
            countServiceRequest: 0,
            countHsmOrganization: 0,
            countLocation: 0,
            countBbProduct: 0,
            countHsmAsset: 0
        };

        this.incidentService = new IncidentService();
        this.serviceRequestService = new ServiceRequestService();
        this.hsmOrganizationService = new HsmOrganizationService();
        this.locationService = new LocationService();
        this.bbProductService = new BbProductService();
        this.hsmAssetService = new HsmAssetService();
    }

    componentDidMount = () => {
        this.refresh();
    }


    render() {
        return (
            <>
                <div className="grid p-fluid dashboard">
                    <div className="p-grid">
                        {GeneralUtils.checkPrivilege('Incident', 'ViewList') &&
                            <OverviewBox
                                link="#/incidentList"
                                title="Arıza"
                                count={this.state.countIncident}
                                icon="assets/icons/user-request-deadline.svg"/>}

                        {GeneralUtils.checkPrivilege('ServiceRequest', 'ViewList') &&
                            <OverviewBox
                                link="#/serviceRequestList"
                                title="Hizmet Talebi"
                                count={this.state.countServiceRequest}
                                icon="assets/icons/user-request.svg"/>}

                        {GeneralUtils.checkPrivilege('HsmOrganization', 'ViewList') &&
                            <OverviewBox
                                link="#/hsmOrganization"
                                title="Organizasyon"
                                count={this.state.countHsmOrganization}
                                icon="assets/icons/organization.svg"/>}

                        {GeneralUtils.checkPrivilege('Location', 'ViewList') &&
                            <OverviewBox
                                link="#/location"
                                title="Lokasyon"
                                count={this.state.countLocation}
                                icon="assets/icons/map-marker.svg"/>}

                        {GeneralUtils.checkPrivilege('BbProduct', 'ViewList') &&
                            <OverviewBox
                                link="#/broadbandProduct"
                                title="Genişbant Ürünleri"
                                count={this.state.countBbProduct}
                                icon="assets/icons/network.svg"/>}

                        {GeneralUtils.checkPrivilege('HsmAsset', 'ViewList') &&
                            <OverviewBox
                                link="#/hsmAsset?assetCategory=ALL"
                                title="Varlık"
                                count={this.state.countHsmAsset}
                                icon="assets/icons/wi-fi-router.svg"/>}

                    </div>
                </div>
            </>
        )
    }

    refresh = () => {
        this.incidentService.getCount().then(response => this.setState({countIncident: response}));
        this.serviceRequestService.getCount().then(response => this.setState({countServiceRequest: response}));
        this.hsmOrganizationService.getCount().then(response => this.setState({countHsmOrganization: response}));
        this.locationService.getCount().then(response => this.setState({countLocation: response}));
        this.bbProductService.getCount().then(response => this.setState({countBbProduct: response}));
        this.hsmAssetService.getCount().then(response => this.setState({countHsmAsset: response}));
    }

}