import React from 'react';
import {Panel} from 'primereact/panel';
import DataTable from '../../../shared/component/dataTable/DataTable';
import BbProductService from "../../../shared/service/BbProductService";
import BbProductColumn from '../../../shared/component/dataTable/columns/BbProductColumn';
import BbProduct from "./BbProduct";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import Dialog from "../../../shared/component/dialog/Dialog";
import {PAGE_MODE} from "../../../shared/utils/Constants";

export default class BbProductList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            selected: {},
            pageMode: null
        };
        this.service = new BbProductService();
    }

    componentDidMount = () => {
        this.service.checkPage();
        this.init();
    }

    init = () => {
        let parameter = window.location.href.split('?')[1];
        if (parameter) {
            let value = parameter.split('=')[1];
            if (!GeneralUtils.isNullOrEmpty(value)) {
                this.refDataTable.setParameter(value);
            }
        } else {
            this.refDataTable.setParameter('ALL');
        }
    }

    render() {
        return (
            <Panel header="Genişbant Ürün Yönetimi">
                <DataTable ref={(elem) => this.refDataTable = elem} fields={BbProductColumn.fields}
                           dataURL="bbProduct/findAll" objectName={BbProductColumn.name}
                           selectionMode="single" onDataSelect={this.onDataSelect} loading={this.state.loading}
                           export={true} refresh={true} autoLoad={false}/>
                <Dialog visible={this.state.displayDialog}
                        header={this.state.pageMode === PAGE_MODE.UPDATE ? 'Genişbant Ürün Güncelleme' : 'Genişbant Ürün Görüntüleme'}
                        onHide={() => this.setState({displayDialog: false})} style={{width: '70vw'}}>
                    <BbProduct save={this.refresh} ref={(elem) => this.refBbProduct = elem}
                               closeDisplayDialog={this.closeDisplayDialog}/>
                </Dialog>
            </Panel>
        );
    }

    refresh = () => {
        this.init();
    }

    onDataSelect = (data) => {
        let pageMode = GeneralUtils.getPageModeFromPrivilege(BbProductColumn.name);
        this.service.findById(data.id).then(response => {
            this.setState({displayDialog: true, pageMode: pageMode});
            this.refBbProduct.setData(Object.assign({}, response), pageMode);

        });
    }

    closeDisplayDialog = () => {
        this.setState({displayDialog: false});
    }
}
