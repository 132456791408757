import React from 'react';
import {Panel} from 'primereact/panel';
import HsmJobService from "../../../shared/service/HsmJobService";
import HsmJobColumn from '../../../shared/component/dataTable/columns/HsmJobColumn';
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import {Button, Row} from 'reactstrap';
import {TabPanel, TabView} from "primereact/tabview";
import {PAGE_MODE} from "../../../shared/utils/Constants";
import {AvForm} from 'availity-reactstrap-validation';
import Dialog from "../../../shared/component/dialog/Dialog";
import DataTable from "../../../shared/component/dataTable/DataTable";
import LogJob from "../job/LogJob";
import HsmJobSave from "./HsmJobSave";
import {toast} from "react-toastify";
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import HsmRoleColumn from "../../../shared/component/dataTable/columns/HsmRoleColumn";
import Translate from "../../../shared/language/Translate";


export default class HsmJob extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageMode: null
        };
        this.service = new HsmJobService();
    }

    componentDidMount = () => {
        this.service.checkPage();
    }

    render() {
        return (
            <Row className="justify-content-center">
                <Panel header="Job Yönetimi" style={{width: '100%'}}>
                    <DataTable ref={(elem) => this.refDataTable = elem} fields={HsmJobColumn.fields} dataURL="hsmJob/findAll" objectName={HsmJobColumn.name}
                               selectionMode="single" onDataSelect={this.onDataSelect}
                               loading={this.state.loading}/>

                    <Dialog visible={this.state.displayDialog} header='Job Bilgileri'
                            onHide={() => this.setState({displayDialog: false})} style={{width: '50vw'}}>
                        <AvForm onValidSubmit={this.save} beforeSubmitValidation={() => this.setState({activeIndex: 0})}>
                            <Toolbar
                                display={GeneralUtils.getDialogToolbarDisplay(HsmJobColumn.name, this.state.pageMode)}>
                                <div className="p-toolbar-group-right">
                                    <Button color="primary" className="float-right"
                                            onClick={this.run}>
                                        <Translate contentKey="entity.action.save">Çalıştır</Translate>
                                    </Button>
                                    <Button color="primary" type="submit" className="float-right">
                                        <Translate contentKey="entity.action.save">Kaydet</Translate>
                                    </Button>
                                    <Button color="primary" className="float-right"
                                            onClick={() => this.setState({displayDialog: false})}>
                                        <Translate contentKey="entity.action.save">Vazgeç</Translate>
                                    </Button></div>

                            </Toolbar>
                            <TabView activeIndex={this.state.activeIndex}
                                     onTabChange={(e) => this.setState({activeIndex: e.index})}
                                     renderActiveOnly={false}>
                                <TabPanel header="Job Bilgileri" modal={true}>
                                    <HsmJobSave ref={elem => this.refHsmJobSave = elem}
                                                     closeDialog={() => this.setState({displayDialog: false})}
                                                     refresh={this.refresh}/>

                                </TabPanel>

                                <TabPanel header="Tarihçe" rightIcon="pi pi-user"
                                          disabled={this.state.pageMode === PAGE_MODE.CREATE}
                                          headerClassName={this.state.pageMode === PAGE_MODE.CREATE && 'displayNone'}>
                                    <LogJob ref={elem => this.refLogJob = elem} objectName={HsmJobColumn.name}/>
                                </TabPanel>
                            </TabView>
                        </AvForm>
                    </Dialog>
                </Panel>
            </Row>
        );
    }

    refresh = () => {
        this.refDataTable.refresh();
        this.setState({displayDialog: false});
    }


    onDataSelect = (data) => {
        let pageMode = GeneralUtils.getPageModeFromPrivilege(HsmJobColumn.name);
        this.setState({displayDialog: true, pageMode: pageMode, activeIndex: 0});
        this.refHsmJobSave.clearData();
        this.refHsmJobSave.setData(data);
        this.refLogJob.setDataById(data.id)
    }

    save = () => {
        let hsmJob = this.refHsmJobSave.getData();
        if (!GeneralUtils.isNullOrEmpty(hsmJob.id)) {
            this.service.update(hsmJob).then((response) => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    this.setState({displayDialog: false});
                    this.refresh();
                }
            });
        }
    }

    run = () =>{
        let hsmJob = this.refHsmJobSave.getData();
        this.service.run(hsmJob.name).then((response) => {
           if(response && hsmJob.status == "A"){
                toast.success("Uygulama başarıyla çalıştırıldı.")
           }else if (hsmJob.status == 'P'){
                toast.error("Uygulama pasif olduğu için çalıştırılamadı.")
           }else{
               toast.error("Uygulama çalıştırılamadı.")

           }
        } );
        this.setState({displayDialog: false});

    }
}
