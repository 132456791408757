import React from "react";
import PropTypes from 'prop-types';
import SelectDataTable from '../form/SelectDataTable';
import SltColumn from "../../component/dataTable/columns/SltColumn.json";
import Service from "../../../shared/service/Service";

export default class SltGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value,
            validate: this.props.validate,
            dataTable: this.props.dataTable,
            label: this.props.label,
            disabled: this.props.disabled
        };
        this.service = new Service('slt');
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({
            validate: nextProps.validate,
            value: nextProps.value,
            dataTable: nextProps.dataTable,
            label: nextProps.label,
            disabled: nextProps.disabled
        });
    }

    render() {
        let validate = this.state.validate;
        if (!validate) {
            validate = {
                required: {
                    value: true,
                    errorMessage: "Bu alanın doldurulması zorunludur."
                }
            };
        }

        let dataTable = this.state.dataTable;
        if (!dataTable) {
            dataTable = {
                dataURL : "slt/findAll",
                fields: SltColumn.fields
            };
        }

        let label = this.state.label;
        if (!label) {
            label = "SLT"
        }
        return (
            <SelectDataTable
                value={this.state.value}
                validate={validate}
                dataTable={dataTable}
                disabled={this.state.disabled}
                name="requestedBy"
                translateKey="defaultHsmUserGroup"
                label={label}
                dialogHeader="SLT Listesi"
                onDataSelect={this.props.onDataSelect}
                autoRefrestData={this.props.autoRefrestData ? this.props.autoRefrestData : false}
            />
        );
    }
}
SltGroup.propTypes = {
    value: PropTypes.string,
    validate: PropTypes.object,
    dataTable: PropTypes.object
}