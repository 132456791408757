import React from 'react';
import {Panel} from 'primereact/panel';
import DataTable from "../../../shared/component/dataTable/DataTable";
import {PAGE_MODE} from "../../../shared/utils/Constants";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import LocationDetailReportColumn from "../../../shared/component/dataTable/columns/LocationDetailReportColumn";
import LocationDetailReportService from "../../../shared/service/LocationDetailReportService";

export default class LocationDetailReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            selected: {},
            pageMode: null
        };
        this.service = new LocationDetailReportService();
    }

    componentDidMount = () => {
        this.service.checkPage();
        let parameter = window.location.href.split('?')[1];
        if (parameter !== null) {
            let value = parameter.split('=')[1];
            if (!GeneralUtils.isNullOrEmpty(value)) {
                this.refDataTable.setParameter(value);
                // this.service.findLocationsByType(value).then(response => {
                //     this.setState({data: response});
                // })
            }
        }
    }

    render() {
        return (
            <Panel header="Lokasyon Detay Raporu">
                <DataTable ref={(elem) => this.refDataTable = elem} fields={LocationDetailReportColumn.fields} dataURL="locationDetailReport/findLocationsByType"
                           objectName={LocationDetailReportColumn.name} export={true} autoLoad={false}/>
            </Panel>
        );
    }

    onDataSelect = (data) => {
        this.setState({displayDialog: true, pageMode: PAGE_MODE.UPDATE, activeIndex: 0});
        this.refBbProductSave.setData(Object.assign({}, data), PAGE_MODE.UPDATE);
    }

    closeDisplayDialog = () => {
        this.setState({displayDialog: false});
    }
}
