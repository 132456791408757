import React from 'react';
import {Panel} from 'primereact/panel';
import {Button, Col, Container, Row} from 'reactstrap';
import Doughnut from "../../../shared/component/chart/Doughnut";
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import ServiceRequestReportService from "../../../shared/service/ServiceRequestReportService";

export default class CancelChartReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                labels: ['AÇIK', 'ONAY BEKLİYOR', 'KAPALI', 'ASKIDA'],
                datasets: [{
                    data: [],
                    backgroundColor: ['#42A5F5', '#9CCC65', '#F52A2D', '#24F52A'],
                    hoverBackgroundColor: ['#42A5F5', '#9CCC65', '#F52A2D', '#24F52A']
                }]
            }
        };
        this.service = new ServiceRequestReportService();
    }

    componentDidMount = () => {
        this.refresh();
    }

    render() {
        return (
            <Panel header="Hizmet Talebi / TTVPN İptal">
                <Toolbar display={true}>
                    <div className="p-toolbar-group-right">
                        <Button color="primary" onClick={this.refresh}>
                            Yenile
                        </Button>
                    </div>
                </Toolbar>
                <Container>
                    <Row>
                        <Col>
                            {this.state.item}
                        </Col>
                    </Row>
                </Container>
            </Panel>
        );
    }

    getDoughnut = () => {
        return <Doughnut
            data={this.state.data}
            // onElementsClick={this.redirectPage}
            options={
                {legend: {display: true}, responsive: true, cutoutPercentage: 0}
            }/>;
    }

    // redirectPage = (e) => {
    //     if (e.length !== 0) {
    //         if (e[0]._model.label === 'KURULU OLAN LOKASYON') {
    //             window.location = '#/model';
    //         } else {
    //             window.location = '#/location';
    //         }
    //     }
    // }

    refresh = () => {
        let open = 0;
        let awaitingApproval = 0;
        let closed = 0;
        let pending = 0;

        let data = this.state.data;
        this.setState({item: null});
        this.service.getCancelServiceRequestCount().then((response) => {
            open = response.open;
            awaitingApproval = response.awaiting_approval;
            closed = response.closed;
            pending = response.pending;

            data.datasets[0].data = [];
            data.datasets[0].data.push(open, awaitingApproval, closed, pending);
            this.setState({data: data});
            this.setState({item: this.getDoughnut()});
        });
    }
}
