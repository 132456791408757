import React, {Component} from 'react';
import {Bar} from "react-chartjs-2";
import {Panel} from "primereact/panel";
import {Button, Col, Container, Row} from 'reactstrap';
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import TtvpnReportService from "../../../shared/service/TtvpnReportService";

export class TtvpnBarChartReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data : {
                labels: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
                datasets: [
                    {
                        label: 'Toplam Hat Ücreti (TL): ',
                        backgroundColor: '#42A5F5',
                        data: []
                    },
                    {
                        label: 'Toplam Ceza (TL): ',
                        backgroundColor: '#9CCC65',
                        data: []
                    }
                ]
            }
        }

        this.service = new TtvpnReportService();
    }

    componentDidMount = () => {
        this.service.checkPage();
        this.refresh();
    }

    render() {
        return (
            <Panel header="Aylık TTVPN Ücret ve Ceza Bilgileri Raporu">
                <Toolbar display={true}>
                    <div className="p-toolbar-group-right">
                        <Button color="primary" onClick={this.refresh}>
                            Yenile
                        </Button>
                    </div>
                </Toolbar>
                <Container>
                    <Row>
                        <Col>
                            {this.state.item}
                        </Col>
                    </Row>
                </Container>
            </Panel>
        )
    }

    getBar = () => {
        return <Bar data={this.state.data} onElementsClick={this.redirectPage}/>;
    }

    redirectPage = (e) => {
        if (e.length !== 0) {
            if (e[0]._model.label.includes( 'Ocak')) {
                window.location = '#/ttvpnDetailReport?month=Ocak';
            } else if(e[0]._model.label .includes( 'Şubat')){
                window.location = '#/ttvpnDetailReport?month=Şubat';
            } else if(e[0]._model.label .includes( 'Mart')){
                window.location = '#/ttvpnDetailReport?month=Mart';
            } else if(e[0]._model.label .includes( 'Nisan')){
                window.location = '#/ttvpnDetailReport?month=Nisan';
            } else if(e[0]._model.label .includes( 'Mayıs')){
                window.location = '#/ttvpnDetailReport?month=Mayıs';
            } else if(e[0]._model.label .includes( 'Haziran')){
                window.location = '#/ttvpnDetailReport?month=Haziran';
            } else if(e[0]._model.label .includes( 'Temmuz')){
                window.location = '#/ttvpnDetailReport?month=Temmuz';
            } else if(e[0]._model.label .includes( 'Ağustos')){
                window.location = '#/ttvpnDetailReport?month=Ağustos';
            } else if(e[0]._model.label .includes( 'Eylül')){
                window.location = '#/ttvpnDetailReport?month=Eylül';
            } else if(e[0]._model.label .includes( 'Ekim')){
                window.location = '#/ttvpnDetailReport?month=Ekim';
            } else if(e[0]._model.label .includes( 'Kasım')){
                window.location = '#/ttvpnDetailReport?month=Kasım';
            } else if(e[0]._model.label .includes( 'Aralık')){
                window.location = '#/ttvpnDetailReport?month=Aralık';
            }
        }
    }

    refresh = () => {
        let data = this.state.data;
        this.setState({item: null});
        data.datasets[0].data = [];
        data.datasets[1].data = [];

        this.service.getMonthlyAmounts().then((response) => {
            data.datasets[0].data.push(response.januaryAmount, response.februaryAmount, response.marchAmount, response.aprilAmount, response.mayAmount, response.juneAmount, response.julyAmount, response.augustAmount, response.septemberAmount, response.octoberAmount, response.novemberAmount, response.decemberAmount);
            data.datasets[1].data.push(response.januaryPenalty, response.februaryPenalty, response.marchPenalty, response.aprilPenalty, response.mayPenalty, response.junePenalty, response.julyPenalty, response.augustPenalty, response.septemberPenalty, response.octoberPenalty, response.novemberPenalty, response.decemberPenalty);
            this.setState({data: data});
            this.setState({item: this.getBar()});

        });



    }
}

export default TtvpnBarChartReport;
