import React from "react";
import PropTypes from 'prop-types';
import SelectDataTable from '../form/SelectDataTable';
import ContactColumn from '../../../shared/component/dataTable/columns/ContactColumn';
import ContactService from "../../../shared/service/ContactService";
import GeneralUtils from "../../utils/GeneralUtils";
import {PAGE_MODE} from "../../utils/Constants";
import Dialog from "../dialog/Dialog";
import ContactView from "../../../module/account/contact/ContactView";

export default class ContactGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            value: this.props.value,
            validate: this.props.validate,
            dataTable: this.props.dataTable,
            disabled: this.props.disabled,
            label: this.props.label
        };
        this.service = new ContactService();
    }

    componentDidMount = () => {
        if (this.state.data && GeneralUtils.checkPrivilege(ContactColumn.name, 'View')) {
            this.refContactView.setPageMode(PAGE_MODE.VIEW);
        }
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({
            data: nextProps.data,
            validate: nextProps.validate,
            value: nextProps.value,
            dataTable: nextProps.dataTable,
            label: nextProps.label,
            disabled: nextProps.disabled,
        });
    }

    render() {
        let validate = this.state.validate;
        if (!validate) {
            validate = {
                required: {
                    value: true,
                    errorMessage: "Bu alanın doldurulması zorunludur."
                }
            };
        }

        let label = this.state.label;
        if (!label) {
            label = "Kontak"
        }


        let dataTable = this.state.dataTable;
        if (!dataTable) {
            dataTable = {
                dataURL : "contact/findAll",
                fields: ContactColumn.fields
            };
        }
        let openDetailDialog = (this.state.data && GeneralUtils.checkPrivilege(ContactColumn.name, 'View')) ? this.openDetailDialog : false;
        return (
            <div>
                <SelectDataTable
                    value={this.state.value}
                    validate={validate}
                    dataTable={dataTable}
                    disabled={this.state.disabled}
                    name="contact"
                    translateKey="contactManagement.contact"
                    label={label}
                    dialogHeader="Kontak Listesi"
                    onDataSelect={this.props.onDataSelect}
                    autoRefrestData={this.props.autoRefrestData ? this.props.autoRefrestData : false}
                    openDetailDialog={openDetailDialog}
                />
                {(this.state.data && GeneralUtils.checkPrivilege(ContactColumn.name, 'View')) && this.getDetailDialog()}
            </div>
        );
    }

    openDetailDialog = () => {
        this.refContactView.setData(Object.assign({}, this.state.data), PAGE_MODE.VIEW);
        this.setState({displayDialog: true});
    }

    getDetailDialog = () => {
        let result = (
            <Dialog style={{width: '60%'}}
                    visible={this.state.displayDialog}
                    header="Kontak Bilgileri"
                    onHide={() => this.setState({displayDialog: false})}>
                <ContactView ref={(elem) => this.refContactView = elem}
                             closeDisplayDialog={()=> this.setState({displayDialog: true})}/>
            </Dialog>
        );
        return result;
    }
}
ContactGroup.propTypes = {
    data: PropTypes.object,
    value: PropTypes.string,
    validate: PropTypes.object,
    dataTable: PropTypes.object
}