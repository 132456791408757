import React from 'react';
import {Button, Label} from 'reactstrap';
import {AvFeedback, AvGroup} from 'availity-reactstrap-validation';
import AvField from '../../../shared/component/form/AvField';
import PropTypes from 'prop-types';
import Translate from "../../../shared/language/Translate";
import GeneralUtils from '../../../shared/utils/GeneralUtils';
import BillingAccountService from "../../../shared/service/BillingAccountService";
import DistrictService from "../../../shared/service/DistrictService";
import CityService from "../../../shared/service/CityService";
import {BILL_PREFERENCE, PAGE_MODE, STATUS} from '../../../shared/utils/Constants';
import {Fieldset} from "primereact/fieldset";
import BillingAccountColumn from '../../../shared/component/dataTable/columns/BillingAccountColumn';
import HsmOrganizationColumnForDialog from "../../../shared/component/dataTable/columns/HsmOrganizationColumnForDialog";
import DataTable from "../../../shared/component/dataTable/DataTable";
import Dialog from "../../../shared/component/dialog/Dialog";
import HsmOrganizationService from "../../../shared/service/HsmOrganizationService";
import BbProductService from "../../../shared/service/BbProductService";

const defaultBillingAccount = {
    id: '',
    name: '',
    status: 'A',
    code: '',
    description: '',
    email: '',
    billPreference: '',
    statusIsDisabled: false,
    addressLine: '',
    cityId: 1,
    districtId: '',
    buildingName: '',
    buildingNumber: '',
    doorNumber: '',
    levelNumber: '',
    postalCode: '',
    organizationId: ''
};

export default class BillingAccountSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            billingAccount: defaultBillingAccount,
            cityList: [{id: 0, name: 'Adana'}],
            districtList: [],
            pageMode: null,
            organization: null,
            displayContent: true
        }
        this.service = new BillingAccountService();
        this.cityService = new CityService();
        this.districtService = new DistrictService();
        this.hsmOrganizationService = new HsmOrganizationService();
        this.bbProductservice = new BbProductService();
    }

    componentDidMount = () => {
        this.cityService.findAll().then(response => this.setState({cityList: response}));
        this.districtService.findByCityId(this.state.billingAccount.cityId).then(response => {
            let billingAccount = this.state.billingAccount;
            billingAccount.districtId = response[0].id;
            this.setState({districtList: response, billingAccount: billingAccount});
        });
    }

    render() {
        return (
            <div>
                {(GeneralUtils.getDialogToolbarDisplay(BillingAccountColumn.name, this.state.pageMode) || GeneralUtils.checkPrivilege(BillingAccountColumn.name, 'View')) &&
                <div>
                    {this.state.displayContent ?
                        <div className="container">
                            <div className="row">
                                <div className="col">

                                    <Fieldset legend="Organizasyon Bilgileri">
                                        <AvGroup>
                                            <Label for="organizationId">
                                                <Translate contentKey="billing-account.organizationId">Organizasyon
                                                    Adı</Translate>
                                                <span style={{color: 'red'}}> (*)</span>
                                            </Label>
                                            <div style={{position: "relative"}}>
                                                <AvField type="text" className="form-control" name="organization" disabled
                                                         validate={this.state.pageMode === PAGE_MODE.VIEW ? {} : {
                                                             required: {
                                                                 value: true,
                                                                 errorMessage: "Bu alanın doldurulması zorunludur."
                                                             }
                                                         }}
                                                         value={this.state.organization}/>{' '}
                                                {this.state.pageMode !== PAGE_MODE.VIEW &&
                                                <Button color="primary" onClick={this.findAllOrganization}
                                                        disabled={this.state.pageMode === PAGE_MODE.UPDATE}
                                                        className="pi pi-search selectTableButton" size="sm"/>
                                                }
                                            </div>
                                        </AvGroup>
                                    </Fieldset>

                                    {/*<Fieldset legend="Organizasyon Bilgileri">*/}
                                    {/*    <OrganizationGroup*/}
                                    {/*        value={this.state.organization}*/}
                                    {/*        onDataSelect={this.onOrganizationSelect}*/}
                                    {/*        disabled={this.state.pageMode === PAGE_MODE.VIEW || this.state.pageMode === PAGE_MODE.UPDATE}*/}
                                    {/*        validate={this.state.pageMode === PAGE_MODE.VIEW ? {} : {*/}
                                    {/*            required: {*/}
                                    {/*                value: true,*/}
                                    {/*                errorMessage: "Bu alanın doldurulması zorunludur."*/}
                                    {/*            }*/}
                                    {/*        }}*/}
                                    {/*    />*/}
                                    {/*</Fieldset>*/}

                                    <AvGroup>
                                        <Label for="codeBA">
                                            <Translate contentKey="billing-account.code">Fatura Hesabı Kodu</Translate>
                                            <span style={{color: 'red'}}> (*)</span>
                                        </Label>
                                        <AvField
                                            type="text"
                                            className="form-control"
                                            name="codeBA"
                                            validate={this.state.pageMode === PAGE_MODE.VIEW ? {} : {
                                                maxLength: {
                                                    value: 50,
                                                    errorMessage: "Bu alana en fazla 20 karakter girebilirsiniz."
                                                },
                                                required: {
                                                    value: "true",
                                                    errorMessage: "Bu alanın doldurulması zorunludur."
                                                }
                                            }}
                                            value={this.state.billingAccount.code}
                                            onChange={this.__handleChange}
                                            disabled={this.state.pageMode === PAGE_MODE.VIEW}
                                        />
                                        <AvFeedback>This field cannot be longer than 20 characters.</AvFeedback>
                                    </AvGroup>
                                    <AvGroup>
                                        <Label for="nameBA">
                                            <Translate contentKey="billing-account.name">Fatura Hesabı Adı</Translate>
                                            <span style={{color: 'red'}}> (*)</span>
                                        </Label>
                                        <AvField
                                            type="text"
                                            className="form-control"
                                            name="nameBA"
                                            validate={this.state.pageMode === PAGE_MODE.VIEW ? {} : {
                                                maxLength: {
                                                    value: 50,
                                                    errorMessage: "Bu alana en fazla 50 karakter girebilirsiniz."
                                                },
                                                required: {
                                                    value: "true",
                                                    errorMessage: "Bu alanın doldurulması zorunludur."
                                                }
                                            }}
                                            value={this.state.billingAccount.name} onChange={this.__handleChange}
                                            disabled={this.state.pageMode === PAGE_MODE.VIEW}/>
                                        <AvFeedback>Bu alana en fazla 50 karakter girebilirsiniz.</AvFeedback>
                                    </AvGroup>
                                    <AvGroup>
                                        <Label for="billPreferenceBA">
                                            <Translate contentKey="billing-account.billPreference">Gönderim
                                                Tercihi</Translate>
                                            <span style={{color: 'red'}}> (*)</span>
                                        </Label>
                                        <AvField type="select" className="form-control" name="billPreferenceBA"
                                                 value={this.state.billingAccount.billPreference}
                                                 onChange={this.__handleChange}
                                                 validate={this.state.pageMode === PAGE_MODE.VIEW ? {} : {
                                                     required: {
                                                         value: "true",
                                                         errorMessage: "Bu alanın doldurulması zorunludur."
                                                     }
                                                 }}
                                                 disabled={this.state.pageMode === PAGE_MODE.VIEW}>
                                            <option value='' key=''/>
                                            {BILL_PREFERENCE.map(element => (
                                                <option value={element.value} key={element.value}>
                                                    {element.name}
                                                </option>
                                            ))}
                                        </AvField>
                                    </AvGroup>
                                    <AvGroup>
                                        <Label for="statusBA">
                                            <Translate contentKey="billing-account.status">Durum</Translate>
                                            <span style={{color: 'red'}}> (*)</span>
                                        </Label>
                                        <AvField type="select" className="form-control" name="statusBA"
                                                 disabled={this.state.pageMode === PAGE_MODE.VIEW || this.state.statusIsDisabled}
                                                 value={this.state.billingAccount.status}
                                                 onChange={this.__handleChange}>
                                            {STATUS.map(element => (
                                                <option value={element.value} key={element.value}>
                                                    {element.name}
                                                </option>
                                            ))}
                                        </AvField>
                                    </AvGroup>
                                    <AvGroup>
                                        <Label for="descriptionBA">
                                            <Translate contentKey="billing-account.description">Açıklama</Translate>
                                        </Label>
                                        <AvField
                                            type="textarea"
                                            className="form-control textAreaHermes"
                                            name="descriptionBA"
                                            validate={this.state.pageMode === PAGE_MODE.VIEW ? {} : {
                                                maxLength: {
                                                    value: 200,
                                                    errorMessage: "Bu alana en fazla 4000 karakter girebilirsiniz."
                                                }
                                            }}
                                            value={this.state.billingAccount.description}
                                            onChange={this.__handleChange}
                                            disabled={this.state.pageMode === PAGE_MODE.VIEW}
                                        />
                                        <AvFeedback>This field cannot be longer than 4000 characters.</AvFeedback>
                                    </AvGroup>
                                    <AvGroup>
                                        <Label for="emailBA">
                                            <Translate contentKey="billing-account.email">E-Posta</Translate>
                                            <span style={{color: 'red'}}> (*)</span>
                                        </Label>
                                        <AvField
                                            name="emailBA"
                                            type="email"
                                            validate={this.state.pageMode === PAGE_MODE.VIEW ? {} : {
                                                minLength: {
                                                    "value": 5,
                                                    "errorMessage": "Geçersiz E-Posta."
                                                },
                                                maxLength: {
                                                    value: 50,
                                                    errorMessage: "Bu alana en fazla 50 karakter girebilirsiniz."
                                                },
                                                required: {
                                                    value: "true",
                                                    errorMessage: "Bu alanın doldurulması zorunludur."
                                                }
                                            }}
                                            value={this.state.billingAccount.email}
                                            onChange={this.__handleChange}
                                            disabled={this.state.pageMode === PAGE_MODE.VIEW}
                                        />
                                    </AvGroup>
                                </div>
                                <div className="col">
                                    <AvGroup>
                                        <Label for="cityIdBA">
                                            <Translate contentKey="billing-account.cityId">İl Adı</Translate>
                                            <span style={{color: 'red'}}> (*)</span>
                                        </Label>
                                        <AvField type="select" className="form-control" name="cityIdBA"
                                                 value={this.state.billingAccount.cityId}
                                                 onChange={this.__handleChangeCity}
                                                 disabled={this.state.pageMode === PAGE_MODE.VIEW}>
                                            {this.state.cityList.map(element => (
                                                <option value={element.id} key={element.id}>
                                                    {element.name}
                                                </option>
                                            ))}
                                        </AvField>
                                    </AvGroup>
                                    <AvGroup>
                                        <Label for="districtIdBA">
                                            <Translate contentKey="billing-account.districtId">İlçe Adı</Translate>
                                            <span style={{color: 'red'}}> (*)</span>
                                        </Label>
                                        <AvField type="select" className="form-control" name="districtIdBA"
                                                 value={this.state.billingAccount.districtId}
                                                 onChange={this.__handleChange}
                                                 disabled={this.state.pageMode === PAGE_MODE.VIEW}>
                                            {this.state.districtList.map(element => (
                                                <option value={element.id} key={element.id}>
                                                    {element.name}
                                                </option>
                                            ))}
                                        </AvField>
                                    </AvGroup>
                                    <AvGroup>
                                        <Label for="addressLineBA">
                                            <Translate
                                                contentKey="billing-account.addressLine">Cadde/Sokak/Bulvar/Mahalle</Translate>
                                            <span style={{color: 'red'}}> (*)</span>
                                        </Label>
                                        <AvField
                                            type="textarea"
                                            className="form-control textAreaHermes"
                                            name="addressLineBA"
                                            validate={this.state.pageMode === PAGE_MODE.VIEW ? {} : {
                                                maxLength: {
                                                    value: 200,
                                                    errorMessage: "Bu alana en fazla 200 karakter girebilirsiniz."
                                                },
                                                required: {
                                                    value: "true",
                                                    errorMessage: "Bu alanın doldurulması zorunludur."
                                                }
                                            }}
                                            value={this.state.billingAccount.addressLine}
                                            onChange={this.__handleChange}
                                            disabled={this.state.pageMode === PAGE_MODE.VIEW}
                                        />
                                        <AvFeedback>This field cannot be longer than 200 characters.</AvFeedback>
                                    </AvGroup>
                                    <AvGroup>
                                        <Label for="buildingNameBA">
                                            <Translate contentKey="billing-account.buildingName">Bina Adı</Translate>
                                            <span style={{color: 'red'}}> (*)</span>
                                        </Label>
                                        <AvField
                                            type="text"
                                            className="form-control"
                                            name="buildingNameBA"
                                            validate={this.state.pageMode === PAGE_MODE.VIEW ? {} : {
                                                maxLength: {
                                                    value: 50,
                                                    errorMessage: "Bu alana en fazla 50 karakter girebilirsiniz."
                                                },
                                                required: {
                                                    value: "true",
                                                    errorMessage: "Bu alanın doldurulması zorunludur."
                                                }
                                            }}
                                            value={this.state.billingAccount.buildingName}
                                            onChange={this.__handleChange}
                                            disabled={this.state.pageMode === PAGE_MODE.VIEW}
                                        />
                                        <AvFeedback>This field cannot be longer than 50 characters.</AvFeedback>
                                    </AvGroup>
                                    <AvGroup>
                                        <Label for="buildingNumberBA">
                                            <Translate contentKey="billing-account.buildingNumber">Bina
                                                Numarası</Translate>
                                        </Label>
                                        <AvField
                                            type="text"
                                            className="form-control"
                                            name="buildingNumberBA"
                                            validate={this.state.pageMode === PAGE_MODE.VIEW ? {} : {
                                                maxLength: {
                                                    value: 10,
                                                    errorMessage: "Bu alana en fazla 10 karakter girebilirsiniz."
                                                }
                                            }}
                                            value={this.state.billingAccount.buildingNumber}
                                            onChange={this.__handleChange}
                                            disabled={this.state.pageMode === PAGE_MODE.VIEW}
                                        />
                                    </AvGroup>
                                    <AvGroup>
                                        <Label for="doorNumberBA">
                                            <Translate contentKey="billing-account.doorNumber">Kapı Numarası</Translate>
                                        </Label>
                                        <AvField
                                            type="number"
                                            className="form-control"
                                            name="doorNumberBA"
                                            max="99999"
                                            value={this.state.billingAccount.doorNumber}
                                            onChange={this.__handleChange}
                                            disabled={this.state.pageMode === PAGE_MODE.VIEW}
                                        />
                                    </AvGroup>
                                    <AvGroup>
                                        <Label for="levelNumberBA">
                                            <Translate contentKey="billing-account.levelNumber">Kat Numarası</Translate>
                                        </Label>
                                        <AvField
                                            type="number"
                                            className="form-control"
                                            name="levelNumberBA"
                                            max="999"
                                            value={this.state.billingAccount.levelNumber}
                                            onChange={this.__handleChange}
                                            disabled={this.state.pageMode === PAGE_MODE.VIEW}
                                        />
                                    </AvGroup>
                                    <AvGroup>
                                        <Label for="postalCodeBA">
                                            <Translate contentKey="billing-account.postalCode">Posta Kodu</Translate>
                                        </Label>
                                        <AvField
                                            type="number"
                                            className="form-control"
                                            name="postalCodeBA"
                                            min="1000"
                                            max="99999"
                                            value={this.state.billingAccount.postalCode}
                                            onChange={this.__handleChange}
                                            disabled={this.state.pageMode === PAGE_MODE.VIEW}
                                        />
                                        <AvFeedback>This field cannot be longer than 5 characters.</AvFeedback>
                                    </AvGroup>
                                </div>
                            </div>
                        </div> : "Fatura Hesabı Yoktur!"
                    }
                </div>}
                <Dialog id="OrganizationDialog" key="OrganizationDialogKey" visible={this.state.displayDialog}
                        header="Organizasyon Listesi" onHide={() => this.setState({displayDialog: false})}
                        style={{width: '90%', height: '80%'}}>
                    <DataTable ref={(elem) => this.refDataTable = elem} fields={HsmOrganizationColumnForDialog.fields} privilege="allow"
                               dataURL="hsmOrganization/findAll"
                               selectionMode="single" onDataSelect={this.onOrganizationSelect}/>
                </Dialog>
            </div>
        );
    }

    findAllOrganization = () => {
        this.refDataTable.refresh();
        this.setState({displayDialog: true});
    }

    save = () => {
        let data = Object.assign({}, this.state.billingAccount);
        data.cityId = {id: this.state.billingAccount.cityId};
        data.districtId = {id: this.state.billingAccount.districtId};
        data.organizationId = {id: this.state.billingAccount.organizationId}
        if (!GeneralUtils.isNullOrEmpty(this.state.billingAccount.id)) {
            this.service.update(data)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        this.props.save();
                    }
                });
        } else {
            this.service.create(data)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        let billingAccount = this.state.billingAccount;
                        billingAccount.id = response.id;
                        this.setState({billingAccount: billingAccount});
                        this.props.save();
                    }
                });
        }
    }


    setData = (data, pageMode) => {
        this.clearData(true);
        let organization = data.organizationId.name;
        data.organizationId = data.organizationId.id;
        this.findDistrictByCityId(data.cityId.id, data.districtId.id);
        this.setState({billingAccount: data, pageMode: pageMode, organization: organization, displayContent: true});
        this.findStatusIsDisable(data);
        // this.refForm.reset();
    }

    setDataByOrganizationId = (organizationId, pageMode) => {
        this.service.findByOrganizationIdFromBbProduct(organizationId).then((data) => {
            if (!GeneralUtils.isNullOrEmpty(data)) {
                this.setData(data, pageMode);
            } else {
                this.setState({pageMode: pageMode, displayContent: false});
            }
        });
    }

    clearData = (isRefresh) => {
        let billingAccount = Object.assign({}, defaultBillingAccount);
        if (!isRefresh) this.findDistrictByCityId(this.state.cityList[0].id);
        this.setState({billingAccount: billingAccount, pageMode: null, organization: null});
        // this.refForm.reset();
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let billingAccount = this.state.billingAccount;
        billingAccount[e.target.name.replace("BA", "")] = value;
        this.setState({billingAccount: billingAccount});
    }

    __handleChangeCity = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let billingAccount = this.state.billingAccount;
        billingAccount[e.target.name.replace("BA", "")] = value;
        this.findDistrictByCityId(value);
    }

    findDistrictByCityId = (cityId, districtId) => {
        this.districtService.findByCityId(cityId).then(response => {
            let billingAccount = this.state.billingAccount;
            billingAccount.cityId = cityId;
            if (districtId) {
                billingAccount.districtId = districtId;
            } else {
                billingAccount.districtId = response[0].id;
            }
            this.setState({districtList: response, billingAccount: billingAccount});
        });
    }

    onOrganizationSelect = (data) => {
        let billingAccount = this.state.billingAccount;
        billingAccount.organizationId = data.id;
        billingAccount.cityId = data.cityId ? data.cityId.id : null;
        this.districtService.findByCityId(billingAccount.cityId).then(response => {
            this.setState({districtList: response});
        });
        billingAccount.districtId = data.districtId ? data.districtId.id : null;
        let organization = data.name;
        this.setState({billingAccount: billingAccount, organization: organization, displayDialog: false});
    }

    setPageMode = (pageMode) => {
        this.setState({pageMode: pageMode});
    }

    findStatusIsDisable = (data) => {
        this.bbProductservice.hasBbProductOnOrganization(data.organizationId).then((response => {
            let statusIsDisabled = false;
            if (response) {
                if (data.status == 'A') {
                    statusIsDisabled = true;
                }
            }
            this.setState({ statusIsDisabled: statusIsDisabled})
        }));
    }
}

BillingAccountSave.propTypes = {
    billingAccount: PropTypes.object
};




