import React from 'react';
import ProductOfferingColumn from '../../../shared/component/dataTable/columns/ProductOfferingColumn';
import DataTable from "../../../shared/component/dataTable/DataTable";
import Service from "../../../shared/service/Service";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import {Panel} from "primereact/panel";
import Dialog from "../../../shared/component/dialog/Dialog";
import {PAGE_MODE} from "../../../shared/utils/Constants";
import ProductOfferingSave from "./ProductOfferingSave";
import {AvForm} from 'availity-reactstrap-validation';
import PriceList from "./PriceList";

export default class ProductOffering extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
        this.service = new Service("productOffering");
    }

    render() {
        return (
            <Panel header="Teklif İzleme">
                <DataTable ref={(elem) => this.refDataTable = elem} fields={ProductOfferingColumn.fields}
                           selectionMode="single" onDataSelect={this.onDataSelect}
                           dataURL="productOffering/findAll" objectName={ProductOfferingColumn.name} privilege="allow"/>

                <Dialog visible={this.state.displayDialog}
                        header={this.state.pageMode === PAGE_MODE.UPDATE ? 'Teklif Güncelleme' : 'Teklif Görüntüleme'}
                        onHide={() => this.setState({displayDialog: false})} style={{width: '70vw'}}>
                    <AvForm>
                        <ProductOfferingSave ref={(elem) => this.refProductOfferingSave = elem}/>
                        <PriceList ref={(elem) => this.refPriceList = elem}/>
                    </AvForm>
                </Dialog>
            </Panel>
        );
    }

    onDataSelect = (data) => {
        let pageMode = GeneralUtils.getPageModeFromPrivilege(ProductOfferingColumn.name);
        this.refProductOfferingSave.setData(Object.assign({}, data), PAGE_MODE.VIEW);
        this.refPriceList.setData(data);
        this.setState({displayDialog: true, pageMode: PAGE_MODE.VIEW});
    }
}
