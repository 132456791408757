import React, {Component} from 'react';
import {Column} from "primereact/column";
import GeneralUtils from "../../utils/GeneralUtils";
import {DataTable as DataTablePR} from "primereact/datatable";
import {APP_DATE_FORMAT, convertDateTimeWithFormat} from "../../utils/Constants";
import {Button} from "reactstrap";
import Translate from "../../language/Translate";
import exportToExcel from "./export/exportToExcel";
import TextFormat from "../textFormat/TextFormat";


export default class DataTableForPivot extends Component  {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: this.props.loading
        };
    }
    componentDidMount=()=> {
        this.getColumns();
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({data: nextProps.data, loading: nextProps.loading});
    }


    render() {
        return (
            <div className="content-section implementation pivot-datatables-class">
                {this.state.data.length > 0 &&
                <DataTablePR {...this.props} value={this.state.data} header={this.getHeader()} responsive={true}
                             paginator={true}
                             rows={5} loading={this.state.loading}
                             ref={(el) => {
                                 this.dt = el;
                             }}
                             emptyMessage="Kayıt Bulunamadı"
                             rowsPerPageOptions={[5, 10, 20, 50, 100]}
                >
                    {this.state.columns}
                </DataTablePR>
                }
            </div>
        );
    }

    getColumns = (data) => {
        let columns = [];
        // columns.push(<Column key='request_id' field='request_id' header='request_id'/>);
        if(!GeneralUtils.isNullOrEmpty(data)) {
            columns = [];
            // const map = new Map((Object.entries(data[0])));
            // let keys = map.keys();
            // this.setState({cols: [...map.keys()]});
            let keys = Object.keys(data[0]);

            this.setState({cols: keys});

            let sortedKeys =[];

            keys.forEach(item => {
                if(item.substring(0,1) == '_'){
                    sortedKeys.push(item);
                }else{
                    sortedKeys.unshift(item)
                }
            });

            keys.forEach((item) => {
                console.log(item);
                if(item.substring(0,1) == '_'){
                    columns.push(<Column key={item} field={item} header={item.replace("_pre_", "").replace(/_/g, " ").toUpperCase()}/>);
                }else{
                    if (item.toUpperCase().includes('DATE')) {
                        columns.push(<Column key={item} field={item} header={!GeneralUtils.isNullOrEmpty(this.props.fieldList) ? this.props.fieldList[item] : item }
                                             body={this.dateCell}
                                             filterMatchMode="contains"
                                             sortable={item}/>)
                    }else{
                        columns.push(<Column key={item} field={item} header={!GeneralUtils.isNullOrEmpty(this.props.fieldList) ? this.props.fieldList[item] : item }
                                             filter={item} filterMatchMode="contains" sortable={item} />);
                    }
                }
            });
        }
        this.setState({columns: columns});
    }

    setData= (data)=>{
        this.setState({data: data});
        this.getColumns(data);
    }

    export = () => {
        this.exportXLSX();
    }

    exportXLSX = ()=>{
        this.setState({loadingGlobal: true});
        let exportFilename = "download" + convertDateTimeWithFormat(Date.now(), 'DD.MM.YYYY_HH.mm');
        let data = this.state.data;
        let columns=this.state.cols;
        console.log("------------"+columns);
        let dataAll = [];
        var len = columns.length;
        data.forEach((record, j) => {
            let dataTemp = {};
            for (var i = 0; i < len; i++) {
                let key= !GeneralUtils.isNullOrEmpty(this.props.fieldList) ? this.props.fieldList[columns[i]] : columns[i];
                if(columns[i].substring(0,1) == '_'){
                    key = columns[i].replace("_pre_", "").replace(/_/g, " ").toUpperCase();
                }
               dataTemp[key] = this.check(this.resolveFieldData(record, columns[i]));
            }
            dataAll.push(dataTemp);
        });
        console.log("dataAll"+  dataAll)
        exportToExcel(dataAll, exportFilename);
        this.setState({loadingGlobal: false});
    }

    resolveFieldData(data, col) {
        if (data && col) {
             if (col.toUpperCase().includes('DATE')) {
                return convertDateTimeWithFormat(data[col], APP_DATE_FORMAT)
            }
             else {
                return data[col];
            }
        } else {
            return null;
        }
    }

    isFunction(obj) {
        return !!(obj && obj.constructor && obj.call && obj.apply);
    }

    check(value) {
        return !GeneralUtils.__isValueEmpty(value) ? value : "";
    }

    getExportButton = () => {
        if (this.props.export) {
            return <Button style={{float: 'left'}} color="primary" onClick={this.export} className="pi pi-download">
                &nbsp;
                <Translate contentKey="entity.action.csv">İndir</Translate>
            </Button>;
        }
    }

    getHeader = () => {
        if (this.props.header || this.props.export) {
            return <div className="p-clearfix"
                        style={{lineHeight: '1.87em'}}>
                {this.state.data.length > 0 && this.getExportButton()}
                {this.props.header}
            </div>;
        }
    }

    dateCell = (rowData, column) => {
        return <TextFormat value={rowData[column.field]} type="date" format={APP_DATE_FORMAT} blankOnInvalid/>;
    }

}
