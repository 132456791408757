import Service from './Service';
import GeneralUtils from "../utils/GeneralUtils";
import {SERVER_URL} from "../utils/Constants";

export default class DashboardService extends Service {
    constructor() {
        super();
        this.baseURL = 'dashboard';
    }

    getCountForDashboard(requestType, name, subcategoryId,status, statusReason ) {
        let pathParam = '';
        if(!GeneralUtils.__isValueEmpty(status)){
            pathParam = "/"+ status;
        }
        if(!GeneralUtils.__isValueEmpty(statusReason)){
            pathParam = pathParam + "/"+ statusReason;
        }

        return fetch(SERVER_URL + this.baseURL + "/getCountForDashboard/" + requestType + "/"+ name + "/"+  GeneralUtils.getContactId()+ "/"+ subcategoryId + pathParam, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }
}