import React from 'react';
import {Panel} from 'primereact/panel';
import DataTable from '../../shared/component/dataTable/DataTable';
import Service from '../../shared/service/Service';
import LogPageColumn from '../../shared/component/dataTable/columns/LogPageColumn';


export default class LogPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
        this.service = new Service('logPage');
    }

    componentDidMount = () => {
        this.service.checkPage();
    }

    render() {
        return (
            <Panel header="Sayfa Erişim Günlüğü">
                <DataTable fields = {LogPageColumn.fields} dataURL="logPage/findAll" objectName={LogPageColumn.name} export={true}/>
            </Panel>
        );
    }


}
