import React from 'react';
import ContactService from '../../../shared/service/ContactService';
import ContactColumn from '../../../shared/component/dataTable/columns/ContactColumn';
import {Button} from 'reactstrap';
import Translate from "../../../shared/language/Translate";
import ContactSave from "./ContactSave";
import {TabPanel, TabView} from "primereact/tabview";
import {PAGE_MODE} from "../../../shared/utils/Constants";
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import {AvForm} from 'availity-reactstrap-validation';
import AuditEvent from "../../administration/AuditEvent";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import BusinessRuleService from "../../../shared/service/BusinessRuleService";


export default class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageMode: null
        };
        this.service = new ContactService();
        this.businessRuleService = new BusinessRuleService();
    }

    render() {
        return (
            <AvForm onValidSubmit={this.saveAll} beforeSubmitValidation={() => this.setState({activeIndex: 0})}>
                <Toolbar
                    display={GeneralUtils.getDialogToolbarDisplay(ContactColumn.name, this.state.pageMode)}>
                    <div className="p-toolbar-group-right">
                        <div>
                            <Button color="primary" type="submit" className="float-right">

                                <Translate contentKey="entity.action.save">Kaydet</Translate>
                            </Button>
                            <Button color="primary" className="float-right"
                                    onClick={() => this.props.closeDisplayDialog()}>
                                &nbsp;
                                <Translate contentKey="entity.action.save">Vazgeç</Translate>
                            </Button>
                        </div>
                    </div>
                </Toolbar>
                <TabView activeIndex={this.state.activeIndex}
                         onTabChange={(e) => this.setState({activeIndex: e.index})} renderActiveOnly={false}>
                    <TabPanel header="Kontak Bilgileri">
                        <ContactSave save={this.findAll} ref={(elem) => this.refContactSave = elem}
                                     closeDisplayDialog={this.props.closeDisplayDialog}/>
                    </TabPanel>

                    <TabPanel header="Tarihçe" rightIcon="pi pi-user"
                              disabled={this.state.pageMode === PAGE_MODE.CREATE}
                              headerClassName={this.state.pageMode === PAGE_MODE.CREATE && 'displayNone'}>
                        <AuditEvent ref={elem => this.refAuditEvent = elem} objectName={ContactColumn.name}/>
                    </TabPanel>
                </TabView>
            </AvForm>
        );
    }

    setData = (data, pageMode, displayButton) => {
        this.setState({pageMode: pageMode, activeIndex: 0, displayButton: displayButton});
        this.refContactSave.setData(Object.assign({}, data), pageMode);
        this.refAuditEvent.setData(ContactColumn.name, data.id);
    }


    addNew = () => {
        this.setState({displayDialog: true, pageMode: PAGE_MODE.CREATE, activeIndex: 0});
        this.refContactSave.clearData();
    }

    setOrgAndLocAndGeneric(organizationId, locationId,pageMode) {
        this.refContactSave.setOrgAndLocAndGeneric(organizationId, locationId,pageMode);
    }

    saveAll = () => {
        let data = this.refContactSave.getData();
        if (this.state.statusTemp === 'A' && this.state.contact.status === 'P') {
            this.businessRuleService.doesHaveActiveHsmUser(this.state.contact.id).then(response => {
                if (response) {
                    this.setState({displayConfirmDialog: true});
                } else {
                    this.finalAdd(data);
                }
            });
        } else {
            this.finalAdd(data);
        }
    }

    finalAdd = (data) => {
        if (!GeneralUtils.isNullOrEmpty(data)) {
            if (!GeneralUtils.isNullOrEmpty(data.id)) {
                data = GeneralUtils.addUpdateInfo(data);
                this.service.update(data).then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response)) {
                        if (this.props.refresh) {
                            this.props.refresh();
                        }
                        if(GeneralUtils.isNullOrEmpty(response.type)){
                            this.props.closeDisplayDialog();
                        }
                    }
                });
            } else {
                data = GeneralUtils.addCreateInfo(data);
                this.service.create(data)
                    .then(response => {
                        if (!GeneralUtils.isNullOrEmpty(response)) {
                            if (this.props.refresh) {
                                this.props.refresh();
                            }
                            if(GeneralUtils.isNullOrEmpty(response.type)){
                                if (this.props.setNewContact) {
                                    this.props.setNewContact(response);
                                }
                                this.props.closeDisplayDialog();
                            }
                        }
                    });
            }
        }
    }

    onDataSelect = (data) => {
        let pageMode = GeneralUtils.getPageModeFromPrivilege(ContactColumn.name);
        this.refContactSave.clearData();
        this.setState({
            displayDialog: true,
            pageMode: pageMode,
            activeIndex: 0
        });
        this.refContactSave.setData(Object.assign({}, data), pageMode);
        this.refAuditEvent.setData(ContactColumn.name, data.id);
    }

}
