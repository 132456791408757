import React from 'react';
import Translate from "../../../shared/language/Translate";
import {AvGroup} from "availity-reactstrap-validation";
import {Label} from "reactstrap";
import {PAGE_MODE, STATUS} from '../../../shared/utils/Constants';
import AvField from "../../../shared/component/form/AvField";
import OrganizationGroup from "../../../shared/component/hsmGroup/OrganizationGroup";

const defaultRole = {
    id: '',
    name: '',
    description: '',
    status: 'A',
    organizationId: null,
    menuList: []
};
export default class HsmRoleSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            role: defaultRole,
            organization: null,
            pageMode: null
        };
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col">

                        <AvGroup>
                            <Label for="name">
                                <Translate contentKey="roleManagement.roleName">Rol Adı</Translate>
                                <span style={{color: 'red'}}> (*)</span>
                            </Label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="name"
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: 'Bu alan zorunlu.'
                                    },
                                    minLength: {
                                        value: 1,
                                        errorMessage: 'Bu alan en az 1 karakterden oluşabilir.'
                                    },
                                    maxLength: {
                                        value: 50,
                                        errorMessage: 'Bu alan en fazla 50 karakterden oluşabilir.'
                                    }
                                }}
                                value={this.state.role.name}
                                onChange={this.__handleChange}
                            />
                        </AvGroup>

                        <AvGroup>
                            <Label for="description">
                                <Translate contentKey="roleManagement.description">Açıklama</Translate>
                            </Label>
                            <AvField
                                type="textarea"
                                className="form-control textAreaHermes"
                                name="description"
                                validate={{
                                    maxLength: {
                                        value: 4000,
                                        errorMessage: 'Bu alan en fazla 4000 karakterden oluşabilir.'
                                    }
                                }}
                                value={this.state.role.description}
                                onChange={this.__handleChange}
                            />
                        </AvGroup>

                        <OrganizationGroup
                            value={this.state.organization}
                            onDataSelect={this.onOrganizationSelect}
                            disabled={this.state.pageMode === PAGE_MODE.VIEW || this.state.pageMode === PAGE_MODE.UPDATE}
                        />

                        <AvGroup>
                            <Label for="status">
                                <Translate contentKey="roleManagement.status">Durum</Translate>
                            </Label>
                            <AvField type="select" className="form-control" name="status"
                                     value={this.state.role.status} onChange={this.__handleChange}>
                                {STATUS.map(element => (
                                    <option value={element.value} key={element.value}>
                                        {element.name}
                                    </option>
                                ))}
                            </AvField>
                        </AvGroup>


                    </div>
                </div>
            </div>
        );
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let role = this.state.role;
        role[e.target.name] = value;
        this.setState({role: role});
    }

    setData = (data, pageMode) => {
        let organization = data.organizationId ? data.organizationId.name : null;
        this.setState({role: data, organization: organization, pageMode: pageMode});
    }

    getData = () => {
        return this.state.role;
    }

    clearData = () => {
        this.setState({
            role: Object.assign({}, defaultRole),
            organization: null,
            pageMode: null
        });
    }

    onOrganizationSelect = (data) => {
        let role = this.state.role;
        role.organizationId = data;
        this.setState({role: role, organization: data.name});
    }

}
