import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class ExternalTicketService extends Service {
    constructor() {
        super();
        this.baseURL = 'externalTicket';
    }

    findNewRecordByRequestTypeAndRequestId(requestType, requestId) {
        return fetch(SERVER_URL + this.baseURL + '/findNewRecordByRequestTypeAndRequestId/' +requestType+ '/'+requestId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        })
            .then(response => response.json())
            .catch(error => {
                GeneralUtils.notification(error);
            });
    }

    findAllByRequestTypeAndRequestId(requestType, requestId) {
        return fetch(SERVER_URL + this.baseURL + '/findAllByRequestTypeAndRequestId/' + requestType + '/' + requestId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        })
            .then(response => response.json())
            .catch(error => {
                GeneralUtils.notification(error);
            });
    }
}