import React from 'react';
import {Panel} from 'primereact/panel';
import DataTable from '../../../shared/component/dataTable/DataTable';
import Service from '../../../shared/service/Service';
import HsmSubnetListColumn from '../../../shared/component/dataTable/columns/HsmSubnetListColumn';


export default class HsmSubnetList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: HsmSubnetListColumn.fields
        };
        this.service = new Service('hsmSubnet');
    }

    componentDidMount = () => {
        this.service.checkPage();
    }

    render() {
        return (
            <Panel header="Subnet Listesi">
                <DataTable fields = {HsmSubnetListColumn.fields} dataURL="hsmSubnet/findAll" objectName={HsmSubnetListColumn.name} export={true} privilege="allow"/>
            </Panel>
        );
    }


}
