import React from 'react';
import {Button, Col, Row} from 'reactstrap';
import {AvForm} from 'availity-reactstrap-validation';
import PropTypes from 'prop-types';
import ContactService from "../../../shared/service/ContactService";
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import Dialog from "../../../shared/component/dialog/Dialog";
import {toast} from "react-toastify";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import ContactGroup from "../../../shared/component/hsmGroup/ContactGroup";
import ContactColumnForDialog from '../../../shared/component/dataTable/columns/ContactColumnForDialog';
import TeamGroup from "../../../shared/component/hsmGroup/TeamGroup";
import {PAGE_MODE} from "../../../shared/utils/Constants";
import TeamColumn from "../../../shared/component/dataTable/columns/TeamColumn";


const data = {
    requestedBy: '',
    description: ''
};

export default class AssignedDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: data,
            displayDialogAssigned: false,
            pageMode: null,
            isRequiredContact: true
        }
        this.contactService = new ContactService();
    }

    render() {
        return (
            <Dialog
                header={'Atama Bilgileri'} modal={true} resizable={true} visible={this.state.displayDialog}
                maximizable={true} responsive={true} onHide={() => {
                this.setState({displayDialog: false})
            }}
                style={{width: '50vw'}}>

                <Row className="justify-content-center">
                    <Col md="8">
                        <AvForm onValidSubmit={this.assigned}>
                            <Toolbar display={true}>
                                <div className="p-toolbar-group-right">
                                    <Button color="primary" className="float-right" type="submit">
                                        Kaydet
                                    </Button>
                                    <Button color="primary" className="float-right"
                                            onClick={() => {
                                                this.setState({displayDialog: false});
                                            }}>
                                        Vazgeç
                                    </Button>
                                </div>
                            </Toolbar>
                            <Row>
                                <Col md={6}>
                                    <TeamGroup
                                        label="Atanan Ekip"
                                        value={this.state.teamName}
                                        onDataSelect={this.onTeamSelect}
                                        dataTable={{
                                            dataURL: "workflowmodel/findTeamListByCategoryAndSubcategoryAndFlowId",
                                            parameter: this.state.categoryId  + '/' + this.state.subcategoryId  + '/' + this.state.flowId,
                                            fields: TeamColumn.fields
                                        }}
                                        autoRefrestData={true}
                                        // disabled={this.state.teamDisabled}
                                    />
                                </Col>
                                <Col md={6}>
                                    <ContactGroup
                                        value={this.state.requestedBy}
                                        onDataSelect={this.onRequestedBySelect}
                                        label="Atanan Kişi"
                                        validate={!this.state.isRequiredContact ? {} : {
                                            required: {
                                                value: true,
                                                errorMessage: "Bu alanın doldurulması zorunludur."
                                            }
                                        }}
                                        dataTable={{
                                            dataURL: "contact/findContactsInSameTeamByTeamId",
                                            parameter: (this.state.teamId ? this.state.teamId.id : 0),
                                            fields: ContactColumnForDialog.fields
                                        }}
                                        autoRefrestData={true}
                                        disabled={GeneralUtils.isNullOrEmpty(this.state.teamId)}
                                    />

                                </Col>
                            </Row>
                        </AvForm>
                    </Col>
                </Row>
            </Dialog>
        );
    }

    onTeamSelect = (data) => {
        this.setState({teamId: data, teamName: data.name, requestedBy: null});
    }

    assigned = () => {
        if ( this.state.isRequiredContact && GeneralUtils.isNullOrEmpty(this.state.requestedBy)) {
            return toast.warn("Zorunlu Alan : Açıklama Giriniz!");
        }
        this.props.assigned();
        this.setState({displayDialog: false});
    }


    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let data = this.state.data;
        data[e.target.name] = value;
        this.setState({data: data});
    }

    onRequestedBySelect = (dataTemp) => {
        let data = this.state.data;
        data.requestedBy = dataTemp.id;
        let requestedBy = dataTemp.givenNames + ' ' + dataTemp.familyName;
        this.setState({
            requestedBy: requestedBy
        });
    }


    setData = (data) => {
        this.setState({assignedIncident: data});
    }

    getData = () => {
        let data = {
            assignedToContactId: this.state.data.requestedBy,
            teamId: this.state.teamId.id
        };
        return data;
    }

    clearData = () => {
        this.setState({data: Object.assign({}, data), requestedBy: null, isRequiredContact: true});
    }

    setDialogAndTeamId = (value, teamId, categoryId, subcategoryId, flowId, isRequiredContact) => {
        this.clearData();
        this.setState({displayDialog: value, teamId:teamId, teamName: teamId ? teamId.name : null,
            teamDisabled : teamId ? true : false, categoryId: categoryId, subcategoryId: subcategoryId, flowId:flowId, isRequiredContact:isRequiredContact});
    }
}

AssignedDialog.propTypes = {
    data: PropTypes.object
};
