import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import {AUTHORIZATION_HEADER, SERVER_URL, TENANT_ID} from '../../shared/utils/Constants';
import SHA256 from "crypto-js/sha256";
import PasswordResetInit from './PasswordResetInit';
import PasswordResetFinish from './PasswordResetFinish';
import GeneralUtils from "../../shared/utils/GeneralUtils";
import {toast} from 'react-toastify';
import GuestIncident from "../test/guestIncident/GuestIncident";
import ReCAPTCHA from 'react-google-recaptcha';
import HsmSystemParameterService from "../../shared/service/HsmSystemParameterService";
import TenantService from "../../shared/service/TenantService";
import Package from "../../../package.json";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import {CONFIG} from "../../shared/utils/Config";

const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    mainGuest: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    button: {
        marginTop: theme.spacing.unit * 3,
        textTransform: 'none'
    }
});


class Login extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            reCaptchaToken: null,
            userName: null,
            password: null,
            panel: 'login',
            loginShow: true,
            logo : CONFIG.LOGO.replace('TENANT_ID', 'DEFAULT'),
            logoOther: CONFIG.LOGO.replace('TENANT_ID', 'DEFAULT'),
            backgroundURL: CONFIG.BACKGROUND.replace('TENANT_ID', 'DEFAULT')
        };
        this.hsmSystemParameterService = new HsmSystemParameterService();
        this.tenantService = new TenantService();
    }

    componentDidMount() {
        if (this.reCAPTCHA) {
            this.reCAPTCHA.reset();
        }
        // document.addEventListener('keydown', this.onKeyDown);

        if (this.getParameterByName('passwordChangeKey') !== null) {
            this.setState({
                panel: 'passwordResetFinish',
                passwordChangeKey: this.getParameterByName('passwordChangeKey')
            });
        }
        if (this.getParameterByName('activationKey') !== null) {
            this.setState({panel: 'passwordResetFinish', activationKey: this.getParameterByName('activationKey')});
        }

        this.hsmSystemParameterService.doesSystemWorkInLiveMode().then(response => {
            if (response) {
                this.setState({doesSystemWorkInLiveMode: response, reCaptchaToken: false})
            } else {
                this.setState({doesSystemWorkInLiveMode: response, reCaptchaToken: true})
            }
        });

        this.tenantService.getTenantWithoutLogin().then(result => {
            if(!GeneralUtils.isNullOrEmpty(result)){
                this.setState({logo : CONFIG.LOGO_HERMES.replace('TENANT_ID', result), logoOther: CONFIG.LOGO.replace('TENANT_ID', result)});
            }
        });
    }

    onChange =(reCaptchaToken)=> {
        this.setState({reCaptchaToken: reCaptchaToken});
    }



    // componentWillUnmount() {
    //     document.removeEventListener('keydown', this.onKeyDown);
    // }

    render() {
        if (this.state.panel === 'login') {
            return this.getLoginPanel();
        }
        // else if (this.state.panel === 'guestIncident') {
        //     return this.guestIncidentPanel();
        // }
        else {
            return this.getPasswordResetPanel();
        }
    }

    getLoginPanel = () => {
        const {classes} = this.props;
        return (
            <div className="login-background"
                 style={{backgroundImage: this.state.backgroundURL}}>
                <main className={classes.main}>
                    <CssBaseline/>
                    <Paper className={classes.paper}>
                        <img src={this.state.logoOther} alt="" width="250" style={{marginTop: '25px'}}/>
                        <img src={this.state.logo} alt="" style={{height: '90px'}}/>
                        <Typography component="h1" variant="subtitle2">
                            {' V '+Package.version+ ' GİRİŞ'}

                        </Typography>
                        <form className={classes.form}>
                            <FormControl margin="normal" required fullWidth variant="outlined">
                                <InputLabel htmlFor="userName">Kullanıcı</InputLabel>
                                <OutlinedInput id="userName" name="userName" autoComplete="userName" value={this.state.userName}
                                       onChange={this.__handleChange} autoFocus/>
                            </FormControl>
                            <FormControl margin="normal" required fullWidth variant="outlined">
                                <InputLabel htmlFor="password">Şifre</InputLabel>
                                <OutlinedInput name="password" type="password" id="password" autoComplete="current-password"
                                       value={this.state.password} onChange={this.__handleChange}/>
                            </FormControl>
                            {/*<Button fullWidth size="small" className={classes.button} disabled={true}*/}
                            {/*        onClick={() => this.setState({panel: 'guestIncident'})}>*/}
                            {/*    MİSAFİR ARIZA KAYDI OLUŞTUR*/}
                            {/*</Button>*/}


                            {this.state.doesSystemWorkInLiveMode &&
                            <span style={{alignItems: 'center'}}>
                                <ReCAPTCHA
                                    ref={(el) => {this.reCAPTCHA = el;}}
                                    hl="tr"
                                    size="normal"
                                    sitekey="6Lep9qIUAAAAAHk6E9GINxi8JKbHK7m9yIQTloQ_"
                                    onChange={this.onChange}/>
                            </span>
                            }

                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={!this.state.reCaptchaToken}
                                className={classes.button}
                                onClick={() => this.login()}>
                                Giriş Yap
                            </Button>

                            <Button fullWidth size="small" className={classes.button}
                                    onClick={() => this.setState({panel: 'passwordResetInit'})}>
                                ŞİFREMİ UNUTTUM
                            </Button>
                        </form>
                            <img src={CONFIG.TT.LOGO} alt="" width="125" style={{marginTop: '25px'}}/>
                    </Paper>
                </main>
            </div>
        );
    }

    getPasswordResetPanel = () => {
        const {classes} = this.props;
        return (
            <div className="login-background"
                 style={{backgroundImage: this.state.backgroundURL}}>
                <main className={classes.main}>
                    <CssBaseline/>
                    <Paper className={classes.paper}>
                        <img src={this.state.logoOther} alt="" width="250" style={{marginTop: '25px'}}/>
                        <img src={this.state.logo} alt="" style={{height: '90px'}}/>
                        <Typography component="h1" variant="h6">
                            {!GeneralUtils.isNullOrEmpty(this.state.activationKey) ? "Kullanıcı Aktivasyon" : "Şifre Sıfırlama"}
                        </Typography>
                        <form className={classes.form}>
                            {this.getPasswordResetContent()}
                        </form>
                        <Button fullWidth size="small" className={classes.button}
                            onClick={() =>{this.setState({panel:'login'});window.location.href="/";}}>
                            GERİ
                        </Button>
                        <img src={CONFIG.TT.LOGO} alt="" width="125" style={{marginTop: '25px'}}/>
                    </Paper>
                </main>
            </div>
        );
    }

    // guestIncidentPanel = () => {
    //     const {classes} = this.props;
    //     return (
    //         <div className="login-background"
    //              style={{backgroundImage: this.state.backgroundURL}}>
    //             <main className={classes.mainGuest}>
    //                 <CssBaseline/>
    //                 <Paper className={classes.paper} style={{maxHeight: 600, overflow: 'auto'}}>
    //                     <img src="assets/layout/images/login.gif" alt="" style={{height: '150px'}}/>
    //                     <img src={this.state.logo} alt="" style={{height: '65px'}}/>
    //                     <Typography component="h1" variant="h5">
    //                         Misafir Arıza Kaydı Oluştur
    //                     </Typography>
    //                     <form className={classes.form}>
    //                         <GuestIncident/>
    //                     </form>
    //                     <Button
    //                         fullWidth
    //                         variant="contained"
    //                         color="primary"
    //                         className={classes.button}
    //                         onClick={() => {this.setState({panel: 'login'}); window.location.href = "/";}}>
    //                     Geri
    //                     </Button>
    //                 </Paper>
    //             </main>
    //         </div>
    //     )
    // }


    getPasswordResetContent = () => {
        if (this.state.panel === 'passwordResetInit') {
            return <PasswordResetInit/>;
        } else if (this.state.panel === 'passwordResetFinish') {
            return <PasswordResetFinish activationKey={this.state.activationKey}
                                        passwordChangeKey={this.state.passwordChangeKey}/>;
        }
    }

    __handleChange = (e) => {
        let state = {};
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        state[e.target.name] = value;
        this.setState(state);
    }

    login = () => {
        if (GeneralUtils.isNullOrEmpty(this.state.userName) || GeneralUtils.isNullOrEmpty(this.state.password)) {
            return toast.warn("Kullanıcı adı veya şifresi boş olamaz!")
        }
        window.location.hash = "/";
        fetch(SERVER_URL + 'login', {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                "userName": this.state.userName,
                "password": SHA256(this.state.password).toString(),
                "reCaptchaToken":  this.state.reCaptchaToken
            })
        })
            .then(response => {
                if (response.ok) {
                    response.json().then((response) => {
                        localStorage.setItem(AUTHORIZATION_HEADER, response.token);
                        localStorage.setItem("hsmUser", JSON.stringify(response.hsmUser));
                        localStorage.setItem("isAuthenticated", true);
                        localStorage.setItem("hsmPrivilegeList", JSON.stringify(GeneralUtils.getPrivilegeListFromRoleList()));
                        this.props.loginSuccess();
                    });
                } else {
                    this.setState({userName: '', password: ''});
                    GeneralUtils.notification(response);

                }
            }).catch(error => {
            GeneralUtils.notification(error);
        });
    }


    getParameterByName = (name, url) => {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    onKeyDown = (e) => {
        switch (e.key) {
            case "Enter":
                if (this.state.reCaptchaToken) {
                    this.login();
                    break;
                }

            default:
                break;
        }
    }
}


Login.propTypes = {
    classes: PropTypes.object.isRequired,
    loginSuccess: PropTypes.func,
};

export default withStyles(styles)(Login);
