import React from 'react';
import {AvGroup} from "availity-reactstrap-validation";
import {Col, Label, Row} from "reactstrap";
import AvField from "../../shared/component/form/AvField";
import {TRUEFALSE} from "../../shared/utils/Constants"

const defaultAssetCategory = {
    id: '',
    name:'',
    physical:true
    
};
export default class AssetCategorySave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            assetCategory: defaultAssetCategory,
            pageMode: null
        };
    }

    render() {
        return (
            <Row className="justify-content-center">
                <Col>
                    <AvGroup>
                        <Label for="name">Varlık Sınıfı Adı<span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="name"
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                },
                                minLength: {
                                    value: 1,
                                    errorMessage: 'Bu alan en az 1 karakterden oluşabilir.'
                                },
                                maxLength: {
                                    value: 50,
                                    errorMessage: 'Bu alan en fazla 50 karakterden oluşabilir.'
                                }
                            }}
                            value={this.state.assetCategory.name}
                            onChange={this.__handleChange}
                        />
                    </AvGroup>
                    <AvGroup>
                        <Label for="physical">Mevcut<span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField type="select" className="form-control" name="physical"
                                 value={this.state.assetCategory.physical}
                                 validate={{
                                    required: {
                                        value:false,
                                        errorMessage: 'Bu alan zorunlu.'
                                    }
                                }}
                                 onChange={this.__handleChange}>
                            {TRUEFALSE.map(element => (
                                <option value={element.value} key={element.value}>
                                    {element.name}
                                </option>
                            ))}
                        </AvField>
                    </AvGroup>
                </Col>
            </Row>
        );
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let assetCategory = this.state.assetCategory;
        assetCategory[e.target.name] = value;
        this.setState({assetCategory: assetCategory});
    }

    setData = (data, pageMode) => {
        this.setState({assetCategory: data, pageMode: pageMode});
    }

    getData = () => {
        return this.state.assetCategory;
    }

    clearData = () => {
        this.setState({assetCategory: Object.assign({}, defaultAssetCategory), pageMode: null});
    }

}
