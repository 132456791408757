import React from 'react';
import {Panel} from 'primereact/panel';
import DataTable from "../../../shared/component/dataTable/DataTable";
import ExternalTicketService from "../../../shared/service/ExternalTicketService";
import ExternalTicketColumn from "../../../shared/component/dataTable/columns/ExternalTicketColumn.json";

export default class ExternalTicketReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            selected: {},
            pageMode: null
        };
        this.service = new ExternalTicketService();
    }

    componentDidMount = () => {
        this.service.checkPage();
    }

    render() {
        return (
            <Panel header="Arıza Talebi ile İlişkili Dış Sistem Arıza Kayıtları">
                <DataTable fields={ExternalTicketColumn.fields} dataURL="externalTicket/findAll"
                           objectName={ExternalTicketColumn.name} loading={this.state.loading} export={true}
                />
            </Panel>
        );
    }

    closeDisplayDialog = () => {
        this.setState({displayDialog: false});
    }
}
