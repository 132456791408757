import React from 'react';
import {
    ASSET_OWNER,
    CANCELLATION_TYPE, COPPER_SPEED_BPS,
    PAGE_MODE,
    PREFERRED_ACCESS_TECH, REGION_TYPE,
    SERVICE_SUBCATEGORY, TENANT_ID
} from "../../shared/utils/Constants";
import {Fieldset} from "primereact/fieldset";
import {AvFeedback, AvGroup} from "availity-reactstrap-validation";
import AvField from "../../shared/component/form/AvField";
import Translate from "../../shared/language/Translate";
import {Col, Label, Row} from "reactstrap";
import OrganizationGroup from "../../shared/component/hsmGroup/OrganizationGroup";
import LocationService from "../../shared/service/LocationService";
import HsmOrganizationService from "../../shared/service/HsmOrganizationService";
import GeneralUtils from "../../shared/utils/GeneralUtils";
import {Checkbox} from 'primereact/checkbox';
import BandwidthService from "../../shared/service/BandwidthService";
import ProductOfferingGroup from "../../shared/component/hsmGroup/ProductOfferingGroup";
import ServiceRequestService from "../../shared/service/ServiceRequestService";
import BusinessRuleService from "../../shared/service/BusinessRuleService";
import BillingAccountGroup from "../../shared/component/hsmGroup/BillingAccountGroup";
import BillingAccountColumn from '../../shared/component/dataTable/columns/BillingAccountColumn';
import BillingAccountService from "../../shared/service/BillingAccountService";
import HermesSweetAlert from "../../shared/component/sweetAlert/HermesSweetAlert";
import LocationGroup from "../../shared/component/hsmGroup/LocationGroup";
import HsmProjectService from "../../shared/service/HsmProjectService";
import ProductOfferingColumn from "../../shared/component/dataTable/columns/ProductOfferingColumn";
import TenantService from "../../shared/service/TenantService";
import {toast} from "react-toastify";
import CityService from "../../shared/service/CityService";
import DistrictService from "../../shared/service/DistrictService";
import TtRegionService from "../../shared/service/TtRegionService";

const defaultServiceRequestExt = {
    cancellationReason: '',
    newServiceName: '',
    organizationNewCode: '',
    organizationNewName: '',
    organizationOldCode: '',
    organizationOldName: '',
    transfereeOrganizationId: null,
    transfereeLocationId: null,
    oldProductOfferingId: null,
    productOfferingId: null,
    serviceNumber: '',
    externalBillingAccountNumber: '',
    billingAccountId: null,
    assetOwner: 'Provider',
    preferredAccessTech: 'Fiber',
    presentServiceNumber: '',
    movedLocationId: null,
    newServiceNumber: '',
    hsmProjectId: null,
    regionType: 'Metropolitan',
    reuseIP: null,

    cityId: null,
    districtId: null,
    ttRegionId: null,
    addressLine: '',
    buildingName: '',
    buildingNumber: '',
    doorNumber: '',
    levelNumber: '',
    postalCode: '',
    latitude: '',
    longitude: '',
}
export default class ServiceRequestNewExt extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            serviceRequestExt: Object.assign({}, defaultServiceRequestExt),
            serviceSubcategoryId: this.props.serviceSubcategoryId,
            pageMode: PAGE_MODE.CREATE,
            locationList: [],
            locationHidden: true,
            movedLocationList: [],
            hsmProjectList: [],
            locationListProps: [],
            cityList: [],
            districtList: []
        };
        this.service = new ServiceRequestService();
        this.hsmOrganizationService = new HsmOrganizationService();
        this.locationService = new LocationService();
        this.bandwidthService = new BandwidthService();
        this.businessRuleService = new BusinessRuleService();
        this.billingAccountService = new BillingAccountService();
        this.hsmProjectService = new HsmProjectService();
        this.tenantService = new TenantService();

        this.cityService = new CityService();
        this.districtService = new DistrictService();
        this.ttRegionService = new TtRegionService();
    }

    componentDidMount=()=> {
        this.hsmProjectService.findAllActive().then(response =>{
            if(!GeneralUtils.isNullOrEmpty(response) && response.length == 1){
                let serviceRequestExt = this.state.serviceRequestExt;
                serviceRequestExt.hsmProjectId = response[0].id;
                this.setState({serviceRequestExt: serviceRequestExt});
            }
            this.setState({hsmProjectList: response});
        });

        this.tenantService.getTenant().then(result => {
            if(!GeneralUtils.isNullOrEmpty(result)){
                this.setState({tenantId: result});
                if (result == TENANT_ID.MEB) {
                    let serviceRequestExt = this.state.serviceRequestExt;
                    serviceRequestExt.reuseIP = false;
                    this.setState({
                        reuseIP: serviceRequestExt.reuseIP,
                        serviceRequestExt: serviceRequestExt
                    });
                }
            }
        });
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.pageMode === PAGE_MODE.VIEW && !GeneralUtils.isNullOrEmpty(nextProps.serviceRequestExt)) {
            let serviceRequestExt = Object.assign({}, nextProps.serviceRequestExt);
            let organizationExt = serviceRequestExt.transfereeOrganizationId ? serviceRequestExt.transfereeOrganizationId.name : null;
            let location = serviceRequestExt.transfereeLocationId ? new Array(serviceRequestExt.transfereeLocationId) : [];

            let oldBandwidth = serviceRequestExt.oldProductOfferingId ? serviceRequestExt.oldProductOfferingId.productSpecId.bandwidthId.accessTechnology
                + ' :' + serviceRequestExt.oldProductOfferingId.productSpecId.bandwidthId.downloadSpeed + '/' + serviceRequestExt.oldProductOfferingId.productSpecId.bandwidthId.uploadSpeed : null;
            let oldDownloadSpeed = serviceRequestExt.oldProductOfferingId ? serviceRequestExt.oldProductOfferingId.productSpecId.bandwidthId.downloadSpeed : null;
            let oldUploadSpeed= serviceRequestExt.oldProductOfferingId ? serviceRequestExt.oldProductOfferingId.productSpecId.bandwidthId.uploadSpeed : null;

            serviceRequestExt.transfereeLocationId = serviceRequestExt.transfereeLocationId ? serviceRequestExt.transfereeLocationId.id : null;

            //for connect
            let tariffName = serviceRequestExt.productOfferingId ? serviceRequestExt.productOfferingId.tariffName : null;
            let packageName = serviceRequestExt.productOfferingId ? serviceRequestExt.productOfferingId.packageName : null;
            let productSpecName = serviceRequestExt.productOfferingId ? serviceRequestExt.productOfferingId.productSpecId.name : null;
            let accessTechnologyPO = serviceRequestExt.productOfferingId ? serviceRequestExt.productOfferingId.productSpecId.bandwidthId.accessTechnology : null;
            let downloadSpeedPO = serviceRequestExt.productOfferingId ? serviceRequestExt.productOfferingId.productSpecId.bandwidthId.downloadSpeed : null;
            let uploadSpeedPO = serviceRequestExt.productOfferingId ? serviceRequestExt.productOfferingId.productSpecId.bandwidthId.uploadSpeed : null;
            let newBandwidthForBW = serviceRequestExt.productOfferingId ?
                (serviceRequestExt.productOfferingId.productSpecId.bandwidthId.accessTechnology + ' :'
                    + serviceRequestExt.productOfferingId.productSpecId.bandwidthId.downloadSpeed
                    + '/' + serviceRequestExt.productOfferingId.productSpecId.bandwidthId.uploadSpeed) : null;
            let billingAccountName = serviceRequestExt.billingAccountId ? serviceRequestExt.billingAccountId.name : null;

            serviceRequestExt.hsmProjectId = serviceRequestExt.hsmProjectId ? serviceRequestExt.hsmProjectId.id : null;

            let movedLocationList = [];
            if (serviceRequestExt.movedLocationId) {
                movedLocationList.push(serviceRequestExt.movedLocationId);
                serviceRequestExt.movedLocationId = serviceRequestExt.movedLocationId.id;
            }

            if (serviceRequestExt.cityId) {
                this.cityService.findAll().then(response => this.setState({cityList: response}));
                this.findDistrictByCityId(serviceRequestExt.cityId.id);
                this.findTtRegionByCityId(serviceRequestExt.cityId.id);
                serviceRequestExt.cityId = serviceRequestExt.cityId.id;
                serviceRequestExt.districtId = serviceRequestExt.districtId.id;
                serviceRequestExt.ttRegionId = serviceRequestExt.ttRegionId.id;
            }

            this.setState({
                serviceRequestExt: serviceRequestExt,
                organizationExt: organizationExt,
                locationList: location,
                oldBandwidth: oldBandwidth,
                oldDownloadSpeed: oldDownloadSpeed,
                oldUploadSpeed: oldUploadSpeed,
                serviceSubcategoryId: nextProps.serviceSubcategoryId,
                pageMode: nextProps.pageMode,
                status: nextProps.status,
                //for connect
                tariffName: tariffName,
                packageName: packageName,
                productSpecName: productSpecName,
                accessTechnologyPO: accessTechnologyPO,
                downloadSpeedPO: downloadSpeedPO,
                uploadSpeedPO: uploadSpeedPO,
                billingAccountName: billingAccountName,
                movedLocationList: movedLocationList,
                newBandwidthForBW : newBandwidthForBW
            });
        } else {
            let serviceRequestExt = this.state.serviceRequestExt;
            if (this.state.locationId !== nextProps.locationId) {
                if (this.props.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_MOVE) {
                    this.findNotInBbProductByOrganizationId(this.props.organizationId, nextProps.locationId);
                }
                if (this.props.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CHANGE_ADDRESS) {
                    this.locationService.findById(nextProps.locationId).then(response => {
                        serviceRequestExt.cityId = response.cityId.id;
                        serviceRequestExt.districtId = response.districtId.id;
                        serviceRequestExt.ttRegionId = response.ttRegionId.id;
                        this.cityService.findAll().then(response => this.setState({cityList: response}));
                        this.findDistrictByCityId(response.cityId.id);
                        this.findTtRegionByCityId(response.cityId.id);
                    });
                }
            }
            serviceRequestExt.organizationOldCode = nextProps.organizationOldCode;
            serviceRequestExt.organizationOldName = nextProps.organizationOldName;
            serviceRequestExt.oldProductOfferingId = nextProps.oldProductOfferingId;

            let oldBandwidth = serviceRequestExt.oldProductOfferingId ? serviceRequestExt.oldProductOfferingId.productSpecId.bandwidthId.accessTechnology
                + ' :' + serviceRequestExt.oldProductOfferingId.productSpecId.bandwidthId.downloadSpeed + '/' + serviceRequestExt.oldProductOfferingId.productSpecId.bandwidthId.uploadSpeed : null;
            let oldDownloadSpeed = serviceRequestExt.oldProductOfferingId ? serviceRequestExt.oldProductOfferingId.productSpecId.bandwidthId.downloadSpeed : null;
            let oldUploadSpeed= serviceRequestExt.oldProductOfferingId ? serviceRequestExt.oldProductOfferingId.productSpecId.bandwidthId.uploadSpeed : null;

            if (this.props.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_TRANSFER) {
                serviceRequestExt.transfereeLocationId = nextProps.locationId;
            }
            this.setState({
                serviceRequestExt: serviceRequestExt,
                serviceSubcategoryId: nextProps.serviceSubcategoryId,
                locationListProps: nextProps.locationListProps,
                locationList: nextProps.locationListProps,
                locationId: nextProps.locationId,
                organizationId: nextProps.organizationId,
                oldBandwidth: oldBandwidth,
                oldDownloadSpeed: oldDownloadSpeed,
                oldUploadSpeed: oldUploadSpeed,
                pageMode: nextProps.pageMode,
                status: nextProps.status
            });
        }
        // if (this.props.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CONNECT) {
        //     this.hsmProjectService.findAllActive().then(response =>{
        //         if(!GeneralUtils.isNullOrEmpty(response) && response.length == 1){
        //             let serviceRequestExt = this.state.serviceRequestExt;
        //             serviceRequestExt.hsmProjectId = response[0].id;
        //             this.setState({serviceRequestExt: serviceRequestExt});
        //         }
        //         this.setState({hsmProjectList: response});
        //     }
        //         );
        // }
    }

    render() {
        return (
            <div>
                {this.otherInfo()}
            </div>
        );
    }

    otherInfo = () => {
        if (this.state.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CONNECT) {
            return this.connectInfo();
        } else if (this.state.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_TRANSFER) {
            return this.transferInfo();
        } else if (this.state.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_MOVE) {
            return this.moveInfo();
        } else if (this.state.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_SUSPEND) {
            return this.suspendInfo();
        } else if (this.state.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CHANGE_TITLE) {
            return this.changeTitleInfo();
        } else if (this.state.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_INCREASE_BW || this.state.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_REDUCE_BW) {
            return this.changeBWInfo();
        } else if (this.state.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CANCEL && this.state.tenantId ==  TENANT_ID.MEB) {
            return this.cancellationInfo();
        }else if (this.state.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CHANGE_ADDRESS) {
            return this.addressInfo();
        }
    }

    connectInfo = () => {
        return <Fieldset legend="Bağlantı Bilgileri">
            <Row>
                <Col>
                    <Fieldset legend="Teklif Bilgileri" toggleable={true} collapsed={this.state.panelCollapsedSR}
                              onToggle={(e) => this.setState({panelCollapsedSR: e.value})}>
                        <ProductOfferingGroup
                            value={this.state.tariffName}
                            onDataSelect={this.onProductOfferingSelect}
                            disabled={GeneralUtils.incidentButtonDisabled(this.state.pageMode, 'connectInfo')}/>
                        <AvGroup>
                            <Label for="packageName">
                                <Translate contentKey="packageName">Paket</Translate>
                            </Label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="packageName"
                                disabled={true}
                                value={this.state.packageName}/>
                        </AvGroup>
                        <AvGroup>
                            <Label for="productSpecName">
                                <Translate contentKey="productSpecName">Ürün Adı</Translate>
                            </Label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="productSpecName"
                                disabled={true}
                                value={this.state.productSpecName}/>
                        </AvGroup>
                        <AvGroup>
                            <Label for="accessTechnologyPO">
                                <Translate contentKey="bbProduct.bandwidthDownloadSpeed">Erişim Teknolojisi</Translate>
                            </Label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="accessTechnologyPO"
                                disabled={true}
                                value={this.state.accessTechnologyPO}/>
                        </AvGroup>
                        <AvGroup>
                            <Label for="downloadSpeedPO">
                                <Translate contentKey="bbProduct.bandwidthDownloadSpeed">İndirme Hızı</Translate>
                            </Label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="downloadSpeedPO"
                                disabled={true}
                                value={this.state.downloadSpeedPO}/>
                        </AvGroup>
                        <AvGroup>
                            <Label for="uploadSpeedPO">
                                <Translate contentKey="bbProduct.bandwidthUploadSpeed">Yükleme Hızı</Translate>
                            </Label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="uploadSpeedPO"
                                disabled={true}
                                value={this.state.uploadSpeedPO}/>
                        </AvGroup>
                    </Fieldset>
                </Col>
                <Col>
                    <AvGroup>
                        <Label for="hsmProjectId">
                            Proje
                        </Label>
                        <AvField type="select" className="form-control" name="hsmProjectId"
                                 validate={{
                                     required: {
                                         value: true,
                                         errorMessage: 'Bu alanın doldurulması zorunludur.'
                                     }
                                 }}
                                 disabled={GeneralUtils.incidentButtonDisabled(this.state.pageMode, 'connectInfo') || this.state.hsmProjectList.length == 1}
                                 value={this.state.serviceRequestExt.hsmProjectId}
                                 onChange={this.__handleChange}>
                            <option value='' key=''/>
                            {this.state.hsmProjectList.map(element => (
                                <option value={element.id} key={element.id}>
                                    {element.name}
                                </option>
                            ))}
                        </AvField>
                    </AvGroup>
                    <BillingAccountGroup
                        value={this.state.billingAccountName}
                        dataTable={{
                            dataURL: "billingAccount/findAllFilteredByOrganizationId",
                            parameter: (this.state.organizationId ? this.state.organizationId : 0),
                            fields: BillingAccountColumn.fields
                        }}
                        autoRefrestData={true}
                        onDataSelect={this.onBillingAccountSelect}
                        disabled={GeneralUtils.incidentButtonDisabled(this.state.pageMode, 'connectInfo')}/>
                    {(this.state.status !== 'NEW' || this.state.status !== 'DRAFT') &&
                    <AvGroup>
                        <Label for="serviceNumber">
                            <Translate contentKey="bbProduct.serviceNumber">Hizmet Numarası</Translate>
                        </Label>
                        <AvField
                            type="number"
                            className="form-control"
                            name="serviceNumber"
                            disabled={GeneralUtils.incidentButtonDisabled(this.state.pageMode, 'connectInfo') || (this.state.status === 'NEW' || this.state.status === 'DRAFT')}
                            value={this.state.serviceRequestExt.serviceNumber}
                            onChange={this.__handleChangeServiceNumber}
                            validate={{
                                minLength: {
                                    "value": 15,
                                    "errorMessage": "Bu alan en az 15 karakterden oluşabilir."
                                },
                                maxLength: {
                                    value: 15,
                                    errorMessage: "Bu alana en fazla 15 karakter girebilirsiniz."
                                }
                            }}/>
                    </AvGroup>}
                    {(this.state.status !== 'NEW' || this.state.status !== 'DRAFT') &&
                    <AvGroup>
                        <Label for="externalBillingAccountNumber">
                            <Translate contentKey="bbProduct.externalBillingAccountNumber">Harici Fatura Hesap
                                Numarası</Translate>
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="externalBillingAccountNumber"
                            disabled={GeneralUtils.incidentButtonDisabled(this.state.pageMode, 'connectInfo') || (this.state.status === 'NEW' || this.state.status === 'DRAFT')}
                            value={this.state.serviceRequestExt.externalBillingAccountNumber}
                            validate={{
                                maxLength: {
                                    value: 50,
                                    errorMessage: "Bu alana en fazla 50 karakter girebilirsiniz."
                                }
                            }}
                            onChange={this.__handleChange}/>
                    </AvGroup>}

                    {(this.state.status !== 'NEW' || this.state.status !== 'DRAFT') &&
                    <AvGroup>
                        <Label for="status">
                            <Translate contentKey="serviceRequestExt.regionType">Bölge</Translate>
                        </Label>
                        <AvField type="select" className="form-control" name="regionType"
                                 value={this.state.serviceRequestExt.regionType} onChange={this.__handleChange}
                                 disabled={GeneralUtils.incidentButtonDisabled(this.state.pageMode, 'connectInfo') || (this.state.status === 'NEW' || this.state.status === 'DRAFT')}>
                            {REGION_TYPE.map(element => (
                                <option value={element.value} key={element.value}>
                                    {element.name}
                                </option>
                            ))}
                        </AvField>
                    </AvGroup>}

                    <AvGroup>
                        <Label for="assetOwner">
                            Teçhizat Kim Tarafından Karşılanacak
                            <span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField type="select" className="form-control" name="assetOwner"
                                 disabled={GeneralUtils.incidentButtonDisabled(this.state.pageMode, 'connectInfo')}
                                 value={this.state.serviceRequestExt.assetOwner}
                                 validate={{
                                     required: {
                                         value: true,
                                         errorMessage: "Bu alanın doldurulması zorunludur."
                                     },
                                     maxLength: {
                                         value: 50,
                                         errorMessage: "Bu alana en fazla 50 karakter girebilirsiniz."
                                     }
                                 }}
                                 onChange={this.__handleChange}>
                            <option value=""/>
                            {ASSET_OWNER.map(element => (
                                <option value={element.value} key={element.value}>
                                    {element.name}
                                </option>
                            ))}
                        </AvField>
                    </AvGroup>
                    <AvGroup>
                        <Label for="preferredAccessTech">
                            Erişim Şekli Tercihi
                        </Label>
                        <AvField type="select" className="form-control" name="preferredAccessTech"
                                 disabled={GeneralUtils.incidentButtonDisabled(this.state.pageMode, 'connectInfo')}
                                 value={this.state.serviceRequestExt.preferredAccessTech}
                                 validate={{
                                     maxLength: {
                                         value: 50,
                                         errorMessage: "Bu alana en fazla 50 karakter girebilirsiniz."
                                     }
                                 }}
                                 onChange={this.__handleChange}>
                            <option value=""/>
                            {PREFERRED_ACCESS_TECH.map(element => (
                                <option value={element.value} key={element.value}>
                                    {element.name}
                                </option>
                            ))}
                        </AvField>
                    </AvGroup>
                    {this.state.serviceRequestExt.preferredAccessTech === 'PresentService' &&
                    <AvGroup>
                        <Label for="presentServiceNumber">
                            Mevcut Devre/Hizmet No
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="presentServiceNumber"
                            disabled={GeneralUtils.incidentButtonDisabled(this.state.pageMode, 'connectInfo')}
                            value={this.state.serviceRequestExt.presentServiceNumber}
                            validate={{
                                maxLength: {
                                    value: 50,
                                    errorMessage: "Bu alana en fazla 50 karakter girebilirsiniz."
                                }
                            }}
                            onChange={this.__handleChange}/>
                    </AvGroup>}

                </Col>
            </Row>
        </Fieldset>
    }

    transferInfo = () => {
        return <Fieldset legend="Devir Bilgileri">
            <div>
                <OrganizationGroup
                    name="transfereOrganizationId"
                    value={this.state.organizationExt}
                    onDataSelect={this.onOrganizationSelect}
                    label="Devralan Organizasyon"
                    disabled={this.state.pageMode !== PAGE_MODE.CREATE}
                    addNewButton={this.state.pageMode === PAGE_MODE.CREATE && this.state.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_TRANSFER ? true : false }
                    createNewOrganization={this.props.createNewOrganization}
                />

                {/*{this.state.pageMode === PAGE_MODE.CREATE &&*/}
                {/*<AvGroup>*/}
                {/*    <Checkbox name="locationChecked" onChange={this.__handleChangeLocationChecked}*/}
                {/*              checked={this.state.locationChecked} disabled={this.state.locationHidden}></Checkbox>*/}
                {/*    <Label for="locationChecked">*/}
                {/*        Lokasyon aynı kalacak*/}
                {/*    </Label>*/}
                {/*</AvGroup>*/}
                {/*}*/}


                {/*{this.state.locationChecked ?*/}
                {/*    <AvGroup>*/}
                {/*        <Label for="locationIdTemp">*/}
                {/*            Devralan Lokasyon*/}
                {/*            <span style={{color: 'red'}}> (*)</span>*/}
                {/*        </Label>*/}
                {/*        <AvField type="select" className="form-control" name="locationIdTemp"*/}
                {/*                 disabled={true}*/}
                {/*                 validate={{*/}
                {/*                     required: {*/}
                {/*                         value: true,*/}
                {/*                         errorMessage: "Bu alanın doldurulması zorunludur."*/}
                {/*                     }*/}
                {/*                 }}*/}
                {/*                 value={this.state.locationId}>*/}
                {/*            <option value=""/>*/}
                {/*            {this.state.locationListProps.map(element => (*/}
                {/*                <option value={element.id} key={element.id}>*/}
                {/*                    {element.name}*/}
                {/*                </option>*/}
                {/*            ))}*/}
                {/*        </AvField>*/}
                {/*    </AvGroup>*/}
                    {/*:*/}
                    <AvGroup>
                        <Label for="transfereeLocationId">
                            Devralan Lokasyon
                            <span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField type="select" className="form-control" name="transfereeLocationId"
                                 disabled={true}
                                 validate={{
                                     required: {
                                         value: true,
                                         errorMessage: "Bu alanın doldurulması zorunludur."
                                     }
                                 }}
                                 value={this.state.serviceRequestExt.transfereeLocationId}
                                 onChange={this.__handleChange}>
                            <option value=""/>
                            {this.state.locationList.map(element => (
                                <option value={element.id} key={element.id}>
                                    {element.name}
                                </option>
                            ))}
                        </AvField>
                    </AvGroup>
                {/*}*/}

                <BillingAccountGroup
                    value={this.state.billingAccountName}
                    dataTable={{
                        dataURL: "billingAccount/findAllFilteredByOrganizationId",
                        parameter: this.state.organizationId ? this.state.organizationId : 0,
                        fields: BillingAccountColumn.fields
                    }}
                    autoRefrestData={true}
                    onDataSelect={this.onBillingAccountSelect}
                    disabled={GeneralUtils.incidentButtonDisabled(this.state.pageMode, 'billingAccountName')}/>
            </div>
        </Fieldset>
    }

    cancellationInfo = () => {
        return <Fieldset legend="İptal Bilgileri">
            <div className="position-relative form-group">
                <Checkbox name="reuseIP"
                          onChange={e => {
                              let serviceRequestExt = this.state.serviceRequestExt;
                              serviceRequestExt.reuseIP = e.checked;
                              this.setState({
                                  reuseIP: serviceRequestExt.reuseIP,
                                  serviceRequestExt: serviceRequestExt
                              });
                              toast.warn("IP havuzuna atılan IP lerin havuzda olması durumda, havuzdaki ip adresi paketi kullanılmış durumuna alınacaktır.");
                          }}
                          checked={this.state.reuseIP || this.state.serviceRequestExt.reuseIP}
                          disabled={GeneralUtils.incidentButtonDisabled(this.state.pageMode, 'cancellationInfo')}></Checkbox>
                <Label for="reuseIP">
                    IP tekrar kullanılmak üzere IP Havuzuna atılsın mı ?
                </Label>
            </div>
            {/*<AvGroup>*/}
            {/*    <Label for="cancellationReason">*/}
            {/*        Lütfen İptal Sebebini Seçiniz*/}
            {/*    </Label>*/}
            {/*    <AvField type="select" className="form-control" name="cancellationReason"*/}
            {/*             value={this.state.serviceRequestExt.cancellationReason}*/}
            {/*             onChange={this.__handleChange}*/}
            {/*             disabled={this.state.pageMode !== PAGE_MODE.CREATE}>*/}
            {/*        <option value=""/>*/}
            {/*        {CANCELLATION_TYPE.map(element => (*/}
            {/*            <option value={element.value} key={element.value}>*/}
            {/*                {element.name}*/}
            {/*            </option>*/}
            {/*        ))}*/}
            {/*    </AvField>*/}
            {/*</AvGroup>*/}
            {/*{this.state.serviceRequestExt.cancellationReason === 'CANCELLATION_FOR_ALTERATION' &&*/}
            {/*<AvGroup>*/}
            {/*    <Label for="newServiceName">*/}
            {/*        Geçilecek Hizmet*/}
            {/*    </Label>*/}
            {/*    <AvField type="text" className="form-control" name="newServiceName"*/}
            {/*             value={this.state.serviceRequestExt.newServiceName}*/}
            {/*             onChange={this.__handleChange}*/}
            {/*             disabled={this.state.pageMode !== PAGE_MODE.CREATE}*/}
            {/*             validate={{*/}
            {/*                 maxLength: {*/}
            {/*                     value: 4000,*/}
            {/*                     errorMessage: "Bu alana en fazla 4000 karakter girebilirsiniz."*/}
            {/*                 },*/}
            {/*                 required: {*/}
            {/*                     value: true,*/}
            {/*                     errorMessage: "Bu alanın doldurulması zorunludur."*/}
            {/*                 }*/}
            {/*             }}/>*/}
            {/*</AvGroup>}*/}
        </Fieldset>
    }

    addressInfo = () =>{
        return <Fieldset legend="Adres Bilgileri">
            <Row>
                <Col>
                    <AvGroup>
                        <Label for="cityIdNew">İl Adı <span style={{color: 'red'}}>(*)</span></Label>
                        <AvField type="select" className="form-control" name="cityIdNew"
                                 value={this.state.serviceRequestExt.cityId}
                                 onChange={this.__handleChangeCity}
                                 validate={{
                                     required: {
                                         value: true,
                                         errorMessage: 'Bu alanın doldurulması zorunludur.'
                                     }
                                 }}
                                 disabled={true}>
                            <option value='' key=''/>
                            {this.state.cityList.map(element => (
                                <option value={element.id} key={element.id}>
                                    {element.name}
                                </option>
                            ))}
                        </AvField>
                    </AvGroup>

                    <AvGroup>
                        <Label for="districtIdNew">İlçe Adı <span style={{color: 'red'}}>(*)</span></Label>
                        <AvField type="select" className="form-control" name="districtIdNew"
                                 value={!GeneralUtils.isNullOrEmpty(this.state.serviceRequestExt.districtId) ? this.state.serviceRequestExt.districtId : null }
                                 onChange={this.__handleChangeForAddressInfo}
                                 disabled={this.state.pageMode !== PAGE_MODE.CREATE}>
                            <option value='' key=''/>
                            {this.state.districtList.map(element => (
                                <option value={element.id} key={element.id}>
                                    {element.name}
                                </option>
                            ))}
                        </AvField>
                    </AvGroup>

                    {/*<AvGroup>*/}
                    {/*    <Label for="ttRegionIdNew">TT Bölge Adı </Label>*/}
                    {/*    <AvField*/}
                    {/*        type="text"*/}
                    {/*        className="form-control"*/}
                    {/*        name="ttRegionIdNew"*/}
                    {/*        value={this.state.serviceRequestExt.ttRegionId}*/}
                    {/*        onChange={this.__handleChangeForAddressInfo}*/}
                    {/*        disabled={true}*/}
                    {/*    />*/}
                    {/*</AvGroup>*/}


                    <AvGroup>
                        <Label for="postalCodeNew">Posta Kodu</Label>
                        <AvField
                            type="number"
                            className="form-control"
                            name="postalCodeNew"
                            min="1000"
                            max="99999"
                            value={this.state.serviceRequestExt.postalCode}
                            onChange={this.__handleChangeForAddressInfo}
                            disabled={this.state.pageMode !== PAGE_MODE.CREATE}
                        />
                        <AvFeedback>Bu alan en fazla 5 karakterden oluşabilir.</AvFeedback>
                    </AvGroup>
                    <AvGroup>
                        <Label for="latitudeNew">Enlem</Label>
                        <AvField
                            type="number"
                            className="form-control"
                            name="latitudeNew"
                            max="999999"
                            value={this.state.serviceRequestExt.latitude}
                            onChange={this.__handleChangeForAddressInfo}
                            disabled={this.state.pageMode !== PAGE_MODE.CREATE}
                        />
                        <AvFeedback>Bu alanın formatı yanlış.</AvFeedback>
                    </AvGroup>
                    <AvGroup>
                        <Label for="longitudeNew">Boylam</Label>
                        <AvField
                            type="number"
                            className="form-control"
                            name="longitudeNew"
                            max="999999"
                            value={this.state.serviceRequestExt.longitude}
                            onChange={this.__handleChangeForAddressInfo}
                            disabled={this.state.pageMode !== PAGE_MODE.CREATE}
                        />
                        <AvFeedback>Bu alanın formatı yanlış.</AvFeedback>
                    </AvGroup>
                </Col>
                <Col>
                    <AvGroup>
                        <Label for="addressLineNew">Cadde/Sokak/Bulvar/Mahalle <span style={{color: 'red'}}>(*)</span></Label>
                        <AvField
                            type="textarea"
                            className="form-control textAreaHermes"
                            name="addressLineNew"
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: "Bu alanın doldurulması zorunludur."
                                },
                                maxLength: {
                                    value: 200,
                                    errorMessage: "Bu alana en fazla 200 karakter girebilirsiniz."
                                }
                            }}
                            value={this.state.serviceRequestExt.addressLine}
                            onChange={this.__handleChangeForAddressInfo}
                            disabled={this.state.pageMode !== PAGE_MODE.CREATE}
                        />
                        <AvFeedback>Bu alan en fazla 200 karakterden oluşabilir.</AvFeedback>
                    </AvGroup>
                    <AvGroup>
                        <Label for="sta">Bina Adı <span style={{color: 'red'}}>(*)</span></Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="buildingNameNew"
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: "Bu alanın doldurulması zorunludur."
                                },
                                maxLength: {
                                    value: 50,
                                    errorMessage: "Bu alana en fazla 50 karakter girebilirsiniz."
                                }
                            }}
                            value={this.state.serviceRequestExt.buildingName}
                            onChange={this.__handleChangeForAddressInfo}
                            disabled={this.state.pageMode !== PAGE_MODE.CREATE}
                        />
                        <AvFeedback>Bu alan en fazla 50 karakterden oluşabilir.</AvFeedback>
                    </AvGroup>
                    <AvGroup>
                        <Label for="buildingNumberNew">Bina Numarası <span style={{color: 'red'}}>(*)</span></Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="buildingNumberNew"
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: "Bu alanın doldurulması zorunludur."
                                },
                                maxLength: {
                                    value: 20,
                                    errorMessage: "Bu alana en fazla 20 karakter girebilirsiniz."
                                }
                            }}
                            value={this.state.serviceRequestExt.buildingNumber}
                            onChange={this.__handleChangeForAddressInfo}
                            disabled={this.state.pageMode !== PAGE_MODE.CREATE}
                        />
                    </AvGroup>
                    <AvGroup>
                        <Label for="doorNumberNew">Kapı Numarası</Label>
                        <AvField
                            type="number"
                            className="form-control"
                            name="doorNumberNew"
                            max="99999"
                            value={this.state.serviceRequestExt.doorNumber}
                            onChange={this.__handleChangeForAddressInfo}
                            disabled={this.state.pageMode !== PAGE_MODE.CREATE}
                        />
                    </AvGroup>
                    <AvGroup>
                        <Label for="levelNumberNew">Kat Numarası</Label>
                        <AvField
                            type="number"
                            className="form-control"
                            name="levelNumberNew"
                            max="999"
                            value={this.state.serviceRequestExt.levelNumber}
                            onChange={this.__handleChangeForAddressInfo}
                            disabled={this.state.pageMode !== PAGE_MODE.CREATE}
                        />
                    </AvGroup>


                </Col>
            </Row>
        </Fieldset>
    }

    __handleChangeForAddressInfo = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let serviceRequestExt = this.state.serviceRequestExt;
        serviceRequestExt[e.target.name.replace("New", "")] = value;
        this.setState({serviceRequestExt: serviceRequestExt});
    }


    __handleChangeCity = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        this.findDistrictByCityId(value);
        this.findTtRegionByCityId(value);
    }


    findDistrictByCityId = (cityId) => {
        this.districtService.findByCityId(cityId).then(response => {
            this.setState({districtList: response});
        });
    }

    findTtRegionByCityId = (cityId) => {
        this.ttRegionService.findTtRegionByCityId(cityId).then(response => {
            this.setState({ttRegion: response});
        });
    }

    findNotInBbProductByOrganizationId = (orgId, locId) => {
        this.locationService.findNotInBbProductByOrganizationId(orgId, locId).then(response => {
                if (!GeneralUtils.isNullOrEmpty(response)) {
                    this.setState({movedLocationList: response});
                }
            }
        )
    }

    moveInfo = () => {
        return <Fieldset legend="Nakil Bilgileri">
            <Col md={7}>
            <LocationGroup name="movedLocationId" label="Nakil Lokasyon"
                           value={this.state.serviceRequestExt.movedLocationId}
                           optionList={this.state.movedLocationList}
                           disabled={this.state.pageMode !== PAGE_MODE.CREATE}
                           onChange={this.__handleChange}
                           addNewButton={this.state.pageMode === PAGE_MODE.CREATE && this.state.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_MOVE ? true : false }
                           createNewLocation={this.props.createNewLocation}
            />
                {this.state.pageMode !== PAGE_MODE.CREATE &&
                <div className="position-relative form-group">
                    <Checkbox name="serviceNumberChangeChecked"
                              onChange={e => {
                                  let serviceRequestExt = this.state.serviceRequestExt;
                                  serviceRequestExt.newServiceNumber = '';
                                  this.setState({
                                      serviceNumberChangeChecked: e.checked,
                                      serviceRequestExt: serviceRequestExt
                                  });
                              }}
                              checked={this.state.serviceNumberChangeChecked || this.state.serviceRequestExt.newServiceNumber}
                              disabled={GeneralUtils.incidentButtonDisabled(this.state.pageMode, 'moveInfo')}></Checkbox>
                    <Label for="serviceNumberChangeChecked">
                        Hizmet Numarası Değişti
                    </Label>
                </div>}
            {(this.state.serviceNumberChangeChecked || this.state.serviceRequestExt.newServiceNumber) &&
            <AvGroup>
                <Label for="newServiceNumber">
                    <Translate contentKey="bbProduct.newServiceNumber">Hizmet Numarası</Translate>
                    <span style={{color: 'red'}}> (*)</span>
                </Label>
                <AvField
                    type="number"
                    className="form-control"
                    name="newServiceNumber"
                    disabled={GeneralUtils.incidentButtonDisabled(this.state.pageMode, 'moveInfo')}
                    value={this.state.serviceRequestExt.newServiceNumber}
                    onChange={this.__handleChangeServiceNumber}
                    validate={{
                        required: {
                            value: true,
                            errorMessage: "Bu alanın doldurulması zorunludur."
                        },
                        minLength: {
                            "value": 15,
                            "errorMessage": "Bu alan en az 15 karakterden oluşabilir."
                        },
                        maxLength: {
                            value: 15,
                            errorMessage: "Bu alana en fazla 15 karakter girebilirsiniz."
                        }
                    }}/>
            </AvGroup>}
            </Col>
        </Fieldset>
    }

    changeBWInfo = () => {
        return <Fieldset legend="Hız Değişikliği Bilgileri">
            <Row>
                <Col md="6">
                        <ProductOfferingGroup
                            value={this.state.tariffName}
                            onDataSelect={this.onProductOfferingSelect}
                            disabled={GeneralUtils.incidentButtonDisabled(this.state.pageMode, 'connectInfo')}
                            dataTable={{
                                dataURL: (this.state.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_INCREASE_BW ? "productOffering/findAllByOrgIdAndLocIdAndMoreDownloadSpeed" : "productOffering/findAllByOrgIdAndLocIdAndLessDownloadSpeed"),
                                parameter: (this.state.organizationId ? this.state.organizationId : 0) +'/'+ (this.state.locationId ? this.state.locationId : 0) +'/'+ (this.state.oldDownloadSpeed ? GeneralUtils.convertBps(this.state.oldDownloadSpeed) : 0),
                                fields: ProductOfferingColumn.fields
                            }}/>
                        <AvGroup>
                            <Label for="newBandwidthForBW">
                                <Translate contentKey="bbProduct.bandwidthDownloadSpeed">Yeni Bantgenişliği (Hız)</Translate>
                            </Label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="newBandwidthForBW"
                                disabled={true}
                                value={this.state.newBandwidthForBW}/>
                        </AvGroup>
                        <AvGroup>
                            <Label for="downloadSpeedPO">
                                <Translate contentKey="bbProduct.bandwidthDownloadSpeed">İndirme Hızı</Translate>
                            </Label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="downloadSpeedPO"
                                disabled={true}
                                value={this.state.downloadSpeedPO}/>
                        </AvGroup>
                        <AvGroup>
                            <Label for="uploadSpeedPO">
                                <Translate contentKey="bbProduct.bandwidthUploadSpeed">Yükleme Hızı</Translate>
                            </Label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="uploadSpeedPO"
                                disabled={true}
                                value={this.state.uploadSpeedPO}/>
                        </AvGroup>
                </Col>
                {this.state.oldBandwidth &&
                <Col md="6" style={{paddingTop: '52px'}}>
                    <AvGroup>
                        <Label for="oldBandwidth">
                            Eski Bantgenişliği (Hız)
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="oldBandwidth"
                            disabled={true}
                            value={this.state.oldBandwidth}/>
                    </AvGroup>
                    < AvGroup>
                        < Label for="oldDownloadSpeed">
                            < Translate contentKey="bbProduct.bandwidthDownloadSpeed">Eski İndirme Hızı</Translate>
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="oldDownloadSpeed"
                            disabled={true}
                            value={this.state.oldDownloadSpeed}/>
                    </AvGroup>
                    <AvGroup>
                        <Label for="oldUploadSpeed">
                            <Translate contentKey="bbProduct.bandwidthUploadSpeed">Eski Yükleme Hızı</Translate>
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="oldUploadSpeed"
                            disabled={true}
                            value={this.state.oldUploadSpeed}/>
                    </AvGroup>
                </Col>
                }
            </Row>
        </Fieldset>
    }

    suspendInfo = () => {
    }

    changeTitleInfo = () => {
        return <Fieldset legend="Ünvan Değişikliği Bilgileri">
            <Row>
                <Col>
                    <AvGroup>
                        <Label for="organizationNewCode">
                            <Translate contentKey="hsmOrganizationManagement.code">Organizasyon Yeni Kodu</Translate>
                            <span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="organizationNewCode"
                            validate={{
                                maxLength: {
                                    value: 20,
                                    errorMessage: "Bu alana en fazla 20 karakter girebilirsiniz."
                                },
                                required: {
                                    value: "true",
                                    errorMessage: "Bu alanın doldurulması zorunludur."
                                }
                            }}
                            value={this.state.serviceRequestExt.organizationNewCode}
                            onChange={this.__handleChange}
                            disabled={this.state.pageMode !== PAGE_MODE.CREATE}
                        />
                    </AvGroup>
                    <AvGroup>
                        <Label for="organizationNewName">
                            <Translate contentKey="hsmOrganizationManagement.name">Organizasyon Yeni Adı</Translate>
                            <span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="organizationNewName"
                            validate={{
                                maxLength: {
                                    value: 255,
                                    errorMessage: "Bu alana en fazla 255 karakter girebilirsiniz."
                                },
                                required: {
                                    value: "true",
                                    errorMessage: "Bu alanın doldurulması zorunludur."
                                }
                            }}
                            value={this.state.serviceRequestExt.organizationNewName}
                            onChange={this.__handleChange}
                            disabled={this.state.pageMode !== PAGE_MODE.CREATE}
                        />
                    </AvGroup>
                </Col>
                <Col>
                    <AvGroup>
                        <Label for="organizationOldCode">
                            <Translate contentKey="hsmOrganizationManagement.code">Organizasyon Eski Kodu</Translate>
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="organizationOldCode"
                            value={this.state.serviceRequestExt.organizationOldCode}
                            disabled={true}
                        />
                    </AvGroup>
                    <AvGroup>
                        <Label for="organizationOldName">
                            <Translate contentKey="hsmOrganizationManagement.name">Organizasyon Eski Adı</Translate>
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="organizationOldName"
                            value={this.state.serviceRequestExt.organizationOldName}
                            disabled={true}
                        />
                    </AvGroup>
                </Col>
            </Row>
        </Fieldset>
    }

    billDisputeInfo = () => {
    }


    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let serviceRequestExt = this.state.serviceRequestExt;
        serviceRequestExt[e.target.name] = value;
        this.setState({serviceRequestExt: serviceRequestExt});
    }

    __handleChangeServiceNumber = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let serviceRequestExt = this.state.serviceRequestExt;
        serviceRequestExt[e.target.name] = value;
        this.setState({serviceRequestExt: serviceRequestExt});
        if (value.length == 15) {
            this.doesHaveServiceNumber(value);
        }
    }

    // __handleChangeLocationChecked = (e) => {
    //     if (e.checked) {
    //         let serviceRequestExt = this.state.serviceRequestExt;
    //         serviceRequestExt.transfereeLocationId = this.state.locationId;
    //         this.setState({locationChecked: e.checked, serviceRequestExt: serviceRequestExt});
    //         this.doesHaveLocation(serviceRequestExt.transfereeOrganizationId);
    //         this.doesHaveOpenSRByOrgId(serviceRequestExt.transfereeOrganizationId);
    //     } else {
    //         let serviceRequestExt = this.state.serviceRequestExt;
    //         serviceRequestExt.transfereeLocationId = null;
    //         this.setState({locationChecked: e.checked, serviceRequestExt: serviceRequestExt});
    //     }
    // }

    onOrganizationSelect = (data) => {
        let serviceRequestExt = this.state.serviceRequestExt;
        serviceRequestExt.transfereeOrganizationId = data.id;
        serviceRequestExt.transfereeLocationId = null;
        let organizationExt = data.name;
        // this.setOrgAndLoc(data.id);
        this.setState({
            serviceRequestExt: serviceRequestExt,
            organizationExt: organizationExt,
            locationHidden: false,
            locationList: [],
            locationChecked: false
        });
        if (this.state.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_TRANSFER) {
            this.doesHaveBillingAccount(data.id);
            this.doesHaveLocation(data.id);
            this.doesHaveOpenSRByOrgId(data.id);
        }
    }

    onProductOfferingSelect = (data) => {
        if(this.props.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_REDUCE_BW || this.props.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_INCREASE_BW) {
            if (GeneralUtils.isNullOrEmpty(this.state.oldDownloadSpeed)) {
                return HermesSweetAlert.warn("Talebiniz ile ilgili bir genişbant ürünü seçilmedi! Lütfen ilgili genişbant ürününü “Varlıklar” sekmesi altında seçiniz.");
            }
            if (GeneralUtils.convertBps(data.productSpecId.bandwidthId.downloadSpeed) < GeneralUtils.convertBps(this.state.oldDownloadSpeed)) {
                HermesSweetAlert.info("Mevcut İndirme Hızından Daha Düşük Seçim Yaptınız!");
            }
        }
        let serviceRequestExt = this.state.serviceRequestExt;
        serviceRequestExt.productOfferingId = data.id;
        if(data.productSpecId.bandwidthId.accessTechnology === 'xDSL'){
            serviceRequestExt.preferredAccessTech = 'Copper';
        } else{
            serviceRequestExt.preferredAccessTech = 'Fiber';
        }
        let newBandwidthForBW = data.productSpecId.bandwidthId.accessTechnology + ' :' + data.productSpecId.bandwidthId.downloadSpeed + '/' + data.productSpecId.bandwidthId.uploadSpeed;
        this.setState({
            serviceRequestExt: serviceRequestExt,
            newBandwidthForBW: newBandwidthForBW,
            tariffName: data.tariffName,
            packageName: data.packageName,
            productSpecName: data.productSpecId.name,
            accessTechnologyPO: data.productSpecId.bandwidthId.accessTechnology,
            downloadSpeedPO: data.productSpecId.bandwidthId.downloadSpeed,
            uploadSpeedPO: data.productSpecId.bandwidthId.uploadSpeed
        });
    }

    onBillingAccountSelect = (data) => {
        let serviceRequestExt = this.state.serviceRequestExt;
        serviceRequestExt.billingAccountId = data.id;
        this.setState({serviceRequestExt: serviceRequestExt, billingAccountName: data.name});
    }

    setOrgAndLoc = (orgId) => {
        this.locationService.findByOrganizationId(orgId).then(response => {
                if (!GeneralUtils.isNullOrEmpty(response)) {
                    this.setState({locationList: response});
                }
            }
        )

    }

    doesHaveBillingAccount = (organizationId) => {
        this.businessRuleService.doesHaveBillingAccount(organizationId).then(response => {
            if (!response) {
                let serviceRequestExt = this.state.serviceRequestExt;
                serviceRequestExt.transfereeOrganizationId = null;
                this.setState({
                    serviceRequestExt: serviceRequestExt,
                    organizationExt: null,
                    locationList: [],
                    locationHidden: true
                });
                return HermesSweetAlert.warn("Seçmiş olduğunuz organizasyona bağlı fatura hesabı yoktur!");
            } else {
                if (this.props.setTransfereeOrganizationId) {
                    this.props.setTransfereeOrganizationId(organizationId);
                }
            }
        });
    }


    doesHaveLocation = (organizationId) => {
        if (GeneralUtils.isNullOrEmpty(organizationId)) {
            return;
        }
        this.businessRuleService.doesHaveLocation(organizationId).then(response => {
            if (response) {
                let serviceRequestExt = this.state.serviceRequestExt;
                serviceRequestExt.transfereeOrganizationId = null;
                // serviceRequestExt.transfereeLocationId = null;
                this.setState({
                    serviceRequestExt: serviceRequestExt,
                    organizationExt: null,
                    locationList: [],
                    locationHidden: true,
                    locationChecked: false
                });
                return HermesSweetAlert.warn("Seçmiş olduğunuz organizasyona bağlı lokasyon bulunmaktadır!");
            }
        });
    }

    doesHaveOpenSRByOrgId = (organizationId) => {
        if (GeneralUtils.isNullOrEmpty(organizationId)) {
            return;
        }
        this.businessRuleService.doesHaveOpenSRByOrgId(organizationId).then(response => {
            if (response) {
                let serviceRequestExt = this.state.serviceRequestExt;
                serviceRequestExt.transfereeOrganizationId = null;
                // serviceRequestExt.transfereeLocationId = null;
                this.setState({
                    serviceRequestExt: serviceRequestExt,
                    organizationExt: null,
                    locationList: [],
                    locationHidden: true,
                    locationChecked: false
                });
                return HermesSweetAlert.warn("Seçmiş olduğunuz organizasyon üzerinde henüz kapanmamış bir hizmet talebi mevcuttur! Yeni bir hizmet talebi iletemezsiniz.");
            }
        });
    }

    doesHaveServiceNumber(serviceNumber) {
        this.businessRuleService.doesHaveServiceNumber(serviceNumber).then(response => {
            if (response) {
                let serviceRequestExt = this.state.serviceRequestExt;
                serviceRequestExt.serviceNumber = null;
                serviceRequestExt.newServiceNumber = null;
                this.setState({serviceRequestExt: serviceRequestExt});
                return HermesSweetAlert.warn(serviceNumber + " hizmet numarasına sahip bir ürün sistemde mevcuttur! Bu hizmet numarası yeniden kullanılamaz.");
            }
        });
    }

    getData = () => {
        let serviceRequestExt = Object.assign({}, this.state.serviceRequestExt);
        serviceRequestExt.hsmProjectId = serviceRequestExt.hsmProjectId ? {id: serviceRequestExt.hsmProjectId} : null;
        return serviceRequestExt;
    }

    refresh = (location) => {
        let movedLocationList = this.state.movedLocationList;
        movedLocationList.push(location);
        this.setState({
            movedLocationList
        });
    }

}

