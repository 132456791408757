import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'reactstrap';
import {AvField, AvForm} from 'availity-reactstrap-validation';
import Translate from "../../shared/language/Translate";
import GeneralUtils from "../../shared/utils/GeneralUtils";
import PasswordStrengthBar from '../../shared/component/password/password-strength-bar';
import {toast} from "react-toastify";
import LoginService from "../../shared/service/LoginService";
import SHA256 from "crypto-js/sha256";

export default class PasswordResetFinish extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newPassword: '',
            activationKey: this.props.activationKey,
            passwordChangeKey: this.props.passwordChangeKey
        };
        this.service = new LoginService();
    }

    render() {
        return (
            <div>
            <AvForm>
                <AvField
                    name="newPassword"
                    label="Yeni Şifre"
                    placeholder="Yeni Şifre"
                    type="password"
                    validate={{
                        required: {
                            value: true,
                            errorMessage: 'Bu alan zorunlu.'
                        },
                        minLength: {
                            value: 8,
                            errorMessage: 'Bu alan en az 8 karakterden oluşabilir.'
                        },
                        maxLength: {
                            value: 50,
                            errorMessage: 'Bu alan en fazla 50 karakterden oluşabilir.'
                        }
                    }}
                    onChange={this.__handleChange}
                />
                <PasswordStrengthBar password={this.state.newPassword}/>
                <AvField
                    name="confirmPassword"
                    label="Şifre Tekrar"
                    placeholder="Şifre Tekrar"
                    type="password"
                    value={this.state.newPassword} onChange={this.__handleChange}
                    validate={{
                        required: {
                            value: true,
                            errorMessage: 'Bu alan zorunlu.'
                        },
                        minLength: {
                            value: 8,
                            errorMessage: 'Bu alan en az 8 karakterden oluşabilir.'
                        },
                        maxLength: {
                            value: 50,
                            errorMessage: 'Bu alan en fazla 50 karakterden oluşabilir.'
                        },
                        match: {
                            value: 'newPassword',
                            errorMessage: 'Şifreler eşleşmedi!'
                        }
                    }}
                />
                <Button color="success" onClick={this.handleValidSubmit}>
                    <Translate contentKey="reset.finish.form.button">Kaydet</Translate>
                </Button>
            </AvForm></div>
        );
    }

    __handleChange = (e) => {
        let state = {};
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        state[e.target.name] = value;
        this.setState(state);
    }

    handleValidSubmit = () => {
        if (!GeneralUtils.checkPasswordFormat(this.state.newPassword)) {
            return toast.warn("Şifre en az bir sayı, bir küçük harf, bir büyük harf, en az 8 karakter olmalıdır.");
        }
        if(!GeneralUtils.isNullOrEmpty(this.state.activationKey)) {
            let data = {
                key: this.state.activationKey,
                newPassword: SHA256(this.state.newPassword).toString()
            };
            this.service.activateUser(data);
        }else{
            let data = {
                key: this.state.passwordChangeKey,
                newPassword: SHA256(this.state.newPassword).toString()
            };
            this.service.resetPasswordFinish(data);
        }
    };

    // getUrlParameter = (name, search) => {
    //     const url = new URL(`http://localhost${search}`); // using a dummy url for parsing
    //     return url.searchParams.get(name) || '';
    // };
}
PasswordResetFinish.propTypes = {
    activationKey: PropTypes.string,
    passwordChangeKey: PropTypes.string,
};