import React from 'react';
import PriceColumn from '../../../shared/component/dataTable/columns/PriceColumn';
import PriceService from "../../../shared/service/PriceService";
import DataTable from "../../../shared/component/dataTable/DataTable";


export default class Price extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
        this.service = new PriceService();
    }

    render() {
        return (
            <DataTable ref={(elem) => this.refDataTable = elem} fields={PriceColumn.fields} dataURL="price/findByProductId" objectName={PriceColumn.name} autoLoad={false}/>
        );
    }

    setData = (data) => {
        this.clearData();
        this.refDataTable.setParameter(data.id);
        // this.service.findByProductId(data.id).then(response => {
        //     this.setState({data: response})
        // });
    }

    clearData = () => {
        this.setState({data: []})
    }
}
