import React, {Component} from 'react';
import {Bar} from "react-chartjs-2";
import {Panel} from "primereact/panel";
import {Button, Col, Container, Row} from 'reactstrap';
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import SlaExceedReportService from "../../../shared/service/SlaExceedReportService";

export class SlaExceedBarChartReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                labels: ['Bu Gün', 'Bu Hafta', 'Bu Ay'],
                datasets: [
                    {
                        label: 'SLA:  ',
                        backgroundColor: '#42A5F5',
                        data: []
                    }
                ]
            }
        }
        this.service = new SlaExceedReportService();
    }

    componentDidMount = () => {
        this.service.checkPage();
        this.refresh();
    }

    render() {
        return (
            <Panel header="Aylık SLA Aşmış Talepler Raporu">
                <Toolbar display={true}>
                    <div className="p-toolbar-group-right">
                        <Button color="primary" onClick={this.refresh}>
                            Yenile
                        </Button>
                    </div>
                </Toolbar>
                <Container>
                    <Row>
                        <Col>
                            {this.state.item}
                        </Col>
                    </Row>
                </Container>
            </Panel>
        )
    }

    getBar = () => {
        return <Bar data={this.state.data} onElementsClick={this.redirectPage}/>;
    }

    redirectPage = (e) => {
        if (e.length !== 0) {
            if (e[0]._model.label === 'Bu Gün') {
                window.location = '#/slaExceedDetailReport?interval=TODAY';
            } else if (e[0]._model.label === 'Bu Hafta') {
                window.location = '#/slaExceedDetailReport?interval=WEEK';
            } else if (e[0]._model.label === 'Bu Ay') {
                window.location = '#/slaExceedDetailReport?interval=MONTH';
            }
        }
    }

    refresh = () => {
        let data = this.state.data;
        this.setState({item: null});
        data.datasets[0].data = [];


        this.service.getOpenSlaCounts().then((response) => {
            data.datasets[0].data.push(response.day, response.week, response.month);
            this.setState({data: data});
            this.setState({item: this.getBar()});

        });

    }
}

export default SlaExceedBarChartReport;
