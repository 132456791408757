import React from 'react';
import {Button, Col, Label, Row} from 'reactstrap';
import {AvFeedback, AvForm, AvGroup} from 'availity-reactstrap-validation';
import AvField from '../../../shared/component/form/AvField';
import PropTypes from 'prop-types';
import Translate from "../../../shared/language/Translate";
import {
    BILLING_APPROVAL_STATUS_ALL,
    BILLING_APPROVAL_STATUS_OK,
    EXISTS,
    SERVICE_SUBCATEGORY,
    TENANT_ID
} from "../../../shared/utils/Constants";
import Dialog from "../../../shared/component/dialog/Dialog";
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import LocationService from "../../../shared/service/LocationService";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import TenantService from "../../../shared/service/TenantService";

const data = {
    id: '',
    description: '',
    billingApprovedTF: null,
    smartBoardExist: ''
};
export default class BillingApprovalDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageMode: null,
            billingApprovalStatus: []
        }
        this.locationService = new LocationService();
        this.tenantService = new TenantService();
    }

    componentDidMount = () => {
        this.tenantService.getTenant().then(result => {
            if (!GeneralUtils.isNullOrEmpty(result)) {
                if (!GeneralUtils.isNullOrEmpty(result) && result == TENANT_ID.GSB) {
                    this.setState({billingApprovalStatus: BILLING_APPROVAL_STATUS_OK});
                } else {
                    this.setState({billingApprovalStatus: BILLING_APPROVAL_STATUS_ALL});
                }
                this.setState({tenantId: result});
            }
        });
    }

    // componentWillReceiveProps = (nextProps) => {
    //     if (nextProps.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_BILLING_APPROVAL) {
    //         this.setState({billingApprovalStatus: BILLING_APPROVAL_STATUS_OK});
    //     }
    // }

    render() {
        return (
            <Row className="justify-content-center">
                <Col md="8">
                    <Dialog
                        header={'Faturalama Onay'} modal={true} resizable={true}
                        visible={this.state.displayDialog}
                        maximizable={true} responsive={true} onHide={() => {
                        this.setState({displayDialog: false});
                    }}
                        style={{width: '50vw'}}>

                        <AvForm onValidSubmit={this.billingApproved}>
                            <Toolbar display={true}>
                                <div className="p-toolbar-group-right">
                                    <Button color="primary" className="float-right" type="submit">
                                        Kaydet
                                    </Button>
                                    <Button color="primary" className="float-right"
                                            onClick={() => {
                                                this.setState({displayDialog: false});
                                            }}>
                                        Vazgeç
                                    </Button>
                                </div>
                            </Toolbar>


                            <AvGroup>
                                <Label for="description">
                                    <Translate contentKey="description">Açıklama</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvField
                                    type="textarea"
                                    className="form-control textAreaHermes"
                                    name="description"
                                    validate={{
                                        maxLength: {
                                            value: 4000,
                                            errorMessage: "Bu alana en fazla 4000 karakter girebilirsiniz."
                                        },
                                        required: {
                                            value: "true",
                                            errorMessage: "Bu alanın doldurulması zorunludur."
                                        }
                                    }}
                                    value={this.state.data.description}
                                    onChange={this.__handleChange}
                                />
                                <AvFeedback>Bu alana en fazla 4000 karakter girebilirsiniz.</AvFeedback>
                            </AvGroup>
                            {this.state.tenantId == TENANT_ID.MEB &&
                            <AvGroup>
                                <Label for="smartBoardExist">
                                    <Translate contentKey="location.smartBoardExist">Akıllı Tahta</Translate>
                                </Label>
                                <AvField type="select" className="form-control" name="smartBoardExist"
                                         value={this.state.data.smartBoardExist} onChange={this.__handleChange}>
                                    <option value='' key=''/>
                                    {EXISTS.map(element => (
                                        <option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>}
                            <AvGroup>
                                <Label for="billingApprovedTF">
                                    Faturalama Onayı
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvField type="select" className="form-control" name="billingApprovedTF"
                                         validate={{
                                             required: {
                                                 value: true,
                                                 errorMessage: "Bu alanın doldurulması zorunludur."
                                             }
                                         }}
                                         value={this.state.data.billingApprovedTF}
                                         onChange={this.__handleChange}>
                                    <option value=""/>
                                    {this.state.billingApprovalStatus.map(element => (
                                        <option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>
                        </AvForm>
                    </Dialog>
                </Col>
            </Row>
        );
    }

    billingApproved = () => {
        this.props.billingApproved();
        this.setState({displayDialog: false});
    }

    setData = (data) => {
        this.setState({data: data});
    }

    setLocationId = (locationId) => {
        this.locationService.findById(locationId).then((response) => {
                let data = this.state.data;
                data.smartBoardExist = response.smartBoardExist;
                this.setState({data: data})
            }
        )
    }


    clearData = () => {
        this.setState({data: Object.assign({}, data)});
    }

    getData = () => {
        return this.state.data;
    }


    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let data = this.state.data;
        data[e.target.name] = value;
        this.setState({data: data});
    }

    setDialog = (value) => {
        this.clearData();
        this.setState({displayDialog: value});
    }

}

BillingApprovalDialog.propTypes = {
    data: PropTypes.object
};
