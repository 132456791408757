import React, {Component} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import "./Loading.css";
// import {Circle} from 'better-react-spinkit';

export default class Loading extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div id="loadingHermes" className="loadingHermes">
                <div className="loading">

                </div>
                <div className="loadingContent">
                    <div className="spinner">
                        <CircularProgress
                            variant="indeterminate"
                            disableShrink
                            style={{
                                color: 'blue',
                                animationDuration: '550ms',
                            }}
                            size={50}
                            thickness={7}
                        />
                    </div>
                </div>
            </div>
        );
    }
}