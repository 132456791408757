import React from 'react';
import {Button, Col, Label, Row} from "reactstrap";
import {AvFeedback, AvGroup} from 'availity-reactstrap-validation';
import AvField from "../../shared/component/form/AvField";
import ServiceRequestService from "../../shared/service/ServiceRequestService";
import {
    ASSET_CATEGORY,
    CHANNEL_TYPE,
    GUEST_CONTACT_ID,
    IMPACT_TYPE,
    PAGE_MODE,
    PRIORITY_TYPE,
    REQUEST_TYPE,
    SERVICE_REQUEST_STATUS,
    SERVICE_REQUEST_STATUS_REASON,
    SERVICE_SUBCATEGORY,
    URGENCY_TYPE,
    YESNO,
    ILKO_ROL_ID
} from "../../shared/utils/Constants";
import GeneralUtils from "../../shared/utils/GeneralUtils";
import ServiceSubcategoryService from "../../shared/service/ServiceSubcategoryService";
import HsmOrganizationService from "../../shared/service/HsmOrganizationService";
import ServiceCategoryService from "../../shared/service/ServiceCategoryService";
import ContactService from "../../shared/service/ContactService";
import LocationService from "../../shared/service/LocationService";
import {Fieldset} from "primereact/fieldset";
import DateInput from "../../shared/component/form/DateInput";
import OrganizationGroup from "../../shared/component/hsmGroup/OrganizationGroup";
import HsmOrganizationColumnForDialog from '../../shared/component/dataTable/columns/HsmOrganizationColumnForDialog';
import ContactGroup from "../../shared/component/hsmGroup/ContactGroup";
import BbProductService from "../../shared/service/BbProductService";
import HsmUserGroup from "../../shared/component/hsmGroup/HsmUserGroup";
import HsmResolutionCodeService from "../../shared/service/HsmResolutionCodeService";
import ServiceRequestNewExt from "./ServiceRequestNewExt";
import LocationGroup from "../../shared/component/hsmGroup/LocationGroup";
import ContactColumnForDialog from "../../shared/component/dataTable/columns/ContactColumnForDialog";
import BusinessRuleService from "../../shared/service/BusinessRuleService";
import ServiceRequestColumn from '../../shared/component/dataTable/columns/ServiceRequestColumn';
import HermesSweetAlert from "../../shared/component/sweetAlert/HermesSweetAlert";
import RequestLocationService from "../../shared/service/RequestLocationService";
import {MultiSelect} from "primereact/multiselect";
import BbProductGroup from "../../shared/component/hsmGroup/BbProductGroup";
import BbProductColumnForGroup from "../../shared/component/dataTable/columns/BbProductColumnForGroup";
import {PhoneField} from "../../shared/component/form/PhoneField";
import Location from "../asset-management/location/Location";
import Dialog from "../../shared/component/dialog/Dialog";
import HsmSubnet from "../asset-management/bb-product/HsmSubnet";
import RequestSubnetService from "../../shared/service/RequestSubnetService";

let defaultServiceRequest = {
    id: null,
    title: '',
    description: '',
    serviceCategoryId: 1,
    closureId: null,
    serviceSubcategoryId: 2,
    organizationId: null,
    locationId: null,
    channel: '',
    urgency: '4',
    priority: '4',
    impact: '4',
    emailNotification: '1',
    status: 'NEW',
    contactGivenNames: '',
    contactFamilyNames: '',
    contactEmail: '',
    contactPhone: '',
    contactGivenNamesTemp: '',
    contactFamilyNamesTemp: '',
    contactEmailTemp: '',
    contactPhoneTemp: '',
    requestedBy: null,
    reportedBy: null,
    assignedTo: null,
    assignmentDate: null,
    lastModifiedDate: null,
    createdBy: null,
    createdDate: null,
    teamId: null
};
export default class ServiceRequestNew extends React.Component {
    constructor(props) {
        super(props);
        defaultServiceRequest.requestedBy = GUEST_CONTACT_ID;
        defaultServiceRequest.reportedBy = GeneralUtils.getContactId();
        defaultServiceRequest.createdBy = GeneralUtils.getUserId();
        this.state = {
            displayContent: false,
            serviceRequest: defaultServiceRequest,
            hsmResolutionCodeList: [],
            pageMode: PAGE_MODE.CREATE,
            serviceCategoryList: [{id: 1, name: 'TTVPN'}],
            serviceSubcategoryList: [],
            locationList: [],
            locationListForMultiple: [],
            locationHidden: true,
            phoneFieldDisabled: false,
            emailNotificationDisabled: false,
            closureCodeList: [{id: 0, name: ''}],
            organization: null,
            requestedBy: "",
            reportedBy: GeneralUtils.getUserNameAndFamilyNames(),
            servedLocationList: [],
            isOrganizationSelected:false
        };
        this.service = new ServiceRequestService();
        this.serviceCategoryService = new ServiceCategoryService();
        this.serviceSubategoryService = new ServiceSubcategoryService();
        this.hsmOrganizationService = new HsmOrganizationService();
        this.locationService = new LocationService();
        this.bbProductService = new BbProductService();
        this.hsmResolutionCodeService = new HsmResolutionCodeService();
        this.contactService = new ContactService();
        this.businessRuleService = new BusinessRuleService();
        this.requestLocationService = new RequestLocationService();
        this.requestSubnetService = new RequestSubnetService();
    }


    componentDidMount = () => {
        this.clearData();
        this.serviceCategoryService.findAll().then(response => this.setState({serviceCategoryList: response}));
        this.serviceSubategoryService.findServiceRequestSubCategories(this.state.serviceRequest.serviceCategoryId).then(response => {
            let serviceSubategoryList = this.checkSubcategoryAccess(response);

            let serviceRequest = this.state.serviceRequest;
            if (!GeneralUtils.isNullOrEmpty(serviceSubategoryList)) {
                serviceRequest.serviceSubcategoryId = serviceSubategoryList[0].id;
                serviceRequest.title = serviceSubategoryList[0].name;
            }
            this.setState({serviceSubcategoryList: serviceSubategoryList, serviceRequest: serviceRequest});
        });

        this.hsmResolutionCodeService.findByRequestType(REQUEST_TYPE.SERVICE_REQUEST).then(response => {
            this.setState({hsmResolutionCodeList: response});
        });

        this.contactService.findById(defaultServiceRequest.requestedBy).then(data=>{
            let requestedBy = data.givenNames + ' ' + data.familyName;
            let requestedByData = data;
            let serviceRequest = this.state.serviceRequest;
            serviceRequest.contactGivenNames = "";
            serviceRequest.contactFamilyNames = "";
            serviceRequest.contactEmail = data.email;
            serviceRequest.contactPhone = data.mobilePhone;
            this.setState({
                serviceRequest, requestedBy,requestedByData
            });
        });
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({productOfferingId: nextProps.productOfferingId});
    }

    render() {
        return (<div>{this.state.displayContent ? this.getContent() : this.getCategoryAndSubcategory()}</div>);
    }

    getCategoryAndSubcategory = () => {
        return <div>
            {GeneralUtils.checkPrivilege(ServiceRequestColumn.name, 'Create') &&
            <Row className="justify-content-left">
                <Col md="7">
                    <Row>
                        <Col md="7">
                            <Fieldset legend="Hizmet Talebi Bilgileri">
                                <AvGroup>
                                    <Label for="serviceCategoryId">
                                        Kategori
                                    </Label>
                                    <AvField type="select" className="form-control" name="serviceCategoryId"
                                             value={this.state.serviceRequest.serviceCategoryId}
                                             disabled={this.state.displayOrganizationAndLocation}
                                             onChange={this.__handleChangeServiceCategory}>
                                        {this.state.serviceCategoryList.map(element => (
                                            <option value={element.id} key={element.id}>
                                                {element.name}
                                            </option>
                                        ))}
                                    </AvField>
                                </AvGroup>


                                <AvGroup>
                                    <Label for="serviceSubcategoryId">
                                        Alt Kategori
                                    </Label>
                                    <AvField type="select" className="form-control" name="serviceSubcategoryId"
                                             value={this.state.serviceRequest.serviceSubcategoryId}
                                             disabled={this.state.displayOrganizationAndLocation}
                                             onChange={this.__handleChangeServiceSubcategory}>
                                        {this.state.serviceSubcategoryList.map(element => (
                                            <option value={element.id} key={element.id}>
                                                {element.name}
                                            </option>
                                        ))}
                                    </AvField>
                                </AvGroup>
                                {this.state.displayOrganizationAndLocation && this.getOrganizationAndLocation()}
                                {!this.state.displayOrganizationAndLocation &&
                                <Button color="primary"
                                        onClick={() => this.setState({displayOrganizationAndLocation: true})}>
                                    Devam
                                </Button>}
                            </Fieldset>
                        </Col>
                        <Col/>
                    </Row>
                </Col>
                <Col md="5">
                </Col>
            </Row>
            }
        </div>
    }

    getOrganizationAndLocation = () => {
        return <div>
            <Fieldset
                legend={(this.state.serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CONNECT || this.state.serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CHANGE_TITLE)
                    ? (this.state.serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CHANGE_TITLE ? "Organizasyon" :"Organizasyon, Lokasyon") : "Hizmet Numarası, Organizasyon, Lokasyon"}>
                {(this.state.serviceRequest.serviceSubcategoryId != SERVICE_SUBCATEGORY.TTVPN_CONNECT && this.state.serviceRequest.serviceSubcategoryId != SERVICE_SUBCATEGORY.TTVPN_CHANGE_TITLE) &&
                <BbProductGroup
                    value={this.state.bbProduct}
                    data={this.state.bbProductObject}
                    onDataSelect={this.onBbProductSelect}
                    disabled={GeneralUtils.incidentButtonDisabled(this.state.pageMode, 'bbProduct')}
                    dataTable={{
                        dataURL : "bbProduct/findByContactIdAndStatus",
                        parameter: GeneralUtils.getContactId() + '/'+ GeneralUtils.getAssetStatus(this.state.serviceRequest.serviceSubcategoryId),
                        fields: BbProductColumnForGroup.fields
                    }}/>}
                <OrganizationGroup
                    value={this.state.organization}
                    data={this.state.organizationObject}
                    onDataSelect={this.onOrganizationSelect}
                    dataTable={{
                        dataURL: "hsmOrganization/findByContactId",
                        parameter:  GeneralUtils.getContactId(),
                        fields: HsmOrganizationColumnForDialog.fields
                    }}
                    disabled={(this.state.serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CONNECT || this.state.serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CHANGE_TITLE)
                        ? GeneralUtils.incidentButtonDisabled(this.state.pageMode, 'organizationId') : true}
                    addNewButton={ this.state.serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CONNECT ? true : false }
                    createNewOrganization={this.props.createNewOrganization}
                />
                {this.state.serviceRequest.serviceSubcategoryId != SERVICE_SUBCATEGORY.TTVPN_CHANGE_TITLE &&
                <LocationGroup value={this.state.serviceRequest.locationId}
                               optionList={this.state.locationList}
                               disabled={(this.state.serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CONNECT || this.state.serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CHANGE_TITLE)
                                   ? GeneralUtils.incidentButtonDisabled(this.state.pageMode, 'locationId') : true}
                               addNewButton={this.state.serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CONNECT ? true : false }
                               createNewLocation={this.props.createNewLocation}
                               onChange={this.__handleChangeLocation}
                />}
            </Fieldset>
            <Button color="primary" onClick={() => this.setState({displayOrganizationAndLocation: false})}>
                Geri
            </Button>
            <Button color="primary" onClick={() => {
                if(this.state.serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CHANGE_TITLE){
                    if (GeneralUtils.isNullOrEmpty(this.state.serviceRequest.organizationId)) {
                        return HermesSweetAlert.warn("Zorunlu Alan : Organizasyon");
                    }
                } else if(this.state.serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CONNECT){
                    if (GeneralUtils.isNullOrEmpty(this.state.serviceRequest.organizationId) || GeneralUtils.isNullOrEmpty(this.state.serviceRequest.locationId)) {
                        return HermesSweetAlert.warn("Zorunlu Alan : Organizasyon, Lokasyon");
                    }
                } else {
                    if (GeneralUtils.isNullOrEmpty(this.state.bbProduct) || GeneralUtils.isNullOrEmpty(this.state.serviceRequest.organizationId) || GeneralUtils.isNullOrEmpty(this.state.serviceRequest.locationId)) {
                        return HermesSweetAlert.warn("Zorunlu Alan : Hizmet Numarası, Organizasyon, Lokasyon");
                    }
                }
                this.setState({displayContent: true});
                this.props.disabledTabs();
            }}>
                Devam
            </Button>
        </div>
    }

    getContent = () => {
        return <div>
            <Row className="justify-content-left">
                    <Col md="4">
                        {this.getServiceRequestInfo()}
                    </Col>

                    <Col md="5">
                        {this.getDescription()}
                    </Col>

                    <Col>
                        {this.getContactInfo()}
                    </Col>
            </Row>

            <Row className="justify-content-left">
                    <Col md="4">
                        <ServiceRequestNewExt ref={elem => this.refServiceRequestNewExt = elem}
                                              serviceSubcategoryId={this.state.serviceRequest.serviceSubcategoryId}
                                              serviceRequestExt={this.state.serviceRequest.serviceRequestExt}
                                              pageMode={this.state.pageMode}
                                              organizationOldCode={this.state.organizationCode}
                                              organizationOldName={this.state.organization}
                                              organizationId={this.state.serviceRequest.organizationId}
                                              locationId={this.state.serviceRequest.locationId}
                                              locationListProps={this.state.locationList}
                                              status={this.state.serviceRequest.status}
                                              setTransfereeOrganizationId={(transfereeOrganizationId) => {
                                                  if (this.props.setTransfereeOrganizationId) {
                                                      this.props.setTransfereeOrganizationId(transfereeOrganizationId);
                                                  }
                                              }}
                                              oldProductOfferingId={this.state.productOfferingId}
                                              createNewOrganization ={this.props.createNewOrganization}
                                              createNewLocation={this.props.createNewLocation}
                        />
                    </Col>

                {this.state.serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CONNECT &&
                <Col md="5">
                    <Fieldset legend="IP Bilgileri">
                        <HsmSubnet ref={(elem) => this.refHsmSubnet = elem} data={this.state.hsmSubnetList}
                                   setHsmSubnetList={(hsmSubnetList) => this.setState({hsmSubnetList: hsmSubnetList})}
                                   pageMode={this.state.pageMode} status={this.state.serviceRequest.status}
                                   locationId={this.state.serviceRequest.locationId}
                                   organizationId={this.state.organizationObject.id}
                        />
                    </Fieldset>
                </Col>
                }

                {(this.state.serviceRequest.status !== 'DRAFT' && this.state.pageMode !== PAGE_MODE.CREATE) &&

                    <Col md="5">
                        {this.getAssignedInfo()}
                    </Col>}

                {(this.state.serviceRequest.status !== 'DRAFT' && this.state.pageMode !== PAGE_MODE.CREATE) &&
                    <Col md="3">
                        {this.closureInfo()}
                    </Col>}
            </Row>

            <Row>

            </Row>
        </div>
    }


    getDescription = () => {
        return <Fieldset legend="Hizmet Talebi Bilgileri">

            {/*<AvGroup>*/}
            {/*    <Label for="channel">*/}
            {/*        Kanal*/}
            {/*    </Label>*/}
            {/*    <AvField type="select" className="form-control" name="channel"*/}
            {/*             disabled={GeneralUtils.incidentButtonDisabled(this.state.pageMode, 'channel')}*/}
            {/*             value={this.state.serviceRequest.channel} onChange={this.__handleChange}>*/}
            {/*        <option value='' key=''/>*/}
            {/*        {CHANNEL_TYPE.map(element => (*/}
            {/*            <option value={element.value} key={element.value}>*/}
            {/*                {element.name}*/}
            {/*            </option>*/}
            {/*        ))}*/}
            {/*    </AvField>*/}
            {/*</AvGroup>*/}


            <AvGroup>
                <Label for="title">
                    Başlık
                    <span style={{color: 'red'}}> (*)</span>
                </Label>
                <AvField type="text" className="form-control" name="title" required
                         disabled={GeneralUtils.incidentButtonDisabled(this.state.pageMode, 'title')}
                         validate={{
                             required: {
                                 value: true,
                                 errorMessage: "Bu alanın doldurulması zorunludur."
                             },
                             maxLength: {
                                 value: 255,
                                 errorMessage: 'Bu alan en fazla 4000 karakterden oluşabilir.'
                             }
                         }}
                         value={this.state.serviceRequest.title} onChange={this.__handleChange}/>
            </AvGroup>
            <AvGroup>
                <Label for="description">
                    Açıklama
                    <span style={{color: 'red'}}> (*)</span>
                </Label>
                <AvField type="textarea"
                         className="form-control textAreaHermesSoBig" name="description" required
                         validate={{
                             required: {
                                 value: true,
                                 errorMessage: "Bu alanın doldurulması zorunludur."
                             },
                             maxLength: {
                                 value: 2000,
                                 errorMessage: 'Bu alan en fazla 2000 karakterden oluşabilir.'
                             }
                         }}
                         value={this.state.serviceRequest.description}
                         disabled={GeneralUtils.incidentButtonDisabled(this.state.pageMode, 'description')}
                         onChange={this.__handleChange}
                         placeholder="Lütfen İletişime Geçme Sebebinizi Detaylı Bir Şekilde Açıklayınız."/>
            </AvGroup>


        </Fieldset>;
    }

    closureInfo = () => {
        return <Fieldset legend="Çözüm Bilgileri">
            {/*<Row>*/}
            {/*    <Col md="4">*/}
                    <AvGroup>
                        <Label for="resolutionCode">
                            Çözüm Kodu
                        </Label>
                        <AvField type="select" className="form-control" name="resolutionCode"
                                 disabled={true}
                                 value={this.state.serviceRequest.resolutionCode}
                        >
                            {GeneralUtils.isNullOrEmpty(this.state.serviceRequest.resolutionCode) && <option value=""/>}
                            {this.state.hsmResolutionCodeList.map(element => (
                                <option value={element.code} key={element.code}>
                                    {element.shortName}
                                </option>
                            ))}
                        </AvField>
                    </AvGroup>
                {/*</Col>*/}
                {/*<Col md="4">*/}
                    <HsmUserGroup
                        value={this.state.resolvedBy}
                        disabled={true}
                        validate={{
                            required: {
                                value: false,
                                errorMessage: 'Bu alanın doldurulması zorunludur.'
                            }
                        }}
                        label="Çözen"
                    />
                {/*</Col>*/}
                {/*<Col md="4">*/}
                    <div className="position-relative form-group">
                        <Label for="resolutionDate">
                            Çözüm Tarihi
                        </Label>
                        <DateInput
                            name="resolutionDate"
                            onChange={this.__handleChange}
                            disabled={true}
                            value={this.state.serviceRequest.resolutionDate}/>
                    </div>
            {/*    </Col>*/}
            {/*</Row>*/}
        </Fieldset>;
    }

    getAssignedInfo = () => {
        return <Fieldset legend="Atama Bilgileri">
            <Row>
                <Col md="4">
                    <AvGroup>
                        <Label for="assignedTo">
                            Atanan Kişi
                        </Label>
                        <AvField type="text" className="form-control" name="assignedTo" disabled={true}
                                 value={this.state.serviceRequest.assignedTo != null ? this.state.serviceRequest.assignedTo.givenNames + ' ' + this.state.serviceRequest.assignedTo.familyName : ''}/>{' '}
                    </AvGroup>

                    <div className="position-relative form-group">
                        <Label for="createdDate">
                            Talep Tarihi
                        </Label>

                        <DateInput
                            name="createdDate"
                            onChange={this.__handleChange}
                            disabled={true}
                            value={this.state.serviceRequest.createdDate}/>
                    </div>

                    <AvGroup>
                        <Label for="status">
                            Durum
                        </Label>
                        <AvField type="select" className="form-control" name="status" disabled={true}
                                 validate={{
                                     required: {
                                         value: true,
                                         errorMessage: 'Bu alanın doldurulması zorunludur.'
                                     }
                                 }}
                                 value={this.state.serviceRequest.status}
                        >
                            {SERVICE_REQUEST_STATUS.map(element => (
                                <option value={element.value} key={element.value}>
                                    {element.name}
                                </option>
                            ))}
                        </AvField>
                    </AvGroup>
                </Col>
                <Col md="4">
                    <AvGroup>
                        <Label for="teamId">
                            Atanan Ekip
                        </Label>
                        <AvField type="text" className="form-control" name="teamId" disabled={true}
                                 value={this.state.serviceRequest.teamId != null ? this.state.serviceRequest.teamId.name : ''}/>{' '}
                    </AvGroup>

                    <div className="position-relative form-group">
                        <Label for="lastModifiedDate">
                            Güncelleme Tarihi
                        </Label>

                        <DateInput
                            name="lastModifiedDate"
                            onChange={this.__handleChange}
                            disabled={true}
                            value={this.state.serviceRequest.lastModifiedDate}/>
                    </div>

                    <AvGroup>
                        <Label for="statusReason">
                            Durum Nedeni
                        </Label>
                        <AvField type="select" className="form-control" name="statusReason" disabled={true}
                                 value={this.state.serviceRequest.statusReason}
                        >
                            <option value='' key=''/>
                            {SERVICE_REQUEST_STATUS_REASON.map(element => (
                                <option value={element.value} key={element.value}>
                                    {element.name}
                                </option>
                            ))}
                        </AvField>
                    </AvGroup>
                </Col>
                <Col md="4">
                    <div className="position-relative form-group">
                        <Label for="assignmentDate">
                            Atama Tarihi
                        </Label>

                        <DateInput
                            name="assignmentDate"
                            onChange={this.__handleChange}
                            disabled={true}
                            value={this.state.serviceRequest.assignmentDate}/>
                    </div>

                    {this.state.serviceRequest.closureDate &&
                    <div className="position-relative form-group">
                        <Label for="closureDate">
                            Kapanma Tarihi
                        </Label>

                        <DateInput
                            name="closureDate"
                            onChange={this.__handleChange}
                            disabled={true}
                            value={this.state.serviceRequest.closureDate}/>
                    </div>}
                </Col>
            </Row>


        </Fieldset>;
    }


    getUrgencyInfo = () => {
        return <Fieldset legend="Öncelik Bilgileri">


            <AvGroup>
                <Label for="urgency">
                    Aciliyet
                </Label>
                <AvField type="select" className="form-control" name="urgency"
                         validate={{
                             required: {
                                 value: true,
                                 errorMessage: 'Bu alanın doldurulması zorunludur.'
                             }
                         }}
                         value={this.state.serviceRequest.urgency}
                         disabled={GeneralUtils.incidentButtonDisabled(this.state.pageMode, 'urgency')}
                         onChange={this.__handleChangeUrgencyAndImpact}>
                    {URGENCY_TYPE.map(element => (
                        <option value={element.value} key={element.value}>
                            {element.name}
                        </option>
                    ))}
                </AvField>
            </AvGroup>


            <AvGroup>
                <Label for="impact">
                    Etki
                </Label>
                <AvField type="select" className="form-control" name="impact"
                         validate={{
                             required: {
                                 value: true,
                                 errorMessage: 'Bu alanın doldurulması zorunludur.'
                             }
                         }}
                         value={this.state.serviceRequest.impact}
                         disabled={GeneralUtils.incidentButtonDisabled(this.state.pageMode, 'impact')}
                         onChange={this.__handleChangeUrgencyAndImpact}>
                    {IMPACT_TYPE.map(element => (
                        <option value={element.value} key={element.value}>
                            {element.name}
                        </option>
                    ))}
                </AvField>
            </AvGroup>


            <AvGroup>
                <Label for="priority">
                    Öncelik
                </Label>
                <AvField type="select" className="form-control" name="priority"
                         disabled={true}
                         validate={{
                             required: {
                                 value: true,
                                 errorMessage: 'Bu alanın doldurulması zorunludur.'
                             }
                         }}

                         value={this.state.serviceRequest.priority} onChange={this.__handleChange}>
                    {PRIORITY_TYPE.map(element => (
                        <option value={element.value} key={element.value}>
                            {element.name}
                        </option>
                    ))}
                </AvField>
            </AvGroup>


        </Fieldset>;
    }

    getContactInfo = () => {
        return <Fieldset legend="İletişim Bilgileri">

            <ContactGroup
                data={this.state.reportedByData}
                value={this.state.reportedBy}
                disabled={true}
                label="Raporlayan"/>

            <ContactGroup
                data={this.state.requestedByData}
                value={this.state.requestedBy}
                dataTable={{
                    dataURL : "contact/findContactsInOrganizationPlusGuestAndReportedBy",
                    parameter: this.state.serviceRequest.reportedBy +'/' +this.state.serviceRequest.organizationId,
                    fields: ContactColumnForDialog.fields
                }}
                autoRefrestData={true}
                onDataSelect={this.onRequestedBySelect}
                disabled={GeneralUtils.incidentButtonDisabled(this.state.pageMode, 'requestedBy')}
                label="Talep Sahibi [Yetkili Kişi]"/>

            {/*{!GeneralUtils.incidentButtonDisabled(this.state.pageMode, 'addButton') &&*/}
            {/*<div className="position-relative form-group">*/}
            {/*<Label>Kontak Ekle</Label>*/}
            {/*<Button color="primary" className="pi pi-plus selectTableButton" size="sm" onClick={this.addNewContact}/>*/}
            {/*</div>}*/}


            <AvGroup>
                <Label for="emailNotification">
                    Geri Bildirim İsteniyor mu?
                    <span style={{color: 'red'}}> (*)</span>
                </Label>
                <AvField type="select" className="form-control" name="emailNotification"
                         validate={{
                             required: {
                                 value: true,
                                 errorMessage: 'Bu alanın doldurulması zorunludur.'
                             }
                         }}
                         value={this.state.serviceRequest.emailNotification}
                         disabled={true}
                         onChange={this.__handleChangeEmailNotification}>
                    {YESNO.map(element => (
                        <option value={element.value} key={element.value}>
                            {element.name}
                        </option>
                    ))}
                </AvField>
            </AvGroup>


            <AvGroup>
                <Label for="contactGivenNames">
                    Ad
                    <span style={{color: 'red'}}> (*)</span>
                </Label>
                <AvField
                    type="text"
                    className="form-control"
                    name="contactGivenNames"
                    disabled={this.state.emailNotificationDisabled || GeneralUtils.incidentButtonDisabled(this.state.pageMode, 'contactGivenNames')}
                    validate={{
                        maxLength: {
                            value: 255,
                            errorMessage: "Bu alana en fazla 255 karakter girebilirsiniz."
                        }
                    }}
                    value={this.state.serviceRequest.contactGivenNames}
                    onChange={this.__handleChange}/>
                <AvFeedback>This field cannot be longer than 50 characters.</AvFeedback>
            </AvGroup>
            <AvGroup>
                <Label for="contactFamilyNames">
                    Soyad
                    <span style={{color: 'red'}}> (*)</span>
                </Label>
                <AvField
                    type="text"
                    className="form-control"
                    disabled={this.state.emailNotificationDisabled || GeneralUtils.incidentButtonDisabled(this.state.pageMode, 'contactFamilyNames')}
                    name="contactFamilyNames"
                    validate={{
                        maxLength: {
                            value: 255,
                            errorMessage: "Bu alana en fazla 255 karakter girebilirsiniz."
                        }
                    }}
                    value={this.state.serviceRequest.contactFamilyNames}
                    onChange={this.__handleChange}/>
            </AvGroup>


            <AvGroup>
                <Label for="contactEmail">
                    E-posta
                    <span style={{color: 'red'}}> (*)</span>
                </Label>
                <AvField
                    name="contactEmail"
                    type="email"
                    disabled={this.state.emailNotificationDisabled || GeneralUtils.incidentButtonDisabled(this.state.pageMode, 'contactEmail')}
                    validate={{
                        minLength: {
                            "value": 5,
                            "errorMessage": "Geçersiz E-posta Adresi."
                        },
                        maxLength: {
                            value: 50,
                            errorMessage: "Bu alana en fazla 50 karakter girebilirsiniz."
                        }
                    }}
                    value={this.state.serviceRequest.contactEmail} onChange={this.__handleChange}/>
            </AvGroup>


            <AvGroup>
                <Label for="contactPhone">
                    Telefon
                    <span style={{color: 'red'}}> (*)</span>
                </Label>
                <PhoneField kind={'any'}
                            disabled={this.state.phoneFieldDisabled || GeneralUtils.incidentButtonDisabled(this.state.pageMode, 'contactPhone')}
                            name="contactPhone" errorMessage="Geçersiz Numara Girişi"
                            value={this.state.serviceRequest.contactPhone}
                            onChange={this.__handleChange}
                            validate={ this.state.phoneFieldDisabled  ? {}: {
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                }
                            }}
                />
            </AvGroup>

        </Fieldset>;
    }

    getServiceRequestInfo = () => {
        return <Fieldset legend="Hizmet Talebi Bilgileri">
            <AvGroup>
                <Label for="serviceCategoryId">
                    Kategori
                </Label>
                <AvField type="select" className="form-control" name="serviceCategoryId"
                         value={this.state.serviceRequest.serviceCategoryId}
                         disabled={true}
                         onChange={this.__handleChangeServiceCategory}>
                    {this.state.serviceCategoryList.map(element => (
                        <option value={element.id} key={element.id}>
                            {element.name}
                        </option>
                    ))}
                </AvField>
            </AvGroup>


            <AvGroup>
                <Label for="serviceSubcategoryId">
                    Alt Kategori
                </Label>
                <AvField type="select" className="form-control" name="serviceSubcategoryId"
                         value={this.state.serviceRequest.serviceSubcategoryId}
                         disabled={true}
                         onChange={this.__handleChangeServiceSubcategory}>
                    {this.state.serviceSubcategoryList.map(element => (
                        <option value={element.id} key={element.id}>
                            {element.name}
                        </option>
                    ))}
                </AvField>
            </AvGroup>

            <Fieldset
                legend={(this.state.serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CONNECT || this.state.serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CHANGE_TITLE)
                    ? (this.state.serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CHANGE_TITLE ? "Organizasyon" :"Organizasyon, Lokasyon") : "Hizmet Numarası, Organizasyon, Lokasyon"}>
                {(this.state.serviceRequest.serviceSubcategoryId != SERVICE_SUBCATEGORY.TTVPN_CONNECT && this.state.serviceRequest.serviceSubcategoryId != SERVICE_SUBCATEGORY.TTVPN_CHANGE_TITLE) &&
                <BbProductGroup
                    value={this.state.bbProduct}
                    data={this.state.bbProductObject}
                    onDataSelect={this.onBbProductSelect}
                    disabled={true}
                    dataTable={{
                        dataURL : "bbProduct/findByContactIdAndStatus",
                        parameter: GeneralUtils.getContactId() + '/'+ GeneralUtils.getAssetStatus(this.state.serviceRequest.serviceSubcategoryId),
                        fields: BbProductColumnForGroup.fields
                    }}/>}
                <OrganizationGroup
                    value={this.state.organization}
                    data={this.state.organizationObject}
                    onDataSelect={this.onOrganizationSelect}
                    dataTable={{
                        dataURL: "hsmOrganization/findByContactId",
                        parameter:  GeneralUtils.getContactId(),
                        fields: HsmOrganizationColumnForDialog.fields
                    }}
                    disabled={true}
                />
                {this.state.serviceRequest.serviceSubcategoryId != SERVICE_SUBCATEGORY.TTVPN_CHANGE_TITLE &&
                <LocationGroup value={this.state.serviceRequest.locationId}
                               optionList={this.state.locationList}
                               disabled={true}
                               onChange={this.__handleChangeLocation}/>}
            </Fieldset>

            {(this.state.serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CONNECT) &&
            <div className="position-relative form-group">
                <Row>
                    <Col>
                        <Label for="servedLocationList">
                            Hizmet Verilecek Ek Lokasyonlar
                        </Label>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <MultiSelect optionLabel="name" dataKey="id" value={this.state.servedLocationList}
                                     options={this.state.locationListForMultiple}
                                     onChange={(e) => {
                                         this.setState({servedLocationList: e.value})
                                     }} filter={true}
                                     disabled={GeneralUtils.incidentButtonDisabled(this.state.pageMode, 'servedLocationList')}
                                     selectedItemTemplate={this.servedLocationListTemplate} selectedItemsLabel="{0} Kayıt Seçildi"/>
                    </Col>
                </Row>
            </div>
            }

            {(this.state.serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CONNECT_BLOCK || this.state.serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CANCEL
                || this.state.serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_DISCONNECT_BLOCK) &&
            <div className="position-relative form-group">
                <Row>
                    <Col>
                        <Label for="servedLocationList">
                            {(this.state.serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_DISCONNECT_BLOCK
                            || this.state.serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CANCEL) ? 'Kaldırılacak Lokasyonlar' : 'Eklenecek Lokasyonlar'}
                            {this.state.serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CONNECT_BLOCK && <span style={{color: 'red'}}> (*)</span>}
                        </Label>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <MultiSelect optionLabel="name" dataKey="id" value={this.state.servedLocationList}
                                     options={this.state.locationListForMultiple}
                                     onChange={(e) => {
                                         console.log(e.value);
                                         this.setState({servedLocationList: e.value})
                                     }} filter={true}
                                     disabled={GeneralUtils.incidentButtonDisabled(this.state.pageMode, 'servedLocationList') || this.state.serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CANCEL}
                                     selectedItemTemplate={this.servedLocationListTemplate}/>
                        {this.state.serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CONNECT_BLOCK&&this.state.pageMode === PAGE_MODE.CREATE && GeneralUtils.checkPrivilege("Location", 'Create') &&
                        <Button color="primary"
                                onClick={()=> {
                                    this.props.createNewLocation();
                                }
                                }
                                className={"pi pi-plus selectTableButtonForBlock"}
                                size="sm"/>}
                    </Col>
                </Row>
            </div>
            }

        </Fieldset>;
    }

    servedLocationListTemplate = (option) => {
        if (option) {
            return (
                <div className="my-multiselected-item-token">
                    <span>{option.code+' - '+option.name}</span>
                </div>
            );
        } else {
            return <span>&nbsp;</span>
        }
    }

    addNewContact = () => {
        this.props.openContactDialog();
    }


    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let serviceRequest = this.state.serviceRequest;
        serviceRequest[e.target.name] = value;
        this.setState({serviceRequest: serviceRequest});
    }

    __handleChangeServiceCategory = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let serviceRequest = this.state.serviceRequest;
        serviceRequest[e.target.name] = value;
        serviceRequest.serviceSubcategoryId = null;
        serviceRequest.organizationId = null;
        serviceRequest.locationId = null;
        this.setState({
            serviceRequest: serviceRequest,
            organizationObject: null,
            organization: null,
            locationList: [],
            bbProduct: null,
            bbProductObject: null
        });
        if (this.props.clearAsset) {
            this.props.clearAsset();
        }
    }

    __handleChangeServiceSubcategory = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let serviceRequest = this.state.serviceRequest;
        serviceRequest[e.target.name] = value;
        serviceRequest.organizationId = null;
        serviceRequest.locationId = null;
        for (var i = 0; i < this.state.serviceSubcategoryList.length; i++) {
            if (this.state.serviceSubcategoryList[i].id == value) {
                serviceRequest.title = this.state.serviceSubcategoryList[i].name;
            }

        }
        this.setState({
            serviceRequest: serviceRequest,
            organizationObject: null,
            organization: null,
            locationList: [],
            bbProduct: null,
            bbProductObject: null
        });
        if (this.props.clearAsset) {
            this.props.clearAsset();
        }
        if (this.props.serviceSubcategoryId) {
            this.props.serviceSubcategoryId(value);
        }

    }

    __handleChangeLocation = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let serviceRequest = this.state.serviceRequest;
        serviceRequest[e.target.name] = value;
        this.setState({serviceRequest: serviceRequest,locationListForMultiple:[]});
        if (this.props.organizationId) {
            this.props.organizationId(this.state.serviceRequest.organizationId);
        }
        if (this.props.locationId) {
            this.props.locationId(value);
        }
        if (!GeneralUtils.isNullOrEmpty(value)) {
            if (this.state.serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CONNECT || this.state.serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CONNECT_BLOCK) {
                this.doesHaveBbProductForConnect(this.state.serviceRequest.organizationId, value);
                this.findNotInBbProductByOrganizationId(this.state.serviceRequest.organizationId, value);
            } else if (this.state.serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_DISCONNECT_BLOCK || this.state.serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CANCEL) {
                this.findServedLocationByOrgIdAndLocId(this.state.serviceRequest.organizationId, value);
            }

            if (this.state.serviceRequest.serviceSubcategoryId != SERVICE_SUBCATEGORY.TTVPN_CONNECT) {
                this.doesHaveBbProduct(this.state.serviceRequest.organizationId, value);
            }
        }
    }


    __handleChangeUrgencyAndImpact = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let serviceRequest = this.state.serviceRequest;
        serviceRequest[e.target.name] = value;
        serviceRequest.priority = GeneralUtils.findPriority(serviceRequest.urgency, serviceRequest.impact);
        this.setState({serviceRequest: serviceRequest});
    }


    __handleChangeEmailNotification = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let serviceRequest = this.state.serviceRequest;
        serviceRequest[e.target.name] = value;

        let reporterLocal = GeneralUtils.getContact();
        let reporterLocalGivenNameFamilyName = reporterLocal.givenNames + ' ' + reporterLocal.familyName;
        let reporterChanged = this.state.requestedBy;

        if (reporterLocalGivenNameFamilyName == reporterChanged && value == 1) {
            serviceRequest.contactEmail = reporterLocal.email;
            serviceRequest.contactFamilyNames = reporterLocal.familyName;
            serviceRequest.contactGivenNames = reporterLocal.givenNames;
            serviceRequest.contactPhone = reporterLocal.mobilePhone;
            this.setState({serviceRequest: serviceRequest});
        } else if (value == 1) {
            serviceRequest.contactEmail = serviceRequest.contactEmailTemp;
            serviceRequest.contactFamilyNames = serviceRequest.contactFamilyNamesTemp;
            serviceRequest.contactGivenNames = serviceRequest.contactGivenNamesTemp;
            serviceRequest.contactPhone = serviceRequest.contactPhoneTemp;
            this.setState({serviceRequest: serviceRequest,phoneFieldDisabled:false});
        } else {
            serviceRequest.contactEmail = '';
            serviceRequest.contactFamilyNames = '';
            serviceRequest.contactGivenNames = '';
            serviceRequest.contactPhone = '';
            this.setState({serviceRequest: serviceRequest,phoneFieldDisabled:true});
        }
        this.setState({serviceRequest: serviceRequest});
    }

    onBbProductSelect = (data) => {
        this.clearBbProduct();
        this.businessRuleService.checkSR(this.state.serviceRequest.serviceSubcategoryId, ASSET_CATEGORY.BROADBAND_PRODUCT, data.id).then(response => {
            if (response === true) {
                let serviceRequest = this.state.serviceRequest;
                serviceRequest.organizationId = data.organizationId.id;
                serviceRequest.locationId = data.locationId.id;
                let organization = data.organizationId.code + ' - ' + data.organizationId.name;
                this.setOrgAndLoc(data.organizationId.id);
                this.setState({
                    serviceRequest: serviceRequest,
                    organization: organization,
                    locationHidden: true,
                    organizationObject: data.organizationId,
                    bbProduct: data.name,
                    bbProductObject: data
                });
                if (this.props.addAsset) {
                    this.props.addAsset(data);
                }
                if (this.props.organizationId) {
                    this.props.organizationId(serviceRequest.organizationId);
                }
                if (this.props.locationId) {
                    this.props.locationId(serviceRequest.locationId);
                }

                if (!GeneralUtils.isNullOrEmpty(serviceRequest.locationId)) {
                    if (this.state.serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CONNECT || this.state.serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CONNECT_BLOCK) {
                        this.findNotInBbProductByOrganizationId(serviceRequest.organizationId, serviceRequest.locationId);
                    } else if (this.state.serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_DISCONNECT_BLOCK || this.state.serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CANCEL) {
                        this.findServedLocationByOrgIdAndLocId(serviceRequest.organizationId, serviceRequest.locationId);
                    }

                }

                if (this.state.serviceRequest.serviceSubcategoryId != SERVICE_SUBCATEGORY.TTVPN_CHANGE_TITLE && this.state.serviceRequest.serviceSubcategoryId != SERVICE_SUBCATEGORY.TTVPN_BILL_DISPUTE) {
                    this.doesHaveBillingAccount(serviceRequest.organizationId);
                }

            }
        });
    }

    clearBbProduct = () => {
        let serviceRequest = this.state.serviceRequest;
        serviceRequest.organizationId = null;
        serviceRequest.locationId = null;
        this.setState({
            serviceRequest: serviceRequest,
            organization: null,
            organizationObject: null,
            locationList: [],
            bbProduct: null,
            bbProductObject: null
        });
        if (this.props.clearAsset) {
            this.props.clearAsset();
        }
        if (this.props.clearContact) {
            this.props.clearContact();
        }
    }


    onOrganizationSelect = (data) => {
        if(this.state.serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CHANGE_TITLE){
            if (this.props.organizationId) {
                this.props.organizationId(data.id);
            }
            this.clearBbProduct();
            this.bbProductService.findAllByOrganizationId(data.id).then(response =>{
                if (!GeneralUtils.isNullOrEmpty(response)) {
                    if (this.props.addAssetList) {
                        this.props.addAssetList(response);
                    }
                }
            });
        }
        let serviceRequest = this.state.serviceRequest;
        serviceRequest.organizationId = data.id;
        serviceRequest.locationId = null;
        let organization = data.code + ' - ' + data.name;
        if(this.state.serviceRequest.serviceSubcategoryId != SERVICE_SUBCATEGORY.TTVPN_CHANGE_TITLE) {
            this.setOrgAndLoc(data.id);
        }
        this.setState({
            serviceRequest: serviceRequest,
            organizationObject: data,
            organization: organization,
            organizationCode: data.code,
            locationHidden: false
        });
        if (this.state.serviceRequest.serviceSubcategoryId != SERVICE_SUBCATEGORY.TTVPN_CHANGE_TITLE && this.state.serviceRequest.serviceSubcategoryId != SERVICE_SUBCATEGORY.TTVPN_BILL_DISPUTE) {
            this.doesHaveBillingAccount(data.id);
        }
        /*  this.getDefaultContact(data);*/
    }

    /*
    getDefaultContact = (data) => {
        let contact;
        this.contactService.findByOrgIdAndCityIdAndRolId(data.id, data.cityId.id, ILKO_ROL_ID).then(response => {
            if (!GeneralUtils.isNullOrEmpty(response)) {
                contact=response;
                if (this.props.addContact) {
                    this.props.addContact(contact);
                }
            }
        });
    }
*/

    onRequestedBySelect = (data) => {
        let serviceRequest = this.state.serviceRequest;
        serviceRequest.requestedBy = data.id;
        let requestedBy = data.givenNames + ' ' + data.familyName;
        serviceRequest.contactGivenNamesTemp = data.givenNames;
        serviceRequest.contactFamilyNamesTemp = data.familyName;
        serviceRequest.contactEmailTemp = data.email;
        serviceRequest.contactPhoneTemp = data.mobilePhone;

        let phoneFieldDisabled = false;
        let emailNotificationDisabled=true;

        if (data.id == GUEST_CONTACT_ID){
            serviceRequest.contactGivenNames = "";
            serviceRequest.contactFamilyNames = "";
            serviceRequest.contactEmail = "";
            serviceRequest.contactPhone = "";
            phoneFieldDisabled = false;
            emailNotificationDisabled = false;
        }else if (serviceRequest.emailNotification == 1) {
            serviceRequest.contactGivenNames = data.givenNames;
            serviceRequest.contactFamilyNames = data.familyName;
            serviceRequest.contactEmail = data.email;
            serviceRequest.contactPhone = data.mobilePhone;
            emailNotificationDisabled = true;
            phoneFieldDisabled = false;
        }


        this.setState({
            serviceRequest: serviceRequest,
            requestedByData: data,
            requestedBy: requestedBy,
            locationHidden: false,
            phoneFieldDisabled : phoneFieldDisabled,
            emailNotificationDisabled: emailNotificationDisabled
        });
    }


    setOrgAndLoc = (orgId) => {
        this.locationService.findByOrganizationId(orgId).then(response => {
                if (!GeneralUtils.isNullOrEmpty(response)) {
                    this.setState({locationList: response});
                }
            }
        )
    }

    findNotInBbProductByOrganizationId = (orgId, locId) => {
        this.locationService.findNotInBbProductByOrganizationId(orgId, locId).then(response => {
                if (!GeneralUtils.isNullOrEmpty(response)) {
                    this.setState({locationListForMultiple: response});
                }
            }
        )
    }

    findNotInBbProductByOnlyOrganizationId = (orgId) => {
        this.locationService.findNotInBbProductByOnlyOrganizationId(orgId).then(response => {
                if (!GeneralUtils.isNullOrEmpty(response)) {
                    this.setState({locationListForMultiple: response});
                }
            }
        )
    }

    findServedLocationByOrgIdAndLocId = (orgId, locId) => {
        this.locationService.findServedLocationByOrgIdAndLocId(orgId, locId).then(response => {
                if (!GeneralUtils.isNullOrEmpty(response)) {
                    this.setState({locationListForMultiple: response});
                    if(this.state.serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CANCEL){
                        this.setState({servedLocationList: response});
                    }
                }
            }
        )
    }

    doesHaveBbProduct = (organizationId, locationId) => {
        if (GeneralUtils.isNullOrEmpty(organizationId) || GeneralUtils.isNullOrEmpty(locationId)) {
            return;
        }
        this.bbProductService.findByOrganizationIdAndLocationIdAndStatus(organizationId, locationId, GeneralUtils.getAssetStatus(this.state.serviceRequest.serviceSubcategoryId)).then(response => {
            if (GeneralUtils.isNullOrEmpty(response)) {
                // if(this.props.clearAsset){
                //     this.props.clearAsset();
                // }
                let serviceRequest = this.state.serviceRequest;
                serviceRequest.locationId = null;
                this.setState({serviceRequest: serviceRequest});
                return HermesSweetAlert.warn("Seçmiş olduğunuz organizasyon ve lokasyon üzerinde talebinize uygun bir ürün/hizmet yoktur!");
            }
            // if (this.props.addAsset) {
            //     this.props.addAsset(response);
            // }
        });
    }

    doesHaveBbProductForConnect = (organizationId, locationId) => {
        if (GeneralUtils.isNullOrEmpty(organizationId) || GeneralUtils.isNullOrEmpty(locationId)) {
            return;
        }
        this.bbProductService.findByOrganizationIdAndLocationIdAndStatus(organizationId, locationId, ['ACTIVE', 'INSTALLED']).then(response => {
            if (!GeneralUtils.isNullOrEmpty(response)) {
                // if(this.props.clearAsset){
                //     this.props.clearAsset();
                // }
                let serviceRequest = this.state.serviceRequest;
                serviceRequest.locationId = null;
                this.setState({serviceRequest: serviceRequest});
                return HermesSweetAlert.warn("Seçmiş olduğunuz organizasyon ve lokasyon üzerinde bir ürün/hizmet vardır!");
            }
            // if (this.props.addAsset) {
            //     this.props.addAsset(response);
            // }
        });
    }

    //KURAL 1
    doesHaveBillingAccount = (organizationId) => {
        this.businessRuleService.doesHaveBillingAccount(organizationId).then(response => {
            if (!response) {
                let serviceRequest = this.state.serviceRequest;
                serviceRequest.organizationId = null;
                this.setState({
                    serviceRequest: serviceRequest,
                    organizationObject: null,
                    organization: null,
                    locationList: [],
                    locationHidden: true,
                    organizationCode: null
                });
                return HermesSweetAlert.warn("Seçmiş olduğunuz organizasyona bağlı fatura hesabı yoktur!");
            }
        });
    }

    assigneeToMe = (response) => {
        let serviceRequest = this.state.serviceRequest;
        serviceRequest.assignedTo = response.assignedTo;
        serviceRequest.lastModifiedDate = response.lastModifiedDate;
        this.setState({serviceRequest: serviceRequest});
    }

    setData = (data, pageMode) => {
        this.clearData();
        let requestedBy = data.requestedBy ? data.requestedBy.givenNames + ' ' + data.requestedBy.familyName : null;
        let reportedBy = data.reportedBy ? data.reportedBy.givenNames + ' ' + data.reportedBy.familyName : null;
        let resolvedBy = data.resolvedBy ? data.resolvedBy.givenNames + ' ' + data.resolvedBy.familyName : null;

        let organization = data.organizationId ? (data.organizationId.code + ' - ' + data.organizationId.name) : null;
        let organizationCode = data.organizationId ? data.organizationId.code : null;
        let organizationObject = data.organizationId;
        this.setOrgAndLoc(data.organizationId.id);
        data.organizationId = data.organizationId ? data.organizationId.id : null;
        data.locationId = data.locationId ? data.locationId.id : null;
        data.serviceCategoryId = data.serviceCategoryId ? data.serviceCategoryId.id : null;
        data.serviceSubcategoryId = data.serviceSubcategoryId ? data.serviceSubcategoryId.id : null;
        this.setState({
            serviceRequest: data,
            pageMode: pageMode,
            requestedByData: data.requestedBy,
            requestedBy: requestedBy,
            reportedByData: data.reportedBy,
            reportedBy: reportedBy,
            resolvedBy: resolvedBy,
            organizationObject: organizationObject,
            organization: organization,
            organizationCode: organizationCode,
            displayContent: true
        });
        this.getServedLocationList(data);
        this.bbProductService.findByRequestTypeAndRequestId(REQUEST_TYPE.SERVICE_REQUEST, data.id).then(response => {
            if (!GeneralUtils.isNullOrEmpty(response)) {
                response.assetCategory = ASSET_CATEGORY.BROADBAND_PRODUCT;
                this.setState({bbProduct: response.name, bbProductObject: response});
            }
        });
        this.serviceSubategoryService.findServiceRequestSubCategories(this.state.serviceRequest.serviceCategoryId).then(response => {
            let serviceSubategoryList = this.SetSubcategoryWithoutAccess(response);
            this.setState({serviceSubcategoryList: serviceSubategoryList});
        });
        if (data.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CONNECT) {
            this.requestSubnetService.findByRequestTypeAndRequestId(REQUEST_TYPE.SERVICE_REQUEST, data.id).then((response) => {
                this.setState({hsmSubnetList: response ? response : []});
            });
        }
    }

    getServedLocationList = (data) => {
        if (this.state.serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CONNECT || this.state.serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CONNECT_BLOCK
            || this.state.serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_DISCONNECT_BLOCK ||  this.state.serviceRequest.serviceSubcategoryId == SERVICE_SUBCATEGORY.TTVPN_CANCEL) {
            this.requestLocationService.findByRequestTypeAndRequestId(REQUEST_TYPE.SERVICE_REQUEST, data.id).then((response) => {
                if (response) {
                    let servedLocationList = [];
                    response.forEach((element) => {
                        servedLocationList.push(element.locationId);
                    });
                    this.setState({servedLocationList: servedLocationList});
                }
            });
        }
    }

    getData = () => {
        return this.state.serviceRequest;
    }

    getDataExt = () => {
        return this.refServiceRequestNewExt.getData();
    }

    getDataServedLocationList = () => {
        return this.state.servedLocationList;
    }

    clearData = () => {
        this.setState({
            serviceRequest: Object.assign({}, defaultServiceRequest),
            servedLocationList: [],
            bbProduct: null,
            bbProductObject: null,
            requestedByData: GeneralUtils.getContact(),
            reportedByData: GeneralUtils.getContact(),
        });
    }

    setPageMode = (pageMode) => {
        this.setState({pageMode: pageMode});
    }

    setId = (id) => {
        let serviceRequest = this.state.serviceRequest;
        serviceRequest.id = id;
        this.setState({serviceRequest: serviceRequest});
    }

    checkSubcategoryAccess = (serviceSubcategoryList) => {
        let subcategoryList = [];
        serviceSubcategoryList.forEach((element) => {
            if (GeneralUtils.checkPrivilege('SubcategoryAccess', new String(element.id)) && (element.status=='A') ) {
                subcategoryList.push(element);
            }
        });
        return subcategoryList;
    }

    SetSubcategoryWithoutAccess = (serviceSubcategoryList) => {
        let subcategoryList = [];
        serviceSubcategoryList.forEach((element) => {
            subcategoryList.push(element);
        });
        return subcategoryList;
    }

    setVersion = (version) => {
        let serviceRequest = this.state.serviceRequest;
        serviceRequest.version= version;
        this.setState({serviceRequest: serviceRequest})
    }

    refresh=(location)=>{
            if(!GeneralUtils.__isValueEmpty(this.state.serviceRequest.organizationId)){
                this.setOrgAndLoc(this.state.serviceRequest.organizationId);
                this.findNotInBbProductByOnlyOrganizationId(this.state.serviceRequest.organizationId);
            }
            if(!GeneralUtils.__isValueEmpty(this.refServiceRequestNewExt)){
                this.refServiceRequestNewExt.refresh(location);
            }
    }

    getAddNewLocationDialog = () => {
        let result = (
            this.state.addNewLocationHidden &&
            <Dialog style={{width: '70%'}}
                    visible={this.state.addNewLocationHidden}
                    header="Lokasyon Bilgileri"
                    onHide={() => this.setState({addNewLocationHidden: false})}>
                <Location refresh={(location)=>
                {
                    this.findNotInBbProductByOnlyOrganizationId(this.state.serviceRequest.organizationId);
                }
                }/>
            </Dialog>
        );
        return result;
    }

    getDataHsmSubnetList =()=>{
        let hsmSubnetList = this.refHsmSubnet.getData();
        hsmSubnetList.forEach((element) => {
            element.organizationId = this.state.serviceRequest.organizationId;
        });
        return hsmSubnetList;
    }

    setDataHsmSubnetList = (data) => {
        this.setState({hsmSubnetList: data});
    }

    getDataHsmSubnetRemovedList =()=>{
        return this.refHsmSubnet.getDataRemoved();
    }

}
