import React from "react";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import AvField from "./AvField";
import {Input} from "reactstrap";
import InputMask from "react-input-mask";
import {PAGE_MODE} from "../../utils/Constants";


const SEPERATOR = {
    COLONS: ":",
    HYPHEN: "-",
    BLANK: " "
}

export default class AvFieldForMac extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mask: "",
            maskChar: "_",
            seperator: ""
        }

    }


    render() {
        return (
            <div>
                <AvField
                    {...this.props}
                    mask={this.state.mask}
                    maskChar={this.state.maskChar}
                    formatChars={{
                        '9': '[0-9]|[A-F]|[a-f]'
                    }}
                    tag={[Input, InputMask]}
                    placeHolder="XX:XX:XX:XX:XX:XX"
                    onChange={this.onChange}
                />
            </div>
        );
    }

    getValue = () => {
        let value = this.props.value;
        let seperator = this.state.seperator;

        if (seperator === SEPERATOR['COLONS']) {
            return value.toString().toLowerCase().split(seperator).join("");
        } else if (seperator === SEPERATOR['HYPHEN']) {
            return value.toString().toLowerCase.split(seperator).join("");
        } else if (seperator === SEPERATOR['BLANK']) {
            return value.toString().toLowerCase.split(seperator).join("");
        }
        return value;
    }

    checkMacAddress = (macAddress) => {
        let regex = "^(?:[0-9A-Fa-f]{2}([-: ]?))(?:[0-9A-Fa-f]{2}\\1){4}[0-9A-Fa-f]{2}|([0-9A-Fa-f]{4}\\.){2}[0-9A-Fa-f]{4}$";
        if (macAddress.match(regex)) {
            return true;
        }
        return false;
    }

    onChange = (e) => {
        let mask = !GeneralUtils.isNullOrEmpty(this.state.mask) ? this.state.mask : "";
        let seperator = !GeneralUtils.isNullOrEmpty(this.state.seperator) ? this.state.seperator : "";

        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        if (value === "") {
            mask = "";
        } else {
            if (!GeneralUtils.isNullOrEmpty(value[2]) && value.length == 3) {
                if (value[2] === SEPERATOR['COLONS']) {
                    seperator = SEPERATOR['COLONS'];
                    mask = "99:99:99:99:99:99";
                } else if (value[2] === SEPERATOR['HYPHEN']) {
                    seperator = SEPERATOR['HYPHEN'];
                    mask = "99-99-99-99-99-99";
                } else if (value[2] === SEPERATOR['BLANK']) {
                    seperator = SEPERATOR['BLANK'];
                    mask = "99 99 99 99 99 99";
                } else {
                    if (!value.includes("_")) {
                        mask = "999999999999";
                    }
                }
            }
        }

        if (this.props.setValue) {
            this.props.setValue(value);
        }

        this.setState({
            mask, seperator
        });

    }

    setPageMode = (pageMode) => {
        if (pageMode === PAGE_MODE.CREATE) {
            this.setState({
                mask: "",
            });
        } else {
            if (pageMode === PAGE_MODE.UPDATE) {
                this.setState({
                    mask: "99:99:99:99:99:99",
                });
            }
        }
    }
}