import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class HsmNetworkInterfaceService extends Service {
    constructor() {
        super();
        this.baseURL = 'hsmNetworkInterface';
    }

    findByAssetId(assetId) {
        return fetch(SERVER_URL + this.baseURL + '/findByAssetId/' + assetId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

}