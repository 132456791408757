import React from 'react';
import {AvGroup} from "availity-reactstrap-validation";
import {Label, Row} from "reactstrap";
import AvField from "../../../shared/component/form/AvField";
import Translate from "../../../shared/language/Translate";
import {PAGE_MODE} from "../../../shared/utils/Constants";
import AssetTypeService from "../../../shared/service/AssetTypeService";
import StatusGroup from "../../../shared/component/hsmGroup/StatusGroup";
import DateInput from "../../../shared/component/form/DateInput";
import {Fieldset} from "primereact/fieldset";
import OrganizationGroup from "../../../shared/component/hsmGroup/OrganizationGroup";
import HsmOrganizationColumnForDialog
    from "../../../shared/component/dataTable/columns/HsmOrganizationColumnForDialog.json";
import LocationService from "../../../shared/service/LocationService";
import LocationGroup from "../../../shared/component/hsmGroup/LocationGroup";
import GeneralUtils from "../../../shared/utils/GeneralUtils";

const defaultHsmService = {
    assetCategory: '',
    id: '',
    assetType: '',
    status: 'A',
    installedDate: '',
    activatedDate: '',
    acceptanceDate: '',
    uninstalledDate: '',
    locationId: '',
    organizationId: '',
    quantity: ''
};
const ASSET_CATEGORY_LIST = [
    {name: 'Hizmet', value: 'SERVICE', id: 6},
    {name: 'Lisans', value: 'LICENCE', id: 7}
];

export default class HsmServiceSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hsmService: defaultHsmService,
            pageMode: null,
            assetTypeList: [],
            locationList: [],
            organization: null
        };
        this.assetTypeService = new AssetTypeService();
        this.locationService = new LocationService();
    }

    render() {
        return (
            <div className="container">

                <AvGroup>
                    <Label for="assetCategory">
                        <Translate contentKey="asset-management.assetCategory">Varlık Sınıfı </Translate>
                        <span style={{color: 'red'}}>(*)</span>
                    </Label>
                    <AvField
                        type="select"
                        // type="text"
                        className="form-control"
                        name="assetCategory"
                        value={this.state.hsmService.assetCategory}
                        validate={this.state.pageMode === PAGE_MODE.VIEW ? {} : {
                            required: {
                                value: "true",
                                errorMessage: "Bu alanın doldurulması zorunludur."
                            }
                        }}
                        onChange={this.__handleChangeAssetCategory}
                        disabled={this.state.pageMode === PAGE_MODE.VIEW}>
                        <option value='' key=''/>
                        {ASSET_CATEGORY_LIST.map(element => (
                            <option value={element.value} key={element.value}>
                                {element.name}
                            </option>
                        ))}
                    </AvField>
                </AvGroup>

                <AvGroup>
                    <Label for="assetType">
                        <Translate contentKey="asset-management.assetType">Varlık Tipi </Translate>
                        <span style={{color: 'red'}}>(*)</span>
                    </Label>
                    <AvField
                        type="select"
                        // type="text"
                        className="form-control"
                        name="assetType"
                        value={this.state.hsmService.assetType}
                        validate={this.state.pageMode === PAGE_MODE.VIEW ? {} : {
                            required: {
                                value: "true",
                                errorMessage: "Bu alanın doldurulması zorunludur."
                            }
                        }}
                        onChange={this.__handleChange}
                        disabled={this.state.pageMode === PAGE_MODE.VIEW}>
                        <option value='' key=''/>
                        {this.state.assetTypeList.map(element => (
                            <option value={element.value} key={element.value}>
                                {element.name}
                            </option>
                        ))}
                    </AvField>
                </AvGroup>

                <Fieldset legend="Organizasyon & Lokasyon Bilgileri">
                    <OrganizationGroup
                        value={this.state.organization}
                        onDataSelect={this.onOrganizationSelect}
                        dataTable={{
                            dataURL: "hsmOrganization/findFilteredOrgs",
                            fields: HsmOrganizationColumnForDialog.fields
                        }}
                        disabled={this.state.pageMode === PAGE_MODE.VIEW}
                    />
                    <LocationGroup value={this.state.hsmService.locationId}
                                   optionList={this.state.locationList}
                                   onChange={this.__handleChange}
                                   disabled={!this.state.organization}
                    />
                </Fieldset>

                <AvGroup>
                    <Label for="quantity">Adet <span style={{color: 'red'}}>(*)</span></Label>
                    <AvField
                        type="number"
                        className="form-control"
                        name="quantity"
                        value={this.state.hsmService.quantity}
                        validate={this.state.pageMode === PAGE_MODE.VIEW ? {} : {
                            required: {
                                value: "true",
                                errorMessage: "Bu alanın doldurulması zorunludur."
                            }
                        }}
                        max="999999"
                        onChange={this.__handleChange}
                    />
                </AvGroup>

                <StatusGroup value={this.state.hsmService.status} onChange={this.__handleChange}
                             disabled={this.state.pageMode === PAGE_MODE.VIEW}/>

                <div className="position-relative form-group">
                    <Label for="installedDate">
                        <Translate contentKey="asset-management.installedDate">Kurulum Tarihi</Translate>
                    </Label>
                    <DateInput
                        name="installedDate"
                        onChange={this.__handleChange}
                        value={this.state.hsmService.installedDate}
                        disabled={this.state.pageMode === PAGE_MODE.VIEW}
                    />
                </div>
                <div className="position-relative form-group">
                    <Label for="activatedDate" style={{color: "green"}}>
                        <Translate contentKey="asset-management.activatedDate">Aktivasyon Tarihi</Translate>
                    </Label>
                    <DateInput
                        name="activatedDate"
                        onChange={this.__handleChange}
                        value={this.state.hsmService.activatedDate}
                        disabled={this.state.pageMode === PAGE_MODE.VIEW}
                    />
                </div>

                <div className="position-relative form-group">
                    <Label for="acceptanceDate">
                        <Translate contentKey="asset-management.acceptanceDate">Kabul Tarihi</Translate>
                    </Label>
                    <DateInput
                        name="acceptanceDate"
                        onChange={this.__handleChange}
                        value={this.state.hsmService.acceptanceDate}
                        disabled={this.state.pageMode === PAGE_MODE.VIEW}
                    />
                </div>

                <div className="position-relative form-group">
                    <Label for="uninstalledDate" style={{color: "red"}}>
                        <Translate contentKey="asset-management.uninstalledDate">İptal Tarihi</Translate>
                    </Label>
                    <DateInput
                        name="uninstalledDate"
                        onChange={this.__handleChange}
                        value={this.state.hsmService.uninstalledDate}
                        disabled={this.state.pageMode === PAGE_MODE.VIEW}
                    />
                </div>

            </div>
        );
    }

    __handleChangeAssetCategory = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let hsmService = this.state.hsmService;
        hsmService[e.target.name] = value;
        this.setState({hsmService: hsmService});
        this.getAssetTypeList(value);
    }

    getAssetTypeList = (value) => {
        let assetCategoryId = 0;
        ASSET_CATEGORY_LIST.map(el => {
            if (el.value === value) {
                assetCategoryId = el.id;
            }
        });
        this.assetTypeService.findByAssetCategoryId(assetCategoryId).then(response => this.setState({assetTypeList: response}));
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let hsmService = this.state.hsmService;
        hsmService[e.target.name] = value;
        this.setState({hsmService: hsmService});
    }

    onOrganizationSelect = (data) => {
        let hsmService = this.state.hsmService;
        hsmService.organizationId = data.id;
        hsmService.locationId = null;
        let organization = data.code + ' - ' + data.name;
        this.setOrgAndLoc(data.id);
        this.setState({
            hsmService: hsmService,
            organization: organization
        });
    }

    setOrgAndLoc = (orgId) => {
        this.locationService.findByOrganizationId(orgId).then(response => {
                if (!GeneralUtils.isNullOrEmpty(response)) {
                    this.setState({locationList: response});
                }
            }
        )
    }

    setData = (data, pageMode) => {
        let organization = data.organizationId.code + ' - ' + data.organizationId.name;
        this.setOrgAndLoc(data.organizationId.id);
        this.getAssetTypeList(data.assetCategory);
        data.organizationId = data.organizationId ? data.organizationId.id : null;
        data.locationId = data.locationId ? data.locationId.id : null;
        this.setState({hsmService: data, pageMode: pageMode, organization: organization});
    }

    getData = () => {
        return this.state.hsmService;
    }

    clearData = () => {
        this.setState({hsmService: Object.assign({}, defaultHsmService), pageMode: null, assetTypeList: [], locationList: [], organization: null});
    }

}
