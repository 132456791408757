import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class WorkFlowModelService extends Service{
    constructor() {
        super();
        this.baseURL = 'workflowmodel';
    }

    findByCategoryIdAndSubcategoryId(categoryId, subcategoryId) {
        return fetch(SERVER_URL + this.baseURL + '/findByCategoryIdAndSubcategoryId/' + categoryId+ '/'+ subcategoryId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    getSplitButtonItems(categoryId, subcategoryId, flowId, data) {
        return fetch(SERVER_URL + this.baseURL+ '/splitbuttonitems/' +categoryId+ '/'+subcategoryId + '/'+ flowId, {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
         });

    }

    getSplitButtonItemsTeamIdIsNull(categoryId, subcategoryId, flowId) {
        return fetch(SERVER_URL + this.baseURL+ '/splitbuttonitemsTeamIdIsNul/' +categoryId+ '/'+subcategoryId + '/'+ flowId, {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });

    }

    findByCategoryAndSubcategoryAndFlowIdAndTeamIdList(categoryId, subcategoryId, flowId ,data ) {
        return fetch(SERVER_URL + this.baseURL + '/findByCategoryAndSubcategoryAndFlowIdAndTeamIdList/' + categoryId+ '/'+ subcategoryId + '/'+ flowId , {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }


}