import React from 'react';
import RequestCommentService from "../../shared/service/RequestCommentService";
import {AvForm, AvGroup} from "availity-reactstrap-validation";
import {Button, Label} from "reactstrap";
import AvField from "../../shared/component/form/AvField";
import GeneralUtils from "../../shared/utils/GeneralUtils";
import {APP_TIMESTAMP_FORMAT, convertDateTimeWithFormat, PAGE_MODE} from "../../shared/utils/Constants";
import Comment from "../../shared/component/others/comment/Comment";

export default class RequestComment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageMode: PAGE_MODE.CREATE,
            status: '',
            text: null,
            requestId: null,
            requestType: null,
            awaitingInfo: false
        };
        this.service = new RequestCommentService();
    }


    render() {
        let requestComment = (this.state.data.map((requestComment, i) => {
            return <Comment
                header={requestComment.createdBy.contactId.givenNames + ' ' + requestComment.createdBy.contactId.familyName +
                (!GeneralUtils.isNullOrEmpty(requestComment.requestCommentType) ? ' - ' + this.setNoteType(requestComment.requestCommentType) : "")}
                date={convertDateTimeWithFormat(requestComment.createdDate, APP_TIMESTAMP_FORMAT)}
                content={requestComment.text}/>;
        }));
        return (
            <div>
                <AvForm>
                    <AvGroup>
                        {this.state.awaitingInfo &&  <Label for="text">İlgili Kaydın Çözülmesi için Bilgi Beklenmektedir. Lütfen Cevap Yazınız.</Label>}
                        <AvField type="textarea" className="form-control textAreaHermes" name="text" required
                                 disabled={false}
                                 validate={{
                                     required: {
                                         value: false,
                                         errorMessage: "Bu alanın doldurulması zorunludur."
                                     },
                                     maxLength: {
                                         value: 4000,
                                         errorMessage: 'Bu alan en fazla 4000 karakterden oluşabilir.'
                                     }
                                 }}
                                 value={this.state.text} onChange={this.__handleChange}
                        />


                        <Button color="primary" onClick={this.save}>
                            {this.state.awaitingInfo ? 'Cevap Yaz' : 'Yorum Ekle'}
                        </Button>

                    </AvGroup>
                </AvForm>
                {requestComment}
            </div>
        );
    }

    __handleChange = (e) => {
        let state = this.state;
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        state[e.target.name] = value;
        this.setState(state);
    }


    save = () => {
        if (!GeneralUtils.isNullOrEmpty(this.state.requestId) && !GeneralUtils.isNullOrEmpty(this.state.text)) {
            let comment = {
                requestId: this.state.requestId,
                text: this.state.text,
                requestType: this.state.requestType,
                createdBy: {id: GeneralUtils.getUserId()},
                requestCommentType: (this.state.awaitingInfo ? 'AWAITING_INFO_RESPONSE' : null)
            }
            this.service.create(comment).then((response) => {
                this.service.findByRequestTypeAndRequestId(this.state.requestType, this.state.requestId).then(response => {
                    this.clearData();
                    this.setState({data: response});
                });
            });
        }
    }

    setData = (data, requestType, pageMode, status) => {
        this.service.findByRequestTypeAndRequestId(requestType, data.id).then(response => {
            this.setState({
                data: response,
                requestId: data.id,
                requestType: requestType,
                pageMode: pageMode,
                status: status,
                awaitingInfo: (!GeneralUtils.isNullOrEmpty(response) && (response[0].requestCommentType === 'AWAITING_INFO'))
            })
        });
    }

    clearData = () => {
        this.setState({
            data: [],
            pageMode: null,
            text: null
        });
    }

    setPageModeAndStatus = (pageMode, status) => {
        this.setState({pageMode: pageMode, status: status});
    }

    setNoteType = (requestCommentType) => {
        if (!GeneralUtils.isNullOrEmpty(requestCommentType)) {
            if (requestCommentType === 'PENDING') {
                return "[Askı Notu]"
            } else if (requestCommentType === 'CLOSED') {
                return "[Kapama Notu]"
            } else if (requestCommentType === 'REOPENED') {
                return "[Yeniden Açma Notu]"
            } else if (requestCommentType === 'REJECTED') {
                return "[Reddetme Notu]"
            } else if (requestCommentType === 'APPROVED') {
                return "[Onay/Teyit Notu]"
            } else if (requestCommentType === 'RESOLVED') {
                return "[Çözümleme Notu]"
            } else if (requestCommentType === 'CANCELLED') {
                return "[Talep İptal Notu]"
            } else if (requestCommentType === 'ESCALATED') {
                return "[Eskale Notu]"
            } else if (requestCommentType === 'AWAITING_INFO') {
                return "[Bilgi Bekleniyor Notu]"
            } else if (requestCommentType === 'AWAITING_INFO_RESPONSE') {
                return "[Bilgi Bekleniyor Cevap]"
            }else if (requestCommentType === 'HAS_BEEN_PROCESSED') {
                return "[İşleme Alındı Notu]"
            }else if (requestCommentType === 'OPEN') {
                return "[Açık Notu]"
            }
        }

    }
}
