import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class DiscoveryItemSelectableService extends Service{
    constructor() {
        super();
        this.baseURL = 'discoveryItemSelectable';
    }

}