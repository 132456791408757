import React from 'react';
import PropTypes from 'prop-types';
import ReactQuill from "react-quill";
import {AvForm, AvGroup} from "availity-reactstrap-validation";
import {Button, Label} from "reactstrap";
import Translate from "../../../shared/language/Translate";
import AvField from "../../../shared/component/form/AvField";
import Service from "../../../shared/service/Service";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import {toast} from "react-toastify";
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import {IMPORTANCE, STATUS} from "../../../shared/utils/Constants";


const defaultMailTemplate = {
    name: '',
    description: '',
    subject: '',
    content: '',
    mailFrom: '',
    status: 'A'
};
export default class MailTemplateSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: defaultMailTemplate
        };
        this.service = new Service('mailTemplate');
    }

    render() {
        return (<div>
                <AvForm onValidSubmit={this.save}>
                    <Toolbar display={true}>
                        <div className="p-toolbar-group-right">
                            <Button color="primary" type="submit" className="float-right">
                                <Translate contentKey="entity.action.save">Kaydet</Translate>
                            </Button>

                            <Button color="primary" className="float-right"
                                    onClick={this.props.closeDialog}>
                                <Translate contentKey="entity.action.save">Vazgeç</Translate>
                            </Button>
                        </div>
                    </Toolbar>
                    <AvGroup>
                        <Label for="name">
                            <Translate contentKey="mailTemplate.name">Template Adı</Translate>
                            <span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="name"
                            disabled={true}
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                },
                                minLength: {
                                    value: 1,
                                    errorMessage: 'Bu alan en az 1 karakterden oluşabilir.'
                                },
                                maxLength: {
                                    value: 50,
                                    errorMessage: 'Bu alan en fazla 50 karakterden oluşabilir.'
                                }
                            }}
                            value={this.state.data.name}
                            onChange={this.__handleChange}
                        />
                    </AvGroup>
                    <AvGroup>
                        <Label for="description">
                            <Translate contentKey="mailTemplate.description">Açıklama</Translate>
                            <span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="textarea"
                            className="form-control textAreaHermes"
                            name="name"
                            disabled={true}
                            value={this.state.data.description}
                            onChange={this.__handleChange}
                        />
                    </AvGroup>
                    <AvGroup>
                        <Label for="mailFrom">
                            <Translate contentKey="mailTemplate.mailFrom">Gönderen</Translate>
                            <span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="mailFrom"
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                },
                                minLength: {
                                    value: 1,
                                    errorMessage: 'Bu alan en az 1 karakterden oluşabilir.'
                                },
                                maxLength: {
                                    value: 256,
                                    errorMessage: 'Bu alan en fazla 256 karakterden oluşabilir.'
                                }
                            }}
                            disabled={true}
                            value={this.state.data.mailFrom}
                            onChange={this.__handleChange}
                        />
                    </AvGroup>
                    <AvGroup>
                        <Label for="mailTo">
                            <Translate contentKey="mailTemplate.mailTo">Alıcı</Translate>
                            <span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="mailTo"
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                },
                                minLength: {
                                    value: 1,
                                    errorMessage: 'Bu alan en az 1 karakterden oluşabilir.'
                                },
                                maxLength: {
                                    value: 256,
                                    errorMessage: 'Bu alan en fazla 256 karakterden oluşabilir.'
                                }
                            }}
                            value={this.state.data.mailTo}
                            onChange={this.__handleChange}
                        />
                    </AvGroup>
                    <AvGroup>
                        <Label for="cc">
                            <Translate contentKey="mailTemplate.cc">CC</Translate>

                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="cc"
                            value={this.state.data.cc}
                            onChange={this.__handleChange}
                        />
                    </AvGroup>
                    <AvGroup>
                        <Label for="bcc">
                            <Translate contentKey="mailTemplate.bcc">BCC</Translate>

                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="bcc"
                            value={this.state.data.bcc}
                            onChange={this.__handleChange}
                        />
                    </AvGroup>
                    <AvGroup>
                        <Label for="importance">
                            <Translate contentKey="mailTemplate.importance">Öncelik</Translate>
                        </Label>
                        <AvField type="select" className="form-control" name="importance"
                                 disabled={true}
                                 value={this.state.data.importance} onChange={this.__handleChange}>
                            {IMPORTANCE.map(element => (
                                <option value={element.value} key={element.value}>
                                    {element.name}
                                </option>
                            ))}
                        </AvField>
                    </AvGroup>
                    <AvGroup>
                        <Label for="status">
                            <Translate contentKey="mailTemplate.status">Durum</Translate>
                        </Label>
                        <AvField type="select" className="form-control" disabled={true} name="status"
                                 value={this.state.data.status} onChange={this.__handleChange}>
                            {STATUS.map(element => (
                                <option value={element.value} key={element.value}>
                                    {element.name}
                                </option>
                            ))}
                        </AvField>
                    </AvGroup>
                    <AvGroup>
                        <Label for="subject">
                            <Translate contentKey="mailTemplate.subject">Konu</Translate>
                            <span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="subject"
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                },
                                minLength: {
                                    value: 1,
                                    errorMessage: 'Bu alan en az 1 karakterden oluşabilir.'
                                },
                                maxLength: {
                                    value: 256,
                                    errorMessage: 'Bu alan en fazla 256 karakterden oluşabilir.'
                                }
                            }}
                            disabled={true}
                            value={this.state.data.subject}
                            onChange={this.__handleChange}
                        />
                    </AvGroup>
                    <AvGroup>
                        <Label for="content">
                            <Translate contentKey="mailTemplate.content">İçerik</Translate>
                            <span style={{color: 'red'}}> (*)</span>
                        </Label>
                        {/*<ReactQuill*/}
                        {/*    name="content"*/}
                        {/*    value={this.state.data.content}*/}
                        {/*    onChange={this.__handleChangeEditor}*/}
                        {/*    readOnly={true}*/}
                        {/*    style={{height: '320px', marginBottom: '50px'}}*/}
                        {/*/>*/}

                        <div dangerouslySetInnerHTML={{__html: this.state.data.content}}
                             onChange={this.__handleChangeEditor}/>
                    </AvGroup>
                </AvForm>
            </div>
        );
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let data = this.state.data;
        data[e.target.name] = value;
        this.setState({data: data});
    }

    __handleChangeEditor = (e) => {
        let data = this.state.data;
        data.content = e;
        this.setState({data: data});
    }

    save = () => {
        if (GeneralUtils.isNullOrEmpty(this.state.data.content)) {
            return toast.warn("Zorunlu Alan : Lütfen İçerik Giriniz!");
        }
        let notice = this.state.data;
        if (!GeneralUtils.isNullOrEmpty(this.state.data.id)) {
            this.service.update(this.state.data).then((response) => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    if (this.props.closeDialog) {
                        this.props.closeDialog();
                    }
                    if (this.props.refresh) {
                        this.props.refresh();
                    }
                }
            });
        } else {
            this.service.create(this.state.data).then((response) => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    if (this.props.closeDialog) {
                        this.props.closeDialog();
                    }
                    if (this.props.refresh) {
                        this.props.refresh();
                    }
                }
            });
        }
    }

    setData = (data) => {
        this.setState({data: data});
    }

    clearData = () => {
        this.setState({data: defaultMailTemplate})
    }

}
MailTemplateSave.propTypes = {
    closeDialog: PropTypes.func,
    refresh: PropTypes.func,
};
