import React from 'react';
import {AvGroup} from "availity-reactstrap-validation";
import {Col, Label, Row} from "reactstrap";
import AvField from "../../shared/component/form/AvField";

const defaultHsmSystemParameter = {
    id: '',
    key: '',
    value: '',
    description: ''
};
export default class HsmSystemParameterSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hsmSystemParameter: defaultHsmSystemParameter,
            pageMode: null
        };
    }

    render() {
        return (
            <Row className="justify-content-center">
                <Col>
                    <AvGroup>
                        <Label for="key">Key<span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="key"
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                },
                                minLength: {
                                    value: 1,
                                    errorMessage: 'Bu alan en az 1 karakterden oluşabilir.'
                                },
                                maxLength: {
                                    value: 100,
                                    errorMessage: 'Bu alan en fazla 100 karakterden oluşabilir.'
                                }
                            }}
                            value={this.state.hsmSystemParameter.key}
                            onChange={this.__handleChange}
                        />
                    </AvGroup>

                    <AvGroup>
                        <Label for="value">Value<span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="value"
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                },
                                minLength: {
                                    value: 1,
                                    errorMessage: 'Bu alan en az 1 karakterden oluşabilir.'
                                },
                                maxLength: {
                                    value: 4000,
                                    errorMessage: 'Bu alan en fazla 4000 karakterden oluşabilir.'
                                }
                            }}
                            value={this.state.hsmSystemParameter.value}
                            onChange={this.__handleChange}
                        />
                    </AvGroup>

                    <AvGroup>
                        <Label for="description">Açıklama</Label>
                        <AvField
                            type="textarea"
                            className="form-control textAreaHermes"
                            name="description"
                            validate={{
                                maxLength: {
                                    value: 255,
                                    errorMessage: 'Bu alan en fazla 255 karakterden oluşabilir.'
                                }
                            }}
                            value={this.state.hsmSystemParameter.description}
                            onChange={this.__handleChange}
                        />
                    </AvGroup>
                </Col>
            </Row>
        );
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let hsmSystemParameter = this.state.hsmSystemParameter;
        hsmSystemParameter[e.target.name] = value;
        this.setState({hsmSystemParameter: hsmSystemParameter});
    }

    setData = (data, pageMode) => {
        this.setState({hsmSystemParameter: data, pageMode: pageMode});
    }

    getData = () => {
        return this.state.hsmSystemParameter;
    }

    clearData = () => {
        this.setState({hsmSystemParameter: Object.assign({}, defaultHsmSystemParameter), pageMode: null});
    }

}
