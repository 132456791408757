import Service from './Service';
import GeneralUtils from "../utils/GeneralUtils";
import {SERVER_URL} from "../utils/Constants";

export default class IncidentReportService extends Service{
    constructor() {
        super();
        this.baseURL = 'incidentReport';
    }

    getIncidentStatusReportCounts() {
        return fetch(SERVER_URL + this.baseURL + "/getIncidentStatusReportCounts", {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    findIncidentReportView = () => {
        return fetch(SERVER_URL + this.baseURL + '/findIncidentReportView', {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    findIncidentReportViewByStartDateAndEndDate = (dateStart,dateEnd) => {
        return fetch(SERVER_URL + this.baseURL + '/findIncidentReportViewByStartDateAndEndDate/'+dateStart+'/'+dateEnd, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    findSumGeneralReportByStartDateAndEndDate = (dateStart,dateEnd) => {
        return fetch(SERVER_URL + this.baseURL + '/findSumGeneralReportByStartDateAndEndDate/'+dateStart+'/'+dateEnd, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }
}
