import Service from './Service';
import GeneralUtils from "../utils/GeneralUtils";
import {SERVER_URL} from "../utils/Constants";

export default class DocumentTransferPDFService extends Service {
    constructor() {
        super();
        this.baseURL = 'transferPDF';
    }

    generateTransferPDF = (data) => {
        return fetch(SERVER_URL + this.baseURL, {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")},
            body: JSON.stringify(data)
        })
            .then(response => response.blob())
            .catch(error => {
                GeneralUtils.notification(error);
            });
    }

}