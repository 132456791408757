import React from 'react';
import {Label} from 'reactstrap';
import {AvFeedback, AvGroup} from 'availity-reactstrap-validation';
import AvField from '../../../shared/component/form/AvField';
import PropTypes from 'prop-types';
import Translate from "../../../shared/language/Translate";
import GeneralUtils from '../../../shared/utils/GeneralUtils';
import ContactService from "../../../shared/service/ContactService";
import LocationService from "../../../shared/service/LocationService";
import {CONTACT_TYPE, STATUS} from "../../../shared/utils/Constants";
import {Fieldset} from "primereact/fieldset";
import ModalConfirm from "../../request/hsmDialog/ModalConfirm";
import OrganizationGroup from "../../../shared/component/hsmGroup/OrganizationGroup";
import {PhoneField} from "../../../shared/component/form/PhoneField";
import HsmOrganizationService from "../../../shared/service/HsmOrganizationService";
import AvFieldUpper from "../../../shared/component/form/AvFieldUpper";

const defaultContact = {
    id: '',
    turkishIdentityNo: null,
    name: '',
    familyName: '',
    givenNames: '',
    status: 'A',
    contactType: '',
    email: '',
    mobilePhone: '',
    businessPhone: '',
    faxNumber: '',
    workTitle: '',
    employeeNo: '',
    taxOffice: '',
    organizationId: null,
    locationId: null
};

export default class ContactView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contact: defaultContact,
            organizationList: [],
            locationList: [],
            pageMode: null,
            organization: null,
            locationHidden: true
        }
        this.service = new ContactService();
        this.locationService = new LocationService();
        this.hsmOrganizationService = new HsmOrganizationService();
    }

    render() {
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col">

                            {/*<AvGroup>*/}
                            {/*    <Label for="turkishIdentityNo">*/}
                            {/*        <Translate contentKey="contactManagement.turkishIdentityNo">TCK No</Translate>*/}
                            {/*    </Label>*/}
                            {/*    <AvField*/}
                            {/*        type="text"*/}
                            {/*        className="form-control"*/}
                            {/*        name="turkishIdentityNo"*/}
                            {/*        value={this.state.contact.turkishIdentityNo} onChange={this.__handleChange}*/}
                            {/*    disabled={true}/>*/}
                            {/*</AvGroup>*/}
                            <AvGroup>
                                <Label for="givenNames">
                                    <Translate contentKey="contactManagement.givenNames">Ad</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvFieldUpper
                                    type="text"
                                    className="form-control"
                                    name="givenNames"
                                    value={this.state.contact.givenNames} onChange={this.__handleChange}
                                    disabled={true}/>
                                <AvFeedback>This field cannot be longer than 50 characters.</AvFeedback>
                            </AvGroup>
                            <AvGroup>
                                <Label for="familyName">
                                    <Translate contentKey="contactManagement.familyName">Soyad</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvFieldUpper
                                    type="text"
                                    className="form-control"
                                    name="familyName"
                                    value={this.state.contact.familyName} onChange={this.__handleChange}
                                    disabled={true}/>
                                <AvFeedback>This field cannot be longer than 50 characters.</AvFeedback>
                            </AvGroup>
                            <AvGroup>
                                <Label for="contactType">
                                    <Translate contentKey="contactManagement.contactType">Kontak Tipi</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvField type="select" className="form-control" name="contactType"
                                         value={this.state.contact.contactType} onChange={this.__handleChange}
                                         disabled={true}>
                                    <option value='' key=''/>
                                    {CONTACT_TYPE.map(element => (
                                        <option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>
                            <AvGroup>
                                <Label for="status">
                                    <Translate contentKey="contactManagement.status">Durum</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvField type="select" className="form-control" name="status"
                                         value={this.state.contact.status} onChange={this.__handleChange}
                                         disabled={true}>
                                    {STATUS.map(element => (
                                        <option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>
                            <AvGroup>
                                <Label for="workTitle">
                                    <Translate contentKey="contactManagement.workTitle">Ünvan</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="workTitle"
                                    value={this.state.contact.workTitle} onChange={this.__handleChange}
                                    disabled={true}/>
                            </AvGroup>
                            <AvGroup>
                                <Label for="employeeNo">
                                    <Translate contentKey="contactManagement.employeeNo">Çalışan
                                        Numarası</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="employeeNo"
                                    value={this.state.contact.employeeNo} onChange={this.__handleChange}
                                    disabled={true}/>
                            </AvGroup>
                        </div>
                        <div className="col">
                            <Fieldset legend="Organizasyon & Lokasyon Bilgileri">
                                <OrganizationGroup
                                    value={this.state.organization}
                                    onDataSelect={this.onOrganizationSelect}
                                    disabled={true}
                                    validate={{}}
                                />
                                <AvGroup>
                                    <Label for="locationId">
                                        <Translate contentKey="contactManagement.locationId">Lokasyon
                                            Adı</Translate>
                                    </Label>
                                    <AvField type="select" className="form-control" name="locationId"
                                             disabled={true}
                                             value={this.state.contact.locationId} onChange={this.__handleChange}>
                                        <option value=""/>
                                        {this.state.locationList.map(element => (
                                            <option value={element.id} key={element.id}>
                                                {element.name}
                                            </option>
                                        ))}
                                    </AvField>
                                </AvGroup>
                            </Fieldset>
                            <br/>
                            <AvGroup>
                                <Label for="email">
                                    <Translate contentKey="billing-account.email">E-Posta</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvField
                                    name="email"
                                    type="email"
                                    value={this.state.contact.email} onChange={this.__handleChange}
                                    disabled={true}/>
                            </AvGroup>
                            <AvGroup>
                                <Label for="mobilePhone">
                                    <Translate contentKey="contactManagement.mobilePhone">GSM No</Translate>
                                </Label>
                                <PhoneField kind={'mobile'}
                                            name="mobilePhone" errorMessage="Geçersiz Numara Girişi"
                                            value={this.state.contact.mobilePhone}
                                            onChange={this.__handleChange}
                                            disabled={true}
                                />
                            </AvGroup>
                            <AvGroup>
                                <Label for="businessPhone">
                                    <Translate contentKey="contactManagement.businessPhone">İş Telefonu</Translate>
                                </Label>
                                <PhoneField kind={'business'}
                                            name="businessPhone" errorMessage="Geçersiz Numara Girişi"
                                            value={this.state.contact.businessPhone}
                                            onChange={this.__handleChange}
                                            disabled={true}
                                />
                            </AvGroup>
                            <AvGroup>
                                <Label for="faxNumber">
                                    <Translate contentKey="contactManagement.faxNumber">Fax Numarası</Translate>
                                </Label>
                                <PhoneField kind={'business'}
                                            name="faxNumber" errorMessage="Geçersiz Numara Girişi"
                                            value={this.state.contact.faxNumber}
                                            onChange={this.__handleChange}
                                            disabled={true}
                                />
                            </AvGroup>
                            {/*<AvGroup>*/}
                            {/*    <Label for="taxOffice">*/}
                            {/*        <Translate contentKey="contactManagement.taxOffice">Vergi Dairesi</Translate>*/}
                            {/*    </Label>*/}
                            {/*    <AvField*/}
                            {/*        type="text"*/}
                            {/*        className="form-control"*/}
                            {/*        name="taxOffice"*/}
                            {/*        value={this.state.contact.taxOffice} onChange={this.__handleChange}*/}
                            {/*        disabled={true}/>*/}
                            {/*</AvGroup>*/}
                        </div>
                    </div>
                </div>

                <ModalConfirm header='Onay' onOkClick={this.save}
                              onCancelClick={() => this.setState({displayConfirmDialog: false})}
                              show={this.state.displayConfirmDialog}
                              message='Bu Kontak için sistemde tanımlı bir kullanıcı bulunmaktadır. Devam ederseniz bu kullanıcı da pasife çekilecektir. Onaylıyor musunuz?'
                />
            </div>
        );
    }

    setData = (data, pageMode) => {
        this.clearData();
        let organization = data.organizationId.name;
        data.organizationId = data.organizationId.id;
        data.locationId = data.locationId ? data.locationId.id : null;
        this.setOrgAndLoc(data.organizationId);

        this.setState({
            statusTemp: data.status,
            contact: data,
            pageMode: pageMode,
            organization: organization,
            locationHidden: false
        });
    }

    getContact = () => {
        let data = Object.assign({}, this.state.contact);
        data.organizationId = {id: this.state.contact.organizationId};
        if (data.locationId) {
            data.locationId = {id: (this.state.contact.locationId ? this.state.contact.locationId : null)};
        }else{
            data.locationId=null;
        }
        return data;
    }

    clearData = () => {
        let contact = Object.assign({}, defaultContact);
        this.setState({
            contact: contact,
            pageMode: null,
            organization: null,
            locationList: [],
            locationHidden: true
        });
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let contact = this.state.contact;
        contact[e.target.name] = value;
        this.setState({contact: contact});
    }

    onOrganizationSelect = (data) => {
        let contact = this.state.contact;
        contact.organizationId = data.id;
        contact.locationId=null;
        let organization = data.name;
        this.setOrgAndLoc(data.id);
        this.setState({contact: contact, organization: organization, displayDialog: false, locationHidden: false});
    }

    setOrgAndLoc = (orgId) => {
        this.locationService.findByOrganizationId(orgId).then(response => {
                if (!GeneralUtils.isNullOrEmpty(response)) {
                    this.setState({locationList: response});
                }
            }
        )
    }

    setOrgAndLocAndGeneric(organizationId, locationId,pageMode) {
        this.hsmOrganizationService.findById(organizationId).then((response) => {
            let contact = this.state.contact;
            contact.organizationId = organizationId;
            contact.locationId = locationId;
            this.setOrgAndLoc(organizationId);
            this.setState({contact: contact, organization: response.name ,pageMode: pageMode, locationHidden: false});
        });
    }

    setPageMode = (pageMode) => {
        this.setState({pageMode: pageMode});
    }
}

ContactView.propTypes = {
    contact: PropTypes.object
};
