import React from 'react';
import {Button, Label} from 'reactstrap';
import {AvFeedback, AvGroup} from 'availity-reactstrap-validation';
import AvField from '../../../shared/component/form/AvField';
import PropTypes from 'prop-types';
import Translate from "../../../shared/language/Translate";
import GeneralUtils from '../../../shared/utils/GeneralUtils';
import LocationService from "../../../shared/service/LocationService";
import CityService from "../../../shared/service/CityService";
import DistrictService from "../../../shared/service/DistrictService";
import TtRegionService from "../../../shared/service/TtRegionService";
import {EXISTS, LOCATION_TYPES, STATUS} from '../../../shared/utils/Constants';
import {Fieldset} from "primereact/fieldset";
import {PhoneField} from "../../../shared/component/form/PhoneField";
import DataTable from "../../../shared/component/dataTable/DataTable";
import Dialog from "../../../shared/component/dialog/Dialog";
import HsmOrganizationColumnForDialog from "../../../shared/component/dataTable/columns/HsmOrganizationColumnForDialog";
import HsmOrganizationService from "../../../shared/service/HsmOrganizationService";
import AvFieldUpper from "../../../shared/component/form/AvFieldUpper";

const defaultLocation = {
    id: '',
    name: '',
    status: 'A',
    code: '',
    locationType: '',
    addressLine: '',
    cityId: 1,
    districtId: '',
    buildingName: '',
    buildingNumber: '',
    doorNumber: '',
    levelNumber: '',
    statusIsDisabled: false,
    postalCode: '',
    latitude: '',
    longitude: '',
    numberOfRooms: '',
    numberOfPerson: '',
    ttRegionId: '',
    fiberExist: '',
    smartBoardExist: '',
    pstnNumber: '',
    organizationId: ''
};

export default class LocationView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            location: defaultLocation,
            cityList: [{id: 0, name: 'Adana'}],
            districtList: [],
            pageMode: null,
            organizationData: null,
            organization: null
        }
        this.service = new LocationService();
        this.cityService = new CityService();
        this.districtService = new DistrictService();
        this.ttRegionService = new TtRegionService();
        this.hsmOrganizationService = new HsmOrganizationService();
    }

    componentDidMount = () => {
        this.cityService.findAll().then(response => this.setState({cityList: response}));
        this.districtService.findByCityId(this.state.location.cityId).then(response => {
            let location = this.state.location;
            location.districtId = response[0].id;
            this.setState({districtList: response, location: location});
        });
    }

    render() {
        return (
            <div>

                <div className="container">
                    <div className="row">
                        <div className="col">
                            <Fieldset legend="Organizasyon Bilgileri">
                                <AvGroup>
                                    <Label for="organizationId">
                                        <Translate contentKey="location.organizationId">Organizasyon Adı</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>
                                    <div style={{position: "relative"}}>
                                        <AvField type="text" className="form-control" name="organization" disabled
                                                 value={this.state.organization}/>{' '}
                                        {false &&
                                        <Button color="primary" onClick={this.findAllOrganization}
                                                disabled={true}
                                                className="pi pi-search selectTableButton" size="sm"/>
                                        }
                                    </div>
                                </AvGroup>
                            </Fieldset>
                            {/*<Fieldset legend="Organizasyon Bilgileri">*/}
                            {/*    <OrganizationGroup*/}
                            {/*        value={this.state.organization}*/}
                            {/*        onDataSelect={this.onOrganizationSelect}*/}
                            {/*        disabled={this.state.pageMode === PAGE_MODE.VIEW}*/}
                            {/*        validate={this.state.pageMode === PAGE_MODE.VIEW ? {} : {*/}
                            {/*            required: {*/}
                            {/*                value: true,*/}
                            {/*                errorMessage: "Bu alanın doldurulması zorunludur."*/}
                            {/*            }*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*</Fieldset>*/}
                            &nbsp;
                            <AvGroup>
                                <Label for="code">
                                    <Translate contentKey="location.code">Tesis Kodu</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="code"
                                    disabled={true}
                                    value={this.state.location.code}/>
                            </AvGroup>
                            <AvGroup>
                                <Label for="name">
                                    <Translate contentKey="location.name">Lokasyon Adı</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvFieldUpper
                                    type="text"
                                    className="form-control"
                                    name="name"
                                    value={this.state.location.name}
                                    disabled={true}/>
                            </AvGroup>
                            <AvGroup>
                                <Label for="locationType">
                                    <Translate contentKey="location.locationType">Lokasyon Tipi</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvField type="select" className="form-control" name="locationType"
                                         disabled={true}
                                         value={this.state.location.locationType}>
                                    <option value='' key=''/>
                                    {LOCATION_TYPES.map(element => (
                                        <option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                                <AvFeedback>This field cannot be longer than 50 characters.</AvFeedback>
                            </AvGroup>
                            <AvGroup>
                                <Label for="status">
                                    <Translate contentKey="location.status">Durum</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvField type="select" className="form-control" name="status"
                                         disabled={true}
                                         value={this.state.location.status}>
                                    {STATUS.map(element => (
                                        <option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>
                            <AvGroup>
                                <Label for="cityId">
                                    <Translate
                                        contentKey="location.cityId">İl Adı</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvField type="select" className="form-control" name="cityId"
                                         disabled={true}
                                         value={this.state.location.cityId}>
                                    {this.state.cityList.map(element => (
                                        <option value={element.id} key={element.id}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>
                            <AvGroup>
                                <Label for="districtId">
                                    <Translate
                                        contentKey="location.districtId">İlçe Adı</Translate>
                                    <span style={{color: 'red'}}>(*)</span>
                                </Label>
                                <AvField type="select" className="form-control" name="districtId"
                                         value={GeneralUtils.isNullOrEmpty(this.state.location.districtId) ? "" : this.state.location.districtId}
                                         disabled={true}>
                                    <option value='' key=''/>
                                    {this.state.districtList.map(element => (
                                        <option value={element.id} key={element.id}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>
                            <AvGroup>
                                <Label for="ttRegionId">
                                    <Translate contentKey="location.ttRegionId">TT Bölge Adı</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="ttRegionId"
                                    value={this.state.location.ttRegionId}
                                    disabled={true}
                                />
                            </AvGroup>
                        </div>
                        <div className="col">
                            <AvGroup>
                                <Label for="addressLine">
                                    <Translate contentKey="location.addressLine">Cadde/Sokak/Bulvar/Mahalle</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvField
                                    type="textarea"
                                    className="form-control textAreaHermes"
                                    name="addressLine"
                                    value={this.state.location.addressLine}
                                    disabled={true}
                                />
                                <AvFeedback>This field cannot be longer than 200 characters.</AvFeedback>
                            </AvGroup>
                            <AvGroup>
                                <Label for="buildingName">
                                    <Translate contentKey="location.buildingName">Bina Adı</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="buildingName"
                                    value={this.state.location.buildingName}
                                    disabled={true}
                                />
                                <AvFeedback>This field cannot be longer than 50 characters.</AvFeedback>
                            </AvGroup>
                            <AvGroup>
                                <Label for="buildingNumber">
                                    <Translate contentKey="location.buildingNumber">Bina Numarası</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="buildingNumber"
                                    value={this.state.location.buildingNumber}
                                    disabled={true}
                                />
                            </AvGroup>
                            <AvGroup>
                                <Label for="doorNumber">
                                    <Translate contentKey="location.doorNumber">Kapı Numarası</Translate>
                                </Label>
                                <AvField
                                    type="number"
                                    className="form-control"
                                    name="doorNumber"
                                    max="99999"
                                    value={this.state.location.doorNumber}
                                    disabled={true}
                                />
                            </AvGroup>
                            <AvGroup>
                                <Label for="levelNumber">
                                    <Translate contentKey="location.levelNumber">Kat Numarası</Translate>
                                </Label>
                                <AvField
                                    type="number"
                                    className="form-control"
                                    name="levelNumber"
                                    max="999"
                                    value={this.state.location.levelNumber}
                                    disabled={true}
                                />
                            </AvGroup>
                            <AvGroup>
                                <Label for="postalCode">
                                    <Translate contentKey="location.postalCode">Posta Kodu</Translate>
                                </Label>
                                <AvField
                                    type="number"
                                    className="form-control"
                                    name="postalCode"
                                    min="1000"
                                    max="99999"
                                    value={this.state.location.postalCode}
                                    disabled={true}
                                />
                                <AvFeedback>This field cannot be longer than 5 characters.</AvFeedback>
                            </AvGroup>
                            <AvGroup>
                                <Label for="latitude">
                                    <Translate contentKey="location.latitude">Enlem</Translate>
                                </Label>
                                <AvField
                                    type="number"
                                    className="form-control"
                                    name="latitude"
                                    max="999999"
                                    value={this.state.location.latitude}
                                    disabled={true}
                                />
                                <AvFeedback>This field format is wrong.</AvFeedback>
                            </AvGroup>
                            <AvGroup>
                                <Label for="longitude">
                                    <Translate contentKey="location.longitude">Boylam</Translate>
                                </Label>
                                <AvField
                                    type="number"
                                    className="form-control"
                                    name="longitude"
                                    max="999999"
                                    value={this.state.location.longitude}
                                    disabled={true}
                                />
                                <AvFeedback>This field format is wrong.</AvFeedback>
                            </AvGroup>
                        </div>
                        <div className="col">
                            <AvGroup>
                                <Label for="numberOfRooms">
                                    <Translate contentKey="location.numberOfRooms">Oda Sayısı</Translate>
                                </Label>
                                <AvField
                                    type="number"
                                    className="form-control"
                                    name="numberOfRooms"
                                    max="999"
                                    value={this.state.location.numberOfRooms}
                                    disabled={true}
                                />
                            </AvGroup>
                            <AvGroup>
                                <Label for="numberOfPerson">
                                    <Translate contentKey="location.numberOfPerson">Kişi Sayısı</Translate>
                                </Label>
                                <AvField
                                    type="number"
                                    className="form-control"
                                    name="numberOfPerson"
                                    max="99999"
                                    value={this.state.location.numberOfPerson}
                                    disabled={true}
                                />
                            </AvGroup>
                            <AvGroup>
                                <Label for="fiberExist">
                                    <Translate contentKey="location.fiberExist">Fiber Altyapı</Translate>
                                </Label>
                                <AvField type="select" className="form-control" name="fiberExist"
                                         value={this.state.location.fiberExist}
                                         disabled={true}>
                                    <option value='' key=''/>
                                    {EXISTS.map(element => (
                                        <option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>
                            <AvGroup>
                                <Label for="smartBoardExist">
                                    <Translate contentKey="location.smartBoardExist">Akıllı Tahta</Translate>
                                </Label>
                                <AvField type="select" className="form-control" name="smartBoardExist"
                                         value={this.state.location.smartBoardExist}
                                         disabled={true}>
                                    <option value='' key=''/>
                                    {EXISTS.map(element => (
                                        <option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>

                            <AvGroup>
                                <Label for="pstnNumber">
                                    <Translate contentKey="location.pstnNumber">İş Telefonu</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <PhoneField kind={'business'}
                                            name="pstnNumber" errorMessage="Geçersiz Numara Girişi"
                                            value={this.state.location.pstnNumber}
                                            disabled={true}
                                />
                            </AvGroup>

                        </div>
                    </div>
                </div>
                <Dialog id="OrganizationDialog" key="OrganizationDialogKey" visible={this.state.displayDialog}
                        header="Organizasyon Listesi" onHide={() => this.setState({displayDialog: false})}
                        style={{width: '90%', height: '80%'}}>
                    <DataTable fields={HsmOrganizationColumnForDialog.fields} privilege="allow"
                               data={this.state.organizationData}
                               selectionMode="single" onDataSelect={this.onOrganizationSelect}/>
                </Dialog>
            </div>
        );
    }

    findAllOrganization = () => {
        this.hsmOrganizationService.findAll().then(response => this.setState({
            organizationData: response,
            displayDialog: true
        }));
    }

    save = () => {
        let data = Object.assign({}, this.state.location);
        data.code = GeneralUtils.getUpperCasedVariable(this.state.location.code);
        data.cityId = {id: this.state.location.cityId};
        data.districtId = {id: this.state.location.districtId};
        data.ttRegionId = {id: this.state.ttRegion.id};
        data.organizationId = {id: this.state.location.organizationId};
        if (!GeneralUtils.isNullOrEmpty(this.state.location.id)) {
            this.service.update(data)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        this.props.save();
                    }
                });
        } else {
            this.service.create(data)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        let location = this.state.location;
                        location.id = response.id;
                        this.setState({location: location});
                        this.props.save();
                    }
                });
        }
    }

    setData = (data, pageMode) => {
        this.clearData(true);
        let organization = data.organizationId.name;
        data.organizationId = data.organizationId.id;
        let districtId = GeneralUtils.isNullOrEmpty(data.districtId) ? null : data.districtId.id;
        this.findDistrictByCityId(data.cityId.id,districtId);
        this.findTtRegionByCityId(data.cityId.id);
        this.setState({location: data, pageMode: pageMode, organization: organization});
    }

    setDataById = (id, pageMode) => {
        this.service.findById(id).then((data) => {
            this.setData(data, pageMode);
        });
    }

    clearData = (isRefresh) => {
        let location = Object.assign({}, defaultLocation);
        if (!isRefresh) {
            this.findDistrictByCityId(this.state.cityList[0].id);
            this.findTtRegionByCityId(this.state.cityList[0].id);
        }
        this.setState({location: location, pageMode: null, organization: null});
    }


    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let location = this.state.location;
        location[e.target.name] = value;
        this.setState({location: location});
    }

    __handleChangeCity = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let location = this.state.location;
        location[e.target.name] = value;
        // this.findDistrictByCityId(value);
        this.findTtRegionByCityId(value);
    }


    findDistrictByCityId = (cityId, districtId) => {
        this.districtService.findByCityId(cityId).then(response => {
            let districtList = response;
            let location = this.state.location;
            location.cityId = cityId;
            if (districtId) {
                location.districtId = districtId;
            } else {
                location.districtId = "";
                districtList = [];
            }
            this.setState({districtList, location: location});
        });
    }

    findTtRegionByCityId = (cityId) => {
        this.ttRegionService.findTtRegionByCityId(cityId).then(response => {
            let location = this.state.location;
            location.ttRegionId = response.ttRegionName;
            this.setState({location: location, ttRegion: response});
        });
    }

    onOrganizationSelect = (data) => {
        let location = this.state.location;
        location.organizationId = data.id;
        let organization = data.name;
        this.setState({location: location, organization: organization, displayDialog: false});
    }

    setPageMode = (pageMode) => {
        this.setState({pageMode: pageMode});
    }
}

LocationView.propTypes = {
    location: PropTypes.object
};
