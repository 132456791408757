import React, {Component} from 'react';
import {TreeTable as TreeTablePR} from 'primereact/treetable';
import {Column} from "primereact/column";
import DataTableCrud from "../dataTable/DataTable";
import PropTypes from 'prop-types';

export class TreeTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cols: this.props.fields,
            nodes: [],
            selectedNodeKeys: []
        };
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({nodes: nextProps.data, selectedNodeKeys: nextProps.selectionKeys});
    }

    render() {
        return (
            <div className="content-section implementation">
                <TreeTablePR {...this.props} value={this.state.nodes} selectionMode="checkbox" scrollable={true} responsive={true}
                             selectionKeys={this.state.selectedNodeKeys}
                             onSelectionChange={this.onSelectionChange}
                             onToggle={e => this.setState({expandedKeys: e.value})}
                             expandedKeys={this.state.expandedKeys}
                             emptyMessage="Kayıt Bulunamadı"
                >
                    {this.getColumns()}
                </TreeTablePR>
            </div>

        )
    }

    onSelectionChange = (e) => {
        this.setState({selectedNodeKeys: e.value});
        if (this.props.onSelectionChange) {
            this.props.onSelectionChange(e);
        }
    }

    collapseAll = () => {
        this.setState({expandedKeys: {}});
    }

    getColumns = () => {
        let dynamicColumns = this.state.cols.map((col, i) => {
            if (col.dataElement) {
                return <Column key={col.field} field={col.field} header={col.header} filter={true} filterMatchMode="contains"
                               sortable={col.sortable} expander={col.expander}
                               body={(rowData) => {
                                   return col.dataElement[this.dataElementCell(rowData, col.field)]
                               }}/>;
            }
            else {
                return <Column key={col.field} field={col.field} header={col.header}  filter={true} filterMatchMode="contains"
                               sortable={col.sortable} expander={col.expander}/>;
            }
        });
        return dynamicColumns;
    }

    dataElementCell = (rowData, field) => {
        return rowData.data[field];
    }

}

TreeTable.propTypes = {
    data: PropTypes.array.isRequired
};

DataTableCrud.defaultProps = {
    data: []
};
