import React from 'react';
import {AvGroup} from "availity-reactstrap-validation";
import {Col, Label, Row} from "reactstrap";
import AvField from "../../../shared/component/form/AvField";
import {REQUEST_TYPES_CONS, SERVICE_NAME} from "../../../shared/utils/Constants";


const defaultExternalSubcategoryMapParameter = {
    id: '',
    serviceName: '',
    requestType: '',
    subcategory: '',
    extSubcategory: ''
};

export default class ExternalSubcategoryMapSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            externalSubcategoryMapParameter: defaultExternalSubcategoryMapParameter,
            pageMode: null
        };
    }

    render() {
        return (
            <Row className="justify-content-center">
                <Col>
                    <AvGroup>
                        <Label for="serviceName">Hizmet Türü<span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="select"
                            className="form-control"
                            name="serviceName"
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                },
                                minLength: {
                                    value: 1,
                                    errorMessage: 'Bu alan en az 1 karakterden oluşabilir.'
                                },
                                maxLength: {
                                    value: 40,
                                    errorMessage: 'Bu alan en fazla 40 karakterden oluşabilir.'
                                }
                            }}
                            value={this.state.externalSubcategoryMapParameter.serviceName}
                            onChange={this.__handleChange}
                        >
                            <option value='' key=''/>
                            {SERVICE_NAME.map(element => (
                                <option value={element.value} key={element.value}>
                                    {element.name}
                                </option>
                            ))}
                        </AvField>
                    </AvGroup>

                    <AvGroup>
                        <Label for="requestType">Talep Tipi<span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="select"
                            className="form-control"
                            name="requestType"
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                },
                                minLength: {
                                    value: 1,
                                    errorMessage: 'Bu alan en az 1 karakterden oluşabilir.'
                                },
                                maxLength: {
                                    value: 40,
                                    errorMessage: 'Bu alan en fazla 40 karakterden oluşabilir.'
                                }
                            }}
                            value={this.state.externalSubcategoryMapParameter.requestType}
                            onChange={this.__handleChange}
                        >
                            <option value='' key=''/>
                            {REQUEST_TYPES_CONS.map(element => (
                                <option value={element.value} key={element.value}>
                                    {element.name}
                                </option>
                            ))}
                        </AvField>
                    </AvGroup>

                    <AvGroup>
                        <Label for="subcategory">Alt Kategori<span style={{color: 'red'}}> (*)</span></Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="subcategory"
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                },
                                minLength: {
                                    value: 1,
                                    errorMessage: 'Bu alan en az 1 karakterden oluşabilir.'
                                },
                                maxLength: {
                                    value: 40,
                                    errorMessage: 'Bu alan en fazla 40 karakterden oluşabilir.'
                                }
                            }}
                            value={this.state.externalSubcategoryMapParameter.subcategory}
                            onChange={this.__handleChange}
                        />
                    </AvGroup>

                    <AvGroup>
                        <Label for="extSubcategory">Dış Sistem Alt Kategori<span style={{color: 'red'}}> (*)</span></Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="extSubcategory"
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                },
                                minLength: {
                                    value: 1,
                                    errorMessage: 'Bu alan en az 1 karakterden oluşabilir.'
                                },
                                maxLength: {
                                    value: 40,
                                    errorMessage: 'Bu alan en fazla 40 karakterden oluşabilir.'
                                }
                            }}
                            value={this.state.externalSubcategoryMapParameter.extSubcategory}
                            onChange={this.__handleChange}
                        />
                    </AvGroup>
                </Col>
            </Row>
        );
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let externalSubcategoryMapParameter = this.state.externalSubcategoryMapParameter;
        externalSubcategoryMapParameter[e.target.name] = value;
        this.setState({externalSubcategoryMapParameter: externalSubcategoryMapParameter});
    }

    setData = (data, pageMode) => {
        this.setState({externalSubcategoryMapParameter: data, pageMode: pageMode});
    }

    getData = () => {
        return this.state.externalSubcategoryMapParameter;
    }

    clearData = () => {
        this.setState({
            externalSubcategoryMapParameter: Object.assign({}, defaultExternalSubcategoryMapParameter),
            pageMode: null
        });
    }

}
