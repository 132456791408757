import React from 'react';
import TeamService from '../../../shared/service/TeamService';
import Translate from "../../../shared/language/Translate";
import {AvGroup} from "availity-reactstrap-validation";
import {Col, Label, Row} from "reactstrap";
import {PAGE_MODE, STATUS} from '../../../shared/utils/Constants';
import AvField from "../../../shared/component/form/AvField";
import OrganizationGroup from "../../../shared/component/hsmGroup/OrganizationGroup";
import {PhoneField} from "../../../shared/component/form/PhoneField";

const defaultTeam = {
    id: '',
    name: '',
    description: '',
    status: 'A',
    organizationId: null
};
export default class TeamSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            team: defaultTeam,
            organization: null,
            pageMode: null
        };
        this.service = new TeamService();
    }


    render() {
        return (
            <div>
                <Row className="justify-content-center">
                    <Col md="6">
                        <OrganizationGroup
                            value={this.state.organization}
                            onDataSelect={this.onOrganizationSelect}
                            disabled={this.state.pageMode === PAGE_MODE.VIEW || this.state.pageMode === PAGE_MODE.UPDATE}
                        />
                        <AvGroup>
                            <Label for="name">
                                <Translate contentKey="teamManagement.teamName">Ekip Adı</Translate>
                                <span style={{color: 'red'}}> (*)</span>
                            </Label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="name"
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: 'Bu alan zorunlu.'
                                    },
                                    minLength: {
                                        value: 1,
                                        errorMessage: 'Bu alan en az 1 karakterden oluşabilir.'
                                    },
                                    maxLength: {
                                        value: 50,
                                        errorMessage: 'Bu alan en fazla 50 karakterden oluşabilir.'
                                    }
                                }}
                                value={this.state.team.name}
                                onChange={this.__handleChange}
                            />
                        </AvGroup>

                        <AvGroup>
                            <Label for="status">
                                <Translate contentKey="teamManagement.status">Durum</Translate>
                            </Label>
                            <AvField type="select" className="form-control" name="status"
                                     value={this.state.team.status} onChange={this.__handleChange}>
                                {STATUS.map(element => (
                                    <option value={element.value} key={element.value}>
                                        {element.name}
                                    </option>
                                ))}
                            </AvField>
                        </AvGroup>
                    </Col>

                    <Col md="6">
                        <AvGroup>
                            <Label for="email">
                                <Translate contentKey="teamManagement.email">E-Posta</Translate>
                            </Label>
                            <AvField
                                name="email"
                                type="email"
                                validate={{
                                    maxLength: {
                                        value: 254,
                                        errorMessage: 'E-Posta adresi 254 karakterden fazla olamaz.'
                                    },
                                    email: {
                                        errorMessage: 'E-Posta alanı uygun değil.'
                                    }
                                }}
                                value={this.state.team.email} onChange={this.__handleChange}/>
                        </AvGroup>

                        <AvGroup>
                            <Label for="mobilePhone">
                                <Translate contentKey="teamManagement.mobilePhone">GSM No</Translate>
                            </Label>
                            <PhoneField kind={'mobile'}
                                        name="mobilePhone" errorMessage="Geçersiz Numara Girişi"
                                    value={this.state.team.mobilePhone}
                                    onChange={this.__handleChange}/>
                        </AvGroup>

                        <AvGroup>
                            <Label for="businessPhone">
                                <Translate contentKey="teamManagement.businessPhone">İş Telefonu</Translate>
                            </Label>
                            <PhoneField kind={'business'} errorMessage="Geçersiz Numara Girişi"
                                        name="businessPhone"
                                        value={this.state.team.businessPhone}
                                        onChange={this.__handleChange}
                            />
                        </AvGroup>

                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col>
                        <AvGroup>
                            <Label for="description">
                                <Translate contentKey="teamManagement.description">Açıklama</Translate>
                            </Label>
                            <AvField
                                type="textarea"
                                className="form-control textAreaHermes"
                                name="description"
                                validate={{
                                    maxLength: {
                                        value: 4000,
                                        errorMessage: 'Bu alan en fazla 4000 karakterden oluşabilir.'
                                    }
                                }}
                                value={this.state.team.description}
                                onChange={this.__handleChange}
                            />
                        </AvGroup>
                    </Col>
                </Row>
            </div>
        );
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let team = this.state.team;
        team[e.target.name] = value;
        this.setState({team: team});
    }

    setData = (data, pageMode) => {
        let organization = data.organizationId ? data.organizationId.name : null;
        this.setState({team: data, organization: organization, pageMode: pageMode});
    }

    getData = () => {
        return this.state.team;
    }

    clearData = () => {
        this.setState({team: Object.assign({}, defaultTeam), organization: null, pageMode: null});
    }

    onOrganizationSelect = (data) => {
        let team = this.state.team;
        team.organizationId = data;
        this.setState({team: team, organization: data.name, displayDialog: false});
    }

}
