import React from 'react';
import {Col, Container, Row} from 'reactstrap';
import ConnectChartReport from "./ConnectChartReport";
import TransferChartReport from "./TransferChartReport";
import CancelChartReport from "./CancelChartReport";
import MoveChartReport from "./MoveChartReport";
import SuspendChartReport from "./SuspendChartReport";
import ChangeTitleChartReport from "./ChangeTitleChartReport";
import BillDisputeChartReport from "./BillDisputeChartReport";
import BillingApprovalChartReport from "./BillingApprovalChartReport";
import ServiceRequestReportService from "../../../shared/service/ServiceRequestReportService";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import IncreaseBWChartReport from "./IncreaseBWChartReport";
import ReduceBWChartReport from "./ReduceBWChartReport";

export default class ServiceRequestStatusReport extends React.Component {
    constructor(props) {
        super(props);
        this.service = new ServiceRequestReportService();
    }

    componentDidMount = () => {
        this.service.checkPage();
    }

    render() {
        return (
            <Container>
                {GeneralUtils.checkPrivilege('ServiceRequestStatusReport', 'View') &&
                <Row>
                    <Col>
                        <ConnectChartReport/>
                        <br/>
                        <TransferChartReport/>
                        <br/>
                        <BillDisputeChartReport/>
                        <br/>
                        <ChangeTitleChartReport/>
                    </Col>

                    <Col>
                        {/*<SuspendChartReport/>*/}
                        {/*<br/>*/}
                        <MoveChartReport/>
                        <br/>
                        <CancelChartReport/>
                        <br/>
                        <BillingApprovalChartReport/>
                        <br/>
                        <IncreaseBWChartReport/>
                        <br/>
                        <ReduceBWChartReport/>
                    </Col>
                </Row>
                }
            </Container>
        );
    }
}
