import React from 'react';
import {Panel} from 'primereact/panel';
import DataTable from '../../../shared/component/dataTable/DataTable';
import NoticeService from '../../../shared/service/NoticeService';
import NoticeColumn from '../../../shared/component/dataTable/columns/NoticeColumn';
import {Button} from "reactstrap";
import Translate from "../../../shared/language/Translate";
import {PAGE_MODE} from "../../../shared/utils/Constants";
import NoticeSave from "./NoticeSave";
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import Dialog from "../../../shared/component/dialog/Dialog";

export default class Notice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageMode: null
        };
        this.service = new NoticeService();
    }

    componentDidMount = () => {
        this.service.checkPage();
    }

    render() {
        return (
            <Panel header="Duyuru Yönetimi">
                <Toolbar  display={GeneralUtils.checkPrivilege(NoticeColumn.name, 'Create')}>
                    <div className="p-toolbar-group-right">
                        <Button color="primary" onClick={this.addNew}>
                            <Translate contentKey="entity.action.add">Yeni Kayıt</Translate>
                        </Button>
                    </div>
                </Toolbar>
                <DataTable ref={(elem) => this.refDataTable = elem} fields={NoticeColumn.fields} dataURL="notice/findAll" objectName={NoticeColumn.name}
                           selectionMode="single" onDataSelect={this.onDataSelect} loading={this.state.loading}/>

                <Dialog visible={this.state.displayDialog}
                        header={this.state.pageMode === PAGE_MODE.UPDATE ? 'Duyuru Güncelleme' : 'Yeni Duyuru Oluşturma'} onHide={() => this.setState({displayDialog: false})} style={{width: '50%'}}>
                    <NoticeSave ref={elem => this.refNoticeSave = elem}
                                closeDialog={() => this.setState({displayDialog: false})}
                                refresh={this.refresh}/>
                </Dialog>
            </Panel>
        );
    }

    refresh = () => {
        this.refDataTable.refresh();
    }

    onDataSelect = (data) => {
        let pageMode = GeneralUtils.getPageModeFromPrivilege(NoticeColumn.name);
        this.setState({displayDialog: true, pageMode: pageMode});
        this.refNoticeSave.clearData();
        this.refNoticeSave.setData(Object.assign({}, data), pageMode);
    }

    addNew = () => {
        this.setState({displayDialog: true, pageMode: PAGE_MODE.CREATE});
        this.refNoticeSave.clearData();
        this.refNoticeSave.setPageMode(PAGE_MODE.CREATE);
    }

}
