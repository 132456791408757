import React from 'react';
import {Button, Row} from 'reactstrap';
import {Panel} from "primereact/panel";
import DataTable from "../../../shared/component/dataTable/DataTable";
import WorkFlowModelColumn from "../../../shared/component/dataTable/columns/WorkFlowModelColumn.json"
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import Translate from "../../../shared/language/Translate";
import Dialog from "../../../shared/component/dialog/Dialog";
import Service from "../../../shared/service/Service";
import {PAGE_MODE} from "../../../shared/utils/Constants";
import WorkFlowModelSave from "./WorkFlowModelSave";
import {AvForm} from "availity-reactstrap-validation";
import HsmEdrColumn from "../../../shared/component/dataTable/columns/HsmEdrColumn.json";
import IncidentColumn from "../../../shared/component/dataTable/columns/IncidentColumn.json";
import AuditEventLogColumn from "../../../shared/component/dataTable/columns/AuditEventLogColumn.json";
import WorkFlowModelService from "../../../shared/service/WorkFlowModelService";


class WorkFlowModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageMode: null,
            categoryId:null,
            subCategoryId:null
        };
        this.service = new WorkFlowModelService();
    }

    // componentDidMount = () => {
    //     if(!GeneralUtils.isNullOrEmpty(this.state.subCategoryId)){
    //         this.refDataTable.setParameter(this.state.categoryId,this.state.subCategoryId);
    //     }else{
    //         // this.refDataTable.setParameter('ALL');
    //     }
    // }

    render() {
        return (
            <Row className="justify-content-center">
                <Panel header="İş Akışı Tanımları" style={{width: '90%'}}>
                    <Toolbar display={true}>
                        <div className="p-toolbar-group-right">
                            <Button color="primary" onClick={this.addNew}>
                                <Translate contentKey="entity.action.add">Yeni Kayıt</Translate>
                            </Button>
                        </div>
                    </Toolbar>

                    <DataTable ref={(elem) => this.refDataTable = elem} fields={WorkFlowModelColumn.fields}
                               dataURL="workflowmodel/findByCategoryIdAndSubcategoryId"
                               onDataSelect={this.onDataSelect} export={true} autoLoad={false} selectionMode="single"
                               privilege="allow"/>

                    <Dialog visible={this.state.displayDialog}
                            header={GeneralUtils.getDialogHeader('İş Akışı', this.state.pageMode)}
                            onHide={() => this.setState({displayDialog: false})} style={{width: '60%'}}>
                        <AvForm onValidSubmit={this.save}>
                            <Toolbar
                                display={true}>
                                <div className="p-toolbar-group-right">
                                    <Button color="primary" type="submit" className="float-right">
                                        <Translate contentKey="entity.action.save">Kaydet</Translate>
                                    </Button>
                                    <Button color="primary" className="float-right"
                                            onClick={() => this.setState({displayDialog: false})}>
                                        <Translate contentKey="entity.action.save">Vazgeç</Translate>
                                    </Button></div>

                            </Toolbar>
                            <WorkFlowModelSave ref={elem => this.refWorkFlowModelSave = elem}/>
                        </AvForm>
                    </Dialog>
                </Panel>
            </Row>
        );
    }

    refresh = () => {
        this.refDataTable.refresh();
    }

    addNew = () => {
        this.setState({displayDialog: true, pageMode: PAGE_MODE.CREATE});
        this.refWorkFlowModelSave.clearData();
    }

    onDataSelect = (data) => {
        let pageMode = GeneralUtils.getPageModeFromPrivilege(WorkFlowModelColumn.name);
        this.setState({displayDialog: true, pageMode: pageMode});
        this.refWorkFlowModelSave.clearData();
        this.refWorkFlowModelSave.setData(Object.assign({}, data), pageMode);
    }

    setData = (data) => {
        this.setState({categoryId:data.categoryId.id , subCategoryId:data.id})
        this.refDataTable.setParameter(data.categoryId.id,data.id);
    }

    save = () => {
        let workFlowModel = this.refWorkFlowModelSave.getData();
        if (!GeneralUtils.isNullOrEmpty(workFlowModel.id)) {
            this.service.update(workFlowModel).then((response) => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    this.setState({displayDialog: false});
                    this.refresh();
                }
            });
        } else {
            this.service.create(workFlowModel).then((response) => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    this.setState({displayDialog: false});
                    this.refresh();
                }
            });
        }
    }
}


export default WorkFlowModel;
