import React from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from "react-bootstrap-sweetalert/lib/dist/SweetAlert";

export default class HermesSweetAlert {

    static getAlertComponent(data, callBackConfirm, callBackCancel) {
        return (<SweetAlert
            title={data.title}
            show={data.show}
            type={data.type}
            onConfirm={() => { this.closeAlert(callBackConfirm)}}
            confirmBtnText={data.confirmBtnText}
            confirmBtnBsStyle={data.confirmBtnBsStyle}
            onCancel={() => { this.onCancel(callBackCancel); }}
            cancelBtnText={data.cancelBtnText}
            cancelBtnBsStyle={data.cancelBtnBsStyle}
            showConfirm={data.showConfirm}
            showCancel={data.showCancel}
            focusConfirmBtn={false}
            closeOnClickOutside={false}
            allowEscape={false}
        >
            {data.message}
        </SweetAlert>);
    }

    static error(message) {
        let data = {};
        Object.assign(data, defaultData);
        data.message = message;
        data.title = "Hata";
        data.show = true;
        data.type = "error";
        this.renderAlert(this.getAlertComponent(data));
    }

    static success(message, callBackConfirm) {
        let data = {};
        Object.assign(data, defaultData);
        data.message = message;
        data.title = "Başarılı";
        data.show = true;
        data.type = "success";
        this.renderAlert(this.getAlertComponent(data, callBackConfirm));
    }

    static info(message) {
        let data = {};
        Object.assign(data, defaultData);
        data.message = message;
        data.title = "Bilgi";
        data.show = true;
        data.type = "info";
        this.renderAlert(this.getAlertComponent(data));
    }

    static warn(message) {
        let data = {};
        Object.assign(data, defaultData);
        data.message = message;
        data.title = "Uyarı";
        data.show = true;
        data.type = "warning";
        this.renderAlert(this.getAlertComponent(data));
    }

    static renderAlert(alert) {
        ReactDOM.render(alert, document.getElementById('hermesSweetAlert'));
    }

    static closeAlert(callBackConfirm) {
        let data = { show: false };
        let alert = this.getAlertComponent(data);
        this.renderAlert(alert);
        if(callBackConfirm){
            callBackConfirm();
        }
    }
}

const defaultData = {
    message: "",
    title: "",
    type: "",
    cancelBtnText: "İptal",
    cancelBtnBsStyle: "default",
    confirmBtnText: "Tamam",
    confirmBtnBsStyle: "primary",
    showConfirm: true,
    showCancel: false
}