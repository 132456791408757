import React from 'react';
import {Panel} from 'primereact/panel';
import DataTable from '../../../shared/component/dataTable/DataTable';
import ContactService from '../../../shared/service/ContactService';
import ContactColumn from '../../../shared/component/dataTable/columns/ContactColumn';
import {Button} from 'reactstrap';
import Translate from "../../../shared/language/Translate";
import {PAGE_MODE} from "../../../shared/utils/Constants";
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import Dialog from "../../../shared/component/dialog/Dialog";
import Contact from "./Contact";


export default class ContactList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageMode: null
        };
        this.service = new ContactService();
    }

    componentDidMount = () => {
        this.service.checkPage();
    }

    render() {
        return (
            <Panel header="Kontak Yönetimi">
                <Toolbar display={GeneralUtils.checkPrivilege(ContactColumn.name, 'Create')}>
                    <div className="p-toolbar-group-right">
                        <Button color="primary" onClick={this.addNew}>
                            <Translate contentKey="entity.action.add">Yeni Kayıt</Translate>
                        </Button>
                    </div>
                </Toolbar>
                <DataTable ref={(elem) => this.refDataTable = elem} fields={ContactColumn.fields} dataURL="contact/findAllFilteredOrganization" objectName={ContactColumn.name}
                           selectionMode="single" onDataSelect={this.onDataSelect} export={true}/>
                <Dialog visible={this.state.displayDialog}
                        header={GeneralUtils.getDialogHeader('Kontak', this.state.pageMode)}
                        onHide={() => this.setState({displayDialog: false})} style={{width: '50vw'}}>
                    <Contact ref={(elem) => this.refContact = elem}
                             closeDisplayDialog={this.closeDisplayDialog} refresh={this.refresh}/>
                </Dialog>

            </Panel>
        );
    }

    addNew = () => {
        this.setState({displayDialog: true, pageMode: PAGE_MODE.CREATE});
        this.refContact.addNew();
    }

    refresh = () => {
        this.refDataTable.refresh();
    }

    onDataSelect = (data) => {
        let pageMode = GeneralUtils.getPageModeFromPrivilege(ContactColumn.name);
        this.setState({
            displayDialog: true,
            pageMode: pageMode
        });
        this.refContact.setData(data, pageMode);
    }

    closeDisplayDialog = () => {
        this.setState({displayDialog: false});
    }

}
