import React from 'react';
import PropTypes from 'prop-types';
import {Steps} from "primereact/steps";
import GeneralUtils from "../../utils/GeneralUtils";

/**
 * Ümit Yıldırım ._.
 */
const flowGenericListConst = {
    theStart: "Başlangıç",
    support: "Destek",
    firstLineApproval: "Yeni Talep Onay",
    firstLineConfirmation: "Teyit (Müşteri)",
    firstLineSupport: "Destek (Müşteri)",
    providerConfirmation: "Teyit (TT)",
    providerSupport: "Destek (TT)",
    partnerSupport: "Seviye-1 Destek",
    postInstallApproval: "Kurulum Onayı",
    providerClosure: "Kapanış (TT)",
    theEnd: "Bitiş"
}

const flowActiveListConst = {
    theStart: "Başlangıç",
    support: "Destek",
    firstLineApproval: "Yeni Talep Onay",
    firstLineConfirmation: "Teyit (Müşteri)",
    firstLineSupport: "Destek (Müşteri)",
    providerConfirmation: "Teyit (TT)",
    providerSupport: "Destek (TT)",
    partnerSupport: "Seviye-1 Destek",
    postInstallApproval: "Kurulum Onayı",
    providerClosure: "Kapanış (TT)",
    theEnd: "Bitiş"
}


export default class FlowGroup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            flowList: [],
            items: []
        };
    }

    componentWillReceiveProps = (nextProps) => {
        let flowList = nextProps.flowList;
        flowList.sort(function (a, b) {
            return a.step - b.step;
        });
        let items = [];
        items.push({label: flowGenericListConst['theStart'], step: 0});

        let tempElement;
        let activeIndex = 0;
        flowList.forEach(element => {
            if (!GeneralUtils.isNullOrEmpty(tempElement) && tempElement.step === element.step) {
                if (nextProps.flowId === element.flowId) {
                    items.pop();
                    items.push({label: flowActiveListConst[element.flowId]});
                    activeIndex = element.step;
                }
            } else {
                if (nextProps.flowId === element.flowId) {
                    items.push({label: flowActiveListConst[element.flowId]});
                    activeIndex = element.step;
                } else {
                    items.push({label: flowGenericListConst[element.flowId]});
                }
            }
            tempElement = element;
        });
        items.push({label: flowGenericListConst['theEnd']});
        if (nextProps.flowId === 'theEnd') {
            activeIndex = items.length - 1;
        }

        // const uniqueArr = Array.from(new Set(flowList.map(data => data.step))).map(step=> {return {step: step, flowId: flowList.find(e => e.step ===step).name}});
        // console.log(uniqueArr);

        this.setState({flowList: flowList, flowId: nextProps.flowId, items: items, activeIndex: activeIndex});
    }

    render() {
        return (<Steps model={this.state.items} activeIndex={this.state.activeIndex} readOnly={true}/>);
    }

    // renderItems =()=>{
    //     return <div className='p-steps p-component'>
    //         <ul role="tablist">
    //             {this.renderFlow({step:0, flowId: "theStart"})}
    //             {this.state.flowList.map(element => (this.renderFlow(element)))}
    //             {this.renderFlow({step:this.state.flowList.length+1, flowId: "theEnd"})}
    //         </ul>
    //     </div>
    // }
    //
    // renderFlow=(item)=>{
    //     const className = classNames('p-steps-item', {
    //         'p-highlight p-steps-current': (item.flowId === this.state.flowId)});
    //
    //     return (
    //         <li key={item.flowId + '_' + item.step} className={className}>
    //             <a className="p-menuitem-link">
    //                 <span className="p-steps-number">{item.step}</span>
    //                 <span className="p-steps-title">{item.flowId}</span>
    //             </a>
    //         </li>
    //     );
    // }
}

FlowGroup.propTypes = {
    flowList: PropTypes.array.isRequired,
    flowId: PropTypes.number.isRequired
};