import React from 'react';
import PropTypes from 'prop-types';
import {Button, Label} from 'reactstrap';
import {AvGroup} from 'availity-reactstrap-validation';
import AvField from '../../../shared/component/form/AvField';
import Translate from "../../../shared/language/Translate";

export default class SelectInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: this.props.name,
            value: this.props.value,
            translateKey: this.props.translateKey,
            label: this.props.label,
            optionList: this.props.optionList,
            keyText: this.props.keyText,
            valueKeyText: this.props.valueKeyText,
            disabled: this.props.disabled,
            addNewButton: this.props.addNewButton,
            validate: this.props.validate,
            emptyOption: this.props.emptyOption
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            name: nextProps.name,
            value: nextProps.value,
            translateKey: nextProps.translateKey,
            label: nextProps.label,
            optionList: nextProps.optionList,
            keyText: nextProps.keyText,
            valueKeyText: nextProps.valueKeyText,
            disabled: nextProps.disabled,
            addNewButton: nextProps.addNewButton,
            validate: nextProps.validate,
            emptyOption: nextProps.emptyOption
        });
    }

    render() {
        let requiredIcon = this.getRequiredIcon();
        let getOption = this.getOption();

        let comp = (
            <AvGroup>
                <Label for={this.state.name}>
                    <Translate contentKey={this.state.translateKey}>{this.state.label}</Translate>
                    {requiredIcon}
                </Label>
                <div style={{position: "relative"}}>
                    <AvField {...this.props}
                             label={undefined}
                             disabled={this.state.disabled}
                             type="select"
                             className="form-control"
                             name={this.state.name}
                             value={this.state.value}
                             onChange={this.onChange}
                             validate={this.state.validate}
                    >
                        {this.state.emptyOption && <option value=""/>}
                        {getOption}
                    </AvField>
                    {(this.props.openDetailDialog && this.state.value) &&
                    <Button color="primary" onClick={this.props.openDetailDialog}
                            disabled={this.props.openDetailDialog ? false : true}
                            className="pi pi-info selectTableButton" size="sm"/>
                    }
                    {this.state.addNewButton &&
                    <Button color="primary" onClick={this.props.createNewLocation}
                            className={this.props.openDetailDialog ? "pi pi-plus selectTablePlusButton25" :"pi pi-plus selectTableButton"}
                            size="sm"/>
                    }
                </div>
            </AvGroup>
        );
        return (comp);
    }

    getRequiredIcon = () => {
        let result = null;
        if (this.state.validate && this.state.validate.required && this.state.validate.required.value === true) {
            result = <span style={{color: 'red'}}> (*)</span>;
        }
        return result;
    }

    onChange = (e) => {
        this.props.onChange(e);
    }

    getOption = () => {
        let key = this.state.keyText;
        let value = this.state.valueKeyText;

        if (key ===  undefined || key === "") key = "key";
        if (value ===  undefined || value ===  "") value = "value";

        let result;
        if (value.includes('-')) {
            let key1 = value.split('-')[0];
            let key2 = value.split('-')[1];
            result = this.state.optionList.map(element => (
                <option value={element[key]} key={element[key]}>
                    {element[key1] +' - '+ element[key2]}
                </option>
            ));
        }else{
            result = this.state.optionList.map(element => (
                <option value={element[key]} key={element[key]}>
                    {element[value]}
                </option>
            ));
        }
        return result;
    }
}

SelectInput.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    translateKey: PropTypes.string,
    label: PropTypes.string,
    optionList: PropTypes.array,
    keyText: PropTypes.string,
    valueKeyText: PropTypes.string,
    disabled: PropTypes.bool,
    addNewButton: PropTypes.bool,
    validate: PropTypes.object,
    emptyOption: PropTypes.bool
};