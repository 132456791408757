import React from "react";
import PropTypes from 'prop-types';
import SelectDataTable from '../form/SelectDataTable';
import HsmUserColumn from "../../component/dataTable/columns/HsmUserColumn";
import HsmUserService from "../../service/HsmUserService";

export default class HsmUserGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value,
            validate: this.props.validate,
            dataTable: this.props.dataTable,
            label: this.props.label,
            disabled: this.props.disabled
        };
        this.service = new HsmUserService();
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({
            validate: nextProps.validate,
            value: nextProps.value,
            dataTable: nextProps.dataTable,
            label: nextProps.label,
            disabled: nextProps.disabled
        });
    }

    render() {
        let validate = this.state.validate;
        if (!validate) {
            validate = {
                required: {
                    value: true,
                    errorMessage: "Bu alanın doldurulması zorunludur."
                }
            };
        }

        let dataTable = this.state.dataTable;
        if (!dataTable) {
            dataTable = {
                dataURL : "hsmUser/findAllFilteredOrganization",
                fields: HsmUserColumn.fields
            };
        }

        let label = this.state.label;
        if (!label) {
            label = "Kullanıcı"
        }
        return (
            <SelectDataTable
                value={this.state.value}
                validate={validate}
                dataTable={dataTable}
                disabled={this.state.disabled}
                name="requestedBy"
                translateKey="defaultHsmUserGroup"
                label={label}
                dialogHeader="Kullanıcı Listesi"
                onDataSelect={this.props.onDataSelect}
                autoRefrestData={false}
            />
        );
    }
}
HsmUserGroup.propTypes = {
    value: PropTypes.string,
    validate: PropTypes.object,
    dataTable: PropTypes.object
}
