import React from 'react';
import {Button, Col, Row} from 'reactstrap';
import Dialog from "../../shared/component/dialog/Dialog";
import Toolbar from "../../shared/component/toolbar/Toolbar";
import {Fieldset} from "primereact/fieldset";
import OrganizationGroup from "../../shared/component/hsmGroup/OrganizationGroup";
import HsmOrganizationColumnForDialog from "../../shared/component/dataTable/columns/HsmOrganizationColumnForDialog";
import GeneralUtils from "../../shared/utils/GeneralUtils";
import Loading from "../../shared/component/others/Loading";
import InventoryColumnForExcel from "../../shared/component/dataTable/columns/InventoryColumnForExcel";
import * as XLSX from "xlsx";
import {toast} from "react-toastify";
import HsmOrganizationService from "../../shared/service/HsmOrganizationService";
import LocationService from "../../shared/service/LocationService";
import {AvForm} from "availity-reactstrap-validation";
import RequestAssetService from "../../shared/service/RequestAssetService";
import DataTableForExcel from "../../shared/component/dataTable/DataTableForExcel";

const assetTypeConst = {
    AP: "Access Point",
    KABINET: "Cabinet",
    SWITCH: "Switch",
    SFP: "SFP Transceiver Module"
}


class TestPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            inventoryLocationCode: null,
            inventoryOrganizationCode: "",
            inventoryOrganizationId: 14812,
            displayDialogForExcel: true,
            /*
                        locationList: [{id: 0, name: ''}],
            */
            requestType: 'SERVICE_REQUEST',
            requestId: 'SR-00000310'
        };

        this.organizationService = new HsmOrganizationService();
        this.locationService = new LocationService();
        this.requestAssetService = new RequestAssetService();

    }

    componentDidMount() {
        this.organizationService.findById(this.state.inventoryOrganizationId).then(data => {
            let organization = data.code + ' - ' + data.name;
            this.setState({
                organization,
                inventoryOrganizationCode: data.code
            });
        })

        /*this.locationService.findByOrganizationId(this.state.inventoryOrganizationId).then(response => {
            if (!GeneralUtils.isNullOrEmpty(response)) {
                this.setState({locationList: response});
            }
        })*/
    }

    render() {
        return (
            <div>
                <AvForm>
                    <Dialog visible={this.state.displayDialogForExcel} header="Organizasyon & Lokasyon" modal={true}
                            resizable={true} maximizable={true} responsive={true}
                            onHide={() => this.setState({displayDialogForExcel: false})}
                            style={{width: '100%'}}>
                        <Toolbar display={true}>
                            <div className="p-toolbar-group-right">
                                <Button color="primary" onClick={this.clearDataExcel}>
                                    Temizle
                                </Button>
                                <Button color="primary" onClick={this.saveForExcel}>
                                    Kaydet
                                </Button>
                            </div>
                        </Toolbar>
                        <Fieldset legend="Organizasyon & Lokasyon Bilgileri">
                            <Row>
                                <Col md="6">
                                    <OrganizationGroup
                                        value={this.state.organization}
                                        dataTable={{
                                            dataURL: "hsmOrganization/findFilteredOrgs",
                                            fields: HsmOrganizationColumnForDialog.fields
                                        }}
                                        disabled={true}/>
                                </Col>

                                {/* <Col>
                                    <LocationGroup
                                        name="location"
                                        value={this.state.inventoryLocationId}
                                        optionList={this.state.locationList}
                                        validate={true}
                                        onChange={this.__handleChangeLocation}/>
                                </Col>*/}
                            </Row>
                        </Fieldset>
                        {!GeneralUtils.isNullOrEmpty(this.state.organization) &&
                        <Row className="justify-content-center">
                            <br/>
                            <Button color="primary" onClick={this.downloadTaskExcel}>
                                Taslak Dosya İndir
                            </Button>
                            <input type='file' accept='.xlsx, .xls' onChange={this.__handleChangeFile}/>
                            <br/>
                            <DataTableForExcel ref={(elem) => this.refDataTable = elem}
                                               fields={InventoryColumnForExcel.fields}
                                               data={this.state.data}
                                               loading={this.state.loading}
                                               privilege="allow"/>
                        </Row>
                        }


                        {this.state.loading && <Loading/>}
                    </Dialog>
                </AvForm>
            </div>
        );
    }

    clearDataExcel = () => {
        this.setState({data: []});
    }

    saveForExcel = () => {

        if (GeneralUtils.isNullOrEmpty(this.state.data)) {
            return toast.warn('Lütfen dosya yükleyiniz.');
        }
        this.setState({loading: true});
        let data = this.state.data.map((element) => GeneralUtils.addCreateInfo(element));
        this.requestAssetService.createForExcel(data, this.state.requestType, this.state.requestId,
            this.state.inventoryOrganizationCode).then(response => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    /*
                                        this.setState({displayDialogForExcel: false});
                    */
                    let errorCheckCount = 0;
                    response.forEach(element => {
                        if (element.hasError) {
                            errorCheckCount++;
                        }
                    })

                    if (errorCheckCount != 0) {
                        this.setState({loading: false, data: response});
                        return toast.warn('Lütfen hatalı kayıtları düzelterek kaydedin.');
                    }

                    /*this.requestAssetService.findAllAssetByRequestTypeAndRequestId(this.state.requestType, this.state.requestId).then((response) => {
                        let assetList = this.addButton(response);
                        this.setState({
                            assetList: assetList
                        });
                        if (this.props.setAssetList) {
                            this.props.setAssetList(assetList);
                        }
                    });*/
                }
                toast.success("İşlem başarıyla gerçekleştirilmiştir.");
                this.setState({loading: false});
            }
        );
    }

    /*  __handleChangeLocation = (e) => {
          let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
          this.setState({inventoryLocationId: value});

          this.locationService.findById(value).then(res => {
              this.setState({
                  inventoryLocationCode: res.code
              });
          });
      }*/


    __handleChangeFile = (e) => {

        this.onImportExcel(e);

    }


    onImportExcel = (file) => {
        // Get the uploaded file object
        let {files} = file.target;
        if (GeneralUtils.isNullOrEmpty(files)) {
            return;
        }
        // Read the file through the FileReader object
        let fileReader = new FileReader();
        fileReader.onload = event => {
            try {
                let {result} = event.target;
                // Read the entire excel table object in binary stream
                let workbook = XLSX.read(result, {type: 'binary'});
                let data = []; // store the acquired data
                // Traverse each worksheet for reading (here only the first table is read by default)
                for (let sheet in workbook.Sheets) {
                    if (workbook.Sheets.hasOwnProperty(sheet)) {
                        // Convert excel to json data using the sheet_to_json method
                        data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
                        // break; // If you only take the first table, uncomment this line
                    }
                }

                let newDataArray = [];
                data.map(element => {
                    let newData = {
                        organizationId: {
                            code: ""
                        },
                        locationId: {
                            code: ""
                        },
                        floorId: {
                            name: ""
                        },
                        roomId: {
                            name: ""
                        },
                        brandId: {
                            name: ""
                        },
                        modelId: {
                            name: ""
                        },
                        assetTag: "",
                        serialNumber: "",
                        dateOfLive: "",
                        kykRegion: "",
                        ttRegion: "",
                        macAddress: "",
                        nature: "",
                        assetType: "",
                        temosId: "",
                        acceptanceDate: "",
                        activatedDate: "",
                        hsmProjectId: {
                            name: ""
                        },
                        createdBy: GeneralUtils.getUserId()
                    }
                    newData.kykRegion = element['KYK Bölge'];
                    newData.ttRegion = element['TT Bölge'];
                    newData.organizationId.code = element['YURT KODU'];
                    newData.locationId.code = element['BLOK KODU'];
                    newData.floorId.name = element['KAT'];
                    newData.roomId.name = element['KONUM'];
                    newData.assetType = assetTypeConst[element['Nature']];
                    newData.nature = element['TÜR'];
                    newData.brandId.name = element['MARKA'];
                    newData.modelId.name = element['MODEL'];
                    newData.assetTag = element['BARKOD'];
                    newData.serialNumber = element['SERI NO'];
                    newData.macAddress = element['MAC AD.']
                    newData.temosId = element['TEMOS ID'];
                    newData.acceptanceDate = this.ExcelDateToJSDate(element['KABUL TARIHI']);
                    newData.activatedDate = this.ExcelDateToJSDate(element['AKTIFLEME TARIHI']);
                    newData.hsmProjectId.name = element['Faz'];
                    this.checkFile(newData);
                    newDataArray.push(newData);
                    return element;
                });

                if (newDataArray.length == 0) {
                    return toast.warn('Dosyada kayıt bulunamadı.');
                }

                this.setState({loading: true});
                /*
                                this.refDataTable.setSendData(newDataArray);
                */
                this.requestAssetService.checkForExcel(newDataArray, this.state.inventoryOrganizationCode).then(response => {
                        if (!GeneralUtils.isNullOrEmpty(response)) {
                            /*
                                                        this.setState({displayDialogForExcel: false});
                            */
                            this.setState({
                                data: response,
                                loading: false
                            });

                            /*this.requestAssetService.findAllAssetByRequestTypeAndRequestId(this.state.requestType, this.state.requestId).then((response) => {
                                let assetList = this.addButton(response);
                                this.setState({
                                    assetList: assetList
                                });
                                if (this.props.setAssetList) {
                                    this.props.setAssetList(assetList);
                                }
                            });*/
                        }
                    }
                );
            } catch (e) {
                this.setState({
                    loading: false
                });
                return toast.warn('Lütfen seçilen dosyayı kontrol ediniz.');
            }
        };
// Open the file in binary mode
        fileReader.readAsBinaryString(files[0]);
    }

    checkFile = (data) => {
        if (data.organizationId.code === undefined ||
            data.locationId.code === undefined ||
            data.floorId.name === undefined ||
            data.roomId.name === undefined ||
            data.brandId.name === undefined ||
            data.modelId.name === undefined ||
            data.assetTag === undefined ||
            data.serialNumber === undefined ||
            data.dateOfLive === undefined ||
            data.kykRegion === undefined ||
            data.ttRegion === undefined ||
            data.macAddress === undefined ||
            data.nature === undefined ||
            data.assetType === undefined ||
            data.temosId === undefined ||
            data.acceptanceDate === undefined ||
            data.activatedDate === undefined ||
            data.hsmProjectId.name === undefined ||
            data.createdBy === undefined
        ) {
            throw 'file type is incorrect';
        }
    }

    ExcelDateToJSDate = (serial) => {
        let utc_days = Math.floor(serial - 25569);
        let utc_value = utc_days * 86400;
        let date_info = new Date(utc_value * 1000);
        let fractional_day = serial - Math.floor(serial) + 0.0000001;
        let total_seconds = Math.floor(86400 * fractional_day);
        let seconds = total_seconds % 60;
        total_seconds -= seconds;
        let hours = Math.floor(total_seconds / (60 * 60));
        let minutes = Math.floor(total_seconds / 60) % 60;

        return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
    }

    downloadTaskExcel = () => {
        let url = 'data/taskExcel.xlsx'
        let a = document.createElement('a')
        a.href = url
        a.download = 'taskExcel.xlsx'
        a.click()
        window.URL.revokeObjectURL(url)
    }

}


export default TestPage;
