import React, {Component} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import GeneralUtils from "../../utils/GeneralUtils";
import NoticeService from "../../service/NoticeService";

export class AppInlineProfile extends Component {

    constructor() {
        super();
        this.state = {
            expanded: false,
            noticeCount: 0
        };
        this.onClick = this.onClick.bind(this);
        this.noticeService = new NoticeService();
    }

    componentDidMount = () =>{
        this.noticeService.getCountAllActive().then(response => this.setState({noticeCount: response}));
    }

    onClick(event) {
        this.setState({expanded: !this.state.expanded});
        event.preventDefault();
    }

    render() {
        return (
            <div className="profile">
                <div>
                    <img src="assets/layout/images/profile.png" alt=""/>
                </div>
                <a className="profile-link" onClick={this.onClick}>
                    <span className="username">{GeneralUtils.getUserNameAndFamilyNames()}</span>
                    <i className="pi pi-fw pi-cog"/>
                </a>
                <ul className={classNames({'profile-expanded': this.state.expanded})}>
                    <li><a onClick={() => {
                        window.location = '#/hsmUserAccount'
                    }}><i className="pi pi-fw pi-user"/><span>Hesap Bilgileri</span></a></li>
                    <li><a onClick={() => {
                        window.location = '#/noticeBoard'
                    }}><i className="pi pi-fw pi-inbox"/><span>Duyurular</span>
                        {this.state.noticeCount>0 && <span className="menuitem-badge">{this.state.noticeCount}</span>}</a></li>
                    <li><a onClick={this.props.logout}><i className="pi pi-fw pi-power-off"/><span>Çıkış</span></a>
                    </li>
                </ul>
            </div>
        );
    }
}

AppInlineProfile.propTypes = {
    logout: PropTypes.func
};
