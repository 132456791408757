import React from 'react';
import {Panel} from 'primereact/panel';
import {Button, Col, Container, Label, Row} from 'reactstrap';
import Doughnut from "../../../shared/component/chart/Doughnut";
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import IncidentReportService from "../../../shared/service/IncidentReportService";

export default class IncidentChartReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            totalCount: 0,
            data: {
                labels: ['AÇIK', 'ONAY BEKLİYOR', 'TEYİT BEKLİYOR', 'KAPALI', 'ASKIDA'],
                datasets: [{
                    data: [],
                    backgroundColor: ['#42A5F5', '#9CCC65', '#45D9F5', '#F52A2D', '#24F52A'],
                    hoverBackgroundColor: ['#42A5F5', '#9CCC65', '#45D9F5', '#F52A2D', '#24F52A']
                }]
            }
        };
        this.service = new IncidentReportService();
    }

    componentDidMount = () => {
        this.service.checkPage();
        this.refresh();
    }

    render() {
        return (
            <Panel header="Arıza Kayıt Durum Raporu">
                <Toolbar display={true}>
                    <div className="p-toolbar-group-right">
                        <Button color="primary" onClick={this.refresh}>
                            Yenile
                        </Button>
                    </div>
                </Toolbar>
                <Container>
                    <Row>
                        <Col>
                            {this.state.item}
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Label >{'Toplam : '+this.state.totalCount}</Label>
                    </Row>
                </Container>
            </Panel>
        );
    }

    getDoughnut = () => {
        return <Doughnut
            data={this.state.data}
            // onElementsClick={this.redirectPage}
            options={
                {legend: {display: true}, responsive: true, cutoutPercentage: 0}
            }/>;
    }

    // redirectPage = (e) => {
    //     if (e.length !== 0) {
    //         if (e[0]._model.label === 'KURULU OLAN LOKASYON') {
    //             window.location = '#/model';
    //         } else {
    //             window.location = '#/location';
    //         }
    //     }
    // }

    refresh = () => {
        let open = 0;
        let awaitingApproval = 0;
        let resolved = 0;
        let closed = 0;
        let pending = 0;

        let data = this.state.data;
        this.setState({item: null});
        this.service.getIncidentStatusReportCounts().then((response) => {
            open = response.open;
            awaitingApproval = response.awaiting_approval;
            resolved = response.resolved;
            closed = response.closed;
            pending = response.pending;

            data.datasets[0].data = [];
            data.datasets[0].data.push(open, awaitingApproval, resolved, closed, pending);
            data.labels = [data.labels[0] + " (" + open + ")", data.labels[1] + " (" + awaitingApproval + ")",
                data.labels[2] + " (" + resolved + ")", data.labels[3] + " (" + closed + ")", data.labels[4] + " (" + pending + ")"];
            let totalCount = new Number(open) + new Number(awaitingApproval) + new Number(resolved) + new Number(closed) + new Number(pending);
            this.setState({data: data, totalCount:totalCount});
            this.setState({item: this.getDoughnut()});
        });
    }
}
