import React from 'react';
import {AvGroup} from "availity-reactstrap-validation";
import {Col, Label, Row} from "reactstrap";
import AssetCategoryService from "../../shared/service/AssetCategoryService"
import AvField from "../../shared/component/form/AvField";

const defaultAssetType = {
    id: '',
    name:'',
    assetCategoryId: 1
    
};
export default class AssetTypeSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            assetType: defaultAssetType,
            assetCategoryList:[],
            pageMode: null
        };
        this.assetCategoryService=new AssetCategoryService();
    }

    componentDidMount = () => {
        this.clearData();
        this.assetCategoryService.findAllAssetCategory().then(response => this.setState({assetCategoryList: response}));
    }

    render() {
        return (
            <Row className="justify-content-center">
                <Col>
                    <AvGroup>
                        <Label for="assetCategoryId">Varlık Sınıfı<span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="select"
                            className="form-control"
                            name="assetCategoryId"
                            value={this.state.assetType.assetCategoryId}
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                }
                            }}
                            onChange={this.__handleChange}>
                        <option value='' key=''/>
                        {this.state.assetCategoryList.map(element => (
                            <option value={element.id} key={element.id}>
                                {element.name}
                            </option>
                        ))}
                        </AvField>
                    </AvGroup>
                    <AvGroup>
                        <Label for="name">Varlık Tipi Adı<span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="name"
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                },
                                minLength: {
                                    value: 1,
                                    errorMessage: 'Bu alan en az 1 karakterden oluşabilir.'
                                },
                                maxLength: {
                                    value: 50,
                                    errorMessage: 'Bu alan en fazla 50 karakterden oluşabilir.'
                                }
                            }}
                            value={this.state.assetType.name}
                            onChange={this.__handleChange}
                        />
                    </AvGroup>
                </Col>
            </Row>
        );
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let assetType = this.state.assetType;
        assetType[e.target.name] = value;
        this.setState({assetType: assetType});
    }

    setData = (data, pageMode) => {
        data.assetCategoryId= data.assetCategoryId ?  data.assetCategoryId.id: null
        this.setState({assetType: data, pageMode: pageMode});
    }

    getData = () => {
        return this.state.assetType;
    }

    clearData = () => {
        this.setState({assetType: Object.assign({}, defaultAssetType), pageMode: null});
    }

}
