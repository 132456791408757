import React from 'react';
import PropTypes from 'prop-types';
import {AvForm} from 'availity-reactstrap-validation';
import {Button, Col, Row} from 'reactstrap';
import Translate from "../../../shared/language/Translate";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import SHA256 from "crypto-js/sha256";
import PasswordStrengthBar from '../../../shared/component/password/password-strength-bar';
import {toast} from "react-toastify";
import AvField from "../../../shared/component/form/AvField";
import HsmUserService from "../../../shared/service/HsmUserService";

export default class PasswordChange extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newPassword: ''
        };
        this.service = new HsmUserService();
    }

    render() {
        return (
            <div>
                <Row className="justify-content-center">
                    <Col md="8">
                        <AvForm id="password-form" onValidSubmit={this.save}>
                            <AvField
                                name="currentPassword"
                                label='Eski Şifre'
                                placeholder='Eski Şifre'
                                type="password"
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: 'Bu alan zorunlu.'
                                    }
                                }}
                            />
                            <AvField
                                name="newPassword"
                                label='Yeni Şifre'
                                placeholder='Yeni Şifre'
                                type="password"
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: 'Bu alan zorunlu.'
                                    },
                                    minLength: {
                                        value: 8,
                                        errorMessage: 'Bu alan en az 8 karakterden oluşabilir.'
                                    },
                                    maxLength: {
                                        value: 50,
                                        errorMessage: 'Bu alan en fazla 50 karakterden oluşabilir.'
                                    }
                                }}
                                onChange={this.__handleChange}
                            />
                            <PasswordStrengthBar password={this.state.newPassword}/>
                            <AvField
                                name="confirmPassword"
                                label='Şifre Tekrar'
                                placeholder='Şifre Tekrar'
                                type="password"
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: 'Bu alan zorunlu.'
                                    },
                                    minLength: {
                                        value: 8,
                                        errorMessage: 'Bu alan en az 8 karakterden oluşabilir.'
                                    },
                                    maxLength: {
                                        value: 50,
                                        errorMessage: 'Bu alan en fazla 50 karakterden oluşabilir.'
                                    },
                                    match: {
                                        value: 'newPassword',
                                        errorMessage: 'Şifreler eşleşmedi!'
                                    }
                                }}
                            />
                            <Button color="success" type="submit" className="float-right">
                                <Translate contentKey="password.form.button">Kaydet</Translate>
                            </Button>
                        </AvForm>
                    </Col>
                </Row>
            </div>
        );
    }

    __handleChange = (e) => {
        let state = {};
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        state[e.target.name] = value;
        this.setState(state);
    }

    save = (event, values) => {
        if (!GeneralUtils.checkPasswordFormat(values.newPassword)) {
            return toast.warn("Şifre en az bir sayı, bir küçük harf, bir büyük harf, en az 8 karakter olmalıdır.");
        }
        let data = {
            "userName" : GeneralUtils.getUserName(),
            "currentPassword": SHA256(values.currentPassword).toString(),
            "newPassword": SHA256(values.newPassword).toString()
        };
        this.service.changePassword(data).then(response => {
            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                this.clearData();
                if (this.props.closeDialog) {
                    this.props.closeDialog();
                }
            }
        });
    }

    clearData = () => {
        this.setState({currentPassword: '', newPassword: '', confirmPassword: ''});
    }

}
PasswordChange.propTypes = {
    closeDialog: PropTypes.func
};