import React from 'react';
import {Panel} from 'primereact/panel';
import NoticeService from '../../../shared/service/NoticeService';
import {Accordion, AccordionTab} from "primereact/accordion";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import {NOTICE_TYPE} from "../../../shared/utils/Constants";
import dompurify from 'dompurify';


export default class NoticeBoard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            panelCollapsed: true
        };
        this.service = new NoticeService();
    }

    componentDidMount = () => {
        this.service.checkPage();
        this.service.findAllActive().then(response => this.setState({data: response}));
    }

    render() {
        return (
            <Panel header="Duyurular">
                {this.getNotice()}
            </Panel>
        );
    }

    getNotice = () => {
        return <Accordion activeIndex={this.state.activeIndex}
                          onTabChange={(e) => this.setState({activeIndex: e.index})}>
            {this.state.data.map((notice, i) => {
                return <AccordionTab header={ GeneralUtils.isNullOrEmpty(notice.noticeType) ? notice.title :  NOTICE_TYPE.map(element => {if(element.value === notice.noticeType) return '(' + element.name + ') - '+notice.title;})}
                                     toggleable={true} collapsed={this.state.panelCollapsed}
                                     onToggle={(e) => this.setState({panelCollapsed: e.value})}
                                     style={{
                                         'backgroundColor': '#' + notice.backgroundColor,
                                         'color': '#' + notice.textColor,
                                         width: '25%',
                                         marginTop: '2em'
                                     }}>
                    <div dangerouslySetInnerHTML={{__html: dompurify.sanitize(notice.content)}}/>
                </AccordionTab>;
            })}
        </Accordion>;
    }

    setData = (data, pageMode) => {
        this.setState({
            data: new Array(data),
            pageMode: pageMode
        });
    }

    clearData = () => {
        this.setState({
            data: [],
            pageMode: null
        });
    }
}
