import React from 'react';
import {Panel} from 'primereact/panel';
import DataTable from '../../../shared/component/dataTable/DataTable';
import LocationService from "../../../shared/service/LocationService";
import LocationColumn from '../../../shared/component/dataTable/columns/LocationColumn';
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import {Button} from 'reactstrap';
import Translate from "../../../shared/language/Translate";
import LocationSave from "./LocationSave";
import {TabPanel, TabView} from "primereact/tabview";
import {PAGE_MODE} from "../../../shared/utils/Constants";
import {AvForm} from "availity-reactstrap-validation";
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import AuditEvent from "../../administration/AuditEvent";
import Dialog from "../../../shared/component/dialog/Dialog";


export default class Location extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            selected: {},
            pageMode: null
        };
        this.service = new LocationService();
    }

    componentDidMount = () => {
        this.service.checkPage();
    }

    render() {
        return (
            <Panel header="Lokasyon Yönetimi">
                <Toolbar display={GeneralUtils.checkPrivilege(LocationColumn.name, 'Create')}>
                    <div className="p-toolbar-group-right">
                        <Button color="primary" onClick={() => {
                            this.setState({displayDialog: true, pageMode: PAGE_MODE.CREATE, activeIndex: 0});
                            this.refLocationSave.clearData()
                        }}>
                            <Translate contentKey="entity.action.add">Yeni Kayıt</Translate>
                        </Button>
                    </div>
                </Toolbar>
                <DataTable ref={(elem) => this.refDataTable = elem} fields={LocationColumn.fields} dataURL="location/findAllLocations" objectName={LocationColumn.name}
                           selectionMode="single" onDataSelect={this.onDataSelect} action={true}
                           loading={this.state.loading} export={true}/>


                <Dialog visible={this.state.displayDialog}
                        header={this.state.pageMode === PAGE_MODE.UPDATE ? 'Lokasyon Güncelleme' : 'Yeni Lokasyon Oluşturma'}
                        onHide={() => this.setState({displayDialog: false})} style={{width: '50vw'}}>
                    <AvForm onValidSubmit={this.submit} beforeSubmitValidation={() => this.setState({activeIndex: 0})}>
                        <Toolbar
                            display={GeneralUtils.getDialogToolbarDisplay(LocationColumn.name, this.state.pageMode)}>
                            <div className="p-toolbar-group-right">
                                <Button color="primary" type="submit" className="float-right">
                                    <Translate contentKey="entity.action.save">Kaydet</Translate>
                                </Button>
                                <Button color="primary" className="float-right"
                                        onClick={() => this.closeDisplayDialog()}>
                                    <Translate contentKey="entity.action.save">Vazgeç</Translate>
                                </Button>
                            </div>
                        </Toolbar>
                        <TabView activeIndex={this.state.activeIndex}
                                 onTabChange={(e) => this.setState({activeIndex: e.index})} renderActiveOnly={false}>
                            <TabPanel header="Lokasyon Bilgileri">
                                <LocationSave save={this.refresh} ref={(elem) => this.refLocationSave = elem}
                                              refresh={(location)=> {
                                                  if(this.props.refresh) {
                                                      this.props.refresh(location);
                                                  }
                                              }}
                                              closeDisplayDialog={this.closeDisplayDialog}/>
                            </TabPanel>
                            <TabPanel header="Tarihçe" rightIcon="pi pi-user"
                                      disabled={this.state.pageMode === PAGE_MODE.CREATE}
                                      headerClassName={this.state.pageMode === PAGE_MODE.CREATE && 'displayNone'}>
                                <AuditEvent ref={elem => this.refAuditEvent = elem} objectName={LocationColumn.name}/>
                            </TabPanel>
                        </TabView>
                    </AvForm>
                </Dialog>
            </Panel>
        );
    }

    submit = () => {
        this.refLocationSave.save();
    }

    refresh = () => {
        this.refDataTable.refresh();
        this.setState({displayDialog: false});
    }

    save = (newData) => {
        if (!GeneralUtils.isNullOrEmpty(newData.id)) {
            this.service.update(newData).then((response) => {
                this.refLocationSave.setData(PAGE_MODE.UPDATE);
                this.refresh();
            });
        } else {
            this.service.create(newData).then((response) => {
                this.refLocationSave.setData(PAGE_MODE.CREATE);
                this.refresh();
            });
        }
    }

    onDataSelect = (data) => {
        let pageMode = GeneralUtils.getPageModeFromPrivilege(LocationColumn.name);
        this.setState({
            displayDialog: true,
            pageMode: pageMode,
            documentIdList:[],
            activeIndex: 0
        });
        this.refLocationSave.setData(Object.assign({}, data), pageMode);
        this.refAuditEvent.setData(LocationColumn.name, data.id);
    }

    closeDisplayDialog = () => {
        this.setState({displayDialog: false});
    }

}
