import React from 'react';
import {Panel} from 'primereact/panel';
import DataTable from '../../../shared/component/dataTable/DataTable';
import UserColumn from '../../../shared/component/dataTable/columns/HsmUserColumn';
import HsmUserSave from "./HsmUserSave";
import {Button} from 'reactstrap';
import Translate from "../../../shared/language/Translate";
import {TabPanel, TabView} from 'primereact/tabview';
import {PAGE_MODE} from "../../../shared/utils/Constants";
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import {AvForm} from "availity-reactstrap-validation";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import AuditEvent from "../../administration/AuditEvent";
import Dialog from "../../../shared/component/dialog/Dialog";
import HsmUserService from "../../../shared/service/HsmUserService";
import HsmUserQuickSave from "./HsmUserQuickSave";
import ContactService from "../../../shared/service/ContactService";

export default class HsmUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            selected: {},
            removeLockDisplay: false
        };
        this.service = new HsmUserService();
        this.contactService = new ContactService();
    }

    componentDidMount = () => {
        this.service.checkPage();
    }

    render() {
        return (
            <Panel header="Kullanıcı Yönetimi">


                <Toolbar display={GeneralUtils.checkPrivilege(UserColumn.name, 'Create')}>
                    <div className="p-toolbar-group-right">
                        <Button color="primary" onClick={this.addNewContactUserAndTeam}>
                            <Translate contentKey="entity.action.add">Yeni Kayıt (Hızlı)</Translate>
                        </Button>
                    </div>
                    <div className="p-toolbar-group-right">
                        <Button color="primary" onClick={this.addNew}>
                            <Translate contentKey="entity.action.add">Yeni Kayıt</Translate>
                        </Button>
                    </div>
                </Toolbar>
                <DataTable ref={(elem) => this.refDataTable = elem} fields={UserColumn.fields} objectName={UserColumn.name}
                           dataURL="hsmUser/findAllFilteredOrganization" selectionMode="single" onDataSelect={this.onDataSelect}
                           export={true}/>

                <Dialog visible={this.state.displayDialog}
                        header={GeneralUtils.getDialogHeader('Kullanıcı', this.state.pageMode)}
                        onHide={() => this.setState({displayDialog: false})} style={{width: '60%'}}>
                    <AvForm onValidSubmit={this.save}>
                        <Toolbar display={GeneralUtils.getDialogToolbarDisplay(UserColumn.name, this.state.pageMode)}>
                            <div className="p-toolbar-group-right">
                                <Button color="primary" type="submit" className="float-right">
                                    <Translate contentKey="entity.action.save">Kaydet</Translate>
                                </Button>

                                <Button color="primary" className="float-right"
                                        onClick={() => this.setState({displayDialog: false})}>
                                    <Translate contentKey="entity.action.save">Vazgeç</Translate>
                                </Button>
                                {(this.state.pageMode === PAGE_MODE.UPDATE && this.state.userStatus === 'P') &&
                                < Button color="primary" className="float-right" onClick={this.sendActivationMail}>
                                    &nbsp;
                                    <Translate contentKey="entity.action.sendActivationMail">Aktivasyon E-postası
                                        Gönder</Translate>
                                </Button>}
                                {this.state.removeLockDisplay &&
                                <Button color="primary" className="float-right" onClick={this.removeLock}>
                                    &nbsp;
                                    <Translate contentKey="entity.action.removeLock">Kilit Kaldır</Translate>
                                </Button>}
                            </div>
                        </Toolbar>
                        <TabView activeIndex={this.state.activeIndex} renderActiveOnly={false}
                                 onTabChange={(e) => this.setState({activeIndex: e.index})}>
                            <TabPanel header="Kullanıcı Bilgisi">
                                <HsmUserSave ref={elem => this.refHsmUserSave = elem}/>
                            </TabPanel>

                            <TabPanel header="Tarihçe" rightIcon="pi pi-user"
                                      disabled={this.state.pageMode === PAGE_MODE.CREATE}
                                      headerClassName={this.state.pageMode === PAGE_MODE.CREATE && 'displayNone'}>
                                <AuditEvent ref={elem => this.refAuditEvent = elem} objectName={UserColumn.name}/>
                            </TabPanel>
                        </TabView>
                    </AvForm>
                </Dialog>


                <Dialog visible={this.state.displayDialogUser}
                        header={GeneralUtils.getDialogHeader('Kullanıcı', this.state.pageMode)}
                        onHide={() => this.setState({displayDialogUser: false})} style={{width: '60%'}}>
                    <AvForm onValidSubmit={this.saveQuickUser}>
                        <Toolbar display={GeneralUtils.getDialogToolbarDisplay(UserColumn.name, this.state.pageMode)}>
                            <div className="p-toolbar-group-right">
                                <Button color="primary" type="submit" className="float-right">
                                    <Translate contentKey="entity.action.save">Kaydet</Translate>
                                </Button>

                                <Button color="primary" className="float-right"
                                        onClick={() => this.setState({displayDialogUser: false})}>
                                    <Translate contentKey="entity.action.save">Vazgeç</Translate>
                                </Button>

                            </div>
                        </Toolbar>
                        <TabView activeIndex={this.state.activeIndex} renderActiveOnly={false}
                                 onTabChange={(e) => this.setState({activeIndex: e.index})}>
                            <TabPanel header="Kullanıcı Bilgisi">
                                <HsmUserQuickSave ref={elem => this.refHsmUserQuickSave = elem}/>
                            </TabPanel>


                        </TabView>
                    </AvForm>
                </Dialog>
            </Panel>
        );
    }

    refresh = () => {
        this.refDataTable.refresh();
    }

    onDataSelect = (data) => {
        let pageMode = GeneralUtils.getPageModeFromPrivilege(UserColumn.name);
        this.refHsmUserSave.clearData();
        this.setState({
            displayDialog: true,
            pageMode: pageMode,
            activeIndex: 0,
            removeLockDisplay: data.locked === 'Y',
            userStatus: data.status
        });
        this.refHsmUserSave.setData(Object.assign({}, data), pageMode);
        this.refAuditEvent.setData(UserColumn.name, data.id);
    }

    addNewContactUserAndTeam = () => {
        this.setState({displayDialogUser: true, pageMode: PAGE_MODE.CREATE, activeIndex: 0, removeLockDisplay: false});
        this.refHsmUserQuickSave.clearData();
    }

    addNew = () => {
        this.setState({displayDialog: true, pageMode: PAGE_MODE.CREATE, activeIndex: 0, removeLockDisplay: false});
        this.refHsmUserSave.clearData();
    }

    removeLock = () => {
        let user = this.refHsmUserSave.getData();
        let data = {
            "userName": user.userName
        }
        this.service.resetLoginRetry(data).then(() => {
            this.setState({displayDialog: false});
            this.refresh();
        });
    }

    save = () => {
        let user = this.refHsmUserSave.getData();
        if (!GeneralUtils.isNullOrEmpty(user.id)) {
            this.service.update(user).then((response) => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    this.setState({displayDialog: false});
                    this.refresh();
                }
            });
        } else {
            this.service.create(user).then((response) => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    this.setState({displayDialog: false});
                    this.refresh();
                }
            });
        }
    }

    saveQuickUser = () => {

        let contact = this.refHsmUserQuickSave.getData();
        if (!GeneralUtils.isNullOrEmpty(contact.id)) {
            this.service.hsmUserQuickSave(contact).then((response) => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    this.setState({displayDialogUser: false});
                    this.refresh();
                }
            });
        } else {
            this.contactService.hsmUserQuickSave(contact).then((response) => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    this.setState({displayDialogUser: false});
                    this.refresh();
                }
            });
        }
    }

    sendActivationMail = () => {
        let user = this.refHsmUserSave.getData();
        this.service.sendActivationMail(user);
    }
}
