import React from 'react';
import DataTableAll from '../../shared/component/dataTable/DataTableAll';
import DataTableWithButton from '../../shared/component/dataTable/DataTableWithButton';
import ContactService from '../../shared/service/ContactService';
import ContactColumnForButton from '../../shared/component/dataTable/columns/ContactColumnForButton';
import GeneralUtils from "../../shared/utils/GeneralUtils";
import {ASSET_CATEGORY, PAGE_MODE, REQUEST_TYPE} from "../../shared/utils/Constants";
import RequestContactService from '../../shared/service/RequestContactService';
import Toolbar from "../../shared/component/toolbar/Toolbar";
import {Dropdown} from "primereact/dropdown";
import Dialog from "../../shared/component/dialog/Dialog";
import {SplitButton} from "primereact/splitbutton";
import RequestAssetColumn from "../../shared/component/dataTable/columns/RequestAssetColumn";
import Contact from "../account/contact/Contact";


export default class RequestContact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contactData: [],
            pageMode: PAGE_MODE.CREATE,
            status: '',
            organizationId: null,
            transfereeOrganizationId: -1,
            contactList:null ,
            requestType: this.props.requestType
        };
        this.contactService = new ContactService();
        this.requestContactService = new RequestContactService();
    }

    componentDidMount = () => {
            let items = [];
            if(GeneralUtils.checkPrivilege(ContactColumnForButton.name, 'Add')){
                items.push({
                    label: 'Kontak Ekle',
                    icon: 'pi pi-user',

                    command: (e) => {
                        this.addNew();
                    },
                    /*disabled: !GeneralUtils.checkPrivilege(ContactColumnForButton.name, 'Add')*/
                });
            }

        if(GeneralUtils.checkPrivilege('Contact', 'Create')){
            items.push({
                label: 'Kontak Oluştur',
                icon: 'pi pi-user',
                command: (e) => {
                    this.props.createNewContact();
                },
              /*  disabled: !GeneralUtils.checkPrivilege('Contact', 'Create')*/
            });
        }

            this.setState({items: items})
        }

    componentWillReceiveProps = (nextProps) => {
        this.setState({
            organizationId: nextProps.organizationId,
            locationId: nextProps.locationId,
            transfereeOrganizationId: nextProps.transfereeOrganizationId,
            contactList: !GeneralUtils.isNullOrEmpty(nextProps.getContactList) ? nextProps.getContactList : []
        });
    }

    render() {
        return (
            <div>
                {(this.state.pageMode !== PAGE_MODE.VIEW || this.state.status === 'DRAFT') &&
                <Toolbar display={true}>
                    <div className="p-toolbar-group-right">


                        <SplitButton ref={elem => this.refSplitButton = elem} label="Ekle" icon="pi pi-plus"
                                     disabled={!GeneralUtils.checkPrivilege(ContactColumnForButton.name, 'Add') && !GeneralUtils.checkPrivilege('Contact', 'Create') }
                                     onClick={this.showSplitButtonMenu}
                                     model={this.state.items}></SplitButton>

                      {/*  <Button color="primary" onClick={this.addNew}
                                disabled={!GeneralUtils.checkPrivilege(ContactColumnForButton.name, 'Add')}>
                            <Translate contentKey="entity.action.add">Ekle</Translate>
                        </Button>*/}
                    </div>
                </Toolbar>
                }

                <DataTableWithButton fields={ContactColumnForButton.fields} objectName={ContactColumnForButton.name}
                                     data={this.state.contactList}
                                     delete={this.deleteRow}
                                     editorContactType={this.editorContactType}
                />

                <Dialog visible={this.state.displayDialog} header="Kontak Listesi"
                        onHide={() => this.setState({displayDialog: false})} style={{width: '80vw'}}>
                    <DataTableAll fields={ContactColumnForButton.fields} privilege="allow"
                               data={this.state.contactData}
                               selectionMode="single" onDataSelect={this.onDataSelect}/>
                </Dialog>
            </div>
        );
    }

    showSplitButtonMenu = () => {
        this.refSplitButton.onDropdownButtonClick();
    }

    editorContactType = (props) => {
        let options = [
            {label: 'Uygulama Kullanıcısı', value: 'ApplicationUser'},
            {label: 'Yardım Masası', value: 'HelpdeskPerson'},
            {label: 'Teknisyen', value: 'SupportPerson'},
            {label: 'Teknik Kişi', value: 'TechnicalPerson'},
            {label: 'Yetkili Kişi', value: 'AuthorizedPerson'}
        ];
        return (
            <div>
                {(this.state.pageMode !== PAGE_MODE.VIEW || this.state.status === 'DRAFT') ?
                    <Dropdown value={props.value[props.rowIndex].contactType} options={options}
                              onChange={(e) => this.onEditorValueChange(props, e.value)} style={{width: '100%'}}/>
                    : props.value[props.rowIndex].contactType}
            </div>
        );
    }

    onEditorValueChange = (props, value) => {
        let contactList = [...props.value];
        contactList[props.rowIndex][props.field] = value;
        this.setState({contactList: contactList});
    }


    addNew = () => {
        this.setState({displayDialog: true, pageMode: PAGE_MODE.CREATE});
        this.findContactsInSameOrganization();

    }

    findContactsInSameOrganization = () => {
        this.contactService.findContactsInSameOrganization(this.state.organizationId, this.state.transfereeOrganizationId != undefined ? this.state.transfereeOrganizationId : -1).then(response =>
            this.setState({contactData: response}));
    }

    onDataSelect = (data, isDeleted) => {

        if(isDeleted === undefined) {
            data.isDeleted = true;
        }

        let contactList = this.state.contactList;
        var existsInList = false;
        for (var i = 0; i < contactList.length; i++) {
            if (data.id == contactList[i].id) {
                existsInList = true;
            }
        }
        if (!existsInList) {
            contactList.push(data);
        }

        this.setState({contactList: contactList, displayDialog: false});
        if (this.props.setContactList) {
            this.props.setContactList(contactList);
        }
    }

    setData = (data, requestType, pageMode, status) => {
        this.requestContactService.findByRequestTypeAndRequestId(requestType, data.id).then((response) => {
            let contactIds = [];
            for (let i of response) {
                contactIds.push(i.contactId.id);
            }
            this.contactService.findContacts(data.id, contactIds).then(response => {
                    this.setState({
                        contactList: response ? response : [], pageMode: pageMode, status: status
                    });
                    if (this.props.setContactList) {
                        this.props.setContactList(response);
                    }
                }
            )
        });
    }

    getData = () => {
        return this.state.contactList;
    }

    setPageModeAndStatus = (pageMode, status) => {
        this.setState({pageMode: pageMode, status: status});
    }

    deleteRow = (rowData) => {
        let contactList = this.state.contactList;
        let newContactList = this.arrayRemove(contactList, rowData);
        this.setState({contactList: newContactList});
        if (this.props.setContactList) {
            this.props.setContactList(newContactList);
        }
    }

    onContactDataSelect = (data) => {

        this.contactService.findById(data.id)
            .then(response => {
                let contactList = this.state.contactList;
                var existsInList = false;
                for (var i = 0; i < contactList.length; i++) {
                    if (data.id == contactList[i].id) {
                        existsInList = true;
                    }
                }
                if (!existsInList) {
                    contactList.push(response);
                }
                response.isDeleted = true;
                this.setState({contactList: contactList, displayDialog: false});
                if (this.props.setContactList) {
                    this.props.setContactList(contactList);
                }
            });
    }

    clearData = () => {
        this.setState({contactList : null});
    }

    addContact =(data) =>{
        data.forEach(item => {
          this.onDataSelect(item);
        });
    }

    arrayRemove = (arr, element) => {
        return arr.filter(function (el) {
            return el.id != element.id;
        });
    }

}
