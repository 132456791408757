import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class ModelService extends Service {
    constructor() {
        super();
        this.baseURL = 'model';
    }

    findByName(name) {
        return fetch(SERVER_URL + this.baseURL + '/findByName/' + name, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    findModelsByBrandId(id) {
        return fetch(SERVER_URL + this.baseURL + '/' + id, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    findByModelIdAndBrandId(id) {
        return fetch(SERVER_URL + this.baseURL + '/findByModelIdAndBrandId/' + id, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

}