import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class ServiceSubcategoryService extends Service{
    constructor() {
        super();
        this.baseURL = 'serviceSubcategory';
    }

    findIncidentSubCategories(serviceCategoryId) {
        return fetch(SERVER_URL + this.baseURL + '/findIncidentSubCategories/' + serviceCategoryId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    findServiceRequestSubCategories(serviceCategoryId) {
        return fetch(SERVER_URL + this.baseURL + '/findServiceRequestSubCategories/' + serviceCategoryId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    findByCategoryId(serviceCategoryId){
        return fetch(SERVER_URL + this.baseURL + '/' + serviceCategoryId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    findAllOrderByStatus() {
        return fetch(SERVER_URL + this.baseURL, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")},
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }
}