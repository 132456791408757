export const CONFIG = {
    LOGO: 'assets/layout/images/TENANT/TENANT_ID/logo.png',
    LOGO_HERMES: 'assets/layout/images/TENANT/TENANT_ID/logo-hermes.png',
    BACKGROUND: 'url(assets/layout/images/TENANT/TENANT_ID/background.jpg)',
    TT:{
        LOGO: 'assets/layout/images/logo-tt.png',
    }
}


