import Service from './Service';
import GeneralUtils from "../utils/GeneralUtils";
import {SERVER_URL} from "../utils/Constants";

export default class TeamContactService extends Service{
    constructor() {
        super();
        this.baseURL = 'teamContact';
    }

    findByTeamId(teamId) {
        return fetch(SERVER_URL + this.baseURL+ '/'+ teamId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }
    findTeamsByContactId(contactId) {
        return fetch(SERVER_URL + this.baseURL+ '/findTeamsByContactId/'+ contactId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
    });
    }
}