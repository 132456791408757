import React from 'react';
import {Panel} from 'primereact/panel';
import DataTable from '../../../shared/component/dataTable/DataTable';
import HsmOrganizationService from '../../../shared/service/HsmOrganizationService';
import HsmOrganizationColumn from '../../../shared/component/dataTable/columns/HsmOrganizationColumn';
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import {Button} from 'reactstrap';
import Translate from "../../../shared/language/Translate";
import {PAGE_MODE} from "../../../shared/utils/Constants";
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import Dialog from "../../../shared/component/dialog/Dialog";
import HsmOrganization from "./HsmOrganization";


export default class HsmOrganizationList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageMode: null
        };
        this.service = new HsmOrganizationService();
    }

    componentDidMount = () => {
        this.service.checkPage();
    }

    render() {
        return (
            <Panel header="Organizasyon Yönetimi">
                <Toolbar display={GeneralUtils.checkPrivilege(HsmOrganizationColumn.name, 'Create')}>
                    <div className="p-toolbar-group-right">
                        <Button color="primary" onClick={this.addNew}>
                            <Translate contentKey="entity.action.add">Yeni Kayıt</Translate>
                        </Button>
                    </div>
                </Toolbar>
                <DataTable ref={(elem) => this.refDataTable = elem} fields={HsmOrganizationColumn.fields}
                           dataURL="hsmOrganization/findAllOrganizations"
                           objectName={HsmOrganizationColumn.name}
                           selectionMode="single" onDataSelect={this.onDataSelect} export={true}/>

                <Dialog visible={this.state.displayDialog}
                        header={GeneralUtils.getDialogHeader('Organizasyon', this.state.pageMode)}
                        onHide={() => this.setState({displayDialog: false})} style={{width: '50vw'}}>
                    <HsmOrganization ref={(elem) => this.refHsmOrganization = elem}
                                     closeDisplayDialog={this.closeDisplayDialog} refresh={this.refresh}/>
                </Dialog>
            </Panel>
        );
    }

    addNew = () => {
        this.setState({displayDialog: true, pageMode: PAGE_MODE.CREATE});
        this.refHsmOrganization.addNew();
    }

    refresh = () => {
        this.refDataTable.refresh();
        this.setState({displayDialog: false});
    }


    onDataSelect = (data) => {
        let pageMode = GeneralUtils.getPageModeFromPrivilege(HsmOrganizationColumn.name);
        this.setState({
            displayDialog: true,
            pageMode: pageMode
        });
        this.refHsmOrganization.setData(Object.assign({}, data), pageMode);
    }

    closeDisplayDialog = () => {
        this.setState({displayDialog: false});
    }
}
