import React from 'react';
import {Panel} from 'primereact/panel';
import IncidentPendingReportService from "../../../shared/service/IncidentPendingReportService";
import DataTable from "../../../shared/component/dataTable/DataTable";
import {PAGE_MODE} from "../../../shared/utils/Constants";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import IncidentPendingReportColumn from "../../../shared/component/dataTable/columns/IncidentPendingReportColumn";
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import {Button, Label} from "reactstrap";
import Translate from "../../../shared/language/Translate";
import {Calendar} from "primereact/calendar";
import {AvForm} from "availity-reactstrap-validation";
import {toast} from "react-toastify";


export default class IncidentPendingReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            selected: {},
            pageMode: null,
            loading: false,
            dateStart: null,
            dateEnd: null,
            dateStartDate: null,
            dateEndDate: null
        };
        this.service = new IncidentPendingReportService();
    }

    componentDidMount = () => {
        this.service.checkPage();
        // this.defaultDateAndData();
    }


    render() {

        const tr = {
            firstDayOfWeek: 1,
            monthNames: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
            monthNamesShort: ['Oca', 'Şub', 'Mar', 'Nis', 'May', 'Haz', 'Tem', 'Ağu', 'Eyl', 'Eki', 'Kas', 'Ara'],
            dayNames: ['Pazartesi,', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi', 'Pazar'],
            dayNamesShort: ['Pzt', 'Sal', 'Çar', 'Prş', 'Cm', 'Cmr', 'Paz'],
            dayNamesMin: ['P', 'S', 'Ç', 'P', 'C', 'Ct', 'Pz'],
            today: ['Bugün'],
            clear: ['Temizle']
        };

        return (
            <Panel header="Askı Yorumları Raporu">
                <AvForm onValidSubmit={this.submit}>
                    <Toolbar display={true}>
                        <div className="p-toolbar-group-right ">
                            <Button color="primary" type="submit" className="float-right">
                                <Translate>Göster</Translate>
                            </Button>
                        </div>
                    </Toolbar>
                    <div className="container-fluid">
                        <h3>Filtre</h3>
                        <div className="row">
                            <div className="col-6" style={{padding: 0}}>
                                <div style={{width: 300}}>
                                    <div className='p-grid p-fluid '>
                                        <Calendar
                                            tooltip="Başlangıç Tarihi"
                                            required={true}
                                            style={{margin: 20}}
                                            placeholder="Başlangıç Tarihi Seç"
                                            value={this.state.dateStartDate}
                                            onChange={e => this._onChangeDateStart(e)}
                                            dateFormat='dd-mm-yy'
                                            showButtonBar={true}
                                            readOnlyInput={true}
                                            showIcon={true}
                                            locale={tr}
                                            onClearButtonClick={e => this.setState({
                                                dateStart: "",
                                            })
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-6" style={{padding: 0}}>
                                <div style={{width: 300}}>
                                    <div className='p-grid p-fluid '>
                                        <Calendar
                                            tooltip="Bitiş Tarihi"
                                            required={true}
                                            style={{margin: 20}}
                                            placeholder="Bitiş Tarihi Seç"
                                            value={this.state.dateEndDate}
                                            onChange={e => this._onChangeDateEnd(e)}
                                            dateFormat='dd-mm-yy'
                                            showButtonBar={true}
                                            readOnlyInput={true}
                                            showIcon={true}
                                            locale={tr}
                                            onClearButtonClick={e => this.setState({
                                                dateEnd: "",
                                            })
                                            }
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <Label>
                                Başlangıç ve Bitiş Tarihleri Arasında Açılan Arızalara Ait Askı Yorumları Raporudur.
                            </Label>
                        </div>
                    </div>

                </AvForm>


                <DataTable ref={(elem) => this.refDataTable = elem} fields={IncidentPendingReportColumn.fields}
                           dataURL="incidentPendingReport/findPendingIncidentByStartDateAndEndDate"
                           objectName={IncidentPendingReportColumn.name} loading={this.state.loading} export={true}
                           autoLoad={false} refresh={false}/>
            </Panel>
        );
    }

    submit = () => {
        let dateStart = this.state.dateStart;
        let dateEnd = this.state.dateEnd;
        let dateStartDate = this.state.dateStartDate;
        let dateEndDate = this.state.dateEndDate;
        let now = Date.now();

        if ((!GeneralUtils.isNullOrEmpty(dateStartDate) && !GeneralUtils.isNullOrEmpty(dateEndDate))) {
            if ((dateStartDate > now || dateEndDate > now)) {
                toast.warn("Başlangıç ve/veya Bitiş Tarihi Bugünün Tarihinde İleri Olamaz.");
                return;
            }
            if (dateStartDate > dateEndDate) {
                toast.warn("Başlangıç Tarihi Bitiş Tarihinden Sonra Olamaz.");
                return;
            }
        }

        if (GeneralUtils.isNullOrEmpty(dateStartDate) || GeneralUtils.isNullOrEmpty(dateEndDate)) {
            toast.warn("Lütfen Başlangıç ve Bitiş Tarihi Alanlarını Doldurunuz.");
            return;
        }

        this.refDataTable.setParameter(dateStart, dateEnd);

    }

    defaultDateAndData = () => {
        let date = new Date(), y = date.getFullYear(), m = date.getMonth();
        let firstDay = new Date(y, m, 1);
        let lastDay = new Date();

        let fullFirstDay = this.getDate(firstDay);
        let fullLastDay = this.getDate(lastDay);

        this.setState({
            dateStart: fullFirstDay, dateStartDate: firstDay, dateEnd: fullLastDay, dateEndDate: lastDay
        });

        this.refDataTable.setParameter(fullFirstDay, fullLastDay);
    }

    onDataSelect = (data) => {
        this.setState({displayDialog: true, pageMode: PAGE_MODE.UPDATE, activeIndex: 0});
        this.refBbProductSave.setData(Object.assign({}, data), PAGE_MODE.UPDATE);
    }

    closeDisplayDialog = () => {
        this.setState({displayDialog: false});
    }

    _onChangeDateStart = (value) => {
        let fullDate = this.getDate(value.value);

        this.setState({
            dateStart: fullDate, dateStartDate: value.value
        });
    }

    _onChangeDateEnd = (value) => {
        let fullDate = this.getDate(value.value);

        this.setState({
            dateEnd: fullDate, dateEndDate: value.value
        });
    }

    getDate(dateValue) {
        let date = new Date(dateValue);
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        return day + "-" + month + "-" + year;
    }
}
