import React from 'react';
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import {Button, Label, Row, Col} from "reactstrap";
import Translate from "../../../shared/language/Translate";
import BbProductColumn from "../../../shared/component/dataTable/columns/BbProductColumn.json";
import {PAGE_MODE} from "../../../shared/utils/Constants";
import HsmSubnetPoolService from "../../../shared/service/HsmSubnetPoolService";
import TenantService from "../../../shared/service/TenantService";
import {AvForm, AvGroup} from "availity-reactstrap-validation";
import AvField from "../../../shared/component/form/AvField";
import CityService from "../../../shared/service/CityService";
import HsmSubnetColumn from "../../../shared/component/dataTable/columns/HsmSubnetColumn.json";
import TtRegionService from "../../../shared/service/TtRegionService";
import HsmSubnetPool from "./HsmSubnetPool";
import {toast} from "react-toastify";

export default class HsmSubnetPoolForRegion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hsmSubnetPoolList: [],
            hsmSubnetPoolRemovedList: [],
            pageMode: PAGE_MODE.UPDATE,
            cityList: [{id: 0, name: 'Adana'}],
            groupId: null,
            cityId: 1,
            ttRegionId: '',
            ttRegion: null
        };
        this.service = new HsmSubnetPoolService();
        this.tenantService = new TenantService();
        this.cityService = new CityService();
        this.ttRegionService = new TtRegionService();
    }

    componentDidMount = () => {
        this.cityService.findAll().then(response => this.setState({cityList: response}));
        this.findTtRegionByCityId(this.state.cityId);
    }

    componentWillReceiveProps = (nextProps) => {
        if (this.props.data) {
            this.setState({hsmSubnetPoolList: nextProps.data, pageMode: nextProps.pageMode, status: nextProps.status});
        }
    }

    render() {
        return (
            <AvForm onValidSubmit={this.save} beforeSubmitValidation={() => this.setState({activeIndex: 0})}>
                <Toolbar display={true}>
                    <div className="p-toolbar-group-right">
                        <Button color="primary" type="submit" className="float-right">
                            <Translate contentKey="entity.action.save">Kaydet</Translate>
                        </Button>
                        <Button color="primary" className="float-right"
                                onClick={this.props.closeDisplayDialog}>
                            <Translate contentKey="entity.action.save">Vazgeç</Translate>
                        </Button>
                    </div>
                </Toolbar>
                <Row className="container">
                    <Col>

                        <AvGroup>
                            <Label for="cityId">
                                <Translate contentKey="hsmSubnetPool.cityId">İl Adı</Translate>
                                <span style={{color: 'red'}}>(*)</span>
                            </Label>
                            <AvField type="select" className="form-control" name="cityId"
                                     disabled={this.state.pageMode === PAGE_MODE.VIEW || this.state.pageMode === PAGE_MODE.UPDATE}
                                     value={this.state.cityId}
                                     onChange={this.__handleChangeCity}>
                                {this.state.cityList.map(element => (
                                    <option value={element.id} key={element.id}>
                                        {element.name}
                                    </option>
                                ))}
                            </AvField>
                        </AvGroup>

                        <AvGroup>
                            <Label for="ttRegionId">
                                <Translate contentKey="hsmSubnetPool.ttRegionId">TT Bölge Adı</Translate>
                                <span style={{color: 'red'}}>(*)</span>
                            </Label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="ttRegionId"
                                disabled={this.state.pageMode === PAGE_MODE.VIEW || this.state.pageMode === PAGE_MODE.UPDATE}
                                value={this.state.ttRegionId}
                                disabled={true}
                            />
                        </AvGroup>

                    </Col>
                </Row>
                <Row>
                    <HsmSubnetPool ref={(elem) => this.refHsmSubnetPool = elem}/>
                </Row>
            </AvForm>
        );
    }

    setData = (data, pageMode) => {
        this.clearData();
        this.setState({pageMode: pageMode, cityId: data[0].cityId.id, groupId: data[0].groupId});
        this.findTtRegionByCityId(this.state.cityId);
        this.refHsmSubnetPool.setData(data, pageMode);
    }

    getData = () => {
        return this.state.hsmSubnetPoolList;
    }

    clearData = () => {
        this.setState({
            hsmSubnetPoolList: [],
            hsmSubnetPoolRemovedList: [],
            groupId: null,
            cityId: 1,
            ttRegion: null
        });
        this.findTtRegionByCityId(this.state.cityList[0].id);
        this.refHsmSubnetPool.clearData();

    }

    onDataSelect = (data) => {
        let pageMode = GeneralUtils.getPageModeFromPrivilege(BbProductColumn.name);
        this.setState({displayDialog: true, pageMode: pageMode});
        this.refHsmSubnetPoolSave.clearData();
        this.refHsmSubnetPoolSave.setData(Object.assign({}, data), pageMode);
    }

    closeDisplayDialog = () => {
        this.setState({displayDialog: false});
    }

    setPageMode = (pageMode) => {
        this.setState({pageMode: pageMode});
    }

    __handleChangeCity = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        this.setState({cityId: value});
        this.findTtRegionByCityId(value);
    }

    findTtRegionByCityId = (cityId) => {
        this.ttRegionService.findTtRegionByCityId(cityId).then(response => {
            this.setState({ttRegion: response, ttRegionId: response.ttRegionName});
        });
    }

    save = () => {
        let hsmSubnetPoolList = this.refHsmSubnetPool.getData();
        let hsmSubnetPoolRemovedList = this.refHsmSubnetPool.getDataRemoved();
        let groupId = null;
        if (!GeneralUtils.__isValueEmpty(this.state.groupId)) {
            groupId = this.state.groupId;
            hsmSubnetPoolList.forEach((element) => {
                element.cityId = this.state.cityId;
                element.ttRegionId = this.state.ttRegion.id;
                element.isUsed = 'N'
                GeneralUtils.addUpdateInfo(element);
            });

        } else {
            hsmSubnetPoolList.forEach((element) => {
                element.cityId = this.state.cityId;
                element.ttRegionId = this.state.ttRegion.id;
                element.isUsed = 'N'
                GeneralUtils.addCreateInfo(element);
            });
        }

        let data = {
            groupId: groupId,
            hsmSubnetPoolList: hsmSubnetPoolList,
            hsmSubnetPoolRemovedList: hsmSubnetPoolRemovedList
        };

        this.service.saveOrUpdate(data).then((response) => {
            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                this.props.closeDisplayDialog();
            }
        });
    }
}


