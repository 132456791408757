import React from 'react';
import {Fieldset} from "primereact/fieldset";
import {AvGroup} from "availity-reactstrap-validation";
import {Col, Label, Row} from "reactstrap";
import Translate from "../../../shared/language/Translate";
import AvField from "../../../shared/component/form/AvField";
import {STATUS} from "../../../shared/utils/Constants";
import PriceService from "../../../shared/service/PriceService";

const defaultProductOffering = {
    productSpecId: {
        name: "",
        bandwidthId: {
            accessTechnology: "",
            downloadSpeed: "",
            uploadSpeed: ""
        },
        organizationId: {}
    }
}
export default class ProductOfferingSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            productOffering: defaultProductOffering,
            pageMode: null
        };
        this.priceService = new PriceService();
    }

    render() {
        return (
            <div>
                <Row className="justify-content-center">
                    <Col md={6}>
                        <Fieldset legend="Teklif Bilgileri">
                            <AvGroup>
                                <Label for="tariffName">
                                    <Translate contentKey="tariffName">Tarife Adı</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="tariffName"
                                    disabled={true}
                                    value={this.state.productOffering.tariffName}
                                    onChange={this.__handleChange}/>
                            </AvGroup>
                            <AvGroup>
                                <Label for="packageName">
                                    <Translate contentKey="packageName">Paket</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="packageName"
                                    disabled={true}
                                    value={this.state.productOffering.packageName}/>
                            </AvGroup>
                            <AvGroup>
                                <Label for="campaignName">
                                    <Translate contentKey="campaignName">Kampanya</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="campaignName"
                                    disabled={true}
                                    value={this.state.productOffering.campaignName}/>
                            </AvGroup>
                            <AvGroup>
                                <Label for="productType">
                                    <Translate contentKey="bbProduct.productType">Ürün Tipi</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="productType"
                                    disabled={true}
                                    value={this.state.productOffering.productType}
                                    onChange={this.__handleChange}/>
                            </AvGroup>

                            <AvGroup>
                                <Label for="status">
                                    <Translate contentKey="bbProduct.status">Durum</Translate>
                                </Label>
                                <AvField type="select" className="form-control" name="status"
                                         value={this.state.productOffering.status} onChange={this.__handleChange}
                                         disabled={true}>
                                    {STATUS.map(element => (
                                        <option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>
                        </Fieldset>
                    </Col>
                    <Col md={6}>
                        <Fieldset legend="Ürün Tanım Bilgileri">
                            <AvGroup>
                                <Label for="name">
                                    <Translate contentKey="name">Adı</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="name"
                                    disabled={true}
                                    value={this.state.productOffering.productSpecId.name}
                                    onChange={this.__handleChange}/>
                            </AvGroup>
                            <AvGroup>
                                <Label for="organizationName">
                                    <Translate contentKey="organizationName">Organizasyon</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="organizationName"
                                    disabled={true}
                                    value={this.state.productOffering.productSpecId.organizationId.name}
                                    onChange={this.__handleChange}/>
                            </AvGroup>
                        </Fieldset>
                        <Fieldset legend="Bant Genişliği Bilgileri">
                            <AvGroup>
                                <Label for="accessTechnologyPO">
                                    <Translate contentKey="bbProduct.bandwidthDownloadSpeed">Erişim
                                        Teknolojisi</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="accessTechnologyPO"
                                    disabled={true}
                                    value={this.state.productOffering.productSpecId.bandwidthId.accessTechnology}/>
                            </AvGroup>
                            <AvGroup>
                                <Label for="downloadSpeedPO">
                                    <Translate contentKey="bbProduct.bandwidthDownloadSpeed">İndirme Hızı</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="downloadSpeedPO"
                                    disabled={true}
                                    value={this.state.productOffering.productSpecId.bandwidthId.downloadSpeed}/>
                            </AvGroup>
                            <AvGroup>
                                <Label for="uploadSpeedPO">
                                    <Translate contentKey="bbProduct.bandwidthUploadSpeed">Yükleme Hızı</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="uploadSpeedPO"
                                    disabled={true}
                                    value={this.state.productOffering.productSpecId.bandwidthId.uploadSpeed}/>
                            </AvGroup>
                        </Fieldset>
                    </Col>
                </Row>
            </div>
        );
    }

    setData = (data, pageMode) => {
        this.clearData();
        this.setState({productOffering: data, pageMode: pageMode});
    }

    clearData = () => {
        this.setState({productOffering: Object.assign({}, defaultProductOffering)});
    }
}
