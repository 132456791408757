import React from 'react';
import {Panel} from 'primereact/panel';
import DataTable from '../../../shared/component/dataTable/DataTable';
import HsmRoleService from '../../../shared/service/HsmRoleService';
import HsmRoleColumn from '../../../shared/component/dataTable/columns/HsmRoleColumn';
import {Button} from 'reactstrap';
import Translate from "../../../shared/language/Translate";
import HsmRoleSave from "./HsmRoleSave";
import {TabPanel, TabView} from "primereact/tabview";
import {PAGE_MODE} from "../../../shared/utils/Constants";
import {AvForm} from "availity-reactstrap-validation";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import HsmRoleMenu from "./HsmRoleMenu";
import Toolbar from "../../../shared/component/toolbar/Toolbar";
import AuditEvent from "../../administration/AuditEvent";
import HsmRolePrivilege from "./HsmRolePrivilege";
import Dialog from "../../../shared/component/dialog/Dialog";

export default class HsmRole extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageMode: null
        };
        this.service = new HsmRoleService();
    }

    componentDidMount = () => {
        this.service.checkPage();
    }

    render() {
        return (
            <Panel header="Rol Yönetimi">
                <Toolbar display={GeneralUtils.checkPrivilege(HsmRoleColumn.name, 'Create')}>
                    <div className="p-toolbar-group-right">
                        <Button color="primary" onClick={this.addNew}>
                            <Translate contentKey="entity.action.add">Yeni Kayıt</Translate>
                        </Button>
                    </div>
                </Toolbar>

                <DataTable ref={(elem) => this.refDataTable = elem}  fields={HsmRoleColumn.fields} dataURL="hsmRole/findAll" objectName={HsmRoleColumn.name}
                           selectionMode="single" onDataSelect={this.onDataSelect} export={true}/>


                <Dialog visible={this.state.displayDialog}
                        header={GeneralUtils.getDialogHeader('Rol', this.state.pageMode)}
                        onHide={() => this.setState({displayDialog: false})} style={{width: '60%'}}>
                    <AvForm onValidSubmit={this.save} beforeSubmitValidation={() => this.setState({activeIndex: 0})}>
                        <Toolbar
                            display={GeneralUtils.getDialogToolbarDisplay(HsmRoleColumn.name, this.state.pageMode)}>
                            <div className="p-toolbar-group-right">
                                <Button color="primary" type="submit" className="float-right">
                                    <Translate contentKey="entity.action.save">Kaydet</Translate>
                                </Button>
                                <Button color="primary" className="float-right"
                                        onClick={() => this.setState({displayDialog: false})}>
                                    <Translate contentKey="entity.action.save">Vazgeç</Translate>
                                </Button></div>

                        </Toolbar>
                        <TabView activeIndex={this.state.activeIndex}
                                 onTabChange={(e) => {
                                     this.setState({activeIndex: e.index});
                                     this.refHsmRoleMenu.collapseAll();
                                 }} renderActiveOnly={false}>
                            <TabPanel header="Rol Bilgisi">
                                <HsmRoleSave ref={elem => this.refHsmRoleSave = elem}/>
                            </TabPanel>

                            <TabPanel header="Rol Menü İlişkilendirme">
                                <HsmRoleMenu ref={elem => this.refHsmRoleMenu = elem}/>
                            </TabPanel>

                            <TabPanel header="Rol Yetki İlişkilendirme">
                                <HsmRolePrivilege ref={elem => this.refHsmRolePrivilege = elem}/>
                            </TabPanel>

                            <TabPanel header="Tarihçe" rightIcon="pi pi-user"
                                      disabled={this.state.pageMode === PAGE_MODE.CREATE}
                                      headerClassName={this.state.pageMode === PAGE_MODE.CREATE && 'displayNone'}>
                                <AuditEvent ref={elem => this.refAuditEvent = elem} objectName={HsmRoleColumn.name}/>
                            </TabPanel>
                        </TabView>
                    </AvForm>

                </Dialog>
            </Panel>
        );
    }

    refresh = () => {
        this.refDataTable.refresh();
    }

    onDataSelect = (data) => {
        let pageMode = GeneralUtils.getPageModeFromPrivilege(HsmRoleColumn.name);
        this.setState({displayDialog: true, pageMode: pageMode, activeIndex: 0});
        this.refHsmRoleSave.clearData();
        this.refHsmRoleSave.setData(Object.assign({}, data), pageMode);
        this.refHsmRoleMenu.clearData();
        this.refHsmRoleMenu.setData(Object.assign({}, data), pageMode);
        this.refHsmRolePrivilege.clearData();
        this.refHsmRolePrivilege.setData(Object.assign({}, data), pageMode);
        this.refAuditEvent.setData(HsmRoleColumn.name, data.id);
    }


    addNew = () => {
        this.setState({displayDialog: true, pageMode: PAGE_MODE.CREATE, activeIndex: 0});
        this.refHsmRoleSave.clearData();
        this.refHsmRoleMenu.clearData();
        this.refHsmRolePrivilege.clearData();
    }


    save = () => {
        let role = this.refHsmRoleSave.getData();
        role.menuList = this.refHsmRoleMenu.getData();
        role.hsmPrivilegeList = this.refHsmRolePrivilege.getData();
        if (!GeneralUtils.isNullOrEmpty(role.id)) {
            this.service.update(role).then((response) => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    this.setState({displayDialog: false});
                    this.refresh();
                }
            });
        } else {
            this.service.create(role).then((response) => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    this.setState({displayDialog: false});
                    this.refresh();
                }
            });
        }
    }
}
