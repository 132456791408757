import React from 'react';
import {Panel} from 'primereact/panel';
import DataTable from '../../shared/component/dataTable/DataTable';
import Service from '../../shared/service/Service';
import WebServiceLogColumn from '../../shared/component/dataTable/columns/WebServiceLogColumn';
import Dialog from "../../shared/component/dialog/Dialog";
import WebServiceLogContent from "./WebServiceLogContent";


export default class WebServiceLog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
        this.service = new Service('webServiceLog');
    }

    componentDidMount = () => {
        this.setState({loading: true});
        this.service.checkPage();
    }

    render() {
        return (
            <Panel header="Webservis Log">
                <DataTable fields={WebServiceLogColumn.fields}
                           dataURL="webServiceLog/findAll" objectName={WebServiceLogColumn.name}
                           loading={this.state.loading} export={true}
                           selectionMode="single"
                           onDataSelect={this.onDataSelect}
                           privilege="allow"
                />

                <Dialog visible={this.state.displayDialog} header="WebService Log Detay"
                        onHide={() => this.setState({displayDialog: false})} style={{width: '60%'}}>
                    <WebServiceLogContent ref={(e) => this.refWebServiceLogContent = e}/>
                </Dialog>
            </Panel>
        );
    }

    onDataSelect = (data) => {
        this.refWebServiceLogContent.setData(data);
        this.setState({
            displayDialog: true
        });
    }

}
