import React from 'react';
import {AvGroup} from "availity-reactstrap-validation";
import {Col, Label, Row} from "reactstrap";
import AvField from "../../../shared/component/form/AvField";
import ServiceCategoryService from "../../../shared/service/ServiceCategoryService"
import ServiceSubcategoryService from "../../../shared/service/ServiceSubcategoryService"
import {FLOW_ID, TRUEFALSE} from "../../../shared/utils/Constants"
import TeamGroup from "../../../shared/component/hsmGroup/TeamGroup"
import CheckboxGroup from "../../../shared/component/multiSelect/CheckboxGroup"

const defaultWorkFlowModel = {
    id: '',
    categoryId: 1,
    subcategoryId: '',
    teamId: '',
    flowId: '',
    step: '',
    autoAssign: true,
    actApprove: false,
    actReject: false,
    actCancel: false,
    actEscalate: false,
    actAssign: false,
    actResolve: false,
    actPending: false,
    actClose: false,
    actReopen: false,
    actEdit: false,
    actResume: false
};

export default class WorkFlowModelSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            workFlowModel: defaultWorkFlowModel,
            serviceSubcategoryList: [],
            serviceCategoryList: [],
            team: null,
            pageMode: null,
            checkboxes: []

        };
        this.serviceCategoryService = new ServiceCategoryService();
        this.serviceSubcategoryService = new ServiceSubcategoryService();


    }

    componentDidMount = () => {
        this.clearData();
        this.serviceCategoryService.findAll().then(response => this.setState({serviceCategoryList: response}));
        this.findServiceSubcategoryByChangedServiceCategoryId(this.state.workFlowModel.categoryId);
    }

    render() {

        return (
            <Row className="justify-content-center">
                <Col>
                    <AvGroup>
                        <Label for="categoryId">Kategori<span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="select"
                            className="form-control"
                            name="categoryId"
                            value={this.state.workFlowModel.categoryId}
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                }
                            }}
                            onChange={this.__handleChangeServiceCategory}>
                            {this.state.serviceCategoryList.map(element => (
                                <option value={element.id} key={element.id}>
                                    {element.name}
                                </option>
                            ))}
                        </AvField>
                    </AvGroup>
                    <AvGroup>
                        <Label for="subcategoryId">Alt Kategori<span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="select"
                            className="form-control"
                            name="subcategoryId"
                            value={this.state.workFlowModel.subcategoryId}
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                }
                            }}
                            onChange={this.__handleChange}>
                            <option value='' key=''/>
                            {this.state.serviceSubcategoryList.map(element => (
                                <option value={element.id} key={element.id}>
                                    {element.name}
                                </option>
                            ))}
                        </AvField>
                    </AvGroup>
                    <AvGroup>
                        <Label for="step">Adım<span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="number"
                            className="form-control"
                            name="step"
                            value={this.state.workFlowModel.step}
                            max="999999"
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                }
                            }}
                            onChange={this.__handleChange}
                        />
                    </AvGroup>
                    <TeamGroup
                        value={this.state.team}
                        onDataSelect={this.onTeamSelect}
                        name="team"
                    />
                    <AvGroup>
                        <Label for="flowId">Flow Id<span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="select"
                            className="form-control"
                            name="flowId"
                            value={this.state.workFlowModel.flowId}
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'Bu alan zorunlu.'
                                },
                                minLength: {
                                    value: 1,
                                    errorMessage: 'Bu alan en az 1 karakterden oluşabilir.'
                                },
                                maxLength: {
                                    value: 100,
                                    errorMessage: 'Bu alan en fazla 100 karakterden oluşabilir.'
                                }
                            }}
                            onChange={this.__handleChange}>
                            <option value='' key=''/>
                            {FLOW_ID.map(element => (
                                <option value={element.value} key={element.value}>
                                    {element.name}
                                </option>
                            ))}
                        </AvField>
                    </AvGroup>
                    <AvGroup>
                        <Label for="autoAssign">Otomatik Atama<span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField type="select" className="form-control" name="autoAssign"
                                 value={this.state.workFlowModel.autoAssign}
                                 validate={{
                                     required: {
                                         value: false,
                                         errorMessage: 'Bu alan zorunlu.'
                                     }
                                 }}
                                 onChange={this.__handleChange}>
                            {TRUEFALSE.map(element => (
                                <option value={element.value} key={element.value}>
                                    {element.name}
                                </option>
                            ))}
                        </AvField>
                    </AvGroup>
                    <CheckboxGroup
                        checkboxList={this.state.checkboxes}
                        onChange={this.handleCheckbox}/>
                </Col>
            </Row>
        );
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let workFlowModel = this.state.workFlowModel;
        workFlowModel[e.target.name] = value;
        this.setState({workFlowModel: workFlowModel});
    }

    setData = (data, pageMode) => {
        console.log(data);
        data.categoryId = data.categoryId ? data.categoryId.id : null;
        data.subcategoryId = data.subcategoryId ? data.subcategoryId.id : null;
        let team = data.teamId ? data.teamId.name : null;
        this.setState({workFlowModel: data, team: team, pageMode: pageMode}, () => {
            this.getChecboxes();
        });
        this.findServiceSubcategoryByServiceCategoryId(data.categoryId);
    }

    getData = () => {
        return this.state.workFlowModel;
    }

    clearData = () => {
        this.setState({workFlowModel: Object.assign({}, defaultWorkFlowModel), team: null, pageMode: null}, () => {
            this.getChecboxes();
        });
        this.findServiceSubcategoryByServiceCategoryId(defaultWorkFlowModel.categoryId);
    }

    __handleChangeServiceCategory = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let workFlowModel = this.state.workFlowModel;
        workFlowModel[e.target.name] = value;
        this.findServiceSubcategoryByChangedServiceCategoryId(value);
    }

    findServiceSubcategoryByServiceCategoryId(categoryId) {
        this.serviceSubcategoryService.findByCategoryId(categoryId).then(response => {
            this.setState({serviceSubcategoryList: response});
        });
    }

    findServiceSubcategoryByChangedServiceCategoryId = (categoryId) => {
        this.serviceSubcategoryService.findByCategoryId(categoryId).then(response => {
            let workFlowModel = this.state.workFlowModel;
            workFlowModel.subcategoryId = response[0].id;
            this.setState({serviceSubcategoryList: response, workFlowModel: workFlowModel});
        });

    }

    onTeamSelect = (data) => {
        let workFlowModel = this.state.workFlowModel;
        workFlowModel.teamId = data;
        this.setState({workFlowModel: workFlowModel, team: data.name, displayDialog: false});
    }

    getChecboxes = () => {
        let checkboxes = this.state.checkboxes;
        checkboxes = [
            {name: "actApprove", label: "Talebi Onayla", isChecked: this.state.workFlowModel.actApprove},
            {name: "actReject", label: "Reddet", isChecked: this.state.workFlowModel.actReject},
            {name: "actCancel", label: "Talebi İptal Et", isChecked: this.state.workFlowModel.actCancel},
            {name: "actEscalate", label: "Eskale Et", isChecked: this.state.workFlowModel.actEscalate},
            {name: "actAssign", label: "Devret", isChecked: this.state.workFlowModel.actAssign},
            {name: "actResolve", label: "Çözümle", isChecked: this.state.workFlowModel.actResolve},
            {name: "actPending", label: "Askıya Al", isChecked: this.state.workFlowModel.actPending},
            {name: "actClose", label: "Kapat", isChecked: this.state.workFlowModel.actClose},
            {name: "actReopen", label: "Tekrar Aç", isChecked: this.state.workFlowModel.actReopen},
            {name: "actEdit", label: "Düzenle", isChecked: this.state.workFlowModel.actEdit},
            {name: "actResume", label: "Askıdan İndir", isChecked: this.state.workFlowModel.actResume}
        ]
        this.setState({checkboxes: checkboxes})
    }

    handleCheckbox = (event, option) => {
        let checkboxes = this.state.checkboxes;
        let workFlowModel = this.state.workFlowModel
        checkboxes.forEach(checkItem => {
            if (checkItem === option) {
                checkItem.isChecked = event.target.checked;
            }
            let name = option.name;
            workFlowModel[name] = event.target.checked;

        })
        this.setState({checkboxes: checkboxes, workFlowModel: workFlowModel});
    }

}
