import Service from './Service';
import GeneralUtils from "../utils/GeneralUtils";
import {SERVER_URL} from "../utils/Constants";

export default class HsmDocumentService extends Service {
    constructor() {
        super();
        this.baseURL = 'hsmDocument';
    }

    uploadFileCreate(allDataAndFileArray, requestId, requestType) {
        for (var i = 0; i < allDataAndFileArray.length; i++) {
            let fileArr = [];
            for (var j = 0; j < allDataAndFileArray[i].file[0].length; j++) {
                fileArr.push(allDataAndFileArray[i].file[0][j]);
            }
            this.uploadFile(allDataAndFileArray[i].data, fileArr, requestId, requestType, GeneralUtils.getUserId(), () => {

            });
        }
    }

    uploadFileUpdate(allDataAndFileArray, requestId, requestType) {
        for (var i = 0; i < allDataAndFileArray.length; i++) {
            if (allDataAndFileArray[i].file.length === 0) {
                this.updateHsmDocumentFieldsWithoutFile(allDataAndFileArray[i].data, allDataAndFileArray[i].data.documentId);
            } else {
                let fileArr = [];
                for (var j = 0; j < allDataAndFileArray[i].file[0].length; j++) {
                    fileArr.push(allDataAndFileArray[i].file[0][j]);
                }
                this.uploadFile(allDataAndFileArray[i].data, fileArr, requestId, requestType, GeneralUtils.getUserId(), () => {

                });
            }
        }
    }

    uploadFile(data,files) {
        let formData = new FormData();
        for (const file of files){
            formData.append("files",file);
        }
        formData.append("data",JSON.stringify(data));

        return fetch(SERVER_URL + this.baseURL + "/uploadFile", {
            crossDomain: true,
            method: 'POST',
            headers: {Authorization: localStorage.getItem("Authorization")},
            body: formData
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response);
            return result.json();
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    updateHsmDocumentFieldsWithoutFile(data, documentId) {
        var formData = new FormData();

        formData.append('documentTitle', data.documentTitle);
        formData.append('documentType', data.documentType);
        formData.append('documentDescription', data.documentDescription);
        formData.append('documentId', documentId);
        formData.append('id', data.id);


        fetch(SERVER_URL + this.baseURL + '/updateHsmDocumentFieldsWithoutFile', {
            method: 'POST',
            headers: {Authorization: localStorage.getItem("Authorization")},
            body: formData
        })
            .then(response => {
                return response.json();
            })
            .catch(error => GeneralUtils.notification(error));
    }

    downloadFileList(documentId) {
        return fetch(SERVER_URL + this.baseURL + '/downloadFileList/' + documentId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        })
            .then(response => response.json())
            .catch(error => {
                GeneralUtils.notification(error);
            });
    }

    download(id) {
        return fetch(SERVER_URL + this.baseURL + '/download/' + id, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        })
            .then(response => response.json())
            .catch(error => {
                GeneralUtils.notification(error);
            });
    }

    findAttachedDocumentByParentObjectId(parantObjectId) {
        return fetch(SERVER_URL + this.baseURL + '/findAttachedDocumentByParentObjectId/' + parantObjectId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    findByObjectTypeAndObjectId(parentObjectType, parentObjectId) {
        return fetch(SERVER_URL + this.baseURL + '/findByObjectTypeAndObjectId/' + parentObjectType + '/' + parentObjectId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    findDocumentIdListByObjectTypeAndObjectId(objectType, objectId) {
        return fetch(SERVER_URL + this.baseURL + '/findDocumentIdListByObjectTypeAndObjectId/' + objectType + '/' + objectId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    findDocumentPartsByDocumentId(documentId) {
        return fetch(SERVER_URL + this.baseURL + '/findDocumentPartsByDocumentId/' + documentId, {
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    deleteDocument(documentId) {
            return fetch(SERVER_URL + this.baseURL + '/deleteDocument/' + documentId, {
                crossDomain: true,
                method: 'delete',
                headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")}
            }).then(response => {
                let result = response.clone();
                GeneralUtils.notification(response);
                return result.json();
            }).catch(error => {
                GeneralUtils.notification(error);
            });
    }

    createOrUpdateHsmAttachDocument(objectType, objectId, data) {
        return fetch(SERVER_URL + this.baseURL + '/createOrUpdateHsmAttachDocument/' + objectType + '/' + objectId, {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")},
            body: JSON.stringify(GeneralUtils.addCreateInfo(data))
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    updateStatus(data) {
        return fetch(SERVER_URL + this.baseURL + '/updateStatus', {
            crossDomain: true,
            method: 'POST',
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("Authorization")},
            body: JSON.stringify(data)
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response);
            return result.json();
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

}